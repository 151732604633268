import { AdminConfigurationState } from '../states/admin-configuration.state';
import {
  clearConfigurationCache,
  getLatestConfiguration,
  updateConfiguration,
} from '../thunks/admin/admin-configuration.thunk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Configuration } from '../../common/model/dto/configuration';
import { AxiosError } from 'axios';
import { ErrorUtils } from '../../common/utils/services/error-utils';

const initialState: AdminConfigurationState = {
  latestConfiguration: null,
  loading: false,
  errorMessage: null,
};

const adminConfigurationSlice = createSlice({
  name: 'adminConfiguration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // @ts-ignore
    builder
      .addCase(getLatestConfiguration.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(
        getLatestConfiguration.fulfilled,
        (state, action: PayloadAction<Configuration>) => {
          state.latestConfiguration = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        getLatestConfiguration.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(updateConfiguration.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(
        updateConfiguration.fulfilled,
        (state, action: PayloadAction<Configuration>) => {
          state.latestConfiguration = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        updateConfiguration.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(clearConfigurationCache.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(
        clearConfigurationCache.fulfilled,
        (state, action: PayloadAction<Configuration>) => {
          state.loading = false;
        }
      )
      .addCase(
        clearConfigurationCache.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );
  },
});

export default adminConfigurationSlice.reducer;
