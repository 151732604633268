import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from '../../design-system/breakpoints';
import Variables from '../../design-system/variables';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import OvBackButton from '../UI/atoms/OvBackButton';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Article } from '../../common/model/dto/article';
import {
  getArticleById,
  createArticle,
  updateArticle,
} from '../../redux/thunks/articles.thunk';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import OvTextFieldFormControl from '../UI/atoms/OvTextFieldFormControl';
import Colours from '../../design-system/colours';
import { OOVA_ARTICLE_ASSETS } from '../../api/firebase-storage';
import OvImageUpload from '../UI/molecules/OvImageUpload';
import OvFormObserver from '../UI/atoms/OvFormObserver';
import { clearError } from '../../redux/reducers/articles.slice';

interface ArticleFormProps {}

const ArticleForm: React.FC<ArticleFormProps> = () => {
  let { articleId }: any = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.articles.loading);
  const errorMessage = useAppSelector((state) => state.articles.error);
  const article: Article = useAppSelector(
    (state) => state.articles.selectedArticle
  );

  const [imageUrl, setImageUrl] = useState<string>();
  const [articleFormValue, setArticleFormValue] = useState<Article>();

  useEffect(() => {
    if (articleId) {
      dispatch(getArticleById(articleId));
    }
  }, [articleId, dispatch]);

  useEffect(() => {
    if (article) {
      initForm();
    }

    return () => {
      dispatch(clearError());
    };

    // eslint-disable-next-line
  }, [article, dispatch]);

  const initForm = () => {
    setImageUrl(article.image_url);
    setArticleFormValue(article);
  };

  const handleSuccessfulImageUpload = ({
    image_path,
  }: {
    image_path: string;
  }) => {
    setImageUrl(image_path);
  };

  const saveArticle = () => {
    const articleDTO: Article = {
      ...article,
      ...articleFormValue,
      image_url: imageUrl,
    };

    if (articleDTO.id) {
      dispatch(updateArticle({ history, article: articleDTO }));
    } else {
      dispatch(createArticle({ article: articleDTO, history }));
    }
  };

  const handleFormValueChange = (value: Article) => {
    setArticleFormValue(value);
  };

  return (
    <Wrapper>
      <Header>
        <StretchedDiv>
          <OvBackButton label={t('common.actions.back')} />
        </StretchedDiv>
        <OvPageTitle
          title={t(articleId ? 'articles.update' : 'articles.create')}
          styles={{ justifyContent: 'center' }}
          icon={<StyledArticleIcon />}
        />
        <StretchedDiv>
          <OvCompactButton
            onClick={saveArticle}
            style={{ marginLeft: 'auto' }}
            icon={<StyledAddIcon />}
          >
            {articleId ? t('articles.update') : t('articles.create')}
          </OvCompactButton>
        </StretchedDiv>
      </Header>

      <Content>
        <FormWrapper>
          <ImageUploadContainer>
            <OvImageUpload
              imageBasePath={OOVA_ARTICLE_ASSETS}
              imageUrl={article?.image_url}
              title={t('articles.images.thumbnail')}
              hintText={t('articles.images.thumbnailHint')}
              ctaLabel={t('imageUpload.uploadImage')}
              dialogTitle={t('articles.images.thumbnailUploadDialogTitle')}
              handleChange={handleSuccessfulImageUpload}
            />
          </ImageUploadContainer>

          <Formik
            initialValues={{
              title: article?.title || '',
              topic: article?.topic || '',
              section: article?.section || '',
              timing: article?.timing || 0,
              word_count: article?.word_count || 0,
              days_in_post_ovulation: article?.days_in_post_ovulation || 0,
              cta: article?.cta || '',
              color: article?.color || '',
              url: article?.url || '',
            }}
            enableReinitialize={true}
            onSubmit={(values) => console.log(values)}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(t('common.requiredField')),
              topic: Yup.string().required(t('common.requiredField')),
              section: Yup.string().required(t('common.requiredField')),
              timing: Yup.number().required(t('common.requiredField')),
              word_count: Yup.number().required(t('common.requiredField')),
              days_in_post_ovulation: Yup.number().required(
                t('common.requiredField')
              ),
              cta: Yup.string().required(t('common.requiredField')),
              color: Yup.string().required(t('common.requiredField')),
              url: Yup.string()
                .url(t('common.invalidUrl'))
                .required(t('common.requiredField')),
            })}
            validateOnBlur={true}
          >
            <StyledForm>
              <StyledFormLabel>
                <StyledLabelText>{t('articles.fields.title')}</StyledLabelText>

                <Field
                  component={StyledInputField}
                  type="text"
                  name="title"
                  placeholder={t('articles.fields.title')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>{t('articles.fields.topic')}</StyledLabelText>

                <Field
                  component={StyledInputField}
                  type="text"
                  name="topic"
                  placeholder={t('articles.fields.topic')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>
                  {t('articles.fields.section')}
                </StyledLabelText>

                <Field
                  component={StyledInputField}
                  type="text"
                  name="section"
                  placeholder={t('articles.fields.section')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>{t('articles.fields.timing')}</StyledLabelText>
                <Field
                  component={StyledInputField}
                  type="number"
                  name="timing"
                  placeholder={t('articles.fields.timing')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>
                  {t('articles.fields.wordCount')}
                </StyledLabelText>
                <Field
                  component={StyledInputField}
                  type="number"
                  name="word_count"
                  placeholder={t('articles.fields.wordCount')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>
                  {t('articles.fields.daysInPostOvulation')}
                </StyledLabelText>
                <Field
                  component={StyledInputField}
                  type="number"
                  name="days_in_post_ovulation"
                  placeholder={t('articles.fields.daysInPostOvulation')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>{t('articles.fields.cta')}</StyledLabelText>
                <Field
                  component={StyledInputField}
                  type="text"
                  name="cta"
                  placeholder={t('articles.fields.cta')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>{t('articles.fields.color')}</StyledLabelText>
                <Field
                  component={StyledInputField}
                  type="text"
                  name="color"
                  placeholder={t('articles.fields.color')}
                />
              </StyledFormLabel>

              <StyledFormLabel>
                <StyledLabelText>{t('articles.fields.url')}</StyledLabelText>
                <Field
                  component={StyledInputField}
                  type="text"
                  name="url"
                  placeholder={t('articles.fields.url')}
                />
              </StyledFormLabel>

              <OvFormObserver valuesChanged={handleFormValueChange} />
            </StyledForm>
          </Formik>
        </FormWrapper>
      </Content>

      {errorMessage && (
        <Box>
          <OvErrorMessage message={errorMessage} />
        </Box>
      )}

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Wrapper>
  );
};

export default ArticleForm;

const Box = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Wrapper = styled(Box)`
  margin-left: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 1rem;
  }
`;

const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  align-items: center;
`;

const StretchedDiv = styled.div`
  flex-grow: 1;
`;

const StyledArticleIcon = styled(ArticleIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledAddIcon = styled(AddIcon)`
  && {
    margin-left: 0.25rem;
  }
`;

const Content = styled.section`
  margin-top: 1.5rem;
  border-top: 1px solid ${Colours.OV_LIGHT_GRAY};
`;

const ImageUploadContainer = styled.div`
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormWrapper = styled.div`
  max-width: 100%;
`;

const StyledForm = styled(Form)`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

const StyledFormLabel = styled.label`
  display: block;
  flex-basis: calc(50% - 0.5rem);

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledLabelText = styled.p`
  font-size: ${Variables.fontSizes.MEDIUM};
  font-weight: bold;
  text-indent: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

const StyledInputField = styled(OvTextFieldFormControl)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      width: 100%;

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;
