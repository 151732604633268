import { OvTableData } from '../../../components/UI/molecules/OvTable';
import Colours from '../../../design-system/colours';
import { NotificationContentFields } from '../../../firebase/document-field.enums';
import i18n from '../../../i18n/config';
import {
  NotificationContent,
  NotificationContentCategory,
} from '../../model/dto/notification/notification-content';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import StringUtils from './string-utils';

export class NotificationContentDataUtils {
  static mapNotificationContentToTableDataFormat(
    notificationContent?: NotificationContent
  ): OvTableData {
    const tableData: OvTableData = [
      {
        label: 'notificationContents.fields.title',
        value: notificationContent?.title,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          propertyName: NotificationContentFields.title,
          placeholder: i18n.t('notificationContents.fields.title'),
        },
      },
      {
        label: 'notificationContents.fields.category',
        value: notificationContent?.category,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.SELECT,
          propertyName: NotificationContentFields.category,
          options: [
            {
              label: i18n.t('notificationContents.categories.fromOova'),
              value: NotificationContentCategory.FROM_OOVA,
            },
            {
              label: i18n.t('notificationContents.categories.blogPost'),
              value: NotificationContentCategory.BLOG_POST,
            },
            {
              label: i18n.t('notificationContents.categories.appUpdates'),
              value: NotificationContentCategory.APP_UPDATES,
            },
            {
              label: i18n.t('notificationContents.categories.studies'),
              value: NotificationContentCategory.STUDIES,
            },
            {
              label: i18n.t('notificationContents.categories.offers'),
              value: NotificationContentCategory.OFFERS,
            },
          ],
        },
      },

      {
        label: 'notificationContents.fields.shortDescription',
        value: notificationContent?.short_description,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          propertyName: NotificationContentFields.short_description,
          placeholder: i18n.t('notificationContents.fields.shortDescription'),
        },
      },
      {
        label: 'notificationContents.fields.fullDescription',
        value: notificationContent?.full_description,
        translateLabel: true,
        displayAsHTML: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.FORMATTED_TEXT_AREA,
          propertyName: NotificationContentFields.full_description,
          placeholder: i18n.t('notificationContents.fields.fullDescription'),
        },
      },
      {
        label: 'notificationContents.fields.disclaimer',
        value: notificationContent?.disclaimer,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          propertyName: NotificationContentFields.disclaimer,
          placeholder: i18n.t('notificationContents.fields.disclaimer'),
        },
      },
      {
        label: 'notificationContents.fields.tags',
        value: notificationContent?.tags,
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.imageUrl',
        value: notificationContent?.image_url,
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.insightsType',
        value: notificationContent?.insights_type,
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.params',
        value: notificationContent?.params,
        prettifiedValue: notificationContent?.params
          ? JSON.stringify(notificationContent?.params, null, 2)
          : '-',
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.isPublished',
        value: notificationContent?.is_published,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          notificationContent?.is_published
        ),
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.numberOfCreatedNotifications',
        value: notificationContent?.number_of_created_notifications,
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.createdAt',
        value: notificationContent?.created_at,
        translateLabel: true,
      },
      {
        label: 'notificationContents.fields.updatedAt',
        value: notificationContent?.updated_at,
        translateLabel: true,
      },
    ];

    if (notificationContent?.actions) {
      for (let i = 0; i < notificationContent?.actions?.length; i++) {
        const action = notificationContent.actions[i];

        tableData.push({
          label: i18n.t('notificationContents.fields.actions', {
            actionIndex: i + 1,
          }),
          value: !!notificationContent?.actions,
          nestedRows: [
            {
              label: 'notificationContents.notificationActions.cta',
              value: action.cta,
              translateLabel: true,
            },
            {
              label: 'notificationContents.notificationActions.buttonStyle',
              value: action.button_style,
              translateLabel: true,
            },
            {
              label: 'notificationContents.notificationActions.actionUrl',
              value: action.action_url,
              translateLabel: true,
            },
            {
              label: 'notificationContents.notificationActions.type',
              value: action.type,
              translateLabel: true,
            },
          ],
          style: {
            backgroundColor: Colours.OV_LIGHT_GRAY,
          },
          showNestedRows: false,
        });
      }
    }

    return tableData;
  }
}
