import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { useIdleTimer } from 'react-idle-timer';
import { useAppDispatch } from '../../../redux/hooks';
import DateUtils from '../../../common/utils/services/date-utils';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { logout } from '../../../redux/thunks/auth.thunk';

enum CrossTabMessageTypeEnum {
  PROMPT = 'promt',
  IDLE = 'idle',
  ACTIVE = 'active',
  STILL_HERE = 'still_here',
  LOGOUT = 'logout',
}

const OvIdleTimer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const timeout = DateUtils.SECOND_IN_MS * DateUtils.MIN_IN_SEC * 10; // idle after 10 minutes
  const promptBeforeIdle = DateUtils.SECOND_IN_MS * DateUtils.MIN_IN_SEC; // prompt will be displayed 1 minute before idle

  // Modal open state
  const [isOpen, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    setOpen(true);
    setRemaining(promptBeforeIdle / DateUtils.SECOND_IN_MS);
  };

  const onIdle = () => {
    handleLogout();
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const handleStillHere = () => {
    setOpen(false);
    activate();
    reset();
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const onMessage = (messageType: CrossTabMessageTypeEnum) => {
    switch (messageType) {
      case CrossTabMessageTypeEnum.PROMPT:
        onPrompt();
        break;
      case CrossTabMessageTypeEnum.IDLE:
        onIdle();
        break;
      case CrossTabMessageTypeEnum.ACTIVE:
        onActive();
        break;
      case CrossTabMessageTypeEnum.STILL_HERE:
        handleStillHere();
        break;
      case CrossTabMessageTypeEnum.LOGOUT:
        handleLogout();
        break;
    }
  };

  const { getRemainingTime, isPrompted, activate, reset, message } =
    useIdleTimer({
      timeout,
      promptBeforeIdle,
      onPrompt: () => message(CrossTabMessageTypeEnum.PROMPT, true),
      onIdle: () => message(CrossTabMessageTypeEnum.IDLE, true),
      onActive: () => message(CrossTabMessageTypeEnum.ACTIVE, true),
      crossTab: true,
      onMessage,
    });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / DateUtils.SECOND_IN_MS));
      }
    }, DateUtils.SECOND_IN_MS / 2);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted, dispatch]);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby={t('userInactivity.title')}
      aria-describedby={t('userInactivity.description', { remaining })}
    >
      <StyledBedTimeIcon />
      <StyledDialogTitle id="alert-dialog-title">
        {t('userInactivity.title')}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('userInactivity.description', { remaining })}
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <StyledLightOvButton
          onClick={() => message(CrossTabMessageTypeEnum.LOGOUT, true)}
        >
          {t('userInactivity.logout')}
        </StyledLightOvButton>
        <StyledOvButton
          onClick={() => message(CrossTabMessageTypeEnum.STILL_HERE, true)}
          autoFocus
        >
          {t('userInactivity.stayCta')}
        </StyledOvButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OvIdleTimer;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;

    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledBedTimeIcon = styled(BedtimeIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
