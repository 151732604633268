import { camelCase } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderActivity } from '../../../common/model/dto/order/order-activity';
import DateUtils from '../../../common/utils/services/date-utils';
import StringUtils from '../../../common/utils/services/string-utils';
import OvListTableField from '../atoms/OvListTableField';
import DataObjectIcon from '@mui/icons-material/DataObject';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import Colours from '../../../design-system/colours';
import OvPayloadModal from '../molecules/OvPayloadModal';

const OvOrderActivityListRow: FC<{
  orderActivity: OrderActivity;
}> = ({ orderActivity }) => {
  const { t } = useTranslation();
  const [isPayloadModalOpen, setPayloadModalOpen] = useState<boolean>(false);

  return (
    <>
      <OvListTableField>
        {DateUtils.formatDate(
          new Date(orderActivity.created_at ?? ''),
          'MMMM DD, YYYY, HH:mm:ss'
        )}
      </OvListTableField>
      <OvListTableField>
        {orderActivity.order_snapshot?.status}
      </OvListTableField>
      <OvListTableField>
        {t(`orders.orderActivities.${camelCase(orderActivity.type)}`)}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.displayValue(orderActivity.initiator)}
      </OvListTableField>
      <OvListTableField>
        {t(`orders.orderActivities.sources.${camelCase(orderActivity.source)}`)}
      </OvListTableField>
      <OvListTableField>
        <Tooltip title={'Payload'}>
          <StyledIconButton
            onClick={() => setPayloadModalOpen(true)}
            className="actionIcon"
          >
            <DataObjectIcon />
          </StyledIconButton>
        </Tooltip>
      </OvListTableField>

      <OvPayloadModal
        isOpen={isPayloadModalOpen}
        onCancel={() => setPayloadModalOpen(false)}
      >
        <StyledDataWrapper>
          <pre>{JSON.stringify(orderActivity.payload, null, 2)}</pre>
        </StyledDataWrapper>
      </OvPayloadModal>
    </>
  );
};

export default OvOrderActivityListRow;

export const StyledDataWrapper = styled.div``;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;
    margin-left: 0.25rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;
