import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FilterModel } from '../../../common/model/ui/filter.model';
import { useHistory } from 'react-router-dom';
import { hasAppliedFiltersSelector } from '../../../redux/selectors/product-price.selector';
import { History } from 'history';
import {
  changeProductPriceListFilter,
  clearFilters,
} from '../../../redux/reducers/admin-product-price.slice';
import { getProductPrices } from '../../../redux/thunks/admin/admin-product-price.thunk';
import OvListFilter from './OvListFilter';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';

const OvProductPriceSearchFilter: FC<{ productId: string }> = ({
  productId,
}) => {
  const isOpened = useAppSelector(
    (state) => state.adminProductPrice.showFilters
  );
  const filters: FilterModel[] = useAppSelector(
    (state) => state.adminProductPrice.filters
  );
  const hasAppliedFilters = useAppSelector(hasAppliedFiltersSelector);
  const dispatch = useAppDispatch();
  const history: History = useHistory();

  const removeFilters = () => {
    dispatch(clearFilters());
    dispatch(getProductPrices({ history, productId }));
  };

  const handleFilterValueChange = (filter: FilterModel) => {
    dispatch(changeProductPriceListFilter(filter));
    dispatch(getProductPrices({ history, productId }));
  };

  return (
    <Container>
      <OvListFilter
        filters={filters}
        isOpened={isOpened}
        hasAppliedFilters={hasAppliedFilters}
        onRemoveFilters={removeFilters}
        onHandleFilterValueChange={handleFilterValueChange}
      />
    </Container>
  );
};

export default OvProductPriceSearchFilter;

const Container = styled.div`
  border-left: 1px solid ${Colours.OV_BORDER_COLOR_V3};
  padding: 1rem 0;
`;
