import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import CircularProgress from '@mui/material/CircularProgress';
import Variables from '../../../design-system/variables';

const OvLoadingIndicator: FC<{
  showBackdropShadow?: boolean;
  position?: any;
}> = ({ showBackdropShadow, position }) => {
  const [opacity, setOpacity] = useState<string>('rgba(0,0,0,0)');
  const spinner = (
    <SpinnerWrapper>
      <StyledSpinner style={{ position: position || 'absolute' }} />
    </SpinnerWrapper>
  );
  let loadingIndicator = spinner;

  if (showBackdropShadow) {
    loadingIndicator = (
      <Wrapper style={{ backgroundColor: opacity }}>{spinner}</Wrapper>
    );
  }

  useEffect(() => {
    setOpacity('rgba(0,0,0,0.6)');
  }, []);

  return loadingIndicator;
};

export default OvLoadingIndicator;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  color: black;
  font-size: 24px;
  z-index: 9999;
  transition: background-color 0.1s linear;
`;

const StyledSpinner = styled(CircularProgress)`
  && {
    color: ${Colours.OV_BASE};
  }
`;

const SpinnerWrapper = styled.div`
  && {
    width: 4.5rem;
    height: 4.5rem;
    padding: 1rem;
    border-radius: ${Variables.borderRadius.LARGE};
    border: 2px solid ${Colours.OV_BASE};
    color: ${Colours.OV_BASE};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colours.OV_WHITE};
  }
`;
