import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvClinicList from '../UI/organisms/OvClinicList';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import Variables from '../../design-system/variables';
import OvClinicTypeahead from '../UI/molecules/OvClinicTypeahead';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvButton from '../UI/atoms/OvButton';
import OvCreateClinicDialog from '../UI/molecules/OvCreateClinicDialog';
import { Clinic, ClinicOverview } from '../../common/model/dto/clinic';
import useAxios from 'axios-hooks';
import usePaginationQueryParams from '../../hooks/use-pagination-query-params';
import { Paginated, SortDirection } from '../../common/types';
import { AdminClinicService } from '../../services/admin/admin-clinic.service';
import { ADMIN_CLINIC_ROUTE } from '../../api/backend';
import { CreateClinicRequest } from '../../common/model/dto/create-clinic-request';
import { isEmpty } from 'lodash';
import OvCreateDemoAccountDialog from '../UI/molecules/OvCreateDemoAccountDialog';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';
import { CreateDemoAccountRequest } from '../../common/model/dto/create-demo-account-request';
import { AdminClinicLocationService } from '../../services/admin/admin-clinic-location.service';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const CLINIC_LIST_LIMIT = 10;

const AdminClinicList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicOperations = useAuthorized(Resource.Clinic);
  const demoAccountOperations = useAuthorized(Resource.DemoAccount);

  const clinicCreateAllowed =
    clinicOperations.create || applicationOperations.supervise;

  const demoAccountCreateAllowed =
    demoAccountOperations.create || applicationOperations.supervise;

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [isCreateDemoAccountDialogOpen, setIsCreateDemoAccountDialogOpen] =
    useState<boolean>(false);
  const [isDemoAccountCreationLoading, setIsDemoAccountCreationLoading] =
    useState<boolean>(false);

  const { offset, orderBy, orderDir, setOffset, setOrderBy, setOrderDir } =
    usePaginationQueryParams();

  const [{ data, loading, error }, refetch] = useAxios<
    Paginated<ClinicOverview>
  >(
    {
      url: `/${ADMIN_CLINIC_ROUTE}`,
      method: 'get',
      params: {
        limit: CLINIC_LIST_LIMIT,
        offset,
        order_by: isEmpty(orderBy) ? undefined : orderBy,
        order_dir: isEmpty(orderDir) ? undefined : orderDir,
      },
    },
    { useCache: false }
  );

  const clinics = data?.docs ?? [];
  const total = data?.total ?? 0;

  const handleOnSave = async (createClinicRequest: CreateClinicRequest) => {
    await AdminClinicService.createClinic(createClinicRequest);
    setIsCreateDialogOpen(false);
    refetch();
  };

  const handleOnCreateDemoAccount = async (
    createDemoAccountRequest: CreateDemoAccountRequest
  ) => {
    setIsDemoAccountCreationLoading(true);
    const response = await AdminClinicLocationService.createDemoClinicLocation(
      createDemoAccountRequest
    );
    setIsDemoAccountCreationLoading(false);
    setIsCreateDemoAccountDialogOpen(false);

    history.push(`/clinics/${response?.clinicLocation?.clinic.id}`);
  };

  const selectClinic = (clinic: Clinic) => {
    if (clinic && clinic.id) {
      history.push(`/clinics/${clinic.id}`);
    }
  };

  const goToPage = (_: any, value: number) => {
    setOffset((value - 1) * CLINIC_LIST_LIMIT);
  };

  const pageCount = () => Math.ceil(total / CLINIC_LIST_LIMIT);

  const onSortByColumn = (orderByParam: string) => {
    if (orderBy === orderByParam) {
      setOrderDir(orderDir === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(orderByParam);
      setOrderDir('asc');
    }
  };

  useEffect(() => {
    setOrderBy('name');
    setOrderDir('asc');
  }, [setOrderBy, setOrderDir]);

  return (
    <Container>
      <CenterPane>
        <HeaderItems>
          <TitleAndSearch>
            <OvPageTitle
              title={t('app.subPages.menu.clinics')}
              icon={<LocalHospitalIcon />}
            />
            <StyledOvClinicTypeahead
              placeholder={t('common.actions.search')}
              onClinicSelect={selectClinic}
            />
          </TitleAndSearch>
        </HeaderItems>

        <ActionBar>
          <PaginationWrapper>
            {total > CLINIC_LIST_LIMIT && (
              <OvPagination
                page={(offset ?? 0) / CLINIC_LIST_LIMIT + 1}
                onChange={goToPage}
                count={pageCount()}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                shape="rounded"
              />
            )}
          </PaginationWrapper>
          <ButtonsWrapper>
            {clinicCreateAllowed && (
              <StyledOvButton onClick={() => setIsCreateDialogOpen(true)}>
                <AddCircleIcon
                  style={{ verticalAlign: 'middle', marginRight: '5' }}
                />
                {t('clinics.createNewClinic')}
              </StyledOvButton>
            )}
            {demoAccountCreateAllowed && (
              <StyledOvButton
                onClick={() => setIsCreateDemoAccountDialogOpen(true)}
              >
                <AddCircleIcon
                  style={{ verticalAlign: 'middle', marginRight: '5' }}
                />
                {t('common.actions.createDemoClinic')}
              </StyledOvButton>
            )}
          </ButtonsWrapper>
        </ActionBar>

        {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}

        {clinics.length > 0 ? (
          <OvClinicList
            clinics={clinics}
            onSort={onSortByColumn}
            orderBy={orderBy}
            orderDir={orderDir as SortDirection}
          />
        ) : (
          !loading && (
            <OvNoContent>
              {t('common.userSearchFilters.emptyUserList')}
            </OvNoContent>
          )
        )}

        {loading && <OvLoadingIndicator position="fixed" />}

        <OvCreateClinicDialog
          isOpen={isCreateDialogOpen}
          onCancel={() => setIsCreateDialogOpen(false)}
          onSave={handleOnSave}
        />

        <OvCreateDemoAccountDialog
          isOpen={isCreateDemoAccountDialogOpen}
          onCancel={() => setIsCreateDemoAccountDialogOpen(false)}
          onSave={handleOnCreateDemoAccount}
          isLoading={isDemoAccountCreationLoading}
          title={t('common.actions.createDemoClinic')}
          enableCreatePassword={true}
        />
      </CenterPane>
    </Container>
  );
};

export default AdminClinicList;

const Container = styled.div`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const HeaderItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const TitleAndSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledOvClinicTypeahead = styled(OvClinicTypeahead)`
  max-width: 100%;
  min-width: 15rem;
  width: 20%;
  border-radius: ${Variables.borderRadius.SMALL};
  font-size: 0.9rem;

  & input {
    padding: 0.4rem !important;
    background: ${Colours.WHITE};
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    background-color: ${Colours.OV_BASE};
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;
