import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import OvPasswordField from './OvPasswordField';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { useAppSelector } from '../../../redux/hooks';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { SetDemoAccountPasswordRequest } from '../../../common/model/dto/set-demo-account-password-request.ts';

interface OvSetPasswordDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (setPasswordAttempt: SetDemoAccountPasswordRequest) => void;
}

const OvSetPasswordDialog: FC<OvSetPasswordDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const isLoading = useAppSelector((state) => state.user.isLoading);
  const formik = useFormik<SetDemoAccountPasswordRequest>({
    initialValues: {
      password: '',
    },
    onSubmit: (setPasswordAttempt: SetDemoAccountPasswordRequest) => {
      onSave(setPasswordAttempt);
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long.')
        .max(128, 'Password cannot exceed 180 characters.')
        .required(),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogTitle>
          {t('userDialogs.createPasswordForDemoAccountTitle')}
        </StyledDialogTitle>
        <StyledDialogDescription>
          {t('userDialogs.createPasswordForDemoAccountDescription')}
        </StyledDialogDescription>
        <StyledDialogContent>
          <OvPasswordField
            style={{ marginBottom: '14px', justifySelf: 'center' }}
            name="password"
            fullWidth
            label={t('login.passwordLabel') + '*'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            helperText={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton type="submit">
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvSetPasswordDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 500px !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const StyledDialogDescription = styled(DialogContentText)`
  && {
    max-width: 30rem;
    text-align: center;
    margin: 0 auto 1rem auto;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;
