import {
  OvTableData,
  OvTableRowData,
} from '../../../components/UI/molecules/OvTable';
import Colours from '../../../design-system/colours';
import { Constants } from '../../constants';
import { ProductPrice } from '../../model/dto/product/product-price';
import StringUtils from './string-utils';

export class ProductPriceDataUtils {
  static mapProductPriceDataToTable(productPrice?: ProductPrice): OvTableData {
    const nestedRows: OvTableRowData[] = [
      {
        label: 'productPrices.fields.recentPrice',
        value: productPrice?.discount_details?.recent_price,
        translateLabel: true,
      },
      {
        label: 'productPrices.fields.badgeText',
        value: productPrice?.discount_details?.badge_text,
        translateLabel: true,
      },
      {
        label: 'productPrices.fields.badgeTextColor',
        value: productPrice?.discount_details?.badge_text_color,
        translateLabel: true,
      },
      {
        label: 'productPrices.fields.badgeBackgroundColor',
        value: productPrice?.discount_details?.badge_background_color,
        translateLabel: true,
      },
    ];

    const tableData = [
      {
        label: 'productPrices.fields.cta',
        translateLabel: true,
        value: productPrice?.cta,
      },
      {
        label: 'productPrices.fields.isActive',
        translateLabel: true,
        value: productPrice?.active,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          productPrice?.active
        ),
      },
      {
        label: 'productPrices.fields.priceDisplay',
        translateLabel: true,
        value: productPrice?.price_display,
      },
      {
        label: 'productPrices.fields.priceTitle',
        translateLabel: true,
        value: productPrice?.price_title,
      },
      {
        label: 'productPrices.fields.sku',
        translateLabel: true,
        value: productPrice?.sku,
      },
      {
        label: 'productPrices.fields.isPaymentLinkActive',
        translateLabel: true,
        value: productPrice?.is_payment_link_active,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          productPrice?.is_payment_link_active
        ),
      },
      {
        label: 'productPrices.fields.stripePaymentLink',
        translateLabel: true,
        value: productPrice?.stripe_payment_link_id,
        linkUrl:
          `https://dashboard.stripe.com/${
            !Constants.IS_PRODUCTION ? 'test/' : ''
          }payment-links/${productPrice?.stripe_payment_link_id}` ?? undefined,
      },
      {
        label: 'productPrices.fields.redirectUrlAfterSuccessfulPayment',
        translateLabel: true,
        value: productPrice?.payment_link_redirect_url,
        linkUrl: productPrice?.payment_link_redirect_url ?? undefined,
      },
      {
        label: 'productPrices.fields.stripePaymentLinkUrl',
        translateLabel: true,
        value: productPrice?.payment_link_url,
        linkUrl: productPrice?.payment_link_url ?? undefined,
      },
      {
        label: 'productPrices.fields.isB2b',
        translateLabel: true,
        value: productPrice?.is_b2b,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          productPrice?.is_b2b
        ),
      },
      {
        label: 'productPrices.fields.isBoosterKit',
        translateLabel: true,
        value: productPrice?.is_booster_kit,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          productPrice?.is_booster_kit
        ),
      },
      {
        label: 'productPrices.fields.order',
        translateLabel: true,
        value: productPrice?.order,
      },
      {
        label: 'productPrices.fields.description',
        translateLabel: true,
        value: productPrice?.description,
        displayAsHTML: true,
      },
      {
        label: 'productPrices.fields.image',
        translateLabel: true,
        value: productPrice?.image_url,
        imageUrl: productPrice?.image_url,
      },
      {
        label: 'productPrices.fields.thumbnailImage',
        translateLabel: true,
        value: productPrice?.thumbnail_image_url,
        imageUrl: productPrice?.thumbnail_image_url,
      },
      {
        label: 'productPrices.fields.type',
        translateLabel: true,
        value: productPrice?.type,
      },
      {
        label: 'productPrices.fields.discountDetails',
        translateLabel: true,
        value: !!productPrice?.discount_details,
        style: {
          backgroundColor: Colours.OV_LIGHT_GRAY,
        },
        nestedRows,
        showNestedRows: !!productPrice?.discount_details,
      },
    ];

    if (productPrice?.type === 'recurring') {
      tableData.push(
        {
          label: 'productPrices.fields.interval',
          translateLabel: true,
          value: productPrice?.recurring?.interval,
        },
        {
          label: 'productPrices.fields.intervalCount',
          translateLabel: true,
          value: StringUtils.displayValue(
            `${productPrice?.recurring?.interval_count}`
          ),
        }
      );
    }

    return tableData;
  }
}
