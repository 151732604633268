import React, { FC } from 'react';
import { Address } from '../../../common/model/dto/address';
import * as Yup from 'yup';
import 'yup-phone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { Field, Form, Formik } from 'formik';
import OvFormObserver from '../atoms/OvFormObserver';
import OvTextFieldFormControl from '../atoms/OvTextFieldFormControl';
import OvPhoneFormControl from '../atoms/OvPhoneFormControl';
import { Regexes } from '../../../common/model/type/regexes.type';

export interface OvAddressControlProps {
  onChangeAddress: (address: Address, isValid: boolean) => void;
  address?: Address;
}

const OvAddressControl: FC<OvAddressControlProps> = ({
  address,
  onChangeAddress,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        company_name: address?.company_name,
        first_name: address?.first_name,
        last_name: address?.last_name,
        address1: address?.address1,
        address2: address?.address2,
        city: address?.city,
        postal_code: address?.postal_code,
        region: address?.region,
        region_code: address?.region_code,
        country: address?.country,
        country_code: address?.country_code,
        phone: address?.phone,
      }}
      enableReinitialize={true}
      onSubmit={(values) => console.log(values)}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required(
          t('orders.address.errors.missingFirstName')
        ),
        last_name: Yup.string().required(
          t('orders.address.errors.missingLastName')
        ),
        address1: Yup.string().required(t('orders.address.errors.address1')),
        country: Yup.string().required(t('orders.address.errors.country')),
        country_code: Yup.string().required(
          t('orders.address.errors.countryCode')
        ),
        postal_code: Yup.string()
          .required(t('orders.address.errors.postalCode'))
          .matches(Regexes.zipCode, t('orders.address.errors.invalidZipCode')),
        phone: Yup.string()
          .required()
          .phone(undefined, undefined, t('orders.address.errors.invalidPhone')),
        region: Yup.string().required(),
        region_code: Yup.string()
          .required()
          .matches(
            Regexes.regionCode,
            t('orders.address.errors.invalidRegionCode')
          ),
        city: Yup.string().required(),
      })}
      validateOnBlur={true}
    >
      <Form>
        <Field
          component={StyledTextField}
          type="text"
          name="first_name"
          placeholder={t('orders.address.firstName')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="last_name"
          placeholder={t('orders.address.lastName')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="address1"
          placeholder={t('orders.address.address1')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="address2"
          placeholder={t('orders.address.address2')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="city"
          placeholder={t('orders.address.city')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="country"
          placeholder={t('orders.address.country')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="country_code"
          placeholder={t('orders.address.countryCode')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="region"
          placeholder={t('orders.address.region')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="region_code"
          placeholder={t('orders.address.regionCode')}
        />
        <Field
          component={StyledTextField}
          type="text"
          name="postal_code"
          placeholder={t('orders.address.postalCode')}
        />
        <StyledPhoneControlWrapper>
          <Field
            component={OvPhoneFormControl}
            type="phone"
            name="phone"
            placeholder={t('orders.address.phone')}
          />
        </StyledPhoneControlWrapper>

        <Field
          component={StyledTextField}
          type="text"
          name="company_name"
          placeholder={t('orders.address.companyName')}
        />

        <OvFormObserver valuesChanged={onChangeAddress} />
      </Form>
    </Formik>
  );
};

export default OvAddressControl;

const StyledTextField = styled(OvTextFieldFormControl)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      display: block;
      width: 100%;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;

const StyledPhoneControlWrapper = styled.div`
  margin-bottom: 0.75rem;

  .react-international-phone-country-selector-button,
  .react-international-phone-input {
    height: 3rem !important;
    border: 1px solid ${Colours.OV_BASE};
  }

  .react-international-phone-input {
    flex-grow: 1;
  }
`;
