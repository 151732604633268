import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {rootReducer} from './reducers/root.reducer';

// @ts-ignore please leave the ts ignore for now for store and state related config files
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// @ts-ignore please leave the ts ignore for now for store and state related config files
export const initialRootState = {
  ...store.getState(),
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
