import React, { FC } from 'react';
import QrCode, { QRCodeProps } from 'react-qr-code';

export interface OvQrCodeImageProps {
  options: QRCodeProps;
}

const OvQrCodeImage: FC<OvQrCodeImageProps> = ({ options }) => {
  return <QrCode value={options?.value} size={options.size} />;
};

export default OvQrCodeImage;
