import { Box, Input, MenuItem, Popover, Select, SxProps } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import { FC, useEffect, useRef, useState } from 'react';
import { Range } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import { CalendarDayStatusUtils } from '../../../common/utils/services/calendar-day-status-utils';
import DateUtils from '../../../common/utils/services/date-utils';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import OvDayStatusHighlight from '../atoms/OvDayStatusHighlight';
import OvDateRangePicker from './OvDateRangePicker';
import { DailyData } from '../../../common/model/dto/daily-data';

const useStyles = (): { [key: string]: SxProps } => ({
  selectRoot: {
    backgroundColor: Colours.WHITE,
    borderRadius: '0.5rem !important',
    padding: '0',
  },
  selectIcon: {
    marginRight: 3,
    color: Colours.OV_BASE,
  },
  menuItemRoot: {
    height: '2.5rem',
  },
});

const OvUserChartsDaterangeSelector: FC<{
  selectCycleValue: string;
  defaultText: string;
  setSelectCycleValue: (data: string) => void;
  cycleRange: Range[];
  setCycleRange: (range: Range[]) => void;
  user: UserInfo;
  clinicLocationId?: string;
  loadDailyDataBetweenDates: any;
}> = ({
  selectCycleValue,
  setSelectCycleValue,
  defaultText,
  setCycleRange,
  user,
  cycleRange,
  clinicLocationId,
  loadDailyDataBetweenDates,
}) => {
  const { t } = useTranslation();
  const styleClasses = useStyles();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<any | null>(null);
  const selectRef = useRef();
  const dispatch = useAppDispatch();
  const dailyDataListInCalendar: DailyData[] = useAppSelector(
    (state) => state.dailyData.visibleDailyDataInCalendar
  );
  const patientDailyDataListInCalendar: DailyData[] = useAppSelector(
    (state) => state.clinicLocation.patientDailyDataInCalendar
  );
  const [selectedRange, setSelectedRange] = useState<Range[]>([
    {
      startDate: cycleRange[0].startDate,
      endDate: cycleRange[0].endDate,
      key: 'selection',
    },
  ]);

  const isCycleRangePickerOpened = Boolean(popoverAnchorEl);
  const popoverId = isCycleRangePickerOpened ? 'simple-popover' : undefined;

  useEffect(() => {
    if (isCycleRangePickerOpened) {
      const now = new Date();

      const firstVisibleDay = moment(selectedRange[0]?.startDate ?? now)
        .startOf('month')
        .subtract(20, 'days') // 7days needed because of the layout of the calendar, and another 10 day to count post ovulation days
        .toDate();
      const lastVisibleDay = moment(selectedRange[0]?.endDate ?? now)
        .add(1, 'month')
        .endOf('month')
        .add(7, 'days')
        .toDate();

      if (clinicLocationId) {
        dispatch(
          loadDailyDataBetweenDates({
            clinicLocationId,
            patientId: user.document_id!,
            startDate: firstVisibleDay,
            endDate: lastVisibleDay,
          })
        );
      } else {
        dispatch(
          loadDailyDataBetweenDates({
            userId: user.document_id!,
            dayFrom: firstVisibleDay,
            dayTo: lastVisibleDay,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCycleRangePickerOpened, clinicLocationId]);

  const handleNavigationBetweenMonths = (date: Date) => {
    const firstVisibleDay = moment(date)
      .startOf('month')
      .subtract(20, 'days') // 7days needed because of the layout of the calendar, and another 10 day to count post ovulation days
      .toDate();
    const lastVisibleDay = moment(date)
      .add(1, 'month')
      .endOf('month')
      .add(7, 'days')
      .toDate();

    if (clinicLocationId) {
      dispatch(
        loadDailyDataBetweenDates({
          clinicLocationId,
          patientId: user.document_id!,
          startDate: firstVisibleDay,
          endDate: lastVisibleDay,
        })
      );
    } else {
      dispatch(
        loadDailyDataBetweenDates({
          userId: user.document_id!,
          dayFrom: firstVisibleDay,
          dayTo: lastVisibleDay,
        })
      );
    }
  };

  const handleCloseCycleRangePicker = () => {
    setPopoverAnchorEl(null);
  };

  const onChangeCycleRange = (selectionRange: Range[]) => {
    setSelectedRange(selectionRange);
  };

  const onShownDateChanged = (date: Date) => {
    if (date && user?.document_id) {
      handleNavigationBetweenMonths(date);
    }
  };

  const getLogIndexFromDate = (calendarDate: Date): number => {
    let logIndex = -1;
    const dailyDataArray = clinicLocationId
      ? patientDailyDataListInCalendar
      : dailyDataListInCalendar;

    if (dailyDataArray.length) {
      dailyDataArray.forEach((dailyData, index) => {
        const dailyDataDate: Date = moment(
          dailyData?.day,
          'YYYY-MM-DD'
        ).toDate();

        if (DateUtils.areSameDays(dailyDataDate, calendarDate)) {
          logIndex = index;
        }
      });
    }

    return logIndex;
  };

  const handleOnClick = () => {
    setSelectCycleValue('custom date range');
    setPopoverAnchorEl(null);

    setCycleRange([
      {
        startDate: selectedRange[0].startDate,
        endDate: selectedRange[0].endDate,
      },
    ]);
  };

  return (
    <Container>
      <Select
        ref={selectRef}
        value={selectCycleValue}
        onChange={(e) => {
          if (e.target.value === 'custom date range') {
            return;
          }
          setSelectCycleValue(e.target.value as string);
        }}
        input={<Input disableUnderline fullWidth color="secondary" />}
        IconComponent={KeyboardArrowDown}
        renderValue={() => (
          <Box ml={2} mr={1}>
            <SelectionText>{defaultText}</SelectionText>
          </Box>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }}
      >
        <MenuItem value="last cycle" sx={{ ...styleClasses.menuItemRoot }}>
          <SelectionText>{t('dashboard.lastCycle')}</SelectionText>
        </MenuItem>
        <MenuItem value="30" sx={{ ...styleClasses.menuItemRoot }}>
          <SelectionText>
            {t('dashboard.lastXDays', { numOfDays: 30 })}
          </SelectionText>
        </MenuItem>
        <MenuItem value="60" sx={{ ...styleClasses.menuItemRoot }}>
          <SelectionText>
            {t('dashboard.lastXDays', { numOfDays: 60 })}
          </SelectionText>
        </MenuItem>
        <MenuItem value="90" sx={{ ...styleClasses.menuItemRoot }}>
          <SelectionText>
            {t('dashboard.lastXDays', { numOfDays: 90 })}
          </SelectionText>
        </MenuItem>
        <MenuItem
          value="custom date range"
          onClick={() => {
            setPopoverAnchorEl(selectRef.current);
            return;
          }}
          sx={{ ...styleClasses.menuItemRoot }}
        >
          <SelectionText>{t('dashboard.customDateRange')}</SelectionText>
        </MenuItem>
      </Select>
      <StyledPopover
        id={popoverId}
        open={isCycleRangePickerOpened}
        anchorEl={popoverAnchorEl}
        onClose={handleCloseCycleRangePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <OvDateRangePicker
          ranges={selectedRange}
          onChange={(item) => onChangeCycleRange([item.selection])}
          onShownDateChange={(date) => onShownDateChanged(date)}
          showDateDisplay={false}
          moveRangeOnFirstSelection={false}
          months={2}
          direction="horizontal"
          preventSnapRefocus={true}
          showPreview={true}
          showMonthAndYearPickers={false}
          rangeColors={['#dfdfdf']}
          dayContentRenderer={(date) => (
            <OvDayStatusHighlight
              tryingToConceiveStatus={CalendarDayStatusUtils.calculateDayStatus(
                clinicLocationId
                  ? patientDailyDataListInCalendar
                  : dailyDataListInCalendar,
                getLogIndexFromDate(date)
              )}
            >
              {date.getDate()}
            </OvDayStatusHighlight>
          )}
        />
        <StyledButton
          onClick={handleOnClick}
          disabled={
            selectedRange?.[0]?.startDate === undefined ||
            differenceInDays(
              endOfDay(selectedRange[0].endDate!),
              startOfDay(selectedRange[0].startDate)
            ) < 1
          }
        >
          {t('common.submit')}
        </StyledButton>
      </StyledPopover>
    </Container>
  );
};

export default OvUserChartsDaterangeSelector;

const Container = styled.div`
  width: 30%;
  min-width: 15.625rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  border-radius: ${Variables.borderRadius.SMALL};
  margin-bottom: 3.25rem;
  background-color: ${Colours.WHITE};
`;

const SelectionText = styled.p`
  font-weight: bold;
  color: ${Colours.OV_BASE};
  font-size: ${Variables.fontSizes.MEDIUM};
  margin: 0.5rem 0;
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif; // honestly not sure why default styles not applied...
`;

const StyledPopover = styled(Popover)`
  && {
    .MuiPopover-paper {
      border-radius: 0.75rem;
      border: 1px solid ${Colours.OV_BASE};
      display: flex;
      flex-direction: column;
    }
  }
`;

const StyledButton = styled.button`
  background: none;
  width: 6.25rem;
  padding: 0.625rem 0.325rem;
  margin: auto;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  border-radius: 0.75rem;
  color: ${Colours.OV_BASE};
  font-weight: bold;
  border: 2px solid ${Colours.OV_BASE};
  cursor: pointer;

  &:hover {
    background-color: ${Colours.OV_WHITE_HOVER};
  }
`;
