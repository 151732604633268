import React, { FC } from 'react';
import { GenericScanningPlan } from '../../../common/model/dto/scan-sequences/generic-scanning-plan';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { ScanType } from '../../../common/model/dto/scan-sequences/scan-type.enum';
import DateUtils from '../../../common/utils/services/date-utils';
import { addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

const OvGenericScanningPlanTable: FC<{
  genericScannigPlan: GenericScanningPlan;
  startDate?: string;
}> = ({ genericScannigPlan, startDate }) => {
  const { t } = useTranslation();
  const shouldScanHormone = (scanType: ScanType, numberOfDay: number) => {
    const scanningPlanEntry = genericScannigPlan?.scanning_plan_entries.find(
      (entry) => entry.number_of_day === numberOfDay
    );

    return scanningPlanEntry?.scan_types?.includes(scanType);
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          {startDate ? (
            <tr>
              <StyledLabelCellHeader />
              {genericScannigPlan.scanning_plan_entries.map((entry) => (
                <StyledDayHeader
                  key={DateUtils.getDbDateTag(
                    addDays(new Date(startDate), entry.number_of_day)
                  )}
                >
                  <StyledMonthName>
                    {addDays(
                      new Date(startDate),
                      entry.number_of_day
                    ).toLocaleString('default', {
                      month: 'short',
                    })}
                  </StyledMonthName>
                  {addDays(new Date(startDate), entry.number_of_day).getDate()}
                </StyledDayHeader>
              ))}
            </tr>
          ) : (
            <tr>
              <StyledLabelCellHeader />
              {genericScannigPlan.scanning_plan_entries.map((entry, index) => (
                <StyledDayHeader key={index}>
                  {entry.number_of_day}
                </StyledDayHeader>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          <tr key={'lh-pg'}>
            <StyledLabelCell>
              {t('userDetails.scanningPlans.lhPg')}
            </StyledLabelCell>
            {genericScannigPlan.scanning_plan_entries.map((entry, dayIndex) => (
              <StyledDayCell key={dayIndex}>
                {shouldScanHormone(ScanType.LH_PG, entry.number_of_day) && (
                  <StyledLhPgCellContent />
                )}
              </StyledDayCell>
            ))}
          </tr>
          <tr key={'lr-e3g'}>
            <StyledLabelCell>
              {t('userDetails.scanningPlans.lrE3g')}
            </StyledLabelCell>
            {genericScannigPlan.scanning_plan_entries.map((entry, dayIndex) => (
              <StyledDayCell key={dayIndex}>
                {shouldScanHormone(ScanType.LR_E3G, entry.number_of_day) && (
                  <StyledE3gCellContent />
                )}
              </StyledDayCell>
            ))}
          </tr>
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default OvGenericScanningPlanTable;

const StyledTable = styled.table`
  width: auto;
  border-collapse: collapse;
  border: 1px solid #ccc;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  margin: 0 auto;
  display: block;
  padding: 1rem 0;
`;

const StyledLabelCellHeader = styled.th`
  font-weight: lighter;
  font-size: 0.65rem;
  text-align: left;
  width: 1.875rem;
  position: sticky;
  z-index: 1;
  left: -0.125rem;
  background-color: ${Colours.OV_WHITE};
  border-bottom: 1px solid #ccc;
`;

const StyledDayHeader = styled.th`
  background-color: 'none';
  text-align: center;
  font-size: 0.85rem;
  width: 2rem;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 3rem;
`;

const StyledLabelCell = styled.td`
  font-weight: lighter;
  font-size: 0.65rem;
  text-align: left;
  padding: 0.5rem 0.5rem;
  position: sticky;
  z-index: 1;
  left: -0.125rem;
  background-color: ${Colours.OV_WHITE};
  height: 1rem;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
`;

const StyledDayCell = styled.td`
  background-color: 'none';
  text-align: center;
  width: 2rem;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.4rem;
  white-space: initial;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 2rem;
`;

const StyledMonthName = styled.div`
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 0.5rem;
`;

const StyledLhPgCellContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-image: linear-gradient(
    to right,
    ${Colours.LIGHT_LEMON} 42%,
    ${Colours.OV_BASE} 42%,
    ${Colours.OV_BASE} 58%,
    ${Colours.BRIGHT_YELLOW} 58%
  );
  background-size: 100% 100%;
  border: 0.2rem solid ${Colours.OV_BASE};
`;

const StyledE3gCellContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-color: ${Colours.OV_LIGHT_GRAY};
  border: 0.2rem solid ${Colours.OV_BASE};
`;
