import React, { FC, useState } from 'react';
import { CreateNotificationContentRequest } from '../../../common/model/dto/notification/create-notification-content-request';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  NotificationButtonStyle,
  NotificationContent,
  NotificationContentCategory,
} from '../../../common/model/dto/notification/notification-content';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';

enum NotificationContentDevicePreviewScreen {
  LIST = 'list',
  DETAILS = 'details',
}

interface OvNotificationContentDevicePreviewProps {
  notificationContentDetails:
    | CreateNotificationContentRequest
    | NotificationContent;
}

const OvNotificationContentDevicePreview: FC<
  OvNotificationContentDevicePreviewProps
> = ({ notificationContentDetails: createNotificationContentRequest }) => {
  const { t } = useTranslation();
  const [activeScreen, setActiveScreen] =
    useState<NotificationContentDevicePreviewScreen>(
      NotificationContentDevicePreviewScreen.LIST
    );

  const handleClick = (url?: string) => {
    window.open(url, '_blank');
  };

  const getNotificationContentProps = () => {
    switch (createNotificationContentRequest.category) {
      case NotificationContentCategory.FROM_OOVA:
        return {
          notificationContentColor: Colours.OV_PERIOD_PINK,
          notificationContentCategoryDisplay: 'From oova',
        };
      case NotificationContentCategory.APP_UPDATES:
        return {
          notificationContentColor: Colours.OV_LIGHT_GRAY,
          notificationContentCategoryDisplay: 'App',
        };
      case NotificationContentCategory.BLOG_POST:
        return {
          notificationContentColor: Colours.PALE_LIME,
          notificationContentCategoryDisplay: 'News',
        };
      case NotificationContentCategory.OFFERS:
        return {
          notificationContentColor: Colours.OV_DARK_GREEN,
          notificationContentCategoryDisplay: 'Offers',
        };
      case NotificationContentCategory.STUDIES:
        return {
          notificationContentColor: Colours.OV_ORDER_CREATED,
          notificationContentCategoryDisplay: 'Studies',
        };
    }
  };

  const getButtonStyle = (
    buttonStyle: NotificationButtonStyle
  ): { backgroundColor: string; color: string; borderColor: string } => {
    switch (buttonStyle) {
      case NotificationButtonStyle.PRIMARY:
        return {
          backgroundColor: Colours.OV_BASE,
          color: Colours.OV_WHITE,
          borderColor: Colours.OV_BASE,
        };
      case NotificationButtonStyle.SECONDARY:
        return {
          backgroundColor: 'transparent',
          color: Colours.OV_BASE,
          borderColor: Colours.OV_BASE,
        };
      case NotificationButtonStyle.DESTRUCTIVE:
        return {
          backgroundColor: Colours.OV_RED,
          color: Colours.OV_WHITE,
          borderColor: Colours.OV_RED,
        };
    }
  };

  const notificationContentProps = getNotificationContentProps();

  return (
    <Container>
      {activeScreen === NotificationContentDevicePreviewScreen.LIST && (
        <>
          <header>
            <ScreenTitle>
              {t('notificationContents.preview.screenTitle')}
            </ScreenTitle>
          </header>
          <FilterContainer>
            <Filter style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
              <StyledText>
                {t('notificationContents.preview.filters.all', {
                  numOfNotifications: 1,
                })}
              </StyledText>
            </Filter>
            <Filter style={{ backgroundColor: 'rgba(0, 40, 70, 0.05)' }}>
              <StyledText>
                {t('notificationContents.preview.filters.unread', {
                  numOfNotifications: 1,
                })}
              </StyledText>
            </Filter>
          </FilterContainer>
          <DividerLine style={{ marginTop: '1rem' }} />
          <ContentContainer>
            <ContentDayTitle>
              {t('notificationContents.preview.today')}
            </ContentDayTitle>
            <DividerLine />
            <ContentWrapper
              onClick={() =>
                setActiveScreen(NotificationContentDevicePreviewScreen.DETAILS)
              }
            >
              <NotificationIconWrapper>
                <StyledSmsIconWrapper
                  style={{
                    backgroundColor:
                      notificationContentProps?.notificationContentColor,
                  }}
                >
                  <TextsmsOutlinedIcon />
                </StyledSmsIconWrapper>
              </NotificationIconWrapper>
              <NotificationContentWrapper>
                <NotificationContentTitle>
                  {createNotificationContentRequest.title}
                </NotificationContentTitle>
                <NotificationContentDescription>
                  {createNotificationContentRequest.short_description}
                </NotificationContentDescription>
                <NotificationContentCategoryWrapper
                  style={{
                    backgroundColor:
                      notificationContentProps?.notificationContentColor,
                  }}
                >
                  <NotificationContentCategoryText>
                    {
                      notificationContentProps?.notificationContentCategoryDisplay
                    }
                  </NotificationContentCategoryText>
                </NotificationContentCategoryWrapper>
              </NotificationContentWrapper>
              <DetailsIconWrapper>
                <StyledArrowForwardIcon />
              </DetailsIconWrapper>
            </ContentWrapper>
            <DividerLine />
          </ContentContainer>
        </>
      )}

      {activeScreen === NotificationContentDevicePreviewScreen.DETAILS && (
        <>
          <HeaderContainer
            style={{
              borderLeft: `30px solid ${notificationContentProps?.notificationContentColor}`,
              borderRight: `30px solid ${notificationContentProps?.notificationContentColor}`,
              borderBottom: `30px solid ${notificationContentProps?.notificationContentColor}`,
              borderTop: 'none',
            }}
          >
            <StyledArrowBackIcon
              onClick={() =>
                setActiveScreen(NotificationContentDevicePreviewScreen.LIST)
              }
            />
            <StyledImageWrapper>
              <StyledImage
                src={
                  createNotificationContentRequest?.image_url ??
                  '/images/oova-product-image.png'
                }
                alt={createNotificationContentRequest?.title}
              />
            </StyledImageWrapper>
          </HeaderContainer>
          <ContentContainer>
            <StyledDetailsScreenTitle>
              {createNotificationContentRequest?.title}
            </StyledDetailsScreenTitle>
            <StyledDetailsScreenDescription>
              {createNotificationContentRequest?.short_description}
            </StyledDetailsScreenDescription>
            <NotificationContentCategoryContainer>
              <NotificationContentCategoryWrapper
                style={{
                  backgroundColor:
                    notificationContentProps?.notificationContentColor,
                }}
              >
                <NotificationContentCategoryText>
                  {notificationContentProps?.notificationContentCategoryDisplay}
                </NotificationContentCategoryText>
              </NotificationContentCategoryWrapper>
              <StyledTimestamp>1h ago</StyledTimestamp>
            </NotificationContentCategoryContainer>
            <StyledDetailsScreenDescription
              style={{ marginTop: '1rem' }}
              dangerouslySetInnerHTML={{
                __html:
                  createNotificationContentRequest?.full_description as any,
              }}
            ></StyledDetailsScreenDescription>
            <DividerLine style={{ marginBottom: '1rem', marginTop: '2rem' }} />
            {createNotificationContentRequest?.actions?.map((action) => {
              const buttonStyle = getButtonStyle(action.button_style);

              return (
                <StyledOvButton
                  style={{
                    backgroundColor: buttonStyle.backgroundColor,
                    marginBottom: '1rem',
                    color: buttonStyle.color,
                    borderColor: buttonStyle.borderColor,
                  }}
                  onClick={
                    action.button_style === NotificationButtonStyle.PRIMARY
                      ? () => handleClick(action.action_url)
                      : undefined
                  }
                >
                  {action.cta}
                </StyledOvButton>
              );
            })}
            <StyledNotificationContentDisclaimer>
              {createNotificationContentRequest.disclaimer}
            </StyledNotificationContentDisclaimer>
          </ContentContainer>
        </>
      )}
    </Container>
  );
};

export default OvNotificationContentDevicePreview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
`;

const ScreenTitle = styled.h4`
  color: ${Colours.OV_BASE};
  margin: 0 0 0.75rem 0;
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
`;

const Filter = styled.div`
  padding: 0.5rem 1rem;
  border-radius: ${Variables.borderRadius.MEDIUM};
`;

const StyledText = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
  color: ${Colours.OV_BASE};
  font-weight: 500;
  font-size: 12px;
`;

const DividerLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${Colours.OV_BORDER_COLOR_V3};
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const ContentDayTitle = styled.h5`
  font-weight: 500;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  width: 100%;
`;

const NotificationIconWrapper = styled.div`
  flex: 1;
`;

const NotificationContentWrapper = styled.div`
  flex: 4;
`;

const NotificationContentTitle = styled.h6`
  margin: 0;
`;

const NotificationContentDescription = styled.p`
  margin-top: 0.25rem;
  font-size: 9px;
`;

const NotificationContentCategoryWrapper = styled.div`
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
`;

const NotificationContentCategoryText = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: bolder;
`;

const DetailsIconWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledSmsIconWrapper = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Variables.borderRadius.CIRCLE};
`;

const StyledArrowForwardIcon = styled(ArrowForwardIosOutlinedIcon)`
  && {
    color: ${Colours.OV_BORDER_COLOR_V3};
    font-size: 16px;
    font-weight: bold;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  right: 20%;
  width: 23.5rem;
  height: 15rem;
  border-bottom-left-radius: 15rem;
  border-bottom-right-radius: 15rem;
  border-top: 'none';
  background-color: transparent;
  margin: auto;
  overflow: hidden;
`;

const StyledImageWrapper = styled.div``;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  border-radius: ${Variables.borderRadius.MEDIUM};
  object-fit: contain;
  margin: auto;
`;

const StyledDetailsScreenTitle = styled.h5`
  margin: 0.5rem 0;
`;

const StyledDetailsScreenDescription = styled.p`
  margin: 0 0 0.5rem 0;
  font-size: 11px;
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  && {
    position: absolute;
    left: 2rem;
    top: 1rem;
  }
`;

const NotificationContentCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledTimestamp = styled.p`
  margin: 0;
  color: ${Colours.OV_BORDER_COLOR_V3};
  font-weight: bold;
  font-size: 12px;
`;

const StyledOvButton = styled(OvButton)`
  && {
    width: 100%;
    border-radius: ${Variables.borderRadius.XLARGE};
  }
`;

const StyledNotificationContentDisclaimer = styled.p`
  margin: 0;
  font-size: 9px;
  text-align: center;
  width: 100%;
`;
