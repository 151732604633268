import { JournalEntry } from './journal-entry';

export interface UserSpecificBaselines {
  period_journal_entries?: JournalEntry[];

  period_cycles?: PeriodCycle[];

  lh_scan_results?: UserScanResult[];

  pg_scan_results?: UserScanResult[];

  lr_e3g_scan_results?: UserScanResult[];

  aggregate_hormone_data?: AggregateHormoneData;
}

export interface CycleBaselines {
  lh_max?: UserScanResult;

  pg_max?: UserScanResult;

  lr_e3g_max?: UserScanResult;

  cycle_length?: number;

  length_of_follicular_phase?: number;

  follicular_phase_start_day?: string;

  follicular_phase_end_day?: string;

  length_of_luteal_phase?: number;

  luteal_phase_start_day?: string;

  luteal_phase_end_day?: string;

  number_of_high_lh_days?: number;

  days_between_lh_max_and_pg_max?: number;

  cycle_status?: 'ovulatory' | 'anovulatory';

  days_since_period_cycle_end?: number;
}

export interface PeriodCycle {
  start_day?: string;

  end_day?: string;

  cycleBaseLines?: CycleBaselines;
}

export interface UserScanResult {
  day: string;

  value: number;

  secondary_values?: UserScanResult[];
}

export enum BaselineQueryMode {
  LastThreeCycles = 'last_three_cycles',
  LastTwoCycles = 'last_two_cycles',
  LastCycle = 'last_cycle',
  CustomDateRange = 'custom_date_range',
}

export class AggregateHormoneData {
  average_max_lh?: number;

  median_max_lh?: number;

  max_lh?: number;

  average_max_pg?: number;

  median_max_pg?: number;

  max_pg?: number;

  average_max_lr_e3g?: number;

  median_max_lr_e3g?: number;

  max_lr_e3g?: number;
}
