import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';

export interface OvFormObserverProps<T> {
  valuesChanged: (values: T, isValid: boolean) => void;
}

const OvFormObserver: FC<OvFormObserverProps<any>> = ({ valuesChanged }) => {
  const { values, isValid } = useFormikContext();

  useEffect(() => {
    valuesChanged(values, isValid);
    // eslint-disable-next-line
  }, [values]);

  return null;
};

export default OvFormObserver;
