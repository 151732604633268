import React, { FC } from 'react';
import { Navigate, NavigateAction, ToolbarProps } from 'react-big-calendar';
import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Colours from '../../../design-system/colours';
import { IconButton } from '@mui/material';
import Variables from '../../../design-system/variables';

const OvCalendarToolbar: FC<ToolbarProps> = ({ label, onNavigate }) => {
  const navigate = (action: NavigateAction) => {
    onNavigate(action);
  };

  return (
    <Toolbar>
      <StyledIconButton onClick={() => navigate(Navigate.PREVIOUS)}>
        <ChevronLeftIcon style={{ position: 'absolute', left: '2px' }} />
      </StyledIconButton>
      <DateLabel>{label}</DateLabel>
      <StyledIconButton onClick={() => navigate(Navigate.NEXT)}>
        <ChevronRightIcon style={{ position: 'absolute', right: '2px' }} />
      </StyledIconButton>
    </Toolbar>
  );
};

export default OvCalendarToolbar;

const Toolbar = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
  margin: 0 auto;
  width: 100%;
  background-color: ${Colours.OV_GRAY};
`;

const DateLabel = styled.span`
  margin: 0 1.5rem;
  text-align: center;
  min-width: 8.75rem;
  font-size: ${Variables.fontSizes.MEDIUM};
  font-weight: bold;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    &:hover {
      background-color: transparent;

      .MuiSvgIcon-root {
        fill: ${Colours.OV_BASE_HOVER};
      }
    }

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
