import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';

export default function usePaginationQueryParams() {
  const [offset, setOffset] = useQueryParam(
    'offset',
    withDefault(NumberParam, null)
  );
  const [orderBy, setOrderBy] = useQueryParam(
    'order_by',
    withDefault(StringParam, '')
  );
  const [orderDir, setOrderDir] = useQueryParam(
    'order_dir',
    withDefault(StringParam, '')
  );

  return {
    offset,
    orderBy,
    orderDir,
    setOffset,
    setOrderBy,
    setOrderDir,
  };
}
