import { FilterType } from '../../common/model/ui/filter.type';
import { OrdersState } from '../states/orders.state';
import { RootState } from '../store';

const hasAppliedFiltersSelector = (state: RootState): boolean => {
  const ordersState = state.orders as OrdersState;

  return ordersState.filters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

export { hasAppliedFiltersSelector };
