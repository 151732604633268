import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { Box } from '@mui/material';
import { HormoneValueType } from '../../../common/model/type/hormone-values.type';
import { Constants } from '../../../common/constants';

export interface OvBaselinesHormoneChartTooltipProps {
  active: boolean;
  payload: any;
  hormoneType: HormoneValueType;
}

const OvBaselinesHormoneChartTooltip: FC<
  OvBaselinesHormoneChartTooltipProps
> = ({ active, payload, hormoneType }) => {
  const dataPointPayload =
    active === true && !!payload ? payload[0]?.payload : null;

  const getUnitOfMeasure = () => {
    switch (hormoneType) {
      case HormoneValueType.LH:
        return Constants.LH_UNIT_OF_MEASURE;
      case HormoneValueType.PG:
        return Constants.PG_UNIT_OF_MEASURE;
      case HormoneValueType.LR_E3G:
        return Constants.E3G_UNIT_OF_MEASURE;
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding="0.5rem 0.75rem"
        bgcolor={Colours.CD_TOOLTIP_HEADER}
        color={Colours.OV_WHITE}
        fontSize={Variables.fontSizes.SMALL}
        style={{
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <StyledLabel style={{ opacity: 1 }}>
          {dataPointPayload?.day}
        </StyledLabel>
      </Box>
      <Box
        padding="0.5rem 0.75rem"
        bgcolor={Colours.OV_BASE}
        color={Colours.OV_WHITE}
        fontSize={Variables.fontSizes.MEDIUM}
        fontWeight="bold"
        style={{
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <StyledLabel>{`${
          dataPointPayload?.value
        } ${getUnitOfMeasure()}`}</StyledLabel>
      </Box>
    </>
  );
};

export default OvBaselinesHormoneChartTooltip;

const StyledLabel = styled.label`
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif !important;
`;
