import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import OvImageUploadDialog from './OvImageUploadDialog';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import ImageIcon from '@mui/icons-material/Image';
import OvLinkButton from '../atoms/OvLinkButton';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface OvImageUploadProps {
  imageBasePath: string;
  imageUrl?: string;
  title?: string;
  ctaLabel?: string;
  hintText?: string;
  dialogTitle: string;
  handleChange: (updatedProperties: any) => void;
  resizeOptions?: {
    resizedImagePath: string;
    maxWidth: number;
    maxHeight: number;
    quailty: number;
  };
}

const OvImageUpload: FC<OvImageUploadProps> = ({
  imageBasePath,
  title,
  imageUrl,
  ctaLabel,
  hintText,
  dialogTitle,
  handleChange,
  resizeOptions,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const previewImageStyles: React.CSSProperties = {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    borderRadius: '1.25rem',
    maxWidth: '18rem',
  };

  useEffect(() => {
    if (imageUrl) {
      setImagePath(imageUrl);
    }
  }, [imageUrl]);

  const onSuccessfulUpload = ({
    image_path,
    resized_image_path,
  }: {
    image_path: string;
    resized_image_path?: string;
  }) => {
    setImagePath(image_path);

    if (resized_image_path) {
      handleChange({ image_path, resized_image_path });
    } else {
      handleChange({ image_path });
    }
  };

  const onRemoveUploadedImage = () => {
    setImagePath('');
    handleChange('');
  };

  return (
    <>
      <Title>{title}</Title>

      {imagePath ? (
        <ImageWrapper>
          <RemoveImageIconButton onClick={onRemoveUploadedImage}>
            <DeleteIcon />
          </RemoveImageIconButton>
          <img src={imagePath} alt={title} style={previewImageStyles} />
        </ImageWrapper>
      ) : (
        <Wrapper>
          <StyledImage />

          <UploadSection>
            <OvLinkButton onClick={() => setIsDialogOpen(true)}>
              {ctaLabel}
            </OvLinkButton>
            <HintText>{hintText}</HintText>
          </UploadSection>
        </Wrapper>
      )}

      <OvImageUploadDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        title={dialogTitle}
        imageBasePath={imageBasePath}
        onUploadSuccess={onSuccessfulUpload}
        previewImageStyles={previewImageStyles}
        resizeOptions={resizeOptions}
      />
    </>
  );
};

export default OvImageUpload;

const Title = styled.h4`
  font-size: ${Variables.fontSizes.MEDIUM};
  text-indent: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  border: 2px dashed rgba(78, 92, 101, 0.46);
  padding: 1.25rem;
  border-radius: ${Variables.borderRadius.MEDIUM};
`;

const StyledImage = styled(ImageIcon)`
  && {
    color: ${Colours.BLUE['300']};
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
`;

const HintText = styled.p`
  margin: 0;
  color: ${Colours.TEXT_DARK['500']};
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const ImageWrapper = styled.div`
  max-width: 8.5rem;
  border-radius: 0.75rem;
  object-fit: cover;
  position: relative;
`;

const RemoveImageIconButton = styled(IconButton)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    width: 1.5rem;
    height: 1.5rem;
    color: ${Colours.BLUE['500']};
    font-size: 1.5rem;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    cursor: pointer;
    padding: 0;

    &:hover {
      background-color: white;
      color: ${Colours.BLUE['700']};
    }

    .MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
