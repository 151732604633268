import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Variables from '../../../design-system/variables';
import WatchIcon from '@mui/icons-material/Watch';

const OvCalendarInfo: FC<{
  theme?: 'light' | 'dark';
  showScanIcons?: boolean;
}> = ({ theme, showScanIcons, ...props }) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      {...props}
      style={{
        backgroundColor: theme === 'dark' ? Colours.OV_BASE : 'transparent',
      }}
    >
      <Info>
        <ColorizedCircle
          style={{ backgroundColor: Colours.OV_PINK }}
        ></ColorizedCircle>
        <Separator />
        <InfoLabel>{t('calendarInfo.period')}</InfoLabel>
      </Info>

      <Info>
        <ColorizedCircle
          style={{ backgroundColor: Colours.OV_FERTILE }}
        ></ColorizedCircle>
        <Separator />
        <InfoLabel>{t('calendarInfo.fertile')}</InfoLabel>
      </Info>

      <Info>
        <ColorizedCircle
          style={{ backgroundColor: Colours.OV_HIGH_TRYING_TO_CONCEIVE }}
        ></ColorizedCircle>
        <Separator />
        <InfoLabel>{t('calendarInfo.highFertility')}</InfoLabel>
      </Info>

      <Info>
        <ColorizedCircle
          style={{ backgroundColor: Colours.OV_HIGH_TRYING_TO_CONCEIVE }}
        ></ColorizedCircle>
        <Separator />
        <InfoLabel>{t('calendarInfo.peak')}</InfoLabel>
      </Info>

      <Info>
        <ColorizedCircle
          style={{ backgroundColor: Colours.POST_OVULATION }}
        ></ColorizedCircle>
        <Separator />
        <InfoLabel>{t('calendarInfo.postOvulation')}</InfoLabel>
      </Info>

      {showScanIcons ? (
        <>
          <Info>
            <ColorizedCircle
              style={{ backgroundColor: Colours.OV_BORDER_COLOR_V3 }}
            >
              2
            </ColorizedCircle>
            <Separator />
            <InfoLabel>{t('calendarInfo.numberOfScans')}</InfoLabel>
          </Info>
          <Info>
            <IconContainer>
              <StyledLibraryBooksIcon />
            </IconContainer>
            <Separator />
            <InfoLabel>{t('calendarInfo.journalEntry')}</InfoLabel>
          </Info>

          <Info>
            <IconContainer>
              <StyledImage
                src="/images/test-strip-white.png"
                alt={t('common.altText.testStrip')}
              />
            </IconContainer>
            <Separator />
            <InfoLabel>{t('calendarInfo.scan')}</InfoLabel>
          </Info>
          <Info>
            <IconContainer>
              <WatchIcon />
            </IconContainer>
            <Separator />
            <InfoLabel>{t('calendarInfo.wearableInfo')}</InfoLabel>
          </Info>
        </>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default OvCalendarInfo;

const Wrapper = styled.div`
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ColorizedCircle = styled.div`
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colours.OV_BASE};
`;

const Separator = styled.hr`
  border-style: dotted;
  border-top-width: 0.25rem;
  height: 0.25rem;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  flex-grow: 1;
  border-color: ${Colours.OV_GRAY};
  margin: 0 1.5rem 0 0;
`;

const InfoLabel = styled.span`
  color: ${Colours.OV_SEMI_LIGHT};
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
`;

const StyledImage = styled.img`
  max-height: 1.25rem;
  max-width: 1.25rem;
`;

const StyledLibraryBooksIcon = styled(LibraryBooksIcon)`
  && {
    color: ${Colours.OV_WHITE};
  }
`;
