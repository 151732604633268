import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ProductPrice } from '../../common/model/dto/product/product-price';
import {
  addPriceToProductAndGoToDetails,
  getProductPriceById,
  updateProductPriceAndGoToDetails,
} from '../../redux/thunks/admin/admin-product-price.thunk';
import {
  clearError,
  clearSelectedProductPrice,
} from '../../redux/reducers/admin-product-price.slice';
import OvProductPriceInfo, {
  ProductPriceInfo,
} from '../UI/molecules/OvProductPriceInfo';
import { UpdateProductPriceRequest } from '../../common/model/dto/product/update-product-price-request';
import styled from 'styled-components';
import breakpoints from '../../design-system/breakpoints';
import Variables from '../../design-system/variables';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import OvBackButton from '../UI/atoms/OvBackButton';
import { useTranslation } from 'react-i18next';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import OvImageUpload from '../UI/molecules/OvImageUpload';
import {
  PRICE_IMAGES_PATH,
  PRICE_THUMBNAIL_IMAGES_PATH,
} from '../../api/firebase-storage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import { AddPriceToProductRequest } from '../../common/model/dto/product/add-price-to-product-request';

const ProductPriceForm: FC = () => {
  const { t } = useTranslation();
  let { productId, productPriceId }: any = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedProductPrice: ProductPrice | undefined = useAppSelector(
    (state) => state.adminProductPrice.selectedProductPrice
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.adminProductPrice.isLoading
  );
  const errorMessage: string = useAppSelector(
    (state) => state.adminProductPrice.error
  );
  const [productPriceInfo, setProductPriceInfo] = useState<{
    values: ProductPriceInfo;
    isValid: boolean;
  }>();
  const [priceImagePath, setPriceImagePath] = useState<string>();
  const [priceThumbnailImagePath, setPriceThumbnailImagePath] =
    useState<string>();

  useEffect(() => {
    if (productPriceId) {
      dispatch(getProductPriceById(productPriceId));
    }

    return () => {
      dispatch(clearSelectedProductPrice());
    };
  }, [productPriceId, dispatch]);

  useEffect(() => {
    if (selectedProductPrice) {
      initForm();
    }

    return () => {
      dispatch(clearError());
    };
    // eslint-disable-next-line
  }, [dispatch, selectedProductPrice]);

  const handleProductPriceInfoChange = (
    productPriceInfo: ProductPriceInfo,
    isValid: boolean
  ) => {
    setProductPriceInfo({
      values: productPriceInfo,
      isValid,
    });
  };

  const handleSuccessfulPriceImageUpload = ({
    image_path,
  }: {
    image_path: string;
  }) => {
    setPriceImagePath(image_path);
  };

  const handleSuccessfulPriceThumbnailImageUpload = ({
    image_path,
  }: {
    image_path: string;
  }) => {
    setPriceThumbnailImagePath(image_path);
  };

  const initForm = () => {
    setProductPriceInfo({
      values: {
        price_title: selectedProductPrice?.price_title,
        price_amount: selectedProductPrice?.unit_amount! / 100,
        description: selectedProductPrice?.description,
        recurring_price_properties: selectedProductPrice?.recurring,
        stripe_checkout_redirect_url:
          selectedProductPrice?.payment_link_redirect_url,
        is_b2b: !!selectedProductPrice?.is_b2b,
        cta: selectedProductPrice?.cta,
        sku: selectedProductPrice?.sku,
        is_booster_kit: selectedProductPrice?.is_booster_kit,
        order: selectedProductPrice?.order,
        discount_details: selectedProductPrice?.discount_details,
      },
      isValid: true,
    });

    setPriceImagePath(selectedProductPrice?.image_url);
    setPriceThumbnailImagePath(selectedProductPrice?.thumbnail_image_url);
  };

  const saveProductPrice = () => {
    if (canSaveProductPrice()) {
      if (selectedProductPrice) {
        const updateProductPriceRequest: UpdateProductPriceRequest = {
          cta: productPriceInfo?.values?.cta,
          price_amount: productPriceInfo?.values.price_amount,
          price_title: productPriceInfo?.values.price_title,
          sku: productPriceInfo?.values.sku,
          payment_link_redirect_url:
            productPriceInfo?.values.stripe_checkout_redirect_url,
          is_b2b: !!productPriceInfo?.values?.is_b2b,
          description: productPriceInfo?.values.description,
          image_url: priceImagePath,
          thumbnail_image_url: priceThumbnailImagePath,
          is_booster_kit: !!productPriceInfo?.values?.is_booster_kit,
          order: productPriceInfo?.values?.order,
          discount_details: productPriceInfo?.values?.discount_details,
        };

        dispatch(
          updateProductPriceAndGoToDetails({
            history,
            productPriceId,
            productId,
            updateProductPriceRequest,
          })
        );
      } else {
        const addPriceToProductRequest: AddPriceToProductRequest = {
          cta: productPriceInfo?.values.cta,
          price_amount: productPriceInfo?.values.price_amount,
          price_title: productPriceInfo?.values.price_title,
          recurring_price_properties:
            productPriceInfo?.values?.recurring_price_properties,
          sku: productPriceInfo?.values.sku,
          payment_link_redirect_url:
            productPriceInfo?.values.stripe_checkout_redirect_url,
          is_b2b: !!productPriceInfo?.values?.is_b2b,
          description: productPriceInfo?.values.description,
          image_url: priceImagePath,
          thumbnail_image_url: priceThumbnailImagePath,
          is_booster_kit: productPriceInfo?.values?.is_booster_kit,
          order: productPriceInfo?.values?.order,
          discount_details: productPriceInfo?.values?.discount_details,
        };

        dispatch(
          addPriceToProductAndGoToDetails({
            history,
            productId,
            addPriceToProductRequest,
          })
        );
      }
    }
  };

  const canSaveProductPrice = (): boolean =>
    !!productPriceInfo?.values &&
    !!priceImagePath &&
    !!priceThumbnailImagePath &&
    !!productPriceInfo.values?.order &&
    productPriceInfo.values?.order > 0;

  return (
    <Wrapper>
      <Header>
        <StretchedDiv>
          <OvBackButton label={t('common.actions.back')} />
        </StretchedDiv>
        <OvPageTitle
          title={
            selectedProductPrice
              ? t('productPrices.update')
              : t('productPrices.create')
          }
          styles={{ justifyContent: 'center' }}
          icon={<StyledStoreIcon />}
        />
        <StretchedDiv>
          <OvCompactButton
            onClick={saveProductPrice}
            disabled={!canSaveProductPrice()}
            style={{ marginLeft: 'auto' }}
            icon={<StyledAddBusinessIcon />}
          >
            {selectedProductPrice
              ? t('productPrices.update')
              : t('productPrices.create')}
          </OvCompactButton>
        </StretchedDiv>
      </Header>
      <Content></Content>

      <Content>
        <ContentGroup>
          <SectionTitle>{t('products.details.defaultPrice')}</SectionTitle>

          <OvProductPriceInfo
            productPriceInfo={productPriceInfo?.values}
            onChangeProductPriceInfo={handleProductPriceInfoChange}
            productPrice={selectedProductPrice}
          />
        </ContentGroup>

        <ContentGroup>
          <SectionTitle>
            {t('productPrices.images.priceImageAndThumbnailImage')}
          </SectionTitle>

          <OvImageUpload
            imageBasePath={PRICE_IMAGES_PATH}
            imageUrl={priceImagePath}
            title={t('productPrices.images.priceImage')}
            hintText={t('products.images.productHint')}
            ctaLabel={t('imageUpload.uploadImage')}
            dialogTitle={t('productPrices.images.uploadDialogTitle')}
            handleChange={handleSuccessfulPriceImageUpload}
          />

          <OvImageUpload
            imageBasePath={PRICE_THUMBNAIL_IMAGES_PATH}
            imageUrl={priceThumbnailImagePath}
            title={t('productPrices.images.priceThumbnailImage')}
            hintText={t('products.images.productHint')}
            ctaLabel={t('imageUpload.uploadImage')}
            dialogTitle={t('products.images.thumbnailUploadDialogTitle')}
            handleChange={handleSuccessfulPriceThumbnailImageUpload}
          />
        </ContentGroup>
      </Content>

      {errorMessage && (
        <Box>
          <OvErrorMessage message={errorMessage} />
        </Box>
      )}

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Wrapper>
  );
};

export default ProductPriceForm;

const Wrapper = styled.div`
  margin-left: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 1rem;
  }
`;

const Box = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Header = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  align-items: center;
`;

const StretchedDiv = styled.div`
  flex-grow: 1;
`;

const StyledStoreIcon = styled(StoreIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledAddBusinessIcon = styled(AddBusinessIcon)`
  && {
    margin-left: 0.25rem;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 1.5rem 0;
`;

const ContentGroup = styled(Box)`
  flex-grow: 1;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}) {
    flex-grow: 1;
    flex-basis: calc(50% - 1.5rem);
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-grow: 1;
    flex-basis: calc(25% - 2.25rem);
  }
`;

const SectionTitle = styled.h3`
  margin: 0 0 1rem 0;
  text-align: center;
  font-size: ${Variables.fontSizes.LARGE};
`;
