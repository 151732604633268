import React, { FC } from 'react';
import { ImpactConversionSubmissionError } from '../../../common/model/dto/order/conversion-submission-error';
import styled from 'styled-components';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import Colours from '../../../design-system/colours';
import breakpoints from '../../../design-system/breakpoints';
import StringUtils from '../../../common/utils/services/string-utils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import OvTextButton from '../atoms/OvTextButton';
import Variables from '../../../design-system/variables';

const OvImpactConversionSubmissionErrorsModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
  errors?: ImpactConversionSubmissionError[];
}> = ({ isOpen, onCancel, errors }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle id="alert-dialog-title">
        {t('orders.conversionSubmission.errorsModalTitle')}
      </StyledDialogTitle>
      <DataWrapper>
        {errors?.map((error) => (
          <ErrorContainer>
            <ErrorDataField>
              <ErrorPropertyName>
                {`${t('orders.conversionSubmission.errors.createdAt')}: `}
              </ErrorPropertyName>
              <ErrorPropertyValue>
                {StringUtils.displayValue(
                  format(new Date(error.created_at), 'MMMM dd, yyyy, HH:mm:ss')
                )}
              </ErrorPropertyValue>
            </ErrorDataField>
            <ErrorDataField>
              <ErrorPropertyName>
                {`${t('orders.conversionSubmission.errors.message')}: `}
              </ErrorPropertyName>
              <ErrorPropertyValue>{error.errorMessage}</ErrorPropertyValue>
            </ErrorDataField>
            <ErrorDataField>
              <ErrorPropertyName>
                {`${t('orders.conversionSubmission.errors.status')}: `}
              </ErrorPropertyName>
              <ErrorPropertyValue>{error.status.toString()}</ErrorPropertyValue>
            </ErrorDataField>
          </ErrorContainer>
        ))}
      </DataWrapper>
      <StyledDialogActions>
        <OvTextButton onClick={onCancel}>
          {t('common.actions.cancel')}
        </OvTextButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default OvImpactConversionSubmissionErrorsModal;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const DataWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 25rem;
  overflow-y: scroll;
`;

const ErrorContainer = styled.div`
  padding: 0.75rem;
  border: 1px solid ${Colours.OV_BASE_HOVER};
  border-radius: ${Variables.borderRadius.MEDIUM};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ErrorDataField = styled.span``;

const ErrorPropertyName = styled.span`
  font-weight: bold;
  color: ${Colours.OV_BASE};
`;

const ErrorPropertyValue = styled.span`
  text-align: justify;
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 1.5rem 0 0 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 1rem 1rem;
  }
`;
