import { fieldToTextField, TextFieldProps } from 'formik-mui';
import React, { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import OvTextField from './OvTextField';
import OvDatePicker from '../molecules/OvDatePicker';

const OvDatePickerFormControl: FC<TextFieldProps> = ({ ...props }) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (date: any) => {
      setFieldValue(name, date);
      setDate(date);
    },
    [setFieldValue, name]
  );
  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(props.field?.value);
  }, [props]);

  return (
    <>
      <OvDatePicker
        views={['year', 'month', 'day']}
        value={date ? new Date() : null}
        onChange={onChange}
        slots={(params: TextFieldProps) => (
          <OvTextField {...params} helperText={null} />
        )}
      />

      <TextField
        style={{ display: 'none' }}
        {...fieldToTextField(props)}
        onChange={onChange}
      />
    </>
  );
};

export default OvDatePickerFormControl;
