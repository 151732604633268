import React from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import { ProductPrice } from '../../../common/model/dto/product/product-price';

const OvProductPreview: React.FC<{ price?: ProductPrice }> = ({ price }) => {
  const openLink = () => {
    window.open(price?.payment_link_url, '_blank');
  };

  return (
    <>
      <Image src={price?.image_url} alt={price?.price_title} />
      <PriceTitle>{price?.price_title}</PriceTitle>
      <PriceDisplay>{price?.price_display}</PriceDisplay>
      <Description
        dangerouslySetInnerHTML={{ __html: price?.description as any }}
      ></Description>
      {price?.stripe_payment_link_id && (
        <StyledOvButton onClick={openLink}>{price?.cta}</StyledOvButton>
      )}
    </>
  );
};

export default OvProductPreview;

const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: ${Variables.borderRadius.MEDIUM};
  margin-bottom: 1rem;
  object-fit: contain;
`;

const PriceTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-size: ${Variables.fontSizes.LARGE};
  font-weight: bold;
`;

const PriceDisplay = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: ${Variables.fontSizes.LARGE};
  color: ${Colours.TEXT_DARK['300']};
`;

const Description = styled.div`
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
    display: block;
    width: 100%;
  }
`;
