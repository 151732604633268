/* eslint-disable jsx-a11y/anchor-is-valid */
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { InfoBoxData } from '../../../components/UI/molecules/OvOrderInfoBox';
import Colours from '../../../design-system/colours';
import i18n from '../../../i18n/config';
import { Order } from '../../model/dto/order/order';
import { FulfillmentStatus } from '../../model/type/fulfillment-status.enum';
import { OrderStatus } from '../../model/type/order-status.enum';
import { OrderPaymentStatus } from '../../model/type/order-payment-status.enum';
import DateUtils from './date-utils';
import StringUtils from './string-utils';

export class OrderDataUtils {
  static getStatusBackgroundColor(order: Order): string {
    switch (order.status) {
      case OrderStatus.Default:
      case OrderStatus.Unfulfilled:
      case OrderStatus.Pending:
      case OrderStatus.Submitted:
      case OrderStatus.Processing:
      case OrderStatus.Hold:
        return Colours.OV_YELLOW;

      case OrderStatus.Created:
        return Colours.OV_ORDER_CREATED;

      case OrderStatus.Fulfilled:
      case OrderStatus.Completed:
        return Colours.OV_BORDER_COLOR_V3;

      default:
        return Colours.OV_RED;
    }
  }

  static getStatusFontColor(order: Order): string {
    switch (order.status) {
      case OrderStatus.Default:
      case OrderStatus.Unfulfilled:
      case OrderStatus.Submitted:
      case OrderStatus.Processing:
      case OrderStatus.Pending:
      case OrderStatus.Hold:
        return Colours.OV_BASE;

      case OrderStatus.Created:
        return Colours.OV_WHITE;

      case OrderStatus.Fulfilled:
      case OrderStatus.Completed:
        return Colours.OV_BASE;

      default:
        return Colours.OV_WHITE;
    }
  }

  static getFulFillmentColor(order: Order): string {
    if (order.fulfillment_status === FulfillmentStatus.Fulfilled) {
      return Colours.OV_BORDER_COLOR_V3;
    }

    return Colours.OV_YELLOW;
  }

  static getPaymentStatusColor(order: Order): string {
    if (
      order.payment_status === OrderPaymentStatus.Complete ||
      order.payment_status === OrderPaymentStatus.Refunded
    ) {
      return Colours.OV_BORDER_COLOR_V3;
    }

    if (order.payment_status === OrderPaymentStatus.Failed) {
      return Colours.OV_RED;
    }

    return Colours.OV_YELLOW;
  }

  static mapOrderGeneralInfoToInfoBoxFormat(order: Order): InfoBoxData[] {
    const rows: InfoBoxData[] = [
      {
        key: i18n.t('orders.generalInfo.internalId'),
        component: (
          <>
            {StringUtils.displayValue(order?.internal_id)}&nbsp;
            <CopyToClipboard text={order?.internal_id ?? ''} onCopy={() => {}}>
              <span style={{ cursor: 'pointer' }}>
                <ContentCopyIcon
                  style={{
                    fontSize: '1rem',
                    paddingLeft: '0',
                  }}
                />
              </span>
            </CopyToClipboard>
          </>
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.email'),
        component: (
          <>
            {StringUtils.displayValue(order?.user_email)}&nbsp;
            <CopyToClipboard text={order?.user_email ?? ''} onCopy={() => {}}>
              <span style={{ cursor: 'pointer' }}>
                <ContentCopyIcon
                  style={{
                    fontSize: '1rem',
                    paddingLeft: '0',
                  }}
                />
              </span>
            </CopyToClipboard>
          </>
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.total'),
        value: `$${order?.items.reduce(
          (partialSum, item) =>
            partialSum +
            ((item.price ?? 0) - (item.discount ?? 0)) * (item.quantity ?? 1),
          0
        )}`,
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.createdAt'),
        value: DateUtils.formatDate(
          new Date(order?.created_at ?? ''),
          'MMMM DD, YYYY, HH:mm:ss'
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('common.orderedAt'),
        value: DateUtils.formatDate(
          new Date(order?.ordered_at ?? ''),
          'MMMM DD, YYYY, HH:mm:ss'
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.deliveryMethod'),
        value: StringUtils.displayValue(order?.delivery_method),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.channel'),
        value: StringUtils.displayValue(order?.channel),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.discountCode'),
        value: StringUtils.displayValue(order?.discount_code),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.salesChannel'),
        value: StringUtils.displayValue(order?.sales_channel),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.dropshippingPartner'),
        value: StringUtils.displayValue(order?.dropshipping_partner),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.isSubscription'),
        value: StringUtils.displayValue(
          order?.is_subscription
            ? i18n.t('common.actions.yes')
            : i18n.t('common.actions.no')
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.isSubmitted'),
        value: StringUtils.displayValue(
          order?.is_submitted
            ? i18n.t('common.actions.yes')
            : i18n.t('common.actions.no')
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.isStripeSubscriptionFirstOrder'),
        value: StringUtils.displayValue(
          order?.is_stripe_subscription_first_order
            ? i18n.t('common.actions.yes')
            : i18n.t('common.actions.no')
        ),
        showKeyInInfoBox: true,
      },
      {
        key: i18n.t('orders.generalInfo.stripeInvoiceNumber'),
        value: StringUtils.displayValue(order?.stripe_invoice_number ?? '-'),
        showKeyInInfoBox: true,
      },
    ];

    if (order.channel === 'Shopify') {
      const match = order.internal_id?.match(/OOVA\d{8}(.*)$/);

      if (match) {
        const shopifyOrderId = match[1];

        rows.push({
          value: i18n.t('common.shopifyLink'),
          showKeyInInfoBox: true,
          link:
            order.channel === 'Shopify'
              ? `https://oovahealth.myshopify.com/admin/orders/${shopifyOrderId}`
              : undefined,
        });
      }
    }

    return rows;
  }
}
