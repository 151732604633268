import React, { FC } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';

const OvListTableField: FC<{ style?: any; children: React.ReactNode }> = ({
  children,
  ...props
}) => <StyledTableField {...props}>{children}</StyledTableField>;

export default OvListTableField;

const StyledTableField = styled.td`
  padding: 1rem;
  font-weight: normal;
  font-size: ${Variables.fontSizes.MEDIUM};
  line-height: 1.25rem;
  vertical-align: top;
  border-bottom: 1px solid rgb(235, 237, 240);
  overflow-wrap: anywhere;
`;
