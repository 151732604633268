import { Paginated } from '../common/types';
import axios from 'axios';
import { ADMIN_ARTICLES_ROUTE } from '../api/backend';
import { Article } from '../common/model/dto/article';

class ArticlesService {
  public static readonly ARTICLE_LIST_LIMIT: number = 10;

  static async getArticles(query: string): Promise<Paginated<Article>> {
    const { data } = await axios.get<Paginated<Article>>(
      `${ADMIN_ARTICLES_ROUTE}?${query}`
    );
    return data;
  }

  static async getArticleById(articleId: string): Promise<Article> {
    const { data } = await axios.get<Article>(
      `${ADMIN_ARTICLES_ROUTE}/${articleId}`
    );
    return data;
  }

  static async createArticle(article: Article): Promise<Article> {
    const { data } = await axios.post<Article>(
      `${ADMIN_ARTICLES_ROUTE}`,
      article
    );
    return data;
  }

  static async updateArticle(article: Article): Promise<Article> {
    const { data } = await axios.patch<Article>(
      `${ADMIN_ARTICLES_ROUTE}/${article.id}`,
      article
    );
    return data;
  }

  static async deleteArticle(articleId: string) {
    return await axios.delete(`${ADMIN_ARTICLES_ROUTE}/${articleId}`);
  }
}

export default ArticlesService;
