import { OvTableData } from '../../../components/UI/molecules/OvTable';
import { ClinicInfoFields } from '../../../firebase/document-field.enums';
import i18n from '../../../i18n/config';
import { ClinicService } from '../../../services/clinic.service';
import { Clinic } from '../../model/dto/clinic';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import DateUtils from './date-utils';

export class ClinicInfoDataUtils {
  static mapClinicInfoToTableDataFormat(clinic?: Clinic): OvTableData {
    return [
      {
        label: 'common.clinicFields.clinicName',
        value: clinic?.name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ClinicInfoFields.name,
          placeholder: i18n.t('common.clinicFields.clinicName'),
        },
      },
      {
        label: 'common.clinicFields.clinicId',
        value: clinic?.clinic_id,
        translateLabel: true,
        editOptions: {
          propertyName: ClinicInfoFields.clinicId,
          widgetType: TableRowEditWidgetType.TEXT,
          placeholder: i18n.t('common.clinicFields.clinicId'),
          uppercase: true,
          minLength: 3,
          maxLength: 6,
          validate: this.isValidClinicId,
          validationErrorMsg: i18n.t('clinicDialogs.clinicIdAlreadyTaken'),
        },
      },
      {
        label: 'common.clinicFields.isPatientSharingOn',
        prettifiedValue: clinic?.is_patient_sharing_on
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        value: clinic?.is_patient_sharing_on,
        translateLabel: true,
        editOptions: {
          propertyName: ClinicInfoFields.isPatientSharingOn,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
      {
        label: 'common.clinicFields.isPatientConnectionEnabled',
        prettifiedValue:
          clinic?.is_automatic_patient_to_clinic_location_connection_enabled
            ? i18n.t('common.actions.yes')
            : i18n.t('common.actions.no'),
        value:
          clinic?.is_automatic_patient_to_clinic_location_connection_enabled,
        translateLabel: true,
        editOptions: {
          propertyName:
            ClinicInfoFields.isAutomaticPatientToClinicLocationConnectionEnabled,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
      {
        label: 'common.clinicFields.description',
        value: clinic?.description,
        translateLabel: true,
        editOptions: {
          propertyName: ClinicInfoFields.description,
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          placeholder: i18n.t('common.clinicFields.description'),
        },
      },
      {
        label: 'common.createdAt',
        translateLabel: true,
        value:
          clinic?.created_at &&
          DateUtils.formatDate(
            clinic?.created_at as Date,
            'MMMM DD, YYYY, HH:mm:ss'
          ),
      },
    ];
  }

  static isValidClinicId = async (clinicId: string) => {
    const doesExist = await ClinicService.doesClinicExistWithClinicId(clinicId);

    return !doesExist;
  };
}
