import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import favicon from './resources/favico/favicon-96x96.png';
import faviconStaging from './resources/favico/favicon-staging-96x96.png';

interface HeadProps {
  title?: string;
  description?: string;
}

export const Head: FunctionComponent<HeadProps> = ({ title, description }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="icon"
        type="image/png"
        href={
          process.env.REACT_APP_ENV !== 'staging' ? favicon : faviconStaging
        }
        sizes="16x16"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
    </Helmet>
  );
};
