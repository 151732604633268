import React, { FC } from 'react';
import styled from 'styled-components';

const OvListTable: FC<{
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ style, children, ...props }) => (
  <StyledTable style={style} {...props}>
    {children}
  </StyledTable>
);

export default OvListTable;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin: -0.5rem 0;
`;
