import React, { FC } from 'react';
import { ProductPrice } from '../../../common/model/dto/product/product-price';
import OvListTableField from '../atoms/OvListTableField';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OvCompactButton from '../atoms/OvCompactButton';
import StringUtils from '../../../common/utils/services/string-utils';

const OvProductPriceListRow: FC<{ productPrice: ProductPrice }> = ({
  productPrice,
}) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  return (
    <>
      <OvListTableField>{productPrice.price_title}</OvListTableField>
      <OvListTableField>{productPrice.price_display}</OvListTableField>
      <OvListTableField>{productPrice?.order ?? '-'}</OvListTableField>
      <OvListTableField>{productPrice.sku}</OvListTableField>
      <OvListTableField>
        {StringUtils.getPrettifiedBooleanValue(productPrice.is_b2b)}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.getPrettifiedBooleanValue(productPrice.active)}
      </OvListTableField>
      <OvListTableField>
        <StyledCompactButton>
          <NavLink to={`${url}/${productPrice.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledCompactButton>
      </OvListTableField>
    </>
  );
};

export default OvProductPriceListRow;

const StyledCompactButton = styled(OvCompactButton)`
  && {
    padding: 0;
  }
`;
