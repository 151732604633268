import { Dialog } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import Colours from '../../../design-system/colours';

const OvPayloadModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
  children?: React.ReactNode;
}> = ({ isOpen, onCancel, children }) => {
  return (
    <StyledDialog open={isOpen} onClose={onCancel}>
      <DataWrapper>{children}</DataWrapper>
    </StyledDialog>
  );
};

export default OvPayloadModal;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const DataWrapper = styled.div`
  padding: 1rem;
`;
