import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AssignPatientToClinicLocationRequest } from '../../../common/model/dto/assign-user-to-clinic-location-request';
import * as Yup from 'yup';
import OvTextField from '../atoms/OvTextField';
import DialogActions from '@mui/material/DialogActions';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import { UserInfo } from '../../../common/model/dto/user-info';
import UserService from '../../../services/user.service';
import { debounce } from 'lodash';
import DoneIcon from '@mui/icons-material/Done';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { UserInfoFields } from '../../../firebase/document-field.enums';

export interface OvAssignPatientToClinicLocationDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave?: (request: AssignPatientToClinicLocationRequest) => void;
  title?: string;
}

const OvAssignPatientToClinicLocationDialog: FC<
  OvAssignPatientToClinicLocationDialogProps
> = ({ isOpen, onCancel, onSave, title }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<UserInfo | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formik = useFormik<AssignPatientToClinicLocationRequest>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
    },
    onSubmit: (request: AssignPatientToClinicLocationRequest) => {
      if (onSave) {
        onSave(request);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required(t('clinicLocationDialogs.emailRequired')),
      first_name: Yup.string().required(
        t('clinicLocationDialogs.firstNameRequired')
      ),
      last_name: Yup.string().required(
        t('clinicLocationDialogs.lastNameRequired')
      ),
    }),
    validateOnBlur: true,
  });
  const REQUEST_DELAY = 1000;

  const fetchUserByEmail = (email: string) => {
    return UserService.getUserInfoByEmail(email);
  };

  // eslint-disable-next-line
  const getUserDelayed = useCallback(
    debounce((inputValue) => {
      (async () => {
        if (inputValue.length >= 4) {
          setIsLoading(true);
          const user: UserInfo = await fetchUserByEmail(inputValue);

          if (user) {
            setUser(user);
            setIsLoading(false);
          }
        }

        setIsLoading(false);
      })();
    }, REQUEST_DELAY),
    []
  );

  useEffect(() => {
    if (isOpen) {
      setUser(undefined);
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (user) {
      formik.getFieldHelpers(UserInfoFields.email).setValue(user.email);
      formik
        .getFieldHelpers(UserInfoFields.first_name)
        .setValue(user.first_name);
      formik.getFieldHelpers(UserInfoFields.last_name).setValue(user.last_name);
    }

    // eslint-disable-next-line
  }, [user]);

  const handleChange = async (e: any) => {
    setUser(undefined);
    if (user) {
      formik.getFieldHelpers(UserInfoFields.first_name).setValue('');
      formik.getFieldHelpers(UserInfoFields.last_name).setValue('');
    }

    const inputField = e.target.name;
    const inputValue = e.target.value;

    if (inputField === UserInfoFields.email && !formik.errors.email) {
      getUserDelayed(inputValue);
    }

    formik.handleChange(e);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={formik.handleSubmit} onChange={handleChange}>
        <StyledDialogContent>
          <StyledDialogHeader>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            {user && (
              <ExistingUserMessage>
                <DoneIcon style={{ marginRight: '5' }} />
                {t('clinicLocationDialogs.userAlreadyExists')}
              </ExistingUserMessage>
            )}
          </StyledDialogHeader>
          <StyledTextField
            type="email"
            fullWidth
            autoComplete="off"
            label={t('common.userFields.email')}
            error={formik.touched.email && !!formik.errors.email}
            helperText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
            {...formik.getFieldProps('email')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading || !!user}
            label={t('common.userFields.firstName')}
            error={formik.touched.first_name && !!formik.errors.first_name}
            helperText={
              formik.errors.first_name &&
              formik.touched.first_name &&
              formik.errors.first_name
            }
            {...formik.getFieldProps('first_name')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading || !!user}
            label={t('common.userFields.lastName')}
            error={formik.touched.last_name && !!formik.errors.last_name}
            helperText={
              formik.errors.last_name &&
              formik.touched.last_name &&
              formik.errors.last_name
            }
            {...formik.getFieldProps('last_name')}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton type="submit" autoFocus>
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
        {isLoading && <OvLoadingIndicator />}
      </form>
    </Dialog>
  );
};

export default OvAssignPatientToClinicLocationDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    padding: 1.5rem !important;
    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledDialogTitle = styled.h2`
  color: ${Colours.OV_BASE};
`;

const ExistingUserMessage = styled.h4`
  color: ${Colours.OV_GREEN};
  display: flex;
`;

const StyledTextField = styled(OvTextField)`
  && {
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 1.5rem;
    }
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;
