import React, { FC } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import { TryingToConceiveStatus } from '../../../common/model/dto/trying-to-conceive-status.enum';
import { CalendarDayStatusUtils } from '../../../common/utils/services/calendar-day-status-utils';

const OvDayStatusHighlight: FC<{
  tryingToConceiveStatus?: TryingToConceiveStatus;
  children?: React.ReactNode;
}> = ({ children, tryingToConceiveStatus }) => {
  return (
    <HighlightedDay
      style={{
        backgroundColor: CalendarDayStatusUtils.getTryingToConceiveStatusColor(
          tryingToConceiveStatus
        ),
      }}
    >
      {children}
    </HighlightedDay>
  );
};

export default OvDayStatusHighlight;

const HighlightedDay = styled.span`
  padding: 12px;
  border-radius: ${Variables.borderRadius.CIRCLE};
  font-size: 14px;
  line-height: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
