import React, { FC, useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import OvTextField from '../atoms/OvTextField';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { UserInfo } from '../../../common/model/dto/user-info';
import { useTranslation } from 'react-i18next';
import OvLabelledValue from '../atoms/OvLabelledValue';
import { debounce } from 'lodash';
import '../../../styles/autocomplete-custom-styles.scss';
import { SxProps } from '@mui/material';

const useStyles = (): { [key: string]: SxProps } => ({
  paper: {
    backgroundColor: Colours.OV_BASE,
  },
});

interface OvUserTypeaheadProps {
  placeholder?: string;
  optionLabel?: void;
  onUserSelect?: (user: UserInfo) => void;
  fetchUsers: (searchText: string) => Promise<UserInfo[]> | undefined;
}

const OvUserTypeahead: FC<OvUserTypeaheadProps> = ({
  placeholder,
  onUserSelect,
  fetchUsers,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState<UserInfo | null>(null);
  const [options, setOptions] = useState<UserInfo[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const REQUEST_DELAY = 600;

  // eslint-disable-next-line
  const getUsersDelayed = useCallback(
    debounce((inputValue, active) => {
      setOptions([]);

      (async () => {
        if (inputValue.length >= 3) {
          setIsLoading(true);
          const userInfoList: UserInfo[] = (await fetchUsers(inputValue)) ?? [];

          if (active) {
            setOptions(userInfoList);
            setIsLoading(false);
          }
        }

        setIsLoading(false);
      })();
    }, REQUEST_DELAY),
    []
  );

  const mapUserInfoToOption = ({
    first_name,
    last_name,
    email,
  }: UserInfo): { label?: string; value: string }[] => {
    const items: { label?: string; value: string }[] = [];

    if (first_name || last_name) {
      items.push({ value: `${first_name} ${last_name}` });
    }

    if (email) {
      items.push({ label: t('common.userFields.email'), value: email });
    }

    return items;
  };

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);

      return undefined;
    }

    getUsersDelayed(inputValue, active);

    return () => {
      active = false;
    };
  }, [inputValue, value, getUsersDelayed]);

  return (
    <StyledAutocomplete
      {...props}
      freeSolo
      options={options}
      value={value}
      onChange={(event, newValue: UserInfo | any) => {
        setOptions(value ? [newValue, ...options] : options);
        setValue(newValue);

        if (onUserSelect) {
          onUserSelect(newValue);
        }
      }}
      getOptionLabel={(option) => {
        if (option) {
          const { first_name, last_name }: UserInfo = option as UserInfo;

          if (first_name || last_name) {
            return `${first_name} ${last_name}`;
          }
        }

        return '';
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(x) => x}
      filterSelectedOptions
      sx={{ ...classes.paper }}
      renderInput={(params) => (
        <OvTextField
          {...params}
          label={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        let items: { label?: string; value: string }[] = [];

        if (option) {
          items = mapUserInfoToOption(option as UserInfo);
        }

        return <OvLabelledValue {...props} items={items} />;
      }}
    />
  );
};

export default OvUserTypeahead;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiFormControl-root,
    .MuiInputLabel-formControl {
      font-size: 0.875rem;
    }

    .MuiInputBase-formControl {
      font-size: 0.75rem;
      background: ${Colours.WHITE};
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    :not(.Mui-focused) {
      .MuiInputLabel-formControl {
        top: -10px;
        left: -7px;
      }
    }

    .MuiFormLabel-filled {
      top: 0 !important;
      left: 0 !important;
    }
  }
`;
