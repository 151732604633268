import React, { FC } from 'react';
import { ScanSequence } from '../../../common/model/dto/scan-sequences/scan-sequence';
import { useTranslation } from 'react-i18next';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvScanSequenceListRow from './OvScanSequenceListRow';

const OvScanSequencesList: FC<{ scanSequences: ScanSequence[] }> = ({
  scanSequences,
}) => {
  const { t } = useTranslation();
  const headerNames = [
    'common.scanSequenceFields.healthGoal',
    'common.scanSequenceFields.isHealthGoalDefault',
    'common.scanSequenceFields.order',
    'common.scanSequenceFields.scanningPlanType',
    'common.scanSequenceFields.numberOfScanningPlans',
    'common.scanSequenceFields.enabled',
    'common.operation',
    'common.action',
  ];

  return (
    <OvListTable>
      <OvListTableRow>
        {headerNames.map((headerName) => (
          <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
        ))}
      </OvListTableRow>
      {scanSequences.map((scanSequence) => (
        <OvListTableRow>
          <OvScanSequenceListRow scanSequence={scanSequence} />
        </OvListTableRow>
      ))}
    </OvListTable>
  );
};

export default OvScanSequencesList;
