import { DailyData } from '../../model/dto/daily-data';
import { TryingToConceiveStatus } from '../../model/dto/trying-to-conceive-status.enum';
import Colours from '../../../design-system/colours';
import i18n from '../../../i18n/config';

export class CalendarDayStatusUtils {
  static calculateDayStatus(
    dailyDataList: DailyData[],
    currentDailyDataIndexInList: number
  ): TryingToConceiveStatus | undefined {
    const currentDailyData: DailyData =
      dailyDataList[currentDailyDataIndexInList];

    if (currentDailyDataIndexInList !== -1) {
      return currentDailyData.trying_to_conceive_status;
    }
  }

  static getTryingToConceiveStatusColor(
    tryingToConceiveStatus: TryingToConceiveStatus | undefined
  ) {
    switch (tryingToConceiveStatus) {
      case TryingToConceiveStatus.LOW:
        return 'transparent';
      case TryingToConceiveStatus.PERIOD:
        return Colours.OV_PINK;
      case TryingToConceiveStatus.FERTILE:
        return Colours.OV_FERTILE;
      case TryingToConceiveStatus.HIGH:
        return Colours.OV_HIGH_TRYING_TO_CONCEIVE;
      case TryingToConceiveStatus.PEAK:
        return Colours.OV_HIGH_TRYING_TO_CONCEIVE;
      case TryingToConceiveStatus.POST_OVULATION:
        return Colours.POST_OVULATION;
      default:
        return 'transparent';
    }
  }

  static getTryingToConceiveStatusLabel(
    tryingToConceiveStatus: TryingToConceiveStatus | undefined
  ): string | null {
    switch (tryingToConceiveStatus) {
      case TryingToConceiveStatus.LOW:
        return i18n.t('calendarInfo.lowFertility');
      case TryingToConceiveStatus.FERTILE:
        return i18n.t('calendarInfo.fertile');
      case TryingToConceiveStatus.HIGH:
        return i18n.t('calendarInfo.highFertility');
      case TryingToConceiveStatus.PEAK:
        return i18n.t('calendarInfo.peak');
      case TryingToConceiveStatus.POST_OVULATION:
        return i18n.t('calendarInfo.postOvulation');
      default:
        return null;
    }
  }
}
