import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export const parseAxiosError = (action: PayloadAction<AxiosError>): string => {
  return (
    (action.payload?.response?.data as any)?.error +
    ': ' +
    JSON.stringify(
      ((action.payload?.response?.data as any)?.message as string[])?.join(',')
    )
  );
};

export const isValidUrl = (url?: string) => {
  try {
    new URL(url ?? '');
  } catch (e) {
    return false;
  }
  return true;
};
