import { createAsyncThunk } from '@reduxjs/toolkit';
import LotsService from '../../services/lots.service';
import { RootState } from '../store';
import { ListPaging } from '../../common/types';
import { History } from 'history';
import { setLotListPaging } from '../reducers/lots.slice';
import { Lot } from '../../common/model/dto/lot';
import { LotType } from '../../common/model/type/lot.type';
import { stringify } from 'query-string';
import { LotsState } from '../states/lots.state';
import { CalculateFitParameters } from '../../common/model/dto/calculate-fit-parameters';
import { HormoneModelFitParameters } from '../../common/model/dto/hormone-model-fit-parameters';
import { CalibrationErrorResponse } from '../../common/model/dto/calibration-error-response';

const getLots = createAsyncThunk(
  'lots/getLots',
  async (
    { limit, lotType }: { limit: number; lotType?: LotType },
    { rejectWithValue, getState }
  ) => {
    try {
      const listPaging: ListPaging = (getState() as RootState)?.lots
        ?.lotListPaging;
      const listSorting = ((getState() as RootState)?.lots as LotsState)
        ?.lotListSorting;

      const query = stringify(
        {
          offset: listPaging.offset,
          limit,
          lot_type: lotType,
          order_by: listSorting ? listSorting.order_by : null,
          order_dir: listSorting ? listSorting.order_dir : null,
        },
        { skipNull: true }
      );

      return await LotsService.getLots(query);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getLotById = createAsyncThunk(
  'lots/getLotById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await LotsService.getById(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createLot = createAsyncThunk(
  'lots/createLot',
  async (
    { history, type }: { history: History; type: LotType },
    { rejectWithValue }
  ) => {
    try {
      const lot: Lot = await LotsService.createLot(type);

      history.push(`/lots/${lot.id}`);
      return lot;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateLot = createAsyncThunk(
  'lots/updateLot',
  async ({ lotId, lot }: { lotId: string; lot: Lot }, { rejectWithValue }) => {
    try {
      return await LotsService.updateLot(lotId, lot);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const saveCalibration = createAsyncThunk(
  'lots/saveCalibration',
  async ({ lotId, lot }: { lotId: string; lot: Lot }, { rejectWithValue }) => {
    try {
      return await LotsService.updateLot(lotId, lot);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteLot = createAsyncThunk(
  'lots/deleteLot',
  async (
    { id, history }: { id: string; history: History },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await LotsService.deleteLot(id);

      dispatch(setLotListPaging({ offset: 0, total: 0 }));
      history.push(`/lots`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const runCalibrationProcess = createAsyncThunk(
  'lots/runCalibrationProcess',
  async (calculateFitParams: CalculateFitParameters, { rejectWithValue }) => {
    try {
      const calibrationResponse: HormoneModelFitParameters &
        CalibrationErrorResponse = await LotsService.runCalibrationProcess(
        calculateFitParams
      );

      if (calibrationResponse.success) {
        if (calculateFitParams.cartridge_type === 'lh') {
          return {
            lh_model_fit_parameters: calibrationResponse,
          };
        } else if (calculateFitParams.cartridge_type === 'pg') {
          return {
            pg_model_fit_parameters: calibrationResponse,
          };
        } else if (calculateFitParams.cartridge_type === 'lr_e3g') {
          return {
            lr_e3g_model_fit_parameters: calibrationResponse,
          };
        }
      } else {
        return {
          ...calibrationResponse,
          cartridge_type: calculateFitParams.cartridge_type,
        };
      }

      return calibrationResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export {
  getLots,
  getLotById,
  createLot,
  updateLot,
  deleteLot,
  runCalibrationProcess,
  saveCalibration,
};
