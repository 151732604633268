import axios from 'axios';
import { CONFIGURATION_ROUTE } from '../api/backend';

export class ConfigurationService {
  static async getProviderDefaultStripeLink() {
    const { data } = await axios.get<{ provider_default_stripe_link: string }>(
      `${CONFIGURATION_ROUTE}/default-stripe-link`
    );

    return data;
  }
}
