import React from 'react';
import {
  CartesianGrid,
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Colours from '../../../design-system/colours';
import styled from 'styled-components';
import { format } from 'date-fns';
import ClinicAuditActivityChartData from '../../../common/model/type/clinic-audit-activity-chart-data.type';

interface OvClinicAuditActivityBarChartProps {
  data: ClinicAuditActivityChartData[];
}

const OvClinicAuditActivityBarChart: React.FC<
  OvClinicAuditActivityBarChartProps
> = ({ data }) => {
  const transformedData = data.map((d, index) => ({
    index: index + 1,
    time: d.time_interval.value,
    patientCount: d.patient_request_count,
    providerCount: d.provider_request_count,
    totalRequestCount: d.patient_request_count + d.provider_request_count,
  }));

  const minTimestamp = data[0]?.time_interval.value;
  const maxTimestamp = data[data.length - 1]?.time_interval.value;

  const maxValue = Math.max(...transformedData.map((d) => d.totalRequestCount));

  return (
    <>
      <ResponsiveContainer height={270}>
        <BarChart data={transformedData} margin={{ top: 22, right: 35 }}>
          <XAxis dataKey="time" type="category" tick={false} tickMargin={10} />
          <YAxis tickLine={false} domain={[0, maxValue]} />
          <CartesianGrid vertical={false} />
          <Tooltip
            formatter={(value, name) => {
              const label = name === 'patientCount' ? 'Patient' : 'Provider';
              return [`${value} requests`, label];
            }}
            labelFormatter={(label) =>
              `Time: ${format(new Date(label), 'MMMM dd, yyyy, HH:mm:ss')}`
            }
          />
          <Legend
            verticalAlign="top"
            height={36}
            payload={[
              {
                value: 'Patient Requests',
                type: 'square',
                color: Colours.OV_PINK,
              },
              {
                value: 'Provider Requests',
                type: 'square',
                color: Colours.LIGHT_TEAL,
              },
            ]}
          />
          <Bar
            dataKey="patientCount"
            stackId="a"
            fill={Colours.OV_PINK}
            barSize={20}
          />
          <Bar
            dataKey="providerCount"
            stackId="a"
            fill={Colours.LIGHT_TEAL}
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
      <MinMaxLabelContainer>
        <span>{`Start: ${format(
          new Date(minTimestamp),
          'MMMM dd, yyyy, HH:mm:ss'
        )}`}</span>
        <span>{`End: ${format(
          new Date(maxTimestamp),
          'MMMM dd, yyyy, HH:mm:ss'
        )}`}</span>
      </MinMaxLabelContainer>
    </>
  );
};

export default OvClinicAuditActivityBarChart;

const MinMaxLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  color: ${Colours.OV_BASE};
`;
