import React, { FC, useState } from 'react';
import styled from 'styled-components';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { useTranslation } from 'react-i18next';
import { Scan } from '../../../common/model/dto/scan';
import OvListTableField from '../atoms/OvListTableField';
import OvDateParser from '../atoms/OvDateParser';
import { Constants } from '../../../common/constants';
import { DailyDataUtils } from '../../../common/utils/services/daily-data-utils';
import { isNumber } from 'lodash';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvNoContent from '../molecules/OvNoContent';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvPrettyUserName from '../atoms/OvPrettyUserName';

export interface ScanListFieldOptions {
  showUserName?: boolean;
  showDetailsAction?: boolean;
  showOperation?: boolean;
  useAdminPath?: boolean;
}

const OvScanList: FC<{
  scans: Scan[];
  isLoading?: boolean;
  fieldOptions?: ScanListFieldOptions;
  deleteScanCallback?: (scanId: string) => void;
}> = ({ scans, isLoading, fieldOptions, deleteScanCallback }) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const [isOpenDeleteScanDialog, setOpenDeleteScanDialog] =
    useState<boolean>(false);
  const [deletableScanId, setDeletableScanId] = useState<string>();

  const headerNames = [
    'common.createdAt',
    'common.dailyData.lh',
    'common.dailyData.pg',
    'common.dailyData.e3g',
  ];

  if (fieldOptions?.showUserName) {
    headerNames.unshift('common.user');
  }

  if (fieldOptions?.showDetailsAction) {
    headerNames.push('common.action');
  }

  if (fieldOptions?.showOperation) {
    headerNames.push('common.operation');
  }

  const openDeleteScanDialog = (id?: string) => {
    setDeletableScanId(id);
    setOpenDeleteScanDialog(true);
  };

  const onDelete = () => {
    if (deletableScanId && deleteScanCallback) {
      setOpenDeleteScanDialog(false);
      deleteScanCallback(deletableScanId);
    }
  };

  return (
    <WrapperBox>
      {scans?.length ? (
        <OvListTable>
          <OvListTableRow>
            {headerNames.map((headerName) => (
              <OvListTableHeaderField key={headerName}>
                {t(headerName)}
              </OvListTableHeaderField>
            ))}
          </OvListTableRow>
          {scans.map((scan, index) => (
            <OvListTableRow key={index}>
              {fieldOptions?.showUserName && (
                <OvListTableField>
                  {scan.user ? (
                    <StyledNavlink
                      to={
                        fieldOptions.useAdminPath
                          ? `/users/${scan.user_id}`
                          : `patients/${scan.user_id}`
                      }
                    >
                      <OvPrettyUserName user={scan.user} />
                    </StyledNavlink>
                  ) : (
                    <StyledNavlink
                      to={
                        fieldOptions.useAdminPath
                          ? `/users/${scan.user_id}`
                          : `patients/${scan.user_id}`
                      }
                    >
                      {t('common.actions.viewProfile')}
                    </StyledNavlink>
                  )}
                </OvListTableField>
              )}
              <OvListTableField>
                <div style={{ whiteSpace: 'nowrap' }}>
                  <OvDateParser
                    date={scan?.created_at as Date}
                    format={'MM/DD/YYYY - hh:mm A'}
                  />
                </div>
              </OvListTableField>

              <OvListTableField>
                {isNumber(scan?.lh)
                  ? DailyDataUtils.prettifyValue(
                      scan?.lh,
                      Constants.LH_MAX,
                      Constants.LH_UNIT_OF_MEASURE
                    )
                  : '-'}
              </OvListTableField>
              <OvListTableField>
                {isNumber(scan?.pg)
                  ? DailyDataUtils.prettifyValue(
                      scan?.pg,
                      Constants.PG_MAX,
                      Constants.PG_UNIT_OF_MEASURE
                    )
                  : '-'}
              </OvListTableField>
              <OvListTableField>
                {isNumber(scan?.lr_e3g)
                  ? DailyDataUtils.prettifyValue(
                      scan?.lr_e3g,
                      Constants.LR_E3G_MAX,
                      Constants.E3G_UNIT_OF_MEASURE
                    )
                  : '-'}
              </OvListTableField>
              {fieldOptions?.showOperation && (
                <OvListTableField>
                  <Tooltip title={t('common.dailyData.deleteScan') || ''}>
                    <StyledIconButton
                      disabled={scan.is_daily_log_record}
                      onClick={() => openDeleteScanDialog(scan.id)}
                      className="actionIcon"
                    >
                      <DeleteIcon />
                    </StyledIconButton>
                  </Tooltip>
                </OvListTableField>
              )}
              {fieldOptions?.showDetailsAction && (
                <OvListTableField>
                  <StyledOvButton>
                    <Link to={`${url}/${scan.id}`}>
                      {t('common.actions.details')}
                    </Link>
                  </StyledOvButton>
                </OvListTableField>
              )}
            </OvListTableRow>
          ))}
        </OvListTable>
      ) : (
        <OvNoContent>{t('common.empty')}</OvNoContent>
      )}

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteScanDialog}
        onCancel={() => setOpenDeleteScanDialog(false)}
        onConfirm={onDelete}
        description="common.dailyData.permanentScanDelete"
        title="common.areYouSure"
      />

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </WrapperBox>
  );
};

export default OvScanList;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};
    white-space: nowrap;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledNavlink = styled(NavLink)`
  && {
    color: ${Colours.OV_BASE};

    &:visited {
      color: ${Colours.OV_BASE};
    }
  }
`;
