import React, { FC, useEffect, useState } from 'react';
import { RecurringPriceProperties } from '../../../common/model/dto/product/recurring-price-properties';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OvTextFieldFormControl from '../atoms/OvTextFieldFormControl';
import Colours from '../../../design-system/colours';
import { Field, FieldProps, Form, Formik } from 'formik';
import OvFormObserver from '../atoms/OvFormObserver';
import * as Yup from 'yup';
import OvQuillEditor from '../atoms/OvQuillEditor';
import { Box, FormControlLabel, MenuItem, Switch } from '@mui/material';
import { ProductPriceInterval } from '../../../common/model/dto/product/product-price-interval.enum';
import OvSelect from '../atoms/OvSelect';
import Variables from '../../../design-system/variables';
import { ProductPrice } from '../../../common/model/dto/product/product-price';
import { DiscountDetails } from '../../../common/model/dto/product/discount-details';

export interface ProductPriceInfo {
  price_title?: string;
  price_amount?: number;
  description?: string;
  recurring_price_properties?: RecurringPriceProperties;
  stripe_checkout_redirect_url?: string;
  is_b2b: boolean;
  cta?: string;
  sku?: string;
  is_booster_kit?: boolean;
  order?: number;
  discount_details?: DiscountDetails;
}

export interface OvProductPriceInfoProps {
  onChangeProductPriceInfo: (
    productPriceInfo: ProductPriceInfo,
    isValid: boolean
  ) => void;
  productPriceInfo?: ProductPriceInfo;
  productPrice?: ProductPrice;
}

const OvProductPriceInfo: FC<OvProductPriceInfoProps> = ({
  productPriceInfo,
  onChangeProductPriceInfo,
  productPrice,
}) => {
  console.log(!!productPriceInfo?.discount_details);
  const { t } = useTranslation();
  const RECURRING_PRICE_INTERVALS = [
    {
      label: t('productPrices.intervals.day'),
      value: ProductPriceInterval.DAY,
    },
    {
      label: t('productPrices.intervals.week'),
      value: ProductPriceInterval.WEEK,
    },
    {
      label: t('productPrices.intervals.month'),
      value: ProductPriceInterval.MONTH,
    },
    {
      label: t('productPrices.intervals.year'),
      value: ProductPriceInterval.YEAR,
    },
  ];
  const [isRecurringPrice, setIsRecurringPrice] = useState<boolean>(
    !!productPriceInfo?.recurring_price_properties ?? false
  );
  const [isDiscountedPrice, setIsDiscountedPrice] = useState<boolean>(
    !!productPriceInfo?.discount_details ?? false
  );
  const [selectedRecurringPriceInterval, setSelectedRecurringPriceInterval] =
    useState<ProductPriceInterval | 'not-selected'>('not-selected');

  useEffect(() => {
    if (productPriceInfo) {
      setIsRecurringPrice(!!productPriceInfo?.recurring_price_properties);
      setIsDiscountedPrice(!!productPriceInfo?.discount_details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPriceInfo]);

  return (
    <Formik
      initialValues={{
        price_title: productPriceInfo?.price_title || '',
        price_amount: productPriceInfo?.price_amount || undefined,
        description: productPriceInfo?.description || '',
        recurring_price_properties:
          productPriceInfo?.recurring_price_properties || undefined,
        stripe_checkout_redirect_url:
          productPriceInfo?.stripe_checkout_redirect_url || '',
        is_b2b: productPriceInfo?.is_b2b || false,
        cta: productPriceInfo?.cta || '',
        sku: productPriceInfo?.sku || '',
        is_booster_kit: productPriceInfo?.is_booster_kit || false,
        order: productPriceInfo?.order,
        discount_details: productPriceInfo?.discount_details || undefined,
      }}
      enableReinitialize={true}
      onSubmit={(values) => console.log(values)}
      validationSchema={Yup.object().shape({
        price_title: Yup.string().required(t('common.requiredField')),
        price_display: Yup.string().required(t('common.requiredField')),
        description: Yup.string().required(t('common.requiredField')),
        stripe_checkout_redirect_url: Yup.string().required(
          t('common.requiredField')
        ),
        is_b2b: Yup.string().required(t('common.requiredField')),
        cta: Yup.string().required(t('common.requiredField')),
        sku: Yup.string().required(t('common.requiredField')),
        order: Yup.number()
          .required(t('common.requiredField'))
          .min(1, t('common.minValue', { minValue: 1 })),
      })}
      validateOnBlur={true}
    >
      <Form>
        <Field
          component={StyledTextField}
          type="text"
          name="price_title"
          placeholder={t('productPrices.fields.priceTitle')}
        />

        <Field
          component={StyledTextField}
          type="number"
          name="price_amount"
          placeholder={t('productPrices.fields.priceAmount')}
          disabled={productPrice}
        />

        <Field type="text" name="description">
          {({ field }: FieldProps) => (
            <StyledOvQuillEditor
              value={field.value}
              handleChange={field.onChange(field.name)}
              placeholder={t('productPrices.fields.description')}
            />
          )}
        </Field>

        <Field
          component={StyledTextField}
          type="url"
          name="stripe_checkout_redirect_url"
          placeholder={t(
            'productPrices.fields.redirectUrlAfterSuccessfulPayment'
          )}
          disabled={
            productPrice && !productPriceInfo?.stripe_checkout_redirect_url
          }
        />

        <Field
          component={StyledTextField}
          type="text"
          name="cta"
          placeholder={t('productPrices.fields.cta')}
        />

        <Field
          component={StyledTextField}
          type="text"
          name="sku"
          placeholder={t('productPrices.fields.sku')}
        />

        <Field
          component={StyledTextField}
          type="number"
          name="order"
          placeholder={t('productPrices.fields.order')}
        />

        {
          <Field name="is_booster_kit">
            {({ field }: FieldProps) => (
              <StyledSwitchContainer>
                <FormControlLabel
                  checked={productPriceInfo?.is_booster_kit}
                  onChange={field.onChange(field.name)}
                  control={<StyledSwitch />}
                  label={t('productPrices.fields.isBoosterKit')}
                  labelPlacement="start"
                  style={{
                    marginLeft: '0',
                  }}
                />
              </StyledSwitchContainer>
            )}
          </Field>
        }

        {
          <Field name="is_b2b">
            {({ field }: FieldProps) => (
              <StyledSwitchContainer>
                <FormControlLabel
                  checked={productPriceInfo?.is_b2b}
                  onChange={field.onChange(field.name)}
                  control={<StyledSwitch />}
                  label={t('productPrices.fields.isB2b')}
                  labelPlacement="start"
                  style={{
                    marginLeft: '0',
                  }}
                />
              </StyledSwitchContainer>
            )}
          </Field>
        }

        {(!productPrice ||
          (productPrice && productPrice?.type === 'recurring')) && (
          <StyledSwitchContainer>
            <FormControlLabel
              checked={isRecurringPrice}
              onChange={() => setIsRecurringPrice(!isRecurringPrice)}
              control={<StyledSwitch />}
              label={t('productPrices.fields.recurringPrice')}
              labelPlacement="start"
              style={{
                marginLeft: '0',
              }}
              disabled={!!productPrice}
            />
          </StyledSwitchContainer>
        )}

        {isRecurringPrice && (
          <>
            <Field name="recurring_price_properties.interval">
              {({ form }: FieldProps) => (
                <StyledOvSelect
                  value={selectedRecurringPriceInterval}
                  onChange={(event: any, newValue: any) => {
                    const value = event?.target ? event.target.value : newValue;
                    setSelectedRecurringPriceInterval(value);
                    form.setFieldValue(
                      'recurring_price_properties.interval',
                      value
                    );
                  }}
                  disabled={!!productPrice}
                >
                  <StyledMenuItem key={'not-selected'} value={'not-selected'}>
                    {t('common.actions.notSelected')}
                  </StyledMenuItem>
                  {RECURRING_PRICE_INTERVALS.map((interval) => (
                    <StyledMenuItem key={interval.value} value={interval.value}>
                      {interval.label}
                    </StyledMenuItem>
                  ))}
                </StyledOvSelect>
              )}
            </Field>

            <Field
              component={StyledTextField}
              type="number"
              name="recurring_price_properties.interval_count"
              placeholder={t('productPrices.fields.intervalCount')}
              disabled={!!productPrice}
            />
          </>
        )}

        {
          <StyledSwitchContainer>
            <FormControlLabel
              checked={isDiscountedPrice}
              value={!!productPriceInfo?.discount_details}
              onChange={() => setIsDiscountedPrice(!isDiscountedPrice)}
              control={<StyledSwitch />}
              label={t('productPrices.fields.isDiscountPrice')}
              labelPlacement="start"
              style={{
                marginLeft: '0',
              }}
            />
          </StyledSwitchContainer>
        }

        {isDiscountedPrice && (
          <>
            <Field
              component={StyledTextField}
              type="text"
              name="discount_details.recent_price"
              placeholder={t('productPrices.fields.recentPrice')}
            />

            <Field
              component={StyledTextField}
              type="text"
              name="discount_details.badge_text"
              placeholder={t('productPrices.fields.badgeText')}
            />

            <Field
              component={StyledTextField}
              type="text"
              name="discount_details.badge_text_color"
              placeholder={t('productPrices.fields.badgeTextColor')}
            />

            <Field
              component={StyledTextField}
              type="text"
              name="discount_details.badge_background_color"
              placeholder={t('productPrices.fields.badgeBackgroundColor')}
            />
          </>
        )}
        <OvFormObserver valuesChanged={onChangeProductPriceInfo} />
      </Form>
    </Formik>
  );
};

export default OvProductPriceInfo;

const StyledTextField = styled(OvTextFieldFormControl)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      display: block;
      width: 100%;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;

const StyledOvQuillEditor = styled(OvQuillEditor)`
  margin-bottom: 0.75rem;
`;

const StyledSwitchContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 0;
  padding: 0 1rem 1rem 0.5rem;
`;

const StyledSwitch = styled(Switch)`
  && {
    .MuiSwitch-thumb {
      color: ${Colours.OV_BASE};
    }
  }
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    margin-bottom: 1rem;
    max-width: none;
    height: 3rem;

    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }

      .MuiSelect-select {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
