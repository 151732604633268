import { IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

export interface OvProviderClinicLocationActionProps {
  tooltipText: string;
  onClick: () => void;
  isLoading: boolean;
  icon: ReactElement;
}

const OvProviderClinicLocationAction: FC<
  OvProviderClinicLocationActionProps
> = ({ tooltipText, onClick, isLoading, icon }) => {
  return (
    <Tooltip title={tooltipText} placement="top">
      <span>
        <StyledIconButton edge="end" disabled={isLoading} onClick={onClick}>
          {icon}
        </StyledIconButton>
      </span>
    </Tooltip>
  );
};

export default OvProviderClinicLocationAction;

const StyledIconButton = styled(IconButton)`
  && {
    margin-left: 1rem;
  }
`;
