import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';

const OvChartLabel: FC<{ style?: any; children?: React.ReactNode }> = ({
  children,
  style,
}) => {
  return <StyledChartLabel style={style}>{children}</StyledChartLabel>;
};

export default OvChartLabel;

const StyledChartLabel = styled.label`
  font-weight: bold;
  color: ${Colours.OV_BASE};
`;
