import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import OvTable, { OvTableRowData } from '../molecules/OvTable';
import OvNoContent from '../molecules/OvNoContent';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { clearSelectedUsersReports } from '../../../redux/reducers/report.slice';
import { UserInfo } from '../../../common/model/dto/user-info';
import {
  createReport,
  deleteReportById,
  findReportsByUserDocId,
  updateReport,
} from '../../../redux/thunks/report.thunk';
import { ReportService } from '../../../services/report.service';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import OvDateRangePicker from '../molecules/OvDateRangePicker';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { Range } from 'react-date-range';
import Popover from '@mui/material/Popover';
import OvRangePickerDisplay from '../molecules/OvRangePickerDisplay';
import OvDayStatusHighlight from '../atoms/OvDayStatusHighlight';
import { CalendarDayStatusUtils } from '../../../common/utils/services/calendar-day-status-utils';
import DateUtils from '../../../common/utils/services/date-utils';
import moment from 'moment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Variables from '../../../design-system/variables';
import { Report } from '../../../common/model/dto/report';
import { DailyData } from '../../../common/model/dto/daily-data';
import { TableRowEditWidgetType } from '../../../common/model/ui/table-row-edit-widget.type';

const OvUserDetailReports: FC<{
  loadDailyDataBetweenRange: (data: any) => any;
  selectedUser?: UserInfo;
  clinicLocationId?: string;
  currentUser?: UserInfo;
}> = ({
  selectedUser,
  loadDailyDataBetweenRange,
  clinicLocationId,
  currentUser,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [cycleRange, setCycleRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [cycleRangePickerAnchorEl, setCycleRangePickerAnchorEl] =
    useState(null);
  const [reportListTableData, setReportListTableData] = useState<
    OvTableRowData[]
  >([]);

  const reportList: Report[] = useAppSelector(
    (state) => state.reports.reportList
  );
  const isLoading = useAppSelector((state) => state.reports.loading);
  const error = useAppSelector((state) => state.reports.error);
  const dailyDataListInCalendar: DailyData[] = useAppSelector(
    (state) => state.dailyData.visibleDailyDataInCalendar
  );
  const patientDailyDataInCalendar: DailyData[] = useAppSelector(
    (state) => state.clinicLocation.patientDailyDataInCalendar
  );

  const handleNavigationBetweenMonths = (
    date: Date,
    userDocumentId: string
  ) => {
    const firstVisibleDay = moment(date)
      .startOf('month')
      .subtract(20, 'days') // 7days needed because of the layout of the calendar, and another 10 day to count post ovulation days
      .toDate();
    const lastVisibleDay = moment(date)
      .add(1, 'month')
      .endOf('month')
      .add(7, 'days')
      .toDate();

    if (clinicLocationId) {
      dispatch(
        loadDailyDataBetweenRange({
          patientId: userDocumentId,
          startDate: firstVisibleDay,
          endDate: lastVisibleDay,
          clinicLocationId,
        })
      );
    } else {
      dispatch(
        loadDailyDataBetweenRange({
          userId: userDocumentId,
          dayFrom: firstVisibleDay,
          dayTo: lastVisibleDay,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedUser?.document_id) {
      dispatch(findReportsByUserDocId(selectedUser?.document_id));
      handleNavigationBetweenMonths(new Date(), selectedUser?.document_id);
    }

    return function cleanup() {
      dispatch(clearSelectedUsersReports());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, dispatch]);

  useEffect(() => {
    setReportListTableData(
      ReportService.mapReportsToTableData(reportList, !clinicLocationId).map(
        (tableRow) => ({
          ...tableRow,
          deleteOptions:
            !clinicLocationId ||
            reportList.find((report) => report.document_id === tableRow.rowId)
              ?.created_by?.id === currentUser?.id
              ? {
                  confirmationOptions: {
                    title: t('common.reports.removeConfirmTitle'),
                    description: t('common.reports.removeConfirmDescription'),
                  },
                  deleteAction: () => {
                    if (tableRow.rowId) {
                      dispatch(deleteReportById(tableRow.rowId));
                    }
                  },
                }
              : undefined,
          editOptions:
            !clinicLocationId ||
            reportList.find((report) => report.document_id === tableRow.rowId)
              ?.created_by?.id === currentUser?.id
              ? {
                  widgetType: TableRowEditWidgetType.TEXT,
                  propertyName: 'title',
                  placeholder: t('common.reports.title'),
                }
              : undefined,
        })
      )
    );
  }, [reportList, t, dispatch, clinicLocationId, currentUser]);

  const getLogIndexFromDate = (calendarDate: Date): number => {
    let dailyDataIndex = -1;
    const dailyDataList = clinicLocationId
      ? patientDailyDataInCalendar
      : dailyDataListInCalendar;

    if (dailyDataList.length) {
      dailyDataList.forEach((dailyData, index) => {
        const dailyDataDate: Date = moment(
          dailyData?.day,
          'YYYY-MM-DD'
        ).toDate();

        if (DateUtils.areSameDays(dailyDataDate, calendarDate)) {
          dailyDataIndex = index;
        }
      });
    }

    return dailyDataIndex;
  };

  const onChangeCycleRange = (selectionRange: Range[]) => {
    setCycleRange(selectionRange);
  };

  const onCreateNewReport = () => {
    if (cycleRange[0].startDate && cycleRange[0].endDate) {
      dispatch(
        createReport({
          user_id: selectedUser?.document_id,
          cycle_start: cycleRange[0].startDate,
          cycle_end: cycleRange[0].endDate,
        })
      );
    }
  };

  const onOpenCycleRangePicker = (event: any) => {
    setCycleRangePickerAnchorEl(event.currentTarget);
  };

  const handleCloseCycleRangePicker = () => {
    setCycleRangePickerAnchorEl(null);
  };

  const onShownDateChanged = (date: Date) => {
    if (date && selectedUser?.document_id) {
      handleNavigationBetweenMonths(date, selectedUser.document_id);
    }
  };

  const handleReportUpdate = (updateObj: {
    id: string;
    updatedProperties: any;
  }) => {
    dispatch(updateReport(updateObj));
  };

  const isCycleRangePickerOpened = Boolean(cycleRangePickerAnchorEl);
  const popoverId = isCycleRangePickerOpened ? 'simple-popover' : undefined;

  return (
    <>
      <Header>
        <RangePickerWrapper>
          <OvRangePickerDisplay
            aria-describedby={popoverId}
            startTitle={t('common.cycleStart')}
            endTitle={t('common.cycleEnd')}
            startPlaceholder={t('common.reports.startDatePlaceholder')}
            endPlaceholder={t('common.reports.endDatePlaceholder')}
            startDate={cycleRange[0].startDate}
            endDate={cycleRange[0].endDate}
            handleClick={onOpenCycleRangePicker}
          />
          <StyledPopover
            id={popoverId}
            open={isCycleRangePickerOpened}
            anchorEl={cycleRangePickerAnchorEl}
            onClose={handleCloseCycleRangePicker}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <OvDateRangePicker
              ranges={cycleRange}
              onChange={(item) => onChangeCycleRange([item.selection])}
              onShownDateChange={(date) => onShownDateChanged(date)}
              showDateDisplay={false}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              preventSnapRefocus={true}
              showPreview={true}
              showMonthAndYearPickers={false}
              rangeColors={['#dfdfdf']}
              dayContentRenderer={(date) => (
                <OvDayStatusHighlight
                  tryingToConceiveStatus={CalendarDayStatusUtils.calculateDayStatus(
                    clinicLocationId
                      ? patientDailyDataInCalendar
                      : dailyDataListInCalendar,
                    getLogIndexFromDate(date)
                  )}
                >
                  {date.getDate()}
                </OvDayStatusHighlight>
              )}
            />
          </StyledPopover>
        </RangePickerWrapper>
        <StyledButton
          disabled={!cycleRange[0]?.startDate || !cycleRange[0]?.endDate}
          onClick={onCreateNewReport}
        >
          {t('common.reports.create')}
        </StyledButton>
      </Header>

      {error ? (
        <StyledErrorMessage>
          <StyledErrorOutlineIcon />
          {error}
        </StyledErrorMessage>
      ) : (
        ''
      )}

      {reportList?.length ? (
        <OvTable
          data={reportListTableData}
          onSaveRowValue={handleReportUpdate}
          getIdFromRowData={true}
        />
      ) : (
        ''
      )}

      {isLoading && <OvLoadingIndicator position="fixed" />}

      {!reportList?.length && !isLoading ? (
        <OvNoContent>{t('common.reports.emptyList')}</OvNoContent>
      ) : (
        ''
      )}
    </>
  );
};

export default OvUserDetailReports;

const Header = styled.header`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${Colours.OV_BASE};
  color: ${Colours.OV_SEMI_LIGHT};
  margin-bottom: 1.5rem;
`;

const RangePickerWrapper = styled.div`
  flex-grow: 1;
  margin-right: 2rem;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
`;

const StyledButton = styled(OvButton)`
  && {
    border-radius: 1.125rem;
    text-transform: none;
    padding: 0.25rem 0.75rem;
    margin: 0.5rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    color: ${Colours.OV_BASE};
    background-color: ${Colours.OV_WHITE};
    &:hover {
      opacity: 0.9;
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 0.5rem;
`;

const StyledPopover = styled(Popover)`
  && {
    .MuiPopover-paper {
      border-radius: 0.75rem;
      border: 1px solid ${Colours.OV_BASE};
    }
  }
`;
