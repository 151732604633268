import React, { FC, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import OvLogo from '../atoms/OvLogo';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Person from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getEntitlements, logout } from '../../../redux/thunks/auth.thunk';
import { UserInfo } from '../../../common/model/dto/user-info';
import breakpoints from '../../../design-system/breakpoints';
import { NavLink, useHistory } from 'react-router-dom';
import Variables from '../../../design-system/variables';
import { ClinicLocation } from '../../../common/model/dto/clinic-location';
import {
  setSelectedClinicLocation,
  setSelectedClinicRole,
  setSelectedRole,
} from '../../../redux/reducers/user.slice';
import { updateOwnUserInfo, userInfo } from '../../../redux/thunks/user.thunk';
import { History } from 'history';
import { Role } from '../../../common/model/type/role.enum';
import { List } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { InAppUserRole } from '../../../common/model/type/in-app-user-role.type';
import { InAppUserRoleSelectValue } from '../../../common/model/type/in-app-user-role-select-value.type';
import { ClinicLocationUtils } from '../../../common/utils/services/clinic-location-utils';
import { ClinicLocationPatient } from '../../../common/model/dto/clinic-location-patient';
import { ClinicLocationProvider } from '../../../common/model/dto/clinic-location-provider';
import {
  hasManagerRole,
  hasViewerRole,
} from '../../../common/utils/auth.helpers';

const OvPageHeader: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [inAppRoleValue, setInAppRoleValue] = useState<string | undefined>(
    undefined
  );
  const [selectInAppUserRoleOptions, setSelectInAppUserRoleOptions] = useState<
    InAppUserRole[]
  >([]);
  const [allClinicLocations, setAllClinicLocations] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const currentUser: UserInfo | null = useAppSelector(
    (state) => state.user.currentUser
  );
  const selectedClinicLocation: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const selectedClinicRole: string | undefined = useAppSelector(
    (state) => state.user.selectedClinicRole
  );
  const selectedRole = useAppSelector((state) => state.user.selectedRole);
  const history: History = useHistory();

  useEffect(() => {
    if (selectedRole === Role.Admin) {
      setInAppRoleValue(
        JSON.stringify({
          role: Role.Admin,
        })
      );
    } else if (
      selectedRole === Role.Regular &&
      selectedClinicLocation &&
      selectedClinicRole
    ) {
      setInAppRoleValue(
        JSON.stringify({
          role: Role.Regular,
          clinicLocationId: selectedClinicLocation?.id,
          clinicRole: selectedClinicRole,
        })
      );
    } else if (hasManagerRole([selectedRole])) {
      setInAppRoleValue(
        JSON.stringify({
          role: selectedRole,
        })
      );
    } else if (hasViewerRole([selectedRole])) {
      setInAppRoleValue(
        JSON.stringify({
          role: selectedRole,
        })
      );
    }
  }, [selectedClinicLocation, selectedRole, selectedClinicRole]);

  useEffect(() => {
    if (currentUser) {
      const inAppUserRoleData =
        ClinicLocationUtils.getInAppUserRoleData(currentUser);
      const selectInAppUserRoleOptions = inAppUserRoleData.options;
      const allClinicLocations = inAppUserRoleData.allClinicLocations;

      setAllClinicLocations(allClinicLocations);
      setSelectInAppUserRoleOptions(selectInAppUserRoleOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleUserMenuClose();
    dispatch(logout());
  };

  const handleInAppRoleSelect = async (value: any) => {
    handleUserMenuClose();

    const selectedInAppRole: InAppUserRoleSelectValue = JSON.parse(value);

    dispatch(setSelectedRole(selectedInAppRole.role));

    const userClinicLocation = [
      ...(currentUser?.patient_clinic_locations ?? []),
      ...(currentUser?.provider_clinic_locations ?? []),
    ].find(
      (location) =>
        location?.clinic_location?.id === selectedInAppRole?.clinicLocationId
    );

    let selectedRole: string = selectedInAppRole.role;

    if (selectedInAppRole.role.endsWith('Manager')) {
      selectedRole = 'Manager';
    }

    if (selectedInAppRole.role.endsWith('Viewer')) {
      selectedRole = 'Viewer';
    }

    if (!userClinicLocation?.clinic_location.is_demo_clinic_location) {
      await dispatch(
        updateOwnUserInfo({
          selected_role: selectedRole,
          selected_clinic_location_id:
            selectedInAppRole.clinicLocationId ?? null,
          selected_clinic_role: selectedInAppRole.clinicRole ?? null,
        })
      );
    }

    if (selectedInAppRole.clinicLocationId && selectedInAppRole.clinicRole) {
      await handleClinicLocationSelect(
        selectedRole,
        selectedInAppRole.clinicRole,
        userClinicLocation
      );
    }

    await dispatch(getEntitlements());
    await dispatch(userInfo());

    history.push('/');
  };

  const handleClinicLocationSelect = async (
    role: string,
    clinic_role: string,
    userClinicLocation?: ClinicLocationPatient | ClinicLocationProvider
  ) => {
    if (userClinicLocation?.clinic_location) {
      dispatch(setSelectedClinicLocation(userClinicLocation.clinic_location));
      dispatch(setSelectedClinicRole(clinic_role));
      if (!userClinicLocation.clinic_location.is_demo_clinic_location) {
        await dispatch(
          updateOwnUserInfo({
            selected_role: role,
            selected_clinic_location_id: userClinicLocation.clinic_location.id,
            selected_clinic_role: clinic_role,
          })
        );
      }
    }
  };

  return (
    <StyledAppHeader position="static">
      <StyledToolbar variant="dense">
        <StyledNavLink to="/users">
          <OvLogo />
        </StyledNavLink>

        <UserActions>
          {selectedRole === Role.Regular && !!selectedClinicLocation && (
            <StyledToolbarTitle>
              {t('common.userFields.inAppRoles.usingAppAsText', {
                clinicRole: allClinicLocations.find(
                  (clinicLocation) =>
                    clinicLocation.clinicLocationId ===
                      selectedClinicLocation.id &&
                    clinicLocation.clinicRole === selectedClinicRole
                )?.clinicRole,
                clinicLocationName: selectedClinicLocation.name,
              })}
            </StyledToolbarTitle>
          )}

          {selectedRole === Role.Admin && (
            <StyledToolbarTitle>
              {t('common.userFields.inAppRoles.usingAsOovaAdmin')}
            </StyledToolbarTitle>
          )}

          {hasManagerRole([selectedRole]) && (
            <StyledToolbarTitle>
              {t('common.userFields.inAppRoles.usingAsOovaManager')}
            </StyledToolbarTitle>
          )}

          {hasViewerRole([selectedRole]) && (
            <StyledToolbarTitle>
              {t('common.userFields.inAppRoles.usingAsOovaViewer')}
            </StyledToolbarTitle>
          )}

          <StyledIconButton
            size="large"
            aria-label="account of current user"
            aria-controls="basic-menu"
            aria-haspopup="true"
            onClick={handleUserMenu}
            color="inherit"
          >
            {currentUser?.image_path ? (
              <StyledImage src={currentUser?.image_path} alt="Profile image" />
            ) : (
              <AccountCircle />
            )}
          </StyledIconButton>
          <UserMenu
            anchorEl={anchorEl}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
          >
            <StyledMenuItem>
              <Person />
              <UserInfoText>{`${currentUser?.first_name} ${currentUser?.last_name}`}</UserInfoText>
            </StyledMenuItem>
            <StyledMenuItem>
              <EmailIcon />
              <UserInfoText>{currentUser?.email}</UserInfoText>
            </StyledMenuItem>
            {selectInAppUserRoleOptions.length > 0 && (
              <StyledList>
                {selectInAppUserRoleOptions.map((option) => (
                  <StyledListItem
                    selected={JSON.stringify(option.value) === inAppRoleValue}
                    key={option.label}
                    onClick={(event) =>
                      handleInAppRoleSelect(
                        (event.target as HTMLButtonElement).dataset.value
                      )
                    }
                  >
                    <StyledListItemText
                      data-value={JSON.stringify(option.value)}
                    >
                      {option.label}
                      {JSON.stringify(option.value) === inAppRoleValue ? (
                        <CheckRoundedIcon
                          style={{ marginLeft: '1rem', color: Colours.OV_BASE }}
                        />
                      ) : (
                        <IconPlaceHolder />
                      )}
                    </StyledListItemText>
                  </StyledListItem>
                ))}
              </StyledList>
            )}
            <LogoutCta onClick={handleLogout}>
              <ExitToApp />
              <UserInfoText>{t('appHeader.logoutCta')}</UserInfoText>
            </LogoutCta>
          </UserMenu>
        </UserActions>
      </StyledToolbar>
    </StyledAppHeader>
  );
};

export default OvPageHeader;

const StyledAppHeader = styled(AppBar)`
  && {
    color: ${Colours.OV_BASE};
    background: ${Colours.OV_LIGHT_GRAY};
    padding: 0.25rem 0.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    @media (min-width: ${breakpoints.lg}) {
      padding-left: 1.25rem;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  && {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const UserActions = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 0.625rem 1rem;
    cursor: default;

    &:hover {
      background: ${Colours.OV_LIGHT_GRAY};
    }

    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
    }
  }
`;

const StyledListItem = styled(MenuItem)`
  && {
    padding: 0;
    cursor: default;
    height: 2.5rem;

    &:hover {
      background: ${Colours.OV_LIGHT_GRAY};
    }

    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
    }
  }
`;

const StyledListItemText = styled.span`
  padding: 0.625rem 1rem;
  width: 100%;
  font-size: ${Variables.fontSizes.MEDIUM};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoutCta = styled(StyledMenuItem)`
  && {
    border-top: 1px solid ${Colours.OV_GRAY};
    margin-top: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

const UserInfoText = styled.span`
  margin-left: 0.375rem;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const UserMenu = styled(Menu)`
  && {
    .MuiMenu-list {
      min-width: 220px !important;
      background: ${Colours.OV_WHITE};
      padding: 0;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-right: 12px;

  @media (min-width: ${breakpoints.lg}) {
    width: 250px;
    margin-right: 0;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      padding: 0.75rem;
      border-radius: 50%;
    }
  }
`;

const StyledToolbarTitle = styled.h4`
  margin: 0 1rem 0 0;
`;

const StyledList = styled(List)`
  &&  {
    max-height: 7.5rem;
    overflow: scroll;
    padding: 0;
  }
`;

const IconPlaceHolder = styled.div`
  width: 2.5rem;
`;

const StyledImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
`;
