import React, { FC } from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from '@mui/material/Button';
import Colours from '../../../design-system/colours';

const OvButton: FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default OvButton;

const StyledButton = styled(Button)`
  && {
    background: ${Colours.OV_DARK_BLUE};
    color: ${Colours.WHITE};
    box-sizing: border-box;
    border: 2px solid ${Colours.OV_DARK_BLUE};
    border-radius: 6px;
    padding: 10px 8px;

    &:hover {
      background: ${Colours.WHITE};
      color: ${Colours.OV_DARK_BLUE};
    }

    &.Mui-disabled {
      background: ${Colours.OV_LIGHT_GRAY};
      border-color: ${Colours.OV_LIGHT_GRAY};
    }
  }
`;
