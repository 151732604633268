import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { Clinic } from '../../../common/model/dto/clinic';
import { RootState } from '../../store';
import { History } from 'history';
import { Paginated, SortingOption } from '../../../common/types';
import { AdminClinicService } from '../../../services/admin/admin-clinic.service';
import { parseUrl, stringify } from 'query-string';
import {
  restoreSearchModel,
  setClinicListPaging,
  setClinicListStoredQuery,
} from '../../reducers/admin-clinic.slice';
import { CreateClinicRequest } from '../../../common/model/dto/create-clinic-request';

const loadClinics = createAsyncThunk<Clinic[], History, { state: RootState }>(
  'clinic/loadClinics',
  async (history: History, { dispatch, getState }) => {
    try {
      let paginatedClinics: Paginated<Clinic>,
        query = '';

      const { offset } = getState().adminClinic.clinicListPaging,
        sorting = getClinicListQuerySorting(getState());

      query = stringify({
        limit: AdminClinicService.CLINIC_LIST_LIMIT,
        order_by: 'name',
        order_dir: sorting?.orderDir,
        offset,
      });

      paginatedClinics = await AdminClinicService.loadClinicsByQuery(query);
      dispatch(setClinicListStoredQuery(offset > 0 ? query : ''));
      history.push(`/clinics${offset > 0 ? '?' + query : ''}`);

      setPaging({ ...paginatedClinics, offset }, dispatch);

      return paginatedClinics.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const restoreClinicSearchFromUrl = createAsyncThunk<
  Clinic[],
  string,
  { state: RootState }
>('clinic/loadClinics', async (query, { dispatch, getState }) => {
  try {
    let paginatedClinics: Paginated<Clinic>;

    dispatch(setClinicListStoredQuery(query));
    dispatch(restoreSearchModel(parseUrl(query).query));
    paginatedClinics = await AdminClinicService.loadClinicsByQuery(
      query.slice(1)
    );
    setPaging(
      {
        ...paginatedClinics,
        offset: getState().adminClinic.clinicListPaging.offset,
      },
      dispatch
    );

    return paginatedClinics.docs;
  } catch (error) {
    console.log(error);
    return [];
  }
});

const getClinicById = createAsyncThunk(
  'clinic/getClinicById',
  async (clinicId: string, { rejectWithValue }) => {
    try {
      return await AdminClinicService.getClinicById(clinicId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createClinic = createAsyncThunk(
  'clinic/create',
  async (
    { createClinicRequest }: { createClinicRequest: CreateClinicRequest },
    { rejectWithValue }
  ) => {
    try {
      return await AdminClinicService.createClinic(createClinicRequest);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateClinic = createAsyncThunk(
  'clinic/update',
  async (
    updateObj: {
      clinicId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      await AdminClinicService.updateClinic(
        updateObj.clinicId,
        updateObj.updatedProperties
      );

      return updateObj;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

const deleteClinic = createAsyncThunk(
  'clinic/delete',
  async (
    { history, clinicId }: { history: History; clinicId: string },
    { rejectWithValue }
  ) => {
    try {
      await AdminClinicService.deleteClinic(clinicId);

      history.push(`/clinics`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function getClinicListQuerySorting(state: RootState) {
  const sortingOptions: SortingOption[] = state?.adminClinic?.sortingOptions;

  const sortingOption = sortingOptions
    .filter((sortingOption) => sortingOption)
    .find((sortingOption) => ['asc', 'desc'].includes(sortingOption.direction));

  if (sortingOption) {
    return {
      orderBy: sortingOption.value,
      orderDir: sortingOption.direction,
    };
  }
}

function setPaging(
  paginatedClinics: Paginated<Clinic>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setClinicListPaging({
      offset: paginatedClinics.offset || 0,
      total: paginatedClinics.total,
    })
  );
}

export {
  restoreClinicSearchFromUrl,
  loadClinics,
  getClinicById,
  createClinic,
  deleteClinic,
  updateClinic,
};
