import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Variables from '../../../design-system/variables';

const OvTextField: FC<TextFieldProps> = ({ children, ...props }) => (
  <StyledTextInput {...props} />
);

export default OvTextField;

const StyledTextInput = styled(TextField)`
  && {
    background: ${Colours.WHITE};
    border-radius: ${Variables.borderRadius.SMALL};
    & label.Mui-focused {
      color: ${Colours.OV_BASE};
    }

    & label.Mui-error {
      color: ${Colours.OV_RED};
    }

    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: ${Colours.OV_BASE};
      }

      &.Mui-error fieldset,
      &.Mui-error:hover fieldset,
      &.Mui-error.Mui-focused {
        border-color: ${Colours.OV_RED};
      }

      &:hover fieldset {
        border-color: ${Colours.OV_DARK_BLUE};
      }

      & input {
        color: ${Colours.OV_BASE};
        padding: 14px 10px;
        font-size: ${Variables.fontSizes.MEDIUM};

        &:autofill {
          box-shadow: 0 0 0 50px white inset;
          -webkit-text-fill-color: ${Colours.OV_BASE};

          &:focus {
            box-shadow: 0 0 0 50px white inset;
            -webkit-text-fill-color: ${Colours.OV_BASE};
          }
        }
      }

      & label {
        line-height: normal;
      }
    }
  }
`;
