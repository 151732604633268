import React, { FC } from 'react';
import { SpikeInfo } from '../../../common/model/dto/spike-info';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import breakpoints from '../../../design-system/breakpoints';
import OvTable from '../molecules/OvTable';
import { DailyDataUtils } from '../../../common/utils/services/daily-data-utils';

const OvWearableInfo: FC<{
  spikeInfo?: SpikeInfo;
}> = ({ spikeInfo }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TableWrapper>
        {spikeInfo ? (
          <OvTable data={DailyDataUtils.mapSpikeInfoToTable(spikeInfo)} />
        ) : (
          <NoContentWrapper>
            {t('common.dailyData.spikeInfo.noContent')}
          </NoContentWrapper>
        )}
      </TableWrapper>
    </Container>
  );
};

export default OvWearableInfo;

const Container = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 0.75rem;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 0.75rem;
  }
`;

const NoContentWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
