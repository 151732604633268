import { FilterType } from '../../common/model/ui/filter.type';
import { ClinicLocationState } from '../states/clinic-location.state';
import { RootState } from '../store';

const DEFAULT_SORTING_VALUE = 'id';

const shouldFilterPatientList = (state: RootState): boolean =>
  hasAppliedSortingSelector(state) || hasAppliedPatientFiltersSelector(state);

const hasAppliedPatientFiltersSelector = (state: RootState): boolean => {
  const userState = state.clinicLocation as ClinicLocationState;

  return userState.userListFilters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

const hasAppliedSortingSelector = (state: RootState): boolean =>
  (state.clinicLocation as ClinicLocationState)?.userListSortingOptions.some(
    (sorting) => sorting.direction && sorting.value !== DEFAULT_SORTING_VALUE
  );

export {
  hasAppliedSortingSelector,
  hasAppliedPatientFiltersSelector,
  shouldFilterPatientList,
};
