import React, { FC, ReactComponentElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';

export interface InfoDialogProps {
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  icon: ReactComponentElement<any>;
  children?: React.ReactNode;
}

const OvInfoModal: FC<InfoDialogProps> = ({
  children,
  isOpen,
  title,
  icon,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onCancel} aria-labelledby={t(title)}>
      <StyledDialogTitle id="alert-dialog-title">
        {icon} {t(title)}
      </StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
    </Dialog>
  );
};

export default OvInfoModal;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: ${Variables.fontSizes.XLARGE};
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    max-width: 30rem;
  }
`;
