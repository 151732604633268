import { Box } from '@mui/material';
import React, { FC } from 'react';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import styled from 'styled-components';
import { JournalEntryUtils } from '../../../common/utils/services/journal-entry-utils';

export interface OvPeriodCyclesChartCustomTooltipProps {
  active: boolean;
  payload: any;
}

const OvPeriodCyclesChartCustomTooltip: FC<
  OvPeriodCyclesChartCustomTooltipProps
> = ({ active, payload }) => {
  const dataPointPayload =
    active === true && !!payload ? payload[0]?.payload : null;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding="0.5rem 0.75rem"
        bgcolor={Colours.CD_TOOLTIP_HEADER}
        color={Colours.OV_WHITE}
        fontSize={Variables.fontSizes.SMALL}
        style={{
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <StyledLabel style={{ opacity: 1 }}>
          {dataPointPayload?.day}
        </StyledLabel>
      </Box>
      <Box
        padding="0.5rem 0.75rem"
        bgcolor={Colours.OV_BASE}
        color={Colours.OV_WHITE}
        fontSize={Variables.fontSizes.MEDIUM}
        fontWeight="bold"
        style={{
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <StyledLabel>
          {`Period flow: ${
            JournalEntryUtils.numericToPeriodFlow(
              dataPointPayload?.period_flow
            ) || 'None'
          }`}
        </StyledLabel>
      </Box>
    </>
  );
};

export default OvPeriodCyclesChartCustomTooltip;

const StyledLabel = styled.label`
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif !important;
`;
