export const managerRolePostfix = 'Manager';
export const viewerRolePostfix = 'Viewer';

export const hasManagerRole = (roles?: string[]) =>
  !!roles?.find((role) => role.endsWith(managerRolePostfix));

export const hasViewerRole = (roles?: string[]) =>
  !!roles?.find((role) => role.endsWith(viewerRolePostfix));

export const findManagerRole = (roles?: string[]) =>
  roles?.find((role) => role.endsWith(managerRolePostfix));

export const findViewerRole = (roles?: string[]) =>
  roles?.find((role) => role.endsWith(viewerRolePostfix));
