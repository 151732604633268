import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useParams } from 'react-router-dom';
import { Article } from '../../common/model/dto/article';
import OvBackButton from '../UI/atoms/OvBackButton';
import { NavLink } from 'react-router-dom-v5-compat';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import EditIcon from '@mui/icons-material/Edit';
import OvTable from '../UI/molecules/OvTable';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import { ArticleDataUtils } from '../../common/utils/services/article-data-utils';
import { clearSelectedArticle } from '../../redux/reducers/articles.slice';
import {
  getArticleById,
  updateArticle,
} from '../../redux/thunks/articles.thunk';
import { useTranslation } from 'react-i18next';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const ArticleDetails: FC = () => {
  let { articleId }: any = useParams();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedArticle: Article = useAppSelector(
    (state) => state.articles.selectedArticle
  );
  const isLoading: boolean = useAppSelector((state) => state.articles.loading);

  const applicationOperations = useAuthorized(Resource.Application);
  const articleOperations = useAuthorized(Resource.Article);

  const articleUpdateAllowed =
    articleOperations.update || applicationOperations.supervise;

  useEffect(() => {
    dispatch(getArticleById(articleId));

    return () => {
      dispatch(clearSelectedArticle());
    };
  }, [articleId, dispatch]);

  const handleArticleUpdate = (updatedProperties: any) => {
    if (selectedArticle?.id) {
      dispatch(
        updateArticle({
          article: { ...selectedArticle, ...updatedProperties },
        })
      );
    }
  };

  return (
    <Container>
      <Header>
        <OvBackButton label={t('common.actions.back')} />
        <Title>{selectedArticle?.title}</Title>
        {articleUpdateAllowed && (
          <NavLink to={`articles/edit/${selectedArticle?.id}`}>
            <OvCompactButton icon={<EditIcon />}>
              {t('common.actions.edit')}
            </OvCompactButton>
          </NavLink>
        )}
      </Header>

      {selectedArticle && (
        <ContentWrapper>
          <OvTable
            data={ArticleDataUtils.mapDataForArticleDetailsTable(
              selectedArticle
            )}
            theme="Light"
            onSaveRowValue={handleArticleUpdate}
          ></OvTable>
        </ContentWrapper>
      )}

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Container>
  );
};

export default ArticleDetails;

const Container = styled.section`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h3`
  flex-grow: 1;
  margin: 0 0.75rem 0 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;
