import { ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { ScanSequence } from '../../../common/model/dto/scan-sequences/scan-sequence';
import { RootState } from '../../store';
import { Paginated } from '../../../common/types';
import { parseUrl, stringify } from 'query-string';
import { AdminScanSequencesService } from '../../../services/admin/admin-scan-sequences.service';
import { History } from 'history';
import {
  restoreSearchModel,
  setScanSequenceListPaging,
  setScanSequenceListStoredQuery,
} from '../../reducers/admin-scan-sequence.slice';
import { AddScanningPlanDto } from '../../../common/model/dto/scan-sequences/add-scanning-plan';
import { CreateScanSequenceRequest } from '../../../common/model/dto/scan-sequences/create-scan-sequence-request.dto';

export const loadScanSequences = createAsyncThunk<
  ScanSequence[],
  History,
  { state: RootState }
>(
  'scanSequence/loadScanSequence',
  async (history: History, { dispatch, getState }) => {
    try {
      let paginatedScanSequences: Paginated<ScanSequence>,
        query = '';

      const { offset } = getState().adminScanSequence.scanSequenceListPaging;

      query = stringify({
        limit: AdminScanSequencesService.SCAN_SEQUENCE_LIST_LIMIT,
        order_by: 'order',
        order_dir: 'asc',
        offset,
      });

      paginatedScanSequences =
        await AdminScanSequencesService.loadScanSequencesByQuery(query);
      dispatch(setScanSequenceListStoredQuery(offset > 0 ? query : ''));
      history.push(`/scan-sequences${offset > 0 ? '?' + query : ''}`);

      setPaging({ ...paginatedScanSequences, offset }, dispatch);

      return paginatedScanSequences.docs;
    } catch (error) {
      return [];
    }
  }
);

export const loadScanSequencesByQuery = createAsyncThunk<
  ScanSequence[],
  string,
  { state: RootState }
>(
  'scanSequence/loadScanSequenceByQuery',
  async (query: string, { dispatch, getState }) => {
    try {
      let paginatedScanSequences: Paginated<ScanSequence>;

      paginatedScanSequences =
        await AdminScanSequencesService.loadScanSequencesByQuery(query);

      return paginatedScanSequences.docs;
    } catch (error) {
      return [];
    }
  }
);

export const restoreScanSequenceSearchFromUrl = createAsyncThunk<
  ScanSequence[],
  string,
  { state: RootState }
>('scanSequence/loadScanSequence', async (query, { dispatch, getState }) => {
  try {
    let paginatedScanSequences: Paginated<ScanSequence>;

    dispatch(setScanSequenceListStoredQuery(query));
    dispatch(restoreSearchModel(parseUrl(query).query));
    paginatedScanSequences =
      await AdminScanSequencesService.loadScanSequencesByQuery(query.slice(1));
    setPaging(
      {
        ...paginatedScanSequences,
        offset: getState().adminScanSequence.scanSequenceListPaging.offset,
      },
      dispatch
    );

    return paginatedScanSequences.docs;
  } catch (error) {
    return [];
  }
});

export const getScanSequenceById = createAsyncThunk(
  'scanSequence/getScanSequenceById',
  async (scanSequenceId: string, { rejectWithValue }) => {
    try {
      return await AdminScanSequencesService.getScanSequenceById(
        scanSequenceId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createScanSequence = createAsyncThunk(
  'scanSequence/createScanSequence',
  async (
    {
      createScanSequenceRequest,
    }: { createScanSequenceRequest: CreateScanSequenceRequest },
    { rejectWithValue }
  ) => {
    try {
      return await AdminScanSequencesService.createScanSequence(
        createScanSequenceRequest
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const duplicateScanSequence = createAsyncThunk(
  'scanSequence/duplicateScanSequence',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      return await AdminScanSequencesService.duplicateScanSequence(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateScanSequence = createAsyncThunk(
  'scanSequence/updateScanSequence',
  async (
    updateObj: {
      scanSequenceId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const updatedScanSequence =
        await AdminScanSequencesService.updateScanSequence(
          updateObj.scanSequenceId,
          updateObj.updatedProperties
        );

      return updatedScanSequence;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteScanSequence = createAsyncThunk(
  'scanSequence/deleteScanSequence',
  async (
    { history, id }: { history: History; id: string },
    { rejectWithValue }
  ) => {
    try {
      await AdminScanSequencesService.deleteScanSequence(id);

      history.push(`/scan-sequences`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addScanningPlanToScanSequence = createAsyncThunk(
  'scanSequence/addScanningPlanToScanSequence',
  async (
    {
      id,
      addScanningPlanDto,
    }: { id: string; addScanningPlanDto: AddScanningPlanDto },
    { rejectWithValue }
  ) => {
    try {
      return await AdminScanSequencesService.addScanningPlanToScanSequence(
        id,
        addScanningPlanDto
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteScanningPlanFromScanSequence = createAsyncThunk(
  'scanSequence/deleteScanningPlanFromScanSequence',
  async (
    { id, length }: { id: string; length: number },
    { rejectWithValue }
  ) => {
    try {
      return await AdminScanSequencesService.deleteScanningPlanFromScanSequence(
        id,
        { length }
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function setPaging(
  paginatedScanSequences: Paginated<ScanSequence>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setScanSequenceListPaging({
      offset: paginatedScanSequences.offset || 0,
      total: paginatedScanSequences.total,
    })
  );
}
