import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ChartData from '../../../common/model/type/chart-data.type';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { isNumber } from 'lodash';
import { Constants } from '../../../common/constants';
import DateUtils from '../../../common/utils/services/date-utils';

export interface OvHormoneDetailsProps {
  data: ChartData;
}

const OvHormoneDetails: FC<OvHormoneDetailsProps> = ({ data, ...props }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <HeaderText>
          {`${t('dashboard.chart.day')} ${data?.index}${
            data?.dateStr ? ' - ' : ''
          }${data?.dateStr}`}
        </HeaderText>
      </Header>
      <ContentSection>
        <SectionHeader>
          <Label>{t('common.hormone')}</Label>
        </SectionHeader>
        <DataContainer>
          <DataGroup>
            <Label>{t('dashboard.luteinizingHormone')}</Label>
            {isNumber(data?.dailyData?.lh)
              ? data?.dailyData?.scans
                  ?.filter((scan) => isNumber(scan?.lh))
                  .map((scan, index) => (
                    <StyledDataText key={`${scan?.lh}-${index}`}>
                      {`${scan?.lh && scan.lh > 0 ? scan.lh : 0} ${
                        Constants.LH_UNIT_OF_MEASURE
                      } - ${
                        scan.created_at &&
                        DateUtils.formatDate(scan.created_at, 'HH:mm')
                      }`}
                    </StyledDataText>
                  ))
              : '-'}
          </DataGroup>

          <DataGroup>
            <Label>{t('dashboard.progesterone')}</Label>
            {isNumber(data?.dailyData?.pg)
              ? data?.dailyData?.scans
                  ?.filter((scan) => isNumber(scan?.pg))
                  .map((scan, index) => (
                    <StyledDataText key={`${scan?.pg}-${index}`}>
                      {`${scan?.pg && scan.pg > 0 ? scan.pg : 0} ${
                        Constants.PG_UNIT_OF_MEASURE
                      } - ${
                        scan.created_at &&
                        DateUtils.formatDate(scan.created_at, 'HH:mm')
                      }`}
                    </StyledDataText>
                  ))
              : '-'}
          </DataGroup>

          <DataGroup>
            <Label>{t('dashboard.estrogen')}</Label>
            {isNumber(data?.dailyData?.lr_e3g)
              ? data?.dailyData?.scans
                  ?.filter((scan) => isNumber(scan?.lr_e3g))
                  .map((scan, index) => (
                    <StyledDataText key={`${scan?.lr_e3g}-${index}`}>
                      {`${scan?.lr_e3g && scan.lr_e3g > 0 ? scan.lr_e3g : 0} ${
                        Constants.E3G_UNIT_OF_MEASURE
                      } - ${
                        scan.created_at &&
                        DateUtils.formatDate(scan.created_at, 'HH:mm')
                      }`}
                    </StyledDataText>
                  ))
              : '-'}
          </DataGroup>
        </DataContainer>

        <SectionHeader>
          <Label>{t('common.nonHormoneData')}</Label>
        </SectionHeader>
        <DataContainer>
          {data?.dailyData?.journal_entry?.temperature && (
            <DataGroup>
              <Label>{t('common.dailyData.temperature')}</Label>
              {isNumber(data?.dailyData?.journal_entry?.temperature) ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.temperature} ${Constants.TEMPERATURE_UNIT_OF_MEASURE}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.stool && (
            <DataGroup>
              <Label>{t('common.dailyData.stool')}</Label>
              {data?.dailyData?.journal_entry?.stool ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.stool}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.skin && (
            <DataGroup>
              <Label>{t('common.dailyData.skin')}</Label>
              {data?.dailyData?.journal_entry?.skin ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.skin}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.hair && (
            <DataGroup>
              <Label>{t('common.dailyData.hair')}</Label>
              {data?.dailyData?.journal_entry?.hair ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.hair}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.digestion && (
            <DataGroup>
              <Label>{t('common.dailyData.digestion')}</Label>
              {data?.dailyData?.journal_entry?.digestion ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.digestion}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.cravings && (
            <DataGroup>
              <Label>{t('common.dailyData.cravings')}</Label>
              {data?.dailyData?.journal_entry?.cravings ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.cravings}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.pain && (
            <DataGroup>
              <Label>{t('common.dailyData.pain')}</Label>
              {data?.dailyData?.journal_entry?.pain ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.pain}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.cervical_position && (
            <DataGroup>
              <Label>{t('common.dailyData.cervicalPosition')}</Label>
              {data?.dailyData?.journal_entry?.cervical_position ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.cervical_position}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.cervical_fluid && (
            <DataGroup>
              <Label>{t('common.dailyData.cervicalFluid')}</Label>
              {data?.dailyData?.journal_entry?.cervical_fluid ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.cervical_fluid}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.period_flow && (
            <DataGroup>
              <Label>{t('common.dailyData.period')}</Label>
              {data?.dailyData?.journal_entry?.period_flow ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.period_flow}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.breakthrough_bleeding && (
            <DataGroup>
              <Label>{t('common.dailyData.breakThroughBleeding')}</Label>
              {data?.dailyData?.journal_entry?.breakthrough_bleeding ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.breakthrough_bleeding}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.hot_flashes && (
            <DataGroup>
              <Label>{t('common.dailyData.hotFlashes')}</Label>
              {data?.dailyData?.journal_entry?.hot_flashes ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.hot_flashes}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.breast_tenderness && (
            <DataGroup>
              <Label>{t('common.dailyData.breastTenderness')}</Label>
              {data?.dailyData?.journal_entry?.breast_tenderness ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.breast_tenderness}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.cramps && (
            <DataGroup>
              <Label>{t('common.dailyData.cramps')}</Label>
              {data?.dailyData?.journal_entry?.cramps ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.cramps}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.nausea && (
            <DataGroup>
              <Label>{t('common.dailyData.nausea')}</Label>
              {data?.dailyData?.journal_entry?.nausea ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.nausea}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.joint_pain && (
            <DataGroup>
              <Label>{t('common.dailyData.jointPain')}</Label>
              {data?.dailyData?.journal_entry?.joint_pain ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.joint_pain}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.back_pain && (
            <DataGroup>
              <Label>{t('common.dailyData.backPain')}</Label>
              {data?.dailyData?.journal_entry?.back_pain ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.back_pain}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.headache && (
            <DataGroup>
              <Label>{t('common.dailyData.headache')}</Label>
              {data?.dailyData?.journal_entry?.headache ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.headache}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.migraine && (
            <DataGroup>
              <Label>{t('common.dailyData.migraine')}</Label>
              {data?.dailyData?.journal_entry?.migraine ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.migraine}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.body_hair_growth && (
            <DataGroup>
              <Label>{t('common.dailyData.bodyHairGrowth')}</Label>
              {data?.dailyData?.journal_entry?.body_hair_growth ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.body_hair_growth}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.skin_changes && (
            <DataGroup>
              <Label>{t('common.dailyData.skinChanges')}</Label>
              {data?.dailyData?.journal_entry?.skin_changes ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.skin_changes}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.acne && (
            <DataGroup>
              <Label>{t('common.dailyData.acne')}</Label>
              {data?.dailyData?.journal_entry?.acne ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.acne}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.painful_urination && (
            <DataGroup>
              <Label>{t('common.dailyData.painfulUrination')}</Label>
              {data?.dailyData?.journal_entry?.painful_urination ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.painful_urination}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.urination_frequency && (
            <DataGroup>
              <Label>{t('common.dailyData.urinationFrequency')}</Label>
              {data?.dailyData?.journal_entry?.urination_frequency ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.urination_frequency}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.incontinence && (
            <DataGroup>
              <Label>{t('common.dailyData.incontinence')}</Label>
              {data?.dailyData?.journal_entry?.incontinence ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.incontinence}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.sexual_activity && (
            <DataGroup>
              <Label>{t('common.dailyData.sexualActivity')}</Label>
              {data?.dailyData?.journal_entry?.sexual_activity ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.sexual_activity}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.sex_drive && (
            <DataGroup>
              <Label>{t('common.dailyData.sexDrive')}</Label>
              {data?.dailyData?.journal_entry?.sex_drive ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.sex_drive}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.exercise_intensity && (
            <DataGroup>
              <Label>{t('common.dailyData.exerciseIntesity')}</Label>
              {data?.dailyData?.journal_entry?.exercise_intensity ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.exercise_intensity}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.exercise_perceived_effort && (
            <DataGroup>
              <Label>{t('common.dailyData.exercisePerceivedEffort')}</Label>
              {data?.dailyData?.journal_entry?.exercise_perceived_effort ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.exercise_perceived_effort}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.hours_of_sleep && (
            <DataGroup>
              <Label>{t('common.dailyData.hoursOfSleep')}</Label>
              {data?.dailyData?.journal_entry?.hours_of_sleep ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.hours_of_sleep}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.sleep_quality && (
            <DataGroup>
              <Label>{t('common.dailyData.sleepQuality')}</Label>
              {data?.dailyData?.journal_entry?.sleep_quality ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.sleep_quality}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.dreams && (
            <DataGroup>
              <Label>{t('common.dailyData.dreams')}</Label>
              {data?.dailyData?.journal_entry?.dreams ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.dreams}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.dreams && (
            <DataGroup>
              <Label>{t('common.dailyData.dreams')}</Label>
              {data?.dailyData?.journal_entry?.dreams ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.dreams}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.mood && (
            <DataGroup>
              <Label>{t('common.dailyData.mood')}</Label>
              {data?.dailyData?.journal_entry?.mood ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.mood}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.anxiety && (
            <DataGroup>
              <Label>{t('common.dailyData.anxiety')}</Label>
              {data?.dailyData?.journal_entry?.anxiety ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.anxiety}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.motivation && (
            <DataGroup>
              <Label>{t('common.dailyData.motivation')}</Label>
              {data?.dailyData?.journal_entry?.motivation ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.motivation}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.productivity && (
            <DataGroup>
              <Label>{t('common.dailyData.productivity')}</Label>
              {data?.dailyData?.journal_entry?.productivity ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.productivity}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.focus && (
            <DataGroup>
              <Label>{t('common.dailyData.focus')}</Label>
              {data?.dailyData?.journal_entry?.focus ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.focus}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.brain_fog && (
            <DataGroup>
              <Label>{t('common.dailyData.brainFog')}</Label>
              {data?.dailyData?.journal_entry?.brain_fog ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.brain_fog}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.memory_problems && (
            <DataGroup>
              <Label>{t('common.dailyData.memoryProblems')}</Label>
              {data?.dailyData?.journal_entry?.memory_problems ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.memory_problems}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.energy && (
            <DataGroup>
              <Label>{t('common.dailyData.energy')}</Label>
              {data?.dailyData?.journal_entry?.energy ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.energy}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.mood_stability && (
            <DataGroup>
              <Label>{t('common.dailyData.moodStability')}</Label>
              {data?.dailyData?.journal_entry?.mood_stability ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.mood_stability}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.stress && (
            <DataGroup>
              <Label>{t('common.dailyData.stress')}</Label>
              {data?.dailyData?.journal_entry?.stress ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.stress}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.mental && (
            <DataGroup>
              <Label>{t('common.dailyData.mental')}</Label>
              {data?.dailyData?.journal_entry?.mental ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.mental}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.birth_control_pill && (
            <DataGroup>
              <Label>{t('common.dailyData.birthControl')}</Label>
              {data?.dailyData?.journal_entry?.birth_control_pill ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.birth_control_pill}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.medication && (
            <DataGroup>
              <Label>{t('common.dailyData.medication')}</Label>
              {data?.dailyData?.journal_entry?.medication ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.medication}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.injection && (
            <DataGroup>
              <Label>{t('common.dailyData.injection')}</Label>
              {data?.dailyData?.journal_entry?.injection ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.injection}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.ailment && (
            <DataGroup>
              <Label>{t('common.dailyData.ailment')}</Label>
              {data?.dailyData?.journal_entry?.ailment ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.ailment}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.drinking && (
            <DataGroup>
              <Label>{t('common.dailyData.drinking')}</Label>
              {data?.dailyData?.journal_entry?.drinking ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.drinking}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.cannabis && (
            <DataGroup>
              <Label>{t('common.dailyData.cannabis')}</Label>
              {data?.dailyData?.journal_entry?.cannabis ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.cannabis}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.hangover && (
            <DataGroup>
              <Label>{t('common.dailyData.hangover')}</Label>
              {data?.dailyData?.journal_entry?.hangover ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.hangover}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.bloating && (
            <DataGroup>
              <Label>{t('common.dailyData.bloating')}</Label>
              {data?.dailyData?.journal_entry?.bloating ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.bloating}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.head_hair && (
            <DataGroup>
              <Label>{t('common.dailyData.headHair')}</Label>
              {data?.dailyData?.journal_entry?.head_hair ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.head_hair}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.bloating && (
            <DataGroup>
              <Label>{t('common.dailyData.bloating')}</Label>
              {data?.dailyData?.journal_entry?.bloating ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.bloating}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.journal_entry?.bloating && (
            <DataGroup>
              <Label>{t('common.dailyData.bloating')}</Label>
              {data?.dailyData?.journal_entry?.bloating ? (
                <StyledDataText>
                  {`${data?.dailyData?.journal_entry?.bloating}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.spike_info?.resting_hr && (
            <DataGroup>
              <Label>{t('common.dailyData.spikeInfo.restingHr')}</Label>
              {data?.dailyData?.spike_info?.resting_hr ? (
                <StyledDataText>
                  {`${data?.dailyData?.spike_info?.resting_hr} ${Constants.HEART_RATE_UNIT}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.spike_info?.avg_hr && (
            <DataGroup>
              <Label>{t('common.dailyData.spikeInfo.avgHr')}</Label>
              {data?.dailyData?.spike_info?.avg_hr ? (
                <StyledDataText>
                  {`${data?.dailyData?.spike_info?.avg_hr} ${Constants.HEART_RATE_UNIT}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.spike_info?.floors && (
            <DataGroup>
              <Label>{t('common.dailyData.spikeInfo.floors')}</Label>
              {data?.dailyData?.spike_info?.floors ? (
                <StyledDataText>
                  {`${data?.dailyData?.spike_info?.floors}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.spike_info?.steps && (
            <DataGroup>
              <Label>{t('common.dailyData.spikeInfo.steps')}</Label>
              {data?.dailyData?.spike_info?.steps ? (
                <StyledDataText>
                  {`${data?.dailyData?.spike_info?.steps}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
          {data?.dailyData?.spike_info?.calories_total && (
            <DataGroup>
              <Label>{t('common.dailyData.spikeInfo.caloriesTotal')}</Label>
              {data?.dailyData?.spike_info?.calories_total ? (
                <StyledDataText>
                  {`${data?.dailyData?.spike_info?.calories_total} ${Constants.CALORIES_UNIT}`}
                </StyledDataText>
              ) : (
                '-'
              )}
            </DataGroup>
          )}
        </DataContainer>
      </ContentSection>
    </Container>
  );
};

export default OvHormoneDetails;

const Container = styled.section`
  box-shadow: ${Variables.boxShadow.defaultBox};
  border-radius: ${Variables.borderRadius.SMALL};
  border: 1px solid ${Colours.OV_LIGHT_GRAY};
  padding: 1rem 1rem 0 1rem;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};
  margin: 0 -1rem;
  padding: 0 1rem 1rem 1rem;
`;

const HeaderText = styled.p`
  font-weight: bold;
  margin: 0;
  font-size: ${Variables.fontSizes.MEDIUM};
  text-transform: uppercase;
`;

const Label = styled.p`
  font-weight: 500;
  margin: 0;
  font-size: ${Variables.fontSizes.MEDIUM};
  text-transform: uppercase;
`;

const StyledDataText = styled.p`
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-weight: bold;
`;

const ContentSection = styled.section`
  margin: 0 -1rem;
  padding: 0 1rem;
`;

const SectionHeader = styled.header`
  padding: 1rem 0;
`;

const DataContainer = styled.div`
  background-color: ${Colours.OV_LIGHT_GRAY};
  padding: 1rem;
  margin: 0 -1rem;
`;

const DataGroup = styled.div`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
