import { FC, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

const OvScrollToTop: FC<{ history: History }> = ({ history }) => {
  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unListen();
    };
  }, [history]);

  return null;
};

export default withRouter(OvScrollToTop);
