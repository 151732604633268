import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import styled from 'styled-components';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import SearchIcon from '@mui/icons-material/Search';
import OvGooglePlacesAutocompleteOptions from './OvGooglePlacesAutocompleteOptions';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';

const REQUEST_DELAY = 500;

const AUTOCOMPLETE_INPUT_MIN_LENGTH = 3;

const useStyles = (): { [key: string]: SxProps } => ({
  popupIndicator: {
    display: 'none',
  },
});

interface OvGooglePlacesAutocompleteProps {
  setFormikFields: (place: google.maps.places.PlaceResult | null) => void;
  formik: any;
}

const OvGooglePlacesAutocomplete: FC<OvGooglePlacesAutocompleteProps> = ({
  setFormikFields,
  formik,
}) => {
  const { t } = useTranslation();
  const google = window.google;
  const service = new google.maps.places.AutocompleteService();
  const sessionToken = useMemo(
    () => new google.maps.places.AutocompleteSessionToken(),
    [google.maps.places.AutocompleteSessionToken]
  );
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<google.maps.places.AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  // eslint-disable-next-line
  const loadSuggestions = useCallback(
    debounce((inputValue) => {
      (async () => {
        if (
          !inputValue ||
          inputValue.trim().length <= AUTOCOMPLETE_INPUT_MIN_LENGTH
        ) {
          setSuggestions([]);
          return;
        }

        try {
          const { predictions } = await service.getPlacePredictions({
            input: inputValue,
            types: ['health'],
            sessionToken: sessionToken,
          });

          setSuggestions(predictions ? predictions : []);
        } catch (e: any) {
          console.log(e);
        }
      })();
    }, REQUEST_DELAY),
    []
  );

  useEffect(() => {
    loadSuggestions(inputValue);

    // eslint-disable-next-line
  }, [inputValue]);

  const handleSuggestionSelected = (
    suggestion: google.maps.places.AutocompletePrediction
  ) => {
    setSuggestions([]);

    new google.maps.places.PlacesService(
      document.getElementById('ov-maps-autocomplete')! as HTMLDivElement
    ).getDetails(
      {
        placeId: suggestion.place_id,
        fields: [
          // @see https://developers.google.com/maps/documentation/javascript/place-data-fields
          'name',
          'geometry.location',
          'place_id',
          'address_components',
          'international_phone_number',
          'vicinity',
        ],
        sessionToken,
      },
      (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          setFormikFields(place);

          formik
            .getFieldHelpers('geolocation.longitude')
            .setValue(place.geometry?.location?.lng());
          formik
            .getFieldHelpers('geolocation.latitude')
            .setValue(place.geometry?.location?.lat());
          formik
            .getFieldHelpers('phone1')
            .setValue(place.international_phone_number);
          formik.getFieldHelpers('address1').setValue(place.vicinity);
          formik
            .getFieldHelpers('state')
            .setValue(
              place.address_components?.find((addressComponent) =>
                addressComponent.types.includes('administrative_area_level_1')
              )?.short_name
            );
          formik
            .getFieldHelpers('address2')
            .setValue(
              place.address_components?.find((addressComponent) =>
                addressComponent.types.includes('subpremise')
              )?.long_name
            );
          formik
            .getFieldHelpers('city')
            .setValue(
              place.address_components?.find((addressComponent) =>
                addressComponent.types.includes('locality')
              )?.long_name
            );
          formik
            .getFieldHelpers('zip_code')
            .setValue(
              place.address_components?.find((addressComponent) =>
                addressComponent.types.includes('postal_code')
              )?.long_name
            );
        } else {
          console.log('Error during retrieving place details');
        }
      }
    );
  };

  const handleAutocompleteChange = (
    e: any,
    newValue: google.maps.places.AutocompletePrediction | null
  ) => {
    setSuggestions(newValue ? [newValue, ...suggestions] : suggestions);
    setSelectedSuggestion(newValue);
    setInputValue(newValue?.description ?? '');
    if (newValue) {
      handleSuggestionSelected(newValue);
    }
  };

  return (
    <>
      <Autocomplete
        id="ov-maps-autocomplete"
        style={{
          width: '100%',
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '1rem',
          marginBottom: '0.5rem',
          borderBottom: `1px solid ${Colours.OV_BORDER_COLOR_V3}`,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            paddingRight: '0.625rem !important',
          },
          ...classes.popupIndicator,
        }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        isOptionEqualToValue={(option, value) =>
          option.place_id === value.place_id
        }
        filterOptions={(x) => x}
        options={suggestions}
        autoComplete
        filterSelectedOptions
        value={selectedSuggestion}
        noOptionsText={t('clinicLocationDialogs.noLocations')}
        onChange={handleAutocompleteChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={t('clinicLocationDialogs.search')}
            InputProps={{
              ...params.InputProps,
              endAdornment: <SearchIcon />,
            }}
          />
        )}
        renderOption={(props, option) => (
          <OvGooglePlacesAutocompleteOptions option={option} {...props} />
        )}
      />
    </>
  );
};

export default OvGooglePlacesAutocomplete;

const StyledTextField = styled(OvTextField)`
  && {
    & .MuiOutlinedInput-root {
      padding: 0 1rem;
      border-radius: ${Variables.borderRadius.XLARGE} !important;
    }

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;
