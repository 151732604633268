import React, { FC, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import OvTableRow from '../atoms/OvTableRow';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { KeyboardArrowUp } from '@mui/icons-material';
import { OvTableRowEditModel } from './OvTableRowEditWidget';

export type OvTableRowData = {
  label?: string;
  value?: any;
  secondaryValue?: any;
  prettifiedValue?: any;
  translateLabel?: boolean;
  translateValue?: boolean;
  style?: any;
  editOptions?: OvTableRowEditModel;
  deleteOptions?: OvTableRowDeleteModel;
  createOptions?: OvTableCreateModel;
  linkUrl?: string;
  imageUrl?: string;
  rowId?: string;
  linkShouldNotRedirect?: boolean;
  displayAsHTML?: boolean;
};

export interface OvTableRowDeleteModel {
  deleteAction?: () => void;
  confirmationOptions?: {
    title: string;
    description: string;
  };
}

export interface OvTableCreateModel {
  createAction?: () => void;
  icon: ReactElement;
  disabled: boolean;
}

export type OvTableData = (OvTableRowData & {
  nestedRows?: OvTableRowData[];
  showNestedRows?: boolean;
})[];

const OvTable: FC<{
  data?: OvTableData;
  onSaveRowValue?: (value: any) => void;
  theme?: 'Light' | 'Dark';
  getIdFromRowData?: boolean;
  editable?: boolean;
}> = ({ data, onSaveRowValue, theme, getIdFromRowData, editable }) => {
  const [tableData, setTableData] = useState<OvTableData>([]);

  useEffect(() => {
    setTableData(data || []);
  }, [data]);

  const toggleNestedRows = (index: number) => {
    setTableData(
      tableData.map((row, rowIndex) => {
        if (rowIndex === index) {
          row.showNestedRows = !row.showNestedRows;
        }

        return row;
      })
    );
  };

  return (
    <Table>
      <TableBody>
        {tableData?.map((row, index) => {
          return (
            <React.Fragment key={`${index}-${row.label}`}>
              <OvTableRow
                row={row}
                onSave={onSaveRowValue}
                cursor={row?.nestedRows && 'pointer'}
                hasNestedRows={!!row?.nestedRows?.length}
                toggleNestedRows={() => toggleNestedRows(index)}
                icon={
                  row?.nestedRows?.length &&
                  (row?.showNestedRows ? (
                    <KeyboardArrowUp style={{ float: 'right' }} />
                  ) : (
                    <KeyboardArrowDown style={{ float: 'right' }} />
                  ))
                }
                backgroundColor={
                  row?.nestedRows?.length
                    ? row?.style?.backgroundColor || Colours.OV_HIGHLIGHT
                    : ''
                }
                theme={theme}
                getIdFromRowData={getIdFromRowData}
                editable={editable}
              />

              {row.showNestedRows &&
                row.nestedRows?.map((nestedRow) => (
                  <OvTableRow
                    key={nestedRow.label}
                    icon={null}
                    row={nestedRow}
                    theme={theme}
                    hasNestedRows={false}
                  />
                ))}
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OvTable;

const Table = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;

  tr:last-child {
    border-bottom-left-radius: 10px;
  }
`;

const TableBody = styled.tbody`
  display: table-row-group;
`;
