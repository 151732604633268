import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import StringUtils from '../../../common/utils/services/string-utils';
import { FitType } from '../../../common/types';

export interface OvHormoneCalibrationValidationProps {
  signals?: string;
  concentrations?: string;
  fit_type?: FitType;
  x_values?: string;
  unit?: string;
  maxSignalUsedForModelFit?: number;
  maxConcentrationUsedForModelFit?: number;
  ax_array: number[];
}

interface ChartPoint {
  signal?: number;
  concentration?: number;
  fit_point?: number;
}

const OvHormoneCalibrationValidation: FC<
  OvHormoneCalibrationValidationProps
> = ({
  signals,
  concentrations,
  fit_type,
  x_values,
  unit,
  maxSignalUsedForModelFit,
  maxConcentrationUsedForModelFit,
  ax_array,
}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<ChartPoint[]>([]);
  const X_MAX = (maxConcentrationUsedForModelFit || 0) * 2;
  const Y_MAX = (maxSignalUsedForModelFit || 0) * 2;

  useEffect(() => {
    const signal_array = StringUtils.convertStringToNumberArray(signals);
    const concentration_array =
      StringUtils.convertStringToNumberArray(concentrations);
    const x_array = StringUtils.convertStringToNumberArray(x_values);

    if (
      signal_array.length &&
      signal_array.length === concentration_array.length &&
      ax_array?.length &&
      (fit_type === '4pl' || fit_type === 'linear')
    ) {
      const data: ChartPoint[] = [];
      const [A, B, C, D] = x_array;

      for (let i = 0; i < signal_array.length; i++) {
        data.push({
          signal: signal_array[i] * ax_array[0] + ax_array[1],
          concentration: concentration_array[i],
        });
      }

      for (let i = Math.floor(Y_MAX / 2); i > 0; i--) {
        let point;

        if (fit_type === '4pl') {
          point = +(C * Math.pow((A - D) / (i - D) - 1, 1 / B)).toFixed(2);
        } else {
          point = +(i * A + B).toFixed(2);
        }

        if (point >= X_MAX) {
          point = X_MAX;
        }

        data.push({
          fit_point: i * ax_array[0] + ax_array[1],
          concentration: point,
        });
      }

      setChartData(data);
    } else {
      setChartData([]);
    }
  }, [
    signals,
    concentrations,
    x_values,
    unit,
    ax_array,
    X_MAX,
    Y_MAX,
    fit_type,
  ]);

  return (
    <>
      {chartData.length &&
      maxConcentrationUsedForModelFit &&
      maxSignalUsedForModelFit ? (
        <ChartWrapper>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis
                domain={[0, X_MAX]}
                tickCount={6}
                allowDecimals={false}
                type="number"
                dataKey="concentration"
                label={{
                  value: unit || t('lots.calibration.fields.concentration'),
                  dy: 24,
                }}
              />
              <YAxis
                domain={[0, maxSignalUsedForModelFit * 2]}
                type="number"
                tickCount={6}
                allowDecimals={false}
                dataKey="signal"
                label={{
                  value: t('lots.calibration.fields.signal'),
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Line
                type="monotone"
                dataKey="signal"
                stroke="none"
                dot={{ r: 4, fill: '#4472c4' }}
              />
              <Line
                type="monotone"
                dataKey="fit_point"
                strokeWidth={1}
                stroke="#ed7d31"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </ChartWrapper>
      ) : (
        ''
      )}
    </>
  );
};

export default OvHormoneCalibrationValidation;

const ChartWrapper = styled.div`
  height: 30rem;
`;
