import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../states/auth.state';
import * as firebase from 'firebase/app';
import {
  getEntitlements,
  login,
  logout,
  setPassword,
} from '../thunks/auth.thunk';
import { Entitlement } from '../../common/model/dto/entitlement';
import { AxiosError } from 'axios';

const initialState: AuthState = {
  authenticated: undefined,
  loading: false,
  isLoggedIn: undefined,
  errorMessage: '',
  userId: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<string>) => {
      state.isLoggedIn = !!action.payload;
      state.authenticated = !!action.payload;
      state.errorMessage = '';
      state.userId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.errorMessage = '';
        state.authenticated = true;
        state.isLoggedIn = true;
        state.userId = action.payload;
      })
      .addCase(
        login.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.errorMessage = action.payload.message;
        }
      );

    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.userId = '';
        state.loading = false;
        state.errorMessage = '';
        state.authenticated = false;
        state.isLoggedIn = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = JSON.stringify(action.payload);
      });

    builder
      .addCase(getEntitlements.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getEntitlements.fulfilled,
        (state, action: PayloadAction<Entitlement[]>) => {
          state.loading = false;
          state.entitlements = action.payload;
        }
      )
      .addCase(getEntitlements.rejected, (state) => {
        state.loading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(setPassword.pending, (state) => {
        state.loading = true;
        state.errorMessage = '';
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(
        setPassword.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          const data = action.payload.response?.data as any;
          state.loading = false;
          state.errorMessage = data.message;
        }
      );
  },
});

export const { setAuthData } = authSlice.actions;
export default authSlice.reducer;
