import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OvTextField from '../atoms/OvTextField';
import DialogActions from '@mui/material/DialogActions';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import { CreateClinicLocationRequest } from '../../../common/model/dto/create-clinic-location-request';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { cleanRequestObject } from '../../../common/utils/services/helpers';
import { Clinic } from '../../../common/model/dto/clinic';
import { ClinicLocationService } from '../../../services/clinic-location.service';
import { debounce } from 'lodash';
import { ClinicLocationFields } from '../../../firebase/document-field.enums';
import { Regexes } from '../../../common/model/type/regexes.type';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import {
  CLINIC_LOCATION_ID_MAX_LENGTH,
  CLINIC_LOCATION_ID_MIN_LENGTH,
} from '../../../common/model/type/id-validation';
import OvGooglePlacesAutocomplete from './OvGooglePlacesAutocomplete';

export interface OvCreateClinicLocationDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave?: (request: any) => void;
  title?: string;
  selectedClinic?: Clinic;
}

const REQUEST_DELAY = 1000;

const OvCreateClinicLocationDialog: FC<OvCreateClinicLocationDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
  title,
  selectedClinic,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    doesClinicLocationEmailAlreadyExist,
    setDoesClinicLocationEmailAlreadyExist,
  ] = useState<boolean | undefined>(undefined);
  const [
    doesClinicLocationIdAlreadyExist,
    setDoesClinicLocationIdAlreadyExist,
  ] = useState<boolean | undefined>(undefined);

  const formik = useFormik<CreateClinicLocationRequest>({
    initialValues: {
      name: '',
      email: undefined,
      address1: '',
      address2: '',
      phone1: '',
      phone2: '',
      city: '',
      state: '',
      zip_code: '',
      clinic_location_id: '',
      geolocation: undefined,
    },
    enableReinitialize: true,
    onSubmit: (request: CreateClinicLocationRequest) => {
      if (onSave) {
        onSave(cleanRequestObject(request));
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(
        t('clinicLocationDialogs.clinicSiteNameRequired')
      ),
      clinic_location_id: Yup.string()
        .required(t('clinicLocationDialogs.clinicSiteIdRequired'))
        .uppercase(t('clinicLocationDialogs.onlyUpperCase'))
        .strict()
        .test(
          'len',
          t('clinicLocationDialogs.clinicLocationIdFormat'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return (
              val.length >= CLINIC_LOCATION_ID_MIN_LENGTH &&
              val.length <= CLINIC_LOCATION_ID_MAX_LENGTH
            );
          }
        )
        .test(
          'checkIfExists',
          t('clinicLocationDialogs.clinicSiteIdAlreadyTaken'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return !doesClinicLocationIdAlreadyExist;
          }
        ),
      email: Yup.string()
        .test(
          'emailValidation',
          t('clinicLocationDialogs.isvalidEmail'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return Regexes.isValidEmail.test(val);
          }
        )
        .test(
          'checkIfExists',
          t('clinicLocationDialogs.clinicSiteEmailAlreadyTaken'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return !doesClinicLocationEmailAlreadyExist;
          }
        ),
    }),
    validateOnBlur: true,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const doesClinicLocationExistWithClinicLocationId = (
    clinicLocationId: string
  ) => {
    return ClinicLocationService.doesClinicLocationExistWithClinicLocationId(
      clinicLocationId
    );
  };

  const doesClinicLocationExistWithEmail = (email: string) => {
    return ClinicLocationService.doesClinicLocationExistWithEmail(email);
  };

  // eslint-disable-next-line
  const checkIfClinicLocationExistsWithClinicLocationIdDelayed = useCallback(
    debounce((inputValue) => {
      (async () => {
        if (inputValue.length >= CLINIC_LOCATION_ID_MIN_LENGTH) {
          setIsLoading(true);
          const alreadyExists =
            await doesClinicLocationExistWithClinicLocationId(inputValue);

          setDoesClinicLocationIdAlreadyExist(alreadyExists);
        }

        setIsLoading(false);
        formik.setFieldTouched(ClinicLocationFields.clinic_location_id);
        document
          .getElementById(ClinicLocationFields.clinic_location_id)
          ?.focus();
      })();
    }, REQUEST_DELAY),
    []
  );

  // eslint-disable-next-line
  const checkIfClinicLocationExistsWithEmail = useCallback(
    debounce((inputValue) => {
      (async () => {
        setIsLoading(true);
        const alreadyExists = await doesClinicLocationExistWithEmail(
          inputValue
        );

        setDoesClinicLocationEmailAlreadyExist(alreadyExists);

        setIsLoading(false);
        formik.setFieldTouched(ClinicLocationFields.email);
        document.getElementById(ClinicLocationFields.email)?.focus();
      })();
    }, REQUEST_DELAY),
    []
  );

  const handleChange = async (e: any) => {
    const inputField = e.target.name;
    const inputValue = e.target.value;

    if (inputField === ClinicLocationFields.clinic_location_id) {
      setDoesClinicLocationIdAlreadyExist(false);
    } else if (inputField === ClinicLocationFields.email) {
      setDoesClinicLocationEmailAlreadyExist(false);
    }

    if (
      inputField === ClinicLocationFields.clinic_location_id &&
      inputValue.length >= CLINIC_LOCATION_ID_MIN_LENGTH &&
      inputValue.length <= CLINIC_LOCATION_ID_MAX_LENGTH
    ) {
      checkIfClinicLocationExistsWithClinicLocationIdDelayed(
        `${selectedClinic?.clinic_id}-${inputValue}`
      );
    } else if (
      inputField === ClinicLocationFields.email &&
      Regexes.isValidEmail.test(inputValue)
    ) {
      checkIfClinicLocationExistsWithEmail(inputValue);
    }

    formik.handleChange(e);
  };

  const setFormikFields = (place: google.maps.places.PlaceResult | null) => {
    if (!place) {
      return;
    }

    const city = place.address_components?.find((addressComponent) =>
      addressComponent.types.includes('locality')
    )?.long_name;

    formik.getFieldHelpers('name').setValue(`${place.name}, ${city}`);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby={title}
      aria-describedby={title}
    >
      <StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
      <form onSubmit={formik.handleSubmit} onChange={handleChange}>
        <StyledDialogContent>
          <OvGooglePlacesAutocomplete
            setFormikFields={setFormikFields}
            formik={formik}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.name')}
            error={formik.touched.name && !!formik.errors.name}
            helperText={
              formik.errors.name && formik.touched.name && formik.errors.name
            }
            {...formik.getFieldProps('name')}
          />
          <StyledDisabledTextField
            type="text"
            disabled={true}
            label={t('common.clinicFields.clinicId')}
            value={selectedClinic?.clinic_id}
          />
          <p>-</p>
          <StyledTextFieldWithPrefix
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            id="clinic_location_id"
            label={t('common.clinicFields.clinicSiteId')}
            error={
              formik.touched.clinic_location_id &&
              !!formik.errors.clinic_location_id
            }
            helperText={
              formik.errors.clinic_location_id &&
              formik.touched.clinic_location_id &&
              formik.errors.clinic_location_id
            }
            {...formik.getFieldProps('clinic_location_id')}
          />
          <StyledTextField
            type="email"
            fullWidth
            autoComplete="off"
            id="email"
            disabled={isLoading}
            label={t('common.clinicLocationFields.email')}
            error={formik.touched.email && !!formik.errors.email}
            helperText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
            {...formik.getFieldProps('email')}
          />
          <StyledHalfLengthTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.address-1')}
            {...formik.getFieldProps('address1')}
          />
          <StyledHalfLengthTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.address-2')}
            {...formik.getFieldProps('address2')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.city')}
            {...formik.getFieldProps('city')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.state')}
            {...formik.getFieldProps('state')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.zipCode')}
            {...formik.getFieldProps('zip_code')}
          />
          <StyledHalfLengthTextField
            type="tel"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.phone-1')}
            {...formik.getFieldProps('phone1')}
          />
          <StyledHalfLengthTextField
            type="tel"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicLocationFields.phone-2')}
            {...formik.getFieldProps('phone2')}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton disabled={isLoading} type="submit">
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
        {isLoading && <OvLoadingIndicator />}
      </form>
    </Dialog>
  );
};

export default OvCreateClinicLocationDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    padding: 1.5rem !important;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDisabledTextField = styled(OvTextField)`
  && {
    flex: 1 0 20%;
    height: 100%;
    background-color: ${Colours.OV_DISABLED};
    color: ${Colours.OV_BASE};

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
    }
  }
`;

const StyledTextFieldWithPrefix = styled(OvTextField)`
  && {
    flex: 1 0 60%;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledHalfLengthTextField = styled(OvTextField)`
  && {
    flex: 1 0 40%;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;
