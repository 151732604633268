import React, { FC, useState } from 'react';
import { OrderItem } from '../../../common/model/dto/order/order-item';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import OvOrderItem from './OvOrderItem';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import OvConfirmationDialog from './OvConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import OvOrderItemFormDialog from './OvOrderItemFormDialog';

export interface OvEditableOrderItemsProps {
  items: OrderItem[];
  onChange: (items: OrderItem[]) => void;
}

const OvEditableOrderItems: FC<OvEditableOrderItemsProps> = ({
  items,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [isOpenOrderItemDialog, setOpenOrderItemDialog] =
    useState<boolean>(false);
  const [deletableItemSku, setDeletableItemSku] = useState<string>();
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<OrderItem | null>();

  const openDeleteDialog = (sku?: string) => {
    setDeletableItemSku(sku);
    setOpenDeleteDialog(true);
  };

  const onDelete = () => {
    if (deletableItemSku) {
      setOpenDeleteDialog(false);
      onChange([...items.filter(({ sku }) => sku !== deletableItemSku)]);
    }
  };

  const onSave = (orderItem: OrderItem) => {
    setSelectedOrderItem(null);
    setOpenOrderItemDialog(false);

    let modifiedItems;
    const modifiedOrderItem: OrderItem | undefined = items.find(
      ({ sku }) => sku === orderItem.sku
    );
    if (modifiedOrderItem) {
      modifiedItems = items.map((item) =>
        item.sku === modifiedOrderItem.sku ? orderItem : item
      );

      onChange(modifiedItems);
    } else {
      modifiedItems = [...items];
      modifiedItems.push(orderItem);
    }

    onChange(modifiedItems);
  };

  const openOrderItemDialog = (orderItem?: OrderItem) => {
    setSelectedOrderItem(orderItem);
    setOpenOrderItemDialog(true);
  };

  return (
    <>
      <StyledOvButton onClick={() => openOrderItemDialog()}>
        <StyledAddCircleIcon />
        {t('common.actions.add')}
      </StyledOvButton>

      {items?.length ? (
        items.map((item) => (
          <ItemWrapper>
            <OvOrderItem item={item} />
            <HorizontalSeparator />
            <ActionsWrapper>
              <StyledActionButton onClick={() => openOrderItemDialog(item)}>
                <EditIcon
                  style={{
                    marginRight: '0.5rem',
                    fontSize: '1rem',
                    paddingLeft: '0',
                  }}
                />
                {t('common.actions.edit')}
              </StyledActionButton>
              <StyledActionButton onClick={() => openDeleteDialog(item.sku)}>
                <ClearIcon
                  style={{
                    marginRight: '0.5rem',
                    fontSize: '1rem',
                    paddingLeft: '0',
                  }}
                />
                {t('common.actions.delete')}
              </StyledActionButton>
            </ActionsWrapper>
          </ItemWrapper>
        ))
      ) : (
        <EmptyList>{t('orders.details.item.emptyList')}</EmptyList>
      )}

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteDialog}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={onDelete}
        description="orders.details.permanentItemRemove"
        title="common.areYouSure"
      />

      <OvOrderItemFormDialog
        isOpen={isOpenOrderItemDialog}
        onCancel={() => setOpenOrderItemDialog(false)}
        onSave={onSave}
        title={
          selectedOrderItem
            ? t('orders.details.editOrderItem', {
                name: selectedOrderItem?.sku,
              })
            : t('orders.details.createItem')
        }
        orderItem={selectedOrderItem}
      />
    </>
  );
};

export default OvEditableOrderItems;

const ItemWrapper = styled.article`
  padding: 0.75rem;
  margin: 1rem 0;
  border: 1px solid ${Colours.OV_WHITE_HOVER};
  border-radius: ${Variables.borderRadius.MEDIUM};
`;

const HorizontalSeparator = styled.hr`
  height: 1px;
  background: ${Colours.GRAY};
  width: 100%;
  margin: 0.75rem 0;
`;

const ActionsWrapper = styled.footer`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: flex-end;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    background-color: ${Colours.OV_BASE};
    margin-left: auto;
  }
`;

const StyledActionButton = styled(OvButton)`
  && {
    padding: 0 0.5rem;
    background: transparent;
    background-color: transparent;
    color: ${Colours.OV_BASE};
    max-height: 1.5rem;
    text-transform: none;

    &:hover {
      background-color: ${Colours.OV_WHITE_HOVER};
    }
  }
`;

const StyledAddCircleIcon = styled(AddCircleIcon)`
  margin-right: 0.5rem;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const EmptyList = styled.p`
  font-style: italic;
  color: ${Colours.GRAY};
  margin-top: 1.5rem;
  margin-bottom: 0;
`;
