import React, { FC } from 'react';
import styled from 'styled-components';

const OvListTableRow: FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => <StyledTableRow {...props}>{children}</StyledTableRow>;

export default OvListTableRow;

const StyledTableRow = styled.tr`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;

    td {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
`;
