import React, { useEffect, useState } from 'react';
import usePaginationQueryParams from '../../hooks/use-pagination-query-params';
import useAxios from 'axios-hooks';
import { OrderBy, Paginated, SortDirection } from '../../common/types';
import { NotificationContent } from '../../common/model/dto/notification/notification-content';
import { ADMIN_NOTIFICATION_CONTENTS_ROUTE } from '../../api/backend';
import { NotificationContentFields } from '../../firebase/document-field.enums';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import NotificationsIcon from '@mui/icons-material/Notifications';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvPagination from '../UI/atoms/OvPagination';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { PaginationItem } from '@mui/material';
import OvListTable from '../UI/atoms/OvListTable';
import OvListTableRow from '../UI/atoms/OvListTableRow';
import OvListTableHeaderField from '../UI/atoms/OvListTableHeaderField';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvNotificationContentListRow from '../UI/organisms/OvNotificationContentListRow';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvCreateNotificationsContentDialog from '../UI/molecules/OvCreateNotificationsContentDialog';
import { CreateNotificationContentRequest } from '../../common/model/dto/notification/create-notification-content-request';
import { createNotificationContent } from '../../services/admin/admin-notification-content.service';
import OvNoContent from '../UI/molecules/OvNoContent';

const NOTIFICATION_CONTENTS_LIST_LIMIT = 10;

const NotificationContentsList = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const { offset, orderBy, orderDir, setOffset, setOrderBy, setOrderDir } =
    usePaginationQueryParams();

  const [{ data, loading, error }, refetch] = useAxios<
    Paginated<NotificationContent>
  >(
    {
      url: `${ADMIN_NOTIFICATION_CONTENTS_ROUTE}`,
      method: 'GET',
      params: {
        limit: NOTIFICATION_CONTENTS_LIST_LIMIT,
        offset,
        order_by: orderBy,
        order_dir: orderDir,
      },
    },
    { useCache: false, manual: !orderBy && !orderDir }
  );

  useEffect(() => {
    setOffset(0);
    setOrderBy('created_at');
    setOrderDir('desc');
  }, [setOrderDir, setOrderBy, setOffset]);

  const notificationContentList: NotificationContent[] = data?.docs ?? [];

  const pageCount: () => number = () =>
    Math.ceil((data?.total ?? 0) / NOTIFICATION_CONTENTS_LIST_LIMIT);

  const goToPage = (event: any, value: number) => {
    setOffset((value - 1) * NOTIFICATION_CONTENTS_LIST_LIMIT);
  };

  const getCurrentSortDirection = (
    columnName: NotificationContentFields
  ): SortDirection =>
    columnName === orderBy ? (orderDir as SortDirection) : 'none';

  const onSortByColumn = (orderBy: OrderBy) => {
    setOrderBy(orderBy.order_by);
    setOrderDir(orderBy.order_dir);
  };

  const handleOnSave = async (
    createNotificationContentRequest: CreateNotificationContentRequest
  ) => {
    setIsRequestLoading(true);
    await createNotificationContent(createNotificationContentRequest);
    setIsRequestLoading(false);
    setIsDialogOpen(false);
    refetch();
  };

  const isLoading = loading || isRequestLoading;

  return (
    <Container>
      <OvPageTitle
        title={t('app.subPages.menu.notificationContents')}
        icon={<StyledNotificationIcon />}
      >
        <StyledOvButton onClick={() => setIsDialogOpen(true)}>
          <AddCircleIcon style={{ marginRight: '5' }} />
          {t('common.actions.addNew')}
        </StyledOvButton>
      </OvPageTitle>

      <ContentWrapper>
        <ActionContainer>
          {pageCount() > 1 ? (
            <OvPagination
              page={(data?.offset ?? 0) / NOTIFICATION_CONTENTS_LIST_LIMIT + 1}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
        </ActionContainer>

        <OvListTableWrapper
          style={{
            marginTop: pageCount() > 1 ? '1.5rem' : 0,
          }}
        >
          <OvListTable style={{ minWidth: '80rem' }}>
            <OvListTableRow>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  NotificationContentFields.title
                )}
                sortBy={NotificationContentFields.title}
                onSort={onSortByColumn}
              >
                {t('notificationContents.fields.title')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  NotificationContentFields.category
                )}
                sortBy={NotificationContentFields.category}
                onSort={onSortByColumn}
              >
                {t('notificationContents.fields.category')}
              </OvListTableHeaderField>

              <OvListTableHeaderField>
                {t('notificationContents.fields.isPublished')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('notificationContents.fields.numberOfCreatedNotifications')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  NotificationContentFields.created_at
                )}
                sortBy={NotificationContentFields.created_at}
                onSort={onSortByColumn}
              >
                {t('notificationContents.fields.createdAt')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('notificationContents.fields.updatedAt')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('common.action')}
              </OvListTableHeaderField>
            </OvListTableRow>

            {!loading &&
              notificationContentList.map((notificationContent) => (
                <OvListTableRow key={notificationContent.id}>
                  <OvNotificationContentListRow
                    notificationContent={notificationContent}
                  />
                </OvListTableRow>
              ))}
          </OvListTable>
        </OvListTableWrapper>
      </ContentWrapper>

      {isLoading && <OvLoadingIndicator position="fixed" />}
      {error && <OvErrorMessage message={error.message} />}

      {!notificationContentList?.length && !loading ? (
        <OvNoContent>{t('common.noContent')}</OvNoContent>
      ) : null}

      <OvCreateNotificationsContentDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
      />
    </Container>
  );
};

export default NotificationContentsList;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const StyledNotificationIcon = styled(NotificationsIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const OvListTableWrapper = styled.div`
  overflow: auto hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.125rem 0.7rem 0.125rem 0.35rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
