import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetPasswordAttempt } from '../../common/model/dto/set-password-attempt';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as Yup from 'yup';
import styled from 'styled-components';
import OvPasswordField from '../UI/molecules/OvPasswordField';
import OvButton from '../UI/atoms/OvButton';
import { Regexes } from '../../common/model/type/regexes.type';
import OvPasswordCheckList from '../UI/organisms/OvPasswordCheckList';
import { useHistory } from 'react-router-dom';
import { setPassword } from '../../redux/thunks/auth.thunk';
import { History } from 'history';
import Colours from '../../design-system/colours';

const SetPasswordForm: FC<{ token: string }> = ({ token }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history: History = useHistory();
  const [passwordModel] = useState<SetPasswordAttempt>({
    password: '',
    confirmPassword: '',
  });
  const [hasMinLength, setHasMinLength] = useState(false);
  const [hasAtLeastOneUpperCaseCharacter, setHasAtLeastOneUpperCaseCharacter] =
    useState(false);
  const [hasAtLeastOneLowerCaseCharacter, setHasAtLeastOneLowerCaseCharacter] =
    useState(false);
  const [hasAtLeastOneDigit, setHasAtLeastOneDigit] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [arePasswordsMatching, setArePasswordsMatching] = useState(false);
  const isAllRequirementsMet =
    hasMinLength &&
    hasAtLeastOneUpperCaseCharacter &&
    hasAtLeastOneLowerCaseCharacter &&
    hasAtLeastOneDigit &&
    hasSpecialCharacter &&
    arePasswordsMatching;
  const errorMessage = useAppSelector((state) => state.auth.errorMessage) || '';

  const formik = useFormik<SetPasswordAttempt>({
    initialValues: {
      password: passwordModel.password,
      confirmPassword: passwordModel.confirmPassword,
    },
    onSubmit: ({ password, confirmPassword }) => {
      if (isAllRequirementsMet) {
        dispatch(setPassword({ history, token, password, confirmPassword }));
      }
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(t('login.passwordMissing')),
    }),
    validate: (values) => {
      setHasMinLength(values.password.length >= 8);
      setHasAtLeastOneUpperCaseCharacter(
        Regexes.atLeastOneUpperCaseLetter.test(values.password)
      );
      setHasAtLeastOneLowerCaseCharacter(
        Regexes.atLeastOneLowerCaseLetter.test(values.password)
      );
      setHasAtLeastOneDigit(Regexes.atLeastOneDigit.test(values.password));
      setHasSpecialCharacter(
        Regexes.atLeastOneSpecialCharacter.test(values.password)
      );
      setArePasswordsMatching(
        values.password.length > 0 && values.password === values.confirmPassword
      );
    },
    validateOnBlur: true,
  });

  return (
    <StyledSetPasswordForm onSubmit={formik.handleSubmit}>
      <OvPasswordField
        style={{ marginBottom: '0.875rem' }}
        name="password"
        fullWidth
        label={t('login.passwordLabel') + '*'}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && !!formik.errors.password}
        helperText={
          formik.errors.password &&
          formik.touched.password &&
          formik.errors.password
        }
      />
      <OvPasswordField
        style={{ marginBottom: '0.875rem' }}
        name="confirmPassword"
        fullWidth
        label={t('setPassword.confirmPasswordLabel') + '*'}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.confirmPassword && !!formik.errors.confirmPassword
        }
        helperText={
          formik.errors.confirmPassword &&
          formik.touched.confirmPassword &&
          formik.errors.confirmPassword
        }
      />

      <OvButton type="submit" fullWidth disabled={!isAllRequirementsMet}>
        {t('setPassword.submitCta')}
      </OvButton>

      {errorMessage && (
        <>
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
          <StyledErrorMessage>
            {t('common.pleaseContactOovaAdministratorErrorMsg')}
          </StyledErrorMessage>
        </>
      )}

      <OvPasswordCheckList
        hasMinLength={hasMinLength}
        hasAtLeastOneUpperCaseCharacter={hasAtLeastOneUpperCaseCharacter}
        hasAtLeastOneLowerCaseCharacter={hasAtLeastOneLowerCaseCharacter}
        hasAtLeastOneDigit={hasAtLeastOneDigit}
        hasSpecialCharacter={hasSpecialCharacter}
        arePasswordsMatching={arePasswordsMatching}
      />
    </StyledSetPasswordForm>
  );
};

export default SetPasswordForm;

const StyledSetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0;
`;
