import { FilterType } from '../../common/model/ui/filter.type';
import { AdminClinicLocationState } from '../states/admin-clinic-location.state';
import { RootState } from '../store';

const DEFAULT_SORTING_VALUE = 'id';

const shouldFilterProviderList = (state: RootState): boolean =>
  hasAppliedSortingSelector(state) || hasAppliedProviderFiltersSelector(state);

const shouldFilterPatientList = (state: RootState): boolean =>
  hasAppliedSortingSelector(state) || hasAppliedPatientFiltersSelector(state);

const hasAppliedProviderFiltersSelector = (state: RootState): boolean => {
  const userState = state.adminClinicLocation as AdminClinicLocationState;

  return userState.providerListFilters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

const hasAppliedPatientFiltersSelector = (state: RootState): boolean => {
  const userState = state.adminClinicLocation as AdminClinicLocationState;

  return userState.patientListFilters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

const hasAppliedSortingSelector = (state: RootState): boolean =>
  (
    state.adminClinicLocation as AdminClinicLocationState
  )?.userListSortingOptions.some(
    (sorting) => sorting.direction && sorting.value !== DEFAULT_SORTING_VALUE
  );

export {
  hasAppliedProviderFiltersSelector,
  hasAppliedSortingSelector,
  hasAppliedPatientFiltersSelector,
  shouldFilterProviderList,
  shouldFilterPatientList,
};
