import React, { FC } from 'react';
import DateUtils from '../../../common/utils/services/date-utils';

const OvDateParser: FC<{ date: Date; format: string }> = ({
  date,
  format,
  ...props
}) => {
  const parsedDateStr: string = DateUtils.formatDate(date, format);

  return <time {...props}>{parsedDateStr}</time>;
};

export default OvDateParser;
