import axios from 'axios';
import { Configuration } from '../../common/model/dto/configuration';
import { ADMIN_CONFIGURATION_ROUTE } from '../../api/backend';

export class AdminConfigurationService {
  static async getLatestConfiguration(): Promise<Configuration> {
    const { data } = await axios.get<Configuration>(
      `${ADMIN_CONFIGURATION_ROUTE}`
    );
    return data;
  }

  static async updateConfiguration(
    configuration: Configuration
  ): Promise<Configuration> {
    const { data } = await axios.patch<Configuration>(
      `${ADMIN_CONFIGURATION_ROUTE}`,
      mapConfigObject(configuration)
    );
    return data;
  }

  static async clearConfigurationCache() {
    return await axios.delete<Configuration>(
      `${ADMIN_CONFIGURATION_ROUTE}/cache`
    );
  }
}

function mapConfigObject(configuration: Configuration): Configuration {
  if (configuration.shiphero_public_api_access_token_expiration === '') {
    configuration.shiphero_public_api_access_token_expiration = null;
  }

  if (configuration.shiphero_public_api_access_token_timestamp === '') {
    configuration.shiphero_public_api_access_token_timestamp = null;
  }

  return configuration;
}
