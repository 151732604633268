import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Colours from '../../design-system/colours';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import Variables from '../../design-system/variables';
import { clearSelectedProduct } from '../../redux/reducers/products.slice';
import {
  getProductById,
  updateProduct,
} from '../../redux/thunks/products.thunk';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { Product } from '../../common/model/dto/product/product';
import { useTranslation } from 'react-i18next';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import EditIcon from '@mui/icons-material/Edit';
import { NavLink } from 'react-router-dom-v5-compat';
import { ProductDetailsTabType } from '../../common/model/type/product-details-tab.type';
import { last } from 'lodash';
import { Alert, AlertTitle, Tab, Tabs } from '@mui/material';
import ProductInfo from './ProductInfo';
import ProductPrices from './ProductPrices';
import { Constants } from '../../common/constants';
import OvButton from '../UI/atoms/OvButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import breakpoints from '../../design-system/breakpoints';
import AddIcon from '@mui/icons-material/Add';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const ProductDetails: FC = () => {
  let { productId }: any = useParams();
  const { t } = useTranslation();
  let location = useLocation();

  const applicationOperations = useAuthorized(Resource.Application);
  const productOperations = useAuthorized(Resource.Product);

  const productUpdateAllowed =
    productOperations.update || applicationOperations.supervise;

  let { path, url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState<ProductDetailsTabType>(
    ProductDetailsTabType.PRODUCT_INFO
  );
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector((state) => state.products.loading);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectedProduct: Product = useAppSelector(
    (state) => state.products.selectedProduct
  );
  const history = useHistory();

  const changeTab = (event: any, newValue: ProductDetailsTabType) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(getProductById(productId));

    return () => {
      dispatch(clearSelectedProduct());
    };
  }, [productId, dispatch]);

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(
        location.pathname.split('/')
      ) as ProductDetailsTabType;
      const { PRODUCT_INFO, PRICES } = ProductDetailsTabType;

      if ([PRODUCT_INFO, PRICES].includes(pathName)) {
        setActiveTab(pathName);
      }
    };

    setActiveTabFromUrl();
  }, [dispatch, selectedProduct?.id, location.pathname]);

  const onBackClicked = () => {
    history.push('/products');
  };

  const handleUnarchiveProduct = () => {
    setIsDialogOpen(false);
    dispatch(
      updateProduct({ productId, updateProductRequest: { is_active: true } })
    );
  };

  return (
    <Container>
      <Header>
        <LeftSideContainer>
          <BackCta onClick={onBackClicked}>
            <StyledArrowBackIcon />
            {t('common.actions.back')}
          </BackCta>
          <TitleContainer>
            <Title>{selectedProduct?.title}</Title>
            {selectedProduct?.stripe_product_id && (
              <a
                href={`https://dashboard.stripe.com/${
                  !Constants.IS_PRODUCTION ? 'test/' : ''
                }products/${selectedProduct?.stripe_product_id}`}
                target="blank"
              >
                {t('products.details.stripeProductLink')}
              </a>
            )}
          </TitleContainer>
        </LeftSideContainer>

        {productUpdateAllowed && (
          <ActionsContainer>
            {selectedProduct?.is_active ? (
              <StyledNavLink
                to={`products/${selectedProduct?.id}/prices/create`}
              >
                <OvCompactButton icon={<AddIcon />}>
                  {t('productPrices.actions.createNewPrice')}
                </OvCompactButton>
              </StyledNavLink>
            ) : (
              <OvCompactButton
                icon={<AddIcon />}
                disabled={!selectedProduct?.is_active}
              >
                {t('productPrices.actions.createNewPrice')}
              </OvCompactButton>
            )}

            <StyledNavLink to={`products/edit/${selectedProduct?.id}`}>
              <OvCompactButton icon={<EditIcon />}>
                {t('common.actions.edit')}
              </OvCompactButton>
            </StyledNavLink>
          </ActionsContainer>
        )}
      </Header>

      {!selectedProduct?.is_active && (
        <StyledAlert severity="warning">
          <AlertTitle>{t('products.inactiveProductAlertTitle')}</AlertTitle>
          <StyledAlertContent>
            {t('products.inactiveProductAlertDescription')}
            <OvCompactButton
              style={{ whiteSpace: 'nowrap' }}
              icon={<RestoreFromTrashIcon />}
              onClick={() => setIsDialogOpen(true)}
            >
              {t('productPrices.actions.unarchive')}
            </OvCompactButton>
          </StyledAlertContent>
        </StyledAlert>
      )}

      {selectedProduct?.product_prices.filter(
        (productPrice) =>
          productPrice.active && productPrice.stripe_payment_link_id
      ).length === 0 && (
        <StyledAlert severity="warning">
          <AlertTitle>{t('products.noActivePricesAlertTitle')}</AlertTitle>
          {t('products.noActivePricesAlertDescription')}
        </StyledAlert>
      )}

      <TabContainer>
        <Tabs onChange={changeTab} value={activeTab}>
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={ProductDetailsTabType.PRODUCT_INFO}
            label={t('products.tabs.productInfo')}
            component={Link}
            to={`${url}/${ProductDetailsTabType.PRODUCT_INFO}`}
          />
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={ProductDetailsTabType.PRICES}
            label={t('products.tabs.prices')}
            component={Link}
            to={`${url}/${ProductDetailsTabType.PRICES}`}
            replace
          />
        </Tabs>
      </TabContainer>

      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/product-info`} />
        </Route>
        <Route path={`${url}/product-info`}>
          <ProductInfo selectedProduct={selectedProduct} />
        </Route>
        <Route path={`${path}/prices`}>
          <ProductPrices productId={productId} />
        </Route>
      </Switch>

      {isLoading && <OvLoadingIndicator position="fixed" />}

      <OvConfirmationDialog
        icon={<StyledRestoreFromTrashIcon />}
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={handleUnarchiveProduct}
        description="products.actions.unarchiveDescription"
        title="common.areYouSure"
      />
    </Container>
  );
};

export default ProductDetails;

const Container = styled.section`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
`;

const Title = styled.h3`
  flex-grow: 1;
  margin: 0 0.75rem 0 0;
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackCta = styled(OvButton)`
  && {
    padding: 0 10px 0 4px;
    margin-right: 16px;
    border-radius: ${Variables.borderRadius.XLARGE};
    background-color: ${Colours.OV_BASE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 4px;
  height: 20px;
`;

const ActionsContainer = styled.div`
  flex-grow: 1;
  min-width: 13rem;
  display: flex;
  flex-wrap: 'wrap';
  gap: 1rem;
  justify-content: flex-end;
  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }
`;

const StyledNavLink = styled(NavLink)`
  &&  {
    text-decoration: none;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};
    gap: 2rem;
    width: 100%;

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;

const StyledAlertContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledRestoreFromTrashIcon = styled(RestoreFromTrashIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
