import React, { FC } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../../styles/date-range-picker-custom-styles.scss';
import { DateRange, DateRangePickerProps } from 'react-date-range';
import styled from 'styled-components';
import OvCalendarInfo from './OvCalendarInfo';

const OvDateRangePicker: FC<DateRangePickerProps> = ({ ...props }) => {
  return (
    <Wrapper>
      <DateRange {...props} />
      <OvCalendarInfo theme="dark" />
    </Wrapper>
  );
};

export default OvDateRangePicker;

const Wrapper = styled.div`
  padding: 0.25rem;
`;
