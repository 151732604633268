import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import { useFormik } from 'formik';
import { CreateScanSequenceRequest } from '../../../common/model/dto/scan-sequences/create-scan-sequence-request.dto';
import * as Yup from 'yup';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import OvRadioGroup from './OvRadioGroup';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import OvSelect from '../atoms/OvSelect';
import { HealthGoalType } from '../../../common/model/dto/health-goal-type.enum';

const HEALTH_GOALS = Object.values(HealthGoalType);

const OvCreateScanSequenceDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: (request: CreateScanSequenceRequest) => void;
}> = ({ isOpen, onCancel, onSave }) => {
  const { t } = useTranslation();
  const formik = useFormik<CreateScanSequenceRequest>({
    initialValues: {
      health_goal: HEALTH_GOALS[0],
      is_health_goal_default: false,
      scanning_plan_type: '',
      order: 1,
      scanning_plans: [],
      is_clinical_monitoring_recommended: false,
      description: '',
    },
    onSubmit: (request) => {
      onSave(request);
    },
    validationSchema: Yup.object().shape({
      health_goal: Yup.string().required('Health goal is required'),
      is_health_goal_default: Yup.boolean().required(),
      scanning_plan_type: Yup.string().required(
        'Scanning plan type is required'
      ),
      order: Yup.number()
        .min(1, 'Order must be higher than 0')
        .max(5, 'Order must be lower than 6')
        .required('Order is required'),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogTitle>
          {t('scanSequenceDetails.createScanSequence')}
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledLabel>{t('common.scanSequenceFields.healthGoal')}</StyledLabel>
          <StyledSelect
            labelId="health-goal"
            {...formik.getFieldProps('health_goal')}
            value={formik.values.health_goal}
          >
            {HEALTH_GOALS.map((goal) => (
              <StyledMenuItem key={goal} value={goal}>
                {goal}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            label={t('common.scanSequenceFields.scanningPlanType')}
            error={
              formik.touched.scanning_plan_type &&
              !!formik.errors.scanning_plan_type
            }
            helperText={
              formik.errors.scanning_plan_type &&
              formik.touched.scanning_plan_type &&
              formik.errors.scanning_plan_type
            }
            {...formik.getFieldProps('scanning_plan_type')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            multiline
            label={t('common.scanSequenceFields.description')}
            error={formik.touched.description && !!formik.errors.description}
            helperText={
              formik.errors.description &&
              formik.touched.description &&
              formik.errors.description
            }
            {...formik.getFieldProps('description')}
          />
          <StyledTextField
            type="number"
            fullWidth
            autoComplete="off"
            InputProps={{
              inputProps: {
                max: 5,
                min: 1,
              },
            }}
            label={t('common.scanSequenceFields.order')}
            error={formik.touched.order && !!formik.errors.order}
            helperText={
              formik.errors.order && formik.touched.order && formik.errors.order
            }
            {...formik.getFieldProps('order')}
          />
          <StyledLabel>
            {t('common.scanSequenceFields.isHealthGoalDefault')}
          </StyledLabel>
          <StyledOvRadioGroup
            options={[
              {
                label: t('common.actions.yes'),
                value: true,
              },
              {
                label: t('common.actions.no'),
                value: false,
              },
            ]}
            {...formik.getFieldProps('is_health_goal_default')}
            onChange={(event, value) => {
              formik.setFieldValue('is_health_goal_default', value === 'true');
            }}
          />
          <StyledLabel>
            {t('common.scanSequenceFields.isClinicalMonitoringRecommended')}
          </StyledLabel>
          <StyledOvRadioGroup
            options={[
              {
                label: t('common.actions.yes'),
                value: true,
              },
              {
                label: t('common.actions.no'),
                value: false,
              },
            ]}
            {...formik.getFieldProps('is_clinical_monitoring_recommended')}
            onChange={(event, value) => {
              formik.setFieldValue(
                'is_clinical_monitoring_recommended',
                value === 'true'
              );
            }}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton type="submit">
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

export default OvCreateScanSequenceDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledOvRadioGroup = styled(OvRadioGroup)`
  && {
    margin-left: 0.125rem;
    flex: 1 0 100%;
    margin-bottom: 1rem;

    .MuiFormControlLabel-root {
      margin-right: 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }

    .MuiRadio-root {
      color: ${Colours.OV_BASE};
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    margin-bottom: 1rem;
    max-width: none;
    height: 3rem;

    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledLabel = styled.label`
  font-size: ${Variables.fontSizes.MEDIUM};
  margin-right: 0.5rem;
`;
