import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import Variables from '../../../design-system/variables';

const OvRadioGroup: FC<
  RadioGroupProps & {
    options: { value: any; label: string }[] | undefined;
    disabled?: boolean;
  }
> = ({ children, options, disabled, ...props }) => {
  const { t } = useTranslation();

  return (
    <StyledRadioGroup {...props}>
      {options?.length &&
        options?.map((option: { value: any; label: string }) => (
          <StyledFormControlLabel
            disabled={disabled || false}
            key={option.label}
            value={option.value}
            control={<Radio />}
            label={t<string>(option.label)}
          />
        ))}
    </StyledRadioGroup>
  );
};

export default OvRadioGroup;

const StyledRadioGroup = styled(RadioGroup)`
  && {
    &.MuiFormGroup-root {
      flex-direction: row;

      .MuiFormControlLabel-root {
        margin-right: 0.875rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
