import axios from 'axios';
import { Paginated } from '../common/types';
import { DailyData } from '../common/model/dto/daily-data';
import { ADMIN_DAILY_DATA_ROUTE } from '../api/backend';
import { DailyDataCalendarEvent } from '../common/model/ui/daily-data-calendar-event';
import moment from 'moment/moment';

export class DailyDataService {
  static async loadDailyDataListByQuery(query: string) {
    const { data } = await axios.get<Paginated<DailyData>>(
      `${ADMIN_DAILY_DATA_ROUTE}?${query}`
    );

    return data;
  }

  static mapDailyDataToCalendarEvents(
    dailyDataList: DailyData[]
  ): DailyDataCalendarEvent[] {
    return dailyDataList.map((dailyData, index) => {
      const start: Date = moment(dailyData.day, 'YYYY-MM-DD').toDate();
      const end: Date = moment(start).add(1, 'day').toDate();

      return {
        id: dailyData.id,
        title: dailyData.user_id,
        allDay: true,
        start: new Date(start.getFullYear(), start.getMonth(), start.getDate()),
        end: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
        dailyData,
        numberOfScans: dailyData.scans?.length,
      };
    });
  }
}
