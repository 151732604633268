import React, { FC, useState } from 'react';
import OvTextField from '../UI/atoms/OvTextField';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import OvPasswordField from '../UI/molecules/OvPasswordField';
import styled from 'styled-components';
import { SignInAttempt } from '../../common/model/dto/sign-in-attempt';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { login } from '../../redux/thunks/auth.thunk';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useLocation } from 'react-router-dom';

const LoginForm: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation<{ from: string }>();
  const [signInModel] = useState<SignInAttempt>({
    email: '',
    password: '',
    rememberMe: false,
  });
  const formik = useFormik<SignInAttempt>({
    initialValues: {
      email: signInModel.email,
      password: signInModel.password,
      rememberMe: signInModel.rememberMe,
    },
    onSubmit: ({ email, password, rememberMe }) => {
      dispatch(
        login({
          signInData: { email, password, rememberMe },
          location,
        })
      );
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('login.invalidEmail'))
        .required(t('login.emailMissing')),
      password: Yup.string().required(t('login.passwordMissing')),
    }),
    validateOnBlur: true,
  });
  let errorMessage = useAppSelector((state) => state.auth.errorMessage) || '';

  return (
    <StyledLoginForm onSubmit={formik.handleSubmit}>
      <OvTextField
        style={{ marginBottom: '14px' }}
        name="email"
        fullWidth
        autoComplete="off"
        label={t('login.emailLabel') + '*'}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && !!formik.errors.email}
        helperText={
          formik.errors.email && formik.touched.email && formik.errors.email
        }
      />

      <OvPasswordField
        style={{ marginBottom: '14px' }}
        name="password"
        fullWidth
        label={t('login.passwordLabel') + '*'}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && !!formik.errors.password}
        helperText={
          formik.errors.password &&
          formik.touched.password &&
          formik.errors.password
        }
      />

      <StyledOvButton type="submit" fullWidth>
        {t('login.title')}
      </StyledOvButton>

      {errorMessage && (
        <>
          {errorMessage === t('login.youAreNotAllowedToLogin') ? (
            <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
          ) : (
            <StyledErrorMessage>
              {t('login.failedLoginAttempt')}
            </StyledErrorMessage>
          )}
        </>
      )}
      {useAppSelector((state) => state.auth.loading) && <OvLoadingIndicator />}
    </StyledLoginForm>
  );
};

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
  }
`;

const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0;
`;

export default LoginForm;
