export const CLINIC_PROFILE_IMAGES_PATH = '/clinic_profile_images';
export const CLINIC_LOCATION_PROFILE_IMAGES_PATH =
  '/clinic_location_profile_images';
export const USER_PROFILE_IMAGES_PATH = '/user_profile_images';
export const PRODUCT_IMAGES_PATH = '/product_images';
export const OOVA_ARTICLE_ASSETS = '/oova-article-assets';
export const PRICE_IMAGES_PATH = '/price_images';
export const PRICE_THUMBNAIL_IMAGES_PATH = '/price_thumbnail_images';
export const OOVA_NOTIFICATION_CONTENT_IMAGES = '/notification_content_images';
export const OOVA_NOTIFICATION_CONTENT_THUMBNAIL_IMAGES =
  '/notification_content_thumbnail_images';
