import { fieldToTextField, TextFieldProps } from 'formik-mui';
import React, { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const OvPhoneFormControl: FC<TextFieldProps> = ({ children, ...props }) => {
  const {
    form: { setFieldValue },
    field: { name, value },
  } = props;
  const onChange = React.useCallback(
    (event: any) => {
      const { value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );
  const [phone, setPhone] = useState(value);

  const handlePhoneChange = (phone: string) => {
    setPhone(phone);
    onChange({
      target: {
        value: phone,
      },
    });
  };

  useEffect(() => {
    setPhone(props.field?.value);
  }, [props.field?.value]);

  return (
    <>
      <PhoneInput
        defaultCountry="us"
        value={phone}
        onChange={(phone) => handlePhoneChange(phone)}
      />
      <TextField
        style={{ display: 'none' }}
        {...fieldToTextField(props)}
        onChange={onChange}
      />
    </>
  );
};

export default OvPhoneFormControl;
