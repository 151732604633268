import axios from 'axios';
import { stringify } from 'query-string';
import {
  API_PATH_PREFIX,
  CLINIC_LOCATION_PATIENTS_ROUTE,
  CLINIC_LOCATION_PROVIDERS_ROUTE,
  CLINIC_LOCATION_ROUTE,
  DAILY_DATA_ROUTE,
  PROVIDER_PATIENTS_ROUTE,
} from '../api/backend';
import { AssignPatientToClinicLocationRequest } from '../common/model/dto/assign-user-to-clinic-location-request';
import { ClinicLocation } from '../common/model/dto/clinic-location';
import { ClinicLocationPatient } from '../common/model/dto/clinic-location-patient';
import { ProviderPatient } from '../common/model/dto/provider-patient';
import { UserInfo } from '../common/model/dto/user-info';
import { Filter, Paginated } from '../common/types';
import { DailyData } from '../common/model/dto/daily-data';
import { Scan } from '../common/model/dto/scan';
import { CreateDemoAccountRequest } from '../common/model/dto/create-demo-account-request';

export class ClinicLocationService {
  public static readonly CLINIC_LOCATION_USER_LIST_LIMIT: number = 10;

  static async getClinicLocationById(id: string) {
    const { data } = await axios.get<ClinicLocation>(
      `${CLINIC_LOCATION_ROUTE}/${id}`
    );

    return data;
  }

  static async loadProviderFilterData(id: string) {
    const { data } = await axios.get<
      {
        id: string;
        first_name: string;
        last_name: string;
      }[]
    >(`${CLINIC_LOCATION_ROUTE}/${id}/providers`);

    return data;
  }

  static async getClinicLocationPatients(query: string, id?: string) {
    const { data } = await axios.get<Paginated<UserInfo>>(
      `${CLINIC_LOCATION_ROUTE}/${id}/patients?${query}`
    );

    return data;
  }

  static async getClinicLocationPatientsTypeahead(
    searchText: string,
    clinicLocationId: string
  ): Promise<UserInfo[]> {
    const query = stringify({
      search_text: searchText.toUpperCase().split(' ').join(','),
      limit: ClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
      only_active_users: true,
    });

    const { data } = await axios.get<Paginated<UserInfo>>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/patients?${query}`
    );

    return data.docs;
  }

  static async assignPatientToClinicLocation(
    id: string,
    request: AssignPatientToClinicLocationRequest
  ) {
    const { data } = await axios.post<ClinicLocationPatient>(
      `${CLINIC_LOCATION_ROUTE}/${id}/patients`,
      request
    );

    return data;
  }

  static async removePatientFromClinicLocation(
    clinicLocationId: string,
    patientId: string
  ) {
    await axios.delete(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/patients/${patientId}`
    );
  }

  static async getPatientById(
    clinicLocationId: string | undefined,
    patientId: string
  ) {
    const { data } = await axios.get<UserInfo>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/patients/${patientId}`
    );

    return data;
  }

  static async getPatientDailyData(
    clinicLocationId: string,
    patientId: string,
    query?: string
  ) {
    const { data } = await axios.get<Paginated<DailyData>>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/patients/${patientId}/daily-data?${query}`
    );

    return data;
  }

  static async getPatientScans(
    patientId: string,
    clinicLocationId?: string,
    query?: string
  ) {
    const { data } = await axios.get<Paginated<Scan>>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/patients/${patientId}/scans?${query}`
    );

    return data;
  }

  static async getScansForClinicLocation(
    clinicLocationId?: string,
    query?: string
  ) {
    const { data } = await axios.get<Paginated<Scan>>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/scans?${query}`
    );

    return data;
  }

  static async getOwnDailyDataByQuery(query: string) {
    const { data } = await axios.get<Paginated<DailyData>>(
      `${DAILY_DATA_ROUTE}?${query}`
    );

    return data;
  }

  static async assignPatientToProvider(
    providerId: string,
    email: string,
    clinicLocationId?: string
  ) {
    const { data } = await axios.post<ProviderPatient>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/providers/${providerId}/patients`,
      { email }
    );

    return data;
  }

  static async revokePatientProviderAssignment(
    providerId: string,
    patientId: string,
    clinicLocationId?: string
  ) {
    await axios.delete<ProviderPatient>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/providers/${providerId}/patients/${patientId}`
    );
  }

  static async acceptPatientInvitation(token: string) {
    // Creating a new axios instance to not intercept this one request
    const unInterceptedAxiosRequest = axios.create();

    await unInterceptedAxiosRequest.patch(
      `${CLINIC_LOCATION_PATIENTS_ROUTE}/approve/${token}`,
      {},
      { baseURL: `${process.env.REACT_APP_API_URL}${API_PATH_PREFIX}` }
    );
  }

  static async acceptProviderInvitation(token: string) {
    // Creating a new axios instance to not intercept this one request
    const unInterceptedAxiosRequest = axios.create();

    await unInterceptedAxiosRequest.patch(
      `${CLINIC_LOCATION_PROVIDERS_ROUTE}/approve/${token}`,
      {},
      { baseURL: `${process.env.REACT_APP_API_URL}${API_PATH_PREFIX}` }
    );
  }

  static async acceptPatientInvitationFromProvider(token: string) {
    // Creating a new axios instance to not intercept this one request
    const unInterceptedAxiosRequest = axios.create();

    await unInterceptedAxiosRequest.patch(
      `${PROVIDER_PATIENTS_ROUTE}/approve/${token}`,
      {},
      { baseURL: `${process.env.REACT_APP_API_URL}${API_PATH_PREFIX}` }
    );
  }

  static async doesClinicLocationExistWithClinicLocationId(
    clinicLocationId: string
  ) {
    const { data } = await axios.get<boolean>(
      `${CLINIC_LOCATION_ROUTE}/exists/${clinicLocationId}`
    );

    return data;
  }

  static async doesClinicLocationExistWithEmail(email: string) {
    const query = stringify({
      email,
    });

    const { data } = await axios.get<boolean>(
      `${CLINIC_LOCATION_ROUTE}/exists/by-email?${query}`
    );

    return data;
  }

  static async createDemoAccount(
    createDemoAccountRequest: CreateDemoAccountRequest,
    clinicLocationId?: string
  ): Promise<{ user: UserInfo; clinicLocation: ClinicLocation }> {
    const { data } = await axios.post<{
      user: UserInfo;
      clinicLocation: ClinicLocation;
    }>(
      `${CLINIC_LOCATION_ROUTE}/${clinicLocationId}/demo-accounts`,
      createDemoAccountRequest
    );

    return data;
  }

  static getQueryParams(filters: Filter[]) {
    const queryParams: any = {};

    filters.forEach((filter) => {
      queryParams[filter.field] = Array.isArray(filter.value)
        ? filter.value.join(',')
        : filter.value;
    });

    return queryParams;
  }
}
