import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { parseUrl, stringify } from 'query-string';
import { UserInfo } from '../../common/model/dto/user-info';
import { Paginated, SortingOption } from '../../common/types';
import { ClinicLocationService } from '../../services/clinic-location.service';
import {
  restorePatientSearchModel,
  restoreScanListSearchModel,
  setScanListPaging,
  setScanListStoredQuery,
  setUserListPaging,
  setUserListStoredQuery,
} from '../reducers/clinic-location.slice';
import { shouldFilterPatientList } from '../selectors/clinic-location.selectors';
import { RootState } from '../store';
import { History } from 'history';
import UserService from '../../services/user.service';
import { AssignPatientToClinicLocationRequest } from '../../common/model/dto/assign-user-to-clinic-location-request';
import { endOfDay, startOfDay } from 'date-fns';
import { DailyData } from '../../common/model/dto/daily-data';
import { Scan } from '../../common/model/dto/scan';
import { CreateDemoAccountRequest } from '../../common/model/dto/create-demo-account-request';
import { ScansService } from '../../services/scans.service';

const getClinicLocationById = createAsyncThunk(
  'clinicLocation/getClinicLocationById',
  async (clinicLocationId: string, { rejectWithValue }) => {
    try {
      return await ClinicLocationService.getClinicLocationById(
        clinicLocationId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const loadClinicLocationPatients = createAsyncThunk<
  UserInfo[],
  {
    history: History;
    clinicLocationId: string | undefined;
  },
  { state: RootState }
>(
  'clinicLocation/loadPatients',
  async ({ history, clinicLocationId }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>,
        query = '';
      const { offset } = getState().clinicLocation.userListPaging,
        sorting = getUserListQuerySorting(getState()),
        filters = getPatientListQueryFilters(getState());

      if (shouldFilterPatientList(getState())) {
        query = stringify({
          limit: ClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
          order_by: sorting?.orderBy,
          order_dir: sorting?.orderDir,
          offset,
          ...ClinicLocationService.getQueryParams(filters),
        });

        paginatedUsers = await ClinicLocationService.getClinicLocationPatients(
          query,
          clinicLocationId
        );
        dispatch(setUserListStoredQuery(query));
        history.push(`/patients?${query}`);
      } else {
        const query = stringify({
          limit: ClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
          order_by: sorting?.orderBy,
          order_dir: sorting?.orderDir,
          offset,
        });

        paginatedUsers = await ClinicLocationService.getClinicLocationPatients(
          query,
          clinicLocationId
        );
        dispatch(setUserListStoredQuery(offset > 0 ? query : ''));
        history.push(`patients${offset > 0 ? '?' + query : ''}`);
      }

      setPaginatedUserList({ ...paginatedUsers, offset }, dispatch);

      return paginatedUsers.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const restorePatientSearchFromUrl = createAsyncThunk<
  UserInfo[],
  { clinicLocationId?: string; query: string },
  { state: RootState }
>(
  'clinicLocation/loadPatients',
  async ({ clinicLocationId, query }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>;

      dispatch(setUserListStoredQuery(query));
      dispatch(restorePatientSearchModel(parseUrl(query).query));
      paginatedUsers = await ClinicLocationService.getClinicLocationPatients(
        query.slice(1),
        clinicLocationId
      );
      setPaginatedUserList(
        {
          ...paginatedUsers,
          offset: getState().clinicLocation.userListPaging.offset,
        },
        dispatch
      );

      return paginatedUsers.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getPatientDetails = createAsyncThunk(
  'clinicLocation/getPatientDetails',
  async (
    {
      clinicLocationId,
      patientId,
    }: { clinicLocationId: string | undefined; patientId: string },
    { rejectWithValue }
  ) => {
    try {
      return await ClinicLocationService.getPatientById(
        clinicLocationId,
        patientId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getPatientScans = createAsyncThunk<
  Scan[],
  {
    clinicLocationId: string;
    patientId: string;
    dayFrom: string;
    dayTo: string;
  }
>(
  'clinicLocation/loadPatientScans',
  async ({ clinicLocationId, patientId, dayFrom, dayTo }) => {
    try {
      const query = stringify({
        limit: 100,
        day_from: dayFrom,
        day_to: dayTo,
      });

      const paginatedScans = await ClinicLocationService.getPatientScans(
        patientId,
        clinicLocationId,
        query
      );

      return paginatedScans.docs;
    } catch (error) {
      return [];
    }
  }
);

const getScansForClinicLocation = createAsyncThunk<
  Scan[],
  {
    clinicLocationId?: string;
    history: History;
  },
  { state: RootState }
>(
  'clinicLocation/getScansForClinicLocation',
  async (
    { clinicLocationId, history },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      let paginatedScanList: Paginated<Scan>;
      const { offset } = getState().clinicLocation.scanListPaging;

      const query = stringify(
        {
          limit: ScansService.ADMIN_SCAN_LIST_LIMIT,
          offset,
        },
        { skipNull: true }
      );

      paginatedScanList = await ClinicLocationService.getScansForClinicLocation(
        clinicLocationId,
        query
      );
      dispatch(setScanListStoredQuery(offset > 0 ? query : ''));
      history.push(`/scan-list${offset > 0 ? '?' + query : ''}`);
      dispatch(setScanListPaging({ ...paginatedScanList, offset }));

      return paginatedScanList.docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const restoreScanListFromUrl = createAsyncThunk<
  Scan[],
  {
    clinicLocationId?: string;
    query: string;
  },
  { state: RootState }
>(
  'clinicLocation/getScansForClinicLocation',
  async ({ query, clinicLocationId }, { dispatch, getState }) => {
    try {
      let paginatedScanList: Paginated<Scan>;

      dispatch(setScanListStoredQuery(query));
      dispatch(restoreScanListSearchModel(parseUrl(query).query));

      paginatedScanList = await ClinicLocationService.getScansForClinicLocation(
        clinicLocationId,
        query.slice(1)
      );
      dispatch(
        setScanListPaging({
          total: paginatedScanList.total,
          offset: getState().clinicLocation.scanListPaging.offset,
        })
      );

      return paginatedScanList.docs;
    } catch (error) {
      return [];
    }
  }
);

export const loadDailyDataEntity = createAsyncThunk(
  'dailyData/selectDailyDataDetails',
  async (
    {
      day,
      userDocumentId,
      clinicLocationId,
    }: { day: string; userDocumentId: string; clinicLocationId: string },
    { rejectWithValue }
  ) => {
    try {
      const paginatedDailyData =
        await ClinicLocationService.getPatientDailyData(
          clinicLocationId,
          userDocumentId,
          stringify({
            limit: 1,
            user_document_id: userDocumentId,
            day_from: day,
            day_to: day,
          })
        );
      return paginatedDailyData?.docs[0];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const loadPatientDailyData = createAsyncThunk<
  DailyData[],
  {
    clinicLocationId: string;
    patientId: string;
    startDate?: Date;
    endDate?: Date;
  },
  { state: RootState }
>(
  'clinicLocation/loadPatientDailyData',
  async ({ clinicLocationId, patientId, startDate, endDate }) => {
    try {
      let query: string = '';

      if (startDate && endDate) {
        query = stringify({
          limit: 0,
          day_from: startOfDay(startDate).toISOString(),
          day_to: endOfDay(endDate).toISOString(),
        });
      }

      const paginatedDailyData =
        await ClinicLocationService.getPatientDailyData(
          clinicLocationId,
          patientId,
          query
        );

      return paginatedDailyData.docs;
    } catch (error) {
      return [];
    }
  }
);

const loadOwnDailyData = createAsyncThunk<
  DailyData[],
  {
    startDate?: Date;
    endDate?: Date;
  },
  { state: RootState }
>('clinicLocation/loadOwnDailyData', async ({ startDate, endDate }) => {
  try {
    let query: string = '';

    if (startDate && endDate) {
      query = stringify({
        limit: 0,
        start_date: startOfDay(startDate).toISOString(),
        end_date: endOfDay(endDate).toISOString(),
      });
    }

    const paginatedDailyData =
      await ClinicLocationService.getOwnDailyDataByQuery(query);

    return paginatedDailyData.docs;
  } catch (error) {
    return [];
  }
});

const loadOwnDailyDataBetWeenDateRange = createAsyncThunk<
  DailyData[],
  {
    startDate: Date;
    endDate: Date;
  },
  { state: RootState }
>(
  'clinicLocation/loadOwnDailyDataBetweenDates',
  async ({ startDate, endDate }) => {
    try {
      const query: any = stringify({
        order_by: 'created_at',
        order_dir: 'asc',
        created_at_from: startDate.toISOString(),
        created_at_to: endDate.toISOString(),
        limit: 100,
      });

      const paginatedDailyData =
        await ClinicLocationService.getOwnDailyDataByQuery(query);

      return paginatedDailyData.docs;
    } catch (error) {
      return [];
    }
  }
);

const loadDailyDataBetweenDateRangeByPatientId = createAsyncThunk(
  'clinicLocation/dailyDataBetweenDates',
  async (
    {
      clinicLocationId,
      patientId,
      startDate,
      endDate,
    }: {
      clinicLocationId: string;
      patientId: string;
      startDate: Date;
      endDate: Date;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryParams: any = {
        order_by: 'day',
        order_dir: 'asc',
        day_from: startDate.toISOString(),
        day_to: endDate.toISOString(),
        limit: 100,
      };

      const query = stringify(queryParams);
      const paginatedDailyData =
        await ClinicLocationService.getPatientDailyData(
          clinicLocationId,
          patientId,
          query
        );
      return paginatedDailyData.docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const loadAllFertilityDisorders = createAsyncThunk(
  'clinicLocation/fertilityDisorders',
  async () => {
    const disorders = await UserService.loadAllFertilityDisorders();

    return disorders.map((disorder) => disorder.known_reproductive_disorder);
  }
);

const loadClinicLocationProviders = createAsyncThunk(
  'clinicLocation/providersFilter',
  async (clinicLocationId: string, { rejectWithValue }) => {
    try {
      const providers = await ClinicLocationService.loadProviderFilterData(
        clinicLocationId
      );

      return providers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const assignPatientToProvider = createAsyncThunk(
  'clinicLocation/assignPatientToProvider',
  async (
    {
      clinicLocationId,
      providerId,
      patientEmail,
    }: {
      clinicLocationId?: string;
      providerId: string;
      patientEmail: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await ClinicLocationService.assignPatientToProvider(
        providerId,
        patientEmail,
        clinicLocationId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const assignPatientToClinicLocation = createAsyncThunk(
  'clinicLocation/assignPatientToClinicLocation',
  async (
    {
      clinicLocationId,
      request,
    }: {
      clinicLocationId: string;
      request: AssignPatientToClinicLocationRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      return await ClinicLocationService.assignPatientToClinicLocation(
        clinicLocationId,
        request
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createDemoAccount = createAsyncThunk(
  'clinicLocation/createDemoAccount',
  async (
    {
      createDemoAccountRequest,
      clinicLocationId,
      history,
    }: {
      createDemoAccountRequest: CreateDemoAccountRequest;
      clinicLocationId?: string;
      history: History;
    },
    { rejectWithValue }
  ) => {
    try {
      const demoData = await ClinicLocationService.createDemoAccount(
        createDemoAccountRequest,
        clinicLocationId
      );

      history.push(`/patients/${demoData.user.id}`);

      return demoData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const revokePatientProviderAssignment = createAsyncThunk(
  'clinicLocation/revokePatientProviderAssignment',
  async (
    {
      clinicLocationId,
      providerId,
      patientId,
    }: {
      clinicLocationId?: string;
      providerId: string;
      patientId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await ClinicLocationService.revokePatientProviderAssignment(
        providerId,
        patientId,
        clinicLocationId
      );

      await dispatch(getPatientDetails({ clinicLocationId, patientId }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acceptPatientInvitation = createAsyncThunk(
  'clinicLocation/acceptPatientInvitation',
  async (token: string, { rejectWithValue }) => {
    try {
      await ClinicLocationService.acceptPatientInvitation(token);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acceptProviderInvitation = createAsyncThunk(
  'clinicLocation/acceptProviderInvitation',
  async (token: string, { rejectWithValue }) => {
    try {
      await ClinicLocationService.acceptProviderInvitation(token);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acceptPatientInvitationFromProvider = createAsyncThunk(
  'clinicLocation/acceptPatientInvitationFromProvider',
  async (token: string, { rejectWithValue }) => {
    try {
      await ClinicLocationService.acceptPatientInvitationFromProvider(token);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function getUserListQuerySorting(state: RootState) {
  const sortingOptions: SortingOption[] =
    state?.clinicLocation?.userListSortingOptions;

  const sortingOption = sortingOptions
    .filter((sortingOption) => sortingOption)
    .find((sortingOption) => ['desc', 'asc'].includes(sortingOption.direction));

  if (sortingOption) {
    return {
      orderBy: sortingOption.value,
      orderDir: sortingOption.direction,
    };
  }
}

function getPatientListQueryFilters(state: RootState): any[] {
  const filters = state?.clinicLocation?.userListFilters
    ? state?.clinicLocation?.userListFilters
        .filter(
          (filter: any) => filter.value !== undefined && filter.value !== ''
        )
        .map((filter: any) => ({
          field: filter.fieldName,
          value: filter.value,
        }))
    : [];

  return filters;
}

function setPaginatedUserList(
  paginatedUserList: Paginated<UserInfo>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setUserListPaging({
      offset: paginatedUserList.offset || 0,
      total: paginatedUserList.total,
    })
  );
}

export {
  getClinicLocationById,
  loadClinicLocationPatients,
  restorePatientSearchFromUrl,
  getPatientDetails,
  loadPatientDailyData,
  loadDailyDataBetweenDateRangeByPatientId,
  loadAllFertilityDisorders,
  assignPatientToProvider,
  revokePatientProviderAssignment,
  loadClinicLocationProviders,
  assignPatientToClinicLocation,
  loadOwnDailyData,
  loadOwnDailyDataBetWeenDateRange,
  acceptPatientInvitation,
  acceptProviderInvitation,
  acceptPatientInvitationFromProvider,
  getPatientScans,
  createDemoAccount,
  getScansForClinicLocation,
  restoreScanListFromUrl,
};
