import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import OvTextField from '../atoms/OvTextField';
import OvButton from '../atoms/OvButton';

export interface OvConfirmationInputProps {
  expectedValue: any;
  label: string;
  ctaLabel: string;
  onMatch: () => void;
  caseSensitive: boolean;
}

const OvConfirmationInput: FC<OvConfirmationInputProps> = ({
  expectedValue,
  label,
  ctaLabel,
  onMatch,
  caseSensitive,
}) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
  };

  return (
    <>
      <StyledLabel>
        {label}
        <OvTextField
          value={currentValue}
          onChange={inputChangeHandler}
          fullWidth
        />
      </StyledLabel>
      <StyledOvButton
        disabled={
          caseSensitive
            ? expectedValue !== currentValue
            : expectedValue?.toLowerCase() !== currentValue?.toLowerCase()
        }
        onClick={onMatch}
        fullWidth
      >
        {ctaLabel}
      </StyledOvButton>
    </>
  );
};

export default OvConfirmationInput;

const StyledLabel = styled.label`
  margin: 1rem 0;
  display: block;
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    font-size: ${Variables.fontSizes.MEDIUM};
  }
`;
