import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { OvSortingButtonModel } from '../molecules/OvSortingButtonGroup';
import { History } from 'history';
import { FilterModel } from '../../../common/model/ui/filter.model';
import {
  changePatientListFilter,
  changeUserListSorting,
  clearPatientFilters,
} from '../../../redux/reducers/admin-clinic-location.slice';
import {
  loadAllFertilityDisorders,
  loadClinicLocationPatients,
  loadClinicLocationProvidersFilters,
} from '../../../redux/thunks/admin/admin-clinic-location.thunk';
import { UserInfoFields } from '../../../firebase/document-field.enums';
import OvListFilter from './OvListFilter';
import { hasAppliedPatientFiltersSelector } from '../../../redux/selectors/admin-clinic-location.selectors';

const OvClinicLocationPatientSearchFilter: FC<{
  clinicLocationId?: string;
  clinicId: string;
}> = ({ clinicLocationId, clinicId }) => {
  const isOpened = useAppSelector(
    (state) => state.adminClinicLocation.showUserListFilters
  );
  const sortingOptions: OvSortingButtonModel[] = useAppSelector(
    (state) => state.adminClinicLocation.userListSortingOptions
  );
  const filters: FilterModel[] = useAppSelector(
    (state) => state.adminClinicLocation.patientListFilters
  );
  const hasAppliedFilters = useAppSelector(hasAppliedPatientFiltersSelector);
  const dispatch = useAppDispatch();
  const history: History = useHistory();

  useEffect(() => {
    if (!areFertilityDisordersLoaded()) {
      dispatch(loadAllFertilityDisorders());
    }

    if (!areProvidersLoaded() && clinicLocationId) {
      dispatch(loadClinicLocationProvidersFilters(clinicLocationId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicLocationId]);

  const removeFilters = () => {
    dispatch(clearPatientFilters());
    dispatch(
      loadClinicLocationPatients({
        history,
        clinicLocationId,
        clinicId,
      })
    );
  };

  const handleSorting = (sortingValue: UserInfoFields) => {
    dispatch(changeUserListSorting(sortingValue));
    dispatch(
      loadClinicLocationPatients({
        history,
        clinicLocationId,
        clinicId,
      })
    );
  };

  const handleFilterValueChange = (filter: FilterModel) => {
    dispatch(changePatientListFilter(filter));
    dispatch(
      loadClinicLocationPatients({
        history,
        clinicLocationId,
        clinicId,
      })
    );
  };

  const areFertilityDisordersLoaded = (): boolean =>
    !!filters.find(
      (filter) =>
        filter.fieldName === UserInfoFields.known_reproductive_disorder
    )?.options?.length;

  const areProvidersLoaded = (): boolean =>
    !!filters.find((filter) => filter.fieldName === UserInfoFields.provider_id)
      ?.options?.length;

  return (
    <OvListFilter
      filters={filters}
      sortingOptions={sortingOptions}
      isOpened={isOpened}
      hasAppliedFilters={hasAppliedFilters}
      onRemoveFilters={removeFilters}
      onHandleSorting={handleSorting}
      onHandleFilterValueChange={handleFilterValueChange}
    />
  );
};

export default OvClinicLocationPatientSearchFilter;
