import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

const OvTag: FC<{ children?: React.ReactNode }> = ({ children, ...props }) => {
  return <StyledTag {...props}>{children}</StyledTag>;
};

export default OvTag;

const StyledTag = styled.p`
  font-size: 0.75rem;
  white-space: nowrap;
  border-radius: ${Variables.borderRadius.SMALL};
  background-color: ${Colours.OV_BORDER_COLOR_V3};
  padding: 0.125rem 0.25rem;
  width: min-content;
`;
