import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import styled from 'styled-components';
import { DailyDataCalendarEvent } from '../../../common/model/ui/daily-data-calendar-event';
import { Timestamp } from 'firebase/firestore';
import { isNumber } from 'lodash';
import OvDayStatusHighlight from '../atoms/OvDayStatusHighlight';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { CalendarDayStatusUtils } from '../../../common/utils/services/calendar-day-status-utils';
import WatchIcon from '@mui/icons-material/Watch';

const OvDailyDataCalendarHoc = (
  events: DailyDataCalendarEvent[] = [],
  selectedDay?: Timestamp
): FC<any> => {
  const { t } = useTranslation();

  function isSelected(selectedDay: Date, eventStart: Date): boolean {
    return (
      selectedDay.getFullYear() === eventStart.getFullYear() &&
      selectedDay.getMonth() === eventStart.getMonth() &&
      selectedDay.getDate() === eventStart.getDate()
    );
  }

  return ({ label, date }) => {
    let currentEvent: DailyDataCalendarEvent | undefined = events.find((ev) =>
        moment(date)
          .add(1, 'minutes')
          .isBetween(moment(ev.start), moment(ev.end), null, '[]')
      ),
      scanResultExists: boolean =
        (isNumber(currentEvent?.dailyData?.lh) &&
          isNumber(currentEvent?.dailyData?.pg)) ||
        isNumber(currentEvent?.dailyData?.lr_e3g),
      journalEntryExists: boolean =
        !!currentEvent &&
        !!currentEvent.dailyData &&
        !!currentEvent.dailyData.journal_entry_id,
      spikeInfoExists: boolean =
        !!currentEvent &&
        !!currentEvent.dailyData &&
        !!currentEvent.dailyData.spike_info;

    const numberOfScans: number = currentEvent?.numberOfScans ?? 0;

    return (
      <HeaderContainer>
        <IconContainer>
          <JournalAndWatchIconWrapper>
            {journalEntryExists && <LibraryBooksIcon />}
            {spikeInfoExists && <WatchIcon />}
          </JournalAndWatchIconWrapper>
          {scanResultExists && (
            <StyledImage
              src="/images/test-strip.png"
              alt={t('common.altText.testStrip')}
            />
          )}
          {numberOfScans > 1 && (
            <NumberOfScansWrapper>
              {currentEvent?.numberOfScans}
            </NumberOfScansWrapper>
          )}
        </IconContainer>
        <DateLabel>
          <OvDayStatusHighlight
            tryingToConceiveStatus={
              currentEvent?.dailyData
                ? CalendarDayStatusUtils.calculateDayStatus(
                    [currentEvent?.dailyData],
                    0
                  )
                : undefined
            }
          >
            {label}
          </OvDayStatusHighlight>
        </DateLabel>
        {selectedDay && isSelected(selectedDay.toDate(), date) ? (
          <SelectedLabel>{t('common.actions.selected')}</SelectedLabel>
        ) : (
          ''
        )}
      </HeaderContainer>
    );
  };
};

export default OvDailyDataCalendarHoc;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 3px 0 3px 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.25rem;
  width: 100%;
`;

const StyledImage = styled.img`
  max-height: 1.25rem;
  max-width: 1.25rem;
`;

const DateLabel = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
`;

const SelectedLabel = styled.p`
  margin: 0;
  text-align: center;
  font-size: ${Variables.fontSizes.MEDIUM};
  padding: 0 0.25rem;
  font-weight: bold;
`;

const NumberOfScansWrapper = styled.span`
  padding: 0.75rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  line-height: 0.85rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${Colours.OV_BORDER_COLOR_V3};
`;

const JournalAndWatchIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
