import { ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { AdminProductPriceService } from '../../../services/admin/admin-product-price.service';
import { UpdateProductPriceRequest } from '../../../common/model/dto/product/update-product-price-request';
import { ProductPrice } from '../../../common/model/dto/product/product-price';
import { History } from 'history';
import { AddPriceToProductRequest } from '../../../common/model/dto/product/add-price-to-product-request';
import { RootState } from '../../store';
import { Paginated } from '../../../common/types';
import { FilterUtils } from '../../../common/utils/services/filter.utils';
import { parseUrl, stringify } from 'query-string';
import {
  restoreSearchModel,
  setProductPriceListPaging,
  setProductPriceListStoredQuery,
} from '../../reducers/admin-product-price.slice';
import { hasAppliedFiltersSelector } from '../../selectors/product-price.selector';

export const getProductPrices = createAsyncThunk<
  ProductPrice[],
  { productId: string; history: History },
  { state: RootState }
>(
  'productPrices/getProductPrices',
  async ({ productId, history }, { dispatch, getState }) => {
    try {
      let paginatedProductPrices: Paginated<ProductPrice>,
        query = '';
      const { offset } = getState().adminProductPrice.productPriceListPaging,
        filters = FilterUtils.mapFilterModelsToQueryParams(
          getState().adminProductPrice.filters
        );

      if (hasAppliedFiltersSelector(getState())) {
        query = stringify(
          {
            offset: offset,
            limit: AdminProductPriceService.PRODUCT_PRICE_LIST_LIMIT,
            order_by: 'created_at',
            order_dir: 'desc',
            ...FilterUtils.getQueryParams(filters),
          },
          { skipNull: true }
        );

        paginatedProductPrices =
          await AdminProductPriceService.getProductPrices(productId, query);
        dispatch(setProductPriceListStoredQuery(query));
        history.push(`/products/detail/${productId}/prices?${query}`);
      } else {
        query = stringify(
          {
            offset: offset,
            limit: AdminProductPriceService.PRODUCT_PRICE_LIST_LIMIT,
            order_by: 'created_at',
            order_dir: 'desc',
          },
          { skipNull: true }
        );

        paginatedProductPrices =
          await AdminProductPriceService.getProductPrices(productId, query);
        dispatch(setProductPriceListStoredQuery(offset > 0 ? '?' + query : ''));
        history.push(
          `/products/detail/${productId}/prices?${
            offset > 0 ? '?' + query : ''
          }`
        );
      }

      setPaging({ ...paginatedProductPrices, offset }, dispatch);

      return paginatedProductPrices.docs;
    } catch (error) {
      return [];
    }
  }
);

export const restoreUserSearchFromUrl = createAsyncThunk<
  ProductPrice[],
  { query: string; productId: string },
  { state: RootState }
>(
  'productPrices/getProductPrices',
  async ({ query, productId }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<ProductPrice>;

      dispatch(setProductPriceListStoredQuery(query));
      dispatch(restoreSearchModel(parseUrl(query).query));
      paginatedUsers = await AdminProductPriceService.getProductPrices(
        productId,
        query.slice(1)
      );
      setPaging(
        {
          ...paginatedUsers,
          offset: getState().adminProductPrice.productPriceListPaging.offset,
        },
        dispatch
      );

      return paginatedUsers.docs;
    } catch (error) {
      return [];
    }
  }
);

export const getProductPriceById = createAsyncThunk(
  'productPrice/getProductPriceById',
  async (productPriceId: string, { rejectWithValue }) => {
    try {
      return await AdminProductPriceService.getProductPriceById(productPriceId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addPriceToProductAndGoToDetails = createAsyncThunk(
  'productPrice/addPriceToProduct',
  async (
    {
      history,
      productId,
      addPriceToProductRequest,
    }: {
      history: History;
      productId: string;
      addPriceToProductRequest: AddPriceToProductRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const response: ProductPrice =
        await AdminProductPriceService.addPriceToProduct(
          productId,
          addPriceToProductRequest
        );

      history.push(`/products/detail/${productId}/prices/${response.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductPriceAndGoToDetails = createAsyncThunk(
  'productPrice/updateProductPriceAndGoToDetails',
  async (
    {
      history,
      productId,
      productPriceId,
      updateProductPriceRequest,
    }: {
      history: History;
      productId: string;
      productPriceId: string;
      updateProductPriceRequest: UpdateProductPriceRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const response: ProductPrice =
        await AdminProductPriceService.updateProductPrice(
          productPriceId,
          updateProductPriceRequest
        );

      history.push(`/products/detail/${productId}/prices/${response.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const archiveProductPrice = createAsyncThunk(
  'productPrice/archiveProductPrice',
  async (
    {
      history,
      productId,
      productPriceId,
    }: { history: History; productId: string; productPriceId: string },
    { rejectWithValue }
  ) => {
    try {
      await AdminProductPriceService.archiveProductPrice(productPriceId);

      history.push(`/products/detail/${productId}/prices`);
      return productPriceId;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

function setPaging(
  paginatedUsers: Paginated<ProductPrice>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setProductPriceListPaging({
      offset: paginatedUsers.offset || 0,
      total: paginatedUsers.total,
    })
  );
}
