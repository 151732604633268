import { Constants } from '../../constants';

export default class ConversionUtils {
  static lbsToKg(value: number): number {
    return value * 0.453592;
  }

  static inchToCm(value: number): number {
    return value * 2.54;
  }

  static heightInCm(feet: number, inch: number): number {
    return feet * Constants.FEET_IN_CM + inch * Constants.INCH_IN_CM;
  }
}
