import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { History } from 'history';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { ListPaging } from '../../common/types';
import {
  createClinicLocation,
  loadClinicLocations,
  restoreClinicLocationSearchFromUrl,
} from '../../redux/thunks/admin/admin-clinic-location.thunk';
import {
  clearClinicLocationList,
  setClinicLocationListPaging,
} from '../../redux/reducers/admin-clinic-location.slice';
import styled from 'styled-components';
import { AdminClinicLocationService } from '../../services/admin/admin-clinic-location.service';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvClinicLocationList from '../UI/organisms/OvClinicLocationList';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvButton from '../UI/atoms/OvButton';
import breakpoints from '../../design-system/breakpoints';
import Variables from '../../design-system/variables';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvCreateClinicLocationDialog from '../UI/molecules/OvCreateClinicLocationDialog';
import { CreateClinicLocationRequest } from '../../common/model/dto/create-clinic-location-request';
import Colours from '../../design-system/colours';
import { Clinic } from '../../common/model/dto/clinic';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const FilterableClinicLocationList: FC<{ selectedClinic?: Clinic }> = ({
  selectedClinic,
}) => {
  const { clinicId }: any = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicLocationOperations = useAuthorized(Resource.ClinicLocation);

  const clinicLocationCreateAllowed =
    clinicLocationOperations.create || applicationOperations.supervise;

  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const clinicLocationsList: ClinicLocation[] = useAppSelector(
    (state) => state.adminClinicLocation.clinicLocationList
  );
  const clinicLocationListPaging: ListPaging = useAppSelector(
    (state) => state.adminClinicLocation.clinicLocationListPaging
  );
  const isLoading = useAppSelector(
    (state) => state.adminClinicLocation.isLoading
  );
  const clinicLocationStoredQuery = useAppSelector(
    (state) => state.adminClinicLocation.clinicLocationStoredQuery
  );

  useEffect(() => {
    if (search) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(restoreClinicLocationSearchFromUrl(search));
    } else if (!clinicLocationStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(loadClinicLocations({ history, clinicId }));
    } else if (!search && clinicLocationStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setClinicLocationListPaging({
          offset: 0,
          total: clinicLocationListPaging.total,
        })
      );
      dispatch(loadClinicLocations({ history, clinicId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history, clinicId]);

  useEffect(() => {
    return () => {
      dispatch(clearClinicLocationList());
      dispatch(setClinicLocationListPaging({ offset: 0, total: 0 }));
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(
      clinicLocationListPaging.offset /
        AdminClinicLocationService.CLINIC_LOCATION_LIST_LIMIT +
        1
    );
  }, [clinicLocationListPaging.offset, clinicId]);

  useEffect(() => {
    setPageCount(
      Math.ceil(
        clinicLocationListPaging?.total /
          AdminClinicLocationService.CLINIC_LOCATION_LIST_LIMIT
      )
    );
  }, [clinicLocationListPaging.total]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setClinicLocationListPaging({
        offset:
          (value - 1) * AdminClinicLocationService.CLINIC_LOCATION_LIST_LIMIT,
        total: clinicLocationListPaging.total,
      })
    );

    dispatch(loadClinicLocations({ history, clinicId }));
  };

  const handleOnSave = (request: CreateClinicLocationRequest) => {
    dispatch(createClinicLocation({ clinicId, request }));
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <CenterPane>
        <UserActions>
          {pageCount > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}

          {clinicLocationCreateAllowed && (
            <ButtonWrapper>
              <StyledOvButton onClick={() => setIsDialogOpen(true)}>
                <AddCircleIcon style={{ marginRight: '5' }} />
                {t('clinicLocationDetails.actions.addNewClinicSite')}
              </StyledOvButton>
            </ButtonWrapper>
          )}
        </UserActions>
        {clinicLocationsList?.length ? (
          <OvClinicLocationList clinicLocations={clinicLocationsList} />
        ) : (
          ''
        )}
        {!clinicLocationsList?.length && !isLoading ? (
          <OvNoContent>
            {t('common.userSearchFilters.emptyUserList')}
          </OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvCreateClinicLocationDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
        title={t('clinicLocationDialogs.createClinicSiteTitle')}
        selectedClinic={selectedClinic}
      />
    </Container>
  );
};

export default FilterableClinicLocationList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  min-width: 13rem;
  display: flex;
  flex-wrap: 'wrap';
  justify-content: flex-end;
  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;
