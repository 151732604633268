import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ScansService } from '../../services/scans.service';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvScanList, { ScanListFieldOptions } from '../UI/organisms/OvScanList';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import {
  getScansForClinicLocation,
  restoreScanListFromUrl,
} from '../../redux/thunks/clinic-location.thunk';
import {
  clearScanList,
  setScanListPaging,
  setScanListStoredQuery,
} from '../../redux/reducers/clinic-location.slice';

const ClinicLocationScanList: FC = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);
  const scans = useAppSelector((state) => state.clinicLocation.scanList);
  const isPatientScansLoading = useAppSelector(
    (state) => state.clinicLocation.isPatientScansLoading
  );
  const storedQuery = useAppSelector(
    (state) => state.clinicLocation.storedQuery
  );
  const errorMessage = useAppSelector((state) => state.clinicLocation.error);
  const scanListPaging = useAppSelector(
    (state) => state.clinicLocation.scanListPaging
  );
  const selectedClinicLocation: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const isUserDataLoading: boolean = useAppSelector(
    (state) => state.user.isLoading
  );
  const isLoading = isPatientScansLoading || isUserDataLoading;

  const listFieldOptions: ScanListFieldOptions = {
    showOperation: false,
    showDetailsAction: false,
    showUserName: true,
    useAdminPath: false,
  };

  useEffect(() => {
    setPage(scanListPaging.offset / ScansService.ADMIN_SCAN_LIST_LIMIT + 1);
  }, [scanListPaging.offset]);

  useEffect(() => {
    if (search && search !== `?${storedQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(
        restoreScanListFromUrl({
          clinicLocationId: selectedClinicLocation?.id,
          query: search,
        })
      );
    } else if (!storedQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(
        getScansForClinicLocation({
          clinicLocationId: selectedClinicLocation?.id,
          history,
        })
      );
    } else if (!search && storedQuery) {
      // jump back to the first page with side nav
      dispatch(
        setScanListPaging({
          offset: 0,
          total: scanListPaging.total,
        })
      );
      dispatch(
        getScansForClinicLocation({
          clinicLocationId: selectedClinicLocation?.id,
          history,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setScanListStoredQuery(''));
      dispatch(clearScanList());
    };
  }, [dispatch]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setScanListPaging({
        offset: (value - 1) * ScansService.ADMIN_SCAN_LIST_LIMIT,
        total: scanListPaging.total,
      })
    );

    dispatch(
      getScansForClinicLocation({
        clinicLocationId: selectedClinicLocation?.id,
        history,
      })
    );
  };

  const pageCount: () => number = () =>
    Math.ceil(scanListPaging?.total / ScansService.ADMIN_SCAN_LIST_LIMIT);

  return (
    <Container>
      <CenterPane>
        {pageCount() > 1 ? (
          <OvPagination
            page={page}
            onChange={goToPage}
            count={pageCount()}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
              />
            )}
            shape="rounded"
          />
        ) : (
          ''
        )}

        {errorMessage && (
          <ErrorMessageWrapper>
            <OvErrorMessage message={errorMessage} />
          </ErrorMessageWrapper>
        )}

        <OvScanList
          scans={scans}
          isLoading={isLoading}
          fieldOptions={listFieldOptions}
        />
      </CenterPane>
    </Container>
  );
};

export default ClinicLocationScanList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
  flex-grow: 1;
`;

const ErrorMessageWrapper = styled.div`
  margin-top: 1rem;
`;
