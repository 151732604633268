import React from 'react';
import {
  CartesianGrid,
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import Colours from '../../../design-system/colours';
import styled from 'styled-components';
import UserAuditActivityChartData from '../../../common/model/type/user-audit-activity-chart-data.type';
import { format } from 'date-fns';

interface OvUserAuditActivityBarChartProps {
  data: UserAuditActivityChartData[];
}

const OvUserAuditActivityBarChart: React.FC<
  OvUserAuditActivityBarChartProps
> = ({ data }) => {
  const transformedData = data.map((d, index) => ({
    index: index + 1,
    time: d.time_interval.value,
    requestCount: d.request_count,
  }));

  const minTimestamp = data[0]?.time_interval.value;
  const maxTimestamp = data[data.length - 1]?.time_interval.value;

  const maxValue = Math.max(...data.map((d) => d.request_count));

  return (
    <>
      <ResponsiveContainer height={270}>
        <BarChart data={transformedData} margin={{ top: 22, right: 35 }}>
          <XAxis dataKey="time" type="category" tick={false} tickMargin={10} />
          <YAxis tickLine={false} domain={[0, maxValue]} />
          <CartesianGrid vertical={false} />
          <Tooltip
            formatter={(value) => [`${value} requests`]}
            labelFormatter={(label) =>
              `Time: ${format(new Date(label), 'MMMM dd, yyyy, HH:mm:ss')}`
            }
          />
          <Bar dataKey="requestCount" fill={Colours.OV_BASE} barSize={20} />
        </BarChart>
      </ResponsiveContainer>
      <MinMaxLabelContainer>
        <span>{`Start: ${format(
          new Date(minTimestamp),
          'MMMM dd, yyyy, HH:mm:ss'
        )}`}</span>
        <span>{`End: ${format(
          new Date(maxTimestamp),
          'MMMM dd, yyyy, HH:mm:ss'
        )}`}</span>
      </MinMaxLabelContainer>
    </>
  );
};

export default OvUserAuditActivityBarChart;

const MinMaxLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  color: ${Colours.OV_BASE};
`;
