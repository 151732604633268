import axios from 'axios';
import { ADMIN_SENDGRID_ROUTE } from '../../api/backend';

export class AdminSendgridService {
  static async sendSetPasswordEmail(
    email: string,
    token: string,
    userName: string,
    clinicLocationId: string
  ) {
    await axios.post(`${ADMIN_SENDGRID_ROUTE}/set-password-email`, {
      email,
      token,
      userName,
      clinicLocationId,
    });
  }

  static async sendApproveInvitationEmail(
    email: string,
    token: string,
    userName: string,
    clinicLocationName: string
  ) {
    await axios.post(`${ADMIN_SENDGRID_ROUTE}/approve-invitation-email`, {
      email,
      token,
      userName,
      clinicLocationName,
    });
  }
}
