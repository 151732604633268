import FormControlLabel from '@mui/material/FormControlLabel';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OvChartLabel from '../atoms/OvChartLabel';
import Colours from '../../../design-system/colours';
import { Switch } from '@mui/material';

const OvHormoneChartToggleButtons: FC<{
  isBBTOn: boolean;
  setIsBBTOn: (data: boolean) => void;
  showAllScans: boolean;
  setShowAllScans: (data: boolean) => void;
}> = ({ isBBTOn, setIsBBTOn, showAllScans, setShowAllScans }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <FormControlLabel
        checked={showAllScans}
        onChange={(e: any) => {
          setShowAllScans(e.target.checked);
        }}
        control={<StyledSwitch />}
        label={<OvChartLabel>{t('dashboard.showAllScans')}</OvChartLabel>}
        labelPlacement="start"
      />
      <FormControlLabel
        checked={isBBTOn}
        onChange={(e: any) => {
          setIsBBTOn(e.target.checked);
        }}
        control={<StyledSwitch />}
        label={<OvChartLabel>{t('dashboard.bbt')}</OvChartLabel>}
        labelPlacement="start"
      />
    </Container>
  );
};

export default OvHormoneChartToggleButtons;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledSwitch = styled(Switch)`
  && {
    .MuiSwitch-thumb {
      color: ${Colours.OV_BASE};
    }

    .MuiSwitch-track {
      background-color: ${Colours.CD_TOOLTIP_HEADER} !important;
    }
  }
`;
