import axios from 'axios';
import { CreateNotificationContentRequest } from '../../common/model/dto/notification/create-notification-content-request';
import { ADMIN_NOTIFICATION_CONTENTS_ROUTE } from '../../api/backend';

export const createNotificationContent = async (
  createNotificationContentRequest: CreateNotificationContentRequest
) => {
  return await axios.post(
    `${ADMIN_NOTIFICATION_CONTENTS_ROUTE}`,
    createNotificationContentRequest
  );
};

export const updateNotificationContent = async (
  id: string,
  updateNotificationContentRequest: any
) => {
  return await axios.patch(
    `${ADMIN_NOTIFICATION_CONTENTS_ROUTE}/${id}`,
    updateNotificationContentRequest
  );
};

export const deleteNotificationContent = async (
  notificationContentId: string
) => {
  return await axios.delete(
    `${ADMIN_NOTIFICATION_CONTENTS_ROUTE}/${notificationContentId}`
  );
};
