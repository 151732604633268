import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScansState } from '../states/scans.state';
import { AxiosError } from 'axios';
import {
  deleteScanById,
  createScan,
  findScanById,
  loadScansBetweenDateRange,
  updateScan,
} from '../thunks/scans.thunk';
import { Scan } from '../../common/model/dto/scan';

const initialState: ScansState = {
  scanList: [],
  errorMessage: '',
  isLoading: false,
};

const scansSlice = createSlice({
  name: 'scans',
  initialState,
  reducers: {
    clearScanList: (state) => {
      state.scanList = [];
    },
    clearSelectedScan: (state) => {
      state.selectedScan = null;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadScansBetweenDateRange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadScansBetweenDateRange.fulfilled,
        (state, action: PayloadAction<Scan[]>) => {
          state.isLoading = false;
          state.scanList = action.payload;
        }
      )
      .addCase(
        loadScansBetweenDateRange.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createScan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createScan.fulfilled, (state, action: PayloadAction<Scan>) => {
        state.isLoading = false;

        if (state.scanList) {
          state.scanList = [action.payload, ...state.scanList];
        } else {
          state.scanList = [action.payload];
        }
      })
      .addCase(
        createScan.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(findScanById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(findScanById.fulfilled, (state, action: PayloadAction<Scan>) => {
        state.isLoading = false;
        state.selectedScan = action.payload;
      })
      .addCase(
        findScanById.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateScan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateScan.fulfilled, (state, action: PayloadAction<Scan>) => {
        state.selectedScan = action.payload;
        state.isLoading = false;
        state.scanList = state.scanList.map((scan) =>
          scan.id === action.payload.id ? action.payload : scan
        );
      })
      .addCase(
        updateScan.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteScanById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deleteScanById.fulfilled,
        (state, action: PayloadAction<Scan>) => {
          state.isLoading = false;
          state.scanList = state.scanList.filter(
            (scan) => scan.id !== action.payload
          );
        }
      )
      .addCase(
        deleteScanById.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );
  },
});

export const { clearScanList, clearSelectedScan } = scansSlice.actions;
export default scansSlice.reducer;
