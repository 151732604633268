export class JournalEntryUtils {
  static periodFlowToNumeric(flow?: string) {
    switch (flow) {
      case 'Light':
        return 1;
      case 'Medium':
        return 2;
      case 'Heavy':
        return 3;
      default:
        return 0;
    }
  }

  static numericToPeriodFlow(num?: number) {
    switch (num) {
      case 1:
        return 'Light';
      case 2:
        return 'Medium';
      case 3:
        return 'Heavy';
      default:
        return '';
    }
  }
}
