import { SignInAttempt } from '../common/model/dto/sign-in-attempt';
import { auth } from '../firebase/config';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
} from 'firebase/auth';
import axios from 'axios';
import { Entitlement } from '../common/model/dto/entitlement';
import { API_PATH_PREFIX } from '../api/backend';

class AuthService {
  static readonly API_URL = '/auth';

  static login({ email, password }: SignInAttempt): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  static logout(): Promise<void> {
    return signOut(auth);
  }

  static async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(auth, email);
  }

  static async setPassword(
    token: string,
    password: string,
    confirmPassword: string
  ): Promise<void> {
    // Creating a new axios instance to not intercept this one request
    const unInterceptedAxiosRequest = axios.create();

    await unInterceptedAxiosRequest.patch(
      `${AuthService.API_URL}/set-password/${token}`,
      {
        password,
        confirmPassword,
      },
      { baseURL: `${process.env.REACT_APP_API_URL}${API_PATH_PREFIX}` }
    );
  }

  static async checkSetPasswordTokenValidity(token: string): Promise<boolean> {
    // Creating a new axios instance to not intercept this one request
    const unInterceptedAxiosRequest = axios.create();

    const { data } = await unInterceptedAxiosRequest.get<{
      isValid: boolean;
    }>(`${AuthService.API_URL}/validate-token/${token}`, {
      baseURL: `${process.env.REACT_APP_API_URL}${API_PATH_PREFIX}`,
    });

    return data.isValid;
  }

  static async getEntitlements(): Promise<Entitlement[]> {
    const { data } = await axios.get<Entitlement[]>(
      `${AuthService.API_URL}/entitlements`
    );
    return data;
  }
}

export default AuthService;
