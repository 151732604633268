import React, { FC, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';
import OvTextButton from '../atoms/OvTextButton';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Address } from '../../../common/model/dto/address';

export interface OvEditAddressDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (address: Address) => void;
  title: string;
  address: Address | null;
}

const OvEditShippingAddressDialog: FC<OvEditAddressDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
  title,
  address,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Address>({
    initialValues: {
      company_name: '',
      first_name: '',
      last_name: '',
      address1: '',
      address2: '',
      city: '',
      postal_code: '',
      region: '',
      region_code: '',
      country: '',
      country_code: '',
      phone: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onSave(values);
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(
        t('orders.address.errors.missingFirstName')
      ),
      last_name: Yup.string().required(
        t('orders.address.errors.missingLastName')
      ),
      address1: Yup.string().required(t('orders.address.errors.address1')),
      country: Yup.string().required(t('orders.address.errors.country')),
      country_code: Yup.string().required(
        t('orders.address.errors.countryCode')
      ),
      postal_code: Yup.number().required(t('orders.address.errors.postalCode')),
    }),
    validateOnBlur: true,
  });

  // Explanation: https://github.com/mui/material-ui/issues/16325
  useEffect(() => {
    if (isOpen && address) {
      formik.setValues({
        company_name: address?.company_name,
        first_name: address?.first_name,
        last_name: address?.last_name,
        address1: address?.address1,
        address2: address?.address2,
        city: address?.city,
        postal_code: address?.postal_code,
        region: address?.region,
        region_code: address?.region_code,
        country: address?.country,
        country_code: address?.country_code,
        phone: address?.phone,
      });
    }
    // eslint-disable-next-line
  }, [isOpen, address]);

  const handleOnSave = async () => {
    formik.handleSubmit();
  };

  return (
    <StyledDialog open={isOpen} onClose={onCancel} aria-labelledby={t(title)}>
      <StyledDialogTitle id="alert-dialog-title">
        <StyledLocationIcon /> {title}
      </StyledDialogTitle>
      <form>
        <StyledDialogContent>
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.firstName') + '*'}
            {...formik.getFieldProps('first_name')}
            error={formik.touched.first_name && !!formik.errors.first_name}
            helperText={
              formik.errors.first_name &&
              formik.touched.first_name &&
              formik.errors.first_name
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.lastName') + '*'}
            {...formik.getFieldProps('last_name')}
            error={formik.touched.last_name && !!formik.errors.last_name}
            helperText={
              formik.errors.last_name &&
              formik.touched.last_name &&
              formik.errors.last_name
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.address1') + '*'}
            {...formik.getFieldProps('address1')}
            error={formik.touched.address1 && !!formik.errors.address1}
            helperText={
              formik.errors.address1 &&
              formik.touched.address1 &&
              formik.errors.address1
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.address2') + '*'}
            {...formik.getFieldProps('address2')}
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.city') + '*'}
            {...formik.getFieldProps('city')}
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.country') + '*'}
            {...formik.getFieldProps('country')}
            error={formik.touched.country && !!formik.errors.country}
            helperText={
              formik.errors.country &&
              formik.touched.country &&
              formik.errors.country
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.countryCode') + '*'}
            {...formik.getFieldProps('country_code')}
            error={formik.touched.country_code && !!formik.errors.country_code}
            helperText={
              formik.errors.country_code &&
              formik.touched.country_code &&
              formik.errors.country_code
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.region') + '*'}
            {...formik.getFieldProps('region')}
            error={formik.touched.region && !!formik.errors.region}
            helperText={
              formik.errors.region &&
              formik.touched.region &&
              formik.errors.region
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.regionCode') + '*'}
            {...formik.getFieldProps('region_code')}
            error={formik.touched.region_code && !!formik.errors.region_code}
            helperText={
              formik.errors.region_code &&
              formik.touched.region_code &&
              formik.errors.region_code
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.postalCode') + '*'}
            {...formik.getFieldProps('postal_code')}
            error={formik.touched.postal_code && !!formik.errors.postal_code}
            helperText={
              formik.errors.postal_code &&
              formik.touched.postal_code &&
              formik.errors.postal_code
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.phone') + '*'}
            {...formik.getFieldProps('phone')}
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.address.companyName') + '*'}
            {...formik.getFieldProps('company_name')}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <OvTextButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </OvTextButton>
          <StyledOvButton onClick={() => handleOnSave()}>
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
};

export default OvEditShippingAddressDialog;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: 1rem;
    padding: 0 1rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0 1rem;
  }
`;

const StyledLocationIcon = styled(EditLocationAltIcon)`
  && {
    margin-right: 0.5rem;
    font-size: 2.5rem;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 1.5rem 0 0 0;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 1.5rem 0 0 0 !important;
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
  }
`;
