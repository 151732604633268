import React, { FC } from 'react';
import { Product } from '../../common/model/dto/product/product';
import styled from 'styled-components';
import { ProductDataUtils } from '../../common/utils/services/product-data-utils';
import breakpoints from '../../design-system/breakpoints';
import OvDeviceView from '../UI/molecules/OvDeviceView';
import OvProductDevicePreview from '../UI/molecules/OvProductDevicePreview';
import OvTable from '../UI/molecules/OvTable';

const ProductInfo: FC<{ selectedProduct: Product }> = ({ selectedProduct }) => {
  return (
    <ContentWrapper>
      {selectedProduct && (
        <>
          <OvTable
            data={ProductDataUtils.mapDataForProductDetailsTable(
              selectedProduct
            )}
            theme="Light"
          ></OvTable>
          {selectedProduct.product_prices.some(
            (price) => price.active && price.stripe_payment_link_id
          ) && (
            <DeviceViewContainer>
              <OvDeviceView>
                <OvProductDevicePreview product={selectedProduct} />
              </OvDeviceView>
            </DeviceViewContainer>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export default ProductInfo;

const ContentWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
  }
`;

const DeviceViewContainer = styled.div`
  min-width: 23rem;
  max-width: 100%;
  height: 46rem;
`;
