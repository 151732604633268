import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { AxiosError } from 'axios';
import moment from 'moment';
import DateUtils from '../../common/utils/services/date-utils';
import { DailyDataState } from '../states/daily-data.state';
import {
  createJournalEntry,
  loadDailyDataBetweenDateRange,
  loadDailyDataEntity,
  loadUserDailyData,
  updateJournalEntry,
} from '../thunks/daily-data.thunk';
import { DailyData } from '../../common/model/dto/daily-data';
import { JournalEntry } from '../../common/model/dto/journal-entry';

const initialState: DailyDataState = {
  dailyDataList: [],
  visibleDailyDataInCalendar: [],
  errorMessage: '',
  isLoading: false,
  isUserDailyDataLoading: false,
};

const dailyDataSlice = createSlice({
  name: 'dailyData',
  initialState,
  reducers: {
    selectDaySlot: (state, action: PayloadAction<Date>) => {
      if (action.payload) {
        state.selectedDay = Timestamp.fromDate(action.payload);

        if (state.visibleDailyDataInCalendar?.length) {
          state.selectedDailyDataInCalendar =
            state.visibleDailyDataInCalendar.find(({ day }) => {
              return DateUtils.areSameDays(
                action.payload,
                moment(day, 'YYYY-MM-DD').toDate()
              );
            });
        }
      }
    },
    clearDaySlotSelection: (state) => {
      state.selectedDailyDataInCalendar = undefined;
      state.selectedDay = undefined;
    },
    clearSelectedDailyData: (state) => {
      state.selectedDailyData = undefined;
    },
    clearDailyDataList: (state) => {
      state.dailyDataList = [];
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadDailyDataBetweenDateRange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadDailyDataBetweenDateRange.fulfilled,
        (state, action: PayloadAction<DailyData[]>) => {
          state.isLoading = false;
          state.visibleDailyDataInCalendar = action.payload;
        }
      )
      .addCase(
        loadDailyDataBetweenDateRange.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadUserDailyData.pending, (state) => {
        state.isUserDailyDataLoading = true;
      })
      .addCase(
        loadUserDailyData.fulfilled,
        (state, action: PayloadAction<DailyData[]>) => {
          state.isUserDailyDataLoading = false;
          state.dailyDataList = action.payload;
        }
      )
      .addCase(
        loadUserDailyData.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isUserDailyDataLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createJournalEntry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        createJournalEntry.fulfilled,
        (state, action: PayloadAction<JournalEntry>) => {
          state.isLoading = false;
          if (state.selectedDailyData) {
            state.selectedDailyData.journal_entry = action.payload;
            state.selectedDailyData.journal_entry_id = action.payload.id;
          }
        }
      )
      .addCase(
        createJournalEntry.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadDailyDataEntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadDailyDataEntity.fulfilled,
        (state, action: PayloadAction<DailyData>) => {
          state.isLoading = false;
          state.selectedDailyData = action.payload;
        }
      )
      .addCase(
        loadDailyDataEntity.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateJournalEntry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateJournalEntry.fulfilled,
        (state, action: PayloadAction<DailyData>) => {
          state.isLoading = false;
          if (state.selectedDailyData) {
            state.selectedDailyData.journal_entry = action.payload;
          }
        }
      )
      .addCase(
        updateJournalEntry.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );
  },
});

export const {
  selectDaySlot,
  clearDaySlotSelection,
  clearSelectedDailyData,
  clearDailyDataList,
} = dailyDataSlice.actions;
export default dailyDataSlice.reducer;
