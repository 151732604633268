import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isNumber } from 'lodash';
import { Clinic } from '../../common/model/dto/clinic';
import { ListPaging } from '../../common/types';
import {
  ClinicInfoFields,
  DocumentFields,
} from '../../firebase/document-field.enums';
import ReducerUtils from '../reducer.utils';
import { AdminClinicState } from '../states/admin-clinic.state';
import {
  createClinic,
  deleteClinic,
  getClinicById,
  loadClinics,
  updateClinic,
} from '../thunks/admin/admin-clinic.thunk';

const initialState: AdminClinicState = {
  clinicList: [],
  clinicListPaging: {
    total: 0,
    offset: 0,
  },
  sortingOptions: [
    {
      value: DocumentFields.id,
      label: 'common.userFields.userId',
      direction: 'asc',
      multiDir: false,
    },
    {
      value: ClinicInfoFields.name,
      label: 'common.clinicFields.clinicName',
      direction: '',
      multiDir: true,
    },
  ],
  filters: [],
  isLoading: false,
  selectedClinic: null,
  clinicListStoredQuery: '',
  error: '',
};

const adminClinicSlice = createSlice({
  name: 'adminClinic',
  initialState,
  reducers: {
    setClinicListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.clinicListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.clinicListPaging.offset = action.payload.offset;
      }
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.filters = ReducerUtils.restoreFilters(
        action.payload,
        state.filters
      );
      state.sortingOptions = ReducerUtils.restoreSorting(
        action.payload,
        state.sortingOptions
      );
      state.clinicListPaging.offset = action.payload.offset;
    },
    setClinicListStoredQuery: (state, action: PayloadAction<string>) => {
      state.clinicListStoredQuery = action.payload;
    },
    clearClinicList: (state) => {
      state.clinicList = [];
    },
    clearSelectedClinic: (state) => {
      state.selectedClinic = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadClinics.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        loadClinics.fulfilled,
        (state, action: PayloadAction<Clinic[]>) => {
          state.clinicList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getClinicById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getClinicById.fulfilled,
        (state, action: PayloadAction<Clinic>) => {
          state.selectedClinic = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(getClinicById.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createClinic.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        createClinic.fulfilled,
        (state, action: PayloadAction<Clinic>) => {
          state.isLoading = false;

          if (state.clinicList) {
            state.clinicList = [action.payload, ...state.clinicList];
          } else {
            state.clinicList = [action.payload];
          }
        }
      )
      .addCase(
        createClinic.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateClinic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateClinic.fulfilled,
        (
          state,
          action: PayloadAction<{
            clinicId: string;
            updatedProperties: any;
          }>
        ) => {
          state.selectedClinic = {
            ...state.selectedClinic,
            ...action.payload?.updatedProperties,
          };
          state.clinicList = state.clinicList.map((clinic) => {
            if (clinic.id === action.payload?.clinicId) {
              return {
                ...clinic,
                ...action.payload?.updatedProperties,
              };
            }

            return clinic;
          });

          state.isLoading = false;
        }
      )
      .addCase(updateClinic.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteClinic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClinic.fulfilled, (state) => {
        state.clinicList = [];
        state.isLoading = false;
      })
      .addCase(deleteClinic.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setClinicListPaging,
  restoreSearchModel,
  clearClinicList,
  setClinicListStoredQuery,
  clearSelectedClinic,
} = adminClinicSlice.actions;
export default adminClinicSlice.reducer;
