import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import OvTable from '../molecules/OvTable';
import breakpoints from '../../../design-system/breakpoints';
import {
  cancelActiveScanningPlanForUser,
  loadAllUserRoles,
  updateClinicLocationProviderData,
  updateUserInfo,
} from '../../../redux/thunks/user.thunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { UserInfoDataUtils } from '../../../common/utils/services/user-info-data-utils';
import OvSelect from '../atoms/OvSelect';
import Colours from '../../../design-system/colours';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { USER_PROFILE_IMAGES_PATH } from '../../../api/firebase-storage';
import OvEditableAvatar from '../molecules/OvEditableAvatar';
import PersonIcon from '@mui/icons-material/Person';
import Variables from '../../../design-system/variables';
import OvScanningPlanTable from '../molecules/OvScanningPlanTable';
import OvButton from '../atoms/OvButton';
import OvActivateScanningPlanDialog from '../molecules/OvActivateScanningPlanDialog';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import DateUtils from '../../../common/utils/services/date-utils';
import useAuthorized from '../../../hooks/use-authorized';
import { Resource } from '../../../common/model/type/resource.enum';

const OvUserInfo: FC<{ selectedUser?: UserInfo }> = ({ selectedUser }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectClinicLocationValue, setSelectClinicLocationValue] = useState<
    string | undefined
  >('');
  const [isActivateScanningPlanOpen, setIsActivateScanningPlanOpen] =
    useState(false);
  const [
    isCancelActiveScanningPlanDialogOpen,
    setIsCancelActiveScanningPlanDialogOpen,
  ] = useState(false);

  const areAllUserRolesLoading = useAppSelector(
    (state) => state.user.areAllUserRolesLoading
  );
  const roles = useAppSelector((state) => state.user.allUserRoles);

  const applicationOperations = useAuthorized(Resource.Application);
  const userOperations = useAuthorized(Resource.User);
  const scanningPlanOperations = useAuthorized(Resource.ScanningPlan);

  const userUpdateAllowed =
    userOperations.update || applicationOperations.supervise;
  const scanningPlanReadAllowed =
    scanningPlanOperations.read || applicationOperations.supervise;
  const scanningPlanUpdateAllowed =
    scanningPlanOperations.update || applicationOperations.supervise;

  useEffect(() => {
    if (selectedUser && selectedUser?.provider_clinic_locations.length > 0) {
      setSelectClinicLocationValue(
        selectedUser?.provider_clinic_locations.length > 0
          ? selectedUser.provider_clinic_locations[0].id
          : undefined
      );
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!areAllUserRolesLoading) {
      dispatch(loadAllUserRoles());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserInfoUpdate = (updatedProperties: any) => {
    if (selectedUser?.id) {
      const updateObj = {
        userId: selectedUser.id,
        updatedProperties: {
          ...updatedProperties,
        },
      };

      dispatch(updateUserInfo(updateObj));
    }
  };

  const handleProviderUserInfoUpdate = (updatedProperties: any) => {
    if (selectClinicLocationValue) {
      const updateObj = {
        clinicLocationProviderId: selectClinicLocationValue,
        updatedProperties: {
          ...updatedProperties,
        },
      };

      dispatch(updateClinicLocationProviderData(updateObj));
    }
  };

  const handleCancelActiveScanningPlanForUser = () => {
    if (selectedUser?.id) {
      const day = DateUtils.getDbDateTag(new Date());

      dispatch(
        cancelActiveScanningPlanForUser({
          userId: selectedUser?.id,
          cancelActiveScanningPlanRequest: { day },
        })
      );

      setIsCancelActiveScanningPlanDialogOpen(false);
    }
  };

  return (
    <Container>
      <TopContainer>
        <GeneralInfoContainer>
          <OvEditableAvatar
            entity={selectedUser}
            handleUpdate={handleUserInfoUpdate}
            imageBasePath={USER_PROFILE_IMAGES_PATH}
            dialogTitle={t('userDialogs.userImageUploadTitle')}
            defaultImageComponent={<ProfileImage />}
            style={{ boxShadow: 'none' }}
          />
          <GeneralInfoWrapper>
            <StyledSectionHeader>
              {t('userDetails.sections.generalInfo')}
            </StyledSectionHeader>
            <TableWrapper>
              <OvTable
                data={UserInfoDataUtils.mapGeneralUserInfoDataToTableFormat(
                  selectedUser
                )}
                onSaveRowValue={handleUserInfoUpdate}
                editable={userUpdateAllowed}
              />
            </TableWrapper>
          </GeneralInfoWrapper>
        </GeneralInfoContainer>
      </TopContainer>
      <SplitContainer>
        <InfoContainer>
          <StyledSectionHeader>
            {t('userDetails.sections.healthInfo')}
          </StyledSectionHeader>
          <TableWrapper>
            <OvTable
              data={UserInfoDataUtils.mapUserHealthInfoToTableDataFormat(
                selectedUser,
                true
              )}
              onSaveRowValue={handleUserInfoUpdate}
              editable={userUpdateAllowed}
            />
          </TableWrapper>
        </InfoContainer>
        <InfoContainer>
          <StyledSectionHeader>
            {t('userDetails.sections.accountDetails')}
          </StyledSectionHeader>
          <TableWrapper>
            <OvTable
              data={UserInfoDataUtils.mapUserAccountDetailsToTableDataFormat(
                selectedUser,
                roles
              )}
              onSaveRowValue={handleUserInfoUpdate}
              editable={applicationOperations.supervise}
            />
          </TableWrapper>
        </InfoContainer>
      </SplitContainer>
      {selectedUser?.cycle_info && (
        <InfoContainer>
          <StyledSectionHeader>
            {t('userDetails.sections.cycleInfo')}
          </StyledSectionHeader>
          <TableWrapper>
            <OvTable
              data={UserInfoDataUtils.mapUserCycleInfoToTableDataFormat(
                selectedUser?.cycle_info
              )}
              onSaveRowValue={handleUserInfoUpdate}
              editable={userUpdateAllowed}
            />
          </TableWrapper>
        </InfoContainer>
      )}
      {scanningPlanReadAllowed && selectedUser?.recommended_scanning_plan && (
        <InfoContainer>
          <HeaderContainer>
            <StyledSectionHeader>
              {t('userDetails.sections.scanningPlans')}
            </StyledSectionHeader>
            {scanningPlanUpdateAllowed &&
              (selectedUser?.active_scanning_plan_snapshot ? (
                <StyledOvButton
                  onClick={() => setIsCancelActiveScanningPlanDialogOpen(true)}
                >
                  {t('userDetails.actions.cancelActiveScanningPlan')}
                </StyledOvButton>
              ) : (
                <StyledOvButton
                  onClick={() => setIsActivateScanningPlanOpen(true)}
                >
                  {t('userDetails.actions.activateScanningPlan')}
                </StyledOvButton>
              ))}
          </HeaderContainer>
          <OvScanningPlanTable user={selectedUser} />
        </InfoContainer>
      )}
      {selectedUser && selectedUser?.provider_clinic_locations.length > 0 && (
        <InfoContainer>
          <TableWrapper>
            <>
              <StyledHeaderWrapper>
                <StyledHeader>
                  {t('common.userFields.providerData.providerInfo')}
                </StyledHeader>
                <StyledSelect
                  labelId="clinicLocationSelect"
                  value={selectClinicLocationValue}
                  onChange={(event) => {
                    setSelectClinicLocationValue(event.target.value as string);
                  }}
                >
                  {selectedUser.provider_clinic_locations.map(
                    (providerClinicLocation) => (
                      <MenuItem
                        key={providerClinicLocation.id}
                        value={providerClinicLocation.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          padding: '5px',
                        }}
                      >
                        {providerClinicLocation.clinic_location.name}
                      </MenuItem>
                    )
                  )}
                </StyledSelect>
              </StyledHeaderWrapper>
              <OvTable
                data={UserInfoDataUtils.mapProviderInfoToTableDataFormat(
                  selectedUser,
                  selectClinicLocationValue
                )}
                onSaveRowValue={handleProviderUserInfoUpdate}
                editable={userUpdateAllowed}
              />
            </>
          </TableWrapper>
        </InfoContainer>
      )}

      <OvActivateScanningPlanDialog
        isOpen={isActivateScanningPlanOpen}
        onCancel={() => setIsActivateScanningPlanOpen(false)}
        user={selectedUser}
      />

      <OvConfirmationDialog
        icon={<StyledCancelIcon />}
        isOpen={isCancelActiveScanningPlanDialogOpen}
        onCancel={() => setIsCancelActiveScanningPlanDialogOpen(false)}
        onConfirm={handleCancelActiveScanningPlanForUser}
        title="userDetails.actions.cancelActiveScanningPlanForUserTitle"
        description="userDetails.actions.cancelActiveScanningPlanForUserDescription"
      />
    </Container>
  );
};

export default OvUserInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
`;

const GeneralInfoContainer = styled.div`
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 0.75rem;
  padding: 1rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const GeneralInfoWrapper = styled.div`
  width: 100%;
`;

const InfoContainer = styled.div`
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;
`;

const ProfileImage = styled(PersonIcon)`
  && {
    width: 12rem !important;
    height: 12rem !important;
    border: 0.375rem solid;
    border-radius: 50%;
    margin-bottom: 0.25rem;
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeader = styled.h4``;

const StyledSelect = styled(OvSelect)`
  && {
    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      margin-left: 1rem;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_LIGHT_GRAY};
      }
    }
  }
`;

const StyledSectionHeader = styled.h3`
  margin: 0 0 0 0.5rem;
`;

const SplitContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledCancelIcon = styled(CancelIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
