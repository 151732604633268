import React, { FC } from 'react';
import { OrderItem } from '../../../common/model/dto/order/order-item';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import StringUtils from '../../../common/utils/services/string-utils';

export interface OvOrderItemProps {
  item: OrderItem;
}

const OvEditableOrderItems: FC<OvOrderItemProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledText>{`${t('orders.details.item.sku')}: ${StringUtils.displayValue(
        item?.sku
      )}`}</StyledText>
      <StyledText>{`${t(
        'orders.details.item.name'
      )}: ${StringUtils.displayValue(item?.name)}`}</StyledText>
      <StyledText>
        {`${t('orders.details.item.price')}: ${StringUtils.displayValue(
          `$${item?.price}`
        )}`}
      </StyledText>
      <StyledText>
        {`${t('orders.details.item.discount')}: ${StringUtils.displayValue(
          item?.discount ? `$${item?.discount}` : '-'
        )}`}
      </StyledText>
      <StyledText>
        {`${t('orders.details.item.quantity')}: ${StringUtils.displayValue(
          `${item?.quantity}`
        )}`}
      </StyledText>
    </>
  );
};

export default OvEditableOrderItems;

const StyledText = styled.p`
  margin: 0;
`;
