import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import OvButton from './OvButton';

export interface OvBackButtonProps {
  label: string;
}

const OvBackButton: FC<OvBackButtonProps> = ({ label }) => {
  let location = useLocation();
  const history = useHistory();
  const [prevPath, setPrevPath] = React.useState<string>('');

  useEffect(() => {
    if ((location as any).state?.prevPath) {
      setPrevPath((location as any)?.state?.prevPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackClicked = () => {
    if (prevPath) {
      history.push(prevPath);
    } else {
      history.goBack();
    }
  };

  return (
    <BackCta onClick={onBackClicked}>
      <StyledArrowBackIcon />
      {label}
    </BackCta>
  );
};

export default OvBackButton;

const BackCta = styled(OvButton)`
  && {
    padding: 0 10px 0 4px;
    margin-right: 1rem;
    text-transform: none;
    font-weight: bold;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 0.25rem;
  height: 1.25rem;
`;
