import axios from 'axios';
import { ADMIN_SCANS_ROUTE } from '../../api/backend';
import { Scan } from '../../common/model/dto/scan';

export class AdminScanService {
  static async process(scanId: string, toolkitVersion?: string): Promise<Scan> {
    const { data } = await axios.get<Scan>(
      `${ADMIN_SCANS_ROUTE}/${scanId}/process`,
      { params: { toolkit_version: toolkitVersion } }
    );

    return data;
  }
}
