import axios from 'axios';
import { ADMIN_NOTIFICATION_ROUTE } from '../../api/backend';
import { PublishNotificationRequest } from '../../common/model/dto/notification/publish-notification-request';

export const createNotifications = async (
  notificationContentId: string,
  publishNotificationRequest: PublishNotificationRequest
) => {
  return await axios.post(
    `${ADMIN_NOTIFICATION_ROUTE}/notification-contents/${notificationContentId}/create`,
    publishNotificationRequest
  );
};
