import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { ConfigurationTabType } from '../../common/model/type/configuration-tab.type';
import ConfigurationSystem from './ConfigurationSystem';
import ConfigurationUserInfoRequiredFields from './ConfigurationUserInfoRequiredFields';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import SettingsIcon from '@mui/icons-material/Settings';

const Configuration = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();

  const [activeTab, setActiveTab] = useState<ConfigurationTabType>(
    ConfigurationTabType.SYSTEM
  );

  const changeTab = (event: any, newValue: ConfigurationTabType) => {
    setActiveTab(newValue);
  };

  return (
    <Container>
      <OvPageTitle
        title={t('configurationSettings.title')}
        icon={<SettingsIcon />}
      />
      <ContentWrapper>
        <TabsContainer>
          <Tabs value={activeTab} onChange={changeTab}>
            <Tab
              value="system"
              label={t('configurationSettings.tabs.system')}
              component={Link}
              to={`${url}/system`}
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
            />
            <Tab
              value="user-info-required-fields"
              label={t('configurationSettings.tabs.userInfoRequiredFields')}
              component={Link}
              to={`${url}/user-info-required-fields`}
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
            />
          </Tabs>
        </TabsContainer>

        <Switch>
          <Route exact path={path}>
            <Redirect to={`${url}/system`} />
          </Route>
          <Route path={`${path}/system`}>
            <ConfigurationSystem />
          </Route>
          <Route path={`${path}/user-info-required-fields`}>
            <ConfigurationUserInfoRequiredFields />
          </Route>
        </Switch>
      </ContentWrapper>
    </Container>
  );
};

export default Configuration;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

const TabsContainer = styled.div`
  margin-bottom: 1.5rem;

  && .MuiTabs-indicator {
    background-color: ${Colours.OV_BASE};
  }
`;
