import React, { FC } from 'react';
import styled from 'styled-components';
import { Clinic, ClinicOverview } from '../../../common/model/dto/clinic';
import OvClinicListRow from './OvClinicListRow';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../../../common/types';
import Colours from '../../../design-system/colours';
import { Resource } from '../../../common/model/type/resource.enum';
import useAuthorized from '../../../hooks/use-authorized';

interface OvClinicListProps {
  clinics: Clinic[] | ClinicOverview[];
  onSort: (orderBy: string) => void;
  orderBy: string;
  orderDir: SortDirection;
}

const OvClinicList: FC<OvClinicListProps> = ({
  clinics,
  onSort,
  orderBy,
  orderDir,
}) => {
  const { t } = useTranslation();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicAuditLogActivityOperations = useAuthorized(
    Resource.ClinicAuditLogActivity
  );

  const clinicAuditLogActivityReadAllowed =
    clinicAuditLogActivityOperations.read || applicationOperations.supervise;

  const getSortDirection = (field: string): SortDirection =>
    orderBy === field ? orderDir : 'none';

  return (
    <WrapperBox>
      {clinicAuditLogActivityReadAllowed && (
        <InfoSection>{t('common.clinicFields.requestCountInfo')}</InfoSection>
      )}
      <OvListTable>
        <OvListTableRow>
          <OvListTableHeaderField
            sortBy="name"
            sortDirection={getSortDirection('name')}
            onSort={() => onSort('name')}
          >
            {t('common.clinicFields.clinicName')}
          </OvListTableHeaderField>
          <OvListTableHeaderField
            sortBy="clinic_location_count"
            sortDirection={getSortDirection('clinic_location_count')}
            onSort={() => onSort('clinic_location_count')}
          >
            {t('common.clinicFields.clinicSites')}
          </OvListTableHeaderField>
          <OvListTableHeaderField
            sortBy="clinic_location_provider_count"
            sortDirection={getSortDirection('clinic_location_provider_count')}
            onSort={() => onSort('clinic_location_provider_count')}
          >
            {t('common.clinicFields.providers')}
          </OvListTableHeaderField>
          <OvListTableHeaderField
            sortBy="clinic_location_patient_count"
            sortDirection={getSortDirection('clinic_location_patient_count')}
            onSort={() => onSort('clinic_location_patient_count')}
          >
            {t('common.clinicFields.patients')}
          </OvListTableHeaderField>
          {clinicAuditLogActivityReadAllowed && (
            <OvListTableHeaderField
              sortBy="request_count"
              sortDirection={getSortDirection('request_count')}
              onSort={() => onSort('request_count')}
            >
              {t('common.clinicFields.requestCount')}
            </OvListTableHeaderField>
          )}
          <OvListTableHeaderField
            sortBy="created_at"
            sortDirection={getSortDirection('created_at')}
            onSort={() => onSort('created_at')}
          >
            {t('common.createdAt')}
          </OvListTableHeaderField>
          <OvListTableHeaderField style={{ textAlign: 'right' }}>
            {t('common.action')}
          </OvListTableHeaderField>
        </OvListTableRow>

        {clinics.map((clinic) => (
          <OvListTableRow key={clinic.id}>
            <OvClinicListRow clinic={clinic} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvClinicList;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const InfoSection = styled.div`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: ${Colours.BLACK};
  padding: 0.5rem;
  background-color: ${Colours.LIGHT_BLUE_GRAY};
  border-radius: 0.5rem;
`;
