import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { ProviderCountryEnum } from '../../../common/model/dto/fullscript/provider-country.enum';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import OvConfirmationDialog from './OvConfirmationDialog';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
  requestFullScriptAccessToken,
  revokeFullScriptAccess,
} from '../../../redux/thunks/fullscript.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { Constants } from '../../../common/constants';
import { parseUrl } from 'query-string';
import { Link, useLocation } from 'react-router-dom';

export interface OvLinkFullScriptProps {
  redirectUri?: string;
}
export type LinkToFsStepState = 'Linked' | 'Unlinked' | 'CountrySelect';

const OvLinkFullScript: FC<OvLinkFullScriptProps> = ({ redirectUri }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState<LinkToFsStepState>();
  const [isOpenConfirmUnlinkDialog, setOpenConfirmUnlinkDialog] =
    useState<boolean>(false);
  const fullScriptUserInfo = useAppSelector(
    (state) => state.user.currentUser?.fullscript
  );
  const isCurrentUserLoading = !!useAppSelector(
    (state) => state.user.isLoading
  );
  const isFsAuthLoading = useAppSelector((state) => state.fullScript.loading);
  const REDIRECT_URI = redirectUri || `${window.location.href}`;
  let currentStepView;

  useEffect(() => {
    if (!isCurrentUserLoading) {
      setCurrentStep(!!fullScriptUserInfo ? 'Linked' : 'Unlinked');
    }
  }, [fullScriptUserInfo, isCurrentUserLoading]);

  useEffect(() => {
    const tempAuthCode: string = parseUrl(search)?.query?.code as string;

    if (tempAuthCode) {
      dispatch(
        requestFullScriptAccessToken({
          code: tempAuthCode,
          selected_country: localStorage.getItem(
            Constants.LOCAL_STORAGE_KEYS.FS_SELECTED_PROVIDER_COUNTRY
          ) as ProviderCountryEnum,
        })
      );
    }

    // eslint-disable-next-line
  }, []);

  const requestAuthCode = (providerCountry: string) => {
    localStorage.setItem(
      Constants.LOCAL_STORAGE_KEYS.FS_SELECTED_PROVIDER_COUNTRY,
      providerCountry
    );
    const clientId = providerCountry.startsWith('us')
      ? process.env.REACT_APP_FS_CLIENT_ID_US
      : process.env.REACT_APP_FS_CLIENT_ID_CA;
    window.location.href = `https://${
      process.env.REACT_APP_FS_PROVIDER_ENV_PREFIX ?? ''
    }${providerCountry}.fullscript.io/oauth/authorize?client_id=${clientId}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  };

  const handleCountrySelect = (providerCountry: ProviderCountryEnum) => {
    requestAuthCode(providerCountry + process.env.REACT_APP_FS_PROVIDER_ENV);
  };

  const handleUnlink = () => {
    setOpenConfirmUnlinkDialog(false);
    dispatch(revokeFullScriptAccess());
  };

  if (currentStep === 'Unlinked') {
    currentStepView = (
      <>
        <Description>{t('integrations.fsLinkTitle')}</Description>
        <ConnectCta onClick={() => setCurrentStep('CountrySelect')}>
          {t('integrations.fsLinkAccount')}
        </ConnectCta>
        <LinkList>
          <LinkWrapper>
            <Info>{t('integrations.fsCreateInfo')}</Info>
            <LinkCta
              href="https://us.fullscript.com/practitioner-signup/oova"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('integrations.fsCreateLinkLabel')}
            </LinkCta>
          </LinkWrapper>

          <LinkWrapper>
            <Info>{t('integrations.fsLearnInfo')}</Info>
            <LinkCta
              href="https://fullscript.com/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('integrations.fsLearnLinkLabel')}
            </LinkCta>
          </LinkWrapper>
        </LinkList>
      </>
    );
  } else if (currentStep === 'CountrySelect') {
    currentStepView = (
      <>
        <Description>{t('integrations.fsCountrySelect')}</Description>
        <CountrySelectWrapper>
          <CountryCta
            onClick={() => handleCountrySelect(ProviderCountryEnum.US)}
          >
            <CountryImage
              src="/images/fullscript/us-flag.png"
              alt={t('app.subPages.menu.fullScript')}
            />
            {t('countries.us')}
          </CountryCta>
          <CountryCta
            onClick={() => handleCountrySelect(ProviderCountryEnum.CA)}
          >
            <CountryImage
              src="/images/fullscript/ca-flag.png"
              alt={t('app.subPages.menu.fullScript')}
            />
            {t('countries.ca')}
          </CountryCta>
        </CountrySelectWrapper>
      </>
    );
  } else if (currentStep === 'Linked') {
    currentStepView = (
      <>
        <Description>{t('integrations.fsUnlinkTitle')}</Description>
        <Description>
          {t('integrations.fsUnlinkDesc')}:{' '}
          <LinkCta
            href="https://fullscript.com/integrations"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common.view')}
          </LinkCta>
        </Description>
        <Description>
          {t('integrations.fsLinkedUsagePlace')}{' '}
          <StyledLink to={`../patients`}>
            {t('integrations.fsSelectPatient')}
          </StyledLink>
        </Description>
        <ConnectCta onClick={() => setOpenConfirmUnlinkDialog(true)}>
          {t('integrations.unlinkFsCta')}
        </ConnectCta>
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <Title>
          <FsLogoImage
            src="/images/fullscript/fs.png"
            alt={t('app.subPages.menu.fullScript')}
          />

          {t('app.subPages.menu.fullScript')}
        </Title>

        {currentStepView}
      </Wrapper>

      <OvConfirmationDialog
        isOpen={isOpenConfirmUnlinkDialog}
        onCancel={() => setOpenConfirmUnlinkDialog(false)}
        onConfirm={handleUnlink}
        title={t('integrations.fsUnlinkConfirmationTitle')}
        icon={<StyledLinkOffIcon />}
      />

      {(isFsAuthLoading || isCurrentUserLoading) && (
        <OvLoadingIndicator position="fixed" />
      )}
    </>
  );
};

export default OvLinkFullScript;

const Wrapper = styled.article`
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.MEDIUM};
  border: 1px solid ${Colours.OV_LIGHT_GRAY};
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

const FsLogoImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const Description = styled.p`
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

const ConnectCta = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.MEDIUM};
    padding: 0.25rem 0.75rem;
    background-color: ${Colours.FULLSCRIPT_GREEN};
    color: ${Colours.FULLSCRIPT_COAL} !important;
    text-transform: none;
    border: none !important;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: ${Colours.FULLSCRIPT_GREEN};
      color: ${Colours.FULLSCRIPT_COAL} !important;
    }
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Info = styled.p`
  margin: 0 0.25rem 0 0;
`;

const LinkCta = styled.a`
  text-decoration: none;
  color: ${Colours.OV_VIVID_BLUE};

  &:hover {
    color: ${Colours.OV_LIGHT_BLUE_HOVER};
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Colours.OV_VIVID_BLUE};

  &:hover {
    color: ${Colours.OV_LIGHT_BLUE_HOVER};
    text-decoration: none;
  }
`;

const CountrySelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CountryCta = styled(OvButton)`
  border-radius: ${Variables.borderRadius.MEDIUM};
  padding: 0.25rem 0.75rem !important;
  text-transform: none !important;
  margin-bottom: 0.5rem;
  margin-right: 1.5rem !important;

  &:last-child {
    margin-right: 0 !important;
  }
`;

const CountryImage = styled.img`
  height: 1rem;
  margin-right: 0.5rem;
`;

const StyledLinkOffIcon = styled(LinkOffIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
