import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { Field, Form, Formik } from 'formik';
import OvFormObserver from '../atoms/OvFormObserver';
import OvTextFieldFormControl from '../atoms/OvTextFieldFormControl';
import { OrderChannel } from '../../../common/model/type/order-channel.enum';
import { OrderStatus } from '../../../common/model/type/order-status.enum';
import OvSelectFormControl from '../atoms/OvSelectFormControl';
import { MenuItem } from '@mui/material';
import Variables from '../../../design-system/variables';
import OvDatePickerFormControl from '../atoms/OvDatePickerFormControl';

export interface OrderGeneralInfo {
  internal_id: string;
  user_email: string;
  user_id: string;
  channel: OrderChannel;
  order_number: string;
  ordered_at: Date;
  is_subscription: boolean;
  delivery_method: 'standard' | 'expedited';
  note?: string;
  status: OrderStatus;
}

export interface OvOrderGeneralInfoControlProps {
  onChangeGeneralInfo: (
    generalInfo: OrderGeneralInfo,
    isValid: boolean
  ) => void;
  generalInfo?: OrderGeneralInfo;
}

const OvOrderGeneralInfoControl: FC<OvOrderGeneralInfoControlProps> = ({
  generalInfo,
  onChangeGeneralInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        user_email: generalInfo?.user_email,
        delivery_method: generalInfo?.delivery_method || 'standard',
        channel: generalInfo?.channel || 'Manual',
        is_subscription: false,
        ordered_at: generalInfo?.ordered_at || null,
        note: generalInfo?.note,
      }}
      enableReinitialize={true}
      onSubmit={(values) => console.log(values)}
      validationSchema={Yup.object().shape({
        user_email: Yup.string().email().required(),
        note: Yup.string(),
        ordered_at: Yup.date().required(),
      })}
      validateOnBlur={true}
    >
      <Form>
        <Field
          component={StyledTextField}
          type="email"
          name="user_email"
          placeholder={t('orders.generalInfo.email')}
        />

        <Field component={StyledSelectField} name="delivery_method">
          <StyledMenuItem key="standard" value="standard">
            Standard
          </StyledMenuItem>
          <StyledMenuItem key="expedited" value="expedited">
            Expedited
          </StyledMenuItem>
        </Field>

        <Field component={StyledSelectField} disabled={true} name="channel">
          <StyledMenuItem key="Shopify" value="Shopify">
            Shopify
          </StyledMenuItem>
          <StyledMenuItem key="Stripe" value="Stripe">
            Stripe
          </StyledMenuItem>
          <StyledMenuItem key="Manual" value="Manual">
            Manual
          </StyledMenuItem>
        </Field>

        <StyledLabel>
          <span>{t('common.orderedAt')}</span>

          <Field
            component={OvDatePickerFormControl}
            type="text"
            name="ordered_at"
          />
        </StyledLabel>

        <Field
          component={StyledTextField}
          type="text"
          name="note"
          placeholder={t('orders.generalInfo.note')}
        />

        <OvFormObserver valuesChanged={onChangeGeneralInfo} />
      </Form>
    </Formik>
  );
};

export default OvOrderGeneralInfoControl;

const StyledTextField = styled(OvTextFieldFormControl)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      display: block;
      width: 100%;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;

const StyledSelectField = styled(OvSelectFormControl)`
  && {
    width: 100%;
    padding: 0.75rem 0.875rem;
    margin-bottom: 0.75rem;

    .MuiSelect-select {
      padding: 0;
    }

    &.Mui-disabled {
      border: inherit;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  height: 3rem;
  margin-bottom: 0.75rem;
  gap: 1rem;

  > span {
    font-size: ${Variables.fontSizes.MEDIUM};
  }

  .MuiFormControl-root {
    flex-grow: 1;
  }
`;
