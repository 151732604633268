import React, { useState } from 'react';
import { Product } from '../../../common/model/dto/product/product';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import OvProductCategoryPreview from './OvProductCategoryPreview';
import OvProductPreview from './OvProductPreview';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

enum ProductDevicePreviewScreen {
  CATEGORY = 'category',
  PRODUCT = 'product',
}

const OvProductDevicePreview: React.FC<{
  product: Product;
}> = ({ product }) => {
  const { t } = useTranslation();
  const [activeScreen, setActiveScreen] = useState<ProductDevicePreviewScreen>(
    ProductDevicePreviewScreen.CATEGORY
  );
  const [selectedPriceId, setSelectedPriceId] = useState<string>();
  const onItemSelected = (priceId: string) => {
    setActiveScreen(ProductDevicePreviewScreen.PRODUCT);
    setSelectedPriceId(priceId);
  };

  return (
    <Wrapper>
      <Header
        style={{
          marginBottom:
            activeScreen === ProductDevicePreviewScreen.CATEGORY
              ? '2rem'
              : '1rem',
        }}
      >
        {activeScreen !== ProductDevicePreviewScreen.CATEGORY && (
          <StyledArrowBackIcon
            onClick={() => setActiveScreen(ProductDevicePreviewScreen.CATEGORY)}
          />
        )}
        <Title>
          {activeScreen === ProductDevicePreviewScreen.CATEGORY
            ? t('common.oovaShop')
            : product.title}
        </Title>
      </Header>

      {activeScreen === ProductDevicePreviewScreen.CATEGORY ? (
        <OvProductCategoryPreview
          product={product}
          inItemSelected={onItemSelected}
        />
      ) : (
        <OvProductPreview
          price={product.product_prices.find(
            (prices) => prices.id === selectedPriceId
          )}
        />
      )}
    </Wrapper>
  );
};

export default OvProductDevicePreview;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Title = styled.h3`
  color: ${Colours.OV_BASE};
  margin: 0;
  text-align: center;
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 0.25rem;
  height: 1.25rem;
  cursor: pointer;
`;
