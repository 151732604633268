import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import OvDateParser from '../atoms/OvDateParser';

export interface OvRangePickerDisplayProps {
  startTitle?: string;
  endTitle?: string;
  startPlaceholder?: string;
  endPlaceholder?: string;
  startDate?: Date;
  endDate?: Date;
  handleClick?: (event: any) => void;
}

const OvRangePickerDisplay: FC<OvRangePickerDisplayProps> = ({
  startTitle,
  endTitle,
  startPlaceholder,
  endPlaceholder,
  startDate,
  endDate,
  handleClick,
}) => {
  return (
    <Wrapper onClick={handleClick} role="button">
      <Section>
        <Title>{startTitle}</Title>
        {startDate && !isNaN(startDate.getTime()) ? (
          <DateValue>
            <OvDateParser date={startDate} format={'MMMM DD, YYYY'} />
          </DateValue>
        ) : (
          <Placeholder>{startPlaceholder}</Placeholder>
        )}
      </Section>
      <Section>
        <Title>{endTitle}</Title>
        {endDate && !isNaN(endDate.getTime()) ? (
          <DateValue>
            <OvDateParser date={endDate} format={'MMMM DD, YYYY'} />
          </DateValue>
        ) : (
          <Placeholder>{endPlaceholder}</Placeholder>
        )}
      </Section>
    </Wrapper>
  );
};

export default OvRangePickerDisplay;

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const Section = styled.div`
  padding: 0;

  &:first-child {
    border-right: 1px solid ${Colours.OV_GRAY};
    padding-right: 2rem;
    margin-right: 2rem;
  }
`;

const Title = styled.h5`
  font-size: 1rem;
  margin: 0;
  opacity: 0.7;
  font-weight: 500;
`;

const Placeholder = styled.p`
  font-size: 0.875rem;
  margin: 0;
  opacity: 0.5;
`;

const DateValue = styled.p`
  font-weight: bold;
  margin: 0;
`;
