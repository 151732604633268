import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { History } from 'history';
import { ListPaging } from '../../common/types';
import {
  approvePatientInvitationForUser,
  assignPatientToClinicLocation,
  createDemoAccount,
  loadClinicLocationPatients,
  removePatientFromClinicLocation,
  restorePatientSearchFromUrl,
} from '../../redux/thunks/admin/admin-clinic-location.thunk';
import {
  clearUserList,
  setUserListPaging,
  setUserListStoredQuery,
} from '../../redux/reducers/admin-clinic-location.slice';
import { AdminClinicLocationService } from '../../services/admin/admin-clinic-location.service';
import styled from 'styled-components';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvClinicLocationUserList from '../UI/organisms/OvClinicLocationUserList';
import { UserInfo } from '../../common/model/dto/user-info';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvClinicLocationPatientSearchFilter from '../UI/organisms/OvClinicLocationPatientSearchFilter';
import OvButton from '../UI/atoms/OvButton';
import Variables from '../../design-system/variables';
import breakpoints from '../../design-system/breakpoints';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AssignPatientToClinicLocationRequest } from '../../common/model/dto/assign-user-to-clinic-location-request';
import OvAssignPatientToClinicLocationDialog from '../UI/molecules/OvAssignPatientToClinicLocationDialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Colours from '../../design-system/colours';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import OvCreateDemoAccountDialog from '../UI/molecules/OvCreateDemoAccountDialog';
import { CreateDemoAccountRequest } from '../../common/model/dto/create-demo-account-request';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';

const TABLE_HEADERS = [
  'common.userFields.name',
  'common.userFields.email',
  'common.status',
  'common.addedAt',
  'common.operation',
  'common.action',
];

const FilterableClinicLocationPatientsList: FC<{
  selectedClinicLocation: ClinicLocation;
  clinicId: string;
}> = ({ selectedClinicLocation, clinicId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicPatientOperations = useAuthorized(Resource.ClinicPatient);
  const demoAccountOperations = useAuthorized(Resource.DemoAccount);

  const clinicPatientCreateAllowed =
    clinicPatientOperations.create || applicationOperations.supervise;
  const clinicPatientUpdateAllowed =
    clinicPatientOperations.update || applicationOperations.supervise;

  const clinicPatientDeleteAllowed =
    clinicPatientOperations.delete || applicationOperations.supervise;
  const demoAccountCreateAllowed =
    demoAccountOperations.create || applicationOperations.supervise;

  const [page, setPage] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isCreateDemoAccountDialogOpen, setIsCreateaDemoAccountDialogOpen] =
    useState(false);
  const patientList: UserInfo[] = useAppSelector(
    (state) => state.adminClinicLocation.userList
  );
  const userListPaging: ListPaging = useAppSelector(
    (state) => state.adminClinicLocation.userListPaging
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.adminClinicLocation.isLoading
  );
  const userListStoredQuery: string = useAppSelector(
    (state) => state.adminClinicLocation.userListStoredQuery
  );
  const error = useAppSelector((state) => state.adminClinicLocation.error);

  useEffect(() => {
    if (selectedClinicLocation && clinicId) {
      if (search && search !== `?${userListStoredQuery}`) {
        // load from url (cases: reloading whole page, navigating with the browser history buttons)
        dispatch(
          restorePatientSearchFromUrl({
            clinicLocationId: selectedClinicLocation.id,
            query: search,
          })
        );
      } else if (!userListStoredQuery) {
        // the simplest load. There is no stored url query string, and nothing in the url
        dispatch(
          loadClinicLocationPatients({
            history,
            clinicLocationId: selectedClinicLocation.id,
            clinicId,
          })
        );
      } else if (!search && userListStoredQuery) {
        // jump back to the first page with side nav
        dispatch(
          setUserListPaging({
            offset: 0,
            total: userListPaging.total,
          })
        );
        dispatch(
          loadClinicLocationPatients({
            history,
            clinicLocationId: selectedClinicLocation.id,
            clinicId,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history, clinicId, selectedClinicLocation]);

  useEffect(() => {
    return () => {
      dispatch(setUserListStoredQuery(''));
      dispatch(clearUserList());
      dispatch(
        setUserListPaging({
          total: 0,
          offset: 0,
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(
      userListPaging.offset /
        AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT +
        1
    );
  }, [userListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setUserListPaging({
        offset:
          (value - 1) *
          AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
        total: userListPaging.total,
      })
    );

    dispatch(
      loadClinicLocationPatients({
        history,
        clinicLocationId: selectedClinicLocation.id,
        clinicId,
      })
    );
  };

  const pageCount: () => number = () =>
    Math.ceil(
      userListPaging?.total /
        AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT
    );

  const handleOnSave = (request: AssignPatientToClinicLocationRequest) => {
    if (selectedClinicLocation?.id) {
      dispatch(
        assignPatientToClinicLocation({
          clinicLocationId: selectedClinicLocation.id,
          request,
        })
      );
    }
    setIsDialogOpen(false);
  };

  const handleOnCreateDemoAccount = (
    createDemoAccountRequest: CreateDemoAccountRequest
  ) => {
    dispatch(
      createDemoAccount({
        createDemoAccountRequest,
        clinicLocationId: selectedClinicLocation?.id,
        history,
        clinicId,
      })
    );
    setIsCreateaDemoAccountDialogOpen(false);
  };

  return (
    <Container>
      <CenterPane>
        <UserActions>
          {pageCount() > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}

          <ButtonWrapper>
            {clinicPatientCreateAllowed && (
              <StyledOvButton onClick={() => setIsDialogOpen(true)}>
                <AddCircleIcon style={{ marginRight: '5' }} />
                {t('clinicLocations.actions.addNewPatient')}
              </StyledOvButton>
            )}

            {demoAccountCreateAllowed && (
              <StyledOvButton
                onClick={() => setIsCreateaDemoAccountDialogOpen(true)}
              >
                <PersonAddAltIcon style={{ marginRight: '5' }} />
                {t('common.actions.createDemoAccount')}
              </StyledOvButton>
            )}
          </ButtonWrapper>
        </UserActions>
        {error ? (
          <StyledErrorMessage>
            <StyledErrorOutlineIcon />
            {error}
          </StyledErrorMessage>
        ) : (
          ''
        )}

        {patientList?.length ? (
          <OvClinicLocationUserList
            users={patientList}
            clinicLocationId={selectedClinicLocation?.id}
            clinicId={clinicId}
            deleteAction={removePatientFromClinicLocation}
            deleteActionCtaLabel={t('clinicLocationDetails.actions.removeUser')}
            showClinicLocationPatientCreatedAt={true}
            approveInvitationAction={approvePatientInvitationForUser}
            approveInvitationCtaLabel={t(
              'clinicLocationDetails.actions.acceptInvitation'
            )}
            editable={clinicPatientUpdateAllowed}
            deletable={clinicPatientDeleteAllowed}
            tableHeaders={TABLE_HEADERS}
          />
        ) : (
          ''
        )}

        {!patientList?.length && !isLoading ? (
          <OvNoContent>
            {t('clinicLocationDetails.emptyList.patients')}
          </OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      {selectedClinicLocation && (
        <OvClinicLocationPatientSearchFilter
          clinicId={clinicId}
          clinicLocationId={selectedClinicLocation.id ?? ''}
        />
      )}

      <OvAssignPatientToClinicLocationDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
        title={t('clinicLocationDialogs.assignPatientTitle')}
      />

      <OvCreateDemoAccountDialog
        isOpen={isCreateDemoAccountDialogOpen}
        onCancel={() => setIsCreateaDemoAccountDialogOpen(false)}
        onSave={handleOnCreateDemoAccount}
        isLoading={isLoading}
        title={t('common.actions.createDemoAccount')}
        enableCreatePassword={true}
      />
    </Container>
  );
};

export default FilterableClinicLocationPatientsList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  min-width: 13rem;
  display: flex;
  flex-wrap: 'wrap';
  justify-content: flex-end;
  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 0.5rem;
`;
