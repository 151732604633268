import { List, ListItem, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Colours from '../../../design-system/colours';

const OvPasswordCheckList: FC<{
  hasMinLength: boolean;
  hasAtLeastOneUpperCaseCharacter: boolean;
  hasAtLeastOneLowerCaseCharacter: boolean;
  hasAtLeastOneDigit: boolean;
  hasSpecialCharacter: boolean;
  arePasswordsMatching: boolean;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <StyledList>
      {Object.keys(props).map((propName) => (
        <StyledListItem key={propName}>
          {props[propName as keyof typeof props] ? (
            <CheckIcon
              style={{
                color: Colours.OV_GREEN,
                marginRight: '0.5rem',
                fontSize: '1.75rem',
              }}
            />
          ) : (
            <CloseIcon
              style={{
                color: Colours.OV_RED,
                marginRight: '0.5rem',
                fontSize: '1.75rem',
              }}
            />
          )}
          {t(`setPassword.passwordCheck.${propName}`)}
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default OvPasswordCheckList;

const StyledList = styled(List)``;

const StyledListItem = styled(ListItem)`
  && {
    &.MuiButtonBase-root {
    }
  }
`;
