import React, { FC, useState } from 'react';
import styled from 'styled-components';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { useTranslation } from 'react-i18next';
import { Scan } from '../../../common/model/dto/scan';
import OvListTableField from '../atoms/OvListTableField';
import OvDateParser from '../atoms/OvDateParser';
import { Constants } from '../../../common/constants';
import { DailyDataUtils } from '../../../common/utils/services/daily-data-utils';
import { isNumber } from 'lodash';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvNoContent from '../molecules/OvNoContent';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvPrettyUserName from '../atoms/OvPrettyUserName';
import {
  scanErrorDisplay,
  scanWarningDisplay,
  scanWarningsDisplay,
} from '../../../common/utils/services/scan-data-utils';
import useAuthorized from '../../../hooks/use-authorized';
import { Resource } from '../../../common/model/type/resource.enum';
import { differenceInSeconds } from 'date-fns';

const OvAdminScanList: FC<{
  scans: Scan[];
  isLoading?: boolean;
  deleteScanCallback?: (scanId: string) => void;
}> = ({ scans, isLoading, deleteScanCallback }) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const scanOperations = useAuthorized(Resource.Scan);
  const userOperations = useAuthorized(Resource.User);

  const [isOpenDeleteScanDialog, setOpenDeleteScanDialog] =
    useState<boolean>(false);
  const [deletableScanId, setDeletableScanId] = useState<string>();

  const headerNames = [
    'common.scan',
    'common.createdBy',
    'common.dailyData.results',
    'common.dailyData.toolkit',
  ];

  const openDeleteScanDialog = (id?: string) => {
    setDeletableScanId(id);
    setOpenDeleteScanDialog(true);
  };

  const onDelete = () => {
    if (deletableScanId && deleteScanCallback) {
      setOpenDeleteScanDialog(false);
      deleteScanCallback(deletableScanId);
    }
  };

  const userReadAllowed =
    userOperations.read || applicationOperations.supervise;
  const scanDeleteAllowed =
    scanOperations.delete || applicationOperations.supervise;

  return (
    <WrapperBox>
      {scans?.length ? (
        <OvListTable>
          <OvListTableRow>
            {headerNames.map((headerName) => (
              <OvListTableHeaderField key={headerName}>
                {t(headerName)}
              </OvListTableHeaderField>
            ))}
          </OvListTableRow>
          {scans.map((scan, index) => (
            <OvListTableRow key={index}>
              <OvListTableField>
                <img alt="" src={scan.output_image_url}></img>
              </OvListTableField>

              {userReadAllowed && (
                <OvListTableField>
                  {scan.user ? (
                    <StyledNavlink to={`/users/${scan.user.id}`}>
                      <OvPrettyUserName user={scan.user} />
                    </StyledNavlink>
                  ) : (
                    <StyledNavlink to={`/users/${scan.user_id}`}>
                      {t('common.actions.viewProfile')}
                    </StyledNavlink>
                  )}
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <i>
                      <OvDateParser
                        date={scan?.created_at as Date}
                        format={'MM/DD/YYYY - hh:mm A'}
                      />
                    </i>
                  </div>
                  {scan &&
                    scan.created_at &&
                    scan.updated_at &&
                    Math.abs(
                      differenceInSeconds(
                        new Date(scan.created_at),
                        new Date(scan.updated_at)
                      )
                    ) > 5 && (
                      <>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <u>{t('common.updatedAt')}:</u>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          <i>
                            <OvDateParser
                              date={scan?.updated_at as Date}
                              format={'MM/DD/YYYY - hh:mm A'}
                            />
                          </i>
                        </div>
                      </>
                    )}
                </OvListTableField>
              )}

              {!userReadAllowed && (
                <OvListTableField>
                  {scan.user ? (
                    <OvPrettyUserName
                      user={scan.user}
                      options={{ readSafe: true }}
                    />
                  ) : null}
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <i>
                      <OvDateParser
                        date={scan?.created_at as Date}
                        format={'MM/DD/YYYY - hh:mm A'}
                      />
                    </i>
                  </div>
                </OvListTableField>
              )}

              <OvListTableField>
                {isNumber(scan?.lh) && (
                  <>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <b>LH</b>:{' '}
                      {DailyDataUtils.prettifyValue(
                        scan?.lh,
                        Constants.LH_MAX,
                        Constants.LH_UNIT_OF_MEASURE
                      )}
                    </div>
                  </>
                )}
                {isNumber(scan?.pg) && (
                  <>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <b>PG</b>:{' '}
                      {DailyDataUtils.prettifyValue(
                        scan?.pg,
                        Constants.PG_MAX,
                        Constants.PG_UNIT_OF_MEASURE
                      )}
                    </div>
                  </>
                )}
                {isNumber(scan?.lr_e3g) && (
                  <>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <b>E3G</b>:{' '}
                      {DailyDataUtils.prettifyValue(
                        scan?.lr_e3g,
                        Constants.LR_E3G_MAX,
                        Constants.E3G_UNIT_OF_MEASURE
                      )}
                    </div>
                  </>
                )}

                {(scan?.toolkit_output?.version ?? '') < '1.3.0' &&
                scan?.toolkit_output?.warning ? (
                  <>
                    <b>Warning / Error</b>:<br />
                    {scanWarningDisplay(scan?.toolkit_output?.warning)}
                    <br></br>
                  </>
                ) : (
                  ''
                )}

                {(scan?.toolkit_output?.version ?? '') >= '1.3.0' &&
                (scan?.toolkit_output?.warnings ?? []).length > 0 ? (
                  <>
                    <b>Warnings</b>:<br />
                    {scanWarningsDisplay(scan?.toolkit_output?.warnings)}
                    <br></br>
                  </>
                ) : (
                  ''
                )}

                {(scan?.toolkit_output?.version ?? '') >= '1.3.0' &&
                  (scan?.toolkit_output?.error ? (
                    <>
                      <b>Error</b>:<br />
                      {scanErrorDisplay(scan?.toolkit_output?.error)}
                      <br></br>
                    </>
                  ) : (
                    ''
                  ))}
              </OvListTableField>

              <OvListTableField>
                {scan?.toolkit_output?.version}
              </OvListTableField>

              <OvListTableField>
                {scanDeleteAllowed && (
                  <Tooltip title={t('common.dailyData.deleteScan') || ''}>
                    <StyledIconButton
                      disabled={scan.is_daily_log_record}
                      onClick={() => openDeleteScanDialog(scan.id)}
                      className="actionIcon"
                    >
                      <DeleteIcon />
                    </StyledIconButton>
                  </Tooltip>
                )}
              </OvListTableField>

              <OvListTableField>
                <StyledOvButton>
                  <Link to={`${url}/${scan.id}`}>
                    {t('common.actions.details')}
                  </Link>
                </StyledOvButton>
              </OvListTableField>
            </OvListTableRow>
          ))}
        </OvListTable>
      ) : (
        <OvNoContent>{t('common.empty')}</OvNoContent>
      )}

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteScanDialog}
        onCancel={() => setOpenDeleteScanDialog(false)}
        onConfirm={onDelete}
        description="common.dailyData.permanentScanDelete"
        title="common.areYouSure"
      />

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </WrapperBox>
  );
};

export default OvAdminScanList;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};
    white-space: nowrap;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledNavlink = styled(NavLink)`
  && {
    color: ${Colours.OV_BASE};

    &:visited {
      color: ${Colours.OV_BASE};
    }
  }
`;
