import axios from 'axios';
import { Paginated } from '../common/types';
import { API_PATH_PREFIX, REPORTS_ROUTE } from '../api/backend';
import { Report } from '../common/model/dto/report';
import {
  OvTableData,
  OvTableRowData,
} from '../components/UI/molecules/OvTable';
import DateUtils from '../common/utils/services/date-utils';
import Colours from '../design-system/colours';
import i18n from 'i18next';
import { CreateReportRequest } from '../common/model/dto/report/create-report-request';

export class ReportService {
  static async findReportsByUserDocId(userDocumentId: string) {
    const { data } = await axios.get<Paginated<Report>>(
      `${REPORTS_ROUTE}?user_document_id=${userDocumentId}`
    );

    return data;
  }

  static async createReport(createReportRequest: CreateReportRequest) {
    const { data } = await axios.post<Report>(
      `${REPORTS_ROUTE}`,
      createReportRequest
    );
    return data;
  }

  static async updateReport(reportId: string, updateObj: any) {
    const { data } = await axios.patch<Report>(
      `${REPORTS_ROUTE}/${reportId}`,
      updateObj
    );

    return data;
  }

  static async deleteReportById(reportId: string) {
    return await axios.delete<Paginated<boolean>>(
      `${REPORTS_ROUTE}/${reportId}`
    );
  }

  static async generatePdf(reportId: string) {
    const { data } = await axios.patch<Report>(
      `/pdf/reports/${reportId}`,
      {},
      {
        baseURL: `${process.env.REACT_APP_FILE_SERVICE_URL}${API_PATH_PREFIX}`,
      }
    );

    return data;
  }

  static mapReportsToTableData(
    reportList: Report[],
    showAdminDetails?: boolean
  ): OvTableData {
    const tableData: OvTableData = [];

    reportList.forEach((report, index) => {
      const nestedRows: OvTableRowData[] = [
        {
          label: 'common.reports.pdfUrl',
          value: report?.pdf_url ? i18n.t('common.actions.viewDoc') : '',
          translateLabel: true,
          linkUrl: report?.pdf_url,
        },
      ];

      if (showAdminDetails) {
        nestedRows.unshift({
          label: 'common.reports.reportId',
          value: report?.document_id,
          translateLabel: true,
        });

        nestedRows.push(
          {
            label: 'common.reports.seen',
            value: report?.seen ? 'common.true' : 'common.false',
            translateLabel: true,
            translateValue: true,
          },
          {
            label: 'common.reports.errors',
            value: report?.errors,
            translateLabel: true,
          }
        );
      }

      tableData.push({
        rowId: report.document_id,
        label: DateUtils.getDateStrFromString(
          report.created_at_tag,
          'YYYY_MM_DD',
          'dddd, MMMM DD, YYYY'
        ),
        value: report.title,
        secondaryValue: `${i18n.t('common.createdBy')}: ${
          report.created_by.first_name
        } ${report.created_by.last_name}`,
        style: {
          backgroundColor: Colours.OV_LIGHT_GRAY,
        },
        showNestedRows: index === 0,
        nestedRows,
      });
    });

    return tableData;
  }
}
