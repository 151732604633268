import { Resource } from '../common/model/type/resource.enum';
import { Entitlement } from '../common/model/dto/entitlement';
import { useAppSelector } from '../redux/hooks';
import { AllowedOperations } from '../common/model/dto/allowed-operations';
import { Operation } from '../common/model/type/operation.enum';
import { Role } from '../common/model/type/role.enum';
import { UserInfo } from '../common/model/dto/user-info';
import {
  hasManagerRole,
  hasViewerRole,
  managerRolePostfix,
  viewerRolePostfix,
} from '../common/utils/auth.helpers';

export default function useAuthorized(
  resource: Resource,
  clinicLocationId?: string,
  selectedClinicRole?: string
): AllowedOperations {
  const entitlements: Entitlement[] = useAppSelector(
    (state) => state.auth.entitlements
  );
  const currentUser: UserInfo | undefined = useAppSelector(
    (state) => state.user.currentUser
  );

  let filteredEntitlements = clinicLocationId
    ? entitlements?.filter(
        (ent) =>
          ent.resource === resource &&
          (clinicLocationId
            ? ent.clinic_location_id === clinicLocationId
            : true)
      )
    : entitlements?.filter((ent) => ent.resource === resource);

  if (selectedClinicRole && selectedClinicRole === 'Patient') {
    return {};
  }

  filteredEntitlements = filteredEntitlements?.filter(
    (ent) => ent.user_role !== Role.Regular
  );

  if (currentUser?.selected_role !== Role.Admin) {
    filteredEntitlements = filteredEntitlements?.filter(
      (ent) => ent.resource !== Resource.Application
    );
  }

  if (hasManagerRole([currentUser?.selected_role ?? ''])) {
    filteredEntitlements = filteredEntitlements?.filter((ent) =>
      ent.user_role?.endsWith(managerRolePostfix)
    );
  }

  if (hasViewerRole([currentUser?.selected_role ?? ''])) {
    filteredEntitlements = filteredEntitlements?.filter((ent) =>
      ent.user_role?.endsWith(viewerRolePostfix)
    );
  }

  return {
    supervise: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Supervise
    ),
    create: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Create
    ),
    delete: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Delete
    ),
    update: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Update
    ),
    read: filteredEntitlements?.some((ent) => ent.operation === Operation.Read),
  };
}
