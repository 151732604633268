import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { parseUrl, stringify } from 'query-string';
import { ClinicLocation } from '../../../common/model/dto/clinic-location';
import { Paginated, SortingOption } from '../../../common/types';
import { AdminClinicLocationService } from '../../../services/admin/admin-clinic-location.service';
import { RootState } from '../../store';
import { History } from 'history';
import {
  restorePatientSearchModel,
  restoreProviderSearchModel,
  restoreSearchModel,
  setClinicLocationListPaging,
  setClinicLocationListStoredQuery,
  setUserListPaging,
  setUserListStoredQuery,
} from '../../reducers/admin-clinic-location.slice';
import { ClinicLocationService } from '../../../services/clinic-location.service';
import { UserInfo } from '../../../common/model/dto/user-info';
import {
  shouldFilterPatientList,
  shouldFilterProviderList,
} from '../../selectors/admin-clinic-location.selectors';
import { AssignPatientToClinicLocationRequest } from '../../../common/model/dto/assign-user-to-clinic-location-request';
import { AssignProviderToClinicLocationRequest } from '../../../common/model/dto/assign-provider-to-clinic-location-request';
import { ClinicRole } from '../../../common/model/type/clinic-role';
import { CreateClinicLocationRequest } from '../../../common/model/dto/create-clinic-location-request';
import { AdminClinicService } from '../../../services/admin/admin-clinic.service';
import UserService from '../../../services/user.service';
import { CreateDemoAccountRequest } from '../../../common/model/dto/create-demo-account-request';
import { getEntitlements } from '../auth.thunk';
import { userInfo } from '../user.thunk';
import { ClinicDetailsTabType } from '../../../common/model/type/clinic-details-tab.type';

const loadClinicLocations = createAsyncThunk<
  ClinicLocation[],
  { history: History; clinicId: string | undefined },
  { state: RootState }
>(
  'clinicLocations/loadClinicLocations',
  async ({ history, clinicId }, { dispatch, getState }) => {
    try {
      let paginatedClinicLocations: Paginated<ClinicLocation>,
        query = '';

      const { offset } =
          getState().adminClinicLocation.clinicLocationListPaging,
        sorting = getClinicLocationQuerySorting(getState());

      query = stringify({
        limit: AdminClinicLocationService.CLINIC_LOCATION_LIST_LIMIT,
        clinic_id: clinicId,
        order_by: sorting?.orderBy,
        order_dir: sorting?.orderDir,
        offset,
      });

      paginatedClinicLocations =
        await AdminClinicLocationService.loadClinicLocationsByQuery(query);
      dispatch(setClinicLocationListStoredQuery(offset > 0 ? query : ''));
      history.push(
        `/clinics/${clinicId}/${ClinicDetailsTabType.CLINIC_INFO}?${
          offset > 0 ? query : ''
        }`
      );

      setPaging({ ...paginatedClinicLocations, offset }, dispatch);

      return paginatedClinicLocations.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const restoreClinicLocationSearchFromUrl = createAsyncThunk<
  ClinicLocation[],
  string,
  { state: RootState }
>(
  'clinicLocations/loadClinicLocations',
  async (query, { dispatch, getState }) => {
    try {
      let paginatedClinicLocations: Paginated<ClinicLocation>;

      dispatch(setClinicLocationListStoredQuery(query));
      dispatch(restoreSearchModel(parseUrl(query).query));
      paginatedClinicLocations =
        await AdminClinicLocationService.loadClinicLocationsByQuery(
          query.slice(1)
        );
      setPaging(
        {
          ...paginatedClinicLocations,
          offset:
            getState().adminClinicLocation.clinicLocationListPaging.offset,
        },
        dispatch
      );

      return paginatedClinicLocations.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getClinicLocationById = createAsyncThunk(
  'clinicLocation/getClinicLocationById',
  async (clinicLocationId: string, { rejectWithValue }) => {
    try {
      return await ClinicLocationService.getClinicLocationById(
        clinicLocationId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createDemoClinicLocation = createAsyncThunk(
  'clinicLocation/createDemoClinicLocation',
  async (
    {
      createDemoAccountRequest,
      history,
    }: {
      createDemoAccountRequest: CreateDemoAccountRequest;
      history: History;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const demoData =
        await AdminClinicLocationService.createDemoClinicLocation(
          createDemoAccountRequest
        );

      //Refresh entitlements and user info
      dispatch(getEntitlements());
      dispatch(userInfo());

      history.push(
        `/clinics/${demoData.clinicLocation.clinic.id}/clinic-Locations/${demoData.clinicLocation.id}`
      );

      return demoData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createDemoAccount = createAsyncThunk(
  'user/createDemoAccount',
  async (
    {
      createDemoAccountRequest,
      clinicLocationId,
      history,
      clinicId,
    }: {
      createDemoAccountRequest: CreateDemoAccountRequest;
      clinicLocationId?: string;
      history: History;
      clinicId?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const demoData = await AdminClinicLocationService.createDemoAccount(
        createDemoAccountRequest,
        clinicLocationId
      );

      dispatch(
        loadClinicLocationPatients({ history, clinicLocationId, clinicId })
      );

      return demoData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getSetPasswordToken = createAsyncThunk(
  'user/getSetPasswordToken',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await UserService.getSetPasswordToken(userId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateClinicLocation = createAsyncThunk(
  'clinicLocation/updateClinicLocation',
  async (
    updateObj: {
      clinicLocationId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const updatedClinicLocation =
        await AdminClinicLocationService.updateClinicLocation(
          updateObj.clinicLocationId,
          updateObj.updatedProperties
        );

      return updatedClinicLocation;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const loadClinicLocationProviders = createAsyncThunk<
  UserInfo[],
  {
    history: History;
    clinicLocationId: string | undefined;
    clinicId: string | undefined;
  },
  { state: RootState }
>(
  'clinicLocation/loadProviders',
  async ({ history, clinicLocationId, clinicId }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>,
        query = '';
      const { offset } = getState().adminClinicLocation.userListPaging,
        sorting = getUserListQuerySorting(getState()),
        filters = getProviderListQueryFilters(getState());

      if (shouldFilterProviderList(getState())) {
        query = stringify({
          limit: AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
          order_by: sorting?.orderBy,
          order_dir: sorting?.orderDir,
          offset,
          ...AdminClinicLocationService.getQueryParams(filters),
        });

        paginatedUsers =
          await AdminClinicLocationService.getClinicLocationProvidersByQuery(
            query,
            clinicLocationId
          );
        dispatch(setUserListStoredQuery(query));
        history.push(
          `/clinics/${clinicId}/${ClinicDetailsTabType.CLINIC_INFO}/${clinicLocationId}/providers?${query}`
        );
      } else {
        const query = stringify({
          limit: AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
          order_by: sorting?.orderBy,
          order_dir: sorting?.orderDir,
          offset,
        });

        paginatedUsers =
          await AdminClinicLocationService.getClinicLocationProvidersByQuery(
            query,
            clinicLocationId
          );
        dispatch(setUserListStoredQuery(offset > 0 ? query : ''));
        history.push(
          `/clinics/${clinicId}/${
            ClinicDetailsTabType.CLINIC_INFO
          }/${clinicLocationId}/providers${offset > 0 ? '?' + query : ''}`
        );
      }

      setPaginatedUserList({ ...paginatedUsers, offset }, dispatch);

      return paginatedUsers.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const loadClinicLocationPatients = createAsyncThunk<
  UserInfo[],
  {
    history: History;
    clinicLocationId: string | undefined;
    clinicId: string | undefined;
  },
  { state: RootState }
>(
  'clinicLocation/loadPatients',
  async ({ history, clinicLocationId, clinicId }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>,
        query = '';
      const { offset } = getState().adminClinicLocation.userListPaging,
        sorting = getUserListQuerySorting(getState()),
        filters = getPatientListQueryFilters(getState());

      if (shouldFilterPatientList(getState())) {
        query = stringify({
          limit: AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
          order_by: sorting?.orderBy,
          order_dir: sorting?.orderDir,
          offset,
          ...AdminClinicLocationService.getQueryParams(filters),
        });

        paginatedUsers =
          await AdminClinicLocationService.getClinicLocationPatients(
            query,
            clinicLocationId
          );
        dispatch(setUserListStoredQuery(query));
        history.push(
          `/clinics/${clinicId}/${ClinicDetailsTabType.CLINIC_INFO}/${clinicLocationId}/patients?${query}`
        );
      } else {
        const query = stringify({
          limit: AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
          order_by: sorting?.orderBy,
          order_dir: sorting?.orderDir,
          offset,
        });

        paginatedUsers =
          await AdminClinicLocationService.getClinicLocationPatients(
            query,
            clinicLocationId
          );
        dispatch(setUserListStoredQuery(offset > 0 ? query : ''));
        history.push(
          `/clinics/${clinicId}/${
            ClinicDetailsTabType.CLINIC_INFO
          }/${clinicLocationId}/patients${offset > 0 ? '?' + query : ''}`
        );
      }

      setPaginatedUserList({ ...paginatedUsers, offset }, dispatch);

      return paginatedUsers.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const restorePatientSearchFromUrl = createAsyncThunk<
  UserInfo[],
  { clinicLocationId?: string; query: string },
  { state: RootState }
>(
  'clinicLocation/loadPatients',
  async ({ clinicLocationId, query }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>;

      dispatch(setUserListStoredQuery(query));
      dispatch(restorePatientSearchModel(parseUrl(query).query));
      paginatedUsers =
        await AdminClinicLocationService.getClinicLocationPatients(
          query.slice(1),
          clinicLocationId
        );
      setPaginatedUserList(
        {
          ...paginatedUsers,
          offset: getState().adminClinicLocation.userListPaging.offset,
        },
        dispatch
      );

      return paginatedUsers.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const restoreProviderSearchFromUrl = createAsyncThunk<
  UserInfo[],
  { clinicLocationId?: string; query: string },
  { state: RootState }
>(
  'clinicLocation/loadProviders',
  async ({ clinicLocationId, query }, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>;

      dispatch(setUserListStoredQuery(query));
      dispatch(restoreProviderSearchModel(parseUrl(query).query));
      paginatedUsers =
        await AdminClinicLocationService.getClinicLocationProvidersByQuery(
          query.slice(1),
          clinicLocationId
        );
      setPaginatedUserList(
        {
          ...paginatedUsers,
          offset: getState().adminClinicLocation.userListPaging.offset,
        },
        dispatch
      );

      return paginatedUsers.docs;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const createClinicLocation = createAsyncThunk(
  'clinicLocation/createClinicLocation',
  async (
    {
      clinicId,
      request,
    }: { clinicId: string; request: CreateClinicLocationRequest },
    { rejectWithValue }
  ) => {
    try {
      return await AdminClinicService.createClinicLocation(clinicId, request);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const assignPatientToClinicLocation = createAsyncThunk(
  'clinicLocation/assignPatientToClinicLocation',
  async (
    {
      clinicLocationId,
      request,
    }: {
      clinicLocationId: string;
      request: AssignPatientToClinicLocationRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      return await ClinicLocationService.assignPatientToClinicLocation(
        clinicLocationId,
        request
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const approveProviderInvitationForUser = createAsyncThunk(
  'clinicLocation/approveProviderInvitationForUser',
  async (
    {
      history,
      clinicLocationId,
      clinicId,
      userId,
    }: {
      history: History;
      clinicLocationId: string;
      clinicId: string;
      userId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await AdminClinicLocationService.approveProviderInvitationForUser(
        clinicLocationId,
        userId
      );

      dispatch(
        loadClinicLocationProviders({ history, clinicLocationId, clinicId })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const approvePatientInvitationForUser = createAsyncThunk(
  'clinicLocation/approvePatientInvitationForUser',
  async (
    {
      history,
      clinicLocationId,
      clinicId,
      userId,
    }: {
      history: History;
      clinicLocationId: string;
      clinicId: string;
      userId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await AdminClinicLocationService.approvePatientInvitationForUser(
        clinicLocationId,
        userId
      );

      dispatch(
        loadClinicLocationPatients({ history, clinicLocationId, clinicId })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const assignProviderToClinicLocation = createAsyncThunk(
  'clinicLocation/assignProviderToClinicLocation',
  async (
    {
      history,
      clinicLocationId,
      clinicId,
      request,
    }: {
      history: History;
      clinicLocationId: string;
      clinicId: string;
      request: AssignProviderToClinicLocationRequest;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      return await AdminClinicLocationService.assignProviderToClinicLocation(
        clinicLocationId,
        request
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const removePatientFromClinicLocation = createAsyncThunk(
  'clinicLocation/removePatientFromClinicLocation',
  async (
    {
      history,
      clinicLocationId,
      clinicId,
      userId,
    }: {
      history: History;
      clinicLocationId: string;
      clinicId: string;
      userId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await ClinicLocationService.removePatientFromClinicLocation(
        clinicLocationId,
        userId
      );

      dispatch(
        loadClinicLocationPatients({ history, clinicLocationId, clinicId })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const removeProviderFromClinicLocation = createAsyncThunk(
  'clinicLocation/removeProviderFromClinicLocation',
  async (
    {
      history,
      clinicLocationId,
      clinicId,
      userId,
      clinicRole,
    }: {
      history: History;
      clinicLocationId: string;
      clinicId: string;
      userId: string;
      clinicRole: ClinicRole;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await AdminClinicLocationService.removeProviderFromClinicLocation(
        clinicLocationId,
        userId,
        clinicRole
      );

      dispatch(
        loadClinicLocationProviders({ history, clinicLocationId, clinicId })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteClinicLocation = createAsyncThunk(
  'clinicLocation/deleteClinicLocation',
  async (
    {
      history,
      clinicLocationId,
      clinicId,
    }: { history: History; clinicLocationId: string; clinicId: string },
    { rejectWithValue }
  ) => {
    try {
      await AdminClinicLocationService.deleteClinicLocation(clinicLocationId);

      history.push(`/clinics/${clinicId}/${ClinicDetailsTabType.CLINIC_INFO}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const loadAllFertilityDisorders = createAsyncThunk(
  'clinicLocation/fertilityDisorders',
  async () => {
    const disorders = await UserService.loadAllFertilityDisorders();

    return disorders.map((disorder) => disorder.known_reproductive_disorder);
  }
);

const getApproveInvitationToken = createAsyncThunk(
  'clinicLocation/getApproveInvitationToken',
  async (clinicLocationProviderId?: string | undefined) => {
    return await AdminClinicLocationService.getApproveInvitationToken(
      clinicLocationProviderId
    );
  }
);

const refreshSetPasswordToken = createAsyncThunk(
  'clinicLocation/refreshSetPasswordToken',
  async (userId: string) => {
    return await UserService.refreshSetPasswordToken(userId);
  }
);

const refreshApproveInvitationToken = createAsyncThunk(
  'clinicLocation/refreshApproveInvitationToken',
  async (clinicLocationProviderId: string) => {
    return await AdminClinicLocationService.refreshApproveInvitationToken(
      clinicLocationProviderId
    );
  }
);

const loadClinicLocationProvidersFilters = createAsyncThunk(
  'clinicLocation/providersFilter',
  async (clinicLocationId: string, { rejectWithValue }) => {
    try {
      const providers = await ClinicLocationService.loadProviderFilterData(
        clinicLocationId
      );

      return providers;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function getClinicLocationQuerySorting(state: RootState) {
  const sortingOptions: SortingOption[] =
    state?.adminClinicLocation?.sortingOptions;

  const sortingOption = sortingOptions
    .filter((sortingOption) => sortingOption)
    .find((sortingOption) => ['asc', 'desc'].includes(sortingOption.direction));

  if (sortingOption) {
    return {
      orderBy: sortingOption.value,
      orderDir: sortingOption.direction,
    };
  }
}

function setPaging(
  paginatedClinics: Paginated<ClinicLocation>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setClinicLocationListPaging({
      offset: paginatedClinics.offset || 0,
      total: paginatedClinics.total,
    })
  );
}

function setPaginatedUserList(
  paginatedUserList: Paginated<UserInfo>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setUserListPaging({
      offset: paginatedUserList.offset || 0,
      total: paginatedUserList.total,
    })
  );
}

function getUserListQuerySorting(state: RootState) {
  const sortingOptions: SortingOption[] =
    state?.adminClinicLocation?.userListSortingOptions;

  const sortingOption = sortingOptions
    .filter((sortingOption) => sortingOption)
    .find((sortingOption) => ['asc', 'desc'].includes(sortingOption.direction));

  if (sortingOption) {
    return {
      orderBy: sortingOption.value,
      orderDir: sortingOption.direction,
    };
  }
}

function getProviderListQueryFilters(state: RootState): any[] {
  const filters = state?.adminClinicLocation?.providerListFilters
    ? state?.adminClinicLocation?.providerListFilters
        .filter(
          (filter: any) => filter.value !== undefined && filter.value !== ''
        )
        .map((filter: any) => ({
          field: filter.fieldName,
          value: filter.value,
        }))
    : [];

  return filters;
}

function getPatientListQueryFilters(state: RootState): any[] {
  const filters = state?.adminClinicLocation?.patientListFilters
    ? state?.adminClinicLocation?.patientListFilters
        .filter(
          (filter: any) => filter.value !== undefined && filter.value !== ''
        )
        .map((filter: any) => ({
          field: filter.fieldName,
          value: filter.value,
        }))
    : [];

  return filters;
}

export {
  loadClinicLocations,
  restoreClinicLocationSearchFromUrl,
  updateClinicLocation,
  getClinicLocationById,
  loadClinicLocationProviders,
  restoreProviderSearchFromUrl,
  loadClinicLocationPatients,
  restorePatientSearchFromUrl,
  assignPatientToClinicLocation,
  assignProviderToClinicLocation,
  removePatientFromClinicLocation,
  removeProviderFromClinicLocation,
  createClinicLocation,
  deleteClinicLocation,
  loadAllFertilityDisorders,
  loadClinicLocationProvidersFilters,
  approveProviderInvitationForUser,
  approvePatientInvitationForUser,
  getSetPasswordToken,
  getApproveInvitationToken,
  refreshSetPasswordToken,
  refreshApproveInvitationToken,
  createDemoClinicLocation,
  createDemoAccount,
};
