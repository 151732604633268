import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportState } from '../states/report.state';
import {
  createReport,
  deleteReportById,
  findReportsByUserDocId,
  updateReport,
} from '../thunks/report.thunk';
import { Report } from '../../common/model/dto/report';

const initialState: ReportState = {
  loading: false,
  error: '',
  reportList: [],
};

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearSelectedUsersReports: (state) => {
      state.reportList = [];
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(findReportsByUserDocId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        findReportsByUserDocId.fulfilled,
        (state, action: PayloadAction<Report[]>) => {
          state.loading = false;
          state.error = '';
          state.reportList = action.payload;
        }
      )
      .addCase(findReportsByUserDocId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteReportById.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        deleteReportById.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = '';
          state.reportList = state.reportList.filter(
            (report) => report.document_id !== action.payload
          );
        }
      )
      .addCase(deleteReportById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createReport.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        createReport.fulfilled,
        (state, action: PayloadAction<Report>) => {
          state.loading = false;
          state.error = '';
          state.reportList.unshift(action.payload);
        }
      )
      .addCase(
        createReport.rejected,
        (state, action: PayloadAction<{ response: any }>) => {
          state.loading = false;
          state.error = action.payload.response.data.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateReport.fulfilled,
        (
          state,
          action: PayloadAction<{
            id: string;
            updatedProperties: any;
          }>
        ) => {
          state.reportList = state.reportList.map((report) => {
            if (report.document_id === action.payload?.id) {
              return {
                ...report,
                ...action.payload?.updatedProperties,
              };
            }

            return report;
          });

          state.loading = false;
        }
      )
      .addCase(updateReport.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { clearSelectedUsersReports } = reportSlice.actions;
export default reportSlice.reducer;
