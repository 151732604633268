import React, { FC } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import { OrderBy, SortDirection } from '../../../common/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import Colours from '../../../design-system/colours';

export interface OvListTableHeaderFieldProps {
  style?: any;
  isSortable?: boolean;
  sortDirection?: SortDirection;
  sortBy?: string;
  onSort?: (order: OrderBy) => void;
  isActiveSort?: boolean;
  children?: React.ReactNode;
}

const OvListTableHeaderField: FC<OvListTableHeaderFieldProps> = ({
  style,
  isSortable = true,
  sortDirection = 'none',
  sortBy,
  onSort,
  isActiveSort = false,
  children,
  ...props
}) => {
  const handleSort = () => {
    if (isSortable && sortBy && onSort) {
      const order_dir = sortDirection === 'asc' ? 'desc' : 'asc';
      onSort({ order_by: sortBy, order_dir });
    }
  };

  return isSortable ? (
    <StyledTableField
      as="th"
      style={style}
      {...props}
      onClick={handleSort}
      isActiveSort={isActiveSort}
    >
      <HeaderContent>
        {children}
        {isActiveSort && sortDirection !== 'none' && (
          <StyledIconButton>
            {sortDirection === 'asc' ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </StyledIconButton>
        )}
      </HeaderContent>
    </StyledTableField>
  ) : (
    <NonSortableLabel as="th" style={style} {...props}>
      {children}
    </NonSortableLabel>
  );
};

export default OvListTableHeaderField;

// Styled Components
const StyledTableField = styled.th<{ isActiveSort: boolean }>`
  font-weight: 500;
  font-size: ${Variables.fontSizes.MEDIUM};
  line-height: 1rem;
  letter-spacing: 0.13px;
  padding: 0 1rem 0.375rem;
  vertical-align: middle;
  opacity: 0.7;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;

  &:hover {
    opacity: 1;
  }

  ${({ isActiveSort }) =>
    isActiveSort &&
    `
    color: ${Colours.OV_BASE_HOVER};
  `}
`;

const HeaderContent = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  && {
    height: 1rem;
    width: 1rem;
    padding: 0.25rem;
    margin-left: 0.25rem;

    .MuiSvgIcon-root {
      fill: ${Colours.OV_BASE};
    }

    &:hover {
      background: none;

      .MuiSvgIcon-root {
        fill: ${Colours.OV_BASE_HOVER};
      }
    }

    .MuiTouchRipple-root {
      display: none !important;
    }
  }
`;

const NonSortableLabel = styled.th`
  font-weight: 500;
  font-size: ${Variables.fontSizes.MEDIUM};
  line-height: 1rem;
  letter-spacing: 0.13px;
  padding: 0 1rem 0.375rem;
  vertical-align: middle;
  opacity: 0.7;
  white-space: nowrap;
  text-align: left;
  cursor: default; // No pointer cursor for non-sortable labels
`;
