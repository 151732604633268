import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import Colours from '../../../design-system/colours';
import { useAppDispatch } from '../../../redux/hooks';
import { History } from 'history';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import OvListTableField from '../atoms/OvListTableField';
import StringUtils from '../../../common/utils/services/string-utils';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import { IconButton, Tooltip } from '@mui/material';
import { ApprovementStatus } from '../../../common/model/dto/approvement-status.enum';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OvProviderAdminActionsDialog from '../molecules/OvProviderAdminActionsDialog';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {
  assignPatientToProvider,
  revokePatientProviderAssignment,
} from '../../../redux/thunks/clinic-location.thunk';
import DateUtils from '../../../common/utils/services/date-utils';
import { Resource } from '../../../common/model/type/resource.enum';
import useAuthorized from '../../../hooks/use-authorized';

interface OvClinicLocationUserListRowProps {
  user: UserInfo;
  seeDetails?: boolean;
  seeDetailsCtaLabel?: string;
  deleteAction?: any;
  deleteActionCtaLabel?: string;
  clinicLocationId?: string;
  clinicId?: string;
  showClinicRole?: boolean;
  showClinicLocationProviderCreatedAt?: boolean;
  showClinicLocationPatientCreatedAt?: boolean;
  isPatientSharingOn?: boolean;
  approveInvitationAction?: any;
  approveInvitationCtaLabel?: string;
  currentUser?: UserInfo;
  editable?: boolean;
  deletable?: boolean;
}

const OvClinicLocationUserListRow: FC<OvClinicLocationUserListRowProps> = ({
  user,
  seeDetails,
  seeDetailsCtaLabel,
  deleteAction,
  deleteActionCtaLabel,
  clinicLocationId,
  clinicId,
  showClinicRole,
  showClinicLocationProviderCreatedAt,
  showClinicLocationPatientCreatedAt,
  isPatientSharingOn,
  approveInvitationAction,
  approveInvitationCtaLabel,
  currentUser,
  editable,
  deletable,
}) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const history: History = useHistory();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const userOperations = useAuthorized(Resource.User);

  const userReadAllowed =
    userOperations.read || applicationOperations.supervise;

  const ACCOUNT_DEACTIVATED = '[ACCOUNT DEACTIVATED]';
  const [isOpenDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [isOpenApproveInvitationDialog, setIsOpenApproveInvitationDialog] =
    useState<boolean>(false);
  const [isProviderActionsDialogOpen, setIsProviderActionsDialogOpen] =
    useState<boolean>(false);

  const [userClinicLocations] = useState(
    showClinicRole
      ? user.provider_clinic_locations
      : user.patient_clinic_locations
  );

  const clinicLocationProvider = user.provider_clinic_locations?.find(
    (cl) => cl.clinic_location.id === clinicLocationId
  );

  const clinicLocationPatient = user.patient_clinic_locations?.find(
    (cl) => cl.clinic_location.id === clinicLocationId
  );

  const isStatusPending = () => {
    if (isPatientSharingOn) {
      return userClinicLocations
        ?.filter(
          (userClinicLocation) =>
            userClinicLocation.clinic_location?.clinic?.id === clinicId
        )
        .some((patient) => patient?.status === ApprovementStatus.PENDING);
    } else {
      return (
        userClinicLocations?.find(
          (userClinicLocation) =>
            userClinicLocation.clinic_location.id === clinicLocationId
        )?.status === ApprovementStatus.PENDING
      );
    }
  };

  const getStatus = (): string => {
    if (!user) {
      return '-';
    }

    if (!user?.document_id) {
      return 'common.userFields.status.inOnBoarding';
    }

    if (user.email === ACCOUNT_DEACTIVATED) {
      return 'common.userFields.status.deactivated';
    }

    if (isStatusPending()) {
      return 'common.userFields.status.waitingForApproval';
    }

    return 'common.userFields.status.active';
  };

  const isUserAssignedToProvider = () => {
    if (user && currentUser) {
      return user?.providers?.some(
        (provider) =>
          provider?.provider?.id === currentUser?.id &&
          provider?.clinic_location?.id === clinicLocationId
      );
    }
  };

  const fieldOpacity =
    getStatus() === 'common.userFields.status.inOnBoarding' ||
    getStatus() === 'common.userFields.status.waitingForApproval'
      ? 0.3
      : 1;

  const getColor = () => {
    const currentClinicLocationExpiryDate =
      user?.provider_clinic_locations?.find(
        (providerClinicLocation) =>
          providerClinicLocation.clinic_location.id === clinicLocationId
      )?.approve_invitation_token_expiration_date;

    if (
      (showClinicRole &&
        user.set_password_token_expiry_date &&
        new Date(user.set_password_token_expiry_date) < new Date()) ||
      (showClinicRole &&
        currentClinicLocationExpiryDate &&
        new Date(currentClinicLocationExpiryDate) < new Date())
    ) {
      return Colours.OV_RED;
    }

    if (user.is_demo_account) {
      return Colours.OV_ORANGE;
    }

    return Colours.OV_BASE;
  };

  const clinicRole = user?.provider_clinic_locations?.find(
    (providerClinicLocation) =>
      providerClinicLocation.clinic_location.id === clinicLocationId
  )?.clinic_role;

  const handleDeleteClinic = () => {
    setOpenDeleteConfirmationDialog(false);

    if (clinicLocationId) {
      dispatch(
        deleteAction({
          history,
          clinicLocationId,
          clinicId,
          userId: user.id,
          clinicRole,
        })
      );
    }
  };

  const handleApproveInvitation = () => {
    setIsOpenApproveInvitationDialog(false);

    if (clinicLocationId && clinicId) {
      dispatch(
        approveInvitationAction({
          history,
          clinicLocationId,
          clinicId,
          userId: user.id,
        })
      );
    }
  };

  const onAssignPatientToProviderClicked = () => {
    if (clinicLocationId && user?.email && currentUser) {
      dispatch(
        assignPatientToProvider({
          clinicLocationId,
          providerId: currentUser.id,
          patientEmail: user?.email,
        })
      );
    }
  };

  const onRevokePatientProviderAssignmentClicked = () => {
    if (clinicLocationId && user?.email && currentUser) {
      dispatch(
        revokePatientProviderAssignment({
          clinicLocationId: clinicLocationId,
          providerId: currentUser.id,
          patientId: user?.id,
        })
      );
    }
  };

  return (
    <>
      <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
        {user.first_name === ACCOUNT_DEACTIVATED ||
        user.last_name === ACCOUNT_DEACTIVATED
          ? ACCOUNT_DEACTIVATED
          : StringUtils.displayValue(`${user.first_name} ${user.last_name}`)}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
        {StringUtils.displayValue(`${user?.email || ''}`)}
      </OvListTableField>

      {currentUser ? (
        <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
          {user?.latest_scan_date
            ? DateUtils.formatDate(
                user?.latest_scan_date,
                'MMMM DD, YYYY, HH:mm:ss'
              )
            : '-'}
        </OvListTableField>
      ) : (
        ''
      )}

      {showClinicRole ? (
        <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
          {StringUtils.displayValue(`${clinicRole || ''}`)}
        </OvListTableField>
      ) : (
        ''
      )}

      <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
        {getStatus() ? t(getStatus()) : '-'}
      </OvListTableField>

      {showClinicLocationProviderCreatedAt ? (
        <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
          {StringUtils.displayValue(
            `${
              clinicLocationProvider?.created_at
                ? DateUtils.formatDate(
                    clinicLocationProvider.created_at,
                    'MMMM DD, YYYY, HH:mm:ss'
                  )
                : '-'
            }`
          )}
        </OvListTableField>
      ) : (
        ''
      )}

      {showClinicLocationPatientCreatedAt ? (
        <OvListTableField style={{ opacity: fieldOpacity, color: getColor() }}>
          {StringUtils.displayValue(
            `${
              clinicLocationPatient?.created_at
                ? DateUtils.formatDate(
                    clinicLocationPatient.created_at,
                    'MMMM DD, YYYY, HH:mm:ss'
                  )
                : '-'
            }`
          )}
        </OvListTableField>
      ) : (
        ''
      )}

      {deleteActionCtaLabel && approveInvitationCtaLabel ? (
        <OvListTableField>
          {deletable && (
            <Tooltip title={deleteActionCtaLabel}>
              <StyledIconButton
                onClick={() => setOpenDeleteConfirmationDialog(true)}
                className="actionIcon"
              >
                <DeleteIcon />
              </StyledIconButton>
            </Tooltip>
          )}
          {showClinicRole ? (
            <Tooltip title={'More'}>
              <StyledIconButton
                onClick={() => setIsProviderActionsDialogOpen(true)}
                className="actionIcon"
              >
                <MoreVertIcon />
              </StyledIconButton>
            </Tooltip>
          ) : (
            editable && (
              <Tooltip title={approveInvitationCtaLabel}>
                <span>
                  <StyledIconButton
                    onClick={() => setIsOpenApproveInvitationDialog(true)}
                    className="actionIcon"
                    disabled={
                      getStatus() === 'common.userFields.status.active' ||
                      getStatus() === 'common.userFields.status.inOnBoarding'
                    }
                  >
                    <VerifiedUserIcon />
                  </StyledIconButton>
                </span>
              </Tooltip>
            )
          )}
        </OvListTableField>
      ) : (
        ''
      )}

      {currentUser && editable ? (
        <OvListTableField>
          {isUserAssignedToProvider() ? (
            <Tooltip title={t('patientDetails.actions.revokeUserAssignment')}>
              <StyledIconButton
                onClick={onRevokePatientProviderAssignmentClicked}
              >
                <StarIcon />
              </StyledIconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('patientDetails.actions.assignPatientToMe')}>
              <StyledIconButton onClick={onAssignPatientToProviderClicked}>
                <StarBorderIcon />
              </StyledIconButton>
            </Tooltip>
          )}
        </OvListTableField>
      ) : (
        ''
      )}

      <OvListTableField style={{ width: '9rem' }}>
        {seeDetails ? (
          <StyledOvButton
            disabled={
              getStatus() === 'common.userFields.status.inOnBoarding' ||
              getStatus() === 'common.userFields.status.waitingForApproval'
            }
          >
            <NavLink to={`${url}/${user.id}`}>{seeDetailsCtaLabel}</NavLink>
          </StyledOvButton>
        ) : (
          userReadAllowed && (
            <StyledOvButton>
              <NavLink to={`/users/${user.id}`}>
                {t('common.actions.viewProfile')}
              </NavLink>
            </StyledOvButton>
          )
        )}
      </OvListTableField>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteConfirmationDialog}
        onCancel={() => setOpenDeleteConfirmationDialog(false)}
        onConfirm={handleDeleteClinic}
        title="clinicLocationDetails.actions.removeUserFromClinicLocationTitle"
        description="clinicLocationDetails.actions.removeUserFromClinicDescription"
      />

      <OvConfirmationDialog
        icon={<StyledVerifiedUserIcon />}
        isOpen={isOpenApproveInvitationDialog}
        onCancel={() => setIsOpenApproveInvitationDialog(false)}
        onConfirm={handleApproveInvitation}
        title="clinicLocationDetails.actions.acceptInvitationTitle"
        description="clinicLocationDetails.actions.acceptInvitationDescription"
      />

      <OvProviderAdminActionsDialog
        isOpen={isProviderActionsDialogOpen}
        onCancel={() => setIsProviderActionsDialogOpen(false)}
        user={user}
        clinicLocationId={clinicLocationId}
        clinicId={clinicId}
      />
    </>
  );
};

export default OvClinicLocationUserListRow;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_WHITE};
  }
`;

const StyledVerifiedUserIcon = styled(VerifiedUserIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_WHITE};
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;
