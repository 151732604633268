export enum LotType {
  CTRL_LH_PG = 'CTRL-LH-PG',
  CTRL_PG_LH = 'CTRL-PG-LH',
  E3G = 'E3G',
  LR_E3G = 'LR_E3G',
}

export const lotTypeConfig = (t: any) => [
  {
    value: LotType.LR_E3G,
    label: t('common.lowRangeEstrogen'),
  },
  {
    value: LotType.CTRL_LH_PG,
    label: `${t('common.luteinizing')} - ${t('common.progesterone')}`,
  },
  {
    value: LotType.CTRL_PG_LH,
    label: `${t('common.progesterone')} - ${t('common.luteinizing')}`,
  },
];
