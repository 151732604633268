import { fieldToTextField, TextFieldProps } from 'formik-mui';
import React, { FC } from 'react';
import OvTextField from './OvTextField';

const OvTextFieldFormControl: FC<TextFieldProps> = ({ children, ...props }) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const handleInputChange = React.useCallback(
    (event: any) => {
      const { value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );

  return (
    <OvTextField
      {...fieldToTextField(props)}
      autoComplete="no"
      onInput={handleInputChange}
    />
  );
};

export default OvTextFieldFormControl;
