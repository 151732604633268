import React, { FC } from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from '@mui/material/Button';
import Colours from '../../../design-system/colours';

const OvTextButton: FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton {...props} variant="text">
    {children}
  </StyledButton>
);

export default OvTextButton;

const StyledButton = styled(Button)`
  && {
    color: ${Colours.OV_BASE};
    background: transparent;

    &:hover {
      background: transparent;
      color: ${Colours.OV_DARK_BLUE};
    }

    * {
      background: transparent !important;
      color: ${Colours.OV_BASE};
    }
  }
`;
