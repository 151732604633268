import { createTheme } from '@mui/material';
import Colours from './colours';

const customMuiTheme = createTheme({
  typography: {
    fontFamily: [
      'CentraNo2-Book',
      'CentraNo2',
      'CentraNo2-Medium',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: Colours.OV_BASE,
    },
    h1: {
      fontSize: '2.4rem',
    },
  },
});

export default customMuiTheme;
