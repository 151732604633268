import React, { FC, useState } from 'react';
import OvGenericScanningPlanTable from '../UI/molecules/OvGenericScanningPlanTable';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import { GenericScanningPlan } from '../../common/model/dto/scan-sequences/generic-scanning-plan';
import { useTranslation } from 'react-i18next';
import OvButton from '../UI/atoms/OvButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import { ScanSequence } from '../../common/model/dto/scan-sequences/scan-sequence';
import { useAppDispatch } from '../../redux/hooks';
import { deleteScanningPlanFromScanSequence } from '../../redux/thunks/admin/admin-scan-sequences.thunk';
import OvNoContent from '../UI/molecules/OvNoContent';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';

const GenericScanningPlans: FC<{
  scanSequence: ScanSequence;
  scanningPlans?: GenericScanningPlan[];
}> = ({ scanSequence, scanningPlans }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const scanningPlanOperations = useAuthorized(Resource.ScanningPlan);

  const scanningPlanDeleteAllowed =
    scanningPlanOperations.delete || applicationOperations.supervise;

  const [isDeleteScanningPlanOpen, setIsDeleteScanningPlanOpen] =
    useState(false);
  const [lengthOfScanningPlanToDelete, setLengthOfScanningPlanToDelete] =
    useState<number | null>(null);

  const handleClickOnDeleteButton = (lenght: number) => {
    setIsDeleteScanningPlanOpen(true);
    setLengthOfScanningPlanToDelete(lenght);
  };

  const handleDeleteScanningPlan = () => {
    if (scanSequence?.id && lengthOfScanningPlanToDelete)
      dispatch(
        deleteScanningPlanFromScanSequence({
          id: scanSequence.id,
          length: lengthOfScanningPlanToDelete,
        })
      );
    setIsDeleteScanningPlanOpen(false);
    setLengthOfScanningPlanToDelete(null);
  };

  return (
    <TablesContainer>
      {scanningPlans?.map((genericScanningPlan) => (
        <StyledTableContainer
          key={genericScanningPlan.scanning_plan_entries.length}
        >
          <HeaderContainer>
            <StyledTableTitle>{`${genericScanningPlan.scanning_plan_entries.length} days plan`}</StyledTableTitle>
            {scanningPlanDeleteAllowed && (
              <StyledOvButton
                onClick={() =>
                  handleClickOnDeleteButton(
                    genericScanningPlan.scanning_plan_entries.length
                  )
                }
              >
                <StyledDeleteCtaIcon />
                {t('common.actions.delete')}
              </StyledOvButton>
            )}
          </HeaderContainer>
          <OvGenericScanningPlanTable
            genericScannigPlan={genericScanningPlan}
          />
        </StyledTableContainer>
      ))}

      {scanningPlans?.length === 0 ? (
        <OvNoContent>
          {t('scanSequenceDetails.emptyScanningPlanList')}
        </OvNoContent>
      ) : null}

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isDeleteScanningPlanOpen}
        onCancel={() => setIsDeleteScanningPlanOpen(false)}
        onConfirm={handleDeleteScanningPlan}
        title="scanSequenceDetails.actions.deleteScanningPlanTitle"
        description="scanSequenceDetails.actions.deleteScanningPlanDescription"
      />
    </TablesContainer>
  );
};

export default GenericScanningPlans;

const StyledTableContainer = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledTableTitle = styled.h5`
  padding: 0;
  margin: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledDeleteCtaIcon = styled(DeleteIcon)`
  && {
    margin-right: 0.25rem;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
