import React, { FC, useCallback, useEffect, useState } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import { SlotInfo, View, Views } from 'react-big-calendar';
import OvDailyDataCalendarHoc from '../molecules/OvDailyDataCalendarHoc';
import OvCalendarView from './OvCalendarView';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import OvDailyDataDetailsInCalendar from '../molecules/OvDailyDataDetailsInCalendar';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import DateUtils from '../../../common/utils/services/date-utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { parseUrl } from 'query-string';
import { DailyDataService } from '../../../services/daily-data.service';
import {
  clearDaySlotSelection,
  clearPatientDailyData,
  clearScanList,
  selectDaySlot,
} from '../../../redux/reducers/clinic-location.slice';
import { DailyData } from '../../../common/model/dto/daily-data';
import { setMonth, setYear } from 'date-fns';

const OvPatientInfoCalendar: FC<{
  loadDailyData: any;
  clinicLocationId?: string;
  selectedPatient?: UserInfo;
}> = ({ selectedPatient, loadDailyData, clinicLocationId }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();

  const [defaultCalendarDate, setDefaultCalendarDate] = useState<Date>();
  const dailyDataList: DailyData[] = useAppSelector(
    (state) => state.clinicLocation.patientDailyData
  );
  const selectedDailyDataInCalendar = useAppSelector(
    (state) => state.clinicLocation.selectedDailyDataInCalendar
  );
  const selectedDay = useAppSelector(
    (state) => state.clinicLocation.selectedDay
  );

  const handleNavigationBetweenMonths = useCallback(
    (date: Date, view: View) => {
      let firstVisibleDay: Date, lastVisibleDay: Date;

      if (view === Views.MONTH) {
        firstVisibleDay = moment(date)
          .startOf('month')
          .subtract(20, 'days') // 7days needed because of the layout of the calendar, and another 10 day to count post ovulation days
          .toDate();
        lastVisibleDay = moment(date).endOf('month').add(7, 'days').toDate();

        dispatch(clearPatientDailyData());
        dispatch(clearScanList());

        if (clinicLocationId) {
          dispatch(
            loadDailyData({
              startDate: firstVisibleDay,
              endDate: lastVisibleDay,
              clinicLocationId,
              patientId: selectedPatient?.document_id!,
            })
          );
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedPatient?.document_id, clinicLocationId]
  );

  useEffect(() => {
    const visibleMonth: number = parseInt(
      parseUrl(search).query['visible-month'] as string
    );
    const visibleYear: number = parseInt(
      parseUrl(search).query['visible-year'] as string
    );

    let date = new Date();

    if (visibleMonth && visibleYear) {
      date = setMonth(date, visibleMonth - 1);
      date = setYear(date, visibleYear);
    }

    setDefaultCalendarDate(date);
    handleNavigationBetweenMonths(date, Views.MONTH);

    return function cleanup() {
      dispatch(clearDaySlotSelection());
    };
  }, [dispatch, handleNavigationBetweenMonths, search]);

  const handleSlotSelect = (slotInfo: SlotInfo) => {
    dispatch(selectDaySlot(slotInfo.start as Date));
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <OvCalendarView
          events={DailyDataService.mapDailyDataToCalendarEvents(dailyDataList)}
          selectedDay={selectedDay}
          style={{
            maxWidth: '100%',
            height: 600,
          }}
          onSelect={(slotInfo) => handleSlotSelect(slotInfo)}
          onNavigate={(date: Date, view: View) => {
            history.replace(
              `${url}?visible-month=${DateUtils.formatDate(
                date,
                'MM'
              )}&visible-year=${DateUtils.formatDate(date, 'YYYY')}`
            );
          }}
          headerHoc={OvDailyDataCalendarHoc}
          defaultDate={defaultCalendarDate}
        />

        <OvDailyDataDetailsInCalendar
          selectedDay={selectedDay}
          dailyData={selectedDailyDataInCalendar}
          selectedUser={selectedPatient}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default OvPatientInfoCalendar;

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;
