import React, { FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { Field, Form, Formik } from 'formik';
import OvFormObserver from '../atoms/OvFormObserver';
import OvTextFieldFormControl from '../atoms/OvTextFieldFormControl';

export interface ProductGeneralInfo {
  title?: string;
  subtitle?: string;
  stripe_checkout_page_description?: string;
  stripe_checkout_page_title?: string;
  order?: number;
}

export interface OvProductGeneralInfoControlProps {
  onChangeGeneralInfo: (
    generalInfo: ProductGeneralInfo,
    isValid: boolean
  ) => void;
  generalInfo?: ProductGeneralInfo;
}

const OvProductGeneralInfoControl: FC<OvProductGeneralInfoControlProps> = ({
  generalInfo,
  onChangeGeneralInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        title: generalInfo?.title || '',
        subtitle: generalInfo?.subtitle || '',
        stripe_checkout_page_description:
          generalInfo?.stripe_checkout_page_description || '',
        stripe_checkout_page_title:
          generalInfo?.stripe_checkout_page_title || '',
        order: generalInfo?.order,
      }}
      enableReinitialize={true}
      onSubmit={(values) => console.log(values)}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('common.requiredField')),
        stripe_checkout_page_description: Yup.string().required(
          t('common.requiredField')
        ),
        stripe_checkout_page_title: Yup.string().required(
          t('common.requiredField')
        ),
        order: Yup.number()
          .required(t('common.requiredField'))
          .min(1, t('common.minValue', { minValue: 1 })),
      })}
      validateOnBlur={true}
    >
      <Form>
        <Field
          component={StyledTextField}
          type="text"
          name="title"
          placeholder={t('products.generalInfo.title')}
        />

        <Field
          component={StyledTextField}
          type="text"
          name="subtitle"
          multiline
          placeholder={t('products.generalInfo.subtitle')}
        />

        <Field
          component={StyledTextField}
          type="text"
          name="stripe_checkout_page_title"
          placeholder={t('products.generalInfo.stripeCheckoutPageTitle')}
        />

        <Field
          component={StyledTextField}
          type="text"
          name="stripe_checkout_page_description"
          placeholder={t('products.generalInfo.stripeCheckoutPageDescription')}
        />

        <Field
          component={StyledTextField}
          type="number"
          name="order"
          placeholder={t('products.generalInfo.order')}
        />

        <OvFormObserver valuesChanged={onChangeGeneralInfo} />
      </Form>
    </Formik>
  );
};

export default OvProductGeneralInfoControl;

const StyledTextField = styled(OvTextFieldFormControl)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      display: block;
      width: 100%;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;
