import React, { FC } from 'react';
import { ClinicLocation } from '../../../common/model/dto/clinic-location';
import OvClinicLocationListRow from './OvClinicLocationListRow';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvListTable from '../atoms/OvListTable';
import { useTranslation } from 'react-i18next';

const OvClinicLocationList: FC<{ clinicLocations: ClinicLocation[] }> = ({
  clinicLocations,
}) => {
  const headerNames = [
    'common.clinicLocationFields.name',
    'common.clinicLocationFields.email',
    'common.clinicLocationFields.city',
    'common.clinicFields.phone1',
    'common.clinicFields.providers',
    'common.clinicFields.patients',
    'common.createdAt',
    'common.action',
  ];
  const { t } = useTranslation();

  return (
    <OvListTable>
      <OvListTableRow>
        {headerNames.map((headerName) => (
          <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
        ))}
      </OvListTableRow>

      {clinicLocations.map((clinicLocation) => (
        <OvListTableRow>
          <OvClinicLocationListRow clinicLocation={clinicLocation} />
        </OvListTableRow>
      ))}
    </OvListTable>
  );
};

export default OvClinicLocationList;
