import React, { useState } from 'react';
import { Head } from '../../common/utils/Head';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LoginForm from '../templates/LoginForm';
import Colours from '../../design-system/colours';
import OvLogo from '../UI/atoms/OvLogo';
import breakpoints from '../../design-system/breakpoints';
import { Typography } from '@mui/material';
import OvForgotPasswordDialog from '../UI/molecules/OvForgotPasswordDialog';
import { AlertColor } from '@mui/material/Alert';
import AuthService from '../../services/auth.service';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvSnackbar from '../UI/molecules/OvSnackbar';

const LoginPage = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('false');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [isLoading, setIsLoading] = useState(false);

  const handleSendPasswordResetEmail = async (email: string) => {
    setIsDialogOpen(false);
    try {
      setIsLoading(true);
      await AuthService.sendPasswordResetEmail(email);
      setIsLoading(false);
      setSnackbarProps('success', t('login.emailSentSuccessfully'), true);
    } catch (error: any) {
      setIsLoading(false);
      setSnackbarProps('error', t('login.emailSendingFailed'), true);
    }
  };

  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: boolean
  ) => {
    setSnackbarSeverity(severity);
    setSnackBarMessage(message);
    setIsSnackbarOpen(isOpen);
  };

  return (
    <>
      <StyledPageWrapper>
        <Head title={t('login.pageTitle')} description={t('login.pageDesc')} />

        <StyledWrapper>
          <StyledTitle>{t('login.header')}</StyledTitle>
          <StyledText>{t('login.subHeader')}</StyledText>
          <Container>
            <InfoBox>
              <OvLogo />
              <Typography
                style={{
                  marginTop: '16px',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
                variant="h1"
              >
                {t('login.pageHeading')}
              </Typography>
            </InfoBox>
            <ActionBox>
              <HorizontalSeparator />
              <LoginForm />
            </ActionBox>
            <StyledForgotPasswordButton onClick={() => setIsDialogOpen(true)}>
              {t('login.forgotPasswordCta')}
            </StyledForgotPasswordButton>
          </Container>
        </StyledWrapper>
      </StyledPageWrapper>

      <OvForgotPasswordDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleSendPasswordResetEmail}
      />

      <OvSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackBarMessage}
      />
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default LoginPage;

const StyledPageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('/images/background.png');
  background-attachment: fixed;
  position: fixed;
  overflow-y: auto;

  @media (max-width: ${breakpoints.md}) {
    background-image: none;
    background: ${Colours.WHITE};
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  text-align: justify;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    max-width: min-content;
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate3d(-50%, -50%, 0);
    text-align: justify;
  }
`;

const Container = styled.main`
  background: ${Colours.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    justify-content: center;
    min-width: 30rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1.5rem rgb(82 77 62 / 10%),
      0 1px 0.375rem rgb(82 77 62 / 8%);
  }
`;

const InfoBox = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ActionBox = styled.section`
  background: ${Colours.WHITE};
  flex-grow: 1;
  border-radius: 0.5rem;
  width: 100%;
`;

const HorizontalSeparator = styled.hr`
  border-top: 1px dotted ${Colours.OV_BASE};
  margin: 1.25rem 0 1.875rem 0;
  width: 100%;
`;

const StyledTitle = styled.h2`
  align-self: flex-start;
  margin: 0;

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const StyledText = styled.p`
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const StyledForgotPasswordButton = styled.button`
  background: transparent;
  border: none;
  align-self: flex-end;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: ${Colours.OV_BASE};
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
`;
