import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import {
  Box,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import OvChartLabel from '../atoms/OvChartLabel';
import OvNoContent from '../molecules/OvNoContent';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import Colours from '../../../design-system/colours';
import OvClinicAuditActivityBarChart from './OvClinicAuditActivityBarChart';
import useAxios from 'axios-hooks';
import { stringify } from 'query-string';
import { ADMIN_ANALYTICS_ROUTE } from '../../../api/backend';
import OvSelect from '../atoms/OvSelect';
import ClinicAuditActivityChartData from '../../../common/model/type/clinic-audit-activity-chart-data.type';

interface OvClinicAuditActivityProps {
  clinicId: string;
}

const OvClinicAuditActivity: FC<OvClinicAuditActivityProps> = ({
  clinicId,
}) => {
  const { t } = useTranslation();

  const [intervalHours, setIntervalHours] = useState<number>(4);
  const [weeks, setWeeks] = useState<number>(4);
  const [includeOovaUsers, setIncludeOovaUsers] = useState<boolean>(false);

  const [{ data: clinicAuditActivityData, loading: isLoading }, refetch] =
    useAxios<ClinicAuditActivityChartData[]>(
      `/${ADMIN_ANALYTICS_ROUTE}/clinic-activities?${stringify({
        clinic_id: clinicId,
        interval_hours: intervalHours,
        weeks,
        is_oova_emails_included: includeOovaUsers, // Pass the checkbox state here
      })}`,
      { useCache: false }
    );

  useEffect(() => {
    refetch();
  }, [intervalHours, weeks, includeOovaUsers, refetch]);

  return (
    <>
      <OverViewWrapper>
        <ControlContainer>
          <DropdownRow>
            <Tooltip title={t('analytics.intervalHoursTooltip')}>
              <FormControlLabel
                control={
                  <OvSelect
                    value={intervalHours}
                    onChange={(e) => setIntervalHours(Number(e.target.value))}
                  >
                    {Array.from({ length: 24 }, (_, i) => i + 1).map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </OvSelect>
                }
                label={t('analytics.intervalHours')}
                labelPlacement="end"
                sx={{ gap: '1rem' }}
              />
            </Tooltip>
          </DropdownRow>
          <DropdownRow>
            <Tooltip title={t('analytics.weeksTooltip')}>
              <FormControlLabel
                control={
                  <OvSelect
                    value={weeks}
                    onChange={(e) => setWeeks(Number(e.target.value))}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((week) => (
                      <MenuItem key={week} value={week}>
                        {week}
                      </MenuItem>
                    ))}
                  </OvSelect>
                }
                label={t('analytics.weeks')}
                labelPlacement="end"
                sx={{ gap: '1rem' }}
              />
            </Tooltip>
          </DropdownRow>
          <DropdownRow>
            <Tooltip title={t('analytics.includeOovaUsersTooltip')}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeOovaUsers}
                    onChange={(e) => setIncludeOovaUsers(e.target.checked)}
                    color="primary"
                  />
                }
                label={t('analytics.includeOovaUsers')}
              />
            </Tooltip>
          </DropdownRow>
        </ControlContainer>
        {!isLoading &&
          clinicAuditActivityData &&
          clinicAuditActivityData.length > 0 &&
          clinicId && (
            <Container>
              <ContentWrapper>
                <ChartContainer>
                  <Box pt={3.5} pb={2} pl={5} pr={5}>
                    <ChartWrapper>
                      <OvChartLabel
                        style={{ display: 'block', marginBottom: '1' }}
                      >
                        {t('clinicDetails.tabs.auditActivity')}
                      </OvChartLabel>
                      <OvClinicAuditActivityBarChart
                        data={clinicAuditActivityData}
                      />
                      <StyledDisclaimerContainer>
                        <ul>
                          <li>
                            This chart represents the number of requests sent to
                            the server associated with clinic activity.
                          </li>
                          <li>
                            This chart is provided for informational purposes
                            only and should not be used as a definitive measure
                            of individual clinic activity.
                          </li>
                        </ul>
                      </StyledDisclaimerContainer>
                    </ChartWrapper>
                  </Box>
                </ChartContainer>
              </ContentWrapper>
            </Container>
          )}
        {!isLoading &&
          (!clinicAuditActivityData ||
            clinicAuditActivityData.length === 0) && (
            <OvNoContent>{t('dashboard.noContent')}</OvNoContent>
          )}
      </OverViewWrapper>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default OvClinicAuditActivity;

const OverViewWrapper = styled.div`
  display: 'flex';
  flex-direction: 'row';
  flex-wrap: 'nowrap';
  width: 100%;
`;

const Container = styled.div``;

const ChartContainer = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  border-radius: ${Variables.borderRadius.SMALL};
  width: calc(100% - 18.5rem);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledDisclaimerContainer = styled.div`
  color: ${Colours.OV_BASE};
  font-size: 0.8rem;
`;

const ChartWrapper = styled.div`
  border-bottom: 1px solid ${Colours.OV_BASE};
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
`;

const DropdownRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
