import * as firebase from 'firebase/auth';

export const getAuthUser = () => {
  const user = firebase.getAuth().currentUser;

  if (!user) {
    throw new Error('Authentication missing');
  }

  return user;
};

export const getIdToken = () => {
  return getAuthUser().getIdToken();
};
