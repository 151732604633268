import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Head } from '../../common/utils/Head';
import breakpoints from '../../design-system/breakpoints';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppSelector } from '../../redux/hooks';
import AuthService from '../../services/auth.service';
import SetPasswordForm from '../templates/SetPasswordForm';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvLogo from '../UI/atoms/OvLogo';
import CancelIcon from '@mui/icons-material/Cancel';

const SetPasswordPage = () => {
  const { t } = useTranslation();
  const { token }: any = useParams();
  const [isCheckTokenValidityLoading, setIsCheckTokenValidityLoading] =
    useState(false);
  const loading = useAppSelector((state) => state.auth.loading) || '';
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkIfTokenIsValid = async () => {
      setIsCheckTokenValidityLoading(true);
      const isValid = await AuthService.checkSetPasswordTokenValidity(token);
      setIsValid(isValid);
      setIsCheckTokenValidityLoading(false);
    };

    checkIfTokenIsValid();
  }, [token]);

  return (
    <>
      <Head title={t('login.pageTitle')} description={t('login.pageDesc')} />

      {!isCheckTokenValidityLoading ? (
        <Container>
          {isValid ? (
            <>
              <InfoBox>
                <OvLogo />

                <Typography
                  style={{ marginTop: '1rem', textAlign: 'center' }}
                  variant="h1"
                >
                  {t('login.pageHeading')}
                </Typography>
              </InfoBox>
              <ActionBox>
                <HorizontalSeparator />
                <SetPasswordForm token={token} />
              </ActionBox>
            </>
          ) : (
            <>
              <InfoBox>
                <StyledCancelIcon />
                <InfoHeading>
                  {t('setPassword.alreadySetPasswordTitle')}
                </InfoHeading>
                <InfoDescription>
                  {t('setPassword.alreadySetPasswordLoginSuggestion')}
                  <StyledLink href="https://360.oova.life">
                    360.oova.life
                  </StyledLink>
                  .
                  <br />
                  {t('setPassword.ifSomethingWrongMessage')}
                </InfoDescription>
              </InfoBox>
            </>
          )}
          {loading && <OvLoadingIndicator position={'fixed'} />}
        </Container>
      ) : (
        <OvLoadingIndicator position={'fixed'} />
      )}
    </>
  );
};

export default SetPasswordPage;

const Container = styled.main`
  background: ${Colours.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${Variables.boxShadow.alternateBox};
  padding: 3.875rem 2rem 0.75rem 2rem;

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 480px;
    border-radius: 8px;
  }
`;

const InfoBox = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

const ActionBox = styled.section`
  background: ${Colours.WHITE};
  flex-grow: 1;
  border-radius: 8px;
  width: 100%;
`;

const HorizontalSeparator = styled.hr`
  border-top: 1px dotted ${Colours.OV_BASE};
  margin: 20px 0 30px 0;
  width: 100%;
`;

const StyledCancelIcon = styled(CancelIcon)`
  && {
    margin-top: 2rem;
    color: ${Colours.OV_RED};
    font-size: 5rem;
  }
`;

const InfoHeading = styled.h1`
  color: ${Colours.OV_BASE};
`;

const InfoDescription = styled.h3`
  color: ${Colours.OV_BASE};
`;

const StyledLink = styled.a`
  color: ${Colours.OV_DARK_BLUE};
`;
