import React, { FC } from 'react';
import styled from 'styled-components';
import Pagination, { PaginationProps } from '@mui/material/Pagination';
import Colours from '../../../design-system/colours';

const OvPagination: FC<PaginationProps & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => <StyledPagination {...props}>{children}</StyledPagination>;

export default OvPagination;

const StyledPagination = styled(Pagination)`
  && {
    .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
      background: ${Colours.OV_BASE};
      color: ${Colours.OV_WHITE};
    }
  }
`;
