import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import OvSelect from '../atoms/OvSelect';
import { CreateEntitlementRequest } from '../../../common/model/dto/create-entitlement-request';
import { Resource } from '../../../common/model/type/resource.enum';

const RESOURCES = Object.values(Resource).sort();

const OvCreateEntitlementDialog: FC<{
  isOpen: boolean;
  mode: string;
  onCancel: () => void;
  onSave: (request: CreateEntitlementRequest) => void;
}> = ({ isOpen, mode, onCancel, onSave }) => {
  const { t } = useTranslation();
  const formik = useFormik<CreateEntitlementRequest>({
    initialValues: {
      user_role: null,
      clinic_role: null,
      resource: RESOURCES[0],
    },
    onSubmit: (request) => {
      onSave(request);
      formik.resetForm();
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      user_role: Yup.string().nullable(),
      resource: Yup.string().required('resource is required'),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      formik.setValues({
        user_role: null,
        clinic_role: null,
        resource: RESOURCES[0],
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        formik.resetForm();
        onCancel();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogTitle>
          {t('entitlements.createEntitlement')}
        </StyledDialogTitle>
        <StyledDialogContent>
          {mode === 'user' && (
            <StyledTextField
              type="text"
              fullWidth
              autoComplete="off"
              multiline
              label={t('entitlements.fields.user_role')}
              error={formik.touched.user_role && !!formik.errors.user_role}
              helperText={
                formik.errors.user_role &&
                formik.touched.user_role &&
                formik.errors.user_role
              }
              {...formik.getFieldProps('user_role')}
            />
          )}

          {mode === 'clinic' && (
            <StyledTextField
              type="text"
              fullWidth
              autoComplete="off"
              multiline
              label={t('entitlements.fields.clinic_role')}
              error={formik.touched.clinic_role && !!formik.errors.clinic_role}
              helperText={
                formik.errors.clinic_role &&
                formik.touched.clinic_role &&
                formik.errors.clinic_role
              }
              {...formik.getFieldProps('clinic_role')}
            />
          )}

          <StyledLabel>{t('entitlements.fields.resource')}</StyledLabel>
          <StyledSelect
            labelId="resource"
            {...formik.getFieldProps('resource')}
            value={formik.values.resource}
          >
            {RESOURCES.map((val) => (
              <StyledMenuItem key={val} value={val}>
                {val}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton type="submit">
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

export default OvCreateEntitlementDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    margin-bottom: 1rem;
    max-width: none;
    height: 3rem;

    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledLabel = styled.label`
  font-size: ${Variables.fontSizes.MEDIUM};
  margin-right: 0.5rem;
`;
