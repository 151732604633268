import React, { FC, useEffect } from 'react';
import { UpdateProductPriceRequest } from '../../../common/model/dto/product/update-product-price-request';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import breakpoints from '../../../design-system/breakpoints';
import styled from 'styled-components';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvButton from '../atoms/OvButton';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import OvTextField from '../atoms/OvTextField';

interface OvCreatePaymentLinkDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (updateProductPriceRequest: UpdateProductPriceRequest) => void;
}

const OvCreatePaymentLinkDialog: FC<OvCreatePaymentLinkDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(
    (state) => state.adminProductPrice.isLoading
  );
  const formik = useFormik<UpdateProductPriceRequest>({
    initialValues: {
      payment_link_redirect_url: '',
      is_b2b: false,
    },
    onSubmit: (updateProductPriceRequest: UpdateProductPriceRequest) => {
      onSave(updateProductPriceRequest);
    },
    validationSchema: Yup.object().shape({
      payment_link_redirect_url: Yup.string().required(
        'Payment link redirect url is required.'
      ),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogTitle>
          {t('productPrices.dialogs.createPaymentLinkDialogTitle')}
        </StyledDialogTitle>
        <StyledDialogDescription>
          {t('productPrices.dialogs.createPaymentLinkDialogDescription')}
        </StyledDialogDescription>
        <StyledDialogContent>
          <StyledTextField
            type="url"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('productPrices.fields.redirectUrlAfterSuccessfulPayment')}
            error={
              formik.touched.payment_link_redirect_url &&
              !!formik.errors.payment_link_redirect_url
            }
            helperText={
              formik.errors.payment_link_redirect_url &&
              formik.touched.payment_link_redirect_url &&
              formik.errors.payment_link_redirect_url
            }
            {...formik.getFieldProps('payment_link_redirect_url')}
          />
          <StyledFormControlLabel
            checked={formik.values.is_b2b}
            onChange={() =>
              formik.getFieldHelpers('is_b2b').setValue(!formik.values.is_b2b)
            }
            control={<StyledSwitch />}
            label={t('productPrices.fields.isB2b')}
            labelPlacement="start"
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton type="submit">
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvCreatePaymentLinkDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 500px !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const StyledDialogDescription = styled(DialogContentText)`
  && {
    max-width: 30rem;
    text-align: center;
    margin: 0 auto 1rem auto;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledSwitch = styled(Switch)`
  && {
    .MuiSwitch-thumb {
      color: ${Colours.OV_BASE};
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &&  {
    margin: 0;
  }
`;
