import {
  getDownloadURL,
  StorageReference,
  uploadBytes,
} from 'firebase/storage';

export const uploadFile = async (
  file: Blob,
  storageRef: StorageReference
): Promise<string> => {
  const snapshot = await uploadBytes(storageRef, file, {
    contentType: 'image/png',
  });
  const downloadURL = await getDownloadURL(snapshot.ref);
  return downloadURL;
};

export const resizeImage = async (
  file: File,
  maxWidth?: number,
  maxHeight?: number,
  quality?: number
): Promise<Blob> => {
  try {
    const dataUrl = await fileToDataURL(file);
    const img = await loadImage(dataUrl);
    const { width, height } = calculateDimensions(
      img,
      maxWidth ?? 100,
      maxHeight ?? 100
    );
    const canvas = createCanvas(width, height);
    drawImageToCanvas(img, canvas, width, height);
    const blob = await canvasToBlob(canvas, 'image/jpeg', quality ?? 0.7);
    return blob;
  } catch (error: any) {
    throw new Error(`Failed to resize image: ${error.message}`);
  }
};

const fileToDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error('Error reading file'));

    reader.readAsDataURL(file);
  });
};

const loadImage = (dataUrl: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Error loading image'));

    img.src = dataUrl;
  });
};

const calculateDimensions = (
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
): { width: number; height: number } => {
  let { width, height } = img;

  if (width > height) {
    if (width > maxWidth) {
      height = (height * maxWidth) / width;
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = (width * maxHeight) / height;
      height = maxHeight;
    }
  }

  return { width, height };
};

const createCanvas = (width: number, height: number): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

const drawImageToCanvas = (
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  width: number,
  height: number
): void => {
  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('Canvas context is not available');
  ctx.drawImage(img, 0, 0, width, height);
};

const canvasToBlob = (
  canvas: HTMLCanvasElement,
  type: string,
  quality: number
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas is empty'));
        }
      },
      type,
      quality
    );
  });
};
