import { isNumber } from 'lodash';
import MathUtils from './math-utils';
import { OvTableData } from '../../../components/UI/molecules/OvTable';
import { Constants } from '../../constants';
import { DailyData } from '../../model/dto/daily-data';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import { JournalEntryFields } from '../../../firebase/document-field.enums';
import i18n from 'i18next';
import { JournalEntry } from '../../model/dto/journal-entry';
import { OverviewGroup } from '../../model/ui/overview-group';
import { SpikeInfo } from '../../model/dto/spike-info';

export class DailyDataUtils {
  static mapDailyDataToTable(dailyData: DailyData): OvTableData {
    const tableRows: OvTableData = [];
    const journalEntry = dailyData?.journal_entry;
    const spikeInfo = dailyData?.spike_info;

    if (isNumber(dailyData?.lh)) {
      tableRows.push({
        label: 'common.dailyData.lh',
        translateLabel: true,
        value: DailyDataUtils.prettifyValue(
          dailyData?.lh,
          Constants.LH_MAX,
          Constants.LH_UNIT_OF_MEASURE
        ),
      });
    }

    if (isNumber(dailyData?.pg)) {
      tableRows.push({
        label: 'common.dailyData.pg',
        translateLabel: true,
        value: DailyDataUtils.prettifyValue(
          dailyData?.pg,
          Constants.PG_MAX,
          Constants.PG_UNIT_OF_MEASURE
        ),
      });
    }

    if (isNumber(dailyData?.lr_e3g)) {
      tableRows.push({
        label: 'common.dailyData.e3g',
        translateLabel: true,
        value: DailyDataUtils.prettifyValue(
          dailyData?.lr_e3g,
          Constants.LR_E3G_MAX,
          Constants.E3G_UNIT_OF_MEASURE
        ),
      });
    }

    if (dailyData?.period_flow) {
      tableRows.push({
        label: 'common.dailyData.period',
        translateLabel: true,
        value: dailyData?.period_flow,
      });
    }

    if (journalEntry?.breakthrough_bleeding) {
      tableRows.push({
        label: 'common.dailyData.breakThroughBleeding',
        translateLabel: true,
        value: journalEntry?.breakthrough_bleeding,
      });
    }
    if (journalEntry?.hot_flashes) {
      tableRows.push({
        label: 'common.dailyData.hotFlashes',
        translateLabel: true,
        value: journalEntry?.hot_flashes,
      });
    }

    if (journalEntry?.breast_tenderness) {
      tableRows.push({
        label: 'common.dailyData.breastTenderness',
        translateLabel: true,
        value: journalEntry?.breast_tenderness,
      });
    }

    if (journalEntry?.cramps) {
      tableRows.push({
        label: 'common.dailyData.cramps',
        translateLabel: true,
        value: journalEntry?.cramps,
      });
    }

    if (journalEntry?.nausea) {
      tableRows.push({
        label: 'common.dailyData.nausea',
        translateLabel: true,
        value: journalEntry?.nausea,
      });
    }

    if (journalEntry?.joint_pain) {
      tableRows.push({
        label: 'common.dailyData.jointPain',
        translateLabel: true,
        value: journalEntry?.joint_pain,
      });
    }

    if (journalEntry?.back_pain) {
      tableRows.push({
        label: 'common.dailyData.backPain',
        translateLabel: true,
        value: journalEntry?.back_pain,
      });
    }

    if (journalEntry?.headache) {
      tableRows.push({
        label: 'common.dailyData.headache',
        translateLabel: true,
        value: journalEntry?.headache,
      });
    }

    if (journalEntry?.migraine) {
      tableRows.push({
        label: 'common.dailyData.migraine',
        translateLabel: true,
        value: journalEntry?.migraine,
      });
    }

    if (journalEntry?.body_hair_growth) {
      tableRows.push({
        label: 'common.dailyData.bodyHairGrowth',
        translateLabel: true,
        value: journalEntry?.body_hair_growth,
      });
    }

    if (journalEntry?.skin_changes) {
      tableRows.push({
        label: 'common.dailyData.skinChanges',
        translateLabel: true,
        value: journalEntry?.skin_changes,
      });
    }

    if (journalEntry?.acne) {
      tableRows.push({
        label: 'common.dailyData.acne',
        translateLabel: true,
        value: journalEntry?.acne,
      });
    }

    if (journalEntry?.painful_urination) {
      tableRows.push({
        label: 'common.dailyData.painfulUrination',
        translateLabel: true,
        value: journalEntry?.painful_urination,
      });
    }

    if (journalEntry?.urination_frequency) {
      tableRows.push({
        label: 'common.dailyData.urinationFrequency',
        translateLabel: true,
        value: journalEntry?.urination_frequency,
      });
    }

    if (journalEntry?.incontinence) {
      tableRows.push({
        label: 'common.dailyData.incontinence',
        translateLabel: true,
        value: journalEntry?.incontinence,
      });
    }

    if (journalEntry?.sexual_activity) {
      tableRows.push({
        label: 'common.dailyData.sexualActivity',
        translateLabel: true,
        value: journalEntry?.sexual_activity,
      });
    }

    if (journalEntry?.sex_drive) {
      tableRows.push({
        label: 'common.dailyData.sexDrive',
        translateLabel: true,
        value: journalEntry?.sex_drive,
      });
    }

    if (journalEntry?.exercise_intensity) {
      tableRows.push({
        label: 'common.dailyData.exerciseIntesity',
        translateLabel: true,
        value: journalEntry?.exercise_intensity,
      });
    }

    if (journalEntry?.exercise_perceived_effort) {
      tableRows.push({
        label: 'common.dailyData.exercisePerceivedEffort',
        translateLabel: true,
        value: journalEntry?.exercise_perceived_effort,
      });
    }

    if (journalEntry?.hours_of_sleep) {
      tableRows.push({
        label: 'common.dailyData.hoursOfSleep',
        translateLabel: true,
        value: journalEntry?.hours_of_sleep,
      });
    }

    if (journalEntry?.sleep_quality) {
      tableRows.push({
        label: 'common.dailyData.sleepQuality',
        translateLabel: true,
        value: journalEntry?.sleep_quality,
      });
    }

    if (journalEntry?.dreams) {
      tableRows.push({
        label: 'common.dailyData.dreams',
        translateLabel: true,
        value: journalEntry?.dreams,
      });
    }

    if (journalEntry?.mood) {
      tableRows.push({
        label: 'common.dailyData.mood',
        translateLabel: true,
        value: journalEntry?.mood,
      });
    }

    if (journalEntry?.anxiety) {
      tableRows.push({
        label: 'common.dailyData.anxiety',
        translateLabel: true,
        value: journalEntry?.anxiety,
      });
    }

    if (journalEntry?.motivation) {
      tableRows.push({
        label: 'common.dailyData.motivation',
        translateLabel: true,
        value: journalEntry?.motivation,
      });
    }

    if (journalEntry?.productivity) {
      tableRows.push({
        label: 'common.dailyData.productivity',
        translateLabel: true,
        value: journalEntry?.productivity,
      });
    }

    if (journalEntry?.focus) {
      tableRows.push({
        label: 'common.dailyData.focus',
        translateLabel: true,
        value: journalEntry?.focus,
      });
    }

    if (journalEntry?.brain_fog) {
      tableRows.push({
        label: 'common.dailyData.brainFog',
        translateLabel: true,
        value: journalEntry?.brain_fog,
      });
    }

    if (journalEntry?.memory_problems) {
      tableRows.push({
        label: 'common.dailyData.memoryProblems',
        translateLabel: true,
        value: journalEntry?.memory_problems,
      });
    }

    if (journalEntry?.energy) {
      tableRows.push({
        label: 'common.dailyData.energy',
        translateLabel: true,
        value: journalEntry?.energy,
      });
    }

    if (journalEntry?.mood_stability) {
      tableRows.push({
        label: 'common.dailyData.moodStability',
        translateLabel: true,
        value: journalEntry?.mood_stability,
      });
    }

    if (journalEntry?.stress) {
      tableRows.push({
        label: 'common.dailyData.stress',
        translateLabel: true,
        value: journalEntry?.stress,
      });
    }

    if (journalEntry?.mental) {
      tableRows.push({
        label: 'common.dailyData.mental',
        translateLabel: true,
        value: journalEntry?.mental,
      });
    }

    if (journalEntry?.birth_control_pill) {
      tableRows.push({
        label: 'common.dailyData.birthControl',
        translateLabel: true,
        value: journalEntry?.birth_control_pill,
      });
    }

    if (journalEntry?.medication) {
      tableRows.push({
        label: 'common.dailyData.medication',
        translateLabel: true,
        value: journalEntry?.medication,
      });
    }

    if (journalEntry?.ailment) {
      tableRows.push({
        label: 'common.dailyData.ailment',
        translateLabel: true,
        value: journalEntry?.ailment,
      });
    }

    if (journalEntry?.drinking) {
      tableRows.push({
        label: 'common.dailyData.drinking',
        translateLabel: true,
        value: journalEntry?.drinking,
      });
    }

    if (journalEntry?.cannabis) {
      tableRows.push({
        label: 'common.dailyData.cannabis',
        translateLabel: true,
        value: journalEntry?.cannabis,
      });
    }

    if (journalEntry?.hangover) {
      tableRows.push({
        label: 'common.dailyData.hangover',
        translateLabel: true,
        value: journalEntry?.hangover,
      });
    }

    if (journalEntry?.cervical_fluid) {
      tableRows.push({
        label: 'common.dailyData.cervicalFluid',
        translateLabel: true,
        value: journalEntry?.cervical_fluid,
      });
    }

    if (journalEntry?.cervical_position) {
      tableRows.push({
        label: 'common.dailyData.cervicalPosition',
        translateLabel: true,
        value: journalEntry?.cervical_position,
      });
    }

    if (journalEntry?.pain) {
      tableRows.push({
        label: 'common.dailyData.pain',
        translateLabel: true,
        value: journalEntry?.pain,
      });
    }

    if (journalEntry?.cravings) {
      tableRows.push({
        label: 'common.dailyData.cravings',
        translateLabel: true,
        value: journalEntry?.cravings,
      });
    }

    if (journalEntry?.digestion) {
      tableRows.push({
        label: 'common.dailyData.digestion',
        translateLabel: true,
        value: journalEntry?.digestion,
      });
    }

    if (journalEntry?.bloating) {
      tableRows.push({
        label: 'common.dailyData.bloating',
        translateLabel: true,
        value: journalEntry?.bloating,
      });
    }

    if (journalEntry?.head_hair) {
      tableRows.push({
        label: 'common.dailyData.headHair',
        translateLabel: true,
        value: journalEntry?.head_hair,
      });
    }

    if (journalEntry?.skin) {
      tableRows.push({
        label: 'common.dailyData.skin',
        translateLabel: true,
        value: journalEntry?.skin,
      });
    }

    if (journalEntry?.stool) {
      tableRows.push({
        label: 'common.dailyData.stool',
        translateLabel: true,
        value: journalEntry?.stool,
      });
    }

    if (journalEntry?.temperature) {
      tableRows.push({
        label: 'common.dailyData.temperature',
        translateLabel: true,
        value: journalEntry?.temperature,
      });
    }

    if (journalEntry?.injection) {
      tableRows.push({
        label: 'common.dailyData.injection',
        translateLabel: true,
        value: journalEntry?.injection,
      });
    }

    if (spikeInfo?.resting_hr) {
      tableRows.push({
        label: 'common.dailyData.spikeInfo.restingHr',
        translateLabel: true,
        value: spikeInfo?.resting_hr,
        prettifiedValue: `${spikeInfo?.resting_hr} ${Constants.HEART_RATE_UNIT}`,
      });
    }

    if (spikeInfo?.avg_hr) {
      tableRows.push({
        label: 'common.dailyData.spikeInfo.avgHr',
        translateLabel: true,
        value: spikeInfo?.avg_hr,
        prettifiedValue: `${spikeInfo?.avg_hr} ${Constants.HEART_RATE_UNIT}`,
      });
    }

    if (spikeInfo?.floors) {
      tableRows.push({
        label: 'common.dailyData.spikeInfo.floors',
        translateLabel: true,
        value: spikeInfo?.floors,
      });
    }

    if (spikeInfo?.steps) {
      tableRows.push({
        label: 'common.dailyData.spikeInfo.steps',
        translateLabel: true,
        value: spikeInfo?.steps,
      });
    }

    if (spikeInfo?.calories_total) {
      tableRows.push({
        label: 'common.dailyData.spikeInfo.caloriesTotal',
        translateLabel: true,
        value: spikeInfo?.calories_total,
        prettifiedValue: `${spikeInfo?.calories_total} ${Constants.CALORIES_UNIT}`,
      });
    }

    return tableRows;
  }

  static getOverviewGroups(journalEntry?: JournalEntry): OverviewGroup[] {
    return [
      {
        title: 'common.dailyData.symptomDetail',
        rows: [
          {
            label: 'common.dailyData.isEverythingFine',
            value: journalEntry?.is_everything_fine,
            prettifiedValue: this.getPrettifiedBooleanValue(
              journalEntry?.is_everything_fine
            ),
            translateLabel: true,
          },
        ],
      },
      {
        title: 'common.dailyData.body',
        rows: [
          {
            label: 'common.dailyData.period',
            translateLabel: true,
            value: journalEntry?.period_flow,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.periodFlowValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.periodFlowValues.spotting'),
                  value: 'Spotting',
                },
                {
                  label: i18n.t('common.dailyData.periodFlowValues.light'),
                  value: 'Light',
                },
                {
                  label: i18n.t('common.dailyData.periodFlowValues.medium'),
                  value: 'Medium',
                },
                {
                  label: i18n.t('common.dailyData.periodFlowValues.heavy'),
                  value: 'Heavy',
                },
              ],
              propertyName: JournalEntryFields.period_flow,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.breakThroughBleeding',
            translateLabel: true,
            value: journalEntry?.breakthrough_bleeding,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.bleedingValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.bleedingValues.light'),
                  value: 'Light',
                },
                {
                  label: i18n.t('common.dailyData.bleedingValues.medium'),
                  value: 'Medium',
                },
                {
                  label: i18n.t('common.dailyData.bleedingValues.heavy'),
                  value: 'Heavy',
                },
              ],
              propertyName: JournalEntryFields.breakthrough_bleeding,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.cervicalFluid',
            translateLabel: true,
            value: journalEntry?.cervical_fluid,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.values.none'),
                  value: 'None',
                },
                {
                  label: i18n.t(
                    'common.dailyData.cervicalFluidValues.eggWhite'
                  ),
                  value: 'Egg-White',
                },
                {
                  label: i18n.t('common.dailyData.cervicalFluidValues.sticky'),
                  value: 'Sticky',
                },
                {
                  label: i18n.t('common.dailyData.cervicalFluidValues.creamy'),
                  value: 'Creamy',
                },
                {
                  label: i18n.t(
                    'common.dailyData.cervicalFluidValues.atypical'
                  ),
                  value: 'Atypical',
                },
              ],
              propertyName: JournalEntryFields.cervical_fluid,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.cervicalPosition',
            translateLabel: true,
            value: journalEntry?.cervical_position,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.cervicalPositionValues.low'),
                  value: 'Low',
                },
                {
                  label: i18n.t('common.dailyData.cervicalPositionValues.high'),
                  value: 'High',
                },
                {
                  label: i18n.t(
                    'common.dailyData.cervicalPositionValues.didNotCheck'
                  ),
                  value: "I didn't check",
                },
              ],
              propertyName: JournalEntryFields.cervical_position,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.hotFlashes',
            translateLabel: true,
            value: journalEntry?.hot_flashes,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.didntHaveAny'
                  ),
                  value: "I didn't have any",
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.hot_flashes,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.temperature',
            translateLabel: true,
            prettifiedValue: journalEntry?.temperature
              ? `${journalEntry?.temperature} °F`
              : '',
            value: journalEntry?.temperature,
            editOptions: {
              propertyName: JournalEntryFields.temperature,
              widgetType: TableRowEditWidgetType.NUMBER,
              min: 95.0,
              max: 105.9,
              step: 0.1,
            },
          },
          {
            label: 'common.dailyData.breastTenderness',
            translateLabel: true,
            value: journalEntry?.breast_tenderness,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.breast_tenderness,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.nausea',
            translateLabel: true,
            value: journalEntry?.nausea,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.nausea,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.cramps',
            translateLabel: true,
            value: journalEntry?.cramps,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.cramps,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.jointPain',
            translateLabel: true,
            value: journalEntry?.joint_pain,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.painOptions.noPain'),
                  value: 'No pain',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.joint_pain,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.backPain',
            translateLabel: true,
            value: journalEntry?.back_pain,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.painOptions.noPain'),
                  value: 'Not at all',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.back_pain,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.headache',
            translateLabel: true,
            value: journalEntry?.headache,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.headacheOptions.no'),
                  value: 'No',
                },
                {
                  label: i18n.t(
                    'common.dailyData.headacheOptions.mildHeadache'
                  ),
                  value: 'Mild Headache',
                },
                {
                  label: i18n.t(
                    'common.dailyData.headacheOptions.moderateHeadache'
                  ),
                  value: 'Moderate Headache',
                },
                {
                  label: i18n.t(
                    'common.dailyData.headacheOptions.severeHeadache'
                  ),
                  value: 'Severe Headache',
                },
              ],
              propertyName: JournalEntryFields.headache,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.migraine',
            translateLabel: true,
            value: journalEntry?.migraine,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.migraine,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.skinAndHair',
        rows: [
          {
            label: 'common.dailyData.headHair',
            translateLabel: true,
            value: journalEntry?.head_hair,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.headHairValues.noChanges'),
                  value: 'No changes',
                },
                {
                  label: i18n.t('common.dailyData.headHairValues.hairLoss'),
                  value: 'Hair loss',
                },
                {
                  label: i18n.t('common.dailyData.headHairValues.oily'),
                  value: 'Oily',
                },
                {
                  label: i18n.t('common.dailyData.headHairValues.dry'),
                  value: 'Dry',
                },
                {
                  label: i18n.t('common.dailyData.headHairValues.frizzy'),
                  value: 'Frizzy',
                },
                {
                  label: i18n.t('common.dailyData.headHairValues.smooth'),
                  value: 'Smooth',
                },
                {
                  label: i18n.t('common.dailyData.headHairValues.shiny'),
                  value: 'Shiny',
                },
              ],
              propertyName: JournalEntryFields.head_hair,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.bodyHairGrowth',
            translateLabel: true,
            value: journalEntry?.body_hair_growth,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.bodyHairGrowthValues.noChange'
                  ),
                  value: 'No change',
                },
                {
                  label: i18n.t(
                    'common.dailyData.bodyHairGrowthValues.hairLoss'
                  ),
                  value: 'hair loss',
                },
                {
                  label: i18n.t(
                    'common.dailyData.bodyHairGrowthValues.hairGrowth'
                  ),
                  value: 'hair growth',
                },
                {
                  label: i18n.t(
                    'common.dailyData.bodyHairGrowthValues.increasedThickness'
                  ),
                  value: 'increased thickness',
                },
                {
                  label: i18n.t(
                    'common.dailyData.bodyHairGrowthValues.hairInNewPlaces'
                  ),
                  value: 'hair in new places',
                },
              ],
              propertyName: JournalEntryFields.body_hair_growth,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.skinChanges',
            translateLabel: true,
            value: journalEntry?.skin_changes,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.skinChangeValues.glowing'),
                  value: 'Glowing',
                },
                {
                  label: i18n.t('common.dailyData.skinChangeValues.good'),
                  value: 'Good',
                },
                {
                  label: i18n.t('common.dailyData.skinChangeValues.smooth'),
                  value: 'Smooth',
                },
                {
                  label: i18n.t('common.dailyData.skinChangeValues.oily'),
                  value: 'Oily',
                },
                {
                  label: i18n.t('common.dailyData.skinChangeValues.dry'),
                  value: 'Dry',
                },
                {
                  label: i18n.t('common.dailyData.skinChangeValues.acne'),
                  value: 'Acne',
                },
                {
                  label: i18n.t('common.dailyData.skinChangeValues.itchy'),
                  value: 'Itchy',
                },
              ],
              propertyName: JournalEntryFields.skin_changes,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.acne',
            translateLabel: true,
            value: journalEntry?.acne,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.acneValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.acneValues.breakout'),
                  value: 'Breakout',
                },
                {
                  label: i18n.t('common.dailyData.acneValues.forehead'),
                  value: 'Forehead',
                },
                {
                  label: i18n.t('common.dailyData.acneValues.cheeks'),
                  value: 'Cheeks',
                },
                {
                  label: i18n.t('common.dailyData.acneValues.chin'),
                  value: 'Chin',
                },
                {
                  label: i18n.t('common.dailyData.acneValues.nose'),
                  value: 'Nose',
                },
                {
                  label: i18n.t('common.dailyData.acneValues.cystic'),
                  value: 'Cystic',
                },
              ],
              propertyName: JournalEntryFields.acne,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.dietAndDigestion',
        rows: [
          {
            label: 'common.dailyData.stool',
            translateLabel: true,
            value: journalEntry?.stool,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.stoolValues.normal'),
                  value: 'Normal',
                },
                {
                  label: i18n.t('common.dailyData.stoolValues.soft'),
                  value: 'Soft',
                },
                {
                  label: i18n.t('common.dailyData.stoolValues.constipated'),
                  value: 'Constipated',
                },
                {
                  label: i18n.t('common.dailyData.stoolValues.diarrhea'),
                  value: 'Diarrhea',
                },
                {
                  label: i18n.t('common.dailyData.stoolValues.painful'),
                  value: 'Painful',
                },
              ],
              propertyName: JournalEntryFields.stool,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.cravings',
            translateLabel: true,
            value: journalEntry?.cravings,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.cravingValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.cravingValues.sweet'),
                  value: 'Sweet',
                },
                {
                  label: i18n.t('common.dailyData.cravingValues.carbohydrates'),
                  value: 'Carbs',
                },
                {
                  label: i18n.t('common.dailyData.cravingValues.salty'),
                  value: 'Salty',
                },
                {
                  label: i18n.t('common.dailyData.cravingValues.greasyFood'),
                  value: 'Greasy Food',
                },
                {
                  label: i18n.t('common.dailyData.cravingValues.sour'),
                  value: 'Sour',
                },
                {
                  label: i18n.t('common.dailyData.cravingValues.spicy'),
                  value: 'Spicy',
                },
              ],
              propertyName: JournalEntryFields.cravings,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.digestion',
            translateLabel: true,
            value: journalEntry?.digestion,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.digestionValues.normal'),
                  value: 'Normal',
                },
                {
                  label: i18n.t('common.dailyData.digestionValues.indigestion'),
                  value: 'Indigestion',
                },
                {
                  label: i18n.t('common.dailyData.digestionValues.nauseated'),
                  value: 'Nauseated',
                },
                {
                  label: i18n.t('common.dailyData.digestionValues.gassy'),
                  value: 'Gassy',
                },
              ],
              propertyName: JournalEntryFields.digestion,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.bloating',
            translateLabel: true,
            value: journalEntry?.bloating,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.notAtAll'
                  ),
                  value: 'Not at all',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.bloating,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.urination',
        rows: [
          {
            label: 'common.dailyData.painfulUrination',
            translateLabel: true,
            value: journalEntry?.painful_urination,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.notAtAll'
                  ),
                  value: 'Not at all',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.painful_urination,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.urinationFrequency',
            translateLabel: true,
            value: journalEntry?.urination_frequency,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.urinationFrequencyValues.lessThanNormal'
                  ),
                  value: 'Less than normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.urinationFrequencyValues.normal'
                  ),
                  value: 'Normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.urinationFrequencyValues.moreThanNormal'
                  ),
                  value: 'More than normal',
                },
              ],
              propertyName: JournalEntryFields.urination_frequency,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.incontinence',
            translateLabel: true,
            value: journalEntry?.incontinence,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.incontinence,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.sex',
        rows: [
          {
            label: 'common.dailyData.sexualActivity',
            translateLabel: true,
            value: journalEntry?.sexual_activity,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.sexValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.sexValues.unprotected'),
                  value: 'Unprotected',
                },
                {
                  label: i18n.t('common.dailyData.sexValues.protected'),
                  value: 'Protected',
                },
                {
                  label: i18n.t('common.dailyData.sexValues.withdrawal'),
                  value: 'Withdrawal',
                },
              ],
              propertyName: JournalEntryFields.sexual_activity,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.painfulSex',
            translateLabel: true,
            value: journalEntry?.painful_sex,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.painfulSexValues.iDidntHaveSex'
                  ),
                  value: "I didn't have sex",
                },
                {
                  label: i18n.t('common.dailyData.painfulSexValues.noPain'),
                  value: 'No pain',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.painful_sex,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.sexDrive',
            translateLabel: true,
            value: journalEntry?.sex_drive,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.sexDriveValues.lowerThanNormal'
                  ),
                  value: 'Lower than normal',
                },
                {
                  label: i18n.t('common.dailyData.sexDriveValues.normal'),
                  value: 'Normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.sexDriveValues.higherThanNormal'
                  ),
                  value: 'Higher than normal',
                },
              ],
              propertyName: JournalEntryFields.sex_drive,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.exercise',
        rows: [
          {
            label: 'common.dailyData.exerciseIntesity',
            translateLabel: true,
            value: journalEntry?.exercise_intensity,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.exerciseValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.exerciseValues.lowImpact'),
                  value: 'Low-Impact',
                },
                {
                  label: i18n.t(
                    'common.dailyData.exerciseValues.moderateImpact'
                  ),
                  value: 'Moderate-Impact',
                },
                {
                  label: i18n.t('common.dailyData.exerciseValues.highImpact'),
                  value: 'High-Impact',
                },
              ],
              propertyName: JournalEntryFields.exercise_intensity,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.exercisePerceivedEffort',
            translateLabel: true,
            value: journalEntry?.exercise_perceived_effort,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.exercisePerceivedEffortValues.easierThanNormal'
                  ),
                  value: 'Easier than normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.exercisePerceivedEffortValues.normal'
                  ),
                  value: 'normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.exercisePerceivedEffortValues.harderThanNormal'
                  ),
                  value: 'Harder than normal',
                },
              ],
              propertyName: JournalEntryFields.exercise_perceived_effort,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.sleep',
        rows: [
          {
            label: 'common.dailyData.hoursOfSleep',
            translateLabel: true,
            value: journalEntry?.hours_of_sleep,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.sleepValues.zeroMore'),
                  value: '0-3 hrs',
                },
                {
                  label: i18n.t('common.dailyData.sleepValues.threeMore'),
                  value: '3-6 hrs',
                },
                {
                  label: i18n.t('common.dailyData.sleepValues.sixMore'),
                  value: '6-9 hrs',
                },
                {
                  label: i18n.t('common.dailyData.sleepValues.nineMore'),
                  value: '9+ hrs',
                },
              ],
              propertyName: JournalEntryFields.hours_of_sleep,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.sleepQuality',
            translateLabel: true,
            value: journalEntry?.sleep_quality,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.sleepQualityValues.troubleFallingAsleep'
                  ),
                  value: 'Trouble falling asleep',
                },
                {
                  label: i18n.t(
                    'common.dailyData.sleepQualityValues.troubleStayingAsleep'
                  ),
                  value: 'trouble staying asleep',
                },
                {
                  label: i18n.t('common.dailyData.sleepQualityValues.restless'),
                  value: 'restless',
                },
                {
                  label: i18n.t(
                    'common.dailyData.sleepQualityValues.goodQuality'
                  ),
                  value: 'good quality',
                },
              ],
              propertyName: JournalEntryFields.sleep_quality,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.dreams',
            translateLabel: true,
            value: journalEntry?.dreams,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.dreamsValues.noDreams'),
                  value: 'No Dreams',
                },
                {
                  label: i18n.t('common.dailyData.dreamsValues.dark'),
                  value: 'Dark',
                },
                {
                  label: i18n.t('common.dailyData.dreamsValues.disturbing'),
                  value: 'Disturbing',
                },
                {
                  label: i18n.t('common.dailyData.dreamsValues.stressful'),
                  value: 'stressful',
                },
                {
                  label: i18n.t('common.dailyData.dreamsValues.vivid'),
                  value: 'vivid',
                },
                {
                  label: i18n.t('common.dailyData.dreamsValues.soothing'),
                  value: 'soothing',
                },
                {
                  label: i18n.t('common.dailyData.dreamsValues.happy'),
                  value: 'happy',
                },
              ],
              propertyName: JournalEntryFields.dreams,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.mentalWellbeing',
        rows: [
          {
            label: 'common.dailyData.mood',
            translateLabel: true,
            value: journalEntry?.mood,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.moodValues.veryNegativeMood'),
                  value: 'Very negative',
                },
                {
                  label: i18n.t('common.dailyData.moodValues.negativeMood'),
                  value: 'Negative',
                },
                {
                  label: i18n.t('common.dailyData.moodValues.averageMood'),
                  value: 'Average',
                },
                {
                  label: i18n.t('common.dailyData.moodValues.positiveMood'),
                  value: 'Positive',
                },
                {
                  label: i18n.t('common.dailyData.moodValues.veryPositiveMood'),
                  value: 'Very positive',
                },
              ],
              propertyName: JournalEntryFields.mood,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.anxiety',
            translateLabel: true,
            value: journalEntry?.anxiety,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.notAtAll'
                  ),
                  value: 'Not at all',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.anxiety,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.motivation',
            translateLabel: true,
            value: journalEntry?.motivation,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.motivationValues.betterThanNormal'
                  ),
                  value: 'Better than normal',
                },
                {
                  label: i18n.t('common.dailyData.motivationValues.normal'),
                  value: 'Normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.motivationValues.worseThanNormal'
                  ),
                  value: 'Worse than normal',
                },
              ],
              propertyName: JournalEntryFields.motivation,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.productivity',
            translateLabel: true,
            value: journalEntry?.productivity,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.productivityValues.betterThanNormal'
                  ),
                  value: 'Better than normal',
                },
                {
                  label: i18n.t('common.dailyData.productivityValues.normal'),
                  value: 'Normal',
                },
                {
                  label: i18n.t(
                    'common.dailyData.productivityValues.worseThanNormal'
                  ),
                  value: 'Worse than normal',
                },
              ],
              propertyName: JournalEntryFields.productivity,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.focus',
            translateLabel: true,
            value: journalEntry?.focus,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.focusValues.betterThanNormal'
                  ),
                  value: 'Better than normal',
                },
                {
                  label: i18n.t('common.dailyData.focusValues.normal'),
                  value: 'Normal',
                },
                {
                  label: i18n.t('common.dailyData.focusValues.worseThanNormal'),
                  value: 'Worse than normal',
                },
              ],
              propertyName: JournalEntryFields.focus,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.brainFog',
            translateLabel: true,
            value: journalEntry?.brain_fog,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.brainFogValues.noBrainFog'),
                  value: 'No braing fog',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.brain_fog,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.memoryProblems',
            translateLabel: true,
            value: journalEntry?.memory_problems,
            editOptions: {
              options: [
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.notAtAll'
                  ),
                  value: 'Not at all',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.mild'),
                  value: 'Mild',
                },
                {
                  label: i18n.t(
                    'common.dailyData.commonSymptomValues.moderate'
                  ),
                  value: 'Moderate',
                },
                {
                  label: i18n.t('common.dailyData.commonSymptomValues.severe'),
                  value: 'Severe',
                },
              ],
              propertyName: JournalEntryFields.memory_problems,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.energy',
            translateLabel: true,
            value: journalEntry?.energy,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.energyValues.exhausted'),
                  value: 'Exhausted',
                },
                {
                  label: i18n.t('common.dailyData.energyValues.lowEnergy'),
                  value: 'Low energy',
                },
                {
                  label: i18n.t('common.dailyData.energyValues.highEnergy'),
                  value: 'High energy',
                },
              ],
              propertyName: JournalEntryFields.energy,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.moodStability',
            translateLabel: true,
            value: journalEntry?.mood_stability,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.moodStabilityValues.stable'),
                  value: 'Stable',
                },
                {
                  label: i18n.t(
                    'common.dailyData.moodStabilityValues.somewhatUnstable'
                  ),
                  value: 'Somewhat Unstable',
                },
                {
                  label: i18n.t(
                    'common.dailyData.moodStabilityValues.veryUnstable'
                  ),
                  value: 'Very Unstable',
                },
              ],
              propertyName: JournalEntryFields.mood_stability,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.stress',
            translateLabel: true,
            value: journalEntry?.stress,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.stressValues.noStress'),
                  value: 'No stress',
                },
                {
                  label: i18n.t('common.dailyData.stressValues.low'),
                  value: 'Low',
                },
                {
                  label: i18n.t('common.dailyData.stressValues.average'),
                  value: 'Average',
                },
                {
                  label: i18n.t('common.dailyData.stressValues.high'),
                  value: 'High',
                },
              ],
              propertyName: JournalEntryFields.stress,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.medical',
        rows: [
          {
            label: 'common.dailyData.birthControl',
            translateLabel: true,
            value: journalEntry?.birth_control_pill,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.birthControlValues.taken'),
                  value: 'Taken',
                },
                {
                  label: i18n.t('common.dailyData.birthControlValues.late'),
                  value: 'Late',
                },
                {
                  label: i18n.t('common.dailyData.birthControlValues.missed'),
                  value: 'Missed',
                },
                {
                  label: i18n.t('common.dailyData.birthControlValues.double'),
                  value: 'Double',
                },
              ],
              propertyName: JournalEntryFields.birth_control_pill,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.medication',
            translateLabel: true,
            value: journalEntry?.medication,
            editOptions: {
              propertyName: JournalEntryFields.medication,
              widgetType: TableRowEditWidgetType.TEXT_AREA,
            },
          },
          {
            label: 'common.dailyData.injection',
            translateLabel: true,
            value: journalEntry?.injection,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.injectionValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t(
                    'common.dailyData.injectionValues.administered'
                  ),
                  value: 'Administered',
                },
                {
                  label: i18n.t('common.dailyData.injectionValues.missed'),
                  value: 'Missed',
                },
              ],
              propertyName: JournalEntryFields.injection,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.ailment',
            translateLabel: true,
            value: journalEntry?.ailment,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.ailmentValues.none'),
                  value: 'None',
                },
                {
                  label: i18n.t('common.dailyData.ailmentValues.fever'),
                  value: 'Fever',
                },
                {
                  label: i18n.t('common.dailyData.ailmentValues.covid'),
                  value: 'COVID',
                },
                {
                  label: i18n.t('common.dailyData.ailmentValues.coldOrFlu'),
                  value: 'Cold/Flu',
                },
                {
                  label: i18n.t('common.dailyData.ailmentValues.allergy'),
                  value: 'Allergy',
                },
                {
                  label: i18n.t('common.dailyData.ailmentValues.injury'),
                  value: 'Injury',
                },
              ],
              propertyName: JournalEntryFields.ailment,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            },
          },
          {
            label: 'common.dailyData.vitaminsAndSupplements',
            translateLabel: true,
            value: journalEntry?.vitamins_and_supplements,
            editOptions: {
              propertyName: JournalEntryFields.vitamins_and_supplements,
              widgetType: TableRowEditWidgetType.TEXT_AREA,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.social',
        rows: [
          {
            label: 'common.dailyData.drinking',
            translateLabel: true,
            value: journalEntry?.drinking,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.drinkingValues.zeroDrinks'),
                  value: '0 drinks',
                },
                {
                  label: i18n.t('common.dailyData.drinkingValues.oneDrink'),
                  value: '1 drink',
                },
                {
                  label: i18n.t('common.dailyData.drinkingValues.twoDrinks'),
                  value: '2 drinks',
                },
                {
                  label: i18n.t('common.dailyData.drinkingValues.threeDrinks'),
                  value: '3 drinks',
                },
                {
                  label: i18n.t(
                    'common.dailyData.drinkingValues.fourPlusDrinks'
                  ),
                  value: '4+ drinks',
                },
              ],
              propertyName: JournalEntryFields.drinking,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.cannabis',
            translateLabel: true,
            value: journalEntry?.cannabis,
            editOptions: {
              options: [
                {
                  label: i18n.t('common.dailyData.cannabisValues.no'),
                  value: 'No',
                },
                {
                  label: i18n.t('common.dailyData.cannabisValues.edible'),
                  value: 'Edible',
                },
                {
                  label: i18n.t('common.dailyData.cannabisValues.smoke'),
                  value: 'Smoke',
                },
                {
                  label: i18n.t('common.dailyData.cannabisValues.vape'),
                  value: 'Vape',
                },
              ],
              propertyName: JournalEntryFields.cannabis,
              widgetType: TableRowEditWidgetType.SELECT,
            },
          },
          {
            label: 'common.dailyData.hangover',
            value: journalEntry?.hangover,
            prettifiedValue: this.getPrettifiedBooleanValue(
              journalEntry?.hangover
            ),
            translateLabel: true,
            editOptions: {
              propertyName: JournalEntryFields.hangover,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            },
          },
        ],
      },
      {
        title: 'common.dailyData.notes',
        rows: [
          {
            label: 'common.dailyData.notes',
            translateLabel: true,
            value: journalEntry?.notes,
          },
        ],
      },
      {
        title: 'common.dailyData.meta',
        rows: [
          {
            label: 'common.os',
            translateLabel: true,
            value: journalEntry?.meta?.os,
          },
          {
            label: 'common.osVersion',
            translateLabel: true,
            value: journalEntry?.meta?.os_version,
          },
          {
            label: 'common.appVersion',
            translateLabel: true,
            value: journalEntry?.meta?.app_version,
          },
          {
            label: 'common.deviceModel',
            translateLabel: true,
            value: journalEntry?.meta?.device_model,
          },
          {
            label: 'common.timeZoneAbbr',
            translateLabel: true,
            value: journalEntry?.meta?.time_zone_abbreviation,
          },
          {
            label: 'common.timeZoneId',
            translateLabel: true,
            value: journalEntry?.meta?.time_zone_identifier,
          },
        ],
      },
    ];
  }

  static getReadonlyOverviewGroups(
    journalEntry?: JournalEntry
  ): OverviewGroup[] {
    return [
      {
        title: 'common.dailyData.symptomDetail',
        rows: [
          {
            label: 'common.dailyData.isEverythingFine',
            value: journalEntry?.is_everything_fine,
            prettifiedValue: this.getPrettifiedBooleanValue(
              journalEntry?.is_everything_fine
            ),
            translateLabel: true,
          },
        ],
      },
      {
        title: 'common.dailyData.body',
        rows: [
          {
            label: 'common.dailyData.period',
            translateLabel: true,
            value: journalEntry?.period_flow,
          },
          {
            label: 'common.dailyData.breakThroughBleeding',
            translateLabel: true,
            value: journalEntry?.breakthrough_bleeding,
          },
          {
            label: 'common.dailyData.cervicalFluid',
            translateLabel: true,
            value: journalEntry?.cervical_fluid,
          },
          {
            label: 'common.dailyData.cervicalPosition',
            translateLabel: true,
            value: journalEntry?.cervical_position,
          },
          {
            label: 'common.dailyData.hotFlashes',
            translateLabel: true,
            value: journalEntry?.hot_flashes,
          },
          {
            label: 'common.dailyData.temperature',
            translateLabel: true,
            prettifiedValue: journalEntry?.temperature
              ? `${journalEntry?.temperature} °F`
              : '',
            value: journalEntry?.temperature,
          },
          {
            label: 'common.dailyData.breastTenderness',
            translateLabel: true,
            value: journalEntry?.breast_tenderness,
          },
          {
            label: 'common.dailyData.nausea',
            translateLabel: true,
            value: journalEntry?.nausea,
          },
          {
            label: 'common.dailyData.cramps',
            translateLabel: true,
            value: journalEntry?.cramps,
          },
          {
            label: 'common.dailyData.jointPain',
            translateLabel: true,
            value: journalEntry?.joint_pain,
          },
          {
            label: 'common.dailyData.backPain',
            translateLabel: true,
            value: journalEntry?.back_pain,
          },
          {
            label: 'common.dailyData.headache',
            translateLabel: true,
            value: journalEntry?.headache,
          },
          {
            label: 'common.dailyData.migraine',
            translateLabel: true,
            value: journalEntry?.migraine,
          },
        ],
      },
      {
        title: 'common.dailyData.skinAndHair',
        rows: [
          {
            label: 'common.dailyData.headHair',
            translateLabel: true,
            value: journalEntry?.head_hair,
          },
          {
            label: 'common.dailyData.bodyHairGrowth',
            translateLabel: true,
            value: journalEntry?.body_hair_growth,
          },
          {
            label: 'common.dailyData.skinChanges',
            translateLabel: true,
            value: journalEntry?.skin_changes,
          },
          {
            label: 'common.dailyData.acne',
            translateLabel: true,
            value: journalEntry?.acne,
          },
        ],
      },
      {
        title: 'common.dailyData.dietAndDigestion',
        rows: [
          {
            label: 'common.dailyData.stool',
            translateLabel: true,
            value: journalEntry?.stool,
          },
          {
            label: 'common.dailyData.cravings',
            translateLabel: true,
            value: journalEntry?.cravings,
          },
          {
            label: 'common.dailyData.digestion',
            translateLabel: true,
            value: journalEntry?.digestion,
          },
          {
            label: 'common.dailyData.bloating',
            translateLabel: true,
            value: journalEntry?.bloating,
          },
        ],
      },
      {
        title: 'common.dailyData.urination',
        rows: [
          {
            label: 'common.dailyData.painfulUrination',
            translateLabel: true,
            value: journalEntry?.painful_urination,
          },
          {
            label: 'common.dailyData.urinationFrequency',
            translateLabel: true,
            value: journalEntry?.urination_frequency,
          },
          {
            label: 'common.dailyData.incontinence',
            translateLabel: true,
            value: journalEntry?.incontinence,
          },
        ],
      },
      {
        title: 'common.dailyData.sex',
        rows: [
          {
            label: 'common.dailyData.sexualActivity',
            translateLabel: true,
            value: journalEntry?.sexual_activity,
          },
          {
            label: 'common.dailyData.painfulSex',
            translateLabel: true,
            value: journalEntry?.painful_sex,
          },
          {
            label: 'common.dailyData.sexDrive',
            translateLabel: true,
            value: journalEntry?.sex_drive,
          },
        ],
      },
      {
        title: 'common.dailData.exercise',
        rows: [
          {
            label: 'common.dailyData.exerciseIntesity',
            translateLabel: true,
            value: journalEntry?.exercise_intensity,
          },
          {
            label: 'common.dailyData.exercisePerceivedEffort',
            translateLabel: true,
            value: journalEntry?.exercise_perceived_effort,
          },
        ],
      },
      {
        title: 'common.dailyData.sleep',
        rows: [
          {
            label: 'common.dailyData.hoursOfSleep',
            translateLabel: true,
            value: journalEntry?.hours_of_sleep,
          },
          {
            label: 'common.dailyData.sleepQuality',
            translateLabel: true,
            value: journalEntry?.sleep_quality,
          },
          {
            label: 'common.dailyData.dreams',
            translateLabel: true,
            value: journalEntry?.dreams,
          },
        ],
      },
      {
        title: 'common.dailData.mentalWellbeing',
        rows: [
          {
            label: 'common.dailyData.mood',
            translateLabel: true,
            value: journalEntry?.mood,
          },
          {
            label: 'common.dailyData.anxiety',
            translateLabel: true,
            value: journalEntry?.anxiety,
          },
          {
            label: 'common.dailyData.motivation',
            translateLabel: true,
            value: journalEntry?.motivation,
          },
          {
            label: 'common.dailyData.productivity',
            translateLabel: true,
            value: journalEntry?.productivity,
          },
          {
            label: 'common.dailyData.focus',
            translateLabel: true,
            value: journalEntry?.focus,
          },
          {
            label: 'common.dailyData.brainFog',
            translateLabel: true,
            value: journalEntry?.brain_fog,
          },
          {
            label: 'common.dailyData.memoryProblems',
            translateLabel: true,
            value: journalEntry?.memory_problems,
          },
          {
            label: 'common.dailyData.energy',
            translateLabel: true,
            value: journalEntry?.energy,
          },
          {
            label: 'common.dailyData.moodStability',
            translateLabel: true,
            value: journalEntry?.mood_stability,
          },
          {
            label: 'common.dailyData.stress',
            translateLabel: true,
            value: journalEntry?.stress,
          },
        ],
      },
      {
        title: 'common.dailyData.medical',
        rows: [
          {
            label: 'common.dailyData.birthControl',
            translateLabel: true,
            value: journalEntry?.birth_control_pill,
          },
          {
            label: 'common.dailyData.medication',
            translateLabel: true,
            value: journalEntry?.medication,
          },
          {
            label: 'common.dailyData.injection',
            translateLabel: true,
            value: journalEntry?.injection,
          },
          {
            label: 'common.dailyData.ailment',
            translateLabel: true,
            value: journalEntry?.ailment,
          },
          {
            label: 'common.dailyData.vitaminsAndSupplements',
            translateLabel: true,
            value: journalEntry?.vitamins_and_supplements,
          },
        ],
      },
      {
        title: 'common.dailyData.social',
        rows: [
          {
            label: 'common.dailyData.drinking',
            translateLabel: true,
            value: journalEntry?.drinking,
          },
          {
            label: 'common.dailyData.cannabis',
            translateLabel: true,
            value: journalEntry?.cannabis,
          },
          {
            label: 'common.dailyData.hangover',
            value: journalEntry?.hangover,
            prettifiedValue: this.getPrettifiedBooleanValue(
              !!journalEntry?.hangover
            ),
            translateLabel: true,
          },
        ],
      },
      {
        title: 'common.dailyData.notes',
        rows: [
          {
            label: 'common.dailyData.notes',
            translateLabel: true,
            value: journalEntry?.notes,
          },
        ],
      },
      {
        title: 'common.dailyData.meta',
        rows: [
          {
            label: 'common.os',
            translateLabel: true,
            value: journalEntry?.meta?.os,
          },
          {
            label: 'common.osVersion',
            translateLabel: true,
            value: journalEntry?.meta?.os_version,
          },
          {
            label: 'common.appVersion',
            translateLabel: true,
            value: journalEntry?.meta?.app_version,
          },
          {
            label: 'common.deviceModel',
            translateLabel: true,
            value: journalEntry?.meta?.device_model,
          },
          {
            label: 'common.timeZoneAbbr',
            translateLabel: true,
            value: journalEntry?.meta?.time_zone_abbreviation,
          },
          {
            label: 'common.timeZoneId',
            translateLabel: true,
            value: journalEntry?.meta?.time_zone_identifier,
          },
        ],
      },
    ];
  }

  static mapSpikeInfoToTable(spikeInfo: SpikeInfo): OvTableData {
    return [
      {
        label: 'common.dailyData.spikeInfo.restingHr',
        translateLabel: true,
        value: spikeInfo?.resting_hr,
        prettifiedValue: spikeInfo?.resting_hr
          ? `${spikeInfo?.resting_hr} ${Constants.HEART_RATE_UNIT}`
          : '-',
      },
      {
        label: 'common.dailyData.spikeInfo.avgHr',
        translateLabel: true,
        value: spikeInfo?.avg_hr,
        prettifiedValue: spikeInfo?.avg_hr
          ? `${spikeInfo?.avg_hr} ${Constants.HEART_RATE_UNIT}`
          : '-',
      },
      {
        label: 'common.dailyData.spikeInfo.floors',
        translateLabel: true,
        value: spikeInfo?.floors,
      },
      {
        label: 'common.dailyData.spikeInfo.steps',
        translateLabel: true,
        value: spikeInfo?.steps,
      },
      {
        label: 'common.dailyData.spikeInfo.caloriesTotal',
        translateLabel: true,
        value: spikeInfo?.calories_total,
        prettifiedValue: spikeInfo?.calories_total
          ? `${spikeInfo?.calories_total} ${Constants.CALORIES_UNIT}`
          : '-',
      },
    ];
  }

  static prettifyValue(
    value: number,
    maxValue: number,
    unitOfMeasure: string
  ): string {
    if (value >= maxValue) {
      return `> ${maxValue.toFixed(2)} ${unitOfMeasure}`;
    }

    const normalizedValue: number = MathUtils.normalizeValue(value);

    return `${normalizedValue.toFixed(2)} ${unitOfMeasure}`;
  }

  static getPrettifiedBooleanValue(value?: boolean) {
    if (value === null || value === undefined) {
      return '-';
    }

    return value ? i18n.t('common.actions.yes') : i18n.t('common.actions.no');
  }
}
