import { AlertColor, Dialog, DialogContent } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import { UserInfo } from '../../../common/model/dto/user-info';
import { UserAccountStatus } from '../../../common/model/dto/user-account-status.enum';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Colours from '../../../design-system/colours';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WarningIcon from '@mui/icons-material/Warning';
import { ClinicLocationProvider } from '../../../common/model/dto/clinic-location-provider';
import { ApprovementStatus } from '../../../common/model/dto/approvement-status.enum';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  approveProviderInvitationForUser,
  getApproveInvitationToken,
  getSetPasswordToken,
  refreshApproveInvitationToken,
  refreshSetPasswordToken,
} from '../../../redux/thunks/admin/admin-clinic-location.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import DoneIcon from '@mui/icons-material/Done';
import { getBaseUrl } from '../../../common/utils/services/helpers';
import {
  clearCurrentApproveInvitationToken,
  clearCurrentSetPasswordToken,
} from '../../../redux/reducers/admin-clinic-location.slice';
import OvConfirmationDialog from './OvConfirmationDialog';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTranslation } from 'react-i18next';
import OvProviderClinicLocationStatus from './OvProviderClinicLocationStatus';
import OvProviderClinicLocationAction from './OvProviderClinicLocationAction';
import { differenceInDays, startOfDay } from 'date-fns';
import PasswordIcon from '@mui/icons-material/Password';
import { AdminSendgridService } from '../../../services/admin/admin-sendgrid.service';
import OvSnackbar from './OvSnackbar';

export interface OvProviderAdminActionsDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  user: UserInfo;
  clinicLocationId?: string;
  clinicId?: string;
}

const OvProviderAdminActionsDialog: FC<OvProviderAdminActionsDialogProps> = ({
  isOpen,
  onCancel,
  user,
  clinicLocationId,
  clinicId,
}) => {
  const { t } = useTranslation();
  const history: History = useHistory();
  const [accountStatus, setAccountStatus] = useState<
    UserAccountStatus | undefined
  >(undefined);
  const [currentClinicLocationProvider, setCurrentClinicLocationProvider] =
    useState<ClinicLocationProvider | undefined>(undefined);
  const [approvementStatus, setApprovementStatus] = useState<
    ApprovementStatus | undefined
  >(undefined);
  const [
    isSetPasswordLinkCopiedToClipboard,
    setIsSetPasswordLinkCopiedToClipboard,
  ] = useState(false);
  const [
    isApproveInvitationLinkCopiedToClipboard,
    setIsApproveInvitationLinkCopiedToClipboard,
  ] = useState(false);
  const [isOpenApproveInvitationDialog, setIsOpenApproveInvitationDialog] =
    useState<boolean>(false);
  const [isOpenResendSetPasswordEmail, setIsOpenResendSetPasswordEmail] =
    useState<boolean>(false);
  const [
    isOpenResendApproveInvitationEmail,
    setIsOpenResendApproveInvitationEmail,
  ] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [messageSendingLoading, setMessageSendingLoading] = useState(false);
  const dispatch = useAppDispatch();
  const currentApproveInvitationToken: string = useAppSelector(
    (state) => state.adminClinicLocation.currentApproveInvitationToken
  );
  const currentSetPasswordToken: string = useAppSelector(
    (state) => state.adminClinicLocation.currentSetPasswordToken
  );
  const isAdminClinicDataLoading: boolean = useAppSelector(
    (state) => state.adminClinicLocation.isLoading
  );

  const isLoading = messageSendingLoading || isAdminClinicDataLoading;

  useEffect(() => {
    const accountStatus = getAccountStatus();
    setAccountStatus(accountStatus);

    const currentClinicLocationProvider = user?.provider_clinic_locations?.find(
      (providerClinicLocation) =>
        providerClinicLocation.clinic_location.id === clinicLocationId
    );

    if (currentClinicLocationProvider) {
      setCurrentClinicLocationProvider(currentClinicLocationProvider);
      const currentApprovementStatus = getApprovementStatus(
        currentClinicLocationProvider
      );

      setApprovementStatus(currentApprovementStatus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, user]);

  useEffect(() => {
    if (approvementStatus === ApprovementStatus.PENDING && isOpen) {
      dispatch(getApproveInvitationToken(currentClinicLocationProvider?.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, approvementStatus]);

  useEffect(() => {
    if (accountStatus === UserAccountStatus.ONBOARDING && isOpen) {
      dispatch(getSetPasswordToken(user.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, accountStatus]);

  const getAccountStatus = () => {
    if (!!user.document_id) {
      return UserAccountStatus.ACTIVE;
    }

    if (
      !user.document_id &&
      !!user.set_password_token_expiry_date &&
      new Date(user.set_password_token_expiry_date) > new Date()
    ) {
      return UserAccountStatus.ONBOARDING;
    }

    if (
      !user.document_id &&
      !!user.set_password_token_expiry_date &&
      new Date(user.set_password_token_expiry_date) < new Date()
    ) {
      return UserAccountStatus.SET_PASSWORD_TOKEN_EXPIRED;
    }
  };

  const getApprovementStatus = (
    clinicLocationProvider: ClinicLocationProvider
  ) => {
    if (clinicLocationProvider.status === ApprovementStatus.ACCEPTED) {
      return ApprovementStatus.ACCEPTED;
    }

    if (
      clinicLocationProvider.status === ApprovementStatus.PENDING &&
      clinicLocationProvider.approve_invitation_token_expiration_date &&
      new Date(
        clinicLocationProvider.approve_invitation_token_expiration_date
      ) > new Date()
    ) {
      return ApprovementStatus.PENDING;
    }

    return ApprovementStatus.TOKEN_EXPIRED;
  };

  const copySetPasswordLinkToClipboard = () => {
    navigator.clipboard.writeText(
      `${getBaseUrl()}/set-password/${currentSetPasswordToken}`
    );

    setIsSetPasswordLinkCopiedToClipboard(true);

    setTimeout(() => {
      setIsSetPasswordLinkCopiedToClipboard(false);
    }, 3000);
  };

  const copyApproveInvitationLinkToClipboard = () => {
    navigator.clipboard.writeText(
      `${getBaseUrl()}/providers/approve/${currentApproveInvitationToken}`
    );

    setIsApproveInvitationLinkCopiedToClipboard(true);

    setTimeout(() => {
      setIsApproveInvitationLinkCopiedToClipboard(false);
    }, 3000);
  };

  const handleRefreshSetPasswordToken = () => {
    dispatch(refreshSetPasswordToken(user.id));
  };

  const handleRefreshApproveInvitationToken = () => {
    if (currentClinicLocationProvider?.id) {
      dispatch(
        refreshApproveInvitationToken(currentClinicLocationProvider?.id)
      );
    }
  };

  const handleSendSetPasswordEmail = async () => {
    if (currentClinicLocationProvider?.clinic_location?.clinic_location_id) {
      setIsOpenResendSetPasswordEmail(false);

      try {
        setMessageSendingLoading(true);
        await AdminSendgridService.sendSetPasswordEmail(
          user.email,
          currentSetPasswordToken,
          `${user.first_name} ${user.last_name}`,
          currentClinicLocationProvider?.clinic_location?.clinic_location_id
        );
        setMessageSendingLoading(false);

        setSnackbarProps(
          'success',
          t('userDetails.actions.emailSentSuccessfully'),
          true
        );
      } catch (error) {
        setMessageSendingLoading(false);

        setSnackbarProps(
          'error',
          t('userDetails.actions.emailSendingFailed'),
          true
        );
      }
    }
  };

  const handleSendApproveInvitationEmail = async () => {
    if (currentClinicLocationProvider?.clinic_location?.name) {
      setIsOpenResendApproveInvitationEmail(false);

      try {
        setMessageSendingLoading(true);
        await AdminSendgridService.sendApproveInvitationEmail(
          user.email,
          currentApproveInvitationToken,
          `${user.first_name} ${user.last_name}`,
          currentClinicLocationProvider?.clinic_location?.name
        );
        setMessageSendingLoading(false);

        setSnackbarProps(
          'success',
          t('userDetails.actions.emailSentSuccessfully'),
          true
        );
      } catch (error) {
        setMessageSendingLoading(false);

        setSnackbarProps(
          'error',
          t('userDetails.actions.emailSendingFailed'),
          true
        );
      }
    }
  };

  const handleApproveInvitation = () => {
    setIsOpenApproveInvitationDialog(false);

    if (clinicLocationId && clinicId) {
      dispatch(
        approveProviderInvitationForUser({
          history,
          clinicLocationId,
          clinicId,
          userId: user.id,
        })
      );
    }
  };

  const getDaysUntilSetPasswordTokenExpires = () => {
    if (user.set_password_token_expiry_date) {
      return Math.abs(
        differenceInDays(
          startOfDay(new Date(user.set_password_token_expiry_date)),
          startOfDay(new Date())
        )
      );
    }
  };

  const getDaysUntilApproveInvitationTokenExpires = () => {
    if (
      currentClinicLocationProvider?.approve_invitation_token_expiration_date
    ) {
      return Math.abs(
        differenceInDays(
          startOfDay(
            new Date(
              currentClinicLocationProvider?.approve_invitation_token_expiration_date
            )
          ),
          startOfDay(new Date())
        )
      );
    }
  };

  const handleOnClose = () => {
    dispatch(clearCurrentApproveInvitationToken());
    dispatch(clearCurrentSetPasswordToken());
    onCancel();
  };

  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: boolean
  ) => {
    setSnackbarSeverity(severity);
    setSnackBarMessage(message);
    setIsSnackbarOpen(isOpen);
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <StyledDialogContent>
        <StyledDialogTitle>
          {t('clinicLocationDetails.providers.statusDialogTitle')}
        </StyledDialogTitle>
        {accountStatus === UserAccountStatus.ACTIVE && (
          <OvProviderClinicLocationStatus
            description={t(
              'clinicLocationDetails.providers.statusDescriptions.userAccountIsActive'
            )}
            statusIcon={<StyledCheckCircleIcon />}
          />
        )}
        {accountStatus === UserAccountStatus.ONBOARDING && (
          <StyledStatusInfoContainer>
            <OvProviderClinicLocationStatus
              description={t(
                'clinicLocationDetails.providers.statusDescriptions.userHasNotRegisteredYet'
              )}
              statusIcon={<StyledWatchLaterIcon />}
              label={
                getDaysUntilSetPasswordTokenExpires()
                  ? t(
                      'clinicLocationDetails.providers.statusDescriptions.tokenValidUntilMoreDays',
                      { numberOfDays: getDaysUntilSetPasswordTokenExpires() }
                    )
                  : undefined
              }
            />
            <StyledActionsContainer>
              {!isSetPasswordLinkCopiedToClipboard ? (
                <OvProviderClinicLocationAction
                  tooltipText={t(
                    'clinicLocationDetails.actions.copyInvitationLink'
                  )}
                  onClick={copySetPasswordLinkToClipboard}
                  isLoading={isLoading}
                  icon={<ContentCopyIcon />}
                />
              ) : (
                <StyledIconButton edge="end" disabled>
                  <DoneIcon />
                </StyledIconButton>
              )}

              <OvProviderClinicLocationAction
                tooltipText={t(
                  'clinicLocationDetails.actions.resendSetPasswordEmail'
                )}
                onClick={() => setIsOpenResendSetPasswordEmail(true)}
                isLoading={isLoading}
                icon={<EmailIcon />}
              />
            </StyledActionsContainer>
          </StyledStatusInfoContainer>
        )}
        {accountStatus === UserAccountStatus.SET_PASSWORD_TOKEN_EXPIRED && (
          <StyledStatusInfoContainer>
            <OvProviderClinicLocationStatus
              description={t(
                'clinicLocationDetails.providers.statusDescriptions.tokenExpiredBeforeTheUserHasRegistered'
              )}
              statusIcon={<StyledWarningIcon />}
            />
            <StyledActionsContainer>
              <OvProviderClinicLocationAction
                tooltipText={t('clinicLocationDetails.actions.refreshToken')}
                onClick={handleRefreshSetPasswordToken}
                isLoading={isLoading}
                icon={<AutorenewIcon />}
              />
            </StyledActionsContainer>
          </StyledStatusInfoContainer>
        )}
        {approvementStatus === ApprovementStatus.ACCEPTED && (
          <StyledStatusInfoContainer>
            <OvProviderClinicLocationStatus
              description={
                accountStatus === UserAccountStatus.ACTIVE
                  ? t(
                      'clinicLocationDetails.providers.statusDescriptions.clinicLocationInvitationIsAlreadyAccepted'
                    )
                  : t(
                      'clinicLocationDetails.providers.statusDescriptions.invitationWillBeAutomaticallyAccepted'
                    )
              }
              statusIcon={<StyledCheckCircleIcon />}
            />
          </StyledStatusInfoContainer>
        )}
        {approvementStatus === ApprovementStatus.PENDING && (
          <StyledStatusInfoContainer>
            <OvProviderClinicLocationStatus
              description={t(
                'clinicLocationDetails.providers.statusDescriptions.clinicLocationInvitationHasNotBeenAcceptedYet'
              )}
              statusIcon={<StyledWatchLaterIcon />}
              label={
                getDaysUntilApproveInvitationTokenExpires()
                  ? t(
                      'clinicLocationDetails.providers.statusDescriptions.tokenValidUntilMoreDays',
                      {
                        numberOfDays:
                          getDaysUntilApproveInvitationTokenExpires(),
                      }
                    )
                  : undefined
              }
            />
            <StyledActionsContainer>
              {!isApproveInvitationLinkCopiedToClipboard ? (
                <OvProviderClinicLocationAction
                  tooltipText={t(
                    'clinicLocationDetails.actions.copyAcceptInvitationLink'
                  )}
                  onClick={copyApproveInvitationLinkToClipboard}
                  isLoading={isLoading}
                  icon={<ContentCopyIcon />}
                />
              ) : (
                <StyledIconButton edge="end" disabled>
                  <DoneIcon />
                </StyledIconButton>
              )}

              <OvProviderClinicLocationAction
                tooltipText={t(
                  'clinicLocationDetails.actions.resendAcceptInvitationEmail'
                )}
                onClick={() => setIsOpenResendApproveInvitationEmail(true)}
                isLoading={isLoading}
                icon={<EmailIcon />}
              />

              <OvProviderClinicLocationAction
                tooltipText={t(
                  'clinicLocationDetails.actions.acceptInvitation'
                )}
                onClick={() => setIsOpenApproveInvitationDialog(true)}
                isLoading={isLoading}
                icon={<VerifiedUserIcon />}
              />
            </StyledActionsContainer>
          </StyledStatusInfoContainer>
        )}
        {approvementStatus === ApprovementStatus.TOKEN_EXPIRED && (
          <StyledStatusInfoContainer>
            <OvProviderClinicLocationStatus
              description={t(
                'clinicLocationDetails.providers.statusDescriptions.tokenExpiredBeforeTheUserAcceptedTheInvitation'
              )}
              statusIcon={<StyledWarningIcon />}
            />
            <StyledActionsContainer>
              <OvProviderClinicLocationAction
                tooltipText={t('clinicLocationDetails.actions.refreshToken')}
                onClick={handleRefreshApproveInvitationToken}
                isLoading={isLoading}
                icon={<AutorenewIcon />}
              />
            </StyledActionsContainer>
          </StyledStatusInfoContainer>
        )}
      </StyledDialogContent>
      {isLoading && <OvLoadingIndicator position="fixed" />}

      <OvConfirmationDialog
        icon={<StyledVerifiedUserIcon />}
        isOpen={isOpenApproveInvitationDialog}
        onCancel={() => setIsOpenApproveInvitationDialog(false)}
        onConfirm={handleApproveInvitation}
        title="clinicLocationDetails.actions.acceptInvitationTitle"
        description="clinicLocationDetails.actions.acceptInvitationDescription"
      />

      <OvConfirmationDialog
        icon={<StyledPasswordIcon />}
        isOpen={isOpenResendSetPasswordEmail}
        onCancel={() => setIsOpenResendSetPasswordEmail(false)}
        onConfirm={handleSendSetPasswordEmail}
        title="clinicLocationDetails.actions.sendSetPasswordEmailTitle"
        description="clinicLocationDetails.actions.sendSetPasswordEmailDescription"
      />

      <OvConfirmationDialog
        icon={<StyledVerifiedUserIcon />}
        isOpen={isOpenResendApproveInvitationEmail}
        onCancel={() => setIsOpenResendApproveInvitationEmail(false)}
        onConfirm={handleSendApproveInvitationEmail}
        title="clinicLocationDetails.actions.sendApproveInvitationEmailTitle"
        description="clinicLocationDetails.actions.sendApproveInvitationEmailDescription"
      />

      <OvSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackBarMessage}
      />
    </Dialog>
  );
};

export default OvProviderAdminActionsDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    padding: 1.5rem !important;
    @media (min-width: ${breakpoints.sm}) {
      width: 35rem;
    }
  }
`;

const StyledStatusInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 2rem 0;
`;

const StyledActionsContainer = styled.div`
  display: flex;
`;

const StyledDialogTitle = styled.h2`
  color: ${Colours.OV_BASE};
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    width: 2.5rem;
    height: 2.5rem;
    color: ${Colours.OV_GREEN};
  }
`;

const StyledWatchLaterIcon = styled(WatchLaterIcon)`
  && {
    width: 2.5rem;
    height: 2.5rem;
    color: ${Colours.OV_YELLOW};
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  && {
    width: 2.5rem;
    height: 2.5rem;
    color: ${Colours.OV_RED};
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    margin-left: 1rem;
  }
`;

const StyledVerifiedUserIcon = styled(VerifiedUserIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_WHITE};
  }
`;

const StyledPasswordIcon = styled(PasswordIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_WHITE};
  }
`;
