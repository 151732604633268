import {
  Alert,
  AlertColor,
  AlertTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { UserInfo } from '../../common/model/dto/user-info';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvButton from '../UI/atoms/OvButton';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import {
  assignPatientToProvider,
  getPatientDetails,
  loadDailyDataBetweenDateRangeByPatientId,
  loadPatientDailyData,
  revokePatientProviderAssignment,
} from '../../redux/thunks/clinic-location.thunk';
import {
  changeFullscriptPatientId,
  clearSelectedUser,
} from '../../redux/reducers/clinic-location.slice';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { PatientDetailTabType } from '../../common/model/type/patient-detail-tab.type';
import { last } from 'lodash';
import OvPatientInfo from '../UI/organisms/OvPatientInfo';
import OvUserDetailReports from '../UI/organisms/OvUserDetailReports';
import OvPatientInfoCalendar from '../UI/organisms/OvPatientInfoCalendar';
import FullScript from './FullScript';
import { parseUrl } from 'query-string';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import OvUserCharts from '../UI/organisms/OvUserCharts';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { SendgridService } from '../../services/sendgrid.service';
import { StripeUtils } from '../../common/utils/services/stripe-utils';
import OvSnackbar from '../UI/molecules/OvSnackbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getProviderDefaultStripeLink } from '../../redux/thunks/configuration.thunk';

const CLINIC_LOCATIONS_MAX_LENGTH = 50;
const PROVIDERS_MAX_LENGTH = 50;

const PatientDetails: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  let { path, url } = useRouteMatch();
  const { patientId }: any = useParams();
  const [prevPath, setPrevPath] = useState<string>('');
  const [isPatientAssignedToProvider, setIsPatientAssignedToProvider] =
    useState(false);
  const selectedPatient: UserInfo | null = useAppSelector(
    (state) => state.clinicLocation.selectedUser
  );
  const selectedClinicLocation: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const isClinicLocationLoading = useAppSelector(
    (state) => state.clinicLocation.isLoading
  );
  const isFullScriptLinked = !!useAppSelector(
    (state) => state.user.currentUser?.fullscript
  );
  const fsTempAuthCode = parseUrl(search)?.query?.code as string;

  const [activeTab, setActiveTab] = useState<PatientDetailTabType>(
    PatientDetailTabType.HORMONE_CHARTS
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const [clinicLocationNames, setClinicLocationNames] = useState('');
  const [providerNames, setProviderNames] = useState('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [isEmailSendingLoading, setIsEmailSendingLoading] =
    useState<boolean>(false);
  const isStripeLinkLoading: boolean = useAppSelector(
    (state) => state.configuration.isLoading
  );
  const providerDefaultStripeLink: string | undefined = useAppSelector(
    (state) => state.configuration.providerDefaultStripeLink
  );

  const isLoading =
    isClinicLocationLoading || isEmailSendingLoading || isStripeLinkLoading;

  useEffect(() => {
    if ((location as any).state?.prevPath) {
      setPrevPath((location as any)?.state?.prevPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(clearSelectedUser());
    dispatch(
      getPatientDetails({
        clinicLocationId: selectedClinicLocation?.id,
        patientId,
      })
    );
    dispatch(getProviderDefaultStripeLink());
  }, [dispatch, patientId, selectedClinicLocation]);

  useEffect(() => {
    if (currentUser && selectedPatient) {
      const isPatientAssignedToProvider = selectedPatient?.providers.some(
        (provider) => provider.provider.id === currentUser.id
      );

      setIsPatientAssignedToProvider(!!isPatientAssignedToProvider);

      setClinicLocationNames(
        selectedPatient?.patient_clinic_locations
          .map(
            (patientClinicLocation) =>
              patientClinicLocation.clinic_location.name
          )
          .join(', ')
      );
      setProviderNames(
        selectedPatient.providers
          .map((patientProvider) => patientProvider.provider)
          .map((provider) => `${provider.first_name} ${provider.last_name}`)
          .join(', ')
      );
    }
  }, [selectedPatient, currentUser, dispatch]);

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(
        location.pathname.split('/')
      ) as PatientDetailTabType;
      const {
        HORMONE_CHARTS,
        PATIENT_INFO,
        PATIENT_REPORTS,
        PATIENT_CALENDAR,
        RECOMMENDATIONS,
      } = PatientDetailTabType;

      if (
        [
          HORMONE_CHARTS,
          PATIENT_INFO,
          PATIENT_REPORTS,
          PATIENT_CALENDAR,
          RECOMMENDATIONS,
        ].includes(pathName)
      ) {
        setActiveTab(pathName);
      }
    };

    setActiveTabFromUrl();
  }, [dispatch, selectedPatient?.document_id, location.pathname]);

  const onBackClicked = () => {
    if (prevPath) {
      history.push(prevPath);
    } else {
      history.goBack();
    }
  };

  const changeTab = (event: any, newValue: PatientDetailTabType) => {
    setActiveTab(newValue);
  };

  const onAssignPatientToProviderClicked = () => {
    if (selectedClinicLocation?.id && selectedPatient?.email) {
      dispatch(
        assignPatientToProvider({
          clinicLocationId: selectedClinicLocation?.id,
          providerId: currentUser.id,
          patientEmail: selectedPatient?.email,
        })
      );
    }
  };

  const onRevokePatientProviderAssignmentClicked = () => {
    if (selectedClinicLocation?.id && selectedPatient?.email) {
      dispatch(
        revokePatientProviderAssignment({
          clinicLocationId: selectedClinicLocation?.id,
          providerId: currentUser.id,
          patientId: selectedPatient?.id,
        })
      );
    }
  };

  const handleLinkFsToPatient = (fsId: string) => {
    dispatch(changeFullscriptPatientId(fsId));
  };

  const handleUnLinkFsToPatient = () => {
    dispatch(changeFullscriptPatientId(''));
  };

  const handleSendEmail = async () => {
    if (
      selectedPatient?.email &&
      selectedClinicLocation &&
      selectedClinicLocation?.name &&
      providerDefaultStripeLink
    ) {
      try {
        setIsEmailSendingLoading(true);
        await SendgridService.invitePatientToOrder(
          selectedPatient?.email,
          `${selectedPatient.first_name} ${selectedPatient.last_name}`,
          `${currentUser?.first_name} ${currentUser?.last_name}`,
          `${providerDefaultStripeLink}?${StripeUtils.populatePaymentLinkWithData(
            currentUser,
            selectedClinicLocation,
            selectedPatient.email
          )}`,
          selectedClinicLocation.name
        );
        setIsEmailSendingLoading(false);

        setSnackbarProps(
          'success',
          t('userDetails.actions.emailSentSuccessfully'),
          true
        );
      } catch (error) {
        setIsEmailSendingLoading(false);
        setSnackbarProps(
          'error',
          t('userDetails.actions.emailSendingFailed'),
          true
        );
      } finally {
        setIsConfirmationDialogOpen(false);
      }
    }
  };

  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: boolean
  ) => {
    setSnackbarSeverity(severity);
    setSnackBarMessage(message);
    setIsSnackbarOpen(isOpen);
  };

  return (
    <DetailPageWrapper>
      {!isClinicLocationLoading &&
        selectedPatient &&
        selectedClinicLocation && (
          <>
            <Header>
              <GeneralHeaderContainer>
                <LeftInfoWrapper>
                  <BackCta onClick={onBackClicked}>
                    <StyledArrowBackIcon />
                    {t('common.actions.back')}
                  </BackCta>
                  <UserName>
                    {`${selectedPatient?.first_name} ${selectedPatient?.last_name}`}
                    <StyledEmail>{selectedPatient?.email}</StyledEmail>
                  </UserName>
                </LeftInfoWrapper>

                <ActionsWrapper>
                  <StyledOvButton
                    onClick={() => setIsConfirmationDialogOpen(true)}
                  >
                    <StyledShoppingCartIcon />
                    {t('dashboard.shop.invitePatientToOrderCta')}
                  </StyledOvButton>
                  {!isPatientAssignedToProvider ? (
                    <Tooltip
                      title={t('patientDetails.actions.assignPatientToMe')}
                    >
                      <StyledIconButton
                        onClick={onAssignPatientToProviderClicked}
                      >
                        <StarBorderIcon />
                      </StyledIconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={t('patientDetails.actions.revokeUserAssignment')}
                    >
                      <StyledIconButton
                        onClick={onRevokePatientProviderAssignmentClicked}
                      >
                        <StarIcon />
                      </StyledIconButton>
                    </Tooltip>
                  )}
                </ActionsWrapper>
              </GeneralHeaderContainer>
              <PatientDataContainer></PatientDataContainer>
            </Header>

            {selectedPatient?.is_demo_account && (
              <StyledAlert severity="warning">
                <AlertTitle>{t('userDetails.demoTitle')}</AlertTitle>
                {t('userDetails.demoDesc')}
              </StyledAlert>
            )}

            <TabContainer>
              <ClinicInfoContainer>
                <ClinicInfoWrapper>
                  <ClinicInfoLabel>
                    {t('patientDetails.clinicSites')}
                  </ClinicInfoLabel>
                  <Tooltip title={clinicLocationNames}>
                    <StyledText>
                      {clinicLocationNames.length === 0
                        ? '-'
                        : clinicLocationNames.length >
                          CLINIC_LOCATIONS_MAX_LENGTH
                        ? `${clinicLocationNames.substring(
                            0,
                            CLINIC_LOCATIONS_MAX_LENGTH
                          )}...`
                        : clinicLocationNames}
                    </StyledText>
                  </Tooltip>
                </ClinicInfoWrapper>
                <ClinicInfoWrapper>
                  <ClinicInfoLabel>
                    {t('patientDetails.providers')}
                  </ClinicInfoLabel>
                  <Tooltip title={providerNames}>
                    <StyledText>
                      {providerNames.length === 0
                        ? '-'
                        : providerNames.length > PROVIDERS_MAX_LENGTH
                        ? `${providerNames.substring(
                            0,
                            PROVIDERS_MAX_LENGTH
                          )}...`
                        : providerNames}
                    </StyledText>
                  </Tooltip>
                </ClinicInfoWrapper>
              </ClinicInfoContainer>
              <Tabs onChange={changeTab} value={activeTab}>
                <Tab
                  style={{
                    borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                    textTransform: 'none',
                  }}
                  value={PatientDetailTabType.HORMONE_CHARTS}
                  label={t('dashboard.tabs.hormoneCharts')}
                  component={Link}
                  to={`${url}/${PatientDetailTabType.HORMONE_CHARTS}`}
                  replace
                />
                <Tab
                  style={{
                    borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                    textTransform: 'none',
                  }}
                  value={PatientDetailTabType.PATIENT_INFO}
                  label={t('dashboard.tabs.patientInfo')}
                  component={Link}
                  to={`${url}/${PatientDetailTabType.PATIENT_INFO}`}
                  replace
                />
                <Tab
                  style={{
                    borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                    textTransform: 'none',
                  }}
                  value={PatientDetailTabType.PATIENT_REPORTS}
                  label={t('dashboard.tabs.reports')}
                  component={Link}
                  to={`${url}/${PatientDetailTabType.PATIENT_REPORTS}`}
                  replace
                />
                <Tab
                  style={{
                    borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                    textTransform: 'none',
                  }}
                  value={PatientDetailTabType.PATIENT_CALENDAR}
                  label={t('dashboard.tabs.calendar')}
                  component={Link}
                  to={`${url}/${PatientDetailTabType.PATIENT_CALENDAR}`}
                  replace
                />
                {!!(isFullScriptLinked || fsTempAuthCode) && (
                  <Tab
                    style={{
                      borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                      textTransform: 'none',
                    }}
                    value={PatientDetailTabType.RECOMMENDATIONS}
                    label={t('common.recommendations')}
                    component={Link}
                    to={`${url}/${PatientDetailTabType.RECOMMENDATIONS}`}
                    replace
                  />
                )}
              </Tabs>
            </TabContainer>
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${url}/hormone-charts`} />
              </Route>
              <Route path={`${path}/hormone-charts`}>
                <ContentWrapper>
                  <OvUserCharts
                    user={selectedPatient}
                    clinicLocationId={selectedClinicLocation?.id}
                    loadDailyData={loadPatientDailyData}
                    loadDailyDataForCalendar={
                      loadDailyDataBetweenDateRangeByPatientId
                    }
                  />
                </ContentWrapper>
              </Route>
              <Route path={`${path}/patient-info`}>
                <ContentWrapper>
                  <OvPatientInfo selectedPatient={selectedPatient} />
                </ContentWrapper>
              </Route>
              <Route path={`${path}/reports`}>
                <OvUserDetailReports
                  selectedUser={selectedPatient}
                  loadDailyDataBetweenRange={
                    loadDailyDataBetweenDateRangeByPatientId
                  }
                  clinicLocationId={selectedClinicLocation.id}
                  currentUser={currentUser}
                />
              </Route>
              <Route path={`${path}/calendar`}>
                <OvPatientInfoCalendar
                  selectedPatient={selectedPatient}
                  clinicLocationId={selectedClinicLocation.id}
                  loadDailyData={loadPatientDailyData}
                />
              </Route>
              <Route path={`${path}/recommendations`}>
                <FullScript
                  patient={selectedPatient}
                  onLinkToPatient={handleLinkFsToPatient}
                  onUnlinkToPatient={handleUnLinkFsToPatient}
                />
              </Route>
            </Switch>
          </>
        )}
      {isLoading && <OvLoadingIndicator position="fixed" />}

      <OvConfirmationDialog
        icon={<StyledShoppingBagIcon />}
        isOpen={isConfirmationDialogOpen}
        onCancel={() => setIsConfirmationDialogOpen(false)}
        onConfirm={handleSendEmail}
        title="patientDetails.actions.invitePatientToOrderDialogTitle"
        description={t(
          'patientDetails.actions.invitePatientToOrderDialogDescription',
          {
            patientFirstName: selectedPatient?.first_name,
          }
        )}
      />

      <OvSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackBarMessage}
      />
    </DetailPageWrapper>
  );
};

export default PatientDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const UserName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledEmail = styled.span`
  border-left: 1px solid ${Colours.OV_BASE_HOVER};
  margin-left: 0.75rem;
  padding-left: 0.75rem;
`;

const BackCta = styled(OvButton)`
  && {
    padding: 0 0.625rem 0 0.25rem;
    margin-right: 1rem;
    border-radius: ${Variables.borderRadius.XLARGE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 0.25rem;
  height: 1.25rem;
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const LeftInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const GeneralHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PatientDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled.p`
  margin: 0;
`;

const ClinicInfoWrapper = styled.div``;

const ClinicInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClinicInfoLabel = styled.label`
  font-size: 0.75rem;
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledShoppingBagIcon = styled(ShoppingBagIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;
