import { addDays, differenceInDays, subDays } from 'date-fns';
import { DateRange } from '../../model/type/date-range.type';
import { HormoneValueType } from '../../model/type/hormone-values.type';
import LinecolorBreakpoint from '../../model/type/lince-color-breakpoint';
import { Range } from 'react-date-range';
import { DailyData } from '../../model/dto/daily-data';
import { ScanChartData } from '../../model/type/scan-chart-data.type';
import MathUtils from './math-utils';
import { Scan } from '../../model/dto/scan';

const PERIOD_FLOW_VALUES = ['Light', 'Medium', 'Heavy'];

export default class ChartUtils {
  static getDateRange(
    selectedCycleValue: string,
    lastFirstDateOfPeriod: Date,
    averageCycleLength: number,
    customDates: Range
  ): DateRange {
    const now = new Date();

    if (selectedCycleValue === 'last cycle') {
      const startDate = subDays(lastFirstDateOfPeriod, 1);
      const dateAfterCycle = addDays(startDate, averageCycleLength + 1);
      const endDate =
        dateAfterCycle.getTime() < now.getTime() ? dateAfterCycle : now;

      return {
        startDate,
        endDate,
      };
    } else if (selectedCycleValue === 'custom date range') {
      const { startDate, endDate } = customDates;

      return {
        startDate: startDate!,
        endDate: endDate!,
      };
    } else {
      return {
        startDate: subDays(now, parseInt(selectedCycleValue) - 1),
        endDate: now,
      };
    }
  }

  static isValuePresent(
    hormoneType: HormoneValueType,
    dailyData?: DailyData
  ): boolean {
    return (
      dailyData !== undefined &&
      dailyData[hormoneType] !== undefined &&
      dailyData[hormoneType] !== null
    );
  }

  static isScanValuePresent(scan: Scan, hormoneType: HormoneValueType) {
    return (
      scan !== undefined &&
      scan[hormoneType] !== undefined &&
      scan[hormoneType] !== null
    );
  }

  static getLinecolorBreakpoints(
    firstMeasureIndex: number,
    lastMeasureIndex: number,
    chartData: any[]
  ) {
    const lineColorBreakPoints: LinecolorBreakpoint[] = [
      {
        percentage: firstMeasureIndex / (chartData.length - 1),
        isMeasured: true,
      },
    ];

    for (let i = firstMeasureIndex + 1; i <= lastMeasureIndex; i++) {
      if (
        chartData[i].measured === false &&
        lineColorBreakPoints[lineColorBreakPoints.length - 1].isMeasured
      ) {
        lineColorBreakPoints.push({
          percentage: (i - 1) / (chartData.length - 1),
          isMeasured: false,
        });
      } else if (
        chartData[i].measured === true &&
        !lineColorBreakPoints[lineColorBreakPoints.length - 1].isMeasured
      ) {
        lineColorBreakPoints.push({
          percentage: i / (chartData.length - 1),
          isMeasured: true,
        });
      }
    }

    lineColorBreakPoints.push({
      percentage: lastMeasureIndex / (chartData.length - 1),
      isMeasured: false,
    });

    return lineColorBreakPoints;
  }

  static getPeriodDays(dailyData: (DailyData | undefined)[]) {
    const periodStartDayIndexes: number[] = [0];
    const periodEndingDayIndexes: number[] = [];

    let foundStartingDay = false;

    for (let i = 0; i < dailyData.length; i++) {
      if (PERIOD_FLOW_VALUES.includes(dailyData[i]?.period_flow ?? '')) {
        if (!foundStartingDay) {
          periodStartDayIndexes[0] = i;
        } else {
          periodStartDayIndexes.push(i);
        }

        foundStartingDay = true;

        while (i < dailyData.length) {
          if (!PERIOD_FLOW_VALUES.includes(dailyData[i]?.period_flow ?? '')) {
            periodEndingDayIndexes.push(i - 1);
            break;
          }
          i++;
        }

        if (
          periodStartDayIndexes[0] !== 0 &&
          periodEndingDayIndexes.length < periodStartDayIndexes.length
        ) {
          periodEndingDayIndexes.push(i - 1);
        }
      }
    }

    return { periodStartDayIndexes, periodEndingDayIndexes };
  }

  static getWindowLength(
    startDate: Date,
    endDate: Date,
    selectedCycleValue: string
  ) {
    if (
      selectedCycleValue === '30' ||
      selectedCycleValue === '60' ||
      selectedCycleValue === '90'
    ) {
      return parseInt(selectedCycleValue);
    }

    const diffInDays = differenceInDays(endDate, startDate);

    return selectedCycleValue === 'custom date range'
      ? diffInDays + 1
      : diffInDays;
  }

  static mapScansToScanChartData(
    hormoneMinValue: number,
    hormoneMaxValue: number,
    index: number,
    hormoneType: HormoneValueType,
    dailyData?: DailyData
  ): ScanChartData[] {
    const dailyDataHormoneValue = dailyData ? dailyData[hormoneType] : null;

    return (
      dailyData?.scans?.reduce((res: ScanChartData[], scan) => {
        const scanValue = scan ? scan[hormoneType] : null;

        if (
          this.isScanValuePresent(scan, hormoneType) &&
          scanValue !== dailyDataHormoneValue
        ) {
          res.push({
            value: MathUtils.normalizeValue(
              scanValue ?? hormoneMinValue,
              hormoneMinValue,
              hormoneMaxValue
            ),
            index,
          });
        }

        return res;
      }, []) ?? []
    );
  }

  static getHormoneThreshold(max: number, percentage: number) {
    return max * percentage;
  }
}
