import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { RouteGuardType } from '../model/type/route-guard.type';
import { OvPermissionType } from '../model/type/ov-permission.type';
import { useAppSelector } from '../../redux/hooks';
import { isUndefined } from 'lodash';
import { Constants } from '../constants';

const AuthRoute = (
  props: RouteProps & { type: RouteGuardType; redirectPath?: string } & {
    permissions: OvPermissionType[];
  }
) => {
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  const { type, redirectPath } = props;

  if (!isUndefined(isAuthenticated)) {
    // call routing logic only after the authentication data has been set
    if (redirectPath) {
      // logged in
      if (type === 'public' && isAuthenticated) {
        const preservedLocation = sessionStorage.getItem(
          Constants.SESSION_STORAGE_KEYS.LOCATION_BEFORE_AUTHENTICATION
        );

        // in case there is a preserved location in session storage, redirect there
        if (preservedLocation) {
          sessionStorage.removeItem(
            Constants.SESSION_STORAGE_KEYS.LOCATION_BEFORE_AUTHENTICATION
          );
          return <Redirect to={preservedLocation} />;
        }

        // otherwise redirect to the default redirect path (user list or patients list)
        return <Redirect to={redirectPath} />;
      } else if (type === 'protected' && !isAuthenticated) {
        // in case you hit a protected route, but you are not authenticated, preserve the original destination for later reuse
        const locationFrom = `${props.location?.pathname}${props.location?.search}${props.location?.hash}`;

        return (
          <Redirect
            to={{
              pathname: redirectPath,
              state: {
                from: locationFrom,
              },
            }}
          />
        );
      }
    }
  }

  return <Route {...props} />;
};

export default AuthRoute;
