import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Clinic } from '../../../common/model/dto/clinic';
import Colours from '../../../design-system/colours';
import { AdminClinicService } from '../../../services/admin/admin-clinic.service';
import OvLabelledValue from '../atoms/OvLabelledValue';
import OvTextField from '../atoms/OvTextField';
import { SxProps } from '@mui/material';

const useStyles = (): { [key: string]: SxProps } => ({
  paper: {
    backgroundColor: Colours.OV_BASE,
  },
});

interface OvClinicTypeaheadProps {
  placeholder?: string;
  optionLabel?: void;
  onClinicSelect?: (clinic: Clinic) => void;
}

const OvClinicTypeahead: FC<OvClinicTypeaheadProps> = ({
  placeholder,
  optionLabel,
  onClinicSelect,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState<Clinic | null>(null);
  const [options, setOptions] = useState<Clinic[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const REQUEST_DELAY = 600;

  const fetchClinics = (searchText: string) => {
    return AdminClinicService.getClinicTypeahead(searchText);
  };

  // eslint-disable-next-line
  const getClinicsDelayed = useCallback(
    debounce((inputValue, active) => {
      setOptions([]);

      (async () => {
        if (inputValue.length >= 3) {
          setIsLoading(true);
          const userInfoList: Clinic[] = await fetchClinics(inputValue);

          if (active) {
            setOptions(userInfoList);
            setIsLoading(false);
          }
        }

        setIsLoading(false);
      })();
    }, REQUEST_DELAY),
    []
  );

  const mapClinicToOption = ({
    name,
  }: Clinic): { label?: string; value: string }[] => {
    const items: { label?: string; value: string }[] = [];
    if (name) {
      items.push({
        label: t('common.clinicFields.clinicName'),
        value: name,
      });
    }

    return items;
  };

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);

      return undefined;
    }

    getClinicsDelayed(inputValue, active);

    return () => {
      active = false;
    };
  }, [inputValue, value, getClinicsDelayed]);

  return (
    <StyledAutocomplete
      {...props}
      freeSolo
      options={options}
      value={value}
      onChange={(event, newValue: Clinic | any) => {
        setOptions(value ? [newValue, ...options] : options);
        setValue(newValue);

        if (onClinicSelect) {
          onClinicSelect(newValue);
        }
      }}
      getOptionLabel={(option) => {
        if (option) {
          const { name }: Clinic = option as Clinic;

          if (name) {
            return `${name}`;
          }
        }

        return '';
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(x) => x}
      filterSelectedOptions
      sx={{ ...classes.paper }}
      renderInput={(params) => (
        <OvTextField
          {...params}
          label={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        let items: { label?: string; value: string }[] = [];

        if (option) {
          items = mapClinicToOption(option as Clinic);
        }

        return <OvLabelledValue {...props} items={items} />;
      }}
    />
  );
};

export default OvClinicTypeahead;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiFormControl-root,
    .MuiInputLabel-formControl {
      font-size: 0.875rem;
    }

    .MuiInputBase-formControl {
      font-size: 0.75rem;
      background: ${Colours.WHITE};
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    :not(.Mui-focused) {
      .MuiInputLabel-formControl {
        top: -10px;
        left: -7px;
      }
    }

    .MuiFormLabel-filled {
      top: 0 !important;
      left: 0 !important;
    }
  }
`;
