import { Configuration } from '../../model/dto/configuration';
import { ConfigurationSettingItemType } from '../../model/type/configuration-setting-item.type';
import StringUtils from './string-utils';
import { ConfigurationOption } from '../../../components/UI/organisms/OvConfigurationSettingItem';
import { ConfigurationSettingsFields } from '../../../firebase/document-field.enums';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';

export class ConfigurationSettingsDataUtils {
  static mapConfigurationsForUI(
    latestConfig: Configuration
  ): ConfigurationOption[] {
    const mappedConfigs = [];

    if (latestConfig.user) {
      mappedConfigs.push({
        label: 'configurationSettings.fields.lastModifiedBy',
        value: StringUtils.getPrettyUserName(latestConfig.user),
        isEditable: false,
        type: ConfigurationSettingItemType.TEXT,
        fieldName: ConfigurationSettingsFields.user,
      });
    }

    return [
      ...mappedConfigs,
      ...[
        {
          label: 'common.modifiedAt',
          value: latestConfig.updated_at,
          isEditable: false,
          type: ConfigurationSettingItemType.DATE,
          iconEl: TodayIcon,
          fieldName: ConfigurationSettingsFields.updated_at,
        },
        {
          label: 'common.createdAt',
          value: latestConfig.created_at,
          isEditable: false,
          type: ConfigurationSettingItemType.DATE,
          iconEl: EventIcon,
          fieldName: ConfigurationSettingsFields.created_at,
        },
        {
          label:
            'configurationSettings.fields.automatedOrderProcessingToOpsengine',
          value:
            latestConfig.is_automated_order_processing_to_opsengine_enabled,
          isEditable: true,
          type: ConfigurationSettingItemType.TOGGLE,
          fieldName:
            ConfigurationSettingsFields.is_automated_order_processing_to_opsengine_enabled,
        },
        {
          label: 'configurationSettings.fields.shipheroPublicApiAccessToken',
          value: latestConfig.shiphero_public_api_access_token,
          isEditable: true,
          type: ConfigurationSettingItemType.TEXT,
          fieldName:
            ConfigurationSettingsFields.shiphero_public_api_access_token,
        },
        {
          label:
            'configurationSettings.fields.shipheroPublicApiAccessTokenTimestamp',
          value: latestConfig.shiphero_public_api_access_token_timestamp,
          isEditable: true,
          type: ConfigurationSettingItemType.TEXT,
          fieldName:
            ConfigurationSettingsFields.shiphero_public_api_access_token_timestamp,
          placeholder: 'configurationSettings.placeholders.dateStringFields',
        },
        {
          label:
            'configurationSettings.fields.shipheroPublicApiAccessTokenExpiration',
          value: latestConfig.shiphero_public_api_access_token_expiration,
          isEditable: true,
          type: ConfigurationSettingItemType.TEXT,
          fieldName:
            ConfigurationSettingsFields.shiphero_public_api_access_token_expiration,
          placeholder: 'configurationSettings.placeholders.dateStringFields',
        },
        {
          label: 'configurationSettings.fields.defaultOrderDropshippingPartner',
          value: latestConfig.default_order_dropshipping_partner,
          isEditable: true,
          type: ConfigurationSettingItemType.TEXT,
          fieldName:
            ConfigurationSettingsFields.default_order_dropshipping_partner,
        },
        {
          label: 'configurationSettings.fields.defaultShipheroOperatorHold',
          value: latestConfig.default_shiphero_operator_hold,
          isEditable: true,
          type: ConfigurationSettingItemType.TOGGLE,
          fieldName: ConfigurationSettingsFields.default_shiphero_operator_hold,
        },
        {
          label: 'configurationSettings.fields.providerDefaultStripeLink',
          value: latestConfig.provider_default_stripe_link,
          isEditable: true,
          type: ConfigurationSettingItemType.LINK,
          fieldName: ConfigurationSettingsFields.provider_default_stripe_link,
        },
        {
          label: 'configurationSettings.fields.skipPoBoxChecking',
          value: latestConfig.skip_po_box_checking,
          isEditable: true,
          type: ConfigurationSettingItemType.TOGGLE,
          fieldName: ConfigurationSettingsFields.skip_po_box_checking,
        },
        {
          label:
            'configurationSettings.fields.defaultScanProcessingToolkitVersion',
          value: latestConfig.default_scan_processing_toolkit_version,
          isEditable: true,
          type: ConfigurationSettingItemType.TEXT,
          fieldName:
            ConfigurationSettingsFields.default_scan_processing_toolkit_version,
        },
      ],
    ];
  }
}
