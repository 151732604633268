import { OvTableData } from '../../../components/UI/molecules/OvTable';
import DateUtils from './date-utils';
import { Article } from '../../model/dto/article';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import { ArticleFields } from '../../../firebase/document-field.enums';
import i18n from 'i18next';

export class ArticleDataUtils {
  static mapDataForArticleDetailsTable(article: Article): OvTableData {
    return [
      {
        label: 'articles.fields.title',
        translateLabel: true,
        value: article.title,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ArticleFields.title,
          placeholder: i18n.t('articles.fields.title'),
        },
      },
      {
        label: 'articles.fields.url',
        translateLabel: true,
        value: article.url,
        linkUrl: article.url,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ArticleFields.url,
          placeholder: i18n.t('articles.fields.url'),
        },
      },
      {
        label: 'articles.fields.wordCount',
        translateLabel: true,
        value: article.word_count,
        editOptions: {
          widgetType: TableRowEditWidgetType.NUMBER,
          propertyName: ArticleFields.word_count,
          placeholder: i18n.t('articles.fields.wordCount'),
        },
      },
      {
        label: 'articles.fields.daysInPostOvulation',
        translateLabel: true,
        value: article.days_in_post_ovulation,
        editOptions: {
          widgetType: TableRowEditWidgetType.NUMBER,
          propertyName: ArticleFields.days_in_post_ovulation,
          placeholder: i18n.t('articles.fields.daysInPostOvulation'),
        },
      },
      {
        label: 'articles.fields.thumbnail',
        translateLabel: true,
        imageUrl: article.image_url,
        value: article.image_url,
      },
      {
        label: 'articles.fields.topic',
        translateLabel: true,
        value: article.topic,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ArticleFields.topic,
          placeholder: i18n.t('articles.fields.topic'),
        },
      },
      {
        label: 'articles.fields.section',
        translateLabel: true,
        value: article.section,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ArticleFields.section,
          placeholder: i18n.t('articles.fields.section'),
        },
      },
      {
        label: 'articles.fields.timing',
        translateLabel: true,
        value: article.timing,
        editOptions: {
          widgetType: TableRowEditWidgetType.NUMBER,
          propertyName: ArticleFields.timing,
          placeholder: i18n.t('articles.fields.timing'),
        },
      },
      {
        label: 'common.createdAt',
        translateLabel: true,
        value:
          article?.created_at &&
          DateUtils.formatDate(
            article?.created_at as Date,
            'MMMM DD, YYYY, HH:mm:ss'
          ),
      },
      {
        label: 'common.updatedAt',
        translateLabel: true,
        value:
          article?.updated_at &&
          DateUtils.formatDate(
            article?.updated_at as Date,
            'MMMM DD, YYYY, HH:mm:ss'
          ),
      },
      {
        label: 'articles.fields.color',
        translateLabel: true,
        value: article.color,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ArticleFields.color,
          placeholder: i18n.t('articles.fields.color'),
        },
      },
      {
        label: 'articles.fields.cta',
        translateLabel: true,
        value: article.cta,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: ArticleFields.cta,
          placeholder: i18n.t('articles.fields.cta'),
        },
      },
    ];
  }
}
