import React, { FC, useState } from 'react';
import styled from 'styled-components';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import breakpoints from '../../../design-system/breakpoints';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { SubPageModel } from '../../../common/model/ui/sub-page.model';
import { NavLink, useRouteMatch } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';

const OvSideNav: FC<{ subPages: SubPageModel[] }> = ({ subPages }) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const [openedDrawer, setOpenedDrawer] = useState<boolean>(false);
  const navigationList = subPages.map((page) => {
    const navigationItem = page.shouldOpenDialog ? (
      <StyledListItemButton onClick={page.handleDialogOpen}>
        <StyledListItemIcon>{page.linkIcon}</StyledListItemIcon>
        <ListItemText>
          <Typography textTransform="none" variant="button">
            {page.linkLabel}
          </Typography>
        </ListItemText>
      </StyledListItemButton>
    ) : (
      <StyledListItemButton>
        <StyledListItemIcon>{page.linkIcon}</StyledListItemIcon>
        <ListItemText>
          <Typography textTransform="none" variant="button">
            {page.linkLabel}
          </Typography>
        </ListItemText>
      </StyledListItemButton>
    );

    return (
      <NavLinkWrapper key={page.url}>
        {page.shouldOpenDialog ? (
          navigationItem
        ) : (
          <NavLink
            exact={!page.url}
            key={page.url}
            to={`${url}${page.url}`}
            className={(isActive) => (isActive ? 'activeLink' : '')}
          >
            {navigationItem}
          </NavLink>
        )}
      </NavLinkWrapper>
    );
  });

  const toggleDrawer = () => {
    setOpenedDrawer(!openedDrawer);
  };

  return (
    <>
      <SideNav aria-labelledby={t('app.subPages.menu.ariaLabel')}>
        {navigationList}
      </SideNav>

      <MobileSideNavIcon onClick={toggleDrawer}>
        <MenuIcon />
      </MobileSideNavIcon>

      <MobileSideNavDrawer
        variant="temporary"
        anchor="left"
        open={openedDrawer}
        onClose={toggleDrawer}
      >
        {navigationList}
      </MobileSideNavDrawer>
    </>
  );
};

export default OvSideNav;

const NavLinkWrapper = styled.li`
  list-style-type: none;

  a {
    text-decoration: none;
  }

  .activeLink {
    .MuiListItemButton-root {
      background-color: ${Colours.OV_BASE};
      border-radius: ${Variables.borderRadius.SMALL};

      &:hover {
        background-color: ${Colours.OV_BASE_HOVER};
      }
    }

    .MuiSvgIcon-root,
    .MuiTypography-button {
      color: ${Colours.OV_SEMI_LIGHT};
    }
  }
`;

const SideNav = styled(List)`
  && {
    display: none;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
    width: 12.75rem;

    @media (min-width: ${breakpoints.lg}) {
      display: block;
      position: sticky;
      top: 5.25rem;
    }
  }
`;

const MobileSideNavIcon = styled(IconButton)`
  && {
    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }

    &.MuiButtonBase-root {
      padding: 0.5rem !important;
    }
  }
`;

const MobileSideNavDrawer = styled(Drawer)`
  && {
    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }

    padding-top: 0;
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  && {
    padding: 8px 10px;
    width: 12.75rem;
    box-sizing: border-box;

    @media (min-width: ${breakpoints.lg}) {
      border-radius: ${Variables.borderRadius.SMALL};
    }

    &:hover {
      background-color: ${Colours.OV_WHITE_HOVER};
    }
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 32px;
    color: ${Colours.OV_BASE};
  }
`;
