import axios from 'axios';
import { ADMIN_CLINIC_ROUTE, CLINICS_ROUTE } from '../api/backend';

export class ClinicService {
  public static readonly PATIENTS_LIST_LIMIT: number = 10;

  static async loadAllClinic() {
    const { data } = await axios.get<
      {
        document_id: string;
      }[]
    >(`${ADMIN_CLINIC_ROUTE}/clinic-ids`);

    return data;
  }

  static async doesClinicExistWithClinicId(clinicId: string) {
    const { data } = await axios.get<boolean>(
      `${CLINICS_ROUTE}/exists/${clinicId}`
    );

    return data;
  }
}
