import axios from 'axios';
import { ADMIN_ENTITLEMENT_ROUTE } from '../api/backend';
import { CreateEntitlementRequest } from '../common/model/dto/create-entitlement-request';
import { DeleteEntitlementRequest } from '../common/model/dto/delete-entitlement-request';

export const createEntitlement = async (
  createEntitlementRequest: CreateEntitlementRequest | null
) => {
  return await axios.post(
    `${ADMIN_ENTITLEMENT_ROUTE}`,
    createEntitlementRequest
  );
};

export const deleteEntitlement = async (
  deleteEntitlementRequest: DeleteEntitlementRequest | null
) => {
  return await axios.delete(`${ADMIN_ENTITLEMENT_ROUTE}`, {
    data: deleteEntitlementRequest,
  });
};

export const deleteEntitlements = async (
  userRole: string | null,
  clinicRole: string | null,
  resource: string
) => {
  return await axios.delete(
    `${ADMIN_ENTITLEMENT_ROUTE}/${userRole ?? 'NULL'}/${
      clinicRole ?? 'NULL'
    }/${resource}`
  );
};
