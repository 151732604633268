import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const detectionOptions = {
  order: [
    'navigator',
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'htmlTag',
    'path',
    'subdomain',
  ],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/translation.json?${new Date().getTime()}`,
      requestOptions: {
        cache: 'no-store',
      },
    },
    fallbackLng: 'en',
    debug: false,
    detection: detectionOptions,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
