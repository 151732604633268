import React, { FC } from 'react';
import styled from 'styled-components';
import { TreatmentPlan } from '../../../common/model/dto/fullscript/treatment-plan';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import OvButton from '../atoms/OvButton';

export interface OvFullscriptTreatmentPlanProps {
  plan?: TreatmentPlan;
  onCancel: (id: string) => void;
}

const OvFullscriptTreatmentPlan: FC<OvFullscriptTreatmentPlanProps> = ({
  plan,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Section>
        <Title>{t('integrations.invitationUrl')}</Title>
        <LinkCta
          href={plan?.invitation_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {plan?.invitation_url}
        </LinkCta>
      </Section>

      <Section>
        <Title>{t('common.message')}</Title>
        {plan?.personal_message ? (
          <Description
            dangerouslySetInnerHTML={{ __html: plan?.personal_message }}
          ></Description>
        ) : (
          <Description>
            {t('integrations.noMessageForRecommendation')}
          </Description>
        )}
      </Section>

      <Section>
        <Title>{t('common.products')}</Title>
        {plan?.recommendations?.length ? (
          plan.recommendations?.map((recommendation: any) => {
            return (
              <Recommendation key={recommendation.variant_id}>
                <RecommendationContentWrapper>
                  <RecommendationImage
                    src={recommendation.variant.image_url_medium}
                    alt={recommendation.name}
                  />

                  <RecommendationInfo>
                    <RecommendationName>
                      {recommendation?.variant?.product?.name} (
                      {recommendation.variant.units}{' '}
                      {recommendation.variant.unit_of_measure})
                    </RecommendationName>
                    <Dose>
                      {recommendation?.dosage?.frequency} (
                      {recommendation?.dosage?.additional_info})
                    </Dose>
                  </RecommendationInfo>
                </RecommendationContentWrapper>
              </Recommendation>
            );
          })
        ) : (
          <Description>{t('integrations.noRecommendation')}</Description>
        )}
      </Section>
      {plan?.state === 'active' && (
        <TreatmentPlanActions>
          <CancelCta onClick={() => onCancel(plan?.id as string)}>
            {t('integrations.cancelTreatment')}
          </CancelCta>
        </TreatmentPlanActions>
      )}
    </Wrapper>
  );
};

export default OvFullscriptTreatmentPlan;

const Wrapper = styled.div`
  background-color: ${Colours.OV_LIGHT_BLUE};
  padding: 1rem;
  border-radius: ${Variables.borderRadius.MEDIUM};
`;

const Section = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const Title = styled.h4`
  margin-bottom: 0.25rem;
  margin-top: 0;
  font-size: ${Variables.fontSizes.LARGE};
`;

const Description = styled.p`
  margin: 0;
  font-style: italic;
  font-size: ${Variables.fontSizes.MEDIUM};

  * {
    margin: 0;
  }
`;

const LinkCta = styled.a`
  text-decoration: none;
  color: ${Colours.OV_VIVID_BLUE};

  &:hover {
    color: ${Colours.OV_LIGHT_BLUE_HOVER};
    text-decoration: none;
  }
`;

const Recommendation = styled.article`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const RecommendationContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const RecommendationImage = styled.img`
  border: 1px solid ${Colours.OV_LIGHT_GRAY};
  border-radius: ${Variables.borderRadius.MEDIUM};
  box-shadow: ${Variables.boxShadow.defaultBox};
  width: 8rem;
  height: 8rem;
`;

const RecommendationInfo = styled.div``;

const RecommendationName = styled.h4`
  margin-top: 0;
  margin-bottom: 0.25rem;
`;

const Dose = styled.p`
  margin: 0;
`;

const TreatmentPlanActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CancelCta = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.MEDIUM};
    padding: 0.25rem 0.75rem;
    background-color: ${Colours.OV_RED};
    color: ${Colours.OV_WHITE} !important;
    text-transform: none;
    border: none !important;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: ${Colours.OV_RED};
      color: ${Colours.OV_WHITE} !important;
    }
`;
