import { ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { ProductsService } from '../../services/products.service';
import { Paginated } from '../../common/types';
import { RootState } from '../store';
import { parseUrl, stringify } from 'query-string';
import { ProductsState } from '../states/products.state';
import { History } from 'history';
import { Product } from '../../common/model/dto/product/product';
import { CreateProductRequest } from '../../common/model/dto/product/create-product-request';
import { UpdateProductRequest } from '../../common/model/dto/product/update-product-request';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import { shouldFilterProductList } from '../selectors/products.selectors';
import {
  restoreSearchModel,
  setProductListPaging,
  setProductListStoredQuery,
} from '../reducers/products.slice';

export const getProducts = createAsyncThunk<
  Product[],
  History,
  { state: RootState }
>('products/getProducts', async (history: History, { dispatch, getState }) => {
  try {
    let paginatedProducts: Paginated<Product>,
      query = '';
    const { offset } = getState().products.productListPaging,
      listSorting = ((getState() as RootState)?.products as ProductsState)
        ?.productListSorting,
      filters = FilterUtils.mapFilterModelsToQueryParams(
        getState().products.filters
      );

    if (shouldFilterProductList(getState())) {
      query = stringify(
        {
          offset: offset,
          limit: ProductsService.PRODUCT_LIST_LIMIT,
          order_by: listSorting ? listSorting.order_by : null,
          order_dir: listSorting ? listSorting.order_dir : null,
          ...FilterUtils.getQueryParams(filters),
        },
        { skipNull: true }
      );

      paginatedProducts = await ProductsService.getProducts(query);
      dispatch(setProductListStoredQuery(query));
      history.push(`/products?${query}`);
    } else {
      query = stringify(
        {
          offset: offset,
          limit: ProductsService.PRODUCT_LIST_LIMIT,
          order_by: listSorting ? listSorting.order_by : null,
          order_dir: listSorting ? listSorting.order_dir : null,
        },
        { skipNull: true }
      );

      paginatedProducts = await ProductsService.getProducts(query);
      dispatch(setProductListStoredQuery(offset > 0 ? '?' + query : ''));
      history.push(`/products?${offset > 0 ? '?' + query : ''}`);
    }

    setPaging({ ...paginatedProducts, offset }, dispatch);

    return paginatedProducts.docs;
  } catch (error) {
    return [];
  }
});

export const restoreUserSearchFromUrl = createAsyncThunk<
  Product[],
  string,
  { state: RootState }
>('products/getProducts', async (query, { dispatch, getState }) => {
  try {
    let paginatedUsers: Paginated<Product>;

    dispatch(setProductListStoredQuery(query));
    dispatch(restoreSearchModel(parseUrl(query).query));
    paginatedUsers = await ProductsService.getProducts(query.slice(1));
    setPaging(
      {
        ...paginatedUsers,
        offset: getState().products.productListPaging.offset,
      },
      dispatch
    );

    return paginatedUsers.docs;
  } catch (error) {
    return [];
  }
});

export const getProductById = createAsyncThunk(
  'products/getProductById',
  async (productId: string, { rejectWithValue }) => {
    try {
      return await ProductsService.getProductById(productId);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createProduct = createAsyncThunk(
  'products/createProduct',
  async (
    { history, product }: { history: History; product: CreateProductRequest },
    { rejectWithValue }
  ) => {
    try {
      const response: Product = await ProductsService.createProduct(product);

      history.push(`/products/detail/${response.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductAndGoToDetails = createAsyncThunk(
  'products/updateProductAndGoToDetails',
  async (
    {
      history,
      product,
      productId,
    }: { history: History; product: UpdateProductRequest; productId: string },
    { rejectWithValue }
  ) => {
    try {
      const response: Product = await ProductsService.updateProduct(
        productId,
        product
      );

      history.push(`/products/detail/${response.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async (
    {
      productId,
      updateProductRequest,
    }: { productId: string; updateProductRequest: UpdateProductRequest },
    { rejectWithValue }
  ) => {
    try {
      return await ProductsService.updateProduct(
        productId,
        updateProductRequest
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const archiveProduct = createAsyncThunk(
  'products/archiveProduct',
  async (productId: string, { rejectWithValue }) => {
    try {
      const deactivatedProduct = await ProductsService.archiveProduct(
        productId
      );

      return deactivatedProduct;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

function setPaging(
  paginatedUsers: Paginated<Product>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setProductListPaging({
      offset: paginatedUsers.offset || 0,
      total: paginatedUsers.total,
    })
  );
}
