import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Order } from '../../../common/model/dto/order/order';
import OvListTable from '../atoms/OvListTable';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvListTableRow from '../atoms/OvListTableRow';
import OvOrderListRow from './OvOrderListRow';

const OvOrderList: FC<{ orders: Order[] }> = ({ orders }) => {
  const { t } = useTranslation();
  const headerNames = [
    'orders.tableHeaders.order',
    'orders.tableHeaders.date',
    'orders.tableHeaders.customer',
    'orders.tableHeaders.total',
    'orders.tableHeaders.discount',
    'orders.tableHeaders.status',
    'orders.tableHeaders.paymentStatus',
    'orders.tableHeaders.action',
  ];

  return (
    <WrapperBox>
      <OvListTable>
        <OvListTableRow>
          {headerNames.map((headerName) => (
            <OvListTableHeaderField key={headerName}>
              {t(headerName)}
            </OvListTableHeaderField>
          ))}
        </OvListTableRow>

        {orders.map((order) => (
          <OvListTableRow key={order.id}>
            <OvOrderListRow key={order.internal_id} order={order} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvOrderList;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;
