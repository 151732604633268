import React from 'react';
import { Product } from '../../../common/model/dto/product/product';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import StringUtils from '../../../common/utils/services/string-utils';

const OvProductCategoryPreview: React.FC<{
  product: Product;
  inItemSelected?: (priceId: string) => void;
}> = ({ product, inItemSelected }) => {
  return (
    <Wrapper>
      <Title>{product.title}</Title>
      {product.subtitle && (
        <SubTitle>{StringUtils.truncateString(product?.subtitle, 80)}</SubTitle>
      )}
      <StyledSwiper slidesPerView={'auto'} spaceBetween={30}>
        {product.product_prices
          .filter((price) => price.active && price.stripe_payment_link_id)
          .map((price) => (
            <StyledSwiperSlide
              onClick={() => inItemSelected && inItemSelected(price.id)}
            >
              <ThumbnailImage
                src={price.thumbnail_image_url}
                alt={price.price_display}
              />
              <Text>{price.price_title}</Text>
              <Text>{price.price_display}</Text>
            </StyledSwiperSlide>
          ))}
      </StyledSwiper>
    </Wrapper>
  );
};

export default OvProductCategoryPreview;

const Wrapper = styled.div``;

const Title = styled.h3`
  color: ${Colours.TEXT_DARK['500']};
  margin: 0 0 0.25rem 0;
`;

const SubTitle = styled.h5`
  color: ${Colours.TEXT_DARK['300']};
  margin: 0 0 1rem 0;
`;

const ThumbnailImage = styled.img`
  display: block;
  width: 100%;
  max-height: 6rem;
  border-radius: ${Variables.borderRadius.MEDIUM};
  margin-bottom: 1rem;
  object-fit: cover;
`;

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-size: ${Variables.fontSizes.MEDIUM};
  font-weight: bold;
`;

const StyledSwiper = styled(Swiper)`
  && {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: stretch;
    cursor: pointer;
  }
`;
const StyledSwiperSlide = styled(SwiperSlide)`
  && {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
  }
`;
