import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminScansState } from '../states/admin-scans.state';
import { ListPaging } from '../../common/types';
import isNumber from 'lodash/isNumber';
import * as firebase from 'firebase/app';
import {
  deleteScanFromAdminListById,
  getAdminScanList,
  loadAllAppVersions,
  loadAllLotId,
} from '../thunks/admin/admin-scans.thunk';
import { Scan } from '../../common/model/dto/scan';
import { AxiosError } from 'axios';
import ReducerUtils from '../reducer.utils';
import {
  ScanFields,
  ScanMetaFields,
  ScanToolkitOutputFields,
} from '../../firebase/document-field.enums';
import { FilterType } from '../../common/model/ui/filter.type';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import {
  scanProcessingToolkitWarningCodes,
  scanProcessingToolkitErrorCodes,
} from '../../common/utils/services/scan-data-utils';
import { loadAllScanDevices } from '../thunks/admin/admin-scans.thunk';
import { Constants } from '../../common/constants';

const initialState: AdminScansState = {
  scanList: [],
  scanListPaging: {
    total: 0,
    offset: 0,
  },
  filters: [
    {
      fieldName: ScanFields.userId,
      label: 'common.scanDetails.results.userId',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ScanFields.lot_id,
      label: 'common.scanDetails.results.lotId',
      type: FilterType.select,
      value: '',
      options: [],
    },
    {
      fieldName: ScanToolkitOutputFields.toolkit_version,
      label: 'common.scanDetails.results.toolkitVersion',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ScanFields.lh,
      label: 'common.dailyData.lh',
      type: FilterType.slider,
      value: [Constants.LH_MIN, Constants.LH_MAX + 0.5],
      min: Constants.LH_MIN,
      max: Constants.LH_MAX + 0.5,
      convertTo: 'number',
    },
    {
      fieldName: ScanFields.pg,
      label: 'common.dailyData.pg',
      type: FilterType.slider,
      value: [Constants.PG_MIN, Constants.PG_MAX + 0.5],
      min: Constants.PG_MIN,
      max: Constants.PG_MAX + 0.5,
      convertTo: 'number',
    },
    {
      fieldName: ScanFields.lr_e3g,
      label: 'common.dailyData.e3g',
      type: FilterType.slider,
      value: [Constants.LR_E3G_MIN, Constants.LR_E3G_MAX + 0.5],
      min: Constants.LR_E3G_MIN,
      max: Constants.LR_E3G_MAX + 0.5,
      convertTo: 'number',
    },
    {
      fieldName: ScanToolkitOutputFields.warning_code,
      label: 'common.scanDetails.results.warning',
      type: FilterType.select,
      options:
        // @ts-ignore
        Object.keys(scanProcessingToolkitWarningCodes).map((code) => ({
          // @ts-ignore
          label: scanProcessingToolkitWarningCodes[code],
          value: code,
          translate: true,
        })),
      value: '',
    },
    {
      fieldName: ScanToolkitOutputFields.error_code,
      label: 'common.scanDetails.results.error',
      type: FilterType.select,
      options:
        // @ts-ignore
        Object.keys(scanProcessingToolkitErrorCodes).map((code) => ({
          // @ts-ignore
          label: scanProcessingToolkitErrorCodes[code],
          value: code,
          translate: true,
        })),
      value: '',
    },
    {
      fieldName: ScanToolkitOutputFields.warning_code,
      label: 'common.scanDetails.results.warningCode',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ScanToolkitOutputFields.error_code,
      label: 'common.scanDetails.results.errorCode',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ScanToolkitOutputFields.warning,
      label: 'scanList.hasWarning',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: 1,
        },
        {
          label: 'common.actions.no',
          value: 0,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: ScanToolkitOutputFields.error,
      label: 'scanList.hasError',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: 1,
        },
        {
          label: 'common.actions.no',
          value: 0,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: ScanMetaFields.os,
      label: 'common.os',
      type: FilterType.radio,
      options: [
        {
          label: 'IOS',
          value: 'ios',
        },
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'common.all',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: ScanMetaFields.app_version,
      label: 'common.appVersion',
      type: FilterType.select,
      value: '',
    },
    {
      fieldName: ScanMetaFields.device_model,
      label: 'common.deviceModel',
      type: FilterType.select,
      options: [],
      value: '',
    },
    {
      fieldName: ScanFields.is_demo_scan,
      label: 'scans.demo',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
  ],
  errorMessage: '',
  isLoading: false,
  storedQuery: '',
  areLotIdsLoading: false,
};

const adminScansSlice = createSlice({
  name: 'adminScans',
  initialState,
  reducers: {
    setScanListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.scanListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.scanListPaging.offset = action.payload.offset;
      }
    },
    clearScanList: (state) => {
      state.scanList = [];
      state.scanListPaging = initialState.scanListPaging;
    },
    clearSelectedScan: (state) => {
      state.selectedScan = null;
    },
    setStoredQuery: (state, action: PayloadAction<string>) => {
      state.storedQuery = action.payload;
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.filters = ReducerUtils.restoreFilters(
        action.payload,
        state.filters
      );
      state.scanListPaging.offset = action.payload.offset;
    },
    clearFilters: (state) => {
      state.filters = state.filters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.filters[index].value,
          disabled: false,
        };
      });
      state.storedQuery = '';
      state.scanListPaging.offset = 0;
    },
    changeListFilters: (state, action: PayloadAction<FilterModel>) => {
      const index = state.filters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      state.filters[index].value = FilterUtils.getParsedFilterValue(
        action.payload.value
      );
      state.scanListPaging.offset = 0;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getAdminScanList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAdminScanList.fulfilled,
        (state, action: PayloadAction<Scan[]>) => {
          state.scanList = action.payload;
          state.isLoading = false;
          state.errorMessage = '';
        }
      )
      .addCase(
        getAdminScanList.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteScanFromAdminListById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deleteScanFromAdminListById.fulfilled,
        (state, action: PayloadAction<Scan>) => {
          state.isLoading = false;
          state.scanList = state.scanList.filter(
            (scan) => scan.id !== action.payload
          );
        }
      )
      .addCase(
        deleteScanFromAdminListById.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadAllLotId.pending, (state) => {
        state.isLoading = true;
        state.areLotIdsLoading = true;
      })
      .addCase(
        loadAllLotId.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.isLoading = false;
          state.areLotIdsLoading = false;
          const lotIdsFilterIdx = state.filters.findIndex(
            (filter) => filter.fieldName === ScanFields.lot_id
          );

          action.payload.forEach((lotId) => {
            state.filters[lotIdsFilterIdx]?.options?.push({
              label: lotId,
              value: lotId,
            });
          });
        }
      )
      .addCase(
        loadAllLotId.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.areLotIdsLoading = false;
          state.errorMessage = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder.addCase(
      loadAllScanDevices.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        const scanDeviceFilterIdx = state.filters.findIndex(
          (filter) => filter.fieldName === ScanMetaFields.device_model
        );
        state.filters[scanDeviceFilterIdx].options = [];
        action.payload?.forEach((deviceName) => {
          state.filters[scanDeviceFilterIdx]?.options?.push({
            label: deviceName,
            value: deviceName,
          });
        });
      }
    );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder.addCase(
      loadAllAppVersions.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        const scanAppVersionFilterIdx = state.filters.findIndex(
          (filter) => filter.fieldName === ScanMetaFields.app_version
        );
        state.filters[scanAppVersionFilterIdx].options = [];
        action.payload?.forEach((appVersion) => {
          state.filters[scanAppVersionFilterIdx]?.options?.push({
            label: appVersion,
            value: appVersion,
          });
        });
      }
    );
  },
});

export const {
  clearScanList,
  clearSelectedScan,
  setScanListPaging,
  setStoredQuery,
  restoreSearchModel,
  changeListFilters,
  clearFilters,
} = adminScansSlice.actions;
export default adminScansSlice.reducer;
