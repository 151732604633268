const Variables = {
  borderRadius: {
    SMALL: '0.25rem',
    MEDIUM: '6px',
    LARGE: '10px',
    CLINIC_DASHBOARD_LARGE: '1.125rem',
    XLARGE: '1.75rem',
    CIRCLE: '50%',
  },
  fontSizes: {
    SMALL: '0.75rem',
    MEDIUM: '0.875rem',
    LARGE: '1rem',
    XLARGE: '1.25rem',
    XXLARGE: '2.8rem',
  },
  boxShadow: {
    defaultBox:
      '0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    alternateBox:
      '0 0.25rem 1.5rem rgb(82 77 62 / 10%),0 0.063rem 0.375rem rgb(82 77 62 / 8%)',
  },
};

export default Variables;
