import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  deleteScanFromAdminListById,
  getAdminScanList,
  loadAllAppVersions,
  loadAllLotId,
  restoreScanListFromUrl,
} from '../../redux/thunks/admin/admin-scans.thunk';
import { ScansService } from '../../services/scans.service';
import {
  changeListFilters,
  clearFilters,
  clearScanList,
  setScanListPaging,
  setStoredQuery,
} from '../../redux/reducers/admin-scans.slice';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvAdminScanList from '../UI/organisms/OvAdminScanList';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import OvListFilter from '../UI/organisms/OvListFilter';
import { FilterModel } from '../../common/model/ui/filter.model';
import { ScanMetaFields } from '../../firebase/document-field.enums';
import { loadAllScanDevices } from '../../redux/thunks/admin/admin-scans.thunk';
import { hasAppliedAdminScanFiltersSelector } from '../../redux/selectors/admin-scans.selectors';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import { useTranslation } from 'react-i18next';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';

const AdminScanList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);
  const scans = useAppSelector((state) => state.adminScans.scanList);
  const filters: FilterModel[] = useAppSelector(
    (state) => state.adminScans.filters
  );
  const isLoading = useAppSelector((state) => state.adminScans.isLoading);
  const storedQuery = useAppSelector((state) => state.adminScans.storedQuery);
  const errorMessage = useAppSelector((state) => state.adminScans.errorMessage);
  const scanListPaging = useAppSelector(
    (state) => state.adminScans.scanListPaging
  );
  const areLotIdsLoading = useAppSelector(
    (state) => state.adminScans.areLotIdsLoading
  );
  const areScanDevicesLoaded = (): boolean =>
    !!filters.find((filter) => filter.fieldName === ScanMetaFields.device_model)
      ?.options?.length;
  const areAppVersionsLoaded = (): boolean =>
    !!filters.find((filter) => filter.fieldName === ScanMetaFields.app_version)
      ?.options?.length;
  const hasAppliedFilters = useAppSelector(hasAppliedAdminScanFiltersSelector);

  useEffect(() => {
    setPage(scanListPaging.offset / ScansService.ADMIN_SCAN_LIST_LIMIT + 1);
  }, [scanListPaging.offset]);

  useEffect(() => {
    if (!areLotIdsLoading) {
      dispatch(loadAllLotId());
    }

    if (!areScanDevicesLoaded()) {
      dispatch(loadAllScanDevices());
    }

    if (!areAppVersionsLoaded()) {
      dispatch(loadAllAppVersions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search && search !== `?${storedQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(restoreScanListFromUrl(search));
    } else if (!storedQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(getAdminScanList(history));
    } else if (!search && storedQuery) {
      // jump back to the first page with side nav
      dispatch(
        setScanListPaging({
          offset: 0,
          total: scanListPaging.total,
        })
      );
      dispatch(getAdminScanList(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setStoredQuery(''));
      dispatch(clearScanList());
    };
  }, [dispatch]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setScanListPaging({
        offset: (value - 1) * ScansService.ADMIN_SCAN_LIST_LIMIT,
        total: scanListPaging.total,
      })
    );

    dispatch(getAdminScanList(history));
  };

  const deleteScanById = (scanId: string) => {
    dispatch(deleteScanFromAdminListById(scanId));
  };

  const pageCount: () => number = () =>
    Math.ceil(scanListPaging?.total / ScansService.ADMIN_SCAN_LIST_LIMIT);

  const removeFilters = () => {
    dispatch(clearFilters());
    dispatch(getAdminScanList(history));
  };

  const handleFilterValueChange = (filter: FilterModel) => {
    dispatch(changeListFilters(filter));
    dispatch(getAdminScanList(history));
  };

  return (
    <Container>
      <CenterPane>
        <HeaderItems>
          <OvPageTitle
            title={t('app.subPages.menu.scans')}
            icon={<DocumentScannerIcon />}
          />
        </HeaderItems>

        <ActionBar>
          <PaginationWrapper>
            {pageCount() > 1 && (
              <OvPagination
                page={page}
                onChange={goToPage}
                count={pageCount()}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                shape="rounded"
              />
            )}
          </PaginationWrapper>
        </ActionBar>

        {errorMessage && (
          <ErrorMessageWrapper>
            <OvErrorMessage message={errorMessage} />
          </ErrorMessageWrapper>
        )}

        {scans?.length ? (
          <ContentWrapper>
            <OvAdminScanList
              scans={scans}
              isLoading={isLoading}
              deleteScanCallback={deleteScanById}
            />
          </ContentWrapper>
        ) : null}

        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvListFilter
        filters={filters}
        isOpened={true}
        hasAppliedFilters={hasAppliedFilters}
        onRemoveFilters={removeFilters}
        onHandleFilterValueChange={handleFilterValueChange}
      />
    </Container>
  );
};

export default AdminScanList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
  flex-grow: 1;
`;

const ErrorMessageWrapper = styled.div`
  margin-top: 1rem;
`;

const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;
