import React, { Children, FC } from 'react';
import {
  Calendar,
  Formats,
  momentLocalizer,
  SlotInfo,
  View,
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/ov-custom-calendar.styles.scss';
import OvCalendarToolbar from '../molecules/OvCalendarToolbar';
import styled from 'styled-components';
import { Timestamp } from 'firebase/firestore';
import { DailyDataCalendarEvent } from '../../../common/model/ui/daily-data-calendar-event';

const localizer = momentLocalizer(moment);

const OvDateCellWrapper: FC<any> = ({ children }) => {
  return React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      cursor: 'pointer',
    },
  });
};

export interface OvCalendarProps {
  onNavigate?: ((date: Date, view: View) => any) | undefined;
  eventClass?: any;
  events?: any[];
  onSelect?: ((slotInfo: SlotInfo) => any) | undefined;
  style: any;
  selectedDay?: Timestamp;
  headerHoc: (
    events: DailyDataCalendarEvent[] | undefined,
    selectedDay?: Timestamp
  ) => FC<any>;
  defaultDate?: Date;
  children?: React.ReactNode;
}

const OvCalendarView: FC<OvCalendarProps> = ({
  onNavigate,
  events,
  onSelect,
  style,
  selectedDay,
  headerHoc,
  defaultDate,
}) => {
  const formats: Formats = {
    weekdayFormat: (date, culture, localizer) => {
      if (localizer) {
        return localizer.format(date, 'dddd', culture || '');
      }

      return date.toDateString();
    },
  };

  return (
    <Wrapper className="ov-calendar">
      <Calendar
        startAccessor="start"
        endAccessor="end"
        events={events}
        views={{ month: true }}
        step={20}
        showMultiDayTimes
        defaultDate={defaultDate}
        date={defaultDate}
        localizer={localizer}
        formats={formats}
        onNavigate={onNavigate}
        style={style}
        components={{
          toolbar: OvCalendarToolbar,
          dateCellWrapper: OvDateCellWrapper,
          month: {
            dateHeader: headerHoc(events, selectedDay),
            event: () => <></>,
          },
          eventWrapper: () => <></>,
        }}
        selectable={true}
        onSelectSlot={onSelect}
        onSelectEvent={(event) => alert(event.title)}
      />
    </Wrapper>
  );
};

export default OvCalendarView;

const Wrapper = styled.section`
  flex-grow: 1;
`;
