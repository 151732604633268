export class Constants {
  static readonly LH_LOWEST_FOR_HIGH_PERCENT = 0.1666666666;
  static readonly LH_LOWEST_FOR_PEAK_PERCENT = 0.2666666666;
  static readonly PG_LOWEST_FOR_HIGH_PERCENT = 0.32;
  static readonly LR_E3G_FERTILE_WINDOW_THRESHOLD = 150;

  static readonly PG_MAX = 22.5;
  static readonly PG_MIN = 0.0;
  static readonly LH_MAX = 125.0;
  static readonly LH_MIN = 0.0;
  static readonly LR_E3G_MAX = 500.0;
  static readonly LR_E3G_CHART_MAX = 550.0;
  static readonly LR_E3G_MIN = 0.0;

  static readonly INCH_IN_CM = 2.54;
  static readonly FEET_IN_CM = 30.48;

  static readonly DEFAULT_CUT_OFF = 0.2;
  static readonly DEFAULT_FIRST_THIRD = 0.33333;
  static readonly DEFAULT_SECOND_THIRD = 0.66667;

  // Unit of measures
  static readonly LH_UNIT_OF_MEASURE = 'mIU/L';
  static readonly PG_UNIT_OF_MEASURE = 'ng/mL';
  static readonly E3G_UNIT_OF_MEASURE = 'ng/mL';
  static readonly TEMPERATURE_UNIT_OF_MEASURE = '°F';

  static readonly DAY_FORMAT = 'yyyy-MM-dd';
  static readonly DATE_DISPLAY_FORMAT = 'DD MMMM, YYYY';
  static readonly US_DATE_DISPLAY_FORMAT = 'MMM-DD-YYYY, hh:mm';

  static readonly LOCAL_STORAGE_KEYS = {
    FS_SELECTED_PROVIDER_COUNTRY: 'fsSelectedProviderCountry',
    FS_SESSION_GRANT: 'oova::360::fs::session::grant',
  };
  static readonly SESSION_STORAGE_KEYS = {
    LOCATION_BEFORE_AUTHENTICATION:
      'oova::360::location::before::authentication',
  };

  static readonly IS_PRODUCTION = process.env.REACT_APP_ENV === 'prod';

  static readonly CALORIES_UNIT = 'kcal';
  static readonly HEART_RATE_UNIT = 'BPM';
}
