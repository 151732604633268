import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminConfigurationService } from '../../../services/admin/admin-configuration.service';
import { Configuration } from '../../../common/model/dto/configuration';

export const getLatestConfiguration = createAsyncThunk(
  'adminConfiguration/getLatest',
  async (_, { rejectWithValue }) => {
    try {
      return await AdminConfigurationService.getLatestConfiguration();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateConfiguration = createAsyncThunk(
  'adminConfiguration/update',
  async (configuration: Configuration, { rejectWithValue }) => {
    try {
      return await AdminConfigurationService.updateConfiguration(configuration);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clearConfigurationCache = createAsyncThunk(
  'adminConfiguration/clearCache',
  async (_, { rejectWithValue }) => {
    try {
      return await AdminConfigurationService.clearConfigurationCache();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
