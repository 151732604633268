import React, { FC } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import styled from 'styled-components';

const OvGooglePlacesAutocompleteOptions: FC<{
  option: google.maps.places.AutocompletePrediction;
}> = ({ option, ...props }) => {
  const matches =
    option.structured_formatting.main_text_matched_substrings || [];
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length])
  );

  return (
    <li {...props}>
      <OptionContainer>
        <LocationOnIcon style={{ marginRight: '1rem' }} />
        <OptionWrapper>
          {parts.map((part, index) => (
            <span
              key={`${option.place_id}-${index}`}
              style={{
                fontWeight: part.highlight ? 'bold' : 'regular',
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </OptionWrapper>
      </OptionContainer>
    </li>
  );
};

export default OvGooglePlacesAutocompleteOptions;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OptionWrapper = styled.div`
  width: 100%;
  word-wrap: break-word;
`;
