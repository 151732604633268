import React, { FC } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import {
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvNoContent from './OvNoContent';
import { useTranslation } from 'react-i18next';

interface OvUserTransferListCustomListProps {
  users: UserInfo[];
  handleToggle: (user: UserInfo) => any;
  checkedUsers: UserInfo[];
  sectionTitle: string;
  isSearchList: boolean;
  isLoading: boolean;
}

const OvUserTransferListCustomList: FC<OvUserTransferListCustomListProps> = ({
  users,
  handleToggle,
  checkedUsers,
  sectionTitle,
  isSearchList,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledSectionTitle>{sectionTitle}</StyledSectionTitle>
      <StyledListContainer>
        <List>
          {users.map((user) => {
            const labelId = `transfer-list-item-${user.id}-label`;

            return (
              <ListItemButton
                key={user.id}
                role="listitem"
                onClick={handleToggle(user)}
              >
                <ListItemIcon key={`list-item-${user.id}`}>
                  <Checkbox
                    key={`checkbox-${user.id}`}
                    checked={checkedUsers.includes(user)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: Colours.OV_BASE,
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemTextContainer>
                  <StyledListItemText
                    key={`label-id-${user.first_name}-${user.id}`}
                    id={labelId}
                    primary={`${user.first_name} ${user.last_name}`}
                  />
                  <StyledListItemText
                    key={`label-id-${user.email}`}
                    id={labelId}
                    primary={`${user.email}`}
                  />
                </ListItemTextContainer>
              </ListItemButton>
            );
          })}
        </List>
        {users.length === 0 && isSearchList && !isLoading && (
          <OvNoContent style={{ padding: '1rem' }}>
            {t('common.userSearchFilters.noUsersFound')}
          </OvNoContent>
        )}
      </StyledListContainer>
    </>
  );
};

export default OvUserTransferListCustomList;

const StyledListContainer = styled.div`
  width: 100%;
  height: 20rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  overflow: auto;
  max-width: 12.5rem;
`;

const StyledSectionTitle = styled.h4`
  margin: 0;
  padding: 1rem 0;
`;

const ListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledListItemText = styled(ListItemText)`
  && {
    margin: 0;
    .MuiListItemText-primary {
      font-size: 0.65rem;
      word-break: break-all;
      padding: 0;
    }
  }
`;
