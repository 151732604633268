import React, { useEffect, useState } from 'react';
import { Product } from '../../common/model/dto/product/product';
import styled from 'styled-components';
import breakpoints from '../../design-system/breakpoints';
import Variables from '../../design-system/variables';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useTranslation } from 'react-i18next';
import OvBackButton from '../UI/atoms/OvBackButton';
import OvProductGeneralInfo, {
  ProductGeneralInfo,
} from '../UI/molecules/OvProductGeneralInfo';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import OvImageUpload from '../UI/molecules/OvImageUpload';
import {
  PRICE_IMAGES_PATH,
  PRICE_THUMBNAIL_IMAGES_PATH,
  PRODUCT_IMAGES_PATH,
} from '../../api/firebase-storage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearError,
  clearSelectedProduct,
} from '../../redux/reducers/products.slice';
import {
  createProduct,
  getProductById,
  updateProduct,
  updateProductAndGoToDetails,
} from '../../redux/thunks/products.thunk';
import { useHistory, useParams } from 'react-router-dom';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import Colours from '../../design-system/colours';
import { CreateProductRequest } from '../../common/model/dto/product/create-product-request';
import { UpdateProductRequest } from '../../common/model/dto/product/update-product-request';
import OvProductPriceInfo, {
  ProductPriceInfo,
} from '../UI/molecules/OvProductPriceInfo';

const ProductForm: React.FC = () => {
  let { productId }: any = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.products.loading);
  const errorMessage = useAppSelector((state) => state.products.error);
  const product: Product = useAppSelector(
    (state) => state.products.selectedProduct
  );

  const [productGeneralInfo, setProductGeneralInfo] = useState<{
    values: ProductGeneralInfo;
    isValid: boolean;
  }>();
  const [productPriceInfo, setProductPriceInfo] = useState<{
    values: ProductPriceInfo;
    isValid: boolean;
  }>();
  const [stripeProductImagePath, setStripeProductImagePath] =
    useState<string>();
  const [priceImagePath, setPriceImagePath] = useState<string>();
  const [priceThumbnailImagePath, setPriceThumbnailImagePath] =
    useState<string>();
  const [isEnabled, setEnabled] = useState<boolean>(false);
  const [isEnabledForMobile, setIsEnabledForMobile] = useState<boolean>(false);
  const [isEnabledForWeb, setIsEnabledForWeb] = useState<boolean>(false);

  useEffect(() => {
    if (productId) {
      dispatch(getProductById(productId));
    }

    return () => {
      dispatch(clearSelectedProduct());
    };
  }, [productId, dispatch]);

  useEffect(() => {
    if (product) {
      initForm();
    }

    return () => {
      dispatch(clearError());
    };
    // eslint-disable-next-line
  }, [dispatch, product]);

  const initForm = () => {
    setEnabled(!!product.is_enabled);
    setIsEnabledForMobile(!!product.is_enabled_for_mobile);
    setIsEnabledForWeb(!!product.is_enabled_for_web);
    setProductGeneralInfo({
      values: {
        title: product?.title,
        subtitle: product?.subtitle,
        stripe_checkout_page_description:
          product?.stripe_checkout_page_description,
        stripe_checkout_page_title: product.stripe_checkout_page_title,
        order: product?.order,
      },
      isValid: true,
    });
    setStripeProductImagePath(product?.stripe_product_image_url);
  };

  const handleGeneralInfoChange = (
    productGeneralInfo: ProductGeneralInfo,
    isValid: boolean
  ) => {
    setProductGeneralInfo({
      values: productGeneralInfo,
      isValid,
    });
  };

  const handleProductPriceInfoChange = (
    productPriceInfo: ProductPriceInfo,
    isValid: boolean
  ) => {
    setProductPriceInfo({
      values: productPriceInfo,
      isValid,
    });
  };

  const handleSuccessfulPriceImageUpload = ({
    image_path,
  }: {
    image_path: string;
  }) => {
    setPriceImagePath(image_path);
  };

  const handleSuccessfulPriceThumbnailImageUpload = ({
    image_path,
  }: {
    image_path: string;
  }) => {
    setPriceThumbnailImagePath(image_path);
  };

  const handleSuccessfulProductImageUpload = ({
    image_path,
  }: {
    image_path: string;
  }) => {
    setStripeProductImagePath(image_path);
  };

  const saveProduct = () => {
    if (canSaveProductChanges()) {
      if (!product) {
        const productDTO: CreateProductRequest = {
          is_enabled: isEnabled,
          is_enabled_for_mobile: isEnabledForMobile,
          is_enabled_for_web: isEnabledForWeb,
          title: productGeneralInfo?.values?.title,
          subtitle: productGeneralInfo?.values?.subtitle,
          stripe_product_image_url: stripeProductImagePath,
          stripe_checkout_page_title:
            productGeneralInfo?.values?.stripe_checkout_page_title,
          stripe_checkout_page_description:
            productGeneralInfo?.values?.stripe_checkout_page_description,
          price_title: productPriceInfo?.values?.price_title,
          price_amount: productPriceInfo?.values?.price_amount,
          description: productPriceInfo?.values?.description,
          recurring_price_properties: {
            interval:
              productPriceInfo?.values?.recurring_price_properties?.interval,
            interval_count:
              productPriceInfo?.values?.recurring_price_properties
                ?.interval_count,
          },
          cta: productPriceInfo?.values?.price_title,
          is_b2b: productPriceInfo?.values?.is_b2b,
          sku: productPriceInfo?.values?.sku,
          image_url: priceImagePath,
          thumbnail_image_url: priceThumbnailImagePath,
          stripe_checkout_redirect_url:
            productPriceInfo?.values?.stripe_checkout_redirect_url,
          is_booster_kit: productPriceInfo?.values?.is_booster_kit,
          order: productGeneralInfo?.values?.order,
          price_order: productPriceInfo?.values?.order,
          discount_details: {
            recent_price:
              productPriceInfo?.values?.discount_details?.recent_price,
            badge_text: productPriceInfo?.values?.discount_details?.badge_text,
            badge_text_color:
              productPriceInfo?.values?.discount_details?.badge_text_color,
            badge_background_color:
              productPriceInfo?.values?.discount_details
                ?.badge_background_color,
          },
        };

        dispatch(
          createProduct({
            history,
            product: productDTO,
          })
        );
      } else {
        const productDTO: UpdateProductRequest = {
          ...product,
          is_enabled: isEnabled,
          title: productGeneralInfo?.values.title,
          subtitle: productGeneralInfo?.values.subtitle,
          stripe_checkout_page_title:
            productGeneralInfo?.values?.stripe_checkout_page_title,
          stripe_checkout_page_description:
            productGeneralInfo?.values?.stripe_checkout_page_description,
          order: productGeneralInfo?.values?.order,
        };

        dispatch(
          updateProductAndGoToDetails({
            history,
            product: productDTO,
            productId: product.id!,
          })
        );
      }
    }
  };

  const handleStatusChange = (e: any) => {
    const is_enabled = e.target.checked;
    setEnabled(is_enabled);

    // if we are updating the product, don't need to click on "Update" cta to save is_enabled
    if (product && product.id) {
      dispatch(
        updateProduct({
          productId: product.id,
          updateProductRequest: { is_enabled },
        })
      );
    }
  };

  const handleEnabledForWebChange = (e: any) => {
    const isEnabledForWeb = e.target.checked;
    setIsEnabledForWeb(isEnabledForWeb);

    // if we are updating the product, don't need to click on "Update" cta to save is_enabled
    if (product && product.id) {
      dispatch(
        updateProduct({
          productId: product.id,
          updateProductRequest: { is_enabled_for_web: isEnabledForWeb },
        })
      );
    }
  };

  const handleEnabledForMobileChange = (e: any) => {
    const isEnabledForMobile = e.target.checked;
    setIsEnabledForMobile(isEnabledForMobile);

    // if we are updating the product, don't need to click on "Update" cta to save is_enabled
    if (product && product.id) {
      dispatch(
        updateProduct({
          productId: product.id,
          updateProductRequest: { is_enabled_for_mobile: isEnabledForMobile },
        })
      );
    }
  };

  const canSaveProductChanges = (): boolean => {
    if (!product) {
      return (
        !!productGeneralInfo?.values &&
        !!productGeneralInfo?.values?.order &&
        !!productPriceInfo?.values &&
        !!stripeProductImagePath &&
        !!priceImagePath &&
        !!priceThumbnailImagePath
      );
    }

    return (
      !!productGeneralInfo?.values &&
      !!stripeProductImagePath &&
      !!productGeneralInfo?.values?.order &&
      productGeneralInfo?.values?.order > 0
    );
  };

  return (
    <Wrapper>
      <Header>
        <StretchedDiv>
          <OvBackButton label={t('common.actions.back')} />
        </StretchedDiv>
        <OvPageTitle
          title={product ? t('products.update') : t('products.create')}
          styles={{ justifyContent: 'center' }}
          icon={<StyledStoreIcon />}
        />
        <StretchedDiv>
          <OvCompactButton
            onClick={saveProduct}
            disabled={!canSaveProductChanges()}
            style={{ marginLeft: 'auto' }}
            icon={<StyledAddBusinessIcon />}
          >
            {product ? t('products.update') : t('products.create')}
          </OvCompactButton>
        </StretchedDiv>
      </Header>

      <StyledSwitchContainer>
        <FormControlLabel
          checked={isEnabled}
          onChange={handleStatusChange}
          control={<StyledSwitch />}
          label={t('products.fields.enabledStatus')}
          labelPlacement="start"
        />
        <FormControlLabel
          checked={isEnabledForWeb}
          onChange={handleEnabledForWebChange}
          control={<StyledSwitch />}
          label={t('products.fields.isForWeb')}
          labelPlacement="start"
        />
        <FormControlLabel
          checked={isEnabledForMobile}
          onChange={handleEnabledForMobileChange}
          control={<StyledSwitch />}
          label={t('products.fields.isForMobile')}
          labelPlacement="start"
        />
      </StyledSwitchContainer>

      <Content>
        <ContentGroup>
          <SectionTitle>{t('products.details.generalInfo')}</SectionTitle>

          <OvProductGeneralInfo
            generalInfo={productGeneralInfo?.values}
            onChangeGeneralInfo={handleGeneralInfoChange}
          />
        </ContentGroup>

        <ContentGroup>
          <SectionTitle>
            {t('products.details.stripeCheckoutPageImage')}
          </SectionTitle>

          <OvImageUpload
            imageBasePath={PRODUCT_IMAGES_PATH}
            imageUrl={product?.stripe_product_image_url}
            title={t('products.images.stripeProductImage')}
            hintText={t('products.images.productHint')}
            ctaLabel={t('imageUpload.uploadImage')}
            dialogTitle={t('products.images.uploadDialogTitle')}
            handleChange={handleSuccessfulProductImageUpload}
          />
        </ContentGroup>
      </Content>
      {!product && !isLoading && (
        <Content>
          <ContentGroup>
            <SectionTitle>{t('products.details.defaultPrice')}</SectionTitle>

            <OvProductPriceInfo
              productPriceInfo={productPriceInfo?.values}
              onChangeProductPriceInfo={handleProductPriceInfoChange}
            />
          </ContentGroup>

          <ContentGroup>
            <SectionTitle>
              {t('productPrices.images.priceImageAndThumbnailImage')}
            </SectionTitle>

            <OvImageUpload
              imageBasePath={PRICE_IMAGES_PATH}
              imageUrl={undefined}
              title={t('productPrices.images.priceImage')}
              hintText={t('products.images.productHint')}
              ctaLabel={t('imageUpload.uploadImage')}
              dialogTitle={t('productPrices.images.uploadDialogTitle')}
              handleChange={handleSuccessfulPriceImageUpload}
            />

            <OvImageUpload
              imageBasePath={PRICE_THUMBNAIL_IMAGES_PATH}
              imageUrl={undefined}
              title={t('productPrices.images.priceThumbnailImage')}
              hintText={t('products.images.productHint')}
              ctaLabel={t('imageUpload.uploadImage')}
              dialogTitle={t('products.images.thumbnailUploadDialogTitle')}
              handleChange={handleSuccessfulPriceThumbnailImageUpload}
            />
          </ContentGroup>
        </Content>
      )}

      {errorMessage && (
        <Box>
          <OvErrorMessage message={errorMessage} />
        </Box>
      )}

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Wrapper>
  );
};

export default ProductForm;

const Wrapper = styled.div`
  margin-left: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 1rem;
  }
`;

const Box = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Header = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  align-items: center;
`;

const StretchedDiv = styled.div`
  flex-grow: 1;
`;

const StyledStoreIcon = styled(StoreIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledAddBusinessIcon = styled(AddBusinessIcon)`
  && {
    margin-left: 0.25rem;
  }
`;

const StyledSwitchContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 1.5rem 0;
  padding: 1rem 1rem 1rem 0.5rem;
`;

const StyledSwitch = styled(Switch)`
  && {
    .MuiSwitch-thumb {
      color: ${Colours.OV_BASE};
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 1.5rem 0;
`;

const ContentGroup = styled(Box)`
  flex-grow: 1;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}) {
    flex-grow: 1;
    flex-basis: calc(50% - 1.5rem);
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-grow: 1;
    flex-basis: calc(25% - 2.25rem);
  }
`;

const SectionTitle = styled.h3`
  margin: 0 0 1rem 0;
  text-align: center;
  font-size: ${Variables.fontSizes.LARGE};
`;
