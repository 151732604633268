import styled from 'styled-components';
import OvButton from './OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import React, { ReactElement, ReactNode } from 'react';
import { ButtonProps } from '@mui/material/Button';

interface OvCompactButtonProps {
  icon?: ReactElement;
  children?: ReactNode;
}

const OvCompactButton: React.FC<OvCompactButtonProps & ButtonProps> = ({
  icon,
  children,
  ...props
}) => {
  return (
    <StyledOvButton {...props}>
      {icon ? <IconWrapper>{icon}</IconWrapper> : ''}
      {children}
    </StyledOvButton>
  );
};

export default OvCompactButton;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-left: 0.25rem;
`;
