import axios from 'axios';
import { ProductPrice } from '../../common/model/dto/product/product-price';
import { ADMIN_PRODUCT_PRICE_ROUTE } from '../../api/backend';
import { UpdateProductPriceRequest } from '../../common/model/dto/product/update-product-price-request';
import { AddPriceToProductRequest } from '../../common/model/dto/product/add-price-to-product-request';
import { Paginated } from '../../common/types';

export class AdminProductPriceService {
  static PRODUCT_PRICE_LIST_LIMIT = 10;

  static async getProductPrices(
    productId: string,
    query: string
  ): Promise<Paginated<ProductPrice>> {
    const { data } = await axios.get<Paginated<ProductPrice>>(
      `${ADMIN_PRODUCT_PRICE_ROUTE}/products/${productId}?${query}`
    );

    return data;
  }

  static async getProductPriceById(
    productPriceId: string
  ): Promise<ProductPrice> {
    const { data } = await axios.get<ProductPrice>(
      `${ADMIN_PRODUCT_PRICE_ROUTE}/${productPriceId}`
    );

    return data;
  }

  static async addPriceToProduct(
    productId: string,
    addPriceToProductRequest: AddPriceToProductRequest
  ) {
    const { data } = await axios.post<ProductPrice>(
      `${ADMIN_PRODUCT_PRICE_ROUTE}/products/${productId}/prices`,
      addPriceToProductRequest
    );

    return data;
  }

  static async updateProductPrice(
    productPriceId: string,
    updateProductPriceRequest: UpdateProductPriceRequest
  ) {
    const { data } = await axios.patch<ProductPrice>(
      `${ADMIN_PRODUCT_PRICE_ROUTE}/${productPriceId}`,
      updateProductPriceRequest
    );

    return data;
  }

  static async archiveProductPrice(
    productPriceId: string
  ): Promise<ProductPrice> {
    const { data } = await axios.delete<ProductPrice>(
      `${ADMIN_PRODUCT_PRICE_ROUTE}/${productPriceId}`
    );

    return data;
  }
}
