import {
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../../common/model/dto/user-info';
import { ClinicLocation } from '../../../common/model/dto/clinic-location';
import { StripeUtils } from '../../../common/utils/services/stripe-utils';
import { useFormik } from 'formik';
import { SendPaymentLinkToUserRequest } from '../../../common/model/dto/order/send-payment-link-to-user-request';
import * as Yup from 'yup';
import OvTextField from '../atoms/OvTextField';
import UserService from '../../../services/user.service';
import { debounce } from 'lodash';
import { UserInfoFields } from '../../../firebase/document-field.enums';
import DoneIcon from '@mui/icons-material/Done';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { SendgridService } from '../../../services/sendgrid.service';
import OvSnackbar from './OvSnackbar';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getProviderDefaultStripeLink } from '../../../redux/thunks/configuration.thunk';

const REQUEST_DELAY = 1000;

const OvShopDialog: FC<{
  isOpen: boolean;
  onClose: () => void;
  currentUser?: UserInfo;
  selectedClinicLocation?: ClinicLocation;
}> = ({ isOpen, onClose, currentUser, selectedClinicLocation }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<UserInfo | undefined>(undefined);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const [isEmailSendingLoading, setIsEmailSendingLoading] =
    useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentLink, setPaymentLink] = useState<string | undefined>(undefined);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const isStripeLinkLoading: boolean = useAppSelector(
    (state) => state.configuration.isLoading
  );
  const providerDefaultStripeLink: string | undefined = useAppSelector(
    (state) => state.configuration.providerDefaultStripeLink
  );
  const isLoading =
    isUserLoading || isEmailSendingLoading || isStripeLinkLoading;

  const formik = useFormik<SendPaymentLinkToUserRequest>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
    },
    onSubmit: (request: SendPaymentLinkToUserRequest) => {
      handleSendEmail(request);
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required(t('clinicLocationDialogs.emailRequired')),
      first_name: Yup.string().required(
        t('clinicLocationDialogs.firstNameRequired')
      ),
      last_name: Yup.string().required(
        t('clinicLocationDialogs.lastNameRequired')
      ),
    }),
    validateOnBlur: true,
  });

  useEffect(() => {
    dispatch(getProviderDefaultStripeLink());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && selectedClinicLocation && providerDefaultStripeLink) {
      setPaymentLink(
        `${providerDefaultStripeLink}?${StripeUtils.populatePaymentLinkWithData(
          currentUser,
          selectedClinicLocation,
          currentUser.email
        )}`
      );
    }

    return () => {
      setPaymentLink(providerDefaultStripeLink);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedClinicLocation, providerDefaultStripeLink]);

  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setUser(undefined);
      formik.resetForm();
    }

    if (currentUser && selectedClinicLocation && providerDefaultStripeLink) {
      setPaymentLink(
        `${providerDefaultStripeLink}?${StripeUtils.populatePaymentLinkWithData(
          currentUser,
          selectedClinicLocation,
          currentUser.email
        )}`
      );
    }

    return () => {
      setPaymentLink(providerDefaultStripeLink);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  const fetchUserByEmail = (email: string) => {
    return UserService.getUserInfoByEmail(email);
  };

  // eslint-disable-next-line
  const getUserDelayed = useCallback(
    debounce((inputValue) => {
      (async () => {
        if (inputValue.length >= 4) {
          setIsUserLoading(true);
          const user: UserInfo = await fetchUserByEmail(inputValue);

          if (user) {
            setUser(user);
            setIsUserLoading(false);
          }
        }

        setIsUserLoading(false);
      })();
    }, REQUEST_DELAY),
    []
  );

  useEffect(() => {
    if (user && selectedClinicLocation && currentUser) {
      formik.getFieldHelpers(UserInfoFields.email).setValue(user.email);
      formik
        .getFieldHelpers(UserInfoFields.first_name)
        .setValue(user.first_name);
      formik.getFieldHelpers(UserInfoFields.last_name).setValue(user.last_name);
    }

    // eslint-disable-next-line
  }, [user]);

  const handleChange = async (e: any) => {
    setUser(undefined);
    if (user) {
      formik.getFieldHelpers(UserInfoFields.first_name).setValue('');
      formik.getFieldHelpers(UserInfoFields.last_name).setValue('');
    }

    const inputField = e.target.name;
    const inputValue = e.target.value;

    if (
      inputField === UserInfoFields.email &&
      !formik.errors.email &&
      currentUser &&
      selectedClinicLocation &&
      providerDefaultStripeLink
    ) {
      getUserDelayed(inputValue);

      setPaymentLink(
        `${providerDefaultStripeLink}?${StripeUtils.populatePaymentLinkWithData(
          currentUser,
          selectedClinicLocation,
          inputValue
        )}`
      );
    }

    formik.handleChange(e);
  };

  const handleGoBack = () => {
    setUser(undefined);
    setActiveStep(0);
    formik.resetForm();
  };

  const handleSendEmail = async (request: SendPaymentLinkToUserRequest) => {
    try {
      if (currentUser && selectedClinicLocation?.name && paymentLink) {
        setIsEmailSendingLoading(true);
        await SendgridService.invitePatientToOrder(
          request.email,
          `${request.first_name} ${request.last_name}`,
          `${currentUser?.first_name} ${currentUser?.last_name}`,
          paymentLink,
          selectedClinicLocation?.name
        );
        setIsEmailSendingLoading(false);

        setSnackbarProps(
          'success',
          t('userDetails.actions.emailSentSuccessfully'),
          true
        );
      }
    } catch (error) {
      setIsEmailSendingLoading(false);
      setSnackbarProps(
        'error',
        t('userDetails.actions.emailSendingFailed'),
        true
      );
    } finally {
      onClose();
    }
  };

  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: boolean
  ) => {
    setSnackbarSeverity(severity);
    setSnackBarMessage(message);
    setIsSnackbarOpen(isOpen);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        {activeStep === 0 && (
          <>
            <StyledDialogTitle>
              {t('dashboard.shop.dialogTitle')}
            </StyledDialogTitle>
            <StyledDialogContent>
              <StyledLink href={paymentLink} target="_blank" rel="noreferrer">
                <StyledChooseOrderTypeButton onClick={onClose}>
                  <StyledButtonTitle>
                    {t('dashboard.shop.proceedToCheckoutCta')}
                  </StyledButtonTitle>
                  <StyledButtonDescription>
                    {t('dashboard.shop.proceedToCheckoutDescription')}
                  </StyledButtonDescription>
                </StyledChooseOrderTypeButton>
              </StyledLink>
              <StyledChooseOrderTypeButton onClick={() => setActiveStep(1)}>
                <StyledButtonTitle>
                  {t('dashboard.shop.invitePatientToOrderCta')}
                </StyledButtonTitle>
                <StyledButtonDescription>
                  {t('dashboard.shop.invitePatientToOrderDescription')}
                </StyledButtonDescription>
              </StyledChooseOrderTypeButton>
            </StyledDialogContent>
          </>
        )}

        {activeStep === 1 && (
          <form onSubmit={formik.handleSubmit} onChange={handleChange}>
            <StyledDialogContent>
              <StyledDialogHeader>
                <StyledDialogTitle>
                  {t('dashboard.shop.invitePatientToOrderCta')}
                </StyledDialogTitle>
                {user && (
                  <UserFoundMessage>
                    <DoneIcon style={{ marginRight: '5' }} />
                    {t('dashboard.shop.userFoundWithEmailText')}
                  </UserFoundMessage>
                )}
              </StyledDialogHeader>
              <StyledTextField
                type="email"
                fullWidth
                autoComplete="off"
                label={t('common.userFields.email')}
                error={formik.touched.email && !!formik.errors.email}
                helperText={
                  formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email
                }
                {...formik.getFieldProps('email')}
              />
              <StyledTextField
                type="text"
                fullWidth
                autoComplete="off"
                disabled={isLoading || !!user}
                label={t('common.userFields.firstName')}
                error={formik.touched.first_name && !!formik.errors.first_name}
                helperText={
                  formik.errors.first_name &&
                  formik.touched.first_name &&
                  formik.errors.first_name
                }
                {...formik.getFieldProps('first_name')}
              />
              <StyledTextField
                type="text"
                fullWidth
                autoComplete="off"
                disabled={isLoading || !!user}
                label={t('common.userFields.lastName')}
                error={formik.touched.last_name && !!formik.errors.last_name}
                helperText={
                  formik.errors.last_name &&
                  formik.touched.last_name &&
                  formik.errors.last_name
                }
                {...formik.getFieldProps('last_name')}
              />
              <StyledDialogActions>
                <StyledLightOvButton onClick={handleGoBack}>
                  {t('common.actions.back')}
                </StyledLightOvButton>
                <StyledLightOvButton onClick={onClose}>
                  {t('common.actions.cancel')}
                </StyledLightOvButton>
                <StyledOvButton type="submit" autoFocus>
                  {t('common.actions.send')}
                </StyledOvButton>
              </StyledDialogActions>
            </StyledDialogContent>
            {isLoading && <OvLoadingIndicator position="fixed" />}
          </form>
        )}
      </Dialog>

      <OvSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackBarMessage}
      />
    </>
  );
};

export default OvShopDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 1.5rem 2rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledChooseOrderTypeButton = styled(OvButton)`
  &&  {
    padding: 1rem;
    border-radius: ${Variables.borderRadius.MEDIUM};
    text-transform: none;
    color: ${Colours.OV_BASE};
    background-color: ${Colours.OV_WHITE};
    border: 1px solid ${Colours.OV_BASE};
    display: flex;
    flex-direction: column;
    box-shadow: ${Variables.boxShadow.alternateBox};
    margin-bottom: 1rem;
    text-align: justify;
  }
`;

const StyledButtonTitle = styled.h3``;

const StyledButtonDescription = styled.p`
  font-size: 0.75rem;
`;

const StyledLink = styled.a`
  height: 100%;
  width: 100%;
  text-decoration: none;
`;

const StyledTextField = styled(OvTextField)`
  && {
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 1.5rem;
    }
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0;
`;

const UserFoundMessage = styled.h4`
  color: ${Colours.OV_GREEN};
  display: flex;
`;
