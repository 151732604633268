import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import OvSelect from '../atoms/OvSelect';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useFormik } from 'formik';
import { PublishNotificationRequest } from '../../../common/model/dto/notification/publish-notification-request';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';
import { HealthGoalType } from '../../../common/model/dto/health-goal-type.enum';
import OvConfirmationDialog from './OvConfirmationDialog';
import SendIcon from '@mui/icons-material/Send';
import OvDateTimePicker from './OvDateTimePicker';
import { DATE_TIME_PICKER_TIMEZONES } from '../../../common/timezones';
import moment from 'moment-timezone';
import { parseISO } from 'date-fns';
import { Constants } from '../../../common/constants';
import OvTextField from '../atoms/OvTextField';
import OvTransferListCustomList from './OvUserTransferListCustomList';
import { UserInfo } from '../../../common/model/dto/user-info';
import UserService from '../../../services/user.service';
import { stringify } from 'query-string';
import { debounce } from 'lodash';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface OvPublishNotificationContentDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (request: any) => void;
}

enum PublishNotificationContentTabType {
  PUBLISH_NOW = 'publish_now',
  SCHEDULE = 'schedule',
}

const OvPublishNotificationContentDialog: FC<
  OvPublishNotificationContentDialogProps
> = ({ isOpen, onCancel, onSave }) => {
  const { t } = useTranslation();
  const publishToOptions = ['All users', 'Subset of users', 'Specific users'];
  const osOptions = ['iOS', 'Android'];
  const healthGoalOptions = [
    HealthGoalType.TRYING_TO_CONCEIVE,
    HealthGoalType.PERIMENOPAUSE_TRACKING,
    HealthGoalType.GENERAL_HEALTH_MONITORING,
  ];
  const [selectedOption, setSelectedOption] = useState(publishToOptions[0]);

  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<PublishNotificationContentTabType>(
    PublishNotificationContentTabType.PUBLISH_NOW
  );
  const [userSearchInput, setUserSearchInput] = useState<string>('');
  const [transferListLeftItems, setTransferListLeftItems] = useState<
    UserInfo[]
  >([]);
  const [transferListRightItems, setTransferListRightItems] = useState<
    UserInfo[]
  >([]);
  const [checkedItems, setCheckedItems] = useState<UserInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik<PublishNotificationRequest>({
    initialValues: {
      health_goal: '',
      os: '',
      user_ids: [],
      schedule_to: undefined,
    },
    onSubmit: (request) => {
      onSave(request);
      setIsDialogOpen(false);
      onCancel();
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setSelectedOption('All users');
      setActiveTab(PublishNotificationContentTabType.PUBLISH_NOW);
      setSelectedDate(undefined);
      setUserSearchInput('');
      setTransferListLeftItems([]);
      setTransferListRightItems([]);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const fetchUsers = async (searchInput: string) => {
      try {
        setIsLoading(true);
        const response = await UserService.loadUsersByQuery(
          stringify({
            limit: 20,
            search_text: searchInput,
            order_by: 'first_name',
            order_dir: 'asc',
          })
        );

        const users = response.docs.filter(
          (user) =>
            !transferListRightItems.some(
              (rightUserItem) => rightUserItem.id === user.id
            )
        );

        setTransferListLeftItems(users);
      } catch (error: any) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const debouncedFetchUsers = debounce(fetchUsers, 500);
    if (userSearchInput) {
      debouncedFetchUsers(userSearchInput);
    }

    return () => {
      debouncedFetchUsers.cancel();
    };

    // eslint-disable-next-line
  }, [userSearchInput]);

  useEffect(() => {
    formik
      .getFieldHelpers('user_ids')
      .setValue(transferListRightItems.map((user) => user.id));

    // eslint-disable-next-line
  }, [transferListRightItems]);

  useEffect(() => {
    if (selectedOption === 'Specific users') {
      const fetchUsers = async () => {
        try {
          setTransferListLeftItems([]);
          setIsLoading(true);
          const response = await UserService.loadUsersByQuery(
            stringify({
              limit: 20,
              order_by: 'first_name',
              order_dir: 'asc',
            })
          );

          const users = response.docs.filter(
            (user) =>
              !transferListRightItems.some(
                (rightUserItem) => rightUserItem.id === user.id
              )
          );

          setTransferListLeftItems(users);
        } catch (error: any) {
          console.error(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchUsers();
    }

    // eslint-disable-next-line
  }, [selectedOption]);

  const changeTab = (
    event: any,
    newValue: PublishNotificationContentTabType
  ) => {
    formik.resetForm();
    setSelectedDate(undefined);
    setActiveTab(newValue);
  };

  const handleDateTimeSelection = (date: Date) => {
    const timeZone = moment.tz.guess(false);

    const isoString = moment(date).tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ');

    setSelectedDate(isoString);

    formik.getFieldHelpers('schedule_to').setValue(isoString);
  };

  const handleToggle = (user: UserInfo) => () => {
    const currentIndex = checkedItems.findIndex((u) => u.id === user.id);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
  };

  const handleCheckedRight = () => {
    const leftChecked = checkedItems.filter((user) =>
      transferListLeftItems.some((leftUser) => leftUser.id === user.id)
    );
    setTransferListRightItems([...transferListRightItems, ...leftChecked]);
    setTransferListLeftItems(
      transferListLeftItems.filter((user) => !leftChecked.includes(user))
    );
    setCheckedItems(checkedItems.filter((user) => !leftChecked.includes(user)));
  };

  const handleCheckedLeft = () => {
    const rightChecked = checkedItems.filter((user) =>
      transferListRightItems.some((rightUser) => rightUser.id === user.id)
    );
    setTransferListLeftItems([...transferListLeftItems, ...rightChecked]);
    setTransferListRightItems(
      transferListRightItems.filter((user) => !rightChecked.includes(user))
    );
    setCheckedItems(
      checkedItems.filter((user) => !rightChecked.includes(user))
    );
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle>
        {t('notificationContents.publish.title')}
      </StyledDialogTitle>
      <TabContainer>
        <Tabs onChange={changeTab} value={activeTab} variant="fullWidth">
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={PublishNotificationContentTabType.PUBLISH_NOW}
            label={t('notificationContents.publish.tabs.publishNow')}
          />
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={PublishNotificationContentTabType.SCHEDULE}
            label={t('notificationContents.publish.tabs.scheduleTo')}
          />
        </Tabs>
      </TabContainer>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogContent>
          {activeTab === PublishNotificationContentTabType.SCHEDULE && (
            <>
              <OvDateTimePicker
                disablePast
                minutesStep={30}
                label={t('notificationContents.publish.scheduleTo')}
                value={parseISO(selectedDate ?? '')}
                onChange={handleDateTimeSelection}
                views={['year', 'day', 'hours', 'minutes']}
                timezone={'system'}
              />
              {selectedDate && (
                <>
                  <StyledLabel>{'Selected time preview'}</StyledLabel>
                  <StyledScheduleTime>
                    {`New York: ${moment(new Date(selectedDate).getTime())
                      .tz(DATE_TIME_PICKER_TIMEZONES.NEW_YORK)
                      .format(Constants.US_DATE_DISPLAY_FORMAT)}`}
                  </StyledScheduleTime>
                  <StyledScheduleTime>
                    {`Los Angeles: ${moment(selectedDate)
                      .utc()
                      .tz(DATE_TIME_PICKER_TIMEZONES.LA)
                      .format(Constants.US_DATE_DISPLAY_FORMAT)}`}
                  </StyledScheduleTime>
                  <StyledScheduleTime>
                    {`Chicago: ${moment(selectedDate)
                      .utc()
                      .tz(DATE_TIME_PICKER_TIMEZONES.CHICAGO)
                      .format(Constants.US_DATE_DISPLAY_FORMAT)}`}
                  </StyledScheduleTime>
                  <StyledScheduleTime>
                    {`London: ${moment(selectedDate)
                      .utc()
                      .tz(DATE_TIME_PICKER_TIMEZONES.LONDON)
                      .format(Constants.US_DATE_DISPLAY_FORMAT)}`}
                  </StyledScheduleTime>
                </>
              )}
              <DividerLine />
            </>
          )}
          <StyledLabel>
            {t('notificationContents.publish.publishTo')}
          </StyledLabel>
          <StyledSelect
            value={selectedOption}
            onChange={(e) => {
              setSelectedOption(e.target.value as string);
            }}
          >
            {publishToOptions.map((option: string) => (
              <StyledMenuItem key={option} value={option}>
                {option}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          {selectedOption === 'Subset of users' && (
            <>
              <StyledSectionTitle>
                {t('notificationContents.publish.filters')}
              </StyledSectionTitle>
              <StyledLabel>
                {t('notificationContents.publish.healthGoal')}
              </StyledLabel>
              <StyledSelect
                labelId="health-goal"
                {...formik.getFieldProps('health_goal')}
                displayEmpty
              >
                <StyledMenuItem value={''}>
                  <em>{t('common.actions.notSelected')}</em>
                </StyledMenuItem>
                {healthGoalOptions.map((healthGoal) => (
                  <StyledMenuItem key={healthGoal} value={healthGoal}>
                    {healthGoal}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <StyledLabel>{t('notificationContents.publish.os')}</StyledLabel>
              <StyledSelect
                labelId="os"
                {...formik.getFieldProps('os')}
                displayEmpty
              >
                <StyledMenuItem value={''}>
                  <em>{t('common.actions.notSelected')}</em>
                </StyledMenuItem>
                {osOptions.map((osOption) => (
                  <StyledMenuItem key={osOption} value={osOption.toLowerCase()}>
                    {osOption}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </>
          )}
          {selectedOption === 'Specific users' && (
            <>
              <StyledSectionTitle>
                {t('notificationContents.publish.specificUsers')}
              </StyledSectionTitle>
              <StyledTextField
                label={t(
                  'notificationContents.publish.specificUsersInputLabel'
                )}
                value={userSearchInput}
                fullWidth
                onChange={(e) => setUserSearchInput(e.target.value)}
              />
              <TransferListContainer>
                <ListContainer>
                  <OvTransferListCustomList
                    users={transferListLeftItems}
                    handleToggle={handleToggle}
                    checkedUsers={checkedItems}
                    sectionTitle="User list"
                    isSearchList={true}
                    isLoading={isLoading}
                  />
                </ListContainer>

                <ButtonContainer>
                  <StyledMuiButton
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={
                      checkedItems.filter((user) =>
                        transferListLeftItems.some(
                          (leftUser) => leftUser.id === user.id
                        )
                      ).length === 0
                    }
                    aria-label="move selected right"
                  >
                    <KeyboardArrowRightIcon />
                  </StyledMuiButton>
                  <StyledMuiButton
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={
                      checkedItems.filter((user) =>
                        transferListRightItems.some(
                          (rightUser) => rightUser.id === user.id
                        )
                      ).length === 0
                    }
                    aria-label="move selected left"
                  >
                    <KeyboardArrowLeftIcon />
                  </StyledMuiButton>
                </ButtonContainer>

                <ListContainer>
                  <OvTransferListCustomList
                    users={transferListRightItems}
                    handleToggle={handleToggle}
                    checkedUsers={checkedItems}
                    sectionTitle="Selected users"
                    isSearchList={false}
                    isLoading={isLoading}
                  />
                </ListContainer>
              </TransferListContainer>
            </>
          )}
          <StyledDialogActions>
            <StyledLightOvButton onClick={onCancel}>
              {t('common.actions.cancel')}
            </StyledLightOvButton>
            {activeTab === PublishNotificationContentTabType.SCHEDULE ? (
              <StyledOvButton onClick={() => setIsDialogOpen(true)}>
                {t('common.actions.schedule')}
              </StyledOvButton>
            ) : (
              <StyledOvButton onClick={() => setIsDialogOpen(true)}>
                {t('common.actions.publish')}
              </StyledOvButton>
            )}
          </StyledDialogActions>
        </StyledDialogContent>
      </form>

      <OvConfirmationDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={formik.handleSubmit}
        title={
          activeTab === PublishNotificationContentTabType.SCHEDULE
            ? t(
                'notificationContents.publish.dialogs.scheduleConfirmationDialogTitle'
              )
            : t('notificationContents.publish.dialogs.confirmationDialogTitle')
        }
        description={
          activeTab === PublishNotificationContentTabType.SCHEDULE
            ? t(
                'notificationContents.publish.dialogs.scheduleConfirmationDialogDescription'
              )
            : t(
                'notificationContents.publish.dialogs.confirmationDialogDescription'
              )
        }
        icon={<StyledSendIcon />}
      />
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvPublishNotificationContentDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    max-width: none;
    height: 3rem;

    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }
    }
  }
`;

const StyledLabel = styled.label`
  font-size: ${Variables.fontSizes.MEDIUM};
  margin-right: 0.5rem;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    max-width: fit-content;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    max-width: fit-content;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledSectionTitle = styled.h4``;

const StyledSendIcon = styled(SendIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const DividerLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${Colours.OV_BORDER_COLOR_V3};
  width: 100%;
  margin: 1rem 0;
`;

const StyledScheduleTime = styled.p`
  margin: 0;
  padding: 0;
  color: ${Colours.OV_BASE};
  font-size: 12px;
`;

const TransferListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const ListContainer = styled.div`
  width: 30rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6rem 1rem;
`;

const StyledTextField = styled(OvTextField)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledMuiButton = styled(Button)`
  && {
    margin: 0.5rem 0;
    border-color: ${Colours.OV_BASE};
    color: ${Colours.OV_BASE};
  }
`;
