import { Action, combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth.slice';
import userReducer from './user.slice';
import dailyDataReducer from './daily-data.slice';
import reportReducer from './report.slice';
import lotsReducer from './lots.slice';
import ordersReducer from './orders.slice';
import productsReducer from './products.slice';
import articlesReducer from './articles.slice';
import scansReducer from './scans.slice';
import adminClinicReducer from './admin-clinic.slice';
import adminClinicLocationReducer from './admin-clinic-location.slice';
import clinicLocationReducer from './clinic-location.slice';
import fullScriptReducer from './fullscript.slice';
import adminScansReducer from './admin-scans.slice';
import demoAccountDataReducer from './demo-account-data.slice';
import adminScanSequenceReducer from './admin-scan-sequence.slice';
import configurationReducer from './configuration.slice';
import adminProductPriceReducer from './admin-product-price.slice';
import adminConfigurationReducer from './admin-configuration.slice';
import { initialRootState } from '../store';
import { logout } from '../thunks/auth.thunk';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  dailyData: dailyDataReducer,
  scans: scansReducer,
  reports: reportReducer,
  lots: lotsReducer,
  orders: ordersReducer,
  products: productsReducer,
  articles: articlesReducer,
  adminClinic: adminClinicReducer,
  adminClinicLocation: adminClinicLocationReducer,
  clinicLocation: clinicLocationReducer,
  fullScript: fullScriptReducer,
  adminScans: adminScansReducer,
  demoAccountData: demoAccountDataReducer,
  adminScanSequence: adminScanSequenceReducer,
  configuration: configurationReducer,
  adminProductPrice: adminProductPriceReducer,
  adminConfiguration: adminConfigurationReducer,
});

// @ts-ignore please leave the ts ignore for now for store and state related config files
export const rootReducer = (state, action: Action<string>) => {
  if (action.type === logout.fulfilled.type) {
    return appReducer(initialRootState, action);
  }

  return appReducer(state, action);
};
