import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Order } from '../../common/model/dto/order/order';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearOrderActivityList,
  clearSelectedOrder,
} from '../../redux/reducers/orders.slice';
import {
  findOrderActivitiesById,
  findOrderById,
  submitOrder,
  updateOrder,
} from '../../redux/thunks/orders.thunk';
import OvButton from '../UI/atoms/OvButton';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvTag from '../UI/atoms/OvTag';
import breakpoints from '../../design-system/breakpoints';
import { OrderDataUtils } from '../../common/utils/services/order-data-utils';
import { OrderActivity } from '../../common/model/dto/order/order-activity';
import OvOrderActivityList from '../UI/organisms/OvOrderActivityList';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OvEditShippingAddressDialog from '../UI/molecules/OvEditShippingAddressDialog';
import OvOrderInfoBox from '../UI/molecules/OvOrderInfoBox';
import { OrderStatus } from '../../common/model/type/order-status.enum';
import OvEditOrderStatusDialog from '../UI/molecules/OvEditOrderStatusDialog';
import OvEditOrderPaymentStatusDialog from '../UI/molecules/OvEditOrderPaymentStatusDialog';
import { Address } from '../../common/model/dto/address';
import OvBackButton from '../UI/atoms/OvBackButton';
import { OrderPaymentStatus } from '../../common/model/type/order-payment-status.enum';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import OvEditOrderDialog from '../UI/molecules/OvEditOrderDialog';
import OvConversionSubmissionDetails from '../UI/organisms/OvConversionSubmissionDetails';
import OvEditableOrderItems from '../UI/molecules/OvEditableOrderItems';
import { OrderItem } from '../../common/model/dto/order/order-item';
import OvOrderItems from '../UI/molecules/OvOrderItems';
import OvOrderAddress from '../UI/molecules/OvOrderAddress';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const OrderDetails: FC = () => {
  const { t } = useTranslation();
  const { orderId }: any = useParams();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const orderOperations = useAuthorized(Resource.Order);
  const orderActivityOperations = useAuthorized(Resource.OrderActivity);

  const orderUpdateAllowed =
    orderOperations.update || applicationOperations.supervise;
  const orderActivitesReadAllowed =
    orderActivityOperations.read || applicationOperations.supervise;

  const [editableOrder, setEditableOrder] = useState<Order | undefined>(
    undefined
  );
  const [isOpenEditOrderDialog, setOpenEditOrderDialog] =
    useState<boolean>(false);
  const [isOpenEditAddressDialog, setOpenEditAddressDialog] =
    useState<boolean>(false);
  const [isEditStatusOpen, setIsEditStatusOpen] = useState<boolean>(false);
  const [isEditPaymentStatusOpen, setIsEditPaymentStatusOpen] =
    useState<boolean>(false);
  const [isOpenSubmitConfirmationDialog, setOpenSubmitConfirmationDialog] =
    useState<boolean>(false);
  const isLoading: boolean = useAppSelector((state) => state.orders.isLoading);
  const selectedOrder: Order | undefined = useAppSelector(
    (state) => state.orders.selectedOrder
  );
  const orderActivities: OrderActivity[] | undefined = useAppSelector(
    (state) => state.orders.orderActivityList
  );

  useEffect(() => {
    dispatch(clearSelectedOrder());
    if (orderId) {
      dispatch(findOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(clearOrderActivityList());
    if (selectedOrder && selectedOrder.internal_id) {
      dispatch(findOrderActivitiesById(selectedOrder.internal_id));
    }
  }, [dispatch, selectedOrder]);

  const openEditOrderModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    setEditableOrder(selectedOrder);
    setOpenEditOrderDialog(true);
  };

  const openEditAddressModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    setEditableOrder(selectedOrder);
    setOpenEditAddressDialog(true);
  };

  const onSaveEditedOrder = (order: { discount_code?: string }) => {
    setOpenEditOrderDialog(false);
    dispatch(
      updateOrder({
        orderId: selectedOrder?.id,
        updatedProperties: order,
      })
    );
    setEditableOrder(undefined);
  };

  const onSaveEditedAddress = (shipping_address: Address) => {
    setOpenEditAddressDialog(false);
    dispatch(
      updateOrder({
        orderId: selectedOrder?.id,
        updatedProperties: {
          shipping_address,
        },
      })
    );
    setEditableOrder(undefined);
  };

  const onSaveStatusChange = ({ status }: { status?: OrderStatus }) => {
    if (status) {
      setIsEditStatusOpen(false);
      dispatch(
        updateOrder({
          orderId: selectedOrder?.id,
          updatedProperties: {
            status,
          },
        })
      );
    }
  };

  const onSavePaymentStatusChange = ({
    payment_status,
  }: {
    payment_status?: OrderPaymentStatus;
  }) => {
    if (payment_status) {
      setIsEditPaymentStatusOpen(false);
      dispatch(
        updateOrder({
          orderId: selectedOrder?.id,
          updatedProperties: {
            payment_status,
          },
        })
      );
    }
  };

  const openSubmitConfirmationDialog = () =>
    setOpenSubmitConfirmationDialog(true);

  const handleProceedToShipping = () => {
    setOpenSubmitConfirmationDialog(false);
    if (selectedOrder?.id) {
      dispatch(submitOrder(selectedOrder.id));
    }
  };

  const handleItemChanges = (items: OrderItem[]) => {
    dispatch(
      updateOrder({
        orderId: selectedOrder?.id,
        updatedProperties: {
          items,
        },
      })
    );
  };

  return (
    <DetailPageWrapper>
      {selectedOrder && orderActivities && (
        <>
          <Header>
            <OrderInfoWrapper>
              <OvBackButton label={t('common.actions.back')} />
              <OrderName>{`${selectedOrder.order_number}`}</OrderName>
              <StyledOrderStatuses>
                <Tooltip title={t('orders.details.tooltip.orderStatus')}>
                  <StyledStatusLabel
                    style={{
                      backgroundColor:
                        OrderDataUtils.getStatusBackgroundColor(selectedOrder),
                      color: OrderDataUtils.getStatusFontColor(selectedOrder),
                    }}
                  >
                    {selectedOrder.status}
                  </StyledStatusLabel>
                </Tooltip>
                <Tooltip title={t('orders.details.tooltip.paymentStatus')}>
                  <StyledStatusLabel
                    style={{
                      backgroundColor:
                        OrderDataUtils.getPaymentStatusColor(selectedOrder),
                    }}
                  >
                    {selectedOrder.payment_status}
                  </StyledStatusLabel>
                </Tooltip>
              </StyledOrderStatuses>
              {selectedOrder.tags && selectedOrder.tags.length > 0 && (
                <StyledTags>
                  {selectedOrder.tags.map((tag) => (
                    <OvTag>{tag}</OvTag>
                  ))}
                </StyledTags>
              )}
            </OrderInfoWrapper>
            {orderUpdateAllowed && (
              <ActionWrapper>
                <StyledOvButton onClick={() => setIsEditStatusOpen(true)}>
                  {t('common.actions.editStatus')}
                </StyledOvButton>
                <StyledOvButton
                  onClick={() => setIsEditPaymentStatusOpen(true)}
                >
                  {t('common.actions.editPaymentStatus')}
                </StyledOvButton>

                <Tooltip
                  title={
                    selectedOrder.is_submitted
                      ? t('orders.details.alreadySubmitted')
                      : ''
                  }
                >
                  <span>
                    <StyledOvButton
                      disabled={selectedOrder.is_submitted}
                      onClick={() => openSubmitConfirmationDialog()}
                    >
                      {t('common.actions.submitToDropshipper')}
                    </StyledOvButton>
                  </span>
                </Tooltip>
              </ActionWrapper>
            )}
          </Header>
          <Container>
            <InfoContaier>
              <GeneralInfoBoxConatiner>
                <OrderGeneralInfoHeaderContainer>
                  <StyledSectionHeader>
                    {t('orders.details.generalInfo')}
                  </StyledSectionHeader>
                  {selectedOrder.stripe_payment_intent_id && (
                    <a
                      href={`https://dashboard.stripe.com/payments/${selectedOrder.stripe_payment_intent_id}`}
                      target="blank"
                    >
                      {t('orders.stripePaymentLink')}
                    </a>
                  )}
                  {orderUpdateAllowed && (
                    <StyledButton
                      disabled={isLoading}
                      onClick={(event) => openEditOrderModal(event)}
                    >
                      <EditIcon
                        style={{
                          marginRight: '0.5rem',
                          fontSize: '1rem',
                          paddingLeft: '0',
                        }}
                      />
                      {t('common.actions.edit')}
                    </StyledButton>
                  )}
                </OrderGeneralInfoHeaderContainer>
                <OvOrderInfoBox
                  infoArray={OrderDataUtils.mapOrderGeneralInfoToInfoBoxFormat(
                    selectedOrder
                  )}
                />
              </GeneralInfoBoxConatiner>
              <InfoBoxContainer>
                <StyledSectionHeader>
                  {t('orders.details.items')}
                </StyledSectionHeader>

                <ScrollableContentContainer>
                  {selectedOrder?.is_submitted ? (
                    <OvOrderItems orderItems={selectedOrder.items} />
                  ) : (
                    <OvEditableOrderItems
                      items={selectedOrder.items}
                      onChange={handleItemChanges}
                    />
                  )}
                </ScrollableContentContainer>
              </InfoBoxContainer>

              <InfoBoxContainer>
                <StyledSectionHeader>
                  {t('orders.details.billingInfo')}
                </StyledSectionHeader>

                <OvOrderAddress
                  address={selectedOrder.billing_address}
                ></OvOrderAddress>
              </InfoBoxContainer>
              <InfoBoxContainer>
                <OrderAddressHeaderContainer>
                  <StyledSectionHeader>
                    {t('orders.details.shippingInfo')}
                  </StyledSectionHeader>
                  {orderUpdateAllowed && (
                    <StyledButton
                      disabled={isLoading}
                      onClick={(event) => openEditAddressModal(event)}
                    >
                      <EditIcon
                        style={{
                          marginRight: '0.5rem',
                          fontSize: '1rem',
                          paddingLeft: '0',
                        }}
                      />
                      {t('common.actions.edit')}
                    </StyledButton>
                  )}
                </OrderAddressHeaderContainer>
                <OvOrderAddress
                  address={selectedOrder.shipping_address}
                ></OvOrderAddress>
              </InfoBoxContainer>
            </InfoContaier>
            {orderActivitesReadAllowed && orderActivities.length > 0 && (
              <StyledOrderActivitesContainer>
                <OvOrderActivityList orderActivities={orderActivities} />
              </StyledOrderActivitesContainer>
            )}
            {selectedOrder.conversion_submission_event && (
              <StyledOrderActivitesContainer>
                <OvConversionSubmissionDetails
                  conversionSubmissionEvent={
                    selectedOrder?.conversion_submission_event
                  }
                />
              </StyledOrderActivitesContainer>
            )}
          </Container>
        </>
      )}

      <OvEditOrderDialog
        isOpen={isOpenEditOrderDialog}
        onCancel={() => setOpenEditOrderDialog(false)}
        onSave={onSaveEditedOrder}
        title={`${t('common.actions.editOrder')} - ${
          (editableOrder as Order)?.order_number
        }`}
        order={editableOrder as Order}
      />

      <OvEditShippingAddressDialog
        isOpen={isOpenEditAddressDialog}
        onCancel={() => setOpenEditAddressDialog(false)}
        onSave={onSaveEditedAddress}
        title={`${t('common.actions.editAddress')} - ${
          (editableOrder as Order)?.order_number
        }`}
        address={(editableOrder as Order)?.shipping_address}
      />

      <OvEditOrderStatusDialog
        isOpen={isEditStatusOpen}
        onCancel={() => setIsEditStatusOpen(false)}
        onSave={onSaveStatusChange}
        currentStatus={selectedOrder?.status}
      />

      <OvEditOrderPaymentStatusDialog
        isOpen={isEditPaymentStatusOpen}
        onCancel={() => setIsEditPaymentStatusOpen(false)}
        onSave={onSavePaymentStatusChange}
        currentPaymentStatus={selectedOrder?.payment_status}
      />

      <OvConfirmationDialog
        isOpen={isOpenSubmitConfirmationDialog}
        onCancel={() => setOpenSubmitConfirmationDialog(false)}
        onConfirm={handleProceedToShipping}
        description={'orders.confirmProceedToShipping'}
        descriptionVariable={{
          dropshippingPartner: selectedOrder?.dropshipping_partner,
        }}
        title={t('common.areYouSure')}
      />

      {isLoading && <OvLoadingIndicator position={'fixed'} />}
    </DetailPageWrapper>
  );
};

export default OrderDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
`;

const Header = styled.header`
  display: flex;
  margin-bottom: 1rem;
  font-weight: bold;
  align-items: center;
  height: 4rem;
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GeneralInfoBoxConatiner = styled.div`
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  margin-bottom: 0.75rem;
  padding: 1rem;
  width: 100%;
  flex: 2.5;
`;

const InfoBoxContainer = styled.div`
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  margin-bottom: 0.75rem;
  padding: 1rem;
  width: 100%;
  flex: 2;
`;

const StyledSectionHeader = styled.h3`
  margin: 0 0 0 0.5rem;
`;

const OrderName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledTags = styled.span`
  border-left: 1px solid ${Colours.OV_BASE_HOVER};
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  display: flex;
  gap: 1rem;
  height: 100%;
  align-items: center;
`;

const StyledOrderStatuses = styled.span`
  border-left: 1px solid ${Colours.OV_BASE_HOVER};
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  display: flex;
  gap: 1rem;
  height: 100%;
  align-items: center;
`;

const StyledStatusLabel = styled.p`
  text-align: center;
  border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
  padding: 0 1rem;
`;

const StyledOrderActivitesContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`;

const InfoContaier = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const OrderAddressHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrderGeneralInfoHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(OvButton)`
  && {
    padding: 0 0.5rem;
    background: transparent;
    background-color: transparent;
    color: ${Colours.OV_BASE};
    max-height: 1.5rem;
    text-transform: none;

    &:hover {
      background-color: ${Colours.OV_WHITE_HOVER};
    }
  }
`;

const ScrollableContentContainer = styled.div`
  max-height: 20rem;
  padding: 0.25rem;
  overflow-y: auto;
`;

const OrderInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;
`;

const ActionWrapper = styled.div`
  display: flex;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;
