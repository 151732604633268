import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DemoAccountDataState } from '../states/demo-account-data.state';
import {
  deleteDemoAccountDataForCurrentUser,
  loadDemoAccountDataForUser,
} from '../thunks/demo-account-data.thunk';
import { DemoAccountData } from '../../common/model/dto/demo-account-data';

const initialState: DemoAccountDataState = {
  isLoading: false,
  demoAccountDataList: [],
};

const demoAccountDataSlice = createSlice({
  name: 'demoAccountData',
  initialState,
  reducers: {
    clearDemoAccountDataList: (state) => {
      state.demoAccountDataList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDemoAccountDataForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadDemoAccountDataForUser.fulfilled,
        (state, action: PayloadAction<DemoAccountData[]>) => {
          state.demoAccountDataList = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(loadDemoAccountDataForUser.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteDemoAccountDataForCurrentUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDemoAccountDataForCurrentUser.fulfilled, (state) => {
        state.demoAccountDataList = [];
        state.isLoading = false;
      })
      .addCase(deleteDemoAccountDataForCurrentUser.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearDemoAccountDataList } = demoAccountDataSlice.actions;
export default demoAccountDataSlice.reducer;
