import React, { FC, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';
import OvTextButton from '../atoms/OvTextButton';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { useFormik } from 'formik';
import { Order } from '../../../common/model/dto/order/order';
import OvSelect from '../atoms/OvSelect';
import { MenuItem } from '@mui/material';
import { OrderDropshippingPartner } from '../../../common/model/type/order-dropshipping-partner.type';

export interface OvEditOrderDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (order: { discount_code?: string; sales_channel?: string }) => void;
  title: string;
  order: Order | null;
}

const DROPSHIPPING_PARTNER_OPTIONS = [
  OrderDropshippingPartner.Thorne as string,
  OrderDropshippingPartner.OpsEngine as string,
];

const OvEditOrderDialog: FC<OvEditOrderDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
  title,
  order,
}) => {
  const { t } = useTranslation();
  const [dropshippingPartner, setDropshippingPartner] = useState('');

  const formik = useFormik<{
    user_email?: string;
    discount_code?: string;
    sales_channel?: string;
    dropshipping_partner?: string;
  }>({
    initialValues: {
      user_email: '',
      discount_code: '',
      sales_channel: '',
      dropshipping_partner: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onSave(values);
    },
  });

  useEffect(() => {
    if (isOpen && order) {
      formik.setValues({
        user_email: order.user_email || '',
        discount_code: order.discount_code || '',
        sales_channel: order.sales_channel || '',
        dropshipping_partner:
          order.dropshipping_partner || DROPSHIPPING_PARTNER_OPTIONS[0],
      });
      setDropshippingPartner(
        order.dropshipping_partner || DROPSHIPPING_PARTNER_OPTIONS[0]
      );
    }
    // eslint-disable-next-line
  }, [isOpen, order]);

  const handleDropshippingPartnerChange = (event: any) => {
    const value = event.target.value;
    setDropshippingPartner(value);
    formik.setFieldValue('dropshipping_partner', value);
  };

  const handleOnSave = async () => {
    formik.handleSubmit();
  };

  return (
    <StyledDialog open={isOpen} onClose={onCancel} aria-labelledby={t(title)}>
      <StyledDialogTitle id="alert-dialog-title">
        <StyledLocationIcon /> {title}
      </StyledDialogTitle>
      <form>
        <StyledDialogContent>
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.generalInfo.email') + '*'}
            {...formik.getFieldProps('user_email')}
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.generalInfo.discountCode') + '*'}
            {...formik.getFieldProps('discount_code')}
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.generalInfo.salesChannel') + '*'}
            {...formik.getFieldProps('sales_channel')}
          />
          <StyledSelectContainer>
            <StyledSelectTitle>
              {t('orders.generalInfo.dropshippingPartner')}:
            </StyledSelectTitle>
            <StyledSelect
              {...formik.getFieldProps('dropshipping_partner')}
              value={dropshippingPartner}
              onChange={handleDropshippingPartnerChange}
            >
              {DROPSHIPPING_PARTNER_OPTIONS.map((dropshipping_partner) => (
                <MenuItem
                  key={dropshipping_partner}
                  value={dropshipping_partner}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '5px',
                  }}
                >
                  {dropshipping_partner}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledSelectContainer>
        </StyledDialogContent>
        <StyledDialogActions>
          <OvTextButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </OvTextButton>
          <StyledOvButton onClick={() => handleOnSave()}>
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
};

export default OvEditOrderDialog;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: 1rem;
    padding: 0 1rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0 1rem;
  }
`;

const StyledLocationIcon = styled(EditLocationAltIcon)`
  && {
    margin-right: 0.5rem;
    font-size: 2.5rem;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 1.5rem 0 0 0;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 1.5rem 0 0 0 !important;
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
  }
`;

const StyledSelectTitle = styled.p`
  font-weight: normal;
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
  color: ${Colours.OV_BASE};
`;

const StyledSelectContainer = styled.div`
  max-height: 38rem;
  overflow-y: auto;
  width: 100%;
`;

const StyledSelect = styled(OvSelect)`
  && {
    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_BASE};
      width: 100%;
      padding: 0.5rem;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }
    }
  }
`;
