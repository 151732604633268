export enum ClinicRole {
  Physician = 'Physician',
  NursePractitioner = 'Nurse Practitioner',
  Nurse = 'Nurse',
  MedicalAssistant = 'Medical Assistant',
  Administrative = 'Administrative',
  Dietitian = 'Dietitian',
  Acupuncturist = 'Acupuncturist',
  HealthCoach = 'Health Coach',
  Doctor = 'Doctor',
}

export const CLINIC_ROLE_URL_VALUES = {
  [ClinicRole.Acupuncturist]: 'acupuncturists',
  [ClinicRole.Administrative]: 'administratives',
  [ClinicRole.Dietitian]: 'dietitians',
  [ClinicRole.Doctor]: 'doctors',
  [ClinicRole.HealthCoach]: 'health-coaches',
  [ClinicRole.MedicalAssistant]: 'medical-assistants',
  [ClinicRole.Nurse]: 'nurses',
  [ClinicRole.NursePractitioner]: 'nurse-practicioners',
  [ClinicRole.Physician]: 'physicians',
};

export const PATIENT = 'Patient';
