import React, { CSSProperties, FC, useState } from 'react';
import OvAvatar from './OvAvatar';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from 'react-i18next';
import OvImageUploadDialog from './OvImageUploadDialog';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { UserInfo } from '../../../common/model/dto/user-info';
import { Clinic } from '../../../common/model/dto/clinic';
import { ClinicLocation } from '../../../common/model/dto/clinic-location';
import { Resource } from '../../../common/model/type/resource.enum';
import useAuthorized from '../../../hooks/use-authorized';

interface OvEditableAvatarProps {
  entity?: UserInfo | Clinic | ClinicLocation;
  handleUpdate?: (updatedProperties: any) => void;
  imageBasePath: string;
  dialogTitle: string;
  defaultImageComponent: React.ReactElement;
  style?: CSSProperties;
}

const OvEditableAvatar: FC<OvEditableAvatarProps> = ({
  entity,
  handleUpdate,
  imageBasePath,
  dialogTitle,
  defaultImageComponent,
  style,
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicLocationOperations = useAuthorized(Resource.ClinicLocation);

  const clinicLocationUpdateAllowed =
    clinicLocationOperations.update || applicationOperations.supervise;

  return (
    <>
      <StyledImageWrapper>
        <OvAvatar
          defaultComponent={defaultImageComponent}
          imgPath={entity?.image_path}
          style={style}
        />
        {clinicLocationUpdateAllowed && (
          <StyledOvButton onClick={() => setIsDialogOpen(true)}>
            <AddPhotoAlternateIcon style={{ marginRight: '0.5rem' }} />
            {entity?.image_path
              ? t('imageUpload.changeImage')
              : t('imageUpload.uploadImage')}
          </StyledOvButton>
        )}
      </StyledImageWrapper>

      <OvImageUploadDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        title={dialogTitle}
        imageBasePath={imageBasePath}
        onUploadSuccess={handleUpdate}
      />
    </>
  );
};

export default OvEditableAvatar;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
    padding: 0.5rem 0.5rem;
    background-color: ${Colours.OV_BASE};
    margin: 1rem 0;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      margin: 0 0.75rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 15rem;
  margin-right: 1rem;
`;
