import React, { FC } from 'react';
import styled from 'styled-components';
import OvTable from '../UI/molecules/OvTable';
import { ScanSequenceDataUtils } from '../../common/utils/services/scan-sequence-data-utils';
import { ScanSequence } from '../../common/model/dto/scan-sequences/scan-sequence';
import { useAppDispatch } from '../../redux/hooks';
import { updateScanSequence } from '../../redux/thunks/admin/admin-scan-sequences.thunk';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';

const ScanSequenceInfo: FC<{ scanSequence: ScanSequence }> = ({
  scanSequence,
}) => {
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const scanningPlanOperations = useAuthorized(Resource.ScanningPlan);

  const scanningPlanUpdateAllowed =
    scanningPlanOperations.update || applicationOperations.supervise;

  const handleScanSequenceUpdate = (updatedProperties: any) => {
    if (scanSequence?.id) {
      const updateObj = {
        scanSequenceId: scanSequence.id,
        updatedProperties: {
          ...updatedProperties,
        },
      };

      dispatch(
        updateScanSequence({
          scanSequenceId: scanSequence?.id,
          updatedProperties: updateObj.updatedProperties,
        })
      );
    }
  };

  return (
    <ContentContainer>
      <OvTable
        data={ScanSequenceDataUtils.mapScanSequenceToTable(scanSequence!)}
        theme="Light"
        onSaveRowValue={handleScanSequenceUpdate}
        editable={scanningPlanUpdateAllowed}
      />
    </ContentContainer>
  );
};

export default ScanSequenceInfo;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;
