import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@mui/material/CssBaseline';
import './i18n/config';
import OvLoadingIndicator from './components/UI/atoms/OvLoadingIndicator';
import { ThemeProvider } from '@mui/material';
import customMuiTheme from './design-system/mui-custom-theme';
import './design-system/fonts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <Suspense fallback={<OvLoadingIndicator />}>
      <ThemeProvider theme={customMuiTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Suspense>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
