import React, { FC, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';
import OvTextButton from '../atoms/OvTextButton';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OrderItem } from '../../../common/model/dto/order/order-item';
import EditIcon from '@mui/icons-material/Edit';
import OvSelect from '../atoms/OvSelect';
import { MenuItem } from '@mui/material';
import Variables from '../../../design-system/variables';

export interface OvOrderItemFormDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (orderItem: OrderItem) => void;
  title: string;
  orderItem?: OrderItem | null;
}

const OvOrderItemFormDialog: FC<OvOrderItemFormDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
  title,
  orderItem,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<OrderItem>({
    initialValues: {
      sku: '',
      name: '',
      price: undefined,
      quantity: undefined,
      discount: undefined,
    },
    enableReinitialize: true,
    onSubmit: (values) => {},
    validationSchema: Yup.object().shape({
      sku: Yup.string(),
      name: Yup.string(),
      price: Yup.number(),
      quantity: Yup.number(),
      discount: Yup.number(),
    }),
    validateOnBlur: true,
  });

  useEffect(() => {
    if (isOpen && orderItem) {
      formik.setValues({
        sku: orderItem?.sku || 'OSK02',
        name: orderItem?.name,
        price: orderItem?.price,
        quantity: orderItem?.quantity,
        discount: orderItem?.discount,
      });
    }
    // eslint-disable-next-line
  }, [isOpen, orderItem]);

  const handleSave = () => {
    const orderItem: OrderItem = formik.values;
    onSave(orderItem);
  };

  return (
    <StyledDialog open={isOpen} onClose={onCancel} aria-labelledby={t(title)}>
      <StyledDialogTitle id="alert-dialog-title">
        <StyledEditIcon />
        {title}
      </StyledDialogTitle>
      <form>
        <StyledDialogContent>
          <StyledSelect
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.details.item.sku')}
            {...formik.getFieldProps('sku')}
            error={formik.touched.sku && !!formik.errors.sku}
          >
            <StyledMenuItem key="OSK02" value="OSK02">
              OSK02
            </StyledMenuItem>
            <StyledMenuItem key="OSK03" value="OSK03">
              OSK03
            </StyledMenuItem>
            <StyledMenuItem key="ORF02" value="ORF02">
              ORF02
            </StyledMenuItem>
            <StyledMenuItem key="ORF03" value="ORF03">
              ORF03
            </StyledMenuItem>
          </StyledSelect>
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.details.item.name')}
            {...formik.getFieldProps('name')}
            error={formik.touched.name && !!formik.errors.name}
            helperText={
              formik.errors.name && formik.touched.name && formik.errors.name
            }
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.details.item.price')}
            {...formik.getFieldProps('price')}
            error={formik.touched.price && !!formik.errors.price}
            helperText={
              formik.errors.price && formik.touched.price && formik.errors.price
            }
            type="number"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.details.item.quantity')}
            {...formik.getFieldProps('quantity')}
            error={formik.touched.quantity && !!formik.errors.quantity}
            helperText={
              formik.errors.quantity &&
              formik.touched.quantity &&
              formik.errors.quantity
            }
            type="number"
          />
          <StyledTextField
            style={{ marginBottom: '.75rem' }}
            fullWidth
            autoComplete="off"
            label={t('orders.details.item.discount')}
            {...formik.getFieldProps('discount')}
            error={formik.touched.quantity && !!formik.errors.discount}
            helperText={
              formik.errors.discount &&
              formik.touched.discount &&
              formik.errors.discount
            }
            type="number"
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <OvTextButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </OvTextButton>
          <StyledOvButton disabled={!formik.isValid} onClick={handleSave}>
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
};

export default OvOrderItemFormDialog;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: 1rem;
    padding: 0 1rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0 1rem;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  && {
    margin-right: 0.5rem;
    font-size: 2rem;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 1.5rem 0 0 0;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 1.5rem 0 0 0 !important;
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    width: 100%;
    padding: 0.75rem 0.875rem;
    margin-bottom: 0.75rem;

    .MuiSelect-select {
      padding: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
