import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserInfo } from '../../common/model/dto/user-info';
import OvUserList from '../UI/organisms/OvUserList';
import OvUserSearchFilter from '../UI/organisms/OvUserSearchFilter';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  loadUsers,
  restoreUserSearchFromUrl,
} from '../../redux/thunks/user.thunk';
import {
  clearUserList,
  setUserListPaging,
  setUserListStoredQuery,
  changeUserListSorting,
} from '../../redux/reducers/user.slice';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvNoContent from '../UI/molecules/OvNoContent';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import OvPagination from '../UI/atoms/OvPagination';
import { ListPaging } from '../../common/types';
import UserService from '../../services/user.service';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { PaginationItem } from '@mui/material';
import Colours from '../../design-system/colours';
import OvUserTypeahead from '../UI/molecules/OvUserTypeahead';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import PeopleIcon from '@mui/icons-material/People';
import Variables from '../../design-system/variables';
import { UserInfoFields } from '../../firebase/document-field.enums';

const AdminUserList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);

  const userList: UserInfo[] = useAppSelector((state) => state.user.userList);
  const userListPaging: ListPaging = useAppSelector(
    (state) => state.user.userListPaging
  );
  const isLoading: boolean = useAppSelector((state) => state.user.isLoading);
  const userListStoredQuery: string = useAppSelector(
    (state) => state.user.userListStoredQuery
  );
  const sortingOptions = useAppSelector((state) => state.user.sortingOptions);
  const errorMessage = useAppSelector((state) => state.user.errorMessage);

  // Find the currently sorted column and direction
  const activeSortOption = sortingOptions.find(
    (option: { direction: string }) =>
      option.direction === 'asc' || option.direction === 'desc'
  );

  useEffect(() => {
    if (search && search !== `?${userListStoredQuery}`) {
      dispatch(restoreUserSearchFromUrl(search));
    } else if (!userListStoredQuery) {
      dispatch(loadUsers(history));
    } else if (!search && userListStoredQuery) {
      dispatch(setUserListPaging({ offset: 0, total: userListPaging.total }));
      dispatch(loadUsers(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setUserListStoredQuery(''));
      dispatch(clearUserList());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(userListPaging.offset / UserService.USER_LIST_LIMIT + 1);
  }, [userListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setUserListPaging({
        offset: (value - 1) * UserService.USER_LIST_LIMIT,
        total: userListPaging.total,
      })
    );
    dispatch(loadUsers(history));
  };

  const selectUser = (user: UserInfo | any) => {
    if (user && user?.id) {
      history.push(`/users/${user?.id}`);
    }
  };

  const pageCount = Math.ceil(
    userListPaging?.total / UserService.USER_LIST_LIMIT
  );

  const fetchUsers = (searchText: string) => {
    return UserService.getUserTypeahead(searchText);
  };

  const handleColumnSort = (columnName: string) => {
    dispatch(changeUserListSorting(columnName as UserInfoFields));
    dispatch(loadUsers(history));
  };

  return (
    <Container>
      <CenterPane>
        <HeaderItems>
          <OvPageTitle title={t('common.users')} icon={<StyledPeopleIcon />} />
          <StyledOvUserTypeahead
            placeholder={t('common.actions.search')}
            onUserSelect={selectUser}
            fetchUsers={fetchUsers}
          />
        </HeaderItems>

        <ActionBar>
          <PaginationWrapper>
            {pageCount > 1 && !isLoading && (
              <OvPagination
                page={page}
                onChange={goToPage}
                count={pageCount}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                shape="rounded"
              />
            )}
          </PaginationWrapper>
        </ActionBar>

        {errorMessage && (
          <ErrorMessageWrapper>
            <OvErrorMessage message={errorMessage} />
          </ErrorMessageWrapper>
        )}

        {userList?.length ? (
          <ContentWrapper>
            <OvUserList
              users={userList}
              onColumnSort={handleColumnSort}
              currentSortColumn={activeSortOption?.value}
              currentSortDirection={activeSortOption?.direction || 'none'}
            />
          </ContentWrapper>
        ) : !isLoading ? (
          <OvNoContent>
            {t('common.userSearchFilters.emptyUserList')}
          </OvNoContent>
        ) : null}

        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvUserSearchFilter />
    </Container>
  );
};

export default AdminUserList;

const Container = styled.div`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-start;
  gap: 1rem;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledOvUserTypeahead = styled(OvUserTypeahead)`
  max-width: 100%;
  min-width: 15rem;
  width: 20%;
  border-radius: ${Variables.borderRadius.SMALL};
  font-size: 0.9rem;

  & input {
    padding: 0.4rem !important;
    background: ${Colours.WHITE};
  }
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

const ErrorMessageWrapper = styled.div`
  margin: 1rem 0;
`;
