import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import ErrorIcon from '@mui/icons-material/Error';

export interface OvErrorMessageProps {
  message: string;
}

const OvErrorMessage: FC<OvErrorMessageProps> = ({ message }) => {
  return (
    <ErrorMessage>
      <StyledErrorIcon />
      {message}
    </ErrorMessage>
  );
};

export default OvErrorMessage;

const ErrorMessage = styled.p`
  margin: 0;
  color: ${Colours.OV_RED};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  && {
    margin-right: 0.5rem;
  }
`;
