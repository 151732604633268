import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const OvLogo = () => {
  const { t } = useTranslation();

  return (
    <StyledOvLogo src="/images/oova_logo.png" alt={t('common.altText.logo')} />
  );
};

export default OvLogo;

const StyledOvLogo = styled.img`
  max-width: 7rem;
`;
