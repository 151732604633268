import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { OvSortingButtonModel } from '../molecules/OvSortingButtonGroup';
import { History } from 'history';
import { FilterModel } from '../../../common/model/ui/filter.model';
import {
  changeProviderListFilter,
  changeUserListSorting,
  clearProviderFilters,
} from '../../../redux/reducers/admin-clinic-location.slice';
import { loadClinicLocationProviders } from '../../../redux/thunks/admin/admin-clinic-location.thunk';
import { UserInfoFields } from '../../../firebase/document-field.enums';
import OvListFilter from './OvListFilter';
import { hasAppliedProviderFiltersSelector } from '../../../redux/selectors/admin-clinic-location.selectors';

const OvClinicLocationProviderSearchFilter: FC<{
  clinicLocationId?: string;
  clinicId: string;
}> = ({ clinicLocationId, clinicId }) => {
  const isOpened = useAppSelector(
    (state) => state.adminClinicLocation.showUserListFilters
  );
  const sortingOptions: OvSortingButtonModel[] = useAppSelector(
    (state) => state.adminClinicLocation.userListSortingOptions
  );
  const filters: FilterModel[] = useAppSelector(
    (state) => state.adminClinicLocation.providerListFilters
  );
  const hasAppliedFilters = useAppSelector(hasAppliedProviderFiltersSelector);
  const dispatch = useAppDispatch();
  const history: History = useHistory();

  const removeFilters = () => {
    dispatch(clearProviderFilters());
    dispatch(
      loadClinicLocationProviders({
        history,
        clinicLocationId,
        clinicId,
      })
    );
  };

  const handleSorting = (sortingValue: UserInfoFields) => {
    dispatch(changeUserListSorting(sortingValue));
    dispatch(
      loadClinicLocationProviders({
        history,
        clinicLocationId,
        clinicId,
      })
    );
  };

  const handleFilterValueChange = (filter: FilterModel) => {
    dispatch(changeProviderListFilter(filter));
    dispatch(
      loadClinicLocationProviders({
        history,
        clinicLocationId,
        clinicId,
      })
    );
  };

  return (
    <OvListFilter
      filters={filters}
      sortingOptions={sortingOptions}
      isOpened={isOpened}
      hasAppliedFilters={hasAppliedFilters}
      onRemoveFilters={removeFilters}
      onHandleSorting={handleSorting}
      onHandleFilterValueChange={handleFilterValueChange}
    />
  );
};

export default OvClinicLocationProviderSearchFilter;
