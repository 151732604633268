import React, { CSSProperties, FC, ReactElement } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import breakpoints from '../../../design-system/breakpoints';

const OvAvatar: FC<{
  imgPath?: string;
  defaultComponent: ReactElement;
  style?: CSSProperties;
}> = ({ imgPath, defaultComponent, style, ...props }) => {
  return (
    <Wrapper style={style}>
      {imgPath ? <StyledImage src={imgPath} /> : defaultComponent}
    </Wrapper>
  );
};

export default OvAvatar;

const Wrapper = styled.section`
  max-width: none;
  width: 100%;
  padding: 0.75rem;
  border-radius: ${Variables.borderRadius.LARGE};
  background-color: ${Colours.OV_GRAY};
  box-shadow: 0 0.25rem 0.313rem rgb(0 0 0 / 20%);
  text-align: center;
  align-self: flex-start;
  @media (min-width: ${breakpoints.md}) {
    max-width: 13.75rem;
  }
`;

const StyledImage = styled.img`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin-bottom: 0.25rem;
  overflow: hidden;
  object-fit: cover;
`;
