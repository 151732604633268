import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as firebase from 'firebase/app';
import { ListPaging, OrderBy, Paginated } from '../../common/types';
import isNumber from 'lodash/isNumber';
import { LotsState } from '../states/lots.state';
import {
  createLot,
  deleteLot,
  getLotById,
  getLots,
  runCalibrationProcess,
  saveCalibration,
  updateLot,
} from '../thunks/lots.thunk';
import { Lot } from '../../common/model/dto/lot';
import { AxiosError } from 'axios';
import { CalibrationErrorResponse } from '../../common/model/dto/calibration-error-response';

const initialState: LotsState = {
  loading: false,
  error: '',
  lotList: [],
  lotListSorting: null,
  lotListPaging: {
    total: 0,
    offset: 0,
  },
  selectedLot: null,
  calibratedSelectedLot: null,
};

const lotSlice = createSlice({
  name: 'lots',
  initialState,
  reducers: {
    setLotListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.lotListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.lotListPaging.offset = action.payload.offset;
      }
    },
    setLotListSorting: (state, action: PayloadAction<OrderBy>) => {
      state.lotListSorting = action.payload;
    },
    clearSelectedLot: (state) => {
      state.selectedLot = null;
      state.calibratedSelectedLot = null;
    },
    clearLotsList: (state) => {
      state.lotList = [];
      state.lotListSorting = null;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getLots.fulfilled,
        (state, action: PayloadAction<Paginated<Lot>>) => {
          state.lotList = action.payload.docs;

          if (isNumber(action.payload.offset)) {
            state.lotListPaging.offset = action.payload.offset;
          }

          if (isNumber(action.payload.total)) {
            state.lotListPaging.total = action.payload.total;
          }

          state.loading = false;
          state.error = '';
        }
      )
      .addCase(
        getLots.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getLotById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLotById.fulfilled, (state, action: PayloadAction<Lot>) => {
        state.selectedLot = action.payload;
        state.loading = false;
        state.error = '';
      })
      .addCase(
        getLotById.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteLot.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLot.fulfilled, (state, action: PayloadAction<Lot>) => {
        state.selectedLot = null;
        state.loading = false;
        state.error = '';
        state.lotList = [];
      })
      .addCase(
        deleteLot.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createLot.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLot.fulfilled, (state, action: PayloadAction<Lot>) => {
        state.loading = false;
        state.error = '';

        if (state.lotList) {
          state.lotList = [action.payload, ...state.lotList];
        } else {
          state.lotList = [action.payload];
        }
      })
      .addCase(
        createLot.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateLot.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLot.fulfilled, (state, action: PayloadAction<Lot>) => {
        state.loading = false;
        state.error = '';
        state.selectedLot = {
          ...state.selectedLot,
          ...action.payload,
        };
      })
      .addCase(
        updateLot.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(runCalibrationProcess.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        runCalibrationProcess.fulfilled,
        (state, action: PayloadAction<Lot | CalibrationErrorResponse>) => {
          const payload = action.payload as Lot & CalibrationErrorResponse;

          state.loading = false;
          state.lhArrayCalibrationError = '';
          state.pgArrayCalibrationError = '';
          state.e3gArrayCalibrationError = '';
          state.error = '';

          if (
            payload.lr_e3g_model_fit_parameters ||
            payload.lh_model_fit_parameters ||
            payload.pg_model_fit_parameters
          ) {
            state.calibratedSelectedLot = {
              ...state.selectedLot,
              ...payload,
            };
            state.selectedLot = {
              ...state.selectedLot,
              ...payload,
            };
          } else {
            if (payload.cartridge_type === 'lh') {
              state.lhArrayCalibrationError = payload.error_message;
            } else if (payload.cartridge_type === 'pg') {
              state.pgArrayCalibrationError = payload.error_message;
            } else if (payload.cartridge_type === 'lr_e3g') {
              state.e3gArrayCalibrationError = payload.error_message;
            }
          }
        }
      )
      .addCase(
        runCalibrationProcess.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          const errorMsg =
            (action.payload?.response?.data as any)?.error +
            ': ' +
            JSON.stringify(
              (
                (action.payload?.response?.data as any)?.message as string[]
              )?.join(',')
            );

          const cartridge_type = (action as any)?.meta?.arg?.cartridge_type;

          if (cartridge_type === 'lh') {
            state.lhArrayCalibrationError = errorMsg;
          } else if (cartridge_type === 'pg') {
            state.pgArrayCalibrationError = errorMsg;
          } else if (cartridge_type === 'lr_e3g') {
            state.e3gArrayCalibrationError = errorMsg;
          } else {
            state.error = errorMsg;
          }
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(saveCalibration.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        saveCalibration.fulfilled,
        (state, action: PayloadAction<Lot>) => {
          state.loading = false;
          state.error = '';
          state.selectedLot = {
            ...state.selectedLot,
            ...action.payload,
          };
          state.calibratedSelectedLot = null;
        }
      )
      .addCase(
        saveCalibration.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.loading = false;
          state.error = action.payload.code;
        }
      );
  },
});

export const {
  setLotListPaging,
  clearSelectedLot,
  clearLotsList,
  setLotListSorting,
} = lotSlice.actions;
export default lotSlice.reducer;
