import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationSettingItemType } from '../../../common/model/type/configuration-setting-item.type';
import { ConfigurationSettingsFields } from '../../../firebase/document-field.enums';
import OvDateParser from '../atoms/OvDateParser';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import OvTextField from '../atoms/OvTextField';
import OvDatePicker from '../molecules/OvDatePicker';
import DateUtils from '../../../common/utils/services/date-utils';

export interface ConfigurationOption {
  label: string;
  value: any;
  isEditable: boolean;
  type: ConfigurationSettingItemType;
  fieldName: ConfigurationSettingsFields;
  placeholder?: string;
  iconEl?: FC;
}

const ConfigurationSettingItem: React.FC<{
  options: ConfigurationOption;
  onChange: (fieldName: ConfigurationSettingsFields, value: any) => void;
  isDisabled: boolean;
}> = ({
  options: { label, type, isEditable, value, fieldName, placeholder, iconEl },
  onChange,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [configItemValue, setConfigItemValue] = useState<any>(value);
  let readOnlyValueEl;
  let formControlEl;

  useEffect(() => {
    setConfigItemValue(value);
  }, [value]);

  const handleOnChange = (value: any) => {
    setConfigItemValue(value);

    if (fieldName) {
      onChange(fieldName, value);
    }
  };

  const onDateValueChanged = (newDate: Date) => {
    handleOnChange(DateUtils.formatDate(newDate, 'YYYY-MM-DD'));
  };

  if (!isEditable) {
    switch (type) {
      case ConfigurationSettingItemType.DATE:
        readOnlyValueEl = (
          <StyledOvDateParser
            date={configItemValue}
            format={'MMMM DD, YYYY - hh:mm A'}
          />
        );
        break;
      case ConfigurationSettingItemType.LINK:
        readOnlyValueEl = (
          <Link
            href={configItemValue}
            rel="noopener noreferrer"
            target="_blank"
          >
            {configItemValue}
          </Link>
        );
        break;
      case ConfigurationSettingItemType.TOGGLE:
        readOnlyValueEl = (
          <FormControlLabel
            checked={configItemValue}
            disabled={true}
            control={<StyledSwitch />}
            label={configItemValue ? t('common.enabled') : t('common.disabled')}
            labelPlacement="start"
          />
        );
        break;
      case ConfigurationSettingItemType.TEXT:
        readOnlyValueEl = (
          <StyledConfigTextValue>{configItemValue}</StyledConfigTextValue>
        );
        break;
      default:
        readOnlyValueEl = configItemValue;
    }
  } else {
    switch (type) {
      case ConfigurationSettingItemType.TOGGLE:
        formControlEl = (
          <FormControlLabel
            control={
              <StyledSwitch
                disabled={isDisabled}
                checked={configItemValue}
                onChange={(e) => {
                  handleOnChange(e.target.checked);
                }}
              />
            }
            label={configItemValue ? t('common.enabled') : t('common.disabled')}
            labelPlacement="start"
          />
        );
        break;
      case ConfigurationSettingItemType.TEXT:
      case ConfigurationSettingItemType.LINK:
        formControlEl = (
          <StyledOvTextField
            disabled={isDisabled}
            onBlur={() => handleOnChange(configItemValue)}
            onChange={(event) => setConfigItemValue(event.target.value)}
            value={configItemValue}
            placeholder={placeholder ? t(placeholder) : t(label)}
          />
        );
        break;
      case ConfigurationSettingItemType.DATE:
        formControlEl = (
          <StyledOvDatePicker
            views={['year', 'month', 'day']}
            value={configItemValue ? new Date(configItemValue) : null}
            onChange={onDateValueChanged}
            slots={(params: any) => (
              <OvTextField {...params} helperText={null} />
            )}
          />
        );
        break;
      default:
        formControlEl = configItemValue;
    }
  }

  return (
    <Container>
      <Header>
        {iconEl && <StyledIcon>{React.createElement(iconEl)}</StyledIcon>}
        <Label>{t(label)}</Label>
      </Header>
      {readOnlyValueEl}
      {formControlEl}
    </Container>
  );
};

export default ConfigurationSettingItem;

const Container = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
`;

const Label = styled.p`
  font-weight: bold;
  margin: 0;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${Colours.BLUE['500']};
  margin-left: 1rem;

  &:hover {
    color: ${Colours.BLUE['300']};
    text-decoration: none;
  }
`;

const StyledConfigTextValue = styled.p`
  margin: 0.25rem 0 0 1rem;
  font-size: ${Variables.fontSizes.LARGE};
  font-weight: normal;
`;

const StyledSwitch = styled(Switch)`
  && {
    .MuiSwitch-thumb {
      color: ${Colours.OV_BASE};
    }
  }
`;

const StyledOvDateParser = styled(OvDateParser)`
  margin-left: 1rem;
`;

const StyledOvTextField = styled(OvTextField)`
  && {
    margin: 0.25rem 0 0 1rem;
    max-width: 100%;
    width: 30rem;

    & .MuiOutlinedInput-root {
      & input {
        padding: 0.5rem 0.75rem;
      }
    }
  }
`;

const StyledOvDatePicker = styled(OvDatePicker)`
  && {
    margin: 0.25rem 0 0 1rem;
  }
`;
