import React, { useState } from 'react';
import styled from 'styled-components';
import { GroupedEntitlement } from '../../../common/model/dto/entitlement';
import OvListTableField from '../atoms/OvListTableField';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import Colours from '../../../design-system/colours';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import { Operation } from '../../../common/model/type/operation.enum';
import { Link } from 'react-router-dom';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';

interface OvEntitlementListRowProps {
  groupedEntitlement: GroupedEntitlement;
  mode: string;
  createEntitlementCallback: (
    userRole: string | null,
    clinicRole: string | null,
    resource: string,
    operation: string
  ) => void;
  deleteEntitlementCallback: (
    userRole: string | null,
    clinicRole: string | null,
    resource: string,
    operation: string
  ) => void;
  deleteEntitlementsCallback: (
    userRole: string | null,
    clinicRole: string | null,
    resource: string
  ) => void;
}

const OvEntitlementListRow: React.FC<OvEntitlementListRowProps> = ({
  groupedEntitlement,
  mode,
  createEntitlementCallback,
  deleteEntitlementCallback,
  deleteEntitlementsCallback,
}) => {
  const { user_role, clinic_role, resource } = groupedEntitlement;

  const { t } = useTranslation();
  const [checkboxState, setCheckboxState] =
    useState<GroupedEntitlement>(groupedEntitlement);

  const [
    isOpenEntitlementDeleteConfirmationDialog,
    setOpenEntitlementDeleteConfirmationDialog,
  ] = useState<boolean>(false);

  const onDelete = () => {
    if (deleteEntitlementsCallback) {
      setOpenEntitlementDeleteConfirmationDialog(false);
      if (groupedEntitlement.resource) {
        deleteEntitlementsCallback(
          groupedEntitlement.user_role ?? null,
          groupedEntitlement.clinic_role ?? null,
          groupedEntitlement.resource
        );
      }
    }
  };

  const handleCheck = (operation: Operation) => {
    if (resource) {
      createEntitlementCallback(
        user_role ?? null,
        clinic_role ?? null,
        resource,
        operation
      );
    }
  };

  const handleUncheck = (operation: Operation) => {
    if (resource) {
      deleteEntitlementCallback(
        user_role ?? null,
        clinic_role ?? null,
        resource,
        operation
      );
    }
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    operation: Operation
  ) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (checked) {
      handleCheck(operation);
    } else {
      handleUncheck(operation);
    }
  };

  return (
    <>
      {mode === 'user' && (
        <EntitlementListTableField style={{ width: '9rem' }}>
          {groupedEntitlement.user_role ?? 'NULL'}
        </EntitlementListTableField>
      )}

      {mode === 'clinic' && (
        <EntitlementListTableField style={{ width: '9rem' }}>
          {groupedEntitlement.clinic_role ?? 'NULL'}
        </EntitlementListTableField>
      )}

      <EntitlementListTableField style={{ width: '7rem' }}>
        {groupedEntitlement.resource}
      </EntitlementListTableField>

      {mode === 'user' && (
        <EntitlementListTableField style={{ width: '7rem' }}>
          <StyledOvButton>
            <Link to={`/users?roles=${groupedEntitlement.user_role}`}>
              {groupedEntitlement.user_count}
            </Link>
          </StyledOvButton>
        </EntitlementListTableField>
      )}

      {mode === 'user' && (
        <EntitlementListTableField>
          <Checkbox
            checked={checkboxState.supervise}
            onChange={(evt) => handleChange(evt, Operation.Supervise)}
          />
        </EntitlementListTableField>
      )}

      <EntitlementListTableField>
        <Checkbox
          checked={checkboxState.create}
          onChange={(evt) => handleChange(evt, Operation.Create)}
        />
      </EntitlementListTableField>
      <EntitlementListTableField>
        <Checkbox
          checked={checkboxState.read}
          onChange={(evt) => handleChange(evt, Operation.Read)}
        />
      </EntitlementListTableField>
      <EntitlementListTableField>
        <Checkbox
          checked={checkboxState.update}
          onChange={(evt) => handleChange(evt, Operation.Update)}
        />
      </EntitlementListTableField>
      <EntitlementListTableField>
        <Checkbox
          checked={checkboxState.delete}
          onChange={(evt) => handleChange(evt, Operation.Delete)}
        />
      </EntitlementListTableField>
      <EntitlementListTableField style={{ width: '7.5rem' }}>
        <Tooltip title={t('entitlements.actions.delete')}>
          <StyledIconButton
            onClick={() => setOpenEntitlementDeleteConfirmationDialog(true)}
            className="actionIcon"
          >
            <DeleteIcon />
          </StyledIconButton>
        </Tooltip>
      </EntitlementListTableField>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenEntitlementDeleteConfirmationDialog}
        onCancel={() => setOpenEntitlementDeleteConfirmationDialog(false)}
        onConfirm={onDelete}
        description="entitlements.actions.permanentEntitlementDelete"
        title="common.areYouSure"
      />
    </>
  );
};

export default OvEntitlementListRow;

const EntitlementListTableField = styled(OvListTableField)`
  vertical-align: middle !important;
  padding: 0.75rem;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};
    white-space: nowrap;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.SMALL};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;
