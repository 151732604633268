export enum OrderPaymentStatus {
  Pending = 'Pending',
  Complete = 'Complete',
  Refunded = 'Refunded',
  Failed = 'Failed',
  Abandoned = 'Abandoned',
  Revoked = 'Revoked',
  Preapproved = 'Preapproved',
  Cancelled = 'Cancelled',
  Subscription = 'Subscription',
}
