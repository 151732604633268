import { createAsyncThunk } from '@reduxjs/toolkit';
import ArticlesService from '../../services/articles.service';
import { Article } from '../../common/model/dto/article';
import { ListPaging } from '../../common/types';
import { RootState } from '../store';
import { stringify } from 'query-string';
import { ArticlesState } from '../states/articles.state';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import { History } from 'history';

export const getArticles = createAsyncThunk(
  'articles/getArticles',
  async (_, { rejectWithValue, getState }) => {
    try {
      const listPaging: ListPaging = (getState() as RootState)?.articles
        ?.articleListPaging;
      const listSorting = ((getState() as RootState)?.articles as ArticlesState)
        ?.articleListSorting;
      const filters = FilterUtils.mapFilterModelsToQueryParams(
        ((getState() as RootState)?.articles as ArticlesState)
          ?.articleListFilters
      );

      const query = stringify(
        {
          offset: listPaging.offset,
          limit: ArticlesService.ARTICLE_LIST_LIMIT,
          order_by: listSorting ? listSorting.order_by : null,
          order_dir: listSorting ? listSorting.order_dir : null,
          ...FilterUtils.getQueryParams(filters),
        },
        { skipNull: true }
      );

      return await ArticlesService.getArticles(query);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getArticleById = createAsyncThunk(
  'articles/getArticleById',
  async (articleId: string) => {
    return await ArticlesService.getArticleById(articleId);
  }
);

export const createArticle = createAsyncThunk(
  'articles/createArticle',
  async (
    { history, article }: { history: History; article: Article },
    { rejectWithValue }
  ) => {
    try {
      const response: Article = await ArticlesService.createArticle(article);

      history.push(`/articles/detail/${response.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateArticle = createAsyncThunk(
  'articles/updateArticle',
  async (
    { history, article }: { history?: History; article: Article },
    { rejectWithValue }
  ) => {
    try {
      const response: Article = await ArticlesService.updateArticle(article);

      if (history) {
        history.push(`/articles/detail/${response.id}`);
      }

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteArticle = createAsyncThunk(
  'articles/deleteArticle',
  async (articleId: string, { rejectWithValue }) => {
    try {
      await ArticlesService.deleteArticle(articleId);
      return articleId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
