import axios from 'axios';
import { ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE } from '../../api/backend';
import { ClinicLocationProvider } from '../../common/model/dto/clinic-location-provider';

export class AdminClinicLocationProviderService {
  static async updateClinicLocationProviderRelation(
    clinicLocationProviderId: string,
    updateObj: any
  ) {
    await axios.patch<ClinicLocationProvider>(
      `${ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE}/${clinicLocationProviderId}`,
      updateObj
    );
  }
}
