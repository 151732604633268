import styled from 'styled-components';
import OvButton from './OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import React, { ReactElement, ReactNode } from 'react';
import { ButtonProps } from '@mui/material/Button';

interface OvLinkButtonProps {
  icon?: ReactElement;
  children?: ReactNode;
}

const OvLinkButton: React.FC<OvLinkButtonProps & ButtonProps> = ({
  icon,
  children,
  ...props
}) => {
  return (
    <StyledOvButton disableRipple {...props}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </StyledOvButton>
  );
};

export default OvLinkButton;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: ${Colours.BLUE['500']};
    font-size: ${Variables.fontSizes.LARGE};
    font-weight: bold;

    &:hover {
      background-color: transparent;
      color: ${Colours.BLUE['700']} !important;
    }
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;
