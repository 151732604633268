import React, { FC } from 'react';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import OvUserListRow from './OvUserListRow';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { useTranslation } from 'react-i18next';
import { UserInfoFields } from '../../../firebase/document-field.enums';

interface OvUserListProps {
  users: UserInfo[];
  onColumnSort: (columnName: string) => void;
  currentSortColumn?: string;
  currentSortDirection?: 'asc' | 'desc' | 'none';
}

const OvUserList: FC<OvUserListProps> = ({
  users,
  onColumnSort,
  currentSortColumn,
  currentSortDirection,
}) => {
  const { t } = useTranslation();

  const headerNames = [
    {
      name: 'common.userFields.name',
      column: UserInfoFields.first_name,
      isSortable: true,
    },
    {
      name: 'common.userFields.email',
      column: UserInfoFields.email,
      isSortable: true,
    },
    { name: 'common.userFields.clinic', column: 'clinic', isSortable: false },
    {
      name: 'common.userFields.latestScanDate',
      column: UserInfoFields.scan_created_at,
      isSortable: true,
    },
    {
      name: 'common.userFields.regDate',
      column: UserInfoFields.registration_date,
      isSortable: true,
    },
    { name: 'common.status', column: 'status', isSortable: false },
    { name: 'common.operation', column: 'operation', isSortable: false },
    { name: 'common.action', column: 'action', isSortable: false },
  ];

  return (
    <WrapperBox>
      <OvListTable>
        <OvListTableRow>
          {headerNames.map(({ name, column, isSortable }) => (
            <OvListTableHeaderField
              key={column}
              isSortable={isSortable}
              sortBy={column}
              sortDirection={
                currentSortColumn === column ? currentSortDirection : 'none'
              }
              onSort={() => onColumnSort(column)}
              isActiveSort={currentSortColumn === column}
              style={{ cursor: 'pointer' }}
            >
              {t(name)}
            </OvListTableHeaderField>
          ))}
        </OvListTableRow>

        {users.map((user) => (
          <OvListTableRow key={user.id}>
            <OvUserListRow user={user} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvUserList;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;
