import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsState } from '../states/products.state';
import { Product } from '../../common/model/dto/product/product';
import {
  createProduct,
  archiveProduct,
  getProductById,
  getProducts,
  updateProduct,
  updateProductAndGoToDetails,
} from '../thunks/products.thunk';
import { AxiosError } from 'axios';
import isNumber from 'lodash/isNumber';
import { ListPaging, OrderBy } from '../../common/types';
import { parseAxiosError } from '../../common/utils/helpers';
import { ProductFields } from '../../firebase/document-field.enums';
import { FilterType } from '../../common/model/ui/filter.type';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import ReducerUtils from '../reducer.utils';

const initialState: ProductsState = {
  loading: false,
  error: '',
  productList: [],
  productListPaging: {
    total: 0,
    offset: 0,
  },
  filters: [
    {
      fieldName: ProductFields.is_active,
      label: 'products.fields.isActive',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: true,
    },
    {
      fieldName: ProductFields.is_enabled_for_mobile,
      label: 'products.fields.isForMobile',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: ProductFields.is_enabled_for_web,
      label: 'products.fields.isForWeb',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
  ],
  showFilters: true,
  productListSorting: null,
  selectedProduct: null,
  productListStoredQuery: '',
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.productListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.productListPaging.offset = action.payload.offset;
      }
    },
    setProductListSorting: (state, action: PayloadAction<OrderBy>) => {
      state.productListSorting = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearSelectedProduct: (state) => {
      state.selectedProduct = null;
    },
    clearProductList: (state) => {
      state.productList = initialState.productList;
      state.productListSorting = initialState.productListSorting;
    },
    changeProductListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.filters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      state.filters[index].value = FilterUtils.getParsedFilterValue(
        action.payload.value
      );
      state.productListPaging.offset = 0;
    },
    clearFilters: (state) => {
      state.filters = state.filters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.filters[index].value,
          disabled: false,
        };
      });
    },
    setProductListStoredQuery: (state, action: PayloadAction<string>) => {
      state.productListStoredQuery = action.payload;
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.filters = ReducerUtils.restoreFilters(
        action.payload,
        state.filters
      );
      state.productListSorting = ReducerUtils.restoreOrderBy(action.payload);
      state.productListPaging.offset = action.payload.offset;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        getProducts.fulfilled,
        (state, action: PayloadAction<Product[]>) => {
          state.loading = false;
          state.error = '';
          state.productList = action.payload;
        }
      )
      .addCase(
        getProducts.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore
    builder
      .addCase(getProductById.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        getProductById.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.loading = false;
          state.error = '';
          state.selectedProduct = action.payload;
        }
      )
      .addCase(
        getProductById.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.loading = false;
          state.error = '';

          if (state.productList) {
            state.productList = [action.payload, ...state.productList];
          } else {
            state.productList = [action.payload];
          }
        }
      )
      .addCase(
        createProduct.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateProductAndGoToDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateProductAndGoToDetails.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.loading = false;
          state.error = '';
          state.selectedProduct = action.payload;
        }
      )
      .addCase(
        updateProductAndGoToDetails.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.loading = false;
          state.error = '';
          state.selectedProduct = action.payload;

          state.productList = state.productList.map((product) => {
            if (product.id === action.payload.id) {
              return { ...product, ...action.payload };
            }

            return product;
          });
        }
      )
      .addCase(
        updateProduct.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(archiveProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        archiveProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.loading = false;
          state.productList = state.productList.map((product) => {
            if (product.id === action.payload.id) {
              return { ...product, ...action.payload };
            }

            return product;
          });
        }
      )
      .addCase(
        archiveProduct.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = parseAxiosError(action);
        }
      );
  },
});

export const {
  setProductListPaging,
  setProductListSorting,
  clearSelectedProduct,
  clearError,
  changeProductListFilter,
  clearFilters,
  setProductListStoredQuery,
  restoreSearchModel,
} = productsSlice.actions;

export default productsSlice.reducer;
