import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import UserService from '../../services/user.service';
import { UserInfo } from '../../common/model/dto/user-info';
import { RootState } from '../store';
import {
  restoreSearchModel,
  setUserListPaging,
  setUserListStoredQuery,
} from '../reducers/user.slice';
import { shouldFilterUserList } from '../selectors/user.selectors';
import { Paginated, SortingOption } from '../../common/types';
import { parseUrl, stringify } from 'query-string';
import { History } from 'history';
import { AdminClinicLocationProviderService } from '../../services/admin/admin-clinic-location-provider.service';
import { ClinicLocationProviderService } from '../../services/clinic-location-provider.service';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import { SetDemoAccountPasswordRequest } from '../../common/model/dto/set-demo-account-password-request.ts';
import { ActivateScanningPlanRequest } from '../../common/model/dto/scan-sequences/activate-scanning-plan';
import { CancelActiveScanningPlanRequest } from '../../common/model/dto/scan-sequences/cancel-active-scanning-plan-request';

const userInfo = createAsyncThunk(
  'user/userInfo',
  async (_, { rejectWithValue }) => {
    try {
      return await UserService.getUserInfo();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await UserService.getUserInfoById(userId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deactivateUser = createAsyncThunk(
  'user/deactivateUser',
  async (
    { id, history }: { id: string; history: History },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      await UserService.deactivateUserById(id);

      const { total } = (getState() as RootState).user.userListPaging;
      dispatch(
        setUserListPaging({
          offset: 0,
          total,
        })
      );
      history.push(`/users`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (
    { id, history }: { id: string; history: History },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      await UserService.deleteUserById(id);

      const { total } = (getState() as RootState).user.userListPaging;
      dispatch(
        setUserListPaging({
          offset: 0,
          total,
        })
      );
      history.push(`/users`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userTypeahead = createAsyncThunk(
  'user/typeahead',
  async (searchText: string) => {
    return await UserService.getUserTypeahead(searchText);
  }
);

const loadUsers = createAsyncThunk<UserInfo[], History, { state: RootState }>(
  'user/loadUsers',
  async (history: History, { dispatch, getState }) => {
    try {
      let paginatedUsers: Paginated<UserInfo>;
      const state = getState();
      const { offset } = state.user.userListPaging;
      const sorting = getUserListQuerySorting(state);
      const filters = FilterUtils.mapFilterModelsToQueryParams(
        state.user.filters
      );

      // Define the base query parameters
      const baseQueryParams = {
        limit: UserService.USER_LIST_LIMIT,
        offset,
        order_by: sorting?.orderBy,
        order_dir: sorting?.orderDir,
      };

      // Merge filter parameters if filters are applied
      const queryParams = shouldFilterUserList(state)
        ? { ...baseQueryParams, ...FilterUtils.getQueryParams(filters) }
        : baseQueryParams;

      // Create the query string
      const query = stringify(queryParams);

      // Load users based on the query
      paginatedUsers = await UserService.loadUsersByQuery(query);

      // Update the stored query in the Redux state and history
      dispatch(
        setUserListStoredQuery(
          offset > 0 || shouldFilterUserList(state) ? query : ''
        )
      );
      history.push(`/users${query ? '?' + query : ''}`);

      // Update paging state
      setPaging({ ...paginatedUsers, offset }, dispatch);

      return paginatedUsers.docs;
    } catch (error) {
      // Handle error by returning an empty array
      return [];
    }
  }
);

const restoreUserSearchFromUrl = createAsyncThunk<
  UserInfo[],
  string,
  { state: RootState }
>('user/loadUsers', async (query, { dispatch, getState }) => {
  try {
    let paginatedUsers: Paginated<UserInfo>;

    dispatch(setUserListStoredQuery(query));
    dispatch(restoreSearchModel(parseUrl(query).query));
    paginatedUsers = await UserService.loadUsersByQuery(query.slice(1));
    setPaging(
      { ...paginatedUsers, offset: getState().user.userListPaging.offset },
      dispatch
    );

    return paginatedUsers.docs;
  } catch (error) {
    return [];
  }
});

const loadAllFertilityDisorders = createAsyncThunk(
  'user/fertilityDisorders',
  async () => {
    const disorders = await UserService.loadAllFertilityDisorders();

    return disorders.map((disorder) => disorder.known_reproductive_disorder);
  }
);

const updateOwnUserInfo = createAsyncThunk(
  'user/updateOwnUserInfo',
  async (updatedProperties: any, { rejectWithValue }) => {
    try {
      return await UserService.updateOwnUserInfo(updatedProperties);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (
    updateObj: {
      userId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      await UserService.updateUserInfo(
        updateObj.userId,
        updateObj.updatedProperties
      );

      return updateObj;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const activateScanningPlanForUser = createAsyncThunk(
  'user/activateScanningPlanForUser',
  async (
    params: {
      userId: string;
      activateScanningPlanRequest: ActivateScanningPlanRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const updatedUser = await UserService.activateScanningPlanForUser(
        params.userId,
        params.activateScanningPlanRequest
      );

      return updatedUser;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const cancelActiveScanningPlanForUser = createAsyncThunk(
  'user/cancelActiveScanningPlanForUser',
  async (
    params: {
      userId: string;
      cancelActiveScanningPlanRequest: CancelActiveScanningPlanRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const updatedUser = await UserService.cancelActiveScanningPlanForUser(
        params.userId,
        params.cancelActiveScanningPlanRequest
      );

      return updatedUser;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const setPasswordForDemoAccount = createAsyncThunk(
  'user/setPasswordForDemoAccount',
  async (
    request: {
      userId: string;
      setPasswordAttempt: SetDemoAccountPasswordRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const updatedUser = await UserService.setPasswordForDemoAccount(
        request.userId,
        request.setPasswordAttempt
      );

      return updatedUser;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const deletePasswordForDemoAccount = createAsyncThunk(
  'user/deletePasswordForDemoAccount',
  async (userId: string, { rejectWithValue }) => {
    try {
      const updatedUser = await UserService.deleteDemoAccountPassword(userId);

      return updatedUser;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const getDemoAccountPassword = createAsyncThunk(
  'user/getDemoAccountPassword',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await UserService.getDemoAccountPassword(userId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateOwnClinicLocationProviderData = createAsyncThunk(
  'user/updateOwnClinicLocationProviderData',
  async (
    updateObj: {
      clinicLocationProviderId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      await ClinicLocationProviderService.updateClinicLocationProviderRelation(
        updateObj.clinicLocationProviderId,
        updateObj.updatedProperties
      );

      return updateObj;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const updateClinicLocationProviderData = createAsyncThunk(
  'user/updateClinicLocationProviderData',
  async (
    updateObj: {
      clinicLocationProviderId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      await AdminClinicLocationProviderService.updateClinicLocationProviderRelation(
        updateObj.clinicLocationProviderId,
        updateObj.updatedProperties
      );

      return updateObj;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const loadAllUserRoles = createAsyncThunk('user/loadAllUserRoles', async () => {
  return await UserService.loadAllUserRoles();
});

function setPaging(
  paginatedUsers: Paginated<UserInfo>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setUserListPaging({
      offset: paginatedUsers.offset || 0,
      total: paginatedUsers.total,
    })
  );
}

function getUserListQuerySorting(state: RootState) {
  const sortingOptions: SortingOption[] = state?.user?.sortingOptions;

  const sortingOption = sortingOptions
    .filter((sortingOption) => sortingOption)
    .find((sortingOption) => ['asc', 'desc'].includes(sortingOption.direction));

  if (sortingOption) {
    return {
      orderBy: sortingOption.value,
      orderDir: sortingOption.direction,
    };
  }
}

export {
  userInfo,
  loadUsers,
  loadAllFertilityDisorders,
  loadAllUserRoles,
  userTypeahead,
  getUserDetails,
  deactivateUser,
  updateUserInfo,
  restoreUserSearchFromUrl,
  updateOwnUserInfo,
  deleteUser,
  updateClinicLocationProviderData,
  updateOwnClinicLocationProviderData,
  setPasswordForDemoAccount,
  getDemoAccountPassword,
  deletePasswordForDemoAccount,
  activateScanningPlanForUser,
  cancelActiveScanningPlanForUser,
};
