import React, { FC, useEffect, useState } from 'react';
import {
  EventListenerPayload,
  Fullscript,
  PatientOptions,
} from '@fullscript/fullscript-js';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { ProviderCountryEnum } from '../../common/model/dto/fullscript/provider-country.enum';
import { UserInfo } from '../../common/model/dto/user-info';
import { Feature } from '@fullscript/fullscript-js/dist/feature';
import UserService from '../../services/user.service';
import { useTranslation } from 'react-i18next';
import OvTextButton from '../UI/atoms/OvTextButton';
import OvButton from '../UI/atoms/OvButton';
import AddIcon from '@mui/icons-material/Add';
import { FullScriptService } from '../../services/fullscript.service';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearTreatmentPlans } from '../../redux/reducers/fullscript.slice';
import {
  getTreatmentPlans,
  removeTreatmentPlan,
} from '../../redux/thunks/fullscript.thunk';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvFullscriptTreatmentPlan from '../UI/molecules/OvFullscriptTreatmentPlan';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreatmentPlan } from '../../common/model/dto/fullscript/treatment-plan';
import OvDateParser from '../UI/atoms/OvDateParser';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';

const FullScript: FC<{
  patient: UserInfo;
  onLinkToPatient: (fsId: string) => void;
  onUnlinkToPatient: () => void;
}> = ({ patient, onLinkToPatient, onUnlinkToPatient }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const treatmentPlans = useAppSelector(
    (state) => state.fullScript?.treatmentPlans
  );
  const isLoading = useAppSelector((state) => state.fullScript?.loading);
  const errorMessage: string = useAppSelector(
    (state) => state.fullScript?.errorMessage
  );
  const [isVisibleFSWrapper, setVisibleFSWrapper] = useState<boolean>(false);
  const [isOpenConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  let feature: Feature | null;

  useEffect(() => {
    return () => {
      feature?.unmount();
      dispatch(clearTreatmentPlans());
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (patient?.id && patient.fullscript_patient_id) {
      dispatch(getTreatmentPlans(patient.fullscript_patient_id));
    }

    // eslint-disable-next-line
  }, [patient.id]);

  const mountTreatmentPlan = (
    secretToken: string,
    selectedCountry: ProviderCountryEnum
  ) => {
    removeFsFromUI();
    const fsPatient: PatientOptions = {
      firstName: patient.first_name || '',
      lastName: patient.last_name || '',
      email: patient.email || '',
      id: patient.fullscript_patient_id || '',
    };

    setVisibleFSWrapper(true);
    const client = Fullscript({
      publicKey:
        (selectedCountry.startsWith('us')
          ? process.env.REACT_APP_FS_CLIENT_ID_US
          : process.env.REACT_APP_FS_CLIENT_ID_CA) ?? '',
      env: selectedCountry,
    });

    feature = client.create('treatmentPlan', {
      secretToken,
      patient: fsPatient,
    });

    feature.mount('treatment-plan-iframe');

    feature.on(
      'patient.selected',
      (data: EventListenerPayload<'patient.selected'>) => {
        const fsPatient = data.data.patient;

        if (!patient.fullscript_patient_id) {
          UserService.linkFullScriptPatientIdToUser(
            patient.id,
            fsPatient.id
          ).then(() => {
            onLinkToPatient(fsPatient.id);
          });
        }
      }
    );
  };

  const unlinkPatient = () => {
    setOpenConfirmationDialog(false);
    removeFsFromUI();
    UserService.unlinkFullScriptPatientIdToUser(patient.id).then(() => {
      onUnlinkToPatient();
      dispatch(clearTreatmentPlans());
    });
  };

  const removeFsFromUI = (options?: { reloadTreatmentPlans: boolean }) => {
    feature?.unmount();
    feature = null;
    setVisibleFSWrapper(false);

    if (
      options?.reloadTreatmentPlans &&
      patient?.id &&
      patient.fullscript_patient_id
    ) {
      dispatch(getTreatmentPlans(patient.fullscript_patient_id));
    }
  };

  const startNewRecommendation = async () => {
    const { data } = await FullScriptService.requestSessionGrant();
    mountTreatmentPlan(
      data.secret_token as string,
      data.selected_country as ProviderCountryEnum
    );
  };

  const cancelTreatmentPlan = (id: string) => {
    dispatch(removeTreatmentPlan(id));
  };

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <Header>
            <Title>
              <FsLogoImage
                src="/images/fullscript/fs.png"
                alt={t('app.subPages.menu.fullScript')}
              />
              {t('integrations.fsTitle')}
            </Title>

            {isVisibleFSWrapper && (
              <StyledTextButton
                onClick={() => removeFsFromUI({ reloadTreatmentPlans: true })}
              >
                {t('integrations.removeFsView')}
              </StyledTextButton>
            )}
          </Header>
          {!isVisibleFSWrapper ? (
            <>
              <Description>
                {patient.fullscript_patient_id
                  ? t('integrations.fsLinkedUserTitle', {
                      firstName: patient.first_name,
                      fullName:
                        patient.first_name +
                        (patient.last_name ? ` ${patient.last_name}` : ''),
                      email: patient.email,
                    })
                  : t('integrations.fsNotFoundUserTitle', {
                      firstName: patient.first_name,
                      fullName:
                        patient.first_name +
                        (patient.last_name ? ` ${patient.last_name}` : ''),
                    })}
              </Description>

              {patient.fullscript_patient_id ? (
                <>
                  <StyledTextButton
                    onClick={() => setOpenConfirmationDialog(true)}
                  >
                    {t('integrations.fsLinkedWrongUser')}
                  </StyledTextButton>
                  <OvConfirmationDialog
                    isOpen={isOpenConfirmationDialog}
                    onCancel={() => setOpenConfirmationDialog(false)}
                    onConfirm={unlinkPatient}
                    description={'integrations.unlinkPatientConfirmationDesc'}
                    title={t('common.areYouSure')}
                  />
                </>
              ) : (
                ''
              )}

              {treatmentPlans?.length ? (
                <PlansContainer>
                  <Description style={{ marginBottom: '0.25rem' }}>
                    {t('integrations.plansTitle', {
                      firstName: patient?.first_name,
                    })}
                  </Description>

                  {treatmentPlans.map((treatmentPlan: TreatmentPlan) => {
                    return (
                      <StyledAccordion key={treatmentPlan.id}>
                        <StyledAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={treatmentPlan.id}
                          id={treatmentPlan.id}
                        >
                          <FsLogoImage
                            src="/images/fullscript/fs.png"
                            alt={t('app.subPages.menu.fullScript')}
                          />
                          <SubTitle>
                            {t('integrations.status', {
                              status: treatmentPlan.state,
                            })}
                          </SubTitle>
                          <DateText>
                            &nbsp;
                            {t('common.from')}
                            &nbsp;
                            <OvDateParser
                              date={new Date(treatmentPlan.updated_at)}
                              format={'MMMM DD, YYYY'}
                            />
                          </DateText>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                          <OvFullscriptTreatmentPlan
                            plan={treatmentPlan}
                            onCancel={cancelTreatmentPlan}
                          />
                        </StyledAccordionDetails>
                      </StyledAccordion>
                    );
                  })}
                </PlansContainer>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {errorMessage && <OvErrorMessage message={errorMessage} />}
          {isVisibleFSWrapper ? (
            <FullScriptJSWrapper id="treatment-plan-iframe"></FullScriptJSWrapper>
          ) : (
            ''
          )}
        </InnerWrapper>

        {!isVisibleFSWrapper ? (
          <CtaRow>
            <ConnectCta onClick={startNewRecommendation}>
              <AddIcon />
              {t('integrations.fsNewRecommendation', {
                firstName: patient.first_name,
              })}
            </ConnectCta>
          </CtaRow>
        ) : (
          ''
        )}
      </Wrapper>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default FullScript;

const Wrapper = styled.div`
  margin-left: 1rem;
`;

const FullScriptJSWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 5rem);
  margin-top: 1.5rem;
`;

const InnerWrapper = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Header = styled.header`
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  margin: 0;
  display: flex;
  align-items: center;
`;

const SubTitle = styled.h5`
  margin: 0;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const DateText = styled.span`
  color: ${Colours.GRAY};
  font-size: ${Variables.fontSizes.SMALL};
`;

const FsLogoImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const Description = styled.p`
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledTextButton = styled(OvTextButton)`
  && {
    color: ${Colours.OV_VIVID_BLUE};
    text-transform: none;

    &:hover {
      color: ${Colours.OV_VIVID_BLUE};
      font-weight: bold;
    }
  }
`;

const CtaRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ConnectCta = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.MEDIUM};
    padding: 0.25rem 0.75rem;
    background-color: ${Colours.FULLSCRIPT_GREEN};
    color: ${Colours.FULLSCRIPT_COAL} !important;
    text-transform: none;
    border: none !important;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: ${Colours.FULLSCRIPT_GREEN};
      color: ${Colours.FULLSCRIPT_COAL} !important;
    }
`;

const PlansContainer = styled.div`
  margin-top: 1rem;
`;

const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;

    &.Mui-expanded {
      margin: 0.5rem 0;
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${Colours.OV_WHITE_HOVER};
    border-radius: 0.25rem;
    padding: 0.75rem;

    .MuiAccordionSummary-content {
      align-items: center;
      margin: 0;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    &.MuiAccordionDetails-root {
      padding: 0.5rem;
    }
  }
`;
