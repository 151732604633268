import { createAsyncThunk } from '@reduxjs/toolkit';
import { DemoAccountData } from '../../common/model/dto/demo-account-data';
import { DemoAccountDataService } from '../../services/demo-account-data.service';
import { userInfo } from './user.thunk';
import { UserInfo } from '../../common/model/dto/user-info';
import { Role } from '../../common/model/type/role.enum';

const loadDemoAccountDataForUser = createAsyncThunk<DemoAccountData[]>(
  'demoAccounts/me',
  async () => {
    try {
      const demoAccountDataForCurrentUser =
        await DemoAccountDataService.loadDemoAccountDataForUser();

      return demoAccountDataForCurrentUser;
    } catch (error) {
      return [];
    }
  }
);

const deleteDemoAccountDataForCurrentUser = createAsyncThunk(
  'demoAccounts/delete',
  async (currentUser: UserInfo | undefined, { rejectWithValue, dispatch }) => {
    try {
      await DemoAccountDataService.deleteDemoAccountDataForUser();

      if (currentUser?.roles.includes(Role.Admin)) {
        dispatch(userInfo());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { loadDemoAccountDataForUser, deleteDemoAccountDataForCurrentUser };
