import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import Colours from '../../design-system/colours';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import Variables from '../../design-system/variables';
import OvButton from '../UI/atoms/OvButton';
import OvTable from '../UI/molecules/OvTable';
import OvQrCodeImage from '../UI/molecules/OvQrCodeImage';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import {
  deleteLot,
  getLotById,
  runCalibrationProcess,
  saveCalibration,
  updateLot,
} from '../../redux/thunks/lots.thunk';
import { clearSelectedLot } from '../../redux/reducers/lots.slice';
import OvHormoneCalibration, {
  CalibrationFormValue,
} from '../UI/organisms/OvHormoneCalibration';
import { LotDataUtils } from '../../common/utils/services/lot-data-utils';
import { LotFields } from '../../firebase/document-field.enums';
import { Lot } from '../../common/model/dto/lot';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DownloadUtils } from '../../common/utils/services/download-utils';
import { elementToSVG } from 'dom-to-svg';
import StringUtils from '../../common/utils/services/string-utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useBeforeunload } from 'react-beforeunload';
import { TableRowEditWidgetType } from '../../common/model/ui/table-row-edit-widget.type';
import OvBackButton from '../UI/atoms/OvBackButton';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';

const LotDetails: FC = () => {
  let { lotId }: any = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const lotOperations = useAuthorized(Resource.Lot);

  const isLoading = useAppSelector((state) => state.lots.loading);
  const lot = useAppSelector<Lot>((state) => state.lots.selectedLot);
  const calibratedSelectedLot = useAppSelector<Lot>(
    (state) => state.lots.calibratedSelectedLot
  );
  const lhArrayCalibrationError = useAppSelector<string>(
    (state) => state.lots.lhArrayCalibrationError
  );
  const pgArrayCalibrationError = useAppSelector<string>(
    (state) => state.lots.pgArrayCalibrationError
  );
  const e3gArrayCalibrationError = useAppSelector<string>(
    (state) => state.lots.e3gArrayCalibrationError
  );
  const [isOpenDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);
  useBeforeunload(
    !!calibratedSelectedLot ? (event) => event.preventDefault() : undefined
  );

  useEffect(() => {
    dispatch(getLotById(lotId));

    return () => {
      dispatch(clearSelectedLot());
    };
  }, [lotId, dispatch]);

  const openDeleteConfirmationDialog = () =>
    setOpenDeleteConfirmationDialog(true);

  const handleDelete = () => {
    setOpenDeleteConfirmationDialog(false);
    dispatch(
      deleteLot({
        id: lot?.document_id ? lot?.document_id : (lot?.id as string),
        history,
      })
    );
  };

  const formatUpdatedProperties = (updatedProperties: any) => {
    const [prop, nestedProp] = Object.keys(updatedProperties)[0]?.split('.');

    if (!!updatedProperties[LotFields.ax_array]) {
      updatedProperties[LotFields.ax_array] =
        StringUtils.convertStringToNumberArray(
          updatedProperties[LotFields.ax_array]
        );
    }

    if (nestedProp) {
      updatedProperties[prop] = {
        // @ts-ignore
        ...lot[prop],
        [nestedProp]:
          nestedProp === LotFields.x || nestedProp === LotFields.raw_x
            ? StringUtils.convertStringToNumberArray(
                updatedProperties[`${prop}.${nestedProp}`]
              )
            : updatedProperties[`${prop}.${nestedProp}`],
      };
    }
  };

  const handleLotUpdate = (updatedProperties: any) => {
    if (lot?.id) {
      formatUpdatedProperties(updatedProperties);

      dispatch(
        updateLot({
          lotId: lot?.id,
          lot: {
            ...updatedProperties,
          },
        })
      );
    }
  };

  const saveUpdatedModelFitAndCalibrationParams = (updateLot: Lot) => {
    if (lot?.id) {
      dispatch(
        saveCalibration({
          lotId: lot?.id,
          lot: updateLot,
        })
      );
    }
  };

  const handleCalibration = (
    calibrationValue: CalibrationFormValue,
    cartridge_type: 'lr_e3g' | 'lh' | 'pg' | 'testosterone'
  ) => {
    const concentrations = StringUtils.convertStringToNumberArray(
      calibrationValue.concentrations
    );
    const signals = StringUtils.convertStringToNumberArray(
      calibrationValue.signals
    );

    dispatch(
      runCalibrationProcess({
        fit_type: calibrationValue.fit_type,
        concentrations,
        signals,
        cartridge_type,
        should_fix_min_at_zero: calibrationValue.should_fix_min_at_zero,
      })
    );
  };

  const handleQrCodeDownload = () => {
    const svgElement = elementToSVG(
      document.querySelector('.qr-code-container') as Element
    );

    DownloadUtils.downloadSvg(
      svgElement,
      lot?.document_id ? lot?.document_id : (lot?.id as string)
    );
  };

  const lotDeleteAllowed =
    lotOperations.delete || applicationOperations.supervise;
  const lotUpdateAllowed =
    lotOperations.update || applicationOperations.supervise;

  return (
    <Container>
      <Header>
        <HeaderGroup>
          <OvBackButton label={t('common.actions.back')} />
          <LotId>
            {lot?.document_id ? lot?.document_id : (lot?.id as string)}
          </LotId>
        </HeaderGroup>
        <HeaderGroup>
          {lotDeleteAllowed && (
            <StyledCta onClick={openDeleteConfirmationDialog}>
              {t('common.actions.delete')}
            </StyledCta>
          )}
        </HeaderGroup>
      </Header>

      {!!lot && (
        <ContentContainer>
          <QrCodeImageContainer>
            <div className="qr-code-container">
              <OvQrCodeImage
                options={{
                  value: (lot?.document_id
                    ? lot?.document_id
                    : lot?.id) as string,
                }}
              />

              <QrCodeLabel>
                {LotDataUtils.getLotTypeLabel(lot?.type)}
              </QrCodeLabel>
            </div>

            <CtaContainer>
              <StyledIconCta onClick={handleQrCodeDownload}>
                <StyledCloudDownloadIcon />
                {t('common.downloadQrCode')}
              </StyledIconCta>
            </CtaContainer>
          </QrCodeImageContainer>

          <DataContainer>
            <OvTable
              data={LotDataUtils.mapLotToTable(lot)}
              theme="Light"
              onSaveRowValue={handleLotUpdate}
              editable={lotUpdateAllowed}
            ></OvTable>

            <Wrapper>
              <StyledAccordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="lh"
                  id="lh"
                >
                  <StyledTitle>{t('common.luteinizing')}</StyledTitle>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <OvHormoneCalibration
                    initialCalibrationValue={lot.lh_calibration}
                    initialHormoneModelFitParameters={{
                      ...lot.lh_model_fit_parameters,
                      ...calibratedSelectedLot?.lh_model_fit_parameters,
                    }}
                    errorMessage={lhArrayCalibrationError}
                    runModel={(values) => handleCalibration(values, 'lh')}
                    saveChanges={saveUpdatedModelFitAndCalibrationParams}
                    type="lh"
                    unit={lot?.lh_unit}
                    ax_array={lot?.ax_array}
                  />

                  <OvTable
                    data={[
                      {
                        label: 'lots.calibration.fields.unit',
                        translateLabel: true,
                        value: lot?.lh_unit,
                        prettifiedValue: lot?.lh_unit || '-',
                        editOptions: {
                          propertyName: LotFields.lh_unit,
                          widgetType: TableRowEditWidgetType.TEXT,
                        },
                      },
                    ]}
                    theme="Light"
                    onSaveRowValue={handleLotUpdate}
                  ></OvTable>
                </AccordionDetails>
              </StyledAccordion>
            </Wrapper>

            <Wrapper>
              <StyledAccordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="pg"
                  id="pg"
                >
                  <StyledTitle>{t('common.progesterone')}</StyledTitle>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <OvHormoneCalibration
                    initialCalibrationValue={lot.pg_calibration}
                    initialHormoneModelFitParameters={{
                      ...lot.pg_model_fit_parameters,
                      ...calibratedSelectedLot?.pg_model_fit_parameters,
                    }}
                    errorMessage={pgArrayCalibrationError}
                    runModel={(values) => handleCalibration(values, 'pg')}
                    saveChanges={saveUpdatedModelFitAndCalibrationParams}
                    type="pg"
                    unit={lot?.pg_unit}
                    ax_array={lot?.ax_array}
                  />

                  <OvTable
                    data={[
                      {
                        label: 'lots.calibration.fields.unit',
                        translateLabel: true,
                        value: lot?.pg_unit,
                        prettifiedValue: lot?.pg_unit || '-',
                        editOptions: {
                          propertyName: LotFields.pg_unit,
                          widgetType: TableRowEditWidgetType.TEXT,
                        },
                      },
                    ]}
                    theme="Light"
                    onSaveRowValue={handleLotUpdate}
                  ></OvTable>
                </AccordionDetails>
              </StyledAccordion>
            </Wrapper>

            <Wrapper>
              <StyledAccordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="lr_e3g"
                  id="lr_e3g"
                >
                  <StyledTitle>{t('common.lowRangeEstrogen')}</StyledTitle>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <OvHormoneCalibration
                    initialCalibrationValue={lot.lr_e3g_calibration}
                    initialHormoneModelFitParameters={{
                      ...lot.lr_e3g_model_fit_parameters,
                      ...calibratedSelectedLot?.lr_e3g_model_fit_parameters,
                    }}
                    errorMessage={e3gArrayCalibrationError}
                    runModel={(values) => handleCalibration(values, 'lr_e3g')}
                    saveChanges={saveUpdatedModelFitAndCalibrationParams}
                    type="lr_e3g"
                    unit={lot?.lr_e3g_unit}
                    ax_array={lot?.ax_array}
                  />

                  <OvTable
                    data={[
                      {
                        label: 'lots.calibration.fields.unit',
                        translateLabel: true,
                        value: lot?.lr_e3g_unit,
                        prettifiedValue: lot?.lr_e3g_unit || '-',
                        editOptions: {
                          propertyName: LotFields.lr_e3g_unit,
                          widgetType: TableRowEditWidgetType.TEXT,
                        },
                      },
                    ]}
                    theme="Light"
                    onSaveRowValue={handleLotUpdate}
                  ></OvTable>
                </AccordionDetails>
              </StyledAccordion>
            </Wrapper>
          </DataContainer>
        </ContentContainer>
      )}

      <OvConfirmationDialog
        isOpen={isOpenDeleteConfirmationDialog}
        onCancel={() => setOpenDeleteConfirmationDialog(false)}
        onConfirm={handleDelete}
        description={'lots.confirmDelete'}
        title={t('common.areYouSure')}
      />

      <Prompt
        when={!!calibratedSelectedLot}
        message={t('common.leavePrompt')}
      />

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Container>
  );
};

export default LotDetails;

const Container = styled.section`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  font-weight: bold;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const LotId = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
`;

const StyledCta = styled(OvButton)`
  && {
    margin-left: auto;
    padding: 0 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    min-width: 8.25rem;
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

const QrCodeImageContainer = styled.div``;

const QrCodeLabel = styled.p`
  font-size: ${Variables.fontSizes.XXLARGE};
  line-height: ${Variables.fontSizes.XXLARGE};
  font-family: 'CentraNo2-Medium';
  margin: 0.875rem 0;
  text-align: center;
  color: black;
`;

const DataContainer = styled.div`
  flex-grow: 1;
`;

const StyledIconCta = styled(OvButton)`
  && {
    padding: 0 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const StyledCloudDownloadIcon = styled(CloudDownloadIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const Wrapper = styled.div`
  padding: 1rem 0;
`;

const StyledTitle = styled.span`
  font-size: ${Variables.fontSizes.MEDIUM};
  font-weight: bold;
`;

const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    background-color: ${Colours.OV_LIGHT_GRAY};
    border-radius: 0.25rem;
    align-items: center;

    .MuiAccordionSummary-content {
      align-items: center;
    }
  }
`;
