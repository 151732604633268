import { RootState } from '../store';
import { FilterType } from '../../common/model/ui/filter.type';
import { AdminScansState } from '../states/admin-scans.state';

export const hasAppliedAdminScanFiltersSelector = (
  state: RootState
): boolean => {
  const adminScansState = state.adminScans as AdminScansState;

  return adminScansState.filters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};
