import axios from 'axios';
import { ADMIN_LOTS } from '../api/backend';
import { Paginated } from '../common/types';
import { Lot } from '../common/model/dto/lot';
import { LotType } from '../common/model/type/lot.type';
import { CalculateFitParameters } from '../common/model/dto/calculate-fit-parameters';
import { HormoneModelFitParameters } from '../common/model/dto/hormone-model-fit-parameters';
import { CalibrationErrorResponse } from '../common/model/dto/calibration-error-response';

export default class LotsService {
  public static readonly LOT_LIST_LIMIT: number = 10;

  static async getLots(query: string): Promise<Paginated<Lot>> {
    const { data } = await axios.get<Paginated<Lot>>(`${ADMIN_LOTS}?${query}`);
    return data;
  }

  static async getById(id: string): Promise<Lot> {
    const { data } = await axios.get<Lot>(`${ADMIN_LOTS}/${id}`);
    return data;
  }

  static async createLot(type: LotType): Promise<Lot> {
    const { data } = await axios.post<Lot>(`${ADMIN_LOTS}`, { type });
    return data;
  }

  static async deleteLot(id: string) {
    return await axios.delete(`${ADMIN_LOTS}/${id}`);
  }

  static async updateLot(lotId: string, lot: Lot): Promise<Lot> {
    const { data } = await axios.patch<Lot>(`${ADMIN_LOTS}/${lotId}`, lot);
    return data;
  }

  static async runCalibrationProcess(
    fitParams: CalculateFitParameters
  ): Promise<HormoneModelFitParameters & CalibrationErrorResponse> {
    const { data } = await axios.post<
      HormoneModelFitParameters & CalibrationErrorResponse
    >(`${ADMIN_LOTS}/calculate-fit-parameters`, fitParams);
    return data;
  }

  static async loadAllLotId(): Promise<string[]> {
    const { data } = await axios.get<string[]>(`${ADMIN_LOTS}/lotids`);

    return data;
  }
}
