import { createAsyncThunk } from '@reduxjs/toolkit';
import { Paginated } from '../../../common/types';
import { RootState } from '../../store';
import { parseUrl, stringify } from 'query-string';
import { ScansService } from '../../../services/scans.service';
import { Scan } from '../../../common/model/dto/scan';
import {
  restoreSearchModel,
  setScanListPaging,
  setStoredQuery,
} from '../../reducers/admin-scans.slice';
import { History } from 'history';
import LotsService from '../../../services/lots.service';
import { FilterUtils } from '../../../common/utils/services/filter.utils';

const getAdminScanList = createAsyncThunk<
  Scan[],
  History,
  { state: RootState }
>(
  'adminScans/getList',
  async (history: History, { dispatch, getState, rejectWithValue }) => {
    try {
      let paginatedScanList: Paginated<Scan>;
      const { offset } = getState().adminScans.scanListPaging,
        filters = FilterUtils.mapFilterModelsToQueryParams(
          getState().adminScans.filters
        );
      const query = stringify(
        {
          limit: ScansService.ADMIN_SCAN_LIST_LIMIT,
          offset,
          ...FilterUtils.getQueryParams(filters),
        },
        { skipNull: true }
      );

      paginatedScanList = await ScansService.loadScans(query);
      dispatch(setStoredQuery(offset > 0 ? query : ''));
      history.push(`/scan-list${offset > 0 ? '?' + query : ''}`);

      dispatch(setScanListPaging({ ...paginatedScanList, offset }));

      return paginatedScanList.docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteScanFromAdminListById = createAsyncThunk(
  'adminScans/deleteById',
  async (scanId: string, { rejectWithValue }) => {
    try {
      await ScansService.deleteScan(scanId);

      return scanId;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const restoreScanListFromUrl = createAsyncThunk<
  Scan[],
  string,
  { state: RootState }
>('adminScans/getList', async (query, { dispatch, getState }) => {
  try {
    let paginatedScanList: Paginated<Scan>;

    dispatch(setStoredQuery(query));
    dispatch(restoreSearchModel(parseUrl(query).query));

    paginatedScanList = await ScansService.loadScans(query.slice(1));
    dispatch(
      setScanListPaging({
        total: paginatedScanList.total,
        offset: getState().adminScans.scanListPaging.offset,
      })
    );

    return paginatedScanList.docs;
  } catch (error) {
    return [];
  }
});

const loadAllLotId = createAsyncThunk('adminScans/loadLotIds', async () => {
  return await LotsService.loadAllLotId();
});

const loadAllScanDevices = createAsyncThunk(
  'scans/loadAllScanDevices',
  async () => {
    const devices = await ScansService.loadAllScanDevices();

    return devices.map(({ device_model }) => device_model);
  }
);

const loadAllAppVersions = createAsyncThunk(
  'scans/loadAllAppVersions',
  async () => {
    const devices = await ScansService.loadAllAppVersions();

    return devices.map(({ app_version }) => app_version);
  }
);

export {
  getAdminScanList,
  deleteScanFromAdminListById,
  restoreScanListFromUrl,
  loadAllLotId,
  loadAllScanDevices,
  loadAllAppVersions,
};
