import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

const OvLabelledValue: FC<OvLabelledValueProps> = ({ items, ...props }) => {
  return (
    <Wrapper {...props}>
      {items.map((item, index) => {
        if (!item.label) {
          return (
            item.value && (
              <Item key={index} style={{ fontWeight: 'bold' }}>
                {item.value}
              </Item>
            )
          );
        }

        return (
          <Item key={index}>
            <Label>{item.label}</Label>
            <span>&nbsp;{item.value}</span>
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default OvLabelledValue;

const Wrapper = styled.div`
  min-width: 20rem;
  color: ${Colours.OV_SEMI_LIGHT};
  background-color: ${Colours.OV_BASE};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${Colours.OV_GRAY};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${Colours.OV_YELLOW} !important;
    color: ${Colours.OV_BASE};
  }
`;

const Item = styled.p`
  margin: 0;
  width: 100%;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const Label = styled.span`
  font-weight: bold;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

interface OvLabelledValueProps {
  items: { label?: string; value: string }[];
}
