import axios from 'axios';
import { SENDGRID_ROUTE } from '../api/backend';

export class SendgridService {
  static async invitePatientToOrder(
    email: string,
    patientName: string,
    providerName: string,
    paymentLink: string,
    clinicLocationName: string
  ) {
    await axios.post(`${SENDGRID_ROUTE}/invite-patient-to-order`, {
      email,
      patient_name: patientName,
      provider_name: providerName,
      payment_link: paymentLink,
      clinic_location_name: clinicLocationName,
    });
  }
}
