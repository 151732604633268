import axios from 'axios';
import { CLINIC_LOCATION_PROVIDERS_ROUTE } from '../api/backend';
import { ClinicLocationProvider } from '../common/model/dto/clinic-location-provider';

export class ClinicLocationProviderService {
  static async updateClinicLocationProviderRelation(
    clinicLocationProviderId: string,
    updateObj: any
  ) {
    await axios.patch<ClinicLocationProvider>(
      `${CLINIC_LOCATION_PROVIDERS_ROUTE}/${clinicLocationProviderId}`,
      updateObj
    );
  }
}
