import { Box } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import ChartData from '../../../common/model/type/chart-data.type';

const OvCustomChartTooltip: FC<{
  active: boolean;
  payload: any;
  chartData: {
    type: string;
    unit: string;
    values: any[];
  };
  chartStyleProps: {
    firstMeasureIndex: number;
    lastMeasureIndex: number;
    xAxisTicks: any[];
    lineColorBreakPoints: any[];
  };
}> = ({ active, payload, chartData, chartStyleProps }) => {
  const { t } = useTranslation();
  let dataPointPayload: ChartData | undefined;
  if (active === true && !!payload) {
    dataPointPayload = payload![0]?.payload;
  }
  if (!dataPointPayload) {
    return null;
  }

  const isInRange =
    dataPointPayload.index >= chartStyleProps.firstMeasureIndex &&
    dataPointPayload.index <= chartStyleProps.lastMeasureIndex;

  const { dateStr, value, measured, dailyData } = dataPointPayload;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding="0.5rem 0.75rem"
        bgcolor={Colours.CD_TOOLTIP_HEADER}
        color={Colours.OV_WHITE}
        fontSize={Variables.fontSizes.SMALL}
        style={
          isInRange
            ? {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
              }
            : { borderRadius: 4 }
        }
      >
        <StyledHeading style={{ opacity: 1 }}>
          {dailyData &&
          dailyData?.cycle_info &&
          dailyData?.cycle_info?.daysSinceLastPeriodStart !== undefined &&
          dailyData?.cycle_info?.daysSinceLastPeriodStart !== null
            ? `${t('dashboard.chart.day')} ${
                dailyData?.cycle_info?.daysSinceLastPeriodStart + 1
              } - ${dateStr}`.toUpperCase()
            : `${dateStr}`.toUpperCase()}
        </StyledHeading>
      </Box>
      {isInRange && (
        <Box
          padding="0.5rem 0.75rem"
          bgcolor={Colours.OV_BASE}
          color={Colours.OV_WHITE}
          fontSize={Variables.fontSizes.MEDIUM}
          fontWeight="bold"
          style={{
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          }}
        >
          <StyledHeading style={{ opacity: 1 }}>
            {measured ? value.toFixed(2) + ' ' + chartData.unit : 'Missed Scan'}
          </StyledHeading>
        </Box>
      )}
    </>
  );
};

export default OvCustomChartTooltip;

const StyledHeading = styled.label`
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif !important;
`;
