import React from 'react';
import styled from 'styled-components';

const OvDeviceView: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <DeviceOuterWrapper>
      <DeviceInnerWrapper>{children}</DeviceInnerWrapper>
    </DeviceOuterWrapper>
  );
};

export default OvDeviceView;

const DeviceOuterWrapper = styled.div`
  width: 100%;
  max-width: 24rem;
  height: 100%;
  background-image: url('/images/iphone.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 3rem 3rem;
`;

const DeviceInnerWrapper = styled.div`
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 5px;
`;
