import { createAsyncThunk } from '@reduxjs/toolkit';
import { FullScriptService } from '../../services/fullscript.service';
import { FullscriptRequestAccessToken } from '../../common/model/dto/fullscript/fullscript-request-access-token';
import { updateFullScriptUserInfo } from '../reducers/user.slice';
import { Constants } from '../../common/constants';
import axios from 'axios';

export const requestFullScriptAccessToken = createAsyncThunk(
  'fullScript/requestAccessToken',
  async (
    requestAccessTokenObject: FullscriptRequestAccessToken,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await FullScriptService.requestAccessToken(
        requestAccessTokenObject
      );

      dispatch(updateFullScriptUserInfo(data));

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const revokeFullScriptAccess = createAsyncThunk(
  'fullScript/revokeAccess',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await FullScriptService.revokeAccess();
      dispatch(updateFullScriptUserInfo(undefined));
      localStorage.removeItem(
        Constants.LOCAL_STORAGE_KEYS.FS_SELECTED_PROVIDER_COUNTRY
      );

      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const getTreatmentPlans = createAsyncThunk(
  'fullScript/getTreatmentPlans',
  async (patientId: string, { rejectWithValue }) => {
    try {
      return await FullScriptService.getTreatmentPlans(patientId);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const removeTreatmentPlan = createAsyncThunk(
  'fullScript/removeTreatmentPlan',
  async (variant_id: string, { rejectWithValue }) => {
    try {
      await FullScriptService.removeTreatmentPlan(variant_id);

      return variant_id;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);
