import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { ScansService } from '../../services/scans.service';
import { CreateScanRequest } from '../../common/model/dto/create-scan-request';

export const loadScansBetweenDateRange = createAsyncThunk(
  'scans/betweenDates',
  async (
    {
      userDocumentId,
      dayFrom,
      dayTo,
    }: { userDocumentId: string; dayFrom: string; dayTo: string },
    { rejectWithValue }
  ) => {
    try {
      const queryParams: any = {
        user_document_id: userDocumentId,
        day_from: dayFrom,
        day_to: dayTo,
        limit: 100,
      };

      const query = stringify(queryParams);
      const paginatedScanList = await ScansService.loadScans(query);
      return paginatedScanList.docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createScan = createAsyncThunk(
  'scans/createScan',
  async (createScanRequest: CreateScanRequest, { rejectWithValue }) => {
    try {
      return await ScansService.createScan(createScanRequest);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const findScanById = createAsyncThunk(
  'scans/findById',
  async (scanId: string, { rejectWithValue }) => {
    try {
      return await ScansService.findScanById(scanId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteScanById = createAsyncThunk(
  'scans/deleteScan',
  async (scanId: string, { rejectWithValue }) => {
    try {
      await ScansService.deleteScan(scanId);

      return scanId;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateScan = createAsyncThunk(
  'scans/updateScan',
  async (
    updateObj: {
      scanId: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      return await ScansService.updateScan(
        updateObj.scanId,
        updateObj.updatedProperties
      );
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
