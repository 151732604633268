import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfigurationService } from '../../services/configuration.service';

export const getProviderDefaultStripeLink = createAsyncThunk(
  'configuration/getProviderDefaultStripeLink',
  async (_, { rejectWithValue }) => {
    try {
      return await ConfigurationService.getProviderDefaultStripeLink();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
