import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Clinic, ClinicOverview } from '../../../common/model/dto/clinic';
import StringUtils from '../../../common/utils/services/string-utils';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import OvListTableField from '../atoms/OvListTableField';
import DateUtils from '../../../common/utils/services/date-utils';
import useAuthorized from '../../../hooks/use-authorized';
import { Resource } from '../../../common/model/type/resource.enum';

const OvClinicListRow: FC<{ clinic: Clinic | ClinicOverview }> = ({
  clinic,
}) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicAuditLogActivityOperations = useAuthorized(
    Resource.ClinicAuditLogActivity
  );

  const clinicAuditLogActivityReadAllowed =
    clinicAuditLogActivityOperations.read || applicationOperations.supervise;

  return (
    <>
      <OvListTableField
        style={{
          color: clinic.is_demo_clinic ? Colours.OV_ORANGE : Colours.OV_BASE,
        }}
      >
        {StringUtils.displayValue(`${clinic?.name || ''}`)}
      </OvListTableField>

      <OvListTableField>
        <>{(clinic as ClinicOverview).clinic_location_count}</>
      </OvListTableField>

      <OvListTableField>
        {(clinic as ClinicOverview).clinic_location_provider_count === '0' ? (
          <span style={{ color: Colours.OV_RED }}>
            {(clinic as ClinicOverview).clinic_location_provider_count}
          </span>
        ) : (
          <>{(clinic as ClinicOverview).clinic_location_provider_count}</>
        )}
      </OvListTableField>

      <OvListTableField>
        {(clinic as ClinicOverview).clinic_location_patient_count === '0' ? (
          <span style={{ color: Colours.OV_RED }}>
            {(clinic as ClinicOverview).clinic_location_patient_count}
          </span>
        ) : (
          <>{(clinic as ClinicOverview).clinic_location_patient_count}</>
        )}
      </OvListTableField>

      {clinicAuditLogActivityReadAllowed && (
        <OvListTableField>
          {(clinic as ClinicOverview).request_count === '0' ? (
            <span style={{ color: Colours.OV_RED }}>
              {(clinic as ClinicOverview).request_count}
            </span>
          ) : (
            <>{(clinic as ClinicOverview).request_count}</>
          )}
        </OvListTableField>
      )}

      <OvListTableField>
        {clinic?.created_at
          ? DateUtils.formatDate(
              new Date(clinic.created_at),
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-'}
      </OvListTableField>

      <OvListTableField style={{ textAlign: 'right' }}>
        <StyledOvButton>
          <NavLink to={`${url}/${clinic.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledOvButton>
      </OvListTableField>
    </>
  );
};

export default OvClinicListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;
