import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import OvTable from '../molecules/OvTable';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButton } from '@mui/material';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { Scan } from '../../../common/model/dto/scan';
import { ScanDataUtils } from '../../../common/utils/services/scan-data-utils';
import { Resource } from '../../../common/model/type/resource.enum';
import useAuthorized from '../../../hooks/use-authorized';

const OvScanResults: FC<{
  scan: Scan;
  onSaveEditedScan?: (value: any) => void;
}> = ({ scan, onSaveEditedScan }) => {
  const { t } = useTranslation();
  const isLoading: boolean = useAppSelector((state) => state.scans.isLoading);

  const applicationOperations = useAuthorized(Resource.Application);
  const lotOperations = useAuthorized(Resource.Lot);
  const scanOperations = useAuthorized(Resource.Scan);

  const lotSuperviseAllowed =
    lotOperations.supervise || applicationOperations.supervise;
  const scanUpdateAllowed =
    scanOperations.update || applicationOperations.supervise;

  const [selectedScanImage, setSelectedScanImage] = useState<string>();
  const OUTPUT_IMG_WIDTH = '14rem';
  const INPUT_IMG_WIDTH = '26rem';

  useEffect(() => {
    if (scan?.output_image_url) {
      setSelectedScanImage(scan.output_image_url);
    }
  }, [scan]);

  const handleScanImageChange = (newValue: any) => {
    if (!!newValue) {
      setSelectedScanImage(newValue);
    }
  };

  const calculateMarginLeft = () => {
    if (!selectedScanImage) {
      return 0;
    }

    return selectedScanImage === scan?.output_image_url
      ? OUTPUT_IMG_WIDTH
      : INPUT_IMG_WIDTH;
  };

  const calculateTableWidth = () => {
    if (!selectedScanImage) {
      return '100%';
    }

    return selectedScanImage === scan?.output_image_url
      ? 'calc(100% - 14rem)'
      : 'calc(100% - 26rem)';
  };

  return (
    <>
      {!isLoading ? (
        <Container>
          {scan?.input_image_url && scan?.output_image_url && (
            <div>
              <RotationWrapperOuter>
                <RotationWrapperInner>
                  <Image
                    src={selectedScanImage}
                    alt={t('common.dailyLogs.image')}
                  />
                </RotationWrapperInner>

                <StyledToggleButtonGroup value={selectedScanImage}>
                  <StyledToggleButton
                    key={scan?.output_image_url}
                    value={scan?.output_image_url}
                    onClick={() =>
                      handleScanImageChange(scan?.output_image_url)
                    }
                    aria-label={t('common.scanDetails.results.scanResultImage')}
                  >
                    {t('common.scanDetails.results.scanResultImage')}
                  </StyledToggleButton>
                  <StyledToggleButton
                    key={scan?.input_image_url}
                    value={scan?.input_image_url}
                    onClick={() => handleScanImageChange(scan?.input_image_url)}
                    aria-label={t(
                      'common.scanDetails.results.originalScanImage'
                    )}
                  >
                    {t('common.scanDetails.results.originalScanImage')}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </RotationWrapperOuter>
            </div>
          )}

          <TableWrapper
            style={{
              width: calculateTableWidth(),
              marginLeft: calculateMarginLeft(),
            }}
          >
            {scan && (
              <OvTable
                data={ScanDataUtils.mapDataForScanResultTable(scan, {
                  enableLotLink: !!lotSuperviseAllowed,
                })}
                onSaveRowValue={onSaveEditedScan}
                editable={scanUpdateAllowed}
              />
            )}
          </TableWrapper>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

export default OvScanResults;

const Container = styled.section`
  padding: 0.75rem;
  display: flex;
  flex-wrap: wrap;
`;

const RotationWrapperOuter = styled.div`
  display: table;
  position: absolute;
`;

const RotationWrapperInner = styled.div`
  padding: 50% 0;
  height: 0;
`;

const Image = styled.img`
  width: 37rem;
  display: block;
  transform-origin: top left;
  transform: rotate(90deg) translate(0, -100%);
  margin-top: -50%;
  white-space: nowrap;
`;

const TableWrapper = styled.div``;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.75rem;
  justify-content: center;
  width: 10.625rem;
`;

const StyledToggleButton = styled(ToggleButton)`
  && {
    &.MuiToggleButton-root {
      padding: 0.25rem 0.875rem;
      margin-right: 0.5rem;
      border: 1px solid ${Colours.OV_PRUSSIAN_BLUE} !important;
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE}!important;
      color: ${Colours.OV_BASE};
      text-transform: none;
      margin-left: 0 !important;
      font-size: 0.875rem;
      background-color: ${Colours.OV_SEMI_LIGHT};
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }
    }

    &.MuiToggleButton-root:hover {
      background-color: #f4f4f2;
      border-color: ${Colours.OV_BASE};
    }

    &.Mui-selected {
      background: ${Colours.OV_BASE};
      color: ${Colours.OV_SEMI_LIGHT};
    }

    &.Mui-selected:hover {
      background: ${Colours.OV_BASE};
      color: ${Colours.OV_SEMI_LIGHT};
    }
  }
`;
