import { UserState } from '../states/user.state';
import { RootState } from '../store';
import { FilterType } from '../../common/model/ui/filter.type';
import { UserInfo } from '../../common/model/dto/user-info';

const DEFAULT_SORTING_VALUE = 'id';

const hasAppliedFiltersSelector = (state: RootState): boolean => {
  const userState = state.user as UserState;

  return userState.filters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

const hasAppliedSortingSelector = (state: RootState): boolean =>
  (state.user as UserState)?.sortingOptions.some(
    (sorting) => sorting.direction && sorting.value !== DEFAULT_SORTING_VALUE
  );

const shouldFilterUserList = (state: RootState): boolean =>
  hasAppliedSortingSelector(state) || hasAppliedFiltersSelector(state);

const findUserById =
  (id: string) =>
  (state: RootState): UserInfo | undefined =>
    (state.user as UserState)?.userList?.find((user) => user.id === id);

export {
  hasAppliedFiltersSelector,
  hasAppliedSortingSelector,
  shouldFilterUserList,
  findUserById,
};
