import React, { FC, useState } from 'react';
import OvConfirmationDialog from './OvConfirmationDialog';
import EmailIcon from '@mui/icons-material/Email';
import AuthService from '../../../services/auth.service';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvSnackbar from './OvSnackbar';
import { AlertColor } from '@mui/material';

const OvResetPasswordDialog: FC<{
  isResetPasswordDialogOpen: boolean;
  onCancel: () => void;
  email?: string;
}> = ({ isResetPasswordDialogOpen, onCancel, email }) => {
  const { t } = useTranslation();
  const [messageSendingLoading, setMessageSendingLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');

  const handleSendPasswordResetEmail = async () => {
    onCancel();
    if (email) {
      try {
        setMessageSendingLoading(true);
        await AuthService.sendPasswordResetEmail(email);
        setMessageSendingLoading(false);

        setSnackbarProps(
          'success',
          t('userDetails.actions.emailSentSuccessfully'),
          true
        );
      } catch (error: any) {
        setMessageSendingLoading(false);

        setSnackbarProps(
          'error',
          t('userDetails.actions.emailSendingFailed'),
          true
        );
      }
    }
  };

  const setSnackbarProps = (
    severity: AlertColor,
    message: string,
    isOpen: boolean
  ) => {
    setSnackbarSeverity(severity);
    setSnackBarMessage(message);
    setIsSnackbarOpen(isOpen);
  };

  return (
    <>
      <OvConfirmationDialog
        icon={<StyledEmailIcon />}
        isOpen={isResetPasswordDialogOpen}
        onCancel={onCancel}
        onConfirm={handleSendPasswordResetEmail}
        title="userDetails.actions.resetPasswordTitle"
        description="userDetails.actions.resetPasswordDescription"
        descriptionVariable={{ email }}
      />

      <OvSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        severity={snackbarSeverity}
        message={snackBarMessage}
      />

      {messageSendingLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default OvResetPasswordDialog;

const StyledEmailIcon = styled(EmailIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
