import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';

export interface OvProviderClinicLocationStatusProps {
  description: string;
  statusIcon: ReactElement;
  label?: string;
}

const OvProviderClinicLocationStatus: FC<
  OvProviderClinicLocationStatusProps
> = ({ description, statusIcon, label }) => {
  return (
    <StyledStatusInfoWrapper>
      <StyledStatusIconWrapper>{statusIcon}</StyledStatusIconWrapper>
      <StyledStatusDescription>
        {description} <br />
        {label && <StyledStatusLabel>( {label} )</StyledStatusLabel>}
      </StyledStatusDescription>
    </StyledStatusInfoWrapper>
  );
};

export default OvProviderClinicLocationStatus;

const StyledStatusInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledStatusIconWrapper = styled.div``;

const StyledStatusDescription = styled.p`
  margin: 0;
  color: ${Colours.OV_BASE};
`;

const StyledStatusLabel = styled.label`
  margin: 0;
  font-size: 0.7rem;
  color: ${Colours.OV_BASE};
`;
