import { fieldToSelect, SelectProps } from 'formik-mui';
import React, { FC } from 'react';
import OvSelect from './OvSelect';

const OvSelectFormControl: FC<SelectProps> = ({ children, ...props }) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event: any) => {
      const { value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );

  return (
    <OvSelect {...fieldToSelect(props)} onChange={onChange}>
      {children}
    </OvSelect>
  );
};

export default OvSelectFormControl;
