import React, { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from '@mui/material';
import Colours from '../../../design-system/colours';
import OvCustomChartTooltip from '../molecules/OvCustomChartTooltip';
import ChartUtils from '../../../common/utils/services/chart-utils';
import ChartData from '../../../common/model/type/chart-data.type';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HormoneValueType } from '../../../common/model/type/hormone-values.type';

export interface ChartDataProps {
  type: HormoneValueType;
  unit: string;
  values: ChartData[];
}

export default function OvHormoneLineChart({
  data,
  isBBTOn,
  isCustomDateRange,
  onSelectDay,
  chartMaxValue,
  showAllScans,
}: {
  data: ChartDataProps;
  isBBTOn: boolean;
  isCustomDateRange: boolean;
  onSelectDay?: (selectedDay: ChartData) => void;
  chartMaxValue: number;
  showAllScans: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const chartData = data.values;
  const [mouseIn, setMouseIn] = useState(false);
  const labelId = 'overview';

  const chartStyleProps = useMemo(() => {
    const dailyData = chartData.map((data) => data.dailyData);

    const { periodStartDayIndexes, periodEndingDayIndexes } =
      ChartUtils.getPeriodDays(dailyData);

    const firstMeasureIndex = chartData
      .map(({ measured }) => measured)
      .indexOf(true);

    const lastMeasureIndex = chartData
      .map(({ measured }) => measured)
      .lastIndexOf(true);

    return {
      firstMeasureIndex,
      lastMeasureIndex,
      xAxisTicks: chartData.map(({ index }) => index),
      lineColorBreakPoints: ChartUtils.getLinecolorBreakpoints(
        firstMeasureIndex,
        lastMeasureIndex,
        chartData
      ),
      periodStartDayIndexes,
      periodEndingDayIndexes,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.values, isCustomDateRange]);

  const bbtHasOver100 =
    isBBTOn !== undefined &&
    chartData.reduce(
      (prev, current) =>
        prev || current?.dailyData?.journal_entry?.temperature! > 100,
      false
    );

  const onMouseMove = () => {
    mouseIn === false && setMouseIn(true);
  };

  const onMouseLeave = () => {
    setMouseIn(false);
  };

  const handleDaySelect = (chartData: ChartData) => onSelectDay?.(chartData);

  return (
    <>
      <LabelContainer>
        {isBBTOn && (
          <StyledLegendContainer>
            <StyledLegendCircle
              style={{ backgroundColor: Colours.CD_BBT_COLOR }}
            />
            <HeaderTitleWrapper>
              <StyledHeaderTitle>{t('dashboard.chart.bbt')}</StyledHeaderTitle>
            </HeaderTitleWrapper>
          </StyledLegendContainer>
        )}
        <StyledLegendContainer>
          <StyledLegendCircle />
          <HeaderTitleWrapper>
            <StyledHeaderTitle>{t('dashboard.chart.period')}</StyledHeaderTitle>
          </HeaderTitleWrapper>
        </StyledLegendContainer>
      </LabelContainer>
      <ResponsiveContainer height={270}>
        <LineChart
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          margin={{ top: 22, right: !!isBBTOn ? 60 : 35 }}
          syncId="anyId"
        >
          <XAxis
            tickLine={false}
            tickSize={16}
            ticks={chartStyleProps.xAxisTicks}
            tick={{ fontSize: 13 }}
            tickMargin={40}
            dataKey={'index'}
            allowDuplicatedCategory={false}
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            tickLine={false}
            tickSize={4}
            label={{
              value: `${t('dashboard.chart.concentration')} ${data.unit}`,
              angle: -90,
              position: 'center',
              offset: 5,
              fill: Colours.OV_BASE,
            }}
            domain={[0, chartMaxValue]}
            width={100}
          />
          {isBBTOn && (
            <YAxis
              yAxisId="temperatureAxis"
              orientation="right"
              tickLine={false}
              tickSize={12}
              tickFormatter={(tick) => {
                return (bbtHasOver100 && tick === 105) ||
                  (!bbtHasOver100 && tick === 100)
                  ? tick + '°F'
                  : tick;
              }}
              ticks={bbtHasOver100 ? [95, 100, 105] : [96, 98, 100]}
              domain={bbtHasOver100 ? [95, 105] : [95, 100]}
            />
          )}
          {chartStyleProps.periodEndingDayIndexes.length > 0 &&
            chartStyleProps.periodStartDayIndexes.map(
              (startingDayIndex, index) => (
                <ReferenceArea
                  key={startingDayIndex}
                  x1={startingDayIndex - 0.5 < 0 ? 0 : startingDayIndex - 0.5}
                  x2={
                    chartStyleProps.periodEndingDayIndexes[index] + 0.5 >
                    Math.max(...chartStyleProps.xAxisTicks)
                      ? chartStyleProps.periodEndingDayIndexes[index]
                      : chartStyleProps.periodEndingDayIndexes[index] + 0.5 ??
                        startingDayIndex + 5
                  }
                  fill="#ff8edd"
                  fillOpacity="0.35"
                />
              )
            )}
          <ReferenceLine x={chartData[chartData.length - 1].index} />
          <CartesianGrid vertical={false} />
          <Tooltip
            wrapperStyle={{
              visibility: 'visible',
            }}
            cursor={{ strokeOpacity: 0.6 }}
            coordinate={{ x: 100, y: 100 }}
            content={(props: any): any => (
              <OvCustomChartTooltip
                active={props.active}
                payload={props.payload}
                chartData={data}
                chartStyleProps={chartStyleProps}
              />
            )}
          />
          <defs>
            <linearGradient
              id={`lineColor-${labelId}-${data.type}`}
              x1="0%"
              y1="0"
              x2="100%"
              y2="0"
            >
              <stop offset="0%" stopColor={'transparent'} />
              {chartStyleProps.lineColorBreakPoints.map(
                ({ percentage, isMeasured }) => (
                  <React.Fragment key={percentage + ', ' + isMeasured}>
                    <stop
                      offset={percentage}
                      stopColor={isMeasured ? 'transparent' : Colours.OV_BASE}
                    />
                    <stop
                      offset={percentage}
                      stopColor={isMeasured ? Colours.OV_BASE : 'transparent'}
                    />
                  </React.Fragment>
                )
              )}
              <stop offset={1} stopColor={'transparent'} />
            </linearGradient>
          </defs>
          {isBBTOn && (
            <Line
              data={chartData}
              isAnimationActive={false}
              yAxisId="temperatureAxis"
              type="monotoneX"
              strokeWidth="1.0"
              stroke={Colours.CD_BBT_COLOR}
              dataKey={(data) => data.dailyData?.journal_entry?.temperature}
              dot={(props): any => {
                const { cx, cy, payload } = props;

                return (
                  payload?.dailyData?.journal_entry?.temperature && (
                    <circle
                      key={`temperature-line-dot-${payload.index}`}
                      cx={cx}
                      cy={cy}
                      r={2.5}
                      stroke={Colours.CD_BBT_COLOR}
                      strokeWidth={1.5}
                      fill={Colours.CD_BBT_COLOR}
                    />
                  )
                );
              }}
              activeDot={false}
            />
          )}
          <Line
            type="monotoneX"
            isAnimationActive={false}
            data={chartData}
            dataKey="value"
            strokeWidth="2.5"
            stroke={`url(#lineColor-${labelId}-${data.type})`}
            dot={(props): any => {
              const { cx, cy, payload } = props;
              const isInRange =
                payload.index >= chartStyleProps.firstMeasureIndex &&
                payload.index <= chartStyleProps.lastMeasureIndex;

              if (!isInRange) return undefined;

              return (
                <circle
                  key={`lineDot.${payload.index}-${payload.dateStr}`}
                  cx={cx}
                  cy={cy}
                  r={3.5}
                  stroke={Colours.OV_BASE}
                  strokeWidth={1.5}
                  fill={
                    payload.measured
                      ? Colours.OV_BASE
                      : theme.palette.common.white
                  }
                />
              );
            }}
            activeDot={(props: any): any => {
              const { cx, cy, payload } = props;
              if (
                payload.index < chartStyleProps.firstMeasureIndex ||
                payload.index > chartStyleProps.lastMeasureIndex ||
                (isCustomDateRange && !payload.measured)
              ) {
                return (
                  <g onClick={() => handleDaySelect(payload)}>
                    <circle
                      cx={cx}
                      cy={cy}
                      r={10}
                      fill={Colours.OV_LIGHT_GRAY}
                      fillOpacity="0.7"
                    />
                    <circle
                      cx={cx}
                      cy={cy}
                      r={3.5}
                      onClick={() => handleDaySelect(payload)}
                      fill={Colours.OV_ESTIMATED_LINE_COLOR}
                    />
                  </g>
                );
              }

              return (
                <g onClick={() => handleDaySelect(payload)}>
                  <circle
                    cx={cx}
                    cy={cy}
                    r={15}
                    fill={Colours.OV_LIGHT_GRAY}
                    fillOpacity="0.7"
                  />
                  <circle
                    cx={cx}
                    cy={cy}
                    r={3.5}
                    stroke={Colours.OV_BASE}
                    strokeWidth={1.5}
                    fill={
                      payload.measured
                        ? Colours.OV_BASE
                        : theme.palette.common.white
                    }
                  />
                </g>
              );
            }}
          />
          {chartData.map((singleChartData, i) => (
            <Line
              type="linear"
              dataKey="value"
              strokeOpacity={0}
              hide={!showAllScans}
              data={singleChartData.scansChartData}
              name={`scan-line-${data.type}-${singleChartData.index}-${i}`}
              key={`scan-line-${data.type}-${singleChartData.index}-${i}`}
              dot={(props): any => {
                const { cx, cy, payload } = props;

                return (
                  <circle
                    key={`scan-lineDot.${payload.index}-${payload.dateStr}-${cy}`}
                    cx={cx}
                    cy={cy}
                    r={3.5}
                    fill={Colours.OV_BASE}
                    opacity={0.75}
                  />
                );
              }}
              activeDot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

const StyledLegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLegendCircle = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #ff8edd;
`;

const LabelContainer = styled.div`
  height: 0.313rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

const StyledHeaderTitle = styled.h5`
  color: ${Colours.OV_BASE};
`;

const HeaderTitleWrapper = styled.div`
  margin-left: 0.313rem;
`;
