import i18n from '../../../i18n/config';
import { UserInfo } from '../../model/dto/user-info';

export default class StringUtils {
  static displayValue(value: string = ''): string {
    return `${value}`?.length && value !== null ? value : '-';
  }

  static displayInPercentage(value: number, maxValue: number): string {
    return ((value / maxValue) * 100).toFixed(2);
  }

  static displayWithPostfix(value: any, postfix: string): string {
    return `${value}${postfix}`;
  }

  static truncateString(value: string, maxLength: number): string {
    return value.length > maxLength
      ? value.substring(0, maxLength) + '...'
      : value;
  }

  static convertStringToNumberArray(text: string = ''): number[] {
    return text
      ?.split(',')
      .map((val) => parseFloat(val.trim()))
      .filter((val) => !isNaN(val));
  }

  static getPrettifiedBooleanValue(value?: boolean) {
    if (value === null || value === undefined) {
      return '-';
    }

    return value ? i18n.t('common.actions.yes') : i18n.t('common.actions.no');
  }

  static getPrettyUserName(
    user?: UserInfo,
    options?: { readSafe: boolean }
  ): string {
    if (!user) {
      return '';
    }
    let prettyUserName = '';

    if (user) {
      if (user.first_name) {
        prettyUserName += user.first_name;
      }

      if (options?.readSafe && prettyUserName) {
        prettyUserName = `${prettyUserName.substring(0, 1)}.`;
      }

      if (user.last_name) {
        prettyUserName += prettyUserName
          ? ` ${user.last_name}`
          : user.last_name;
      }
    }

    return prettyUserName;
  }
}
