import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';
import OvTextField from '../atoms/OvTextField';
import OvSelect from '../atoms/OvSelect';
import { MenuItem } from '@mui/material';
import { FitType } from '../../../common/types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OvErrorMessage from '../atoms/OvErrorMessage';
import { CalibrationParameters } from '../../../common/model/dto/calibaration-paramteres';
import { HormoneModelFitParameters } from '../../../common/model/dto/hormone-model-fit-parameters';
import Colours from '../../../design-system/colours';
import OvRadioGroup from '../molecules/OvRadioGroup';
import { Lot } from '../../../common/model/dto/lot';
import StringUtils from '../../../common/utils/services/string-utils';
import { isNumber } from 'lodash';
import OvHormoneCalibrationValidation from '../molecules/OvHormoneCalibrationValidation';
import useAuthorized from '../../../hooks/use-authorized';
import { Resource } from '../../../common/model/type/resource.enum';

export interface CalibrationFormValue {
  fit_type?: FitType;
  concentrations?: string;
  signals?: string;
  should_fix_min_at_zero?: boolean;
}

export interface HormoneModelFitFormValue {
  x?: string;
  raw_x?: string;
  efficiency?: number;
  minConcentrationUsedForModelFit?: number;
  maxConcentrationUsedForModelFit?: number;
  minSignalUsedForModelFit?: number;
  maxSignalUsedForModelFit?: number;
  doesConcentrationIncreaseAsSignalIncreases?: boolean;
  minMeasurableConcentration?: number;
  maxMeasurableConcentration?: number;
}

export interface OvHormoneCalibrationProps {
  runModel: (calibrationValues: CalibrationFormValue) => void;
  saveChanges?: (lot: Lot) => void;
  errorMessage: string;
  initialCalibrationValue?: CalibrationParameters;
  initialHormoneModelFitParameters?: HormoneModelFitParameters;
  type?: 'lr_e3g' | 'lh' | 'pg' | 'testosterone';
  unit?: string;
  ax_array?: number[];
  should_fix_min_at_zero?: boolean;
}

const OvHormoneCalibration: FC<OvHormoneCalibrationProps> = ({
  runModel,
  saveChanges,
  errorMessage,
  initialCalibrationValue,
  initialHormoneModelFitParameters,
  type,
  unit,
  ax_array,
}) => {
  const { t } = useTranslation();

  const applicationOperations = useAuthorized(Resource.Application);
  const lotOperations = useAuthorized(Resource.Lot);

  const calibrationForm = useFormik<CalibrationFormValue>({
    initialValues: {
      fit_type: initialCalibrationValue?.fit_type,
      concentrations: initialCalibrationValue?.concentrations?.join(','),
      signals: initialCalibrationValue?.signals?.join(','),
      should_fix_min_at_zero: initialCalibrationValue?.should_fix_min_at_zero,
    },
    onSubmit: (value: CalibrationFormValue) => {
      runModel(value);
    },
    validationSchema: Yup.object().shape({
      fitType: Yup.string(),
      concentrations: Yup.string(),
      signals: Yup.string(),
      should_fix_min_at_zero: Yup.boolean(),
    }),
    validateOnBlur: true,
  });

  const modelFitParamsForm = useFormik<HormoneModelFitFormValue>({
    initialValues: {},
    onSubmit: (values: HormoneModelFitFormValue) => {
      if (saveChanges) {
        let lot: Lot = {};

        const concentrations = StringUtils.convertStringToNumberArray(
          calibrationForm.values?.concentrations
        );
        const signals = StringUtils.convertStringToNumberArray(
          calibrationForm.values?.signals
        );

        const raw_x = StringUtils.convertStringToNumberArray(
          modelFitParamsForm.values?.raw_x
        );

        const x = StringUtils.convertStringToNumberArray(
          modelFitParamsForm.values?.x
        );

        if (type === 'lh') {
          lot = {
            lh_calibration: {
              concentrations,
              signals,
              fit_type: calibrationForm.values.fit_type,
              should_fix_min_at_zero:
                calibrationForm.values.should_fix_min_at_zero,
            },
            lh_model_fit_parameters: {
              ...values,
              x,
              raw_x,
            },
          };
        } else if (type === 'pg') {
          lot = {
            pg_calibration: {
              concentrations,
              signals,
              fit_type: calibrationForm.values.fit_type,
              should_fix_min_at_zero:
                calibrationForm.values.should_fix_min_at_zero,
            },
            pg_model_fit_parameters: {
              ...values,
              x,
              raw_x,
            },
          };
        } else if (type === 'lr_e3g') {
          lot = {
            lr_e3g_calibration: {
              concentrations,
              signals,
              fit_type: calibrationForm.values.fit_type,
              should_fix_min_at_zero:
                calibrationForm.values.should_fix_min_at_zero,
            },
            lr_e3g_model_fit_parameters: {
              ...values,
              x,
              raw_x,
            },
          };
        }

        saveChanges(lot);
      }
    },
    validationSchema: Yup.object().shape({
      x: Yup.string(),
      raw_x: Yup.string(),
      efficiency: Yup.number(),
      minConcentrationUsedForModelFit: Yup.number(),
      maxConcentrationUsedForModelFit: Yup.number(),
      minSignalUsedForModelFit: Yup.number(),
      maxSignalUsedForModelFit: Yup.number(),
      doesConcentrationIncreaseAsSignalIncreases: Yup.boolean(),
      minMeasurableConcentration: Yup.number(),
      maxMeasurableConcentration: Yup.number(),
    }),
    validateOnBlur: true,
  });

  useEffect(() => {
    modelFitParamsForm.setValues({
      x: initialHormoneModelFitParameters?.x?.join(','),
      raw_x: initialHormoneModelFitParameters?.raw_x?.join(','),
      efficiency: initialHormoneModelFitParameters?.efficiency,
      minConcentrationUsedForModelFit:
        initialHormoneModelFitParameters?.minConcentrationUsedForModelFit,
      maxConcentrationUsedForModelFit:
        initialHormoneModelFitParameters?.maxConcentrationUsedForModelFit,
      minSignalUsedForModelFit:
        initialHormoneModelFitParameters?.minSignalUsedForModelFit,
      maxSignalUsedForModelFit:
        initialHormoneModelFitParameters?.maxSignalUsedForModelFit,
      doesConcentrationIncreaseAsSignalIncreases:
        initialHormoneModelFitParameters?.doesConcentrationIncreaseAsSignalIncreases,
      minMeasurableConcentration: isNumber(
        modelFitParamsForm?.values.minMeasurableConcentration
      )
        ? modelFitParamsForm?.values.minMeasurableConcentration
        : initialHormoneModelFitParameters?.minMeasurableConcentration,
      maxMeasurableConcentration: isNumber(
        modelFitParamsForm?.values.maxMeasurableConcentration
      )
        ? modelFitParamsForm?.values.maxMeasurableConcentration
        : initialHormoneModelFitParameters?.maxMeasurableConcentration,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialHormoneModelFitParameters]);

  const isEdited = (): boolean => {
    return (
      initialCalibrationValue?.signals?.join(',') !==
        calibrationForm?.values?.signals ||
      initialCalibrationValue?.concentrations?.join(',') !==
        calibrationForm?.values?.concentrations ||
      initialCalibrationValue?.fit_type !== calibrationForm?.values?.fit_type ||
      initialCalibrationValue?.should_fix_min_at_zero !==
        calibrationForm?.values?.should_fix_min_at_zero ||
      initialHormoneModelFitParameters?.x?.join(',') !==
        modelFitParamsForm.values?.x ||
      initialHormoneModelFitParameters?.raw_x?.join(',') !==
        modelFitParamsForm.values?.raw_x ||
      initialHormoneModelFitParameters?.efficiency !==
        modelFitParamsForm.values?.efficiency ||
      initialHormoneModelFitParameters?.minConcentrationUsedForModelFit !==
        modelFitParamsForm.values?.minConcentrationUsedForModelFit ||
      initialHormoneModelFitParameters?.maxConcentrationUsedForModelFit !==
        modelFitParamsForm.values?.maxConcentrationUsedForModelFit ||
      initialHormoneModelFitParameters?.minSignalUsedForModelFit !==
        modelFitParamsForm.values?.minSignalUsedForModelFit ||
      initialHormoneModelFitParameters?.maxSignalUsedForModelFit !==
        modelFitParamsForm.values?.maxSignalUsedForModelFit ||
      initialHormoneModelFitParameters?.doesConcentrationIncreaseAsSignalIncreases !==
        modelFitParamsForm.values?.doesConcentrationIncreaseAsSignalIncreases ||
      initialHormoneModelFitParameters?.minMeasurableConcentration !==
        modelFitParamsForm.values?.minMeasurableConcentration ||
      initialHormoneModelFitParameters?.maxMeasurableConcentration !==
        modelFitParamsForm.values?.maxMeasurableConcentration
    );
  };

  const lotUpdateAllowed =
    lotOperations.update || applicationOperations.supervise;

  return (
    <Wrapper>
      <StyledForm onSubmit={calibrationForm.handleSubmit}>
        <FormControl>
          <FormControlLabel htmlFor="fitType">
            {t('lots.calibration.fields.fitType')}
          </FormControlLabel>

          <StyledOvSelect
            id="fitType"
            name="fit_type"
            value={calibrationForm.values.fit_type}
            onChange={calibrationForm.handleChange}
            disabled={!lotUpdateAllowed}
          >
            <StyledMenuItem value="4pl">4pl</StyledMenuItem>
            <StyledMenuItem value="linear">linear</StyledMenuItem>
          </StyledOvSelect>
        </FormControl>

        <FormControl>
          <FormControlLabel htmlFor="concentrations">
            {t('lots.calibration.fields.concentrations')}
          </FormControlLabel>
          <StyledInput
            id="concentrations"
            name="concentrations"
            value={calibrationForm.values.concentrations}
            onChange={calibrationForm.handleChange}
            placeholder={t('lots.calibration.fields.concentrationsExample')}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>

        <FormControl>
          <FormControlLabel htmlFor="signals">
            {t('lots.calibration.fields.signals')}
          </FormControlLabel>
          <StyledInput
            id="signals"
            name="signals"
            value={calibrationForm.values.signals}
            onChange={calibrationForm.handleChange}
            placeholder={t('lots.calibration.fields.signalsExample')}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>

        <FormControl>
          <FormControlLabel htmlFor="shouldFixMinAtZero">
            {t('lots.fields.shouldFixMinAtZero')}
          </FormControlLabel>
          <StyledOvRadioGroup
            id="shouldFixMinAtZero"
            options={[
              {
                label: t('common.actions.yes'),
                value: true,
              },
              {
                label: t('common.actions.no'),
                value: false,
              },
            ]}
            name="shouldFixMinAtZero"
            value={calibrationForm.values.should_fix_min_at_zero}
            onChange={(event, value) => {
              calibrationForm.setFieldValue(
                'should_fix_min_at_zero',
                value === 'true'
              );
            }}
            disabled={!lotUpdateAllowed}
          />
        </FormControl>

        <StyledOvButton disabled={!lotUpdateAllowed} type="submit">
          {t('lots.calibration.cta')}
        </StyledOvButton>

        {errorMessage && (
          <StyledErrorMessage>
            <OvErrorMessage message={errorMessage} />
          </StyledErrorMessage>
        )}
      </StyledForm>

      <StyledForm onSubmit={modelFitParamsForm.handleSubmit}>
        <FormControl>
          <FormControlLabel htmlFor="x">{'x'}</FormControlLabel>
          <StyledInput
            id="x"
            name="x"
            value={modelFitParamsForm.values.x}
            onChange={modelFitParamsForm.handleChange}
            placeholder={t('0,0,0')}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="raw_x">
            {t('lots.fields.rawX')}
          </FormControlLabel>
          <StyledInput
            id="raw_x"
            name="raw_x"
            value={modelFitParamsForm.values.raw_x}
            onChange={modelFitParamsForm.handleChange}
            placeholder={t('0,0,0')}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="efficiency">
            {t('lots.fields.efficiency')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="efficiency"
            name="efficiency"
            value={modelFitParamsForm.values.efficiency}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="minConcentrationUsedForModelFit">
            {t('lots.fields.minConcentrationForMF')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="minConcentrationUsedForModelFit"
            name="minConcentrationUsedForModelFit"
            value={modelFitParamsForm.values.minConcentrationUsedForModelFit}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="maxConcentrationUsedForModelFit">
            {t('lots.fields.maxConcentrationForMF')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="maxConcentrationUsedForModelFit"
            name="maxConcentrationUsedForModelFit"
            value={modelFitParamsForm.values.maxConcentrationUsedForModelFit}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="minSignalUsedForModelFit">
            {t('lots.fields.minSignalForMF')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="minSignalUsedForModelFit"
            name="minSignalUsedForModelFit"
            value={modelFitParamsForm.values.minSignalUsedForModelFit}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="maxSignalUsedForModelFit">
            {t('lots.fields.maxSignalForMF')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="maxSignalUsedForModelFit"
            name="maxSignalUsedForModelFit"
            value={modelFitParamsForm.values.maxSignalUsedForModelFit}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>

        <FormControl>
          <FormControlLabel htmlFor="doesConcentrationIncreaseAsSignalIncreases">
            {t('lots.fields.doesConcentrationIncrease')}
          </FormControlLabel>
          <StyledOvRadioGroup
            id="doesConcentrationIncreaseAsSignalIncreases"
            options={[
              {
                label: t('common.actions.yes'),
                value: true,
              },
              {
                label: t('common.actions.no'),
                value: false,
              },
            ]}
            name="doesConcentrationIncreaseAsSignalIncreases"
            value={
              '' +
              modelFitParamsForm.values
                .doesConcentrationIncreaseAsSignalIncreases
            }
            onChange={(event, value) => {
              modelFitParamsForm.setFieldValue(
                'doesConcentrationIncreaseAsSignalIncreases',
                value === 'true'
              );
            }}
            disabled={!lotUpdateAllowed}
          />
        </FormControl>

        <FormControl>
          <FormControlLabel htmlFor="minMeasurableConcentration">
            {t('lots.calibration.fields.minMeasurableConcentration')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="minMeasurableConcentration"
            name="minMeasurableConcentration"
            value={modelFitParamsForm.values.minMeasurableConcentration}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <FormControlLabel htmlFor="maxMeasurableConcentration">
            {t('lots.calibration.fields.maxMeasurableConcentration')}
          </FormControlLabel>
          <StyledInput
            type="number"
            placeholder="-"
            InputProps={{
              inputProps: {
                step: 0.01,
              },
            }}
            id="maxMeasurableConcentration"
            name="maxMeasurableConcentration"
            value={modelFitParamsForm.values.maxMeasurableConcentration}
            onChange={modelFitParamsForm.handleChange}
            disabled={!lotUpdateAllowed}
          ></StyledInput>
        </FormControl>

        <StyledOvButton
          disabled={!isEdited() || !lotUpdateAllowed}
          type="submit"
        >
          {t('common.actions.save')}
        </StyledOvButton>
      </StyledForm>

      {modelFitParamsForm.values.x && ax_array?.length && (
        <OvHormoneCalibrationValidation
          signals={calibrationForm.values.signals}
          concentrations={calibrationForm.values.concentrations}
          fit_type={calibrationForm.values.fit_type}
          x_values={modelFitParamsForm.values.x}
          unit={unit}
          maxConcentrationUsedForModelFit={
            modelFitParamsForm.values.maxConcentrationUsedForModelFit
          }
          maxSignalUsedForModelFit={
            modelFitParamsForm.values.maxSignalUsedForModelFit
          }
          ax_array={ax_array}
        ></OvHormoneCalibrationValidation>
      )}
    </Wrapper>
  );
};

export default OvHormoneCalibration;

const Wrapper = styled.div`
  border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
  padding: 1rem;
  border: 1px dotted ${Colours.GRAY};
`;

const StyledForm = styled.form`
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormControl = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormControlLabel = styled.label`
  font-size: ${Variables.fontSizes.MEDIUM};
  flex-grow: 1;
  flex-basis: 50%;
`;

const StyledInput = styled(OvTextField)`
  && {
    flex-grow: 1;
    flex-basis: 50%;

    .MuiInputBase-input {
      padding: 0.5rem !important;
    }
  }
`;

const StyledOvRadioGroup = styled(OvRadioGroup)`
  && {
    .MuiFormControlLabel-root {
      margin-right: 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }

    .MuiRadio-root {
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0.25rem 1rem;
    text-transform: none;
    border-radius: 0.75rem;
  }
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledErrorMessage = styled.div`
  margin: 1.5rem 0;
`;
