export enum OrderStatus {
  Created = 'Created',
  Submitted = 'Submitted',
  Processing = 'Processing',
  Shipped = 'Shipped',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Hold = 'Hold',
  Archived = 'Archived',
  Failed = 'Failed',
  WaitingForManualUpdate = 'WaitingForManualUpdate',
  Unknown = 'Unknown',
  Unfulfilled = 'Unfulfilled',
  Fulfilled = 'Fulfilled',
  Default = 'Default',
  Pending = 'Pending',
}
