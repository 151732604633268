import { Paginated } from '../common/types';
import axios from 'axios';
import { ADMIN_PRODUCTS_ROUTE } from '../api/backend';
import { Product } from '../common/model/dto/product/product';
import { CreateProductRequest } from '../common/model/dto/product/create-product-request';
import { UpdateProductRequest } from '../common/model/dto/product/update-product-request';

export class ProductsService {
  public static readonly PRODUCT_LIST_LIMIT: number = 10;

  static async getProducts(query: string): Promise<Paginated<Product>> {
    const { data } = await axios.get<Paginated<Product>>(
      `${ADMIN_PRODUCTS_ROUTE}?${query}`
    );
    return data;
  }

  static async getProductById(productId: string): Promise<Product> {
    const { data } = await axios.get<Product>(
      `${ADMIN_PRODUCTS_ROUTE}/${productId}`
    );
    return data;
  }

  static async createProduct(product: CreateProductRequest): Promise<Product> {
    const { data } = await axios.post<Product>(
      `${ADMIN_PRODUCTS_ROUTE}`,
      product
    );
    return data;
  }

  static async updateProduct(
    id: string,
    product: UpdateProductRequest
  ): Promise<Product> {
    const { data } = await axios.patch<Product>(
      `${ADMIN_PRODUCTS_ROUTE}/${id}`,
      product
    );
    return data;
  }

  static async archiveProduct(productId: string): Promise<Product> {
    const { data } = await axios.delete(`${ADMIN_PRODUCTS_ROUTE}/${productId}`);

    return data;
  }
}
