import React, { FC } from 'react';
import styled from 'styled-components';
import { OrderItem } from '../../../common/model/dto/order/order-item';
import OvOrderItem from './OvOrderItem';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

const OvOrderItems: FC<{ orderItems: OrderItem[] }> = ({ orderItems }) => {
  return (
    <Container>
      {orderItems.map((orderItem) => (
        <Wrapper key={orderItem.sku}>
          <OvOrderItem item={orderItem} key={orderItem.sku} />
        </Wrapper>
      ))}
    </Container>
  );
};

export default OvOrderItems;

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid ${Colours.OV_WHITE_HOVER};
  border-radius: ${Variables.borderRadius.MEDIUM};
`;
