import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import OvListTableField from '../atoms/OvListTableField';
import StringUtils from '../../../common/utils/services/string-utils';
import { NotificationContent } from '../../../common/model/dto/notification/notification-content';
import DateUtils from '../../../common/utils/services/date-utils';

interface OvNotificationContentListRowProps {
  notificationContent: NotificationContent;
}

const OvNotificationContentListRow: FC<OvNotificationContentListRowProps> = ({
  notificationContent,
}) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  return (
    <>
      <NotificationContentListTableField>
        {StringUtils.displayValue(notificationContent.title)}
      </NotificationContentListTableField>
      <NotificationContentListTableField>
        {StringUtils.displayValue(notificationContent.category)}
      </NotificationContentListTableField>

      <NotificationContentListTableField>
        {StringUtils.getPrettifiedBooleanValue(
          notificationContent.is_published
        )}
      </NotificationContentListTableField>
      <NotificationContentListTableField>
        {StringUtils.displayValue(
          `${notificationContent.number_of_created_notifications}`
        )}
      </NotificationContentListTableField>
      <NotificationContentListTableField>
        {notificationContent?.created_at
          ? DateUtils.formatDate(
              notificationContent?.created_at,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-'}
      </NotificationContentListTableField>

      <NotificationContentListTableField>
        {notificationContent?.updated_at
          ? DateUtils.formatDate(
              notificationContent?.updated_at,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-'}
      </NotificationContentListTableField>

      <NotificationContentListTableField>
        <StyledOvButton>
          <NavLink to={`${url}/${notificationContent.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledOvButton>
      </NotificationContentListTableField>
    </>
  );
};

export default OvNotificationContentListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};
    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }
    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const NotificationContentListTableField = styled(OvListTableField)`
  vertical-align: middle !important;
  padding: 0.75rem;
`;
