import React, { FC } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';

export interface OvQuillEditorProps {
  value: string;
  handleChange?: (value: string) => void;
}

const OvQuillEditor: FC<OvQuillEditorProps & ReactQuillProps> = ({
  value,
  handleChange,
  ...props
}) => {
  return <StyledReactQuill value={value} onChange={handleChange} {...props} />;
};

export default OvQuillEditor;

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar {
    border: 1px solid ${Colours.OV_BASE};
    border-top-left-radius: ${Variables.borderRadius.SMALL};
    border-top-right-radius: ${Variables.borderRadius.SMALL};
    background-color: ${Colours.WHITE};
  }

  .ql-container {
    border: 1px solid ${Colours.OV_BASE};
    border-bottom-left-radius: ${Variables.borderRadius.SMALL};
    border-bottom-right-radius: ${Variables.borderRadius.SMALL};
    background-color: ${Colours.WHITE};
    min-height: 12.5rem;

    .ql-editor {
      font-family: CentraNo2-Book, CentraNo2, CentraNo2-Medium, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: ${Colours.OV_BASE};

      &.ql-blank::before {
        color: ${Colours.OV_BASE};
        opacity: 0.42;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin: 0;
        padding: 0;
      }

      ul {
        list-style-type: disc;
      }

      li {
        list-style-position: outside;
        white-space: pre-line;
      }
    }
  }
`;
