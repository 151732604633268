import React from 'react';
import styled from 'styled-components';
import { ClinicLocation } from '../../../common/model/dto/clinic-location';
import { UserInfo } from '../../../common/model/dto/user-info';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useAppSelector } from '../../../redux/hooks';
import {
  loadOwnDailyData,
  loadOwnDailyDataBetWeenDateRange,
} from '../../../redux/thunks/clinic-location.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvUserCharts from './OvUserCharts';

const OvPatientOwnResults = () => {
  const currentUser: UserInfo | undefined = useAppSelector(
    (state) => state.user.currentUser
  );
  const selectedClinicLocation: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const isLoading = useAppSelector((state) => state.user.isLoading);

  return (
    <>
      {currentUser && selectedClinicLocation && (
        <DetailPageWrapper>
          <Header>
            <LeftInfoWrapper>
              <UserName>
                {`${currentUser?.first_name} ${currentUser?.last_name}`}
                <StyledEmail>{currentUser?.email}</StyledEmail>
              </UserName>
            </LeftInfoWrapper>
          </Header>
          <OvUserCharts
            user={currentUser}
            clinicLocationId={selectedClinicLocation?.id}
            loadDailyData={loadOwnDailyData}
            loadDailyDataForCalendar={loadOwnDailyDataBetWeenDateRange}
          />
        </DetailPageWrapper>
      )}
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default OvPatientOwnResults;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

const UserName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledEmail = styled.span`
  border-left: 1px solid ${Colours.OV_BASE_HOVER};
  margin-left: 0.75rem;
  padding-left: 0.75rem;
`;

const LeftInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
