import React, { FC, useCallback, useEffect, useState } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import { SlotInfo, View, Views } from 'react-big-calendar';
import OvDailyDataCalendarHoc from '../molecules/OvDailyDataCalendarHoc';
import OvCalendarView from './OvCalendarView';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import OvDailyDataDetailsInCalendar from '../molecules/OvDailyDataDetailsInCalendar';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  clearDaySlotSelection,
  selectDaySlot,
} from '../../../redux/reducers/daily-data.slice';
import { Timestamp } from 'firebase/firestore';
import DateUtils from '../../../common/utils/services/date-utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { parseUrl } from 'query-string';
import {
  createJournalEntry,
  loadDailyDataBetweenDateRange,
} from '../../../redux/thunks/daily-data.thunk';
import { DailyDataService } from '../../../services/daily-data.service';
import { setMonth } from 'date-fns';
import { setYear } from 'date-fns';

const OvUserInfoCalendar: FC<{ selectedUser?: UserInfo }> = ({
  selectedUser,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();

  const [defaultCalendarDate, setDefaultCalendarDate] = useState<Date>();
  const dailyDataList = useAppSelector(
    (state) => state.dailyData.visibleDailyDataInCalendar
  );
  const selectedDailyDataInCalendar = useAppSelector(
    (state) => state.dailyData.selectedDailyDataInCalendar
  );
  const selectedDay = useAppSelector((state) => state.dailyData.selectedDay);

  const handleNavigationBetweenMonths = useCallback(
    (date: Date, view: View) => {
      let firstVisibleDay: Date, lastVisibleDay: Date;

      if (view === Views.MONTH) {
        firstVisibleDay = moment(date)
          .startOf('month')
          .subtract(20, 'days') // 7days needed because of the layout of the calendar, and another 10 day to count post ovulation days
          .toDate();
        lastVisibleDay = moment(date).endOf('month').add(7, 'days').toDate();

        if (selectedUser?.document_id) {
          dispatch(
            loadDailyDataBetweenDateRange({
              userId: selectedUser?.document_id,
              dayFrom: firstVisibleDay,
              dayTo: lastVisibleDay,
            })
          );
        }
      }
    },
    [dispatch, selectedUser?.document_id]
  );

  useEffect(() => {
    const visibleMonth: number = parseInt(
      parseUrl(search).query['visible-month'] as string
    );
    const visibleYear: number = parseInt(
      parseUrl(search).query['visible-year'] as string
    );

    let date = new Date();

    if (visibleMonth && visibleYear) {
      date = setMonth(date, visibleMonth - 1);
      date = setYear(date, visibleYear);
    }

    setDefaultCalendarDate(date);
    handleNavigationBetweenMonths(date, Views.MONTH);

    return function cleanup() {
      dispatch(clearDaySlotSelection());
    };
  }, [dispatch, handleNavigationBetweenMonths, search]);

  const handleSlotSelect = (slotInfo: SlotInfo) => {
    dispatch(selectDaySlot(slotInfo.start as Date));
  };

  const createNewJournalEntry = (timestamp: Timestamp) => {
    const day: string = DateUtils.formatDate(timestamp.toDate(), 'YYYY-MM-DD');
    dispatch(
      createJournalEntry({
        history,
        createJournalEntryRequest: {
          day,
          user_id: selectedUser?.id,
          user_document_id: selectedUser?.document_id,
        },
      })
    );
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <OvCalendarView
          events={DailyDataService.mapDailyDataToCalendarEvents(dailyDataList)}
          selectedDay={selectedDay}
          style={{
            maxWidth: '100%',
            height: 600,
          }}
          onSelect={(slotInfo) => handleSlotSelect(slotInfo)}
          onNavigate={(date: Date, view: View) => {
            history.push(
              `${url}?visible-month=${DateUtils.formatDate(
                date,
                'MM'
              )}&visible-year=${DateUtils.formatDate(date, 'YYYY')}`
            );
          }}
          headerHoc={OvDailyDataCalendarHoc}
          defaultDate={defaultCalendarDate}
        />

        <OvDailyDataDetailsInCalendar
          selectedDay={selectedDay}
          dailyData={selectedDailyDataInCalendar}
          createJournalEntry={createNewJournalEntry}
          selectedUser={selectedUser}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default OvUserInfoCalendar;

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;
