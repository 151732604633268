import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Colours from '../../../design-system/colours';
import { useFormik } from 'formik';
import { ActivateScanningPlanRequest } from '../../../common/model/dto/scan-sequences/activate-scanning-plan';
import breakpoints from '../../../design-system/breakpoints';
import OvSelect from '../atoms/OvSelect';
import OvDatePicker from './OvDatePicker';
import { UserInfo } from '../../../common/model/dto/user-info';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { stringify } from 'query-string';
import { loadScanSequencesByQuery } from '../../../redux/thunks/admin/admin-scan-sequences.thunk';
import { ScanSequence } from '../../../common/model/dto/scan-sequences/scan-sequence';
import Variables from '../../../design-system/variables';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvButton from '../atoms/OvButton';
import DateUtils from '../../../common/utils/services/date-utils';
import OvGenericScanningPlanTable from './OvGenericScanningPlanTable';
import { GenericScanningPlan } from '../../../common/model/dto/scan-sequences/generic-scanning-plan';
import { activateScanningPlanForUser } from '../../../redux/thunks/user.thunk';
import { sortBy } from 'lodash';
import { HealthGoalType } from '../../../common/model/dto/health-goal-type.enum';

const HEALTH_GOALS = Object.values(HealthGoalType);

const OvActivateScanningPlanDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  user?: UserInfo;
}> = ({ isOpen, onCancel, user }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const scanSequences: ScanSequence[] = useAppSelector(
    (state) => state.adminScanSequence.filteredScanSequences
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.adminScanSequence.isLoading
  );
  const [scanningPlanTypes, setScanningPlanTypes] = useState<string[]>([]);
  const [scanningPlanLengths, setScanningPlanLengths] = useState<number[]>([]);
  const [selectedScanSequence, setSelectedScanSequence] = useState<
    ScanSequence | undefined
  >(undefined);
  const [selectedScanningPlan, setSelectedScanningPlan] = useState<
    GenericScanningPlan | undefined
  >(undefined);
  const formik = useFormik<ActivateScanningPlanRequest>({
    initialValues: {
      day: DateUtils.getDbDateTag(new Date()),
      scanning_plan_length: 0,
      scanning_plan_type: 'not-selected',
      health_goal: 'not-selected',
    },
    onSubmit: (request: ActivateScanningPlanRequest) => {
      if (user?.id) {
        dispatch(
          activateScanningPlanForUser({
            userId: user?.id,
            activateScanningPlanRequest: request,
          })
        );

        onCancel();
      }
    },
  });

  useEffect(() => {
    if (
      formik.values.health_goal &&
      formik.values.health_goal !== 'not-selected'
    ) {
      const query = stringify({
        offset: 0,
        limit: 0,
        order_by: 'created_at',
        order_dir: 'desc',
        health_goal: formik.values.health_goal,
      });

      dispatch(loadScanSequencesByQuery(query));
    }

    // eslint-disable-next-line
  }, [formik.values.health_goal]);

  useEffect(() => {
    if (scanSequences.length > 0) {
      setScanningPlanTypes(
        scanSequences.map((scanSequence) => scanSequence.scanning_plan_type)
      );
    }
  }, [scanSequences]);

  useEffect(() => {
    if (
      formik.values.scanning_plan_type &&
      formik.values.scanning_plan_type !== 'not-selected' &&
      formik.values.health_goal &&
      formik.values.health_goal !== 'not-selected'
    ) {
      const scanSequence = scanSequences.find(
        (scanSequence) =>
          scanSequence.health_goal.toLowerCase() ===
            formik.values.health_goal.toLowerCase() &&
          scanSequence.scanning_plan_type.toLowerCase() ===
            formik.values.scanning_plan_type.toLowerCase()
      );

      if (scanSequence) {
        setSelectedScanSequence(scanSequence);

        setScanningPlanLengths(
          scanSequence?.scanning_plans.map(
            (scanningPlan) => scanningPlan.scanning_plan_entries.length
          ) ?? []
        );
      }
    }

    // eslint-disable-next-line
  }, [formik.values.scanning_plan_type, formik.values.health_goal]);

  useEffect(() => {
    if (selectedScanSequence && formik.values.scanning_plan_length !== 0) {
      setSelectedScanningPlan(
        selectedScanSequence.scanning_plans.find(
          (plan) =>
            plan.scanning_plan_entries.length ===
            formik.values.scanning_plan_length
        )
      );
    }

    // eslint-disable-next-line
  }, [formik.values.scanning_plan_length]);

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setSelectedScanSequence(undefined);
      setSelectedScanningPlan(undefined);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleDateSelection = (date: Date) => {
    formik.getFieldHelpers('day').setValue(DateUtils.getDbDateTag(date));
  };

  const handleHealthGoalSelection = (e: any) => {
    formik.getFieldHelpers('health_goal').setValue(e.target.value);
    formik.getFieldHelpers('scanning_plan_type').setValue('not-selected');
    formik.getFieldHelpers('scanning_plan_length').setValue(0);
    setSelectedScanningPlan(undefined);
  };

  const handleScanningPlanTypeSelection = (e: any) => {
    formik.getFieldHelpers('scanning_plan_type').setValue(e.target.value);
    formik.getFieldHelpers('scanning_plan_length').setValue(0);
    setSelectedScanningPlan(undefined);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogTitle>
          {t('userDetails.scanningPlans.activateScanningPlan')}
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledDatePicker
            views={['year', 'month', 'day']}
            value={new Date(formik.values.day)}
            label={t('userDetails.scanningPlans.scanningPlanStartDate')}
            onChange={handleDateSelection}
          />
          <StyledHeader>
            {t('userDetails.scanningPlans.healthGoal')}
          </StyledHeader>
          <StyledOvSelect
            error={formik.touched.health_goal && !!formik.errors.health_goal}
            {...formik.getFieldProps('health_goal')}
            onChange={handleHealthGoalSelection}
          >
            <StyledMenuItem value={'not-selected'}>
              <em>{t('common.actions.notSelected')}</em>
            </StyledMenuItem>
            {HEALTH_GOALS.map((healthGoal) => (
              <StyledMenuItem key={healthGoal} value={healthGoal}>
                {healthGoal}
              </StyledMenuItem>
            ))}
          </StyledOvSelect>
          {formik.values.health_goal !== 'not-selected' && (
            <>
              <StyledHeader>
                {t('userDetails.scanningPlans.scanningPlanType')}
              </StyledHeader>
              <StyledOvSelect
                error={
                  formik.touched.scanning_plan_type &&
                  !!formik.errors.scanning_plan_type
                }
                {...formik.getFieldProps('scanning_plan_type')}
                disabled={
                  isLoading || formik.values.health_goal === 'not-selected'
                }
                onChange={handleScanningPlanTypeSelection}
              >
                <StyledMenuItem value={'not-selected'}>
                  <em>{t('common.actions.notSelected')}</em>
                </StyledMenuItem>
                {scanningPlanTypes.map((scanningPlanType) => (
                  <StyledMenuItem
                    key={scanningPlanType}
                    value={scanningPlanType}
                  >
                    {scanningPlanType}
                  </StyledMenuItem>
                ))}
              </StyledOvSelect>
            </>
          )}
          {formik.values.health_goal !== 'not-selected' &&
            formik.values.scanning_plan_type !== 'not-selected' && (
              <>
                <StyledHeader>
                  {t('userDetails.scanningPlans.scanningPlanLength')}
                </StyledHeader>
                <StyledOvSelect
                  error={
                    formik.touched.scanning_plan_length &&
                    !!formik.errors.scanning_plan_length
                  }
                  {...formik.getFieldProps('scanning_plan_length')}
                  disabled={
                    isLoading ||
                    formik.values.health_goal === 'not-selected' ||
                    formik.values.scanning_plan_type === 'not-selected'
                  }
                >
                  <StyledMenuItem value={0}>
                    <em>{t('common.actions.notSelected')}</em>
                  </StyledMenuItem>
                  {sortBy(scanningPlanLengths).map((scanningPlanLength) => (
                    <StyledMenuItem
                      key={scanningPlanLength}
                      value={scanningPlanLength}
                    >
                      {`${scanningPlanLength} day`}
                    </StyledMenuItem>
                  ))}
                </StyledOvSelect>
              </>
            )}

          {selectedScanningPlan && (
            <>
              <StyledHeader>
                {t('userDetails.scanningPlans.preview')}
              </StyledHeader>
              <OvGenericScanningPlanTable
                startDate={formik.values.day}
                genericScannigPlan={selectedScanningPlan}
              />
            </>
          )}
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton
            disabled={
              isLoading ||
              formik.values.health_goal === 'not-selected' ||
              formik.values.scanning_plan_length === 0 ||
              formik.values.scanning_plan_type === 'not-selected'
            }
            type="submit"
          >
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvActivateScanningPlanDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 500px !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    width: 100%;
    align-self: center;
    max-width: 100%;
    margin-bottom: 0.75rem;
  }
`;

const StyledDatePicker = styled(OvDatePicker)`
  width: 100%;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledHeader = styled.h5`
  color: ${Colours.OV_BASE};
  padding-bottom: 0;
  margin: 0;
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;
