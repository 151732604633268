import axios from 'axios';
import { Order } from '../common/model/dto/order/order';
import { Filter, Paginated } from '../common/types';
import { OrderActivity } from '../common/model/dto/order/order-activity';
import {
  ADMIN_ORDER_ACTIVITIES_ROUTE,
  ADMIN_ORDERS_ROUTE,
} from '../api/backend';

export default class OrdersService {
  public static readonly ORDER_LIST_LIMIT: number = 10;
  static readonly BASE_URL = process.env.REACT_APP_SHOPIFY_WEBHOOK_URL;

  static async loadOrdersByQuery(query: string): Promise<Paginated<Order>> {
    const { data } = await axios.get<Paginated<Order>>(
      `${ADMIN_ORDERS_ROUTE}?${query}`
    );

    return data;
  }

  static async createManualOrder(order: Order): Promise<Order> {
    const { data } = await axios.post<Order>(`${ADMIN_ORDERS_ROUTE}`, order);

    return data;
  }

  static async getBackendOrderById(orderId: string): Promise<Order> {
    const { data } = await axios.get<Order>(`${ADMIN_ORDERS_ROUTE}/${orderId}`);

    return data;
  }

  static async getOrderActivitesById(
    orderId?: string
  ): Promise<Paginated<OrderActivity>> {
    const { data } = await axios.get<Paginated<OrderActivity>>(
      `${ADMIN_ORDER_ACTIVITIES_ROUTE}?order_internal_id=${orderId}`
    );

    return data;
  }

  static async updateBackendOrder(updatedProperties: any, orderId?: string) {
    const { data } = await axios.patch<Paginated<OrderActivity>>(
      `${ADMIN_ORDERS_ROUTE}/${orderId}`,
      updatedProperties
    );

    return data;
  }

  static async submitBackendOrder(orderId: string) {
    const { data } = await axios.get<Paginated<OrderActivity>>(
      `${ADMIN_ORDERS_ROUTE}/${orderId}/submit`
    );

    return data;
  }

  static getQueryParams(filters: Filter[]) {
    const queryParams: any = {};

    filters.forEach((filter) => {
      queryParams[filter.field] = Array.isArray(filter.value)
        ? filter.value.join(',')
        : filter.value;
    });

    return queryParams;
  }
}
