import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { ScanSequence } from '../../common/model/dto/scan-sequences/scan-sequence';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearSelectedScanSequence } from '../../redux/reducers/admin-scan-sequence.slice';
import {
  deleteScanSequence,
  getScanSequenceById,
} from '../../redux/thunks/admin/admin-scan-sequences.thunk';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { ScanSequencesTabType } from '../../common/model/type/scan-sequence-details-tab.type';
import { last, sortBy } from 'lodash';
import { Alert, AlertTitle, Tab, Tabs } from '@mui/material';
import ScanSequenceInfo from './ScanSequenceInfo';
import GenericScanningPlans from './GenericScanningPlans';
import OvButton from '../UI/atoms/OvButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvAddNewPlanDialog from '../UI/molecules/OvAddNewPlanDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import OvInfoModal from '../UI/molecules/OvInfoModal';
import InfoIcon from '@mui/icons-material/Info';
import OvConfirmationInput from '../UI/molecules/OvConfirmationInput';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';

const ScanSequenceDetails = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const scanningPlanOperations = useAuthorized(Resource.ScanningPlan);

  const scanningPlanCreateAllowed =
    scanningPlanOperations.create || applicationOperations.supervise;
  const scanningPlanDeleteAllowed =
    scanningPlanOperations.delete || applicationOperations.supervise;

  const { scanSequenceId }: any = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState<ScanSequencesTabType>(
    ScanSequencesTabType.SCANNING_PLANS
  );
  const [isAddNewPlanDialogOpen, setIsAddNewPlanDialogOpen] =
    useState<boolean>(false);
  const [isDeleteScanSequenceDialogOpen, setIsDeleteScanSequenceDialogOpen] =
    useState<boolean>(false);
  const scanSequence: ScanSequence | undefined = useAppSelector(
    (state) => state.adminScanSequence.selectedScanSequence
  );
  const isLoading = useAppSelector(
    (state) => state.adminScanSequence.isLoading
  );

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(
        location.pathname.split('/')
      ) as ScanSequencesTabType;
      const { SCANNING_PLANS, SCAN_SEQUENCE_INFO } = ScanSequencesTabType;

      if ([SCANNING_PLANS, SCAN_SEQUENCE_INFO].includes(pathName)) {
        setActiveTab(pathName);
      }
    };

    setActiveTabFromUrl();
  }, [dispatch, scanSequence?.id, location.pathname]);

  useEffect(() => {
    dispatch(clearSelectedScanSequence());
    dispatch(getScanSequenceById(scanSequenceId));
  }, [dispatch, scanSequenceId]);

  const changeTab = (event: any, newValue: ScanSequencesTabType) => {
    setActiveTab(newValue);
  };

  const onBackClicked = () => {
    history.push('/scan-sequences');
  };

  const handleDeleteScanSequence = () => {
    if (scanSequence?.id) {
      setIsDeleteScanSequenceDialogOpen(false);
      dispatch(deleteScanSequence({ history, id: scanSequence?.id }));
    }
  };

  return (
    <Container>
      <Header>
        <InfoContainer>
          <BackCta onClick={onBackClicked}>
            <StyledArrowBackIcon />
            {t('common.actions.back')}
          </BackCta>
          <Name>{scanSequence?.health_goal}</Name>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Name>{scanSequence?.scanning_plan_type}</Name>
        </InfoContainer>
        <Actions>
          {scanningPlanDeleteAllowed && (
            <StyledOvButton
              onClick={() => setIsDeleteScanSequenceDialogOpen(true)}
            >
              <DeleteIcon
                style={{ verticalAlign: 'middle', marginRight: '5' }}
              />
              {t('scanSequenceDetails.deleteScanSequence')}
            </StyledOvButton>
          )}
          {scanningPlanCreateAllowed && (
            <StyledOvButton onClick={() => setIsAddNewPlanDialogOpen(true)}>
              <AddCircleIcon
                style={{ verticalAlign: 'middle', marginRight: '5' }}
              />
              {t('scanSequenceDetails.addNewScanningPlan')}
            </StyledOvButton>
          )}
        </Actions>
      </Header>
      {!scanSequence?.is_enabled &&
        scanSequence?.scanning_plans?.length === 0 && (
          <StyledAlert severity="info">
            <AlertTitle>{t('scanSequenceDetails.alertTitle')}</AlertTitle>
            <StyledAlertContent>
              {t('scanSequenceDetails.alertDescription')}
            </StyledAlertContent>
          </StyledAlert>
        )}

      <TabContainer>
        <Tabs value={activeTab} onChange={changeTab}>
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={ScanSequencesTabType.SCANNING_PLANS}
            label={t('scanSequenceDetails.tabs.scanningPlans')}
            component={Link}
            to={`${url}/${ScanSequencesTabType.SCANNING_PLANS}`}
          />
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={ScanSequencesTabType.SCAN_SEQUENCE_INFO}
            label={t('scanSequenceDetails.tabs.scanSequenceInfo')}
            component={Link}
            to={`${url}/${ScanSequencesTabType.SCAN_SEQUENCE_INFO}`}
          />
        </Tabs>
      </TabContainer>

      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/scanning-plans`} />
        </Route>
        <Route path={`${path}/scanning-plans`}>
          <GenericScanningPlans
            scanningPlans={sortBy(
              scanSequence?.scanning_plans,
              (plan) => plan.scanning_plan_entries.length
            )}
            scanSequence={scanSequence!}
          />
        </Route>
        <Route path={`${path}/scan-sequence-info`}>
          <ScanSequenceInfo scanSequence={scanSequence!} />
        </Route>
      </Switch>

      {scanSequence && (
        <OvAddNewPlanDialog
          isOpen={isAddNewPlanDialogOpen}
          onCancel={() => setIsAddNewPlanDialogOpen(false)}
          scanSequence={scanSequence}
          isLoading={isLoading}
        />
      )}

      <OvInfoModal
        isOpen={isDeleteScanSequenceDialogOpen}
        title={t('common.areYouSure')}
        onCancel={() => setIsDeleteScanSequenceDialogOpen(false)}
        icon={<StyledInfoIcon />}
      >
        <OvConfirmationInput
          expectedValue={`${scanSequence?.health_goal} ${scanSequence?.scanning_plan_type}`}
          label={t('scanSequenceDetails.actions.deleteScanSequenceConfirm')}
          ctaLabel={t(
            'scanSequenceDetails.actions.deleteScanSequenceConfirmAction'
          )}
          onMatch={handleDeleteScanSequence}
          caseSensitive={true}
        />
      </OvInfoModal>

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Container>
  );
};

export default ScanSequenceDetails;

const Container = styled.section`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-weight: bold;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const Name = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const BackCta = styled(OvButton)`
  && {
    padding: 0 10px 0 4px;
    margin-right: 1rem;
    text-transform: none;
    font-weight: bold;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 0.25rem;
  height: 1.25rem;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const StyledInfoIcon = styled(InfoIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_DISABLED};
    color: ${Colours.OV_BASE};
    gap: 2rem;
    width: 100%;

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;

const StyledAlertContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
