import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearSelectedClinicLocation } from '../../redux/reducers/clinic-location.slice';
import { getClinicLocationById } from '../../redux/thunks/clinic-location.thunk';
import ClinicLocationInfo from './ClinicLocationInfo';

const SelectedClinicInfo = () => {
  const dispatch = useAppDispatch();
  const selectedClinic: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const clinicLocationInfo: ClinicLocation | undefined = useAppSelector(
    (state) => state.clinicLocation.selectedClinicLocation
  );
  const isLoading = useAppSelector((state) => state.clinicLocation.isLoading);

  useEffect(() => {
    dispatch(clearSelectedClinicLocation());
    if (selectedClinic?.id) {
      dispatch(getClinicLocationById(selectedClinic?.id));
    }
  }, [dispatch, selectedClinic]);

  return (
    <ClinicInfoWrapper>
      <ClinicLocationInfo
        selectedClinicLocation={clinicLocationInfo}
        isLoading={isLoading}
      />
    </ClinicInfoWrapper>
  );
};

export default SelectedClinicInfo;

const ClinicInfoWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;
