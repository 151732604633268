import axios from 'axios';
import { ScanSequence } from '../../common/model/dto/scan-sequences/scan-sequence';
import { Paginated } from '../../common/types';
import { ADMIN_SCAN_SEQUENCES_ROUTE } from '../../api/backend';
import { AddScanningPlanDto } from '../../common/model/dto/scan-sequences/add-scanning-plan';
import { DeleteScanningPlanDto } from '../../common/model/dto/scan-sequences/delete-scanning-plan';
import { CreateScanSequenceRequest } from '../../common/model/dto/scan-sequences/create-scan-sequence-request.dto';

export class AdminScanSequencesService {
  public static readonly SCAN_SEQUENCE_LIST_LIMIT = 10;

  static async loadScanSequencesByQuery(query: string) {
    const { data } = await axios.get<Paginated<ScanSequence>>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}?${query}`
    );

    return data;
  }

  static async getScanSequenceById(
    scanSequenceId: string
  ): Promise<ScanSequence> {
    const { data } = await axios.get<ScanSequence>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}/${scanSequenceId}`
    );

    return data;
  }

  static async createScanSequence(
    createScanSequenceRequest: CreateScanSequenceRequest
  ): Promise<ScanSequence> {
    const { data } = await axios.post<ScanSequence>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}`,
      createScanSequenceRequest
    );

    return data;
  }

  static async duplicateScanSequence(id: string): Promise<ScanSequence> {
    const { data } = await axios.post<ScanSequence>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}/${id}`
    );

    return data;
  }

  static async updateScanSequence(
    scanSequenceId: string,
    updateObj: any
  ): Promise<ScanSequence> {
    const { data } = await axios.patch<ScanSequence>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}/${scanSequenceId}`,
      updateObj
    );

    return data;
  }

  static async deleteScanSequence(id: string): Promise<void> {
    await axios.delete(`${ADMIN_SCAN_SEQUENCES_ROUTE}/${id}`);
  }

  static async addScanningPlanToScanSequence(
    id: string,
    addScanningPlanDto: AddScanningPlanDto
  ): Promise<ScanSequence> {
    const { data } = await axios.patch<ScanSequence>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}/${id}/add-scanning-plan`,
      addScanningPlanDto
    );

    return data;
  }

  static async deleteScanningPlanFromScanSequence(
    id: string,
    deleteScanningPlanDto: DeleteScanningPlanDto
  ): Promise<ScanSequence> {
    const { data } = await axios.patch<ScanSequence>(
      `${ADMIN_SCAN_SEQUENCES_ROUTE}/${id}/delete-scanning-plan`,
      deleteScanningPlanDto
    );

    return data;
  }
}
