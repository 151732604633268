import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'query-string';
import { DailyDataService } from '../../services/daily-data.service';
import { History } from 'history';
import { JournalEntry } from '../../common/model/dto/journal-entry';
import { JournalEntryService } from '../../services/journal-entry.service';
import { DailyData } from '../../common/model/dto/daily-data';
import { RootState } from '../store';
import { endOfDay, startOfDay } from 'date-fns';

export const loadDailyDataEntity = createAsyncThunk(
  'dailyData/selectDailyDataDetails',
  async (
    { day, userDocumentId }: { day: string; userDocumentId: string },
    { rejectWithValue }
  ) => {
    try {
      const paginatedDailyData =
        await DailyDataService.loadDailyDataListByQuery(
          stringify({
            limit: 1,
            user_document_id: userDocumentId,
            day_from: day,
            day_to: day,
          })
        );
      return paginatedDailyData?.docs[0];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateJournalEntry = createAsyncThunk(
  'dailyData/updateJournalEntry',
  async (
    updateObj: {
      id: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      return await JournalEntryService.updateJournalEntry(
        updateObj.id,
        updateObj.updatedProperties
      );
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createJournalEntry = createAsyncThunk(
  'dailyData/createJournalEntry',
  async (
    {
      history,
      createJournalEntryRequest,
    }: {
      history: History;
      createJournalEntryRequest: {
        day: string;
        user_id?: string;
        user_document_id?: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const result: JournalEntry = await JournalEntryService.createJournalEntry(
        {
          day: createJournalEntryRequest.day,
          user_id: createJournalEntryRequest.user_id,
        }
      );

      if (createJournalEntryRequest.user_document_id && result.day) {
        history.push(
          `/daily-data/${createJournalEntryRequest.user_document_id}_${result.day}`
        );
      }

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadDailyDataBetweenDateRange = createAsyncThunk(
  'dailyData/betweenDates',
  async (
    { userId, dayFrom, dayTo }: { userId: string; dayFrom: Date; dayTo: Date },
    { rejectWithValue }
  ) => {
    try {
      const queryParams: any = {
        user_document_id: userId,
        day_from: dayFrom.toISOString(),
        day_to: dayTo.toISOString(),
        limit: 100,
      };

      const query = stringify(queryParams);

      const paginatedDailyData =
        await DailyDataService.loadDailyDataListByQuery(query);

      return paginatedDailyData.docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadUserDailyData = createAsyncThunk<
  DailyData[],
  {
    userDocumentId: string;
    startDate?: Date;
    endDate?: Date;
  },
  { state: RootState }
>(
  'dailyData/loadUserDailyData',
  async ({ userDocumentId, startDate, endDate }) => {
    try {
      let query: string = '';

      if (startDate && endDate) {
        query = stringify({
          limit: 0,
          user_document_id: userDocumentId,
          day_from: startOfDay(startDate).toISOString(),
          day_to: endOfDay(endDate).toISOString(),
        });
      }

      const paginatedDailyData =
        await DailyDataService.loadDailyDataListByQuery(query);

      return paginatedDailyData.docs;
    } catch (error) {
      return [];
    }
  }
);
