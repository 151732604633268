import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FilterModel } from '../../../common/model/ui/filter.model';
import { FilterType } from '../../../common/model/ui/filter.type';
import OvTextField from '../atoms/OvTextField';
import OvRadioGroup from '../molecules/OvRadioGroup';
import OvSlider from '../atoms/OvSlider';
import { InputLabel, MenuItem } from '@mui/material';
import OvSelect from '../atoms/OvSelect';
import OvDatePicker from '../molecules/OvDatePicker';
import DateUtils from '../../../common/utils/services/date-utils';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

function sliderValuetext(value: number) {
  return `${value} years`;
}

const OvSearchFilter: FC<{
  filter: FilterModel;
  applyFilter: (filter: FilterModel) => void;
}> = ({ filter, applyFilter, ...props }) => {
  const { t } = useTranslation();
  let filterWidgetComponent;
  const [value, setValue] = useState(filter.value);

  useEffect(() => {
    setValue(filter.value);
  }, [filter]);

  const onChange = (event: any, newValue?: any) => {
    let value = event?.target ? event.target.value : newValue;
    setValue(value);

    return value;
  };

  const onBlur = (event: any, newValue?: any) => {
    onApplyFilter(onChange(event, newValue));
  };

  const onSelect = (event: any, newValue?: any) => {
    onApplyFilter(onChange(event, newValue));
  };

  const onDragEnd = (event: any, newValue?: any) => {
    onApplyFilter(value);
  };

  const onDateValueChanged = (newDate: Date) => {
    onApplyFilter(DateUtils.formatDate(newDate, 'YYYY-MM-DD'));
  };

  const onApplyFilter = (currentValue: any) => {
    applyFilter({
      ...filter,
      value: currentValue,
    });
  };

  switch (filter.type) {
    case FilterType.text:
      filterWidgetComponent = (
        <StyledOvTextField
          disabled={filter.disabled}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          placeholder={filter.placeholder ? t(filter.placeholder) : ''}
        />
      );
      break;
    case FilterType.slider:
      filterWidgetComponent = (
        <StyledSlider
          disabled={filter.disabled}
          onChangeCommitted={onDragEnd}
          disableSwap
          style={{ maxWidth: '320px' }}
          getAriaLabel={() => t('common.actions.horizontalSlider')}
          value={value}
          onChange={onChange}
          valueLabelDisplay="on"
          getAriaValueText={sliderValuetext}
          min={filter.min}
          max={filter.max}
        />
      );
      break;
    case FilterType.select:
      filterWidgetComponent = (
        <StyledSelect
          labelId={filter.fieldName}
          value={value}
          onChange={onSelect}
          displayEmpty
        >
          <StyledMenuItem
            value={''}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <em>{t('common.actions.notSelected')}</em>
          </StyledMenuItem>
          {filter?.options &&
            filter.options.map((filter) => (
              <StyledMenuItem
                key={filter.value}
                value={filter.value}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {filter?.translate ? t(filter.label) : filter.label}
              </StyledMenuItem>
            ))}
        </StyledSelect>
      );
      break;
    case FilterType.radio:
      filterWidgetComponent = (
        <StyledOvRadioGroup
          disabled={filter.disabled}
          options={filter.options}
          value={value}
          onChange={onSelect}
        />
      );
      break;
    case FilterType.date_picker:
      filterWidgetComponent = (
        <OvDatePicker
          views={['year', 'month', 'day']}
          value={filter?.value ? new Date(filter.value) : null}
          onChange={onDateValueChanged}
          slots={(params: any) => <OvTextField {...params} helperText={null} />}
        />
      );
  }

  return (
    <Wrapper>
      {filter.label && <Label id={filter.fieldName}>{t(filter.label)}</Label>}
      {filterWidgetComponent}
    </Wrapper>
  );
};

export default OvSearchFilter;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled(InputLabel)`
  && {
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-transform: uppercase;
  }
`;

const StyledOvTextField = styled(OvTextField)`
  && {
    & .MuiOutlinedInput-root {
      & input {
        padding: 6px 10px;
      }
    }
  }
`;

const StyledOvRadioGroup = styled(OvRadioGroup)`
  && {
    .MuiFormControlLabel-label,
    .MuiRadio-root {
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 0.00714em;
    }

    .MuiFormControlLabel-root {
      margin-right: 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }

    .MuiRadio-root {
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
`;

const StyledSlider = styled(OvSlider)`
  && {
    &.MuiSlider-root {
      color: ${Colours.OV_YELLOW};
    }

    .MuiSlider-thumb {
      width: 1.25rem;
      height: 1.25rem;

      .MuiSlider-valueLabel {
        top: 1.4rem;
        background-color: transparent;
        color: ${Colours.OV_BASE};
        font-size: 0.75rem;
        font-weight: bold;
      }
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_LIGHT_GRAY};
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
