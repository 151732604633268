import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FilterModel } from '../../../common/model/ui/filter.model';
import { hasAppliedFiltersSelector } from '../../../redux/selectors/products.selectors';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { getProducts } from '../../../redux/thunks/products.thunk';
import {
  changeProductListFilter,
  clearFilters,
} from '../../../redux/reducers/products.slice';
import OvListFilter from './OvListFilter';

const OvProductSearchFilter: FC = () => {
  const isOpened = useAppSelector((state) => state.products.showFilters);
  const filters: FilterModel[] = useAppSelector(
    (state) => state.products.filters
  );
  const hasAppliedFilters = useAppSelector(hasAppliedFiltersSelector);
  const dispatch = useAppDispatch();
  const history: History = useHistory();

  const removeFilters = () => {
    dispatch(clearFilters());
    dispatch(getProducts(history));
  };

  const handleFilterValueChange = (filter: FilterModel) => {
    dispatch(changeProductListFilter(filter));
    dispatch(getProducts(history));
  };

  return (
    <OvListFilter
      filters={filters}
      isOpened={isOpened}
      hasAppliedFilters={hasAppliedFilters}
      onRemoveFilters={removeFilters}
      onHandleFilterValueChange={handleFilterValueChange}
    />
  );
};

export default OvProductSearchFilter;
