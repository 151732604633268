import { FC, useEffect } from 'react';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OvTextField from '../atoms/OvTextField';
import DialogActions from '@mui/material/DialogActions';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText } from '@mui/material';

const OvForgotPasswordDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: (email: string) => void;
}> = ({ isOpen, onCancel, onSave }) => {
  const { t } = useTranslation();

  const formik = useFormik<{ email: string }>({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    onSubmit: (request: { email: string }) => {
      onSave(request.email);
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('login.invalidEmail'))
        .required(t('forgotPassword.emailRequired')),
    }),
    validateOnBlur: true,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle id="alert-dialog-title">
        {t('forgotPassword.title')}
      </StyledDialogTitle>
      <StyledDialogContentText>
        {t('forgotPassword.dialogContentText')}
      </StyledDialogContentText>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogContent>
          <StyledTextField
            type="email"
            fullWidth
            autoComplete="off"
            id="email"
            label={t('login.emailLabel')}
            error={formik.touched.email && !!formik.errors.email}
            helperText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
            {...formik.getFieldProps('email')}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton type="submit">
            {t('common.actions.sendEmail')}
          </StyledOvButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

export default OvForgotPasswordDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    padding: 1.5rem !important;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const StyledDialogContentText = styled(DialogContentText)`
  &&  {
    padding: 1.5rem !important;
    width: 100%;
    flex-wrap: wrap;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;
