export const cleanRequestObject = (request: any) => {
  return Object.fromEntries(
    Object.entries(request).filter(
      ([_, v]) => v !== undefined && v !== null && v !== ''
    )
  );
};

export const getBaseUrl = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return 'https://360.oova.life';
  }

  if (process.env.REACT_APP_ENV === 'development') {
    return 'http://localhost:3000';
  }

  return 'https://360-staging.oova.life';
};
