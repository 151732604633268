import { useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateClinicRequest } from '../../../common/model/dto/create-clinic-request';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import DialogContent from '@mui/material/DialogContent';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import DialogActions from '@mui/material/DialogActions';
import Variables from '../../../design-system/variables';
import OvRadioGroup from './OvRadioGroup';
import DialogTitle from '@mui/material/DialogTitle';
import { cleanRequestObject } from '../../../common/utils/services/helpers';
import { ClinicService } from '../../../services/clinic.service';
import { debounce } from 'lodash';
import {
  ClinicInfoFields,
  ClinicLocationFields,
} from '../../../firebase/document-field.enums';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { ClinicLocationService } from '../../../services/clinic-location.service';
import { Regexes } from '../../../common/model/type/regexes.type';
import {
  CLINIC_ID_MAX_LENGTH,
  CLINIC_ID_MIN_LENGTH,
  CLINIC_LOCATION_ID_MAX_LENGTH,
  CLINIC_LOCATION_ID_MIN_LENGTH,
} from '../../../common/model/type/id-validation';
import OvGooglePlacesAutocomplete from './OvGooglePlacesAutocomplete';

export interface OvCreateClinicDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave?: (request: any) => void;
}

const REQUEST_DELAY = 1000;

const OvCreateClinicDialog: FC<OvCreateClinicDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [doesClinicIdAlreadyExist, setDoesClinicIdAlreadyExist] = useState<
    boolean | undefined
  >(undefined);
  const [
    doesClinicLocationEmailAlreadyExist,
    setDoesClinicLocationEmailAlreadyExist,
  ] = useState<boolean | undefined>(undefined);
  const [
    doesClinicLocationIdAlreadyExist,
    setDoesClinicLocationIdAlreadyExist,
  ] = useState<boolean | undefined>(undefined);

  const formik = useFormik<CreateClinicRequest>({
    initialValues: {
      clinic_name: '',
      email: undefined,
      address1: '',
      address2: '',
      phone1: '',
      phone2: '',
      city: '',
      state: '',
      zip_code: '',
      is_patient_sharing_on: false,
      is_automatic_patient_to_clinic_location_connection_enabled: true,
      clinic_id: '',
      clinic_location_name: '',
      clinic_location_id: '',
      geolocation: undefined,
    },
    onSubmit: (createClinicRequest: CreateClinicRequest) => {
      if (onSave) {
        onSave(cleanRequestObject(createClinicRequest));
      }
    },
    validationSchema: Yup.object().shape({
      clinic_name: Yup.string().required(t('clinicDialogs.clinicNameRequired')),
      clinic_location_name: Yup.string().required(
        t('clinicDialogs.clinicSiteNameRequired')
      ),
      clinic_id: Yup.string()
        .required(t('clinicDialogs.clinicIdRequired'))
        .uppercase(t('clinicDialogs.onlyUpperCase'))
        .strict()
        .test('len', t('clinicDialogs.clinicIdFormatError'), (val) => {
          if (val === undefined) {
            return true;
          }
          return (
            val.length >= CLINIC_ID_MIN_LENGTH &&
            val.length <= CLINIC_ID_MAX_LENGTH
          );
        })
        .test(
          'checkIfExists',
          t('clinicDialogs.clinicIdAlreadyTaken'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return !doesClinicIdAlreadyExist;
          }
        ),
      clinic_location_id: Yup.string()
        .required(t('clinicLocationDialogs.clinicSiteIdRequired'))
        .uppercase(t('clinicLocationDialogs.onlyUpperCase'))
        .strict()
        .test(
          'len',
          t('clinicLocationDialogs.clinicLocationIdFormat'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return (
              val.length >= CLINIC_LOCATION_ID_MIN_LENGTH &&
              val.length <= CLINIC_LOCATION_ID_MAX_LENGTH
            );
          }
        )
        .test(
          'checkIfExists',
          t('clinicLocationDialogs.clinicSiteIdAlreadyTaken'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return !doesClinicLocationIdAlreadyExist;
          }
        ),
      email: Yup.string()
        .test(
          'emailValidation',
          t('clinicLocationDialogs.isvalidEmail'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return Regexes.isValidEmail.test(val);
          }
        )
        .test(
          'checkIfExists',
          t('clinicLocationDialogs.clinicSiteEmailAlreadyTaken'),
          (val) => {
            if (val === undefined) {
              return true;
            }

            return !doesClinicLocationEmailAlreadyExist;
          }
        ),
    }),
    validateOnBlur: true,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const doesClinicExistWithClinicId = (clinicId: string) => {
    return ClinicService.doesClinicExistWithClinicId(clinicId);
  };

  const doesClinicLocationExistWithClinicLocationId = (
    clinicLocationId: string
  ) => {
    return ClinicLocationService.doesClinicLocationExistWithClinicLocationId(
      clinicLocationId
    );
  };

  const doesClinicLocationExistWithEmail = (email: string) => {
    return ClinicLocationService.doesClinicLocationExistWithEmail(email);
  };

  // eslint-disable-next-line
  const checkIfClinicExistsWithClinicIdDelayed = useCallback(
    debounce((inputValue) => {
      (async () => {
        if (inputValue.length >= CLINIC_ID_MIN_LENGTH) {
          setIsLoading(true);
          const alreadyExists = await doesClinicExistWithClinicId(inputValue);

          setDoesClinicIdAlreadyExist(alreadyExists);
        }

        setIsLoading(false);
        formik.setFieldTouched(ClinicInfoFields.clinicId);
        document.getElementById(ClinicInfoFields.clinicId)?.focus();
      })();
    }, REQUEST_DELAY),
    []
  );

  // eslint-disable-next-line
  const checkIfClinicLocationExistsWithClinicLocationIdDelayed = useCallback(
    debounce((inputValue) => {
      (async () => {
        if (inputValue.length >= CLINIC_LOCATION_ID_MIN_LENGTH) {
          setIsLoading(true);
          const alreadyExists =
            await doesClinicLocationExistWithClinicLocationId(inputValue);

          setDoesClinicLocationIdAlreadyExist(alreadyExists);
        }

        setIsLoading(false);
        formik.setFieldTouched(ClinicLocationFields.clinic_location_id);
        document
          .getElementById(ClinicLocationFields.clinic_location_id)
          ?.focus();
      })();
    }, REQUEST_DELAY),
    []
  );

  // eslint-disable-next-line
  const checkIfClinicLocationExistsWithEmail = useCallback(
    debounce((inputValue) => {
      (async () => {
        setIsLoading(true);
        const alreadyExists = await doesClinicLocationExistWithEmail(
          inputValue
        );

        setDoesClinicLocationEmailAlreadyExist(alreadyExists);

        setIsLoading(false);
        formik.setFieldTouched(ClinicLocationFields.email);
        document.getElementById(ClinicLocationFields.email)?.focus();
      })();
    }, REQUEST_DELAY),
    []
  );

  const handleChange = async (e: any) => {
    const inputField = e.target.name;
    const inputValue = e.target.value;

    if (inputField === ClinicInfoFields.clinicId) {
      setDoesClinicIdAlreadyExist(false);
    } else if (inputField === ClinicLocationFields.clinic_location_id) {
      setDoesClinicLocationIdAlreadyExist(false);
    } else if (inputField === ClinicLocationFields.email) {
      setDoesClinicLocationEmailAlreadyExist(false);
    }

    if (
      inputField === ClinicInfoFields.clinicId &&
      inputValue.length >= CLINIC_ID_MIN_LENGTH &&
      inputValue.length <= CLINIC_ID_MAX_LENGTH
    ) {
      checkIfClinicExistsWithClinicIdDelayed(inputValue);
    } else if (
      inputField === ClinicLocationFields.clinic_location_id &&
      inputValue.length >= CLINIC_LOCATION_ID_MIN_LENGTH &&
      inputValue.length <= CLINIC_LOCATION_ID_MAX_LENGTH
    ) {
      checkIfClinicLocationExistsWithClinicLocationIdDelayed(
        `${formik.values.clinic_id}-${inputValue}`
      );
    } else if (
      inputField === ClinicLocationFields.email &&
      Regexes.isValidEmail.test(inputValue)
    ) {
      checkIfClinicLocationExistsWithEmail(inputValue);
    }

    formik.handleChange(e);
  };

  const setFormikFields = (place: google.maps.places.PlaceResult | null) => {
    if (!place) {
      return;
    }
    const city = place.address_components?.find((addressComponent) =>
      addressComponent.types.includes('locality')
    )?.long_name;

    formik.getFieldHelpers('clinic_name').setValue(place.name);
    formik
      .getFieldHelpers('clinic_location_name')
      .setValue(`${place.name}, ${city}`);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={formik.handleSubmit} onChange={handleChange}>
        <StyledDialogTitle>
          {t('clinicDialogs.createClinicDialogTitle')}
        </StyledDialogTitle>
        <StyledDialogContent>
          <OvGooglePlacesAutocomplete
            setFormikFields={setFormikFields}
            formik={formik}
          />
          <StyledHeader>{t('clinicDialogs.clinicInputHeader')}</StyledHeader>
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.clinicName')}
            error={formik.touched.clinic_name && !!formik.errors.clinic_name}
            helperText={
              formik.errors.clinic_name &&
              formik.touched.clinic_name &&
              formik.errors.clinic_name
            }
            {...formik.getFieldProps('clinic_name')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            id="clinic_id"
            label={t('common.clinicFields.clinicId')}
            error={formik.touched.clinic_id && !!formik.errors.clinic_id}
            helperText={
              formik.errors.clinic_id &&
              formik.touched.clinic_id &&
              formik.errors.clinic_id
            }
            {...formik.getFieldProps('clinic_id')}
          />
          <StyledLabel>
            {`${t('common.clinicFields.isPatientSharingOn')}?`}
          </StyledLabel>
          <StyledOvRadioGroup
            options={[
              {
                label: t('common.actions.yes'),
                value: true,
              },
              {
                label: t('common.actions.no'),
                value: false,
              },
            ]}
            {...formik.getFieldProps('is_patient_sharing_on')}
            disabled={isLoading}
            onChange={(event, value) => {
              formik.setFieldValue('is_patient_sharing_on', value === 'true');
            }}
          />
          <StyledLabel>
            {`${t('common.clinicFields.isPatientConnectionEnabled')}?`}
          </StyledLabel>
          <StyledOvRadioGroup
            options={[
              {
                label: t('common.actions.yes'),
                value: true,
              },
              {
                label: t('common.actions.no'),
                value: false,
              },
            ]}
            {...formik.getFieldProps(
              'is_automatic_patient_to_clinic_location_connection_enabled'
            )}
            disabled={isLoading}
            onChange={(event, value) => {
              formik.setFieldValue(
                'is_automatic_patient_to_clinic_location_connection_enabled',
                value === 'true'
              );
            }}
          />
          <StyledHeader>
            {t('clinicDialogs.clinicSiteInputHeader')}
          </StyledHeader>
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.clinicSiteName')}
            error={
              formik.touched.clinic_location_name &&
              !!formik.errors.clinic_location_name
            }
            helperText={
              formik.errors.clinic_location_name &&
              formik.touched.clinic_location_name &&
              formik.errors.clinic_location_name
            }
            {...formik.getFieldProps('clinic_location_name')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            id="email"
            disabled={isLoading}
            label={t('common.clinicFields.email')}
            error={formik.touched.email && !!formik.errors.email}
            helperText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
            {...formik.getFieldProps('email')}
          />
          <StyledDisabledTextField
            label={t('common.clinicFields.clinicId')}
            type="text"
            disabled={true}
            {...formik.getFieldProps('clinic_id')}
          />
          <p>-</p>
          <StyledTextFieldWithPrefix
            type="text"
            fullWidth
            autoComplete="off"
            id="clinic_location_id"
            disabled={isLoading}
            label={t('common.clinicFields.clinicSiteId')}
            error={
              formik.touched.clinic_location_id &&
              !!formik.errors.clinic_location_id
            }
            helperText={
              formik.errors.clinic_location_id &&
              formik.touched.clinic_location_id &&
              formik.errors.clinic_location_id
            }
            {...formik.getFieldProps('clinic_location_id')}
          />
          <StyledHalfLengthTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.address-1')}
            {...formik.getFieldProps('address1')}
          />
          <StyledHalfLengthTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.address-2')}
            {...formik.getFieldProps('address2')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.city')}
            {...formik.getFieldProps('city')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.state')}
            {...formik.getFieldProps('state')}
          />
          <StyledTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.zipCode')}
            {...formik.getFieldProps('zip_code')}
          />

          <StyledHalfLengthTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.phone1')}
            {...formik.getFieldProps('phone1')}
          />
          <StyledHalfLengthTextField
            type="text"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            label={t('common.clinicFields.phone2')}
            {...formik.getFieldProps('phone2')}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledLightOvButton onClick={onCancel}>
            {t('common.actions.cancel')}
          </StyledLightOvButton>
          <StyledOvButton disabled={isLoading} type="submit">
            {t('common.actions.save')}
          </StyledOvButton>
        </StyledDialogActions>
        {isLoading && <OvLoadingIndicator />}
      </form>
    </Dialog>
  );
};

export default OvCreateClinicDialog;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 500px !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDisabledTextField = styled(OvTextField)`
  && {
    flex: 1 0 20%;
    height: 100%;
    background-color: ${Colours.OV_DISABLED};
    color: ${Colours.OV_BASE};

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
    }
  }
`;

const StyledTextFieldWithPrefix = styled(OvTextField)`
  && {
    flex: 1 0 60%;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledHalfLengthTextField = styled(OvTextField)`
  && {
    flex: 1 0 40%;

    &:last-child {
      margin-bottom: 1.5rem;
    }

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledOvRadioGroup = styled(OvRadioGroup)`
  && {
    margin-left: 0.125rem;
    flex: 1 0 100%;

    .MuiFormControlLabel-root {
      margin-right: 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }

    .MuiRadio-root {
      color: ${Colours.OV_BASE};
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
`;

const StyledLabel = styled.label`
  margin-left: 0.125rem;
  color: ${Colours.OV_BASE};
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const StyledHeader = styled.h4`
  color: ${Colours.OV_BASE};
  padding-bottom: 0.75rem;
  margin: 0;
`;
