import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdminProductPriceState } from '../states/admin-product-price.state';
import {
  addPriceToProductAndGoToDetails,
  archiveProductPrice,
  getProductPriceById,
  getProductPrices,
  updateProductPriceAndGoToDetails,
} from '../thunks/admin/admin-product-price.thunk';
import { ProductPrice } from '../../common/model/dto/product/product-price';
import { AxiosError } from 'axios';
import { parseAxiosError } from '../../common/utils/helpers';
import { ProductPriceFields } from '../../firebase/document-field.enums';
import { FilterType } from '../../common/model/ui/filter.type';
import { ListPaging } from '../../common/types';
import { isNumber } from 'lodash';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import { FilterModel } from '../../common/model/ui/filter.model';
import ReducerUtils from '../reducer.utils';

const initialState: AdminProductPriceState = {
  isLoading: false,
  selectedProductPrice: null,
  error: '',
  productPriceList: [],
  filters: [
    {
      fieldName: ProductPriceFields.isActive,
      label: 'products.fields.isActive',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
  ],
  showFilters: true,
  productPriceListPaging: {
    total: 0,
    offset: 0,
  },
  productPriceListStoredQuery: '',
};

const adminProductPriceSlice = createSlice({
  name: 'adminProductPrice',
  initialState,
  reducers: {
    clearSelectedProductPrice: (state) => {
      state.selectedProductPrice = null;
    },
    clearError: (state) => {
      state.error = '';
    },
    setProductPriceListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.productPriceListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.productPriceListPaging.offset = action.payload.offset;
      }
    },
    changeProductPriceListFilter: (
      state,
      action: PayloadAction<FilterModel>
    ) => {
      const index = state.filters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      state.filters[index].value = FilterUtils.getParsedFilterValue(
        action.payload.value
      );
      state.productPriceListPaging.offset = 0;
    },
    clearFilters: (state) => {
      state.filters = state.filters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.filters[index].value,
          disabled: false,
        };
      });
    },
    setProductPriceListStoredQuery: (state, action: PayloadAction<string>) => {
      state.productPriceListStoredQuery = action.payload;
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.filters = ReducerUtils.restoreFilters(
        action.payload,
        state.filters
      );
      state.productPriceListPaging.offset = action.payload.offset;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getProductPrices.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        getProductPrices.fulfilled,
        (state, action: PayloadAction<ProductPrice[]>) => {
          state.isLoading = false;
          state.error = '';
          state.productPriceList = action.payload;
        }
      )
      .addCase(
        getProductPrices.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getProductPriceById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getProductPriceById.fulfilled,
        (state, action: PayloadAction<ProductPrice>) => {
          state.selectedProductPrice = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(getProductPriceById.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(addPriceToProductAndGoToDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addPriceToProductAndGoToDetails.fulfilled,
        (state, action: PayloadAction<ProductPrice>) => {
          state.isLoading = false;
          state.error = '';
          state.selectedProductPrice = action.payload;
        }
      )
      .addCase(
        addPriceToProductAndGoToDetails.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateProductPriceAndGoToDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateProductPriceAndGoToDetails.fulfilled,
        (state, action: PayloadAction<ProductPrice>) => {
          state.isLoading = false;
          state.error = '';
          state.selectedProductPrice = action.payload;
        }
      )
      .addCase(
        updateProductPriceAndGoToDetails.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(archiveProductPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        archiveProductPrice.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isLoading = false;
        }
      )
      .addCase(
        archiveProductPrice.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = parseAxiosError(action);
        }
      );
  },
});

export const {
  clearSelectedProductPrice,
  clearError,
  setProductPriceListPaging,
  changeProductPriceListFilter,
  clearFilters,
  setProductPriceListStoredQuery,
  restoreSearchModel,
} = adminProductPriceSlice.actions;
export default adminProductPriceSlice.reducer;
