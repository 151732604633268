import axios from 'axios';
import {
  ADMIN_CLINIC_LOCATION_PATIENT_ROUTE,
  ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE,
  ADMIN_CLINIC_LOCATION_ROUTE,
} from '../../api/backend';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { ClinicLocationProvider } from '../../common/model/dto/clinic-location-provider';
import { UserInfo } from '../../common/model/dto/user-info';
import { ClinicRole } from '../../common/model/type/clinic-role';
import { Filter, Paginated } from '../../common/types';
import { ClinicLocationUtils } from '../../common/utils/services/clinic-location-utils';
import { CreateDemoAccountRequest } from '../../common/model/dto/create-demo-account-request';

export class AdminClinicLocationService {
  public static readonly CLINIC_LOCATION_LIST_LIMIT: number = 5;
  public static readonly CLINIC_LOCATION_USER_LIST_LIMIT: number = 10;

  static async loadClinicLocationsByQuery(query: string) {
    const { data } = await axios.get<Paginated<ClinicLocation>>(
      `${ADMIN_CLINIC_LOCATION_ROUTE}?${query}`
    );

    return data;
  }

  static async getClinicLocationPatients(query: string, id?: string) {
    const { data } = await axios.get<Paginated<UserInfo>>(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/${id}/patients?${query}`
    );

    return data;
  }

  static async getClinicLocationProvidersByQuery(
    query: string,
    clinicLocationId?: string
  ) {
    const { data } = await axios.get<Paginated<UserInfo>>(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/${clinicLocationId}/providers?${query}`
    );

    return data;
  }

  static async createDemoClinicLocation(
    createDemoAccountRequest: CreateDemoAccountRequest
  ): Promise<{ user: UserInfo; clinicLocation: ClinicLocation }> {
    const { data } = await axios.post<{
      user: UserInfo;
      clinicLocation: ClinicLocation;
    }>(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/demo-clinic-locations`,
      createDemoAccountRequest
    );

    return data;
  }

  static async createDemoAccount(
    createDemoAccountRequest: CreateDemoAccountRequest,
    clinicLocationId?: string
  ): Promise<{ user: UserInfo; clinicLocation: ClinicLocation }> {
    const { data } = await axios.post<{
      user: UserInfo;
      clinicLocation: ClinicLocation;
    }>(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/demo-accounts/${clinicLocationId}`,
      createDemoAccountRequest
    );

    return data;
  }

  static async updateClinicLocation(
    clinicLocationId: string,
    updateObj: any
  ): Promise<ClinicLocation> {
    const { data } = await axios.patch<ClinicLocation>(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/${clinicLocationId}`,
      updateObj
    );

    return data;
  }

  static async assignProviderToClinicLocation(id: string, request: any) {
    const { data } = await axios.post(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/${id}/providers`,
      request
    );

    return data;
  }

  static async removeProviderFromClinicLocation(
    clinicLocationId: string,
    providerId: string,
    clinicRole: ClinicRole
  ) {
    const clinicRoleUrlParam =
      ClinicLocationUtils.clinicRoleToUrlParam(clinicRole);

    await axios.delete(
      `${ADMIN_CLINIC_LOCATION_ROUTE}/${clinicLocationId}/providers/${providerId}/${clinicRoleUrlParam}`
    );
  }

  static async approveProviderInvitationForUser(
    clinicLocationId: string,
    userId: string
  ) {
    await axios.patch<ClinicLocationProvider>(
      `${ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE}/clinic-locations/${clinicLocationId}/providers/${userId}/approve`,
      {}
    );
  }

  static async approvePatientInvitationForUser(
    clinicLocationId: string,
    userId: string
  ) {
    await axios.patch(
      `${ADMIN_CLINIC_LOCATION_PATIENT_ROUTE}/clinic-locations/${clinicLocationId}/patients/${userId}/approve`,
      {}
    );
  }

  static async getApproveInvitationToken(clinicLocationProviderId?: string) {
    const { data } = await axios.get<{ approve_invitation_token: string }>(
      `${ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE}/${clinicLocationProviderId}/approve-invitation-token`
    );

    return data;
  }

  static async refreshApproveInvitationToken(
    clinicLocationProviderId?: string
  ) {
    const { data } = await axios.patch<ClinicLocationProvider>(
      `${ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE}/${clinicLocationProviderId}/refresh-approve-invitation-token`
    );

    return data;
  }

  static async deleteClinicLocation(clinicLocationId: string) {
    await axios.delete(`${ADMIN_CLINIC_LOCATION_ROUTE}/${clinicLocationId}`);
  }

  static getQueryParams(filters: Filter[]) {
    const queryParams: any = {};

    filters.forEach((filter) => {
      queryParams[filter.field] = Array.isArray(filter.value)
        ? filter.value.join(',')
        : filter.value;
    });

    return queryParams;
  }
}
