import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import { ToggleButton } from '@mui/material';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import { OrderByDirectionType } from '../../../common/model/type/order-by-direction.type';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export interface OvSortingButtonModel {
  label: string;
  value: any;
  direction: OrderByDirectionType;
  multiDir: boolean;
}

interface OvSortingButtonGroupProps {
  items: OvSortingButtonModel[];
  change?: (value: any) => void;
}

const OvSortingButtonGroup: FC<
  ToggleButtonGroupProps & OvSortingButtonGroupProps
> = ({ items, change, children, ...props }) => {
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();

  const handleChange = (newValue: any) => {
    if (!!newValue) {
      setValue(newValue);

      if (change) {
        change(newValue);
      }
    }
  };

  useEffect(() => {
    let value: string = '';

    items.forEach((item) => {
      if (item.direction) {
        value = item.value;
      }
    });

    setValue(value);
  }, [items]);

  return items.length ? (
    <StyledToggleButtonGroup value={value} {...props}>
      {items.map((item) => (
        <StyledToggleButton
          key={item.value}
          value={item.value}
          onClick={() => handleChange(item.value)}
          aria-label={item.label}
        >
          {t(item.label)}
          {item.multiDir && item.direction === 'asc' && (
            <ArrowUpwardIcon style={{ fontSize: '14px', marginLeft: '6px' }} />
          )}
          {item.multiDir && item.direction === 'desc' && (
            <ArrowDownwardIcon
              style={{ fontSize: '14px', marginLeft: '6px' }}
            />
          )}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  ) : (
    <></>
  );
};

export default OvSortingButtonGroup;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

const StyledToggleButton = styled(ToggleButton)`
  && {
    &.MuiToggleButton-root {
      padding: 0.25rem 0.5rem;
      border: 1px solid ${Colours.OV_PRUSSIAN_BLUE} !important;
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE}!important;
      color: ${Colours.OV_BASE};
      text-transform: none;
      margin-left: 0 !important;
      font-size: 0.875rem;
      background-color: ${Colours.OV_SEMI_LIGHT};
      font-weight: 500;
    }

    &.MuiToggleButton-root:hover {
      background-color: #f4f4f2;
      border-color: ${Colours.OV_BASE};
    }

    &.Mui-selected {
      background: ${Colours.OV_YELLOW};
      color: ${Colours.OV_BASE};
    }

    &.Mui-selected:hover {
      background: ${Colours.OV_YELLOW};
      color: ${Colours.OV_BASE};
    }
  }
`;
