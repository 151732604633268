import React, { useState } from 'react';
import styled from 'styled-components';
import { Article } from '../../../common/model/dto/article';
import OvListTableField from '../atoms/OvListTableField';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from '@mui/material';
import Colours from '../../../design-system/colours';
import StringUtils from '../../../common/utils/services/string-utils';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import DateUtils from '../../../common/utils/services/date-utils';
import { Resource } from '../../../common/model/type/resource.enum';
import useAuthorized from '../../../hooks/use-authorized';

interface OvArticleListRowProps {
  article: Article;
  deleteArticleCallback: (id: string) => void;
}

const OvArticleListRow: React.FC<OvArticleListRowProps> = ({
  article,
  deleteArticleCallback,
}) => {
  let { url } = useRouteMatch();
  const { t } = useTranslation();

  const applicationOperations = useAuthorized(Resource.Application);
  const articleOperations = useAuthorized(Resource.Article);

  const articleDeleteAllowed =
    articleOperations.delete || applicationOperations.supervise;

  const [
    isOpenArticleDeleteConfirmationDialog,
    setOpenArticleDeleteConfirmationDialog,
  ] = useState<boolean>(false);

  const onDelete = () => {
    if (article.id && deleteArticleCallback) {
      setOpenArticleDeleteConfirmationDialog(false);
      deleteArticleCallback(article.id);
    }
  };

  return (
    <>
      <ArticleListTableField>
        <TitleWrapper>
          <ThumbnailImage
            width="64"
            height="50"
            loading="lazy"
            src={article.image_url}
            alt={article.title}
          />
          <Title>{StringUtils.displayValue(article.title)}</Title>
        </TitleWrapper>
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '9rem' }}>
        {article.topic}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '9rem' }}>
        {article.section}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '7rem' }}>
        {article.timing}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '7rem' }}>
        {article.days_in_post_ovulation ?? '-'}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '10rem' }}>
        {DateUtils.formatDate(article.updated_at, 'DD/MM/YYYY HH:mm')}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '7rem' }}>
        {article.color}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '7rem' }}>
        {article.cta}
      </ArticleListTableField>
      <ArticleListTableField style={{ width: '7.5rem' }}>
        <Tooltip title={t('articles.actions.delete')}>
          <StyledIconButton
            onClick={() => setOpenArticleDeleteConfirmationDialog(true)}
            className="actionIcon"
            disabled={!articleDeleteAllowed}
          >
            <DeleteIcon />
          </StyledIconButton>
        </Tooltip>

        <Tooltip title={t('articles.actions.read')}>
          <LinkCta href={article.url} target="_blank" rel="noopener noreferrer">
            <StyledIconButton className="actionIcon">
              <OpenInNewIcon />
            </StyledIconButton>
          </LinkCta>
        </Tooltip>

        <Tooltip title={t('common.actions.seeDetails')}>
          <NavLink to={`${url}/detail/${article.id}`}>
            <StyledIconButton className="actionIcon">
              <VisibilityIcon />
            </StyledIconButton>
          </NavLink>
        </Tooltip>
      </ArticleListTableField>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenArticleDeleteConfirmationDialog}
        onCancel={() => setOpenArticleDeleteConfirmationDialog(false)}
        onConfirm={onDelete}
        description="articles.actions.permanentArticleDelete"
        title="common.areYouSure"
      />
    </>
  );
};

export default OvArticleListRow;

const ArticleListTableField = styled(OvListTableField)`
  vertical-align: middle !important;
  padding: 0.75rem;
`;

const Title = styled.h4`
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

const ThumbnailImage = styled.img`
  max-width: 4rem;
  border-radius: 0.75rem;
  object-fit: cover;
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const LinkCta = styled.a`
  text-decoration: none;
  color: ${Colours.OV_VIVID_BLUE};

  &:hover {
    color: ${Colours.OV_LIGHT_BLUE_HOVER};
    text-decoration: none;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
