import { FilterType } from '../../common/model/ui/filter.type';
import { ProductsState } from '../states/products.state';
import { RootState } from '../store';

const DEFAULT_SORTING_VALUE = 'id';

const hasAppliedFiltersSelector = (state: RootState): boolean => {
  const productState = state.products as ProductsState;

  return productState.filters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

const hasAppliedSortingSelector = (state: RootState): boolean =>
  !!(state.products as ProductsState)?.productListSorting &&
  (state.products as ProductsState)?.productListSorting?.order_by !==
    DEFAULT_SORTING_VALUE;

const shouldFilterProductList = (state: RootState): boolean =>
  hasAppliedSortingSelector(state) || hasAppliedFiltersSelector(state);

export { hasAppliedFiltersSelector, shouldFilterProductList };
