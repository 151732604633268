import { Alert, AlertTitle, Tab, Tabs } from '@mui/material';
import { last } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { Clinic } from '../../common/model/dto/clinic';
import { ClinicDetailsTabType } from '../../common/model/type/clinic-details-tab.type';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearSelectedClinic } from '../../redux/reducers/admin-clinic.slice';
import {
  deleteClinic,
  getClinicById,
} from '../../redux/thunks/admin/admin-clinic.thunk';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OvButton from '../UI/atoms/OvButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';

import ClinicInfo from './ClinicInfo';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';
import OvClinicAuditActivity from '../UI/organisms/OvClinicAuditActivity';

const ClinicDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { clinicId }: any = useParams();
  const { path, url } = useRouteMatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicOperations = useAuthorized(Resource.Clinic);
  const clinicAuditLogActivityOperations = useAuthorized(
    Resource.ClinicAuditLogActivity
  );

  const clinicDeleteAllowed =
    clinicOperations.delete || applicationOperations.supervise;

  const clinicAuditLogActivityReadAllowed =
    clinicAuditLogActivityOperations.read || applicationOperations.supervise;

  const [activeTab, setActiveTab] = useState<ClinicDetailsTabType | null>(null);
  const [isOpenDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);
  const selectedClinic: Clinic | undefined = useAppSelector(
    (state) => state.adminClinic.selectedClinic
  );
  const clinicListStoredQuery: string = useAppSelector(
    (state) => state.adminClinic.clinicListStoredQuery
  );
  const isLoading = useAppSelector((state) => state.adminClinic.isLoading);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const changeTab = (event: any, newValue: ClinicDetailsTabType) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(
        location.pathname.split('/')
      ) as ClinicDetailsTabType;
      const validTabs = Object.values(ClinicDetailsTabType);

      if (validTabs.includes(pathName)) {
        setActiveTab(pathName);
      } else {
        setActiveTab(ClinicDetailsTabType.CLINIC_INFO);
      }
    };

    setActiveTabFromUrl();
  }, [location.pathname]);

  useEffect(() => {
    dispatch(clearSelectedClinic());
    dispatch(getClinicById(clinicId));
  }, [dispatch, clinicId]);

  const onBackClicked = () => {
    if (clinicListStoredQuery) {
      history.push(clinicListStoredQuery);
    } else {
      history.push('/clinics');
    }
  };

  const handleDeleteClinic = () => {
    setOpenDeleteConfirmationDialog(false);

    if (selectedClinic?.id) {
      dispatch(deleteClinic({ clinicId: selectedClinic?.id, history }));
    }
  };

  return (
    <>
      <DetailPageWrapper>
        <DetailsHeader>
          <DetailsWrapper>
            <BackCta onClick={onBackClicked}>
              <StyledArrowBackIcon />
              {t('common.actions.back')}
            </BackCta>

            <ClinicName>{selectedClinic?.name}</ClinicName>
          </DetailsWrapper>

          <CtaWrapper>
            {clinicDeleteAllowed && (
              <StyledOvButton
                onClick={() => setOpenDeleteConfirmationDialog(true)}
              >
                {t('common.actions.delete')}
              </StyledOvButton>
            )}
          </CtaWrapper>
        </DetailsHeader>

        {selectedClinic?.is_demo_clinic && (
          <StyledAlert severity="warning">
            <AlertTitle>{t('clinicDetails.demoAlertTitle')}</AlertTitle>
            {t('clinicDetails.demoAlertDesc')}
          </StyledAlert>
        )}

        <TabContainer>
          <Tabs value={activeTab} onChange={changeTab}>
            <Tab
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
              value={ClinicDetailsTabType.CLINIC_INFO}
              label={t('clinicDetails.tabs.clinicInfo')}
              component={Link}
              to={`${url}/${ClinicDetailsTabType.CLINIC_INFO}`}
            />
            {clinicAuditLogActivityReadAllowed && (
              <Tab
                style={{
                  borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                  textTransform: 'none',
                }}
                value={ClinicDetailsTabType.AUDIT_ACTIVITY}
                label={t('clinicDetails.tabs.auditActivity')}
                component={Link}
                to={`${url}/${ClinicDetailsTabType.AUDIT_ACTIVITY}`}
              />
            )}
          </Tabs>
        </TabContainer>

        <Switch>
          <Route exact path={path}>
            <Redirect to={`${url}/${ClinicDetailsTabType.CLINIC_INFO}`} />
          </Route>
          <Route path={`${path}/clinic-info`}>
            <ClinicInfo selectedClinic={selectedClinic} />
          </Route>
          {selectedClinic && (
            <Route path={`${path}/audit-activity`}>
              <OvClinicAuditActivity clinicId={selectedClinic.id!} />
            </Route>
          )}
        </Switch>
      </DetailPageWrapper>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteConfirmationDialog}
        onCancel={() => setOpenDeleteConfirmationDialog(false)}
        onConfirm={handleDeleteClinic}
        title="clinicDetails.actions.deleteTitle"
        description="clinicDetails.actions.deleteDescription"
      />

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default ClinicDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const DetailsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ClinicName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BackCta = styled(OvButton)`
  && {
    padding: 0 10px 0 4px;
    margin-right: 16px;
    border-radius: ${Variables.borderRadius.XLARGE};
    background-color: ${Colours.OV_BASE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 4px;
  height: 20px;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;
