import { FC } from 'react';
import { FilterModel } from '../../../common/model/ui/filter.model';
import styled from 'styled-components';
import OvSearchFilter from './OvSearchFilter';

interface FiltersComponentProps {
  filters: FilterModel[];
  filterChange?: (filter: FilterModel) => void;
}

const EntitlementListFilters: FC<FiltersComponentProps> = ({
  filters,
  filterChange,
}) => {
  const handleFilterChange = (filter: FilterModel) => {
    if (filterChange) {
      filterChange(filter);
    }
  };

  return (
    <Wrapper>
      {filters.map((filter) => {
        return (
          <OvSearchFilter
            key={filter.fieldName}
            filter={filter}
            applyFilter={handleFilterChange}
          />
        );
      })}
    </Wrapper>
  );
};

export default EntitlementListFilters;

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;
