import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from '../../../design-system/breakpoints';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getDemoAccountPassword } from '../../../redux/thunks/user.thunk';
import { clearDemoAccountPassword } from '../../../redux/reducers/user.slice';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';

interface OvViewPasswordDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  userId: string;
}

const OvViewPasswordDialog: FC<OvViewPasswordDialogProps> = ({
  isOpen,
  onCancel,
  userId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.user.isLoading);
  const demoAccountPassword = useAppSelector(
    (state) => state.user.demoAccountPassword
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(getDemoAccountPassword(userId));
    }

    dispatch(clearDemoAccountPassword());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle>
        {t('userDialogs.demoAccountPassword')}
      </StyledDialogTitle>
      <StyledDialogDescription>
        {t('userDialogs.demoAccountPasswordDescription')}
      </StyledDialogDescription>
      <StyledDialogContent>
        <StyledDialogContentText>{demoAccountPassword}</StyledDialogContentText>
      </StyledDialogContent>
      {isLoading && <OvLoadingIndicator position="fixed" />}
      <StyledDialogActions>
        <StyledLightOvButton onClick={onCancel}>
          {t('common.actions.close')}
        </StyledLightOvButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OvViewPasswordDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 500px !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledDialogDescription = styled(DialogContentText)`
  && {
    max-width: 30rem;
    text-align: center;
    margin: 0 auto 1rem auto;
  }
`;

const StyledDialogContentText = styled(DialogContentText)`
  && {
    max-width: 30rem;
    text-align: center;
    font-weight: bold;
    color: ${Colours.OV_BASE};
    margin: 0 auto 1rem auto;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;
