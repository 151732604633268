import axios from 'axios';
import { DEMO_ACCOUNT_DATA_ROUTE } from '../api/backend';
import { DemoAccountData } from '../common/model/dto/demo-account-data';

export class DemoAccountDataService {
  static async loadDemoAccountDataForUser() {
    const { data } = await axios.get<DemoAccountData[]>(
      `${DEMO_ACCOUNT_DATA_ROUTE}/me`
    );

    return data;
  }

  static async deleteDemoAccountDataForUser() {
    await axios.delete(`${DEMO_ACCOUNT_DATA_ROUTE}`);
  }
}
