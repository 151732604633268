import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const requiredEnvVariables = [
  'REACT_APP_FB_API_KEY',
  'REACT_APP_FB_AUTH_DOMAIN',
  'REACT_APP_FB_DATABASE_URL',
  'REACT_APP_FB_PROJECT_ID',
  'REACT_APP_FB_STORAGE_BUCKET',
  'REACT_APP_FB_MESSAGING_SENDER_ID',
  'REACT_APP_FB_APP_ID',
  'REACT_APP_FB_MEASUREMENT_ID',
  'REACT_APP_API_URL',
  'REACT_APP_SHOPIFY_WEBHOOK_URL',
  'REACT_APP_FILE_SERVICE_URL',
];

requiredEnvVariables.forEach((key) => {
  if (!process.env[key]) {
    throw new Error(`env variable missing: ${key}`);
  }
});

const firebaseOptions: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

requiredEnvVariables.forEach((key) => {
  if (!process.env[key]) {
    throw new Error(`env variable missing: ${key}`);
  }
});

const app = initializeApp(firebaseOptions);

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth, app };
