import React, { FC } from 'react';
import styled from 'styled-components';
import { isArray, isObject, keys } from 'lodash';
import { Timestamp } from 'firebase/firestore';
import DateUtils from '../../../common/utils/services/date-utils';

const OvListDrillDown: FC<{ value: any }> = ({ value }) => {
  function mapObjectToList(object: any): string[] {
    const list: string[] = [],
      oKeys = keys(object);

    oKeys.forEach((key) => {
      list.push(
        `${key}: ${
          object[key] instanceof Timestamp
            ? DateUtils.getDateStrFromTimestamp(
                object[key],
                'dddd, MMMM DD, YYYY, h:mm:ss a'
              )
            : object[key]
        }`
      );
    });

    return list;
  }

  return (
    <TableList>
      {value.map((val: any, index: number) => (
        <li key={index}>
          {isArray(val) ? (
            <OvListDrillDown value={val} />
          ) : isObject(val) ? (
            <OvListDrillDown value={mapObjectToList(val)} />
          ) : (
            val
          )}
        </li>
      ))}
    </TableList>
  );
};

export default OvListDrillDown;

const TableList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-position: inside;
  list-style-type: none;
`;
