import { FilterModel } from '../common/model/ui/filter.model';
import { OvSortingButtonModel } from '../components/UI/molecules/OvSortingButtonGroup';
import { OrderByDirectionType } from '../common/model/type/order-by-direction.type';
import { OrderBy } from '../common/types';

export default class ReducerUtils {
  static restoreFilters(
    filtersToRestore: any,
    availableFilters: FilterModel[]
  ): FilterModel[] {
    return availableFilters.map((filter) => {
      const filterFromUrl: string = filtersToRestore[filter.fieldName];

      if (filterFromUrl) {
        if (filter.min >= 0 && filter.max >= 0) {
          const range = filterFromUrl.split(',');

          filter.value = [parseInt(range[0]), parseInt(range[1])];
        } else {
          filter.value = filterFromUrl;
        }
      }

      return filter;
    });
  }

  static restoreSorting(
    sortingToRestore: { order_dir: OrderByDirectionType; order_by: string },
    availableSortings: OvSortingButtonModel[]
  ): OvSortingButtonModel[] {
    return availableSortings.map((sorting) => {
      const orderBy: string = sortingToRestore.order_by,
        orderDir: OrderByDirectionType = sortingToRestore.order_dir;

      if (sorting.value === orderBy) {
        sorting.direction = orderDir;
      }

      return sorting;
    });
  }

  static restoreOrderBy(sortingToRestore: {
    order_dir: OrderByDirectionType;
    order_by: string;
  }): OrderBy {
    const orderDir =
      sortingToRestore.order_dir !== 'asc' &&
      sortingToRestore.order_dir !== 'desc'
        ? 'none'
        : sortingToRestore.order_dir;

    return {
      order_by: sortingToRestore.order_by,
      order_dir: orderDir,
    };
  }
}
