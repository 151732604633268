import axios from 'axios';
import { ADMIN_JOURNAL_ENTRIES_ROUTE } from '../api/backend';
import { JournalEntry } from '../common/model/dto/journal-entry';

export class JournalEntryService {
  static async createJournalEntry(
    journalEntry: JournalEntry
  ): Promise<JournalEntry> {
    const { data } = await axios.post<JournalEntry>(
      ADMIN_JOURNAL_ENTRIES_ROUTE,
      journalEntry
    );
    return data;
  }

  static async updateJournalEntry(
    id: string,
    updateObj: any
  ): Promise<JournalEntry> {
    const { data } = await axios.patch<JournalEntry>(
      `${ADMIN_JOURNAL_ENTRIES_ROUTE}/${id}`,
      updateObj
    );
    return data;
  }
}
