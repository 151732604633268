import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FilterModel } from '../../../common/model/ui/filter.model';
import { UserInfoFields } from '../../../firebase/document-field.enums';
import {
  loadAllFertilityDisorders,
  loadAllUserRoles,
  loadUsers,
} from '../../../redux/thunks/user.thunk';
import {
  changeUserListFilter,
  clearFilters,
} from '../../../redux/reducers/user.slice';
import { hasAppliedFiltersSelector } from '../../../redux/selectors/user.selectors';
import OvListFilter from './OvListFilter';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

const OvUserSearchFilter: FC = () => {
  const isOpened = useAppSelector((state) => state.user.showFilters);
  const filters: FilterModel[] = useAppSelector((state) => state.user.filters);
  const hasAppliedFilters = useAppSelector(hasAppliedFiltersSelector);
  const dispatch = useAppDispatch();
  const history: History = useHistory();

  useEffect(() => {
    if (!areFertilityDisordersLoaded()) {
      dispatch(loadAllFertilityDisorders());
    }
    if (!areAllUserRolesLoaded()) {
      dispatch(loadAllUserRoles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFilters = () => {
    dispatch(clearFilters());
    dispatch(loadUsers(history));
  };

  const handleFilterValueChange = (filter: FilterModel) => {
    dispatch(changeUserListFilter(filter));
    dispatch(loadUsers(history));
  };

  const areFertilityDisordersLoaded = (): boolean =>
    !!filters.find(
      (filter) =>
        filter.fieldName === UserInfoFields.known_reproductive_disorder
    )?.options?.length;

  const areAllUserRolesLoaded = (): boolean =>
    !!filters.find((filter) => filter.fieldName === UserInfoFields.roles)
      ?.options?.length;

  return (
    <OvListFilter
      filters={filters}
      isOpened={isOpened}
      hasAppliedFilters={hasAppliedFilters}
      onRemoveFilters={removeFilters}
      onHandleFilterValueChange={handleFilterValueChange}
    />
  );
};

export default OvUserSearchFilter;
