import React, { FC, useState } from 'react';
import { ConversionSubmissionEvent } from '../../../common/model/dto/order/conversion-submission-event';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableField from '../atoms/OvListTableField';
import DateUtils from '../../../common/utils/services/date-utils';
import { IconButton, Tooltip } from '@mui/material';
import Colours from '../../../design-system/colours';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ErrorIcon from '@mui/icons-material/Error';
import OvPayloadModal from '../molecules/OvPayloadModal';
import { StyledDataWrapper } from './OvOrderActivityListRow';
import OvImpactConversionSubmissionErrorsModal from '../molecules/OvImpactConversionSubmissionErrorsModal';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { camelCase } from 'lodash';

const OvConversionSubmissionDetails: FC<{
  conversionSubmissionEvent: ConversionSubmissionEvent;
}> = ({ conversionSubmissionEvent }) => {
  const { t } = useTranslation();
  const [isPayloadModalOpen, setPayloadModalOpen] = useState<boolean>(false);
  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState<boolean>(false);

  const headerNames = [
    'orders.conversionSubmission.tableHeaders.impactClickId',
    'orders.conversionSubmission.tableHeaders.createdAt',
    'orders.conversionSubmission.tableHeaders.updatedAt',
    'orders.conversionSubmission.tableHeaders.status',
    'orders.conversionSubmission.tableHeaders.payload',
    'orders.conversionSubmission.tableHeaders.errors',
  ];

  return (
    <WrapperBox>
      <StyledSectionHeader>
        {t('orders.conversionSubmission.sectionTitle')}
      </StyledSectionHeader>

      <OvListTable>
        <OvListTableRow>
          {headerNames.map((headerName) => (
            <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
          ))}
        </OvListTableRow>

        <OvListTableField>
          {conversionSubmissionEvent.payload.ClickId}
        </OvListTableField>

        <OvListTableField>
          {DateUtils.formatDate(
            new Date(conversionSubmissionEvent.created_at ?? ''),
            'MMMM DD, YYYY, HH:mm:ss'
          )}
        </OvListTableField>

        <OvListTableField>
          {DateUtils.formatDate(
            new Date(conversionSubmissionEvent.updated_at ?? ''),
            'MMMM DD, YYYY, HH:mm:ss'
          )}
        </OvListTableField>

        <OvListTableField>
          {t(
            `orders.conversionSubmission.status.${camelCase(
              conversionSubmissionEvent.status
            )}`
          )}
        </OvListTableField>

        <OvListTableField>
          <Tooltip
            title={t('orders.conversionSubmission.tableHeaders.payload')}
          >
            <StyledIconButton
              onClick={() => setPayloadModalOpen(true)}
              className="actionIcon"
            >
              <DataObjectIcon />
            </StyledIconButton>
          </Tooltip>
        </OvListTableField>

        <OvListTableField>
          <Tooltip title={t('orders.conversionSubmission.tableHeaders.errors')}>
            <StyledIconButton
              onClick={() => setIsErrorsModalOpen(true)}
              className="actionIcon"
              disabled={!conversionSubmissionEvent?.errors}
            >
              <ErrorIcon />
            </StyledIconButton>
          </Tooltip>
        </OvListTableField>
      </OvListTable>

      <OvPayloadModal
        isOpen={isPayloadModalOpen}
        onCancel={() => setPayloadModalOpen(false)}
      >
        <StyledDataWrapper>
          <pre>
            {JSON.stringify(conversionSubmissionEvent?.payload, null, 2)}
          </pre>
        </StyledDataWrapper>
      </OvPayloadModal>

      <OvImpactConversionSubmissionErrorsModal
        isOpen={isErrorsModalOpen}
        onCancel={() => setIsErrorsModalOpen(false)}
        errors={conversionSubmissionEvent?.errors}
      />
    </WrapperBox>
  );
};

export default OvConversionSubmissionDetails;

const WrapperBox = styled.div`
  background: white 0 0 no-repeat padding-box;
  border-radius: 0.75rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const StyledSectionHeader = styled.h3`
  margin: 0 0 1rem 0.75rem;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;
    margin-left: 0.25rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;
