import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrderActivity } from '../../../common/model/dto/order/order-activity';
import Variables from '../../../design-system/variables';
import OvListTable from '../atoms/OvListTable';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvListTableRow from '../atoms/OvListTableRow';
import OvOrderActivityListRow from './OvOrderActivityListRow';

const OvOrderActivityList: FC<{ orderActivities: OrderActivity[] }> = ({
  orderActivities,
}) => {
  const { t } = useTranslation();
  const headerNames = [
    'orders.orderActivities.tableHeaders.date',
    'orders.orderActivities.tableHeaders.status',
    'orders.orderActivities.tableHeaders.action',
    'orders.orderActivities.tableHeaders.initiator',
    'orders.orderActivities.tableHeaders.source',
    'orders.orderActivities.tableHeaders.operation',
  ];

  return (
    <WrapperBox>
      <StyledSectionHeader>
        {t('orders.details.activities')}
      </StyledSectionHeader>

      <OvListTable>
        <OvListTableRow>
          {headerNames.map((headerName) => (
            <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
          ))}
        </OvListTableRow>

        {orderActivities.map((orderActivity) => (
          <OvListTableRow key={orderActivity.id}>
            <OvOrderActivityListRow orderActivity={orderActivity} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvOrderActivityList;

const WrapperBox = styled.div`
  background: white 0 0 no-repeat padding-box;
  border-radius: 0.75rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const StyledSectionHeader = styled.h3`
  margin: 0 0 1rem 0.75rem;
`;
