import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export class ErrorUtils {
  static parseAxiosError(action: PayloadAction<AxiosError>): string {
    return (
      (action.payload?.response?.data as any)?.error +
      ': ' +
      JSON.stringify(
        ((action.payload?.response?.data as any)?.message as string[])?.join(
          ','
        )
      )
    );
  }
}
