import { FilterType } from '../../common/model/ui/filter.type';
import { AdminProductPriceState } from '../states/admin-product-price.state';
import { RootState } from '../store';

const hasAppliedFiltersSelector = (state: RootState): boolean => {
  const adminProductPriceState =
    state.adminProductPrice as AdminProductPriceState;

  return adminProductPriceState.filters.some((filter) => {
    if (filter.type === FilterType.slider) {
      const [min, max] = filter.value;

      return min !== filter.min || max !== filter.max;
    }

    return filter.value !== '';
  });
};

export { hasAppliedFiltersSelector };
