import html2canvas from 'html2canvas';

export class DownloadUtils {
  static downloadSvg(svgElement: XMLDocument | SVGElement, fileName: string) {
    let data = new XMLSerializer().serializeToString(svgElement);
    let svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
    let url = URL.createObjectURL(svgBlob);

    DownloadUtils.downloadFile(`${fileName}.svg`, url);
  }

  static async exportElementAsImage(
    element: HTMLElement,
    imageFileName: string
  ): Promise<any> {
    const canvas: HTMLCanvasElement = await html2canvas(element);
    const image = canvas.toDataURL('image/png', 1.0);

    DownloadUtils.downloadFile(imageFileName, image);
  }

  static downloadFile(fileName: string, url: string): void {
    let anchorElement = document.createElement('a');

    anchorElement.setAttribute('download', fileName);
    anchorElement.setAttribute('href', url);
    anchorElement.setAttribute('target', '_blank');

    anchorElement.click();
  }
}
