import { MenuItem, SelectProps } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import OvSelect from '../atoms/OvSelect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const OvHeightPicker: FC<SelectProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const [heightValues, setHeightValues] = useState<number[][]>([]);

  useEffect(() => {
    const values: number[][] = [];

    for (let feet = 1; feet < 9; feet++) {
      for (let inch = 0; inch < 12; inch++) {
        values.push([feet, inch]);
      }
    }

    setHeightValues(values);
  }, []);

  if (heightValues.length) {
    return (
      <OvSelect {...props}>
        <StyledMenuItem value={''}>
          <em>{t('common.actions.notSelected')}</em>
        </StyledMenuItem>
        {heightValues?.length &&
          heightValues.map((value, index) => (
            <StyledMenuItem key={index} value={JSON.stringify(value)}>
              {`${value[0]}' ${value[1]}''`}
            </StyledMenuItem>
          ))}
      </OvSelect>
    );
  }

  return <></>;
};

export default OvHeightPicker;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 6px 16px;
    }
  }
`;
