import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import LinkIcon from '@mui/icons-material/Link';

export interface InfoBoxData {
  value?: string;
  component?: ReactElement;
  key?: string;
  showKeyInInfoBox?: boolean;
  link?: string;
}

const OvOrderInfoBox: FC<{ infoArray: InfoBoxData[] }> = ({ infoArray }) => {
  return (
    <StyledWrapper>
      {infoArray.map((info) =>
        info.component ? (
          <ComponentWrapper>
            {`${info?.showKeyInInfoBox && info.key ? info.key + ': ' : ''}`}
            {info.component}
          </ComponentWrapper>
        ) : info.link ? (
          <StyledText>
            <LinkWrapper>
              {`${info?.showKeyInInfoBox && info.key ? info.key + ': ' : ''}`}

              <ExternalLink key={info.key} target="_blank" href={info.link}>
                <StyledLinkIcon />
                {`${info.value}`}
              </ExternalLink>
            </LinkWrapper>
          </StyledText>
        ) : (
          <StyledText key={info.key}>{`${
            info?.showKeyInInfoBox ? info.key + ':' : ''
          } ${info.value}`}</StyledText>
        )
      )}
    </StyledWrapper>
  );
};

export default OvOrderInfoBox;

const StyledWrapper = styled.div`
  text-align: left;
  padding: 0.75rem;
`;

const StyledText = styled.p`
  margin: 0;
`;

const ExternalLink = styled.a`
  color: ${Colours.OV_BASE};
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledLinkIcon = styled(LinkIcon)`
  && {
    margin-right: 0.25rem;
    margin-left: 0.15rem;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
