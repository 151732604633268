import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OvSortingButtonGroup, {
  OvSortingButtonModel,
} from '../molecules/OvSortingButtonGroup';
import { FilterModel } from '../../../common/model/ui/filter.model';
import OvTextButton from '../atoms/OvTextButton';
import ClearIcon from '@mui/icons-material/Clear';
import OvSearchFilter from './OvSearchFilter';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import breakpoints from '../../../design-system/breakpoints';
import { Drawer, IconButton, List } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export interface ListFilterProps {
  filters?: FilterModel[];
  sortingOptions?: OvSortingButtonModel[];
  isOpened?: boolean;
  hasAppliedFilters?: boolean;
  onRemoveFilters: () => void;
  onHandleSorting?: (sortingValue?: any) => void;
  onHandleFilterValueChange: (filter: FilterModel) => void;
  children?: React.ReactNode;
}

const OvListFilter: FC<ListFilterProps> = ({
  filters = [],
  sortingOptions = [],
  isOpened = false,
  hasAppliedFilters = false,
  onRemoveFilters,
  onHandleSorting,
  onHandleFilterValueChange,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [openedDrawer, setOpenedDrawer] = useState<boolean>(false);

  const toggleDrawer = () => {
    setOpenedDrawer(!openedDrawer);
  };

  const filterElement = (
    <Wrapper>
      <Header>
        {hasAppliedFilters && (
          <CtaContainer>
            <OvTextButton
              onClick={onRemoveFilters}
              style={{
                fontSize: '0.875rem',
                margin: 0,
                padding: 0,
              }}
            >
              {t('common.actions.clearFilters')}
              <StyledClearIcon />
            </OvTextButton>
          </CtaContainer>
        )}
        {sortingOptions?.length && onHandleSorting ? (
          <SortByContainer>
            <SortByTitle>{t('common.sortBy')}</SortByTitle>

            <OvSortingButtonGroup
              items={sortingOptions}
              change={onHandleSorting}
              exclusive
            />
          </SortByContainer>
        ) : (
          ''
        )}
      </Header>

      {isOpened && (
        <FilterGroup>
          {filters.map((filter) => (
            <FilterWrapper key={filter.fieldName}>
              <OvSearchFilter
                filter={filter}
                applyFilter={onHandleFilterValueChange}
              />
            </FilterWrapper>
          ))}
        </FilterGroup>
      )}
    </Wrapper>
  );

  return (
    <>
      <FilterPanelContainer aria-labelledby={t('app.subPages.menu.ariaLabel')}>
        {filterElement}
      </FilterPanelContainer>

      <MobileFilterIcon onClick={toggleDrawer}>
        <FilterListIcon />
      </MobileFilterIcon>

      <MobileFilterDrawer
        variant="temporary"
        anchor="right"
        open={openedDrawer}
        onClose={toggleDrawer}
      >
        {filterElement}
      </MobileFilterDrawer>
    </>
  );
};

export default OvListFilter;

const Wrapper = styled.section`
  width: 19rem;
  padding: 0 1rem;
  align-self: flex-start;
  height: 100%;
  overflow-y: auto;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.lg}) {
    top: 5.25rem;
    position: sticky;
    height: calc(100vh - 5.875rem);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SortByContainer = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${Colours.OV_GRAY};
`;

const SortByTitle = styled.p`
  margin-bottom: 0.25rem;
  margin-top: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-transform: uppercase;
`;

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Colours.OV_GRAY};
`;

const StyledClearIcon = styled(ClearIcon)`
  && {
    margin-left: 0.25rem;
  }
`;

const FilterGroup = styled.div`
  border-radius: ${Variables.borderRadius.SMALL};

  @media (max-width: ${breakpoints.sm}) {
    padding: 0.75rem 1.5rem;
  }

  &:first-child {
  }
`;

const FilterWrapper = styled.article`
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const MobileFilterDrawer = styled(Drawer)`
  && {
    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }

    padding-top: 0;
  }
`;

const MobileFilterIcon = styled(IconButton)`
  && {
    @media (min-width: ${breakpoints.lg}) {
      display: none;
    }

    &.MuiButtonBase-root {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem !important;
      margin: 0.5rem !important;
    }
  }
`;

const FilterPanelContainer = styled(List)`
  && {
    display: none;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: ${breakpoints.lg}) {
      display: block;
    }
  }
`;
