import {
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC } from 'react';

const OvDateTimePicker: FC<DateTimePickerProps<any>> = ({ ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker {...props} />
    </LocalizationProvider>
  );
};

export default OvDateTimePicker;
