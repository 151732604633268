import React, { FC } from 'react';
import {
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import OvChartLabel from '../atoms/OvChartLabel';
import Colours from '../../../design-system/colours';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import {
  PeriodCycle,
  UserScanResult,
} from '../../../common/model/dto/user-specific-baselines';
import { HormoneValueType } from '../../../common/model/type/hormone-values.type';
import { useTranslation } from 'react-i18next';
import OvBaselinesHormoneChartTooltip from '../organisms/OvBaselinesHormoneChartTooltip';

export interface OvBaselinesHormoneChartProps {
  chartLabel: string;
  chartMaxValue: number;
  referenceLineBreakpoints: number[];
  hormoneType: HormoneValueType;
  periodCycles?: PeriodCycle[];
  hormoneScanResults?: UserScanResult[];
  hormoneMax?: number;
}

const OvBaselinesHormoneChart: FC<OvBaselinesHormoneChartProps> = ({
  chartLabel,
  chartMaxValue,
  referenceLineBreakpoints,
  hormoneType,
  periodCycles,
  hormoneScanResults,
  hormoneMax,
}) => {
  const { t } = useTranslation();

  let hormoneSpecificChartProps: {
    datapointColor: string;
    tickStrokeColor: string;
  };

  switch (hormoneType) {
    case HormoneValueType.LH:
      hormoneSpecificChartProps = {
        datapointColor: Colours.LIGHT_LEMON,
        tickStrokeColor: Colours.OV_HIGH_TRYING_TO_CONCEIVE,
      };
      break;

    case HormoneValueType.PG:
      hormoneSpecificChartProps = {
        datapointColor: Colours.BRIGHT_YELLOW,
        tickStrokeColor: Colours.POST_OVULATION,
      };
      break;

    case HormoneValueType.LR_E3G:
      hormoneSpecificChartProps = {
        datapointColor: Colours.OV_LIGHT_GRAY,
        tickStrokeColor: Colours.OV_LIGHT_GRAY,
      };
      break;
  }

  return (
    <ChartWrapper>
      <OvChartLabel style={{ display: 'block', marginBottom: '5px' }}>
        {chartLabel}
      </OvChartLabel>
      <ResponsiveContainer height={400}>
        <LineChart syncId="syncId">
          <XAxis
            dataKey={'day'}
            allowDuplicatedCategory={false}
            tickFormatter={(tick) => format(parseISO(tick), 'MM/dd')}
          />
          <YAxis
            domain={[0, chartMaxValue]}
            ticks={referenceLineBreakpoints}
            tick={{
              fontSize: '12',
              stroke: hormoneSpecificChartProps.tickStrokeColor,
            }}
            tickMargin={5}
          />
          <Tooltip
            isAnimationActive={false}
            content={(props: any) => {
              return (
                <OvBaselinesHormoneChartTooltip
                  active={props.active}
                  payload={props.payload}
                  hormoneType={hormoneType}
                />
              );
            }}
          />
          <Line
            type="linear"
            dataKey="value"
            isAnimationActive={false}
            data={hormoneScanResults}
            stroke={Colours.OV_BASE}
            dot={(props): any => {
              const { cx, cy, payload } = props;

              return (
                payload?.value && (
                  <circle
                    key={`line-dot-${payload.day}`}
                    cx={cx}
                    cy={cy}
                    r={3.5}
                    stroke={Colours.OV_BASE}
                    strokeWidth={1.5}
                    fill={hormoneSpecificChartProps.datapointColor}
                  />
                )
              );
            }}
          />
          {hormoneScanResults?.map((scan_result, i) => (
            <Line
              type="linear"
              dataKey="value"
              strokeOpacity={0}
              isAnimationActive={false}
              data={scan_result.secondary_values}
              name={`scan-result-${hormoneType}-${i}`}
              key={`scan-result-${hormoneType}-${i}`}
              dot={(props): any => {
                const { cx, cy, payload } = props;

                return (
                  <circle
                    key={`scan-result-${hormoneType}-lineDot-${i}-${payload.day}`}
                    cx={cx}
                    cy={cy}
                    r={3.5}
                    fill={Colours.OV_BASE}
                    opacity={0.75}
                  />
                );
              }}
              activeDot={false}
            />
          ))}
          {periodCycles?.map((cycle, index) => {
            return (
              <>
                <ReferenceArea
                  key={index}
                  x1={cycle.start_day}
                  x2={cycle.end_day}
                  fill={Colours.OV_PERIOD_PINK}
                  fillOpacity={'0.2'}
                  stroke={Colours.OV_BASE}
                  strokeOpacity="1"
                  strokeWidth={'0.3'}
                />
                <ReferenceDot
                  x={cycle.cycleBaseLines?.[`${hormoneType}_max`]?.day}
                  y={cycle.cycleBaseLines?.[`${hormoneType}_max`]?.value}
                  stroke={Colours.OV_BASE}
                  strokeWidth={2}
                  r={6.5}
                  fill={hormoneSpecificChartProps.datapointColor}
                />
              </>
            );
          })}
          {referenceLineBreakpoints.map((breakpoint) => (
            <ReferenceLine
              key={breakpoint}
              y={breakpoint}
              stroke={hormoneSpecificChartProps.tickStrokeColor}
              strokeWidth={3}
              strokeDasharray="5 5"
              strokeOpacity={1}
            />
          ))}
          {hormoneMax && hormoneMax > 0 && (
            <ReferenceLine
              y={hormoneMax}
              fill={hormoneSpecificChartProps.tickStrokeColor}
              stroke={Colours.OV_BASE}
              strokeWidth={'0.5'}
              strokeDasharray="5 5"
              label={{
                value: t('userDetails.baselines.hormoneMaxInRange', {
                  hormoneType,
                }).toUpperCase(),
                position: 'insideTop',
                color: Colours.OV_BASE,
                fontSize: '12',
              }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default OvBaselinesHormoneChart;

const ChartWrapper = styled.div`
  border-bottom: 1px solid ${Colours.OV_BASE};
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;
