import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import {
  ClinicLocation,
  ClinicLocationWithAnalytics,
} from '../../../common/model/dto/clinic-location';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import StringUtils from '../../../common/utils/services/string-utils';
import OvButton from '../atoms/OvButton';
import OvListTableField from '../atoms/OvListTableField';
import DateUtils from '../../../common/utils/services/date-utils';

const OvClinicLocationListRow: FC<{
  clinicLocation: ClinicLocation;
}> = ({ clinicLocation }) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  return (
    <>
      <OvListTableField>
        {StringUtils.displayValue(`${clinicLocation?.name || ''}`)}
      </OvListTableField>

      <OvListTableField>
        {StringUtils.displayValue(`${clinicLocation?.email || ''}`)}
      </OvListTableField>

      <OvListTableField>
        {StringUtils.displayValue(`${clinicLocation?.city || ''}`)}
      </OvListTableField>

      <OvListTableField>
        {StringUtils.displayValue(`${clinicLocation?.phone1 || ''}`)}
      </OvListTableField>

      <OvListTableField>
        {(clinicLocation as ClinicLocationWithAnalytics)
          .clinic_location_provider_count === '0' ? (
          <span style={{ color: Colours.OV_RED }}>
            {
              (clinicLocation as ClinicLocationWithAnalytics)
                .clinic_location_provider_count
            }
          </span>
        ) : (
          <>
            {
              (clinicLocation as ClinicLocationWithAnalytics)
                .clinic_location_provider_count
            }
          </>
        )}
      </OvListTableField>

      <OvListTableField>
        {(clinicLocation as ClinicLocationWithAnalytics)
          .clinic_location_patient_count === '0' ? (
          <span style={{ color: Colours.OV_RED }}>
            {
              (clinicLocation as ClinicLocationWithAnalytics)
                .clinic_location_patient_count
            }
          </span>
        ) : (
          <>
            {
              (clinicLocation as ClinicLocationWithAnalytics)
                .clinic_location_patient_count
            }
          </>
        )}
      </OvListTableField>

      <OvListTableField>
        {StringUtils.displayValue(
          `${
            DateUtils.formatDate(
              clinicLocation?.created_at as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            ) || ''
          }`
        )}
      </OvListTableField>

      <OvListTableField style={{ width: '10rem' }}>
        <StyledOvButton>
          <NavLink to={`${url}/${clinicLocation.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledOvButton>
      </OvListTableField>
    </>
  );
};

export default OvClinicLocationListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;
