import { Product } from '../../model/dto/product/product';
import { OvTableData } from '../../../components/UI/molecules/OvTable';
import DateUtils from './date-utils';
import i18n from 'i18next';

export class ProductDataUtils {
  static mapDataForProductDetailsTable(product: Product): OvTableData {
    return [
      {
        label: 'products.fields.title',
        translateLabel: true,
        value: product.title,
      },
      {
        label: 'products.fields.subtitle',
        translateLabel: true,
        value: product.subtitle,
      },
      {
        label: 'products.fields.stripeTitle',
        translateLabel: true,
        value: product.stripe_checkout_page_title,
      },
      {
        label: 'products.fields.stripeDescription',
        translateLabel: true,
        value: product.stripe_checkout_page_description,
      },

      {
        label: 'products.images.stripeProductImage',
        translateLabel: true,
        imageUrl: product.stripe_product_image_url,
        value: product.stripe_product_image_url,
      },
      {
        label: 'products.fields.order',
        translateLabel: true,
        value: product.order,
      },
      {
        label: 'common.updatedAt',
        translateLabel: true,
        value: product?.created_at
          ? DateUtils.formatDate(
              product?.created_at as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : product?.created_at,
      },
      {
        label: 'common.createdAt',
        translateLabel: true,
        value: product?.updated_at
          ? DateUtils.formatDate(
              product?.updated_at as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : product?.updated_at,
      },
      {
        label: 'products.fields.enabledStatus',
        translateLabel: true,
        value: product.is_enabled,
        prettifiedValue: product.is_enabled
          ? i18n.t('common.enabled')
          : i18n.t('common.disabled'),
      },
      {
        label: 'products.fields.isForWeb',
        translateLabel: true,
        value: product.is_enabled_for_web,
        prettifiedValue: product.is_enabled_for_web
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
      },
      {
        label: 'products.fields.isForMobile',
        translateLabel: true,
        value: product.is_enabled_for_mobile,
        prettifiedValue: product.is_enabled_for_mobile
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
      },
    ];
  }
}
