import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FullscriptState } from '../states/fullscript.state';
import {
  getTreatmentPlans,
  removeTreatmentPlan,
  requestFullScriptAccessToken,
  revokeFullScriptAccess,
} from '../thunks/fullscript.thunk';
import { FullScriptOauthAccessToken } from '../../common/model/dto/fullscript/fullscript-oauth-access-token';
import { TreatmentPlanListResponse } from '../../common/model/dto/fullscript/treatment-plan-list-response';
import { AxiosError } from 'axios';

const initialState: FullscriptState = {
  loading: false,
  errorMessage: '',
};

const fullScript = createSlice({
  name: 'fullScript',
  initialState,
  reducers: {
    clearTreatmentPlans: (state) => {
      state.treatmentPlans = [];
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(requestFullScriptAccessToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        requestFullScriptAccessToken.fulfilled,
        (state, action: PayloadAction<FullScriptOauthAccessToken>) => {
          state.loading = false;
          state.fullScriptOauth = action.payload;
        }
      )
      .addCase(
        requestFullScriptAccessToken.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.errorMessage = action.payload?.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(revokeFullScriptAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        revokeFullScriptAccess.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          if (action.payload) {
            state.loading = false;
            state.fullScriptOauth = undefined;
          }
        }
      )
      .addCase(
        revokeFullScriptAccess.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.errorMessage = action.payload?.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getTreatmentPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getTreatmentPlans.fulfilled,
        (state, action: PayloadAction<TreatmentPlanListResponse>) => {
          if (action.payload) {
            state.loading = false;
            state.treatmentPlans = action.payload.treatment_plans;
          }
        }
      )
      .addCase(
        getTreatmentPlans.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.errorMessage = action?.payload?.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(removeTreatmentPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        removeTreatmentPlan.fulfilled,
        (state, action: PayloadAction<string>) => {
          if (action.payload) {
            state.loading = false;
            state.treatmentPlans = state.treatmentPlans?.map((tp) => ({
              ...tp,
              state: tp.id === action.payload ? 'cancelled' : tp.state,
              recommendations:
                tp.id === action.payload ? [] : tp.recommendations,
            }));
          }
        }
      )
      .addCase(
        removeTreatmentPlan.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.errorMessage = action.payload?.message;
        }
      );
  },
});

export const { clearTreatmentPlans } = fullScript.actions;
export default fullScript.reducer;
