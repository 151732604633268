import { createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import OrdersService from '../../services/orders.service';
import { Order } from '../../common/model/dto/order/order';
import { RootState } from '../store';
import { History } from 'history';
import { Paginated } from '../../common/types';
import {
  restoreSearchModel,
  setOrderListPaging,
  setOrderListStoredQuery,
} from '../reducers/orders.slice';
import { parseUrl, stringify } from 'query-string';
import { OrderActivity } from '../../common/model/dto/order/order-activity';
import { hasAppliedFiltersSelector } from '../selectors/orders-selectors';

const loadOrders = createAsyncThunk<Order[], History, { state: RootState }>(
  'orders/findAllBackendOrders',
  async (history: History, { dispatch, getState }) => {
    try {
      let paginatedOrders: Paginated<Order>,
        query = '';

      const { offset } = getState().orders.orderListPaging;
      const filters = getOrderListQueryFilters(getState());

      if (hasAppliedFiltersSelector(getState())) {
        query = stringify({
          limit: OrdersService.ORDER_LIST_LIMIT,
          order_by: 'ordered_at',
          order_dir: 'desc',
          offset,
          ...OrdersService.getQueryParams(filters),
        });

        paginatedOrders = await OrdersService.loadOrdersByQuery(query);
        dispatch(setOrderListStoredQuery(query));
        history.push(`/orders?${query}`);
      } else {
        query = stringify({
          limit: OrdersService.ORDER_LIST_LIMIT,
          order_by: 'ordered_at',
          order_dir: 'desc',
          offset,
        });

        paginatedOrders = await OrdersService.loadOrdersByQuery(query);
        dispatch(setOrderListStoredQuery(offset > 0 ? query : ''));
        history.push(`/orders${offset > 0 ? '?' + query : ''}`);
      }

      setPaging({ ...paginatedOrders, offset }, dispatch);

      return paginatedOrders.docs;
    } catch (error) {
      return [];
    }
  }
);

const findOrderActivitiesById = createAsyncThunk<
  OrderActivity[],
  string | undefined
>('orders/findOrderActivitiesById', async (orderId, { rejectWithValue }) => {
  try {
    const paginatedOrderActivities = await OrdersService.getOrderActivitesById(
      orderId
    );

    return paginatedOrderActivities.docs;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const restoreOrderSearchFromUrl = createAsyncThunk<
  Order[],
  string,
  { state: RootState }
>('orders/findAllBackendOrders', async (query, { dispatch, getState }) => {
  try {
    let paginatedOrders: Paginated<Order>;

    dispatch(setOrderListStoredQuery(query));
    dispatch(restoreSearchModel(parseUrl(query).query));
    paginatedOrders = await OrdersService.loadOrdersByQuery(query.slice(1));
    setPaging(
      {
        ...paginatedOrders,
        offset: getState().orders.orderListPaging.offset,
      },
      dispatch
    );

    return paginatedOrders.docs;
  } catch (error) {
    return [];
  }
});

const findOrderById = createAsyncThunk(
  'orders/findById',
  async (orderId: string, { rejectWithValue }) => {
    try {
      return await OrdersService.getBackendOrderById(orderId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (
    updateObj: {
      orderId?: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      return OrdersService.updateBackendOrder(
        updateObj.updatedProperties,
        updateObj.orderId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const submitOrder = createAsyncThunk(
  'orders/submitOrder',
  async (orderId: string, { rejectWithValue }) => {
    try {
      return OrdersService.submitBackendOrder(orderId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function setPaging(
  paginetedOrders: Paginated<Order>,
  dispatch: ThunkDispatch<any, any, any>
) {
  dispatch(
    setOrderListPaging({
      offset: paginetedOrders.offset || 0,
      total: paginetedOrders.total,
    })
  );
}

function getOrderListQueryFilters(state: RootState): any[] {
  const filters = state?.orders?.filters
    ? state.orders.filters
        .filter(
          (filter: any) => filter.value !== undefined && filter.value !== ''
        )
        .map((filter: any) => ({
          field: filter.fieldName,
          value: filter.value,
        }))
    : [];

  return filters;
}

export {
  loadOrders,
  findOrderById,
  restoreOrderSearchFromUrl,
  findOrderActivitiesById,
  updateOrder,
  submitOrder,
};
