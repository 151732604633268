import React, { FC, ReactComponentElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';

export interface OvConfirmationDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  description?: string;
  descriptionVariable?: any;
  title: string;
  icon?: ReactComponentElement<any>;
}

const OvConfirmationDialog: FC<OvConfirmationDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  description,
  descriptionVariable,
  title,
  icon,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby={t(title)}
      aria-describedby={description ? t(description) : ''}
    >
      {icon ? icon : ''}
      <StyledDialogTitle id="alert-dialog-title">{t(title)}</StyledDialogTitle>
      {description ? (
        <StyledDialogContent>
          <DialogContentText id="alert-dialog-description" textAlign="center">
            {t(description, descriptionVariable)}
          </DialogContentText>
        </StyledDialogContent>
      ) : (
        ''
      )}
      <StyledDialogActions>
        <StyledLightOvButton onClick={onCancel}>
          {t('common.actions.cancel')}
        </StyledLightOvButton>
        <StyledOvButton onClick={onConfirm} autoFocus>
          {t('common.actions.confirm')}
        </StyledOvButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OvConfirmationDialog;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;

    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    max-width: 26rem;
  }
`;
