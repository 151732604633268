import { stringify } from 'query-string';
import { ClinicLocation } from '../../model/dto/clinic-location';
import { UserInfo } from '../../model/dto/user-info';

export class StripeUtils {
  static populatePaymentLinkWithData(
    user: UserInfo,
    clinicLocation: ClinicLocation,
    prefilledEmail: string
  ) {
    const clienReferenceId = `OV1_${user.nano_id}_${clinicLocation.clinic_location_id}`;

    const queryParams = stringify({
      prefilled_email: prefilledEmail,
      client_reference_id: clienReferenceId,
    });

    return queryParams;
  }
}
