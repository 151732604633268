import React, { FC } from 'react';
import styled from 'styled-components';
import { SubPageModel } from '../../common/model/ui/sub-page.model';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import breakpoints from '../../design-system/breakpoints';
import OvSideNav from '../UI/organisms/OvSideNav';
import OvScrollToTop from '../../common/utils/components/OvScrollToTop';
import { Redirect } from 'react-router';

const PageContentTemplate: FC<{ subPages: SubPageModel[] }> = ({
  subPages,
  ...props
}) => {
  let { path } = useRouteMatch();

  return (
    <Container {...props}>
      <OvSideNav
        subPages={subPages
          .filter((page) => page.isVisible && (page.linkLabel || page.linkIcon))
          .reverse()}
      />

      <ContentWrapper>
        <>
          <OvScrollToTop />
          <Switch>
            {subPages
              .filter((page) => page.isVisible)
              .map((page) => {
                if (!page.redirectUrl) {
                  return (
                    <Route
                      exact={!page.url}
                      key={page.url}
                      path={page.url ? `${path}${page.url}` : `${path}`}
                    >
                      {page.component}
                    </Route>
                  );
                } else if (page.redirectUrl) {
                  return (
                    <Redirect
                      key={page.redirectUrl}
                      to={`/${page.redirectUrl}`}
                    />
                  );
                }

                return null;
              })}
          </Switch>
        </>
      </ContentWrapper>
    </Container>
  );
};

export default PageContentTemplate;

const Container = styled.section`
  padding: 0.5rem;
  position: relative;
  z-index: 1;
  top: 68px;

  margin: 0 auto;

  @media (min-width: ${breakpoints.lg}) {
    padding: 1rem 0.5rem;
    display: flex;
    align-items: flex-start;
  }
`;

const ContentWrapper = styled.section`
  flex-grow: 1;
  min-width: 18.75rem;
`;
