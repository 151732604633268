import { Filter } from '../../types';
import { FilterModel } from '../../model/ui/filter.model';
import { FilterType } from '../../model/ui/filter.type';

export class FilterUtils {
  static getQueryParams(filters: Filter[]) {
    const queryParams: any = {};

    filters.forEach((filter) => {
      queryParams[filter.field] = Array.isArray(filter.value)
        ? filter.value.join(',')
        : filter.value;
    });

    return queryParams;
  }

  static getParsedFilterValue(value: any): any {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    }

    return value;
  }

  static mapFilterModelsToQueryParams(filterModels: FilterModel[]): any[] {
    return filterModels
      ? filterModels
          .filter((filter: FilterModel) => {
            if (filter.type === FilterType.slider) {
              const [min, max] = filter.value;

              return min !== filter.min || max !== filter.max;
            }

            return filter.value !== undefined && filter.value !== '';
          })
          .map((filter: FilterModel) => ({
            field: filter.fieldName,
            value: filter.value,
          }))
      : [];
  }
}
