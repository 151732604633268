import { OvTableData } from '../../../components/UI/molecules/OvTable';
import { ScanSequenceFields } from '../../../firebase/document-field.enums';
import i18n from '../../../i18n/config';
import { ScanSequence } from '../../model/dto/scan-sequences/scan-sequence';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import StringUtils from './string-utils';

export class ScanSequenceDataUtils {
  static mapScanSequenceToTable(scanSequence: ScanSequence): OvTableData {
    return [
      {
        label: 'common.scanSequenceFields.healthGoal',
        value: scanSequence?.health_goal,
        translateLabel: true,
      },
      {
        label: 'common.scanSequenceFields.isHealthGoalDefault',
        value: scanSequence?.is_health_goal_default,
        translateLabel: true,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          scanSequence?.is_health_goal_default
        ),
      },
      {
        label: 'common.scanSequenceFields.order',
        value: scanSequence?.order,
        translateLabel: true,
        editOptions: {
          propertyName: ScanSequenceFields.order,
          widgetType: TableRowEditWidgetType.NUMBER,
          placeholder: i18n.t('common.scanSequenceFields.order'),
        },
      },
      {
        label: 'common.scanSequenceFields.scanningPlanType',
        value: scanSequence?.scanning_plan_type,
        translateLabel: true,
        editOptions: {
          propertyName: ScanSequenceFields.scanning_plan_type,
          widgetType: TableRowEditWidgetType.TEXT,
          placeholder: i18n.t('common.scanSequenceFields.scanningPlanType'),
        },
      },
      {
        label: 'common.scanSequenceFields.description',
        value: scanSequence?.description,
        translateLabel: true,
        editOptions: {
          propertyName: ScanSequenceFields.description,
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          placeholder: i18n.t('common.scanSequenceFields.description'),
        },
      },
      {
        label: 'common.scanSequenceFields.enabled',
        value: scanSequence?.is_enabled,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          scanSequence?.is_enabled
        ),
        translateLabel: true,
        editOptions:
          scanSequence?.scanning_plans.length > 0
            ? {
                propertyName: ScanSequenceFields.is_enabled,
                widgetType: TableRowEditWidgetType.BOOLEAN,
                placeholder: i18n.t('common.scanSequenceFields.enabled'),
              }
            : undefined,
      },
      {
        label: 'common.scanSequenceFields.isClinicalMonitoringRecommended',
        value: scanSequence?.is_clinical_monitoring_recommended,
        translateLabel: true,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          scanSequence?.is_clinical_monitoring_recommended
        ),
        editOptions: {
          propertyName: ScanSequenceFields.is_clinical_monitoring_recommended,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
    ];
  }
}
