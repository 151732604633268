import useAxios from 'axios-hooks';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ADMIN_DAILY_DATA_ROUTE,
  ADMIN_USERS_ROUTE,
} from '../../../api/backend';
import {
  BaselineQueryMode,
  UserScanResult,
  UserSpecificBaselines,
} from '../../../common/model/dto/user-specific-baselines';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { UserInfo } from '../../../common/model/dto/user-info';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Colours from '../../../design-system/colours';
import {
  addDays,
  eachDayOfInterval,
  format,
  parseISO,
  subDays,
} from 'date-fns';
import DateUtils from '../../../common/utils/services/date-utils';
import {
  Alert,
  AlertTitle,
  Box,
  Input,
  MenuItem,
  Popover,
  Select,
} from '@mui/material';
import OvChartLabel from '../atoms/OvChartLabel';
import { Constants } from '../../../common/constants';
import OvDateRangePicker from '../molecules/OvDateRangePicker';
import { Range } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import OvDayStatusHighlight from '../atoms/OvDayStatusHighlight';
import OvErrorMessage from '../atoms/OvErrorMessage';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import ChartUtils from '../../../common/utils/services/chart-utils';
import OvTable from '../molecules/OvTable';
import { UserInfoDataUtils } from '../../../common/utils/services/user-info-data-utils';
import OvBaselinesHormoneChart from '../molecules/OvBaselinesHormoneChart';
import { HormoneValueType } from '../../../common/model/type/hormone-values.type';
import OvNoContent from '../molecules/OvNoContent';
import { JournalEntryUtils } from '../../../common/utils/services/journal-entry-utils';
import { DailyData } from '../../../common/model/dto/daily-data';
import { Paginated } from '../../../common/types';
import { CalendarDayStatusUtils } from '../../../common/utils/services/calendar-day-status-utils';
import moment from 'moment';
import OvPeriodCyclesChartCustomTooltip from './OvPeriodCyclesChartCustomTooltip';
import { isEmpty } from 'lodash';
import { KeyboardArrowDown } from '@mui/icons-material';

const OvUserSpecificBaselines: FC<{ selectedUser: UserInfo }> = ({
  selectedUser,
}) => {
  const { t } = useTranslation();
  const [cyclesChartData, setCyclesChartData] = useState<
    { day: string; period_flow: number }[]
  >([]);
  const [selectedBaselineQueryMode, setSelectedBaselineQueryMode] =
    useState<string>(BaselineQueryMode.LastThreeCycles);
  const selectRef = useRef();
  const [cycleRange, setCycleRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [dayFrom, setDayFrom] = useState<string | undefined>(
    DateUtils.getDbDateTag(cycleRange[0].startDate ?? new Date())
  );
  const [dayTo, setDayTo] = useState<string | undefined>(
    DateUtils.getDbDateTag(cycleRange[0].endDate ?? new Date())
  );
  const [calendarStartDay, setCalendarStartDay] = useState(
    DateUtils.getDbDateTag(subDays(cycleRange[0].startDate ?? new Date(), 20))
  );
  const [calendarEndDay, setCalendarEndDay] = useState(
    DateUtils.getDbDateTag(addDays(cycleRange[0].endDate ?? new Date(), 10))
  );
  const [cycleRangePickerAnchorEl, setCycleRangePickerAnchorEl] = useState<
    any | null
  >(null);
  const isCycleRangePickerOpened = Boolean(cycleRangePickerAnchorEl);
  const popoverId = isCycleRangePickerOpened ? 'simple-popover' : undefined;

  const [{ data, loading, error }, execute] = useAxios<UserSpecificBaselines>(
    {
      url: `${ADMIN_USERS_ROUTE}/${selectedUser?.id}/user-specific-baselines`,
      method: 'GET',
      params: {
        day_from:
          selectedBaselineQueryMode === BaselineQueryMode.CustomDateRange
            ? dayFrom
            : undefined,
        day_to:
          selectedBaselineQueryMode === BaselineQueryMode.CustomDateRange
            ? dayTo
            : undefined,
        baseline_query_mode: selectedBaselineQueryMode,
      },
    },
    { useCache: false, manual: true }
  );

  const [{ data: dailyDataInCalendar }] = useAxios<Paginated<DailyData>>(
    {
      url: ADMIN_DAILY_DATA_ROUTE,
      method: 'GET',
      params: {
        limit: 0,
        day_from: calendarStartDay,
        day_to: calendarEndDay,
        user_document_id: selectedUser?.document_id,
      },
    },
    {
      useCache: false,
      manual: !selectedUser?.document_id,
    }
  );

  useEffect(() => {
    if (selectedUser && dayFrom && dayTo) {
      execute()
        .then((res) => {
          const userSpecificBaseline = res.data;
          const rangeStartDate = userSpecificBaseline?.lh_scan_results?.[0].day;
          const rangeEndDate =
            userSpecificBaseline?.lh_scan_results?.[
              userSpecificBaseline?.lh_scan_results?.length - 1
            ].day;

          setCyclesChartData(
            createDataForCyclesChart(rangeStartDate, rangeEndDate, res.data)
          );
        })
        // Do nothing
        .catch(() => {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, dayFrom, dayTo, selectedBaselineQueryMode]);

  const createDataForCyclesChart = (
    dayFrom?: string,
    dayTo?: string,
    data?: UserSpecificBaselines
  ) => {
    const days = [];

    if (!dayTo || !dayFrom) {
      return [];
    }

    const dates = eachDayOfInterval({
      start: new Date(dayFrom),
      end: new Date(dayTo),
    });

    for (const date of dates) {
      const day = DateUtils.getDbDateTag(date);
      const journalEntry = getJournalEntryForDay(day, data);
      days.push({
        day,
        period_flow: JournalEntryUtils.periodFlowToNumeric(
          journalEntry?.period_flow
        ),
      });
    }

    return days;
  };

  const getJournalEntryForDay = (day: string, data?: UserSpecificBaselines) => {
    return data?.period_journal_entries?.find(
      (journalEntry) => journalEntry?.day === day
    );
  };

  const customTooltipFormatter = (value: any, name: any) => {
    if (name === 'period_flow') {
      return JournalEntryUtils.numericToPeriodFlow(value) || 'None';
    }
    return value;
  };

  const handleCloseCycleRangePicker = () => {
    setCycleRangePickerAnchorEl(null);
  };

  const onChangeCycleRange = (selectionRange: Range[]) => {
    setCycleRange(selectionRange);
  };

  const onCalculateBaselines = () => {
    if (cycleRange[0].startDate && cycleRange[0].endDate) {
      setDayFrom(DateUtils.getDbDateTag(cycleRange[0].startDate));
      setDayTo(DateUtils.getDbDateTag(cycleRange[0].endDate));
      setSelectedBaselineQueryMode(BaselineQueryMode.CustomDateRange);
    }

    handleCloseCycleRangePicker();
  };

  const getLhGridBreakpoints = () => {
    const lhMax = selectedUser?.personal_max_lh ?? Constants.LH_MAX;

    const highLhThreshold = Math.ceil(
      ChartUtils.getHormoneThreshold(
        lhMax,
        Constants.LH_LOWEST_FOR_HIGH_PERCENT
      )
    );

    const peakLhThreshold = Math.ceil(
      ChartUtils.getHormoneThreshold(
        lhMax,
        Constants.LH_LOWEST_FOR_PEAK_PERCENT
      )
    );

    return [highLhThreshold, peakLhThreshold, lhMax];
  };

  const getPgGridBreakpoints = () => {
    const pgMax = selectedUser?.personal_max_pg ?? Constants.PG_MAX;

    const postOvulationThreshold = Math.ceil(
      ChartUtils.getHormoneThreshold(
        pgMax,
        Constants.PG_LOWEST_FOR_HIGH_PERCENT
      )
    );

    return [postOvulationThreshold, pgMax];
  };

  const getLrE3gBreakpoints = () => {
    return [Constants.LR_E3G_FERTILE_WINDOW_THRESHOLD, Constants.LR_E3G_MAX];
  };

  const doesHavePeriodJournalEntries = () => {
    if (!data?.period_journal_entries) return false;

    return data?.period_journal_entries?.length > 0;
  };

  const doesHaveScan = (keys: (keyof typeof data)[] | string[]) => {
    if (!data) return false;

    return keys.some((key) => {
      const scanResults = data[
        key as keyof typeof data
      ] as unknown as UserScanResult[];

      return (
        Array.isArray(scanResults) &&
        scanResults.some(
          (scanResult) =>
            scanResult.value !== null && scanResult.value !== undefined
        )
      );
    });
  };

  const getLogIndexFromDate = (calendarDate: Date): number => {
    let dailyDataIndex = -1;

    if (dailyDataInCalendar?.docs.length) {
      dailyDataInCalendar?.docs.forEach((dailyData, index) => {
        const dailyDataDate: Date = moment(
          dailyData?.day,
          'YYYY-MM-DD'
        ).toDate();

        if (DateUtils.areSameDays(dailyDataDate, calendarDate)) {
          dailyDataIndex = index;
        }
      });
    }

    return dailyDataIndex;
  };

  const onShownDateChanged = (date: Date) => {
    const firstVisibleDay = moment(date)
      .startOf('month')
      .subtract(20, 'days') // 7days needed because of the layout of the calendar, and another 10 day to count post ovulation days
      .toDate();
    const lastVisibleDay = moment(date)
      .add(1, 'month')
      .endOf('month')
      .add(7, 'days')
      .toDate();

    setCalendarStartDay(DateUtils.getDbDateTag(firstVisibleDay));
    setCalendarEndDay(DateUtils.getDbDateTag(lastVisibleDay));
  };

  return (
    <>
      {!loading && selectedUser && (
        <Header>
          <StyledSelectWrapper>
            <Select
              ref={selectRef}
              value={selectedBaselineQueryMode}
              onChange={(e) => {
                if (e.target.value !== BaselineQueryMode.CustomDateRange) {
                  setSelectedBaselineQueryMode(e.target.value as string);
                }
              }}
              input={<Input disableUnderline fullWidth color="secondary" />}
              IconComponent={KeyboardArrowDown}
              renderValue={() => (
                <Box ml={2} mr={1}>
                  <SelectionText>{`${
                    data?.lh_scan_results?.[0].day ??
                    DateUtils.getDbDateTag(cycleRange[0]?.startDate)
                  } - ${
                    data?.lh_scan_results?.[cyclesChartData.length - 1].day ??
                    DateUtils.getDbDateTag(cycleRange[0]?.endDate)
                  }`}</SelectionText>
                </Box>
              )}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              }}
            >
              <StyledMenuItem value={BaselineQueryMode.LastThreeCycles}>
                <SelectionText>
                  {t('userDetails.baselines.rangeSelector.lastXCycles', {
                    numOfCycles: 3,
                  })}
                </SelectionText>
              </StyledMenuItem>
              <StyledMenuItem value={BaselineQueryMode.LastTwoCycles}>
                <SelectionText>
                  {t('userDetails.baselines.rangeSelector.lastXCycles', {
                    numOfCycles: 2,
                  })}
                </SelectionText>
              </StyledMenuItem>
              <StyledMenuItem value={BaselineQueryMode.LastCycle}>
                <SelectionText>
                  {t('userDetails.baselines.rangeSelector.lastCycle')}
                </SelectionText>
              </StyledMenuItem>
              <StyledMenuItem
                value={BaselineQueryMode.CustomDateRange}
                onClick={() => {
                  setCycleRangePickerAnchorEl(selectRef.current);
                  return;
                }}
              >
                <SelectionText>{t('dashboard.customDateRange')}</SelectionText>
              </StyledMenuItem>
            </Select>
            <StyledPopover
              id={popoverId}
              open={isCycleRangePickerOpened}
              anchorEl={cycleRangePickerAnchorEl}
              onClose={handleCloseCycleRangePicker}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <OvDateRangePicker
                ranges={cycleRange}
                onChange={(item) => onChangeCycleRange([item.selection])}
                onShownDateChange={(date) => onShownDateChanged(date)}
                showDateDisplay={false}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                preventSnapRefocus={true}
                showPreview={true}
                showMonthAndYearPickers={false}
                rangeColors={['#dfdfdf']}
                dayContentRenderer={(date) => (
                  <OvDayStatusHighlight
                    tryingToConceiveStatus={CalendarDayStatusUtils.calculateDayStatus(
                      dailyDataInCalendar?.docs ?? [],
                      getLogIndexFromDate(date)
                    )}
                  >
                    {date.getDate()}
                  </OvDayStatusHighlight>
                )}
              />
              <StyledButton
                disabled={!cycleRange[0]?.startDate || !cycleRange[0]?.endDate}
                onClick={onCalculateBaselines}
              >
                {t('userDetails.baselines.actions.calculateBaselines')}
              </StyledButton>
            </StyledPopover>
          </StyledSelectWrapper>
          <StyledAlert severity="info">
            <AlertTitle>
              {t('userDetails.baselines.infoBoxTitle', {
                healthGoal: selectedUser?.health_goal?.toLowerCase(),
              })}
            </AlertTitle>
            <StyledAlertContent>
              {t('userDetails.baselines.infoBoxDescription', {
                minDays: 10,
                maxDays:
                  selectedUser?.health_goal?.toLowerCase() ===
                  'perimenopause tracking'
                    ? 150
                    : 50,
              })}
            </StyledAlertContent>
          </StyledAlert>
        </Header>
      )}

      {!loading && !error && data && cyclesChartData && selectedUser && (
        <>
          <Box pb={2}>
            {data.period_cycles && data?.period_cycles?.length > 0 && (
              <StyledTableWrapper>
                <OvChartLabel
                  style={{ display: 'block', marginBottom: '10px' }}
                >
                  {t('userDetails.baselines.detectedCycles')}
                </OvChartLabel>
                <OvTable
                  data={UserInfoDataUtils.mapUserSpecificBaseLinesToTableDataFormat(
                    data
                  )}
                />
              </StyledTableWrapper>
            )}

            {data.aggregate_hormone_data && (
              <StyledTableWrapper>
                <OvChartLabel
                  style={{ display: 'block', marginBottom: '10px' }}
                >
                  {t('userDetails.baselines.aggregateHormoneDataTitle')}
                </OvChartLabel>
                <OvTable
                  data={UserInfoDataUtils.mapAggregateHormoneDataToTableDataFormat(
                    data
                  )}
                />
              </StyledTableWrapper>
            )}

            {!loading && !isEmpty(data) && (
              <>
                <ChartWrapper>
                  <OvChartLabel
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('userDetails.baselines.periodCycles')}
                  </OvChartLabel>
                  <ResponsiveContainer height={400}>
                    <BarChart data={cyclesChartData} syncId="syncId">
                      <CartesianGrid vertical={false} strokeDasharray="5 5" />
                      <YAxis
                        tickFormatter={(tick) =>
                          JournalEntryUtils.numericToPeriodFlow(tick) || ''
                        }
                        domain={[0, 4]}
                        tick={{ fontSize: 12 }}
                      />
                      <XAxis
                        dataKey="day"
                        tickFormatter={(tick) =>
                          format(parseISO(tick), 'MM/dd')
                        }
                      />
                      <Tooltip
                        formatter={(value, name) =>
                          customTooltipFormatter(value, name)
                        }
                        labelFormatter={(label) =>
                          format(parseISO(label), 'MM/dd/yyyy')
                        }
                        isAnimationActive={false}
                        content={(props: any) => {
                          return (
                            <OvPeriodCyclesChartCustomTooltip
                              active={props.active}
                              payload={props.payload}
                            />
                          );
                        }}
                      />

                      <Bar
                        dataKey="period_flow"
                        barSize={20}
                        fill={Colours.OV_GREEN}
                        isAnimationActive={false}
                      />

                      {data &&
                        data.period_cycles &&
                        data?.period_cycles.map((cycle, index) => {
                          return (
                            <ReferenceArea
                              key={index}
                              x1={cycle.start_day}
                              x2={cycle.end_day}
                              fill={Colours.OV_PERIOD_PINK}
                              fillOpacity={'0.2'}
                              stroke={Colours.OV_BASE}
                              strokeOpacity="1"
                              strokeWidth={'0.3'}
                            />
                          );
                        })}
                    </BarChart>
                  </ResponsiveContainer>
                </ChartWrapper>

                <OvBaselinesHormoneChart
                  chartLabel={t('userDetails.baselines.lhScans')}
                  chartMaxValue={Constants.LH_MAX + 10}
                  referenceLineBreakpoints={getLhGridBreakpoints()}
                  hormoneType={HormoneValueType.LH}
                  periodCycles={data?.period_cycles}
                  hormoneScanResults={data?.lh_scan_results}
                  hormoneMax={data?.aggregate_hormone_data?.max_lh}
                />
                <OvBaselinesHormoneChart
                  chartLabel={t('userDetails.baselines.pgScans')}
                  chartMaxValue={Constants.PG_MAX + 5}
                  referenceLineBreakpoints={getPgGridBreakpoints()}
                  hormoneType={HormoneValueType.PG}
                  periodCycles={data?.period_cycles}
                  hormoneScanResults={data?.pg_scan_results}
                  hormoneMax={data?.aggregate_hormone_data?.max_pg}
                />
                <OvBaselinesHormoneChart
                  chartLabel={t('userDetails.baselines.lre3gScans')}
                  chartMaxValue={Constants.LR_E3G_CHART_MAX}
                  referenceLineBreakpoints={getLrE3gBreakpoints()}
                  hormoneType={HormoneValueType.LR_E3G}
                  periodCycles={data?.period_cycles}
                  hormoneScanResults={data?.lr_e3g_scan_results}
                  hormoneMax={data?.aggregate_hormone_data?.max_lr_e3g}
                />
              </>
            )}
          </Box>
        </>
      )}
      {loading && <OvLoadingIndicator position="fixed" />}
      {error && <OvErrorMessage message={error.message} />}
      {!doesHaveScan([
        'lh_scan_results',
        'pg_scan_results',
        'lr_e3g_scan_results',
      ]) &&
        !doesHavePeriodJournalEntries() &&
        !dayFrom &&
        !dayTo && (
          <OvNoContent>
            {t('userDetails.baselines.actions.noDataFound')}
          </OvNoContent>
        )}

      {!loading && isEmpty(data) && selectedUser && (
        <OvNoContent>
          {t('userDetails.baselines.couldNotDetectAnyCyclesInRange')}
        </OvNoContent>
      )}
    </>
  );
};

export default OvUserSpecificBaselines;

const Header = styled.header`
  box-shadow: ${Variables.boxShadow.defaultBox};
  border-radius: ${Variables.borderRadius.SMALL};
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: fit-content;
  margin-bottom: 2rem;
`;

const StyledSelectWrapper = styled.div`
  width: 30%;
  background-color: ${Colours.WHITE};
  border: 1px solid ${Colours.OV_BASE};
  border-radius: ${Variables.borderRadius.SMALL};
`;

const ChartWrapper = styled.div`
  border-bottom: 1px solid ${Colours.OV_BASE};
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledPopover = styled(Popover)`
  && {
    .MuiPopover-paper {
      border-radius: 0.75rem;
      border: 1px solid ${Colours.OV_BASE};
      display: flex;
      flex-direction: column;
    }
  }
`;

const StyledButton = styled(OvButton)`
  && {
    border-radius: 1.125rem;
    text-transform: none;
    padding: 0.25rem 0.75rem;
    margin: 0.5rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    color: ${Colours.OV_BASE};
    background-color: ${Colours.OV_WHITE};
    &:hover {
      opacity: 0.9;
    }
  }
`;

const StyledTableWrapper = styled.div`
  margin-bottom: 1rem;
`;

const SelectionText = styled.p`
  font-weight: bold;
  color: ${Colours.OV_BASE};
  font-size: ${Variables.fontSizes.MEDIUM};
  margin: 0.5rem 0;
  font-family: 'CentraNo2-Book', 'CentraNo2', Roboto, sans-serif;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_BORDER_COLOR_V3};
    color: ${Colours.OV_BASE};
    gap: 2rem;
    width: 100%;

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;

const StyledAlertContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
