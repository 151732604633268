import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import OvTable from '../molecules/OvTable';
import breakpoints from '../../../design-system/breakpoints';
import {
  updateOwnClinicLocationProviderData,
  updateOwnUserInfo,
} from '../../../redux/thunks/user.thunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { UserInfoDataUtils } from '../../../common/utils/services/user-info-data-utils';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import OvSelect from '../atoms/OvSelect';
import { MenuItem } from '@mui/material';
import { USER_PROFILE_IMAGES_PATH } from '../../../api/firebase-storage';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvLinkFullScript from '../molecules/OvLinkFullScript';
import OvEditableAvatar from '../molecules/OvEditableAvatar';
import PersonIcon from '@mui/icons-material/Person';
import {
  deleteDemoAccountDataForCurrentUser,
  loadDemoAccountDataForUser,
} from '../../../redux/thunks/demo-account-data.thunk';
import { DemoAccountData } from '../../../common/model/dto/demo-account-data';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import OvButton from '../atoms/OvButton';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';

const OvProfileUserInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectClinicLocationValue, setSelectClinicLocationValue] = useState<
    string | undefined
  >('');
  const [isOpenConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const currentUser = useAppSelector<UserInfo | undefined>(
    (state) => state.user.currentUser
  );
  const isUserLoading = useAppSelector<boolean>(
    (state) => state.user.isLoading
  );
  const isDemoAccountDataLoading = useAppSelector<boolean>(
    (state) => state.demoAccountData.isLoading
  );
  const demoAccountDataList = useAppSelector<DemoAccountData[]>(
    (state) => state.demoAccountData.demoAccountDataList
  );

  const isLoading = isUserLoading || isDemoAccountDataLoading;

  useEffect(() => {
    if (currentUser && currentUser?.provider_clinic_locations.length > 0) {
      setSelectClinicLocationValue(
        currentUser?.provider_clinic_locations.length > 0
          ? currentUser.provider_clinic_locations[0].id
          : undefined
      );
    }

    dispatch(loadDemoAccountDataForUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserInfoUpdate = (updatedProperties: any) => {
    dispatch(updateOwnUserInfo(updatedProperties));
  };

  const handleProviderUserInfoUpdate = (updatedProperties: any) => {
    if (selectClinicLocationValue) {
      const updateObj = {
        clinicLocationProviderId: selectClinicLocationValue,
        updatedProperties: {
          ...updatedProperties,
        },
      };

      dispatch(updateOwnClinicLocationProviderData(updateObj));
    }
  };

  const onDelete = () => {
    setOpenConfirmationDialog(false);
    dispatch(deleteDemoAccountDataForCurrentUser(currentUser));
  };

  return (
    <DetailPageWrapper>
      <InfoContainer>
        <TopContainer>
          <OvEditableAvatar
            entity={currentUser}
            handleUpdate={handleUserInfoUpdate}
            imageBasePath={USER_PROFILE_IMAGES_PATH}
            dialogTitle={t('userDialogs.userImageUploadTitle')}
            defaultImageComponent={<ProfileImage />}
          />
          <GeneralInfoContainer>
            <StyledSectionHeader>
              {t('patientDetails.generalInfo')}
            </StyledSectionHeader>
            <TableWrapper>
              <OvTable
                data={UserInfoDataUtils.mapGeneralProviderData(currentUser)}
                onSaveRowValue={handleUserInfoUpdate}
              />
            </TableWrapper>
          </GeneralInfoContainer>
        </TopContainer>
        <BoxContainer>
          <ProviderInfo>
            {currentUser && currentUser?.provider_clinic_locations.length > 0 && (
              <>
                <StyledSelectHeaderWrapper>
                  <StyledSectionHeader>
                    {t('common.userFields.providerData.providerInfo')}
                  </StyledSectionHeader>
                  <StyledSelect
                    labelId="clinicLocationSelect"
                    value={selectClinicLocationValue}
                    onChange={(event) => {
                      setSelectClinicLocationValue(
                        event.target.value as string
                      );
                    }}
                  >
                    {currentUser.provider_clinic_locations.map(
                      (providerClinicLocation) => (
                        <MenuItem
                          key={providerClinicLocation.id}
                          value={providerClinicLocation.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '5px',
                          }}
                        >
                          {providerClinicLocation.clinic_location.name}
                        </MenuItem>
                      )
                    )}
                  </StyledSelect>
                </StyledSelectHeaderWrapper>
                <OvTable
                  data={UserInfoDataUtils.mapProviderInfoToTableDataFormat(
                    currentUser,
                    selectClinicLocationValue
                  )}
                  onSaveRowValue={handleProviderUserInfoUpdate}
                />
              </>
            )}
          </ProviderInfo>
        </BoxContainer>
        <BoxContainer>
          <StyledSectionHeader>{t('common.integrations')}</StyledSectionHeader>
          <IntegrationWrapper>
            <OvLinkFullScript />
          </IntegrationWrapper>
        </BoxContainer>
        <StyledOvButton
          disabled={demoAccountDataList.length === 0}
          onClick={() => setOpenConfirmationDialog(true)}
        >
          <DeleteSweepIcon style={{ marginRight: '0.5rem' }} />
          {t('common.demoAccounts.deleteDemoAccountsCta')}
        </StyledOvButton>
      </InfoContainer>
      {isLoading && <OvLoadingIndicator position={'fixed'} />}

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenConfirmationDialog}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirm={onDelete}
        description="common.demoAccounts.deleteDemoAccountsDialogDescription"
        title="common.demoAccounts.deleteConfirmationDialogTitle"
      />
    </DetailPageWrapper>
  );
};

export default OvProfileUserInfo;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const BoxContainer = styled.div`
  width: 100%;
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  padding: 1rem;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0.75rem;
  }
`;

const ProviderInfo = styled.div``;

const StyledSectionHeader = styled.h3`
  margin: 0 0 0 0.5rem;
`;

const ProfileImage = styled(PersonIcon)`
  && {
    width: 12rem !important;
    height: 12rem !important;
    border: 0.375rem solid;
    border-radius: 50%;
    margin-bottom: 0.25rem;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const GeneralInfoContainer = styled.div`
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
`;

const StyledSelectHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(OvSelect)`
  && {
    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      margin-left: 1rem;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_LIGHT_GRAY};
      }
    }
  }
`;

const IntegrationWrapper = styled.article`
  margin-top: 0.5rem;
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
    padding: 0.5rem 0.5rem;
    background-color: ${Colours.OV_BASE};
    margin: 1rem 0;
    max-width: 25%;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      margin: 0 0.75rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledDeleteIcon = styled(DeleteSweepIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
