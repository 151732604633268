import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdminScanSequenceState } from '../states/admin-scan-sequence.state';
import { ListPaging } from '../../common/types';
import { isNumber } from 'lodash';
import {
  addScanningPlanToScanSequence,
  createScanSequence,
  deleteScanSequence,
  deleteScanningPlanFromScanSequence,
  duplicateScanSequence,
  getScanSequenceById,
  loadScanSequences,
  loadScanSequencesByQuery,
  updateScanSequence,
} from '../thunks/admin/admin-scan-sequences.thunk';
import { ScanSequence } from '../../common/model/dto/scan-sequences/scan-sequence';
import { AxiosError } from 'axios';

const initialState: AdminScanSequenceState = {
  scanSequenceList: [],
  filteredScanSequences: [],
  scanSequenceListPaging: {
    total: 0,
    offset: 0,
  },
  isLoading: false,
  selectedScanSequence: null,
  scanSequenceListStoredQuery: '',
  error: '',
};

const adminScanSequenceSlice = createSlice({
  name: 'adminScanSequence',
  initialState,
  reducers: {
    setScanSequenceListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.scanSequenceListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.scanSequenceListPaging.offset = action.payload.offset;
      }
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.scanSequenceListPaging.offset = action.payload.offset;
    },
    setScanSequenceListStoredQuery: (state, action: PayloadAction<string>) => {
      state.scanSequenceListStoredQuery = action.payload;
    },
    clearScanSequenceList: (state) => {
      state.scanSequenceList = [];
    },
    clearFilteredScanSequences: (state) => {
      state.filteredScanSequences = [];
    },
    clearSelectedScanSequence: (state) => {
      state.selectedScanSequence = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadScanSequences.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        loadScanSequences.fulfilled,
        (state, action: PayloadAction<ScanSequence[]>) => {
          state.scanSequenceList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadScanSequencesByQuery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadScanSequencesByQuery.fulfilled,
        (state, action: PayloadAction<ScanSequence[]>) => {
          state.filteredScanSequences = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(loadScanSequencesByQuery.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getScanSequenceById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getScanSequenceById.fulfilled,
        (state, action: PayloadAction<ScanSequence>) => {
          state.selectedScanSequence = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(getScanSequenceById.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createScanSequence.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        createScanSequence.fulfilled,
        (state, action: PayloadAction<ScanSequence>) => {
          state.isLoading = false;

          if (state.scanSequenceList) {
            state.scanSequenceList = [
              action.payload,
              ...state.scanSequenceList,
            ];
          } else {
            state.scanSequenceList = [action.payload];
          }
        }
      )
      .addCase(
        createScanSequence.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(duplicateScanSequence.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        duplicateScanSequence.fulfilled,
        (state, action: PayloadAction<ScanSequence>) => {
          state.isLoading = false;

          if (state.scanSequenceList) {
            state.scanSequenceList = [
              action.payload,
              ...state.scanSequenceList,
            ];
          } else {
            state.scanSequenceList = [action.payload];
          }
        }
      )
      .addCase(
        duplicateScanSequence.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateScanSequence.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateScanSequence.fulfilled,
        (state, action: PayloadAction<ScanSequence>) => {
          state.selectedScanSequence = action.payload;

          state.isLoading = false;
        }
      )
      .addCase(updateScanSequence.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(addScanningPlanToScanSequence.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        addScanningPlanToScanSequence.fulfilled,
        (state, action: PayloadAction<ScanSequence>) => {
          state.isLoading = false;

          state.scanSequenceList = state.scanSequenceList.map(
            (scanSequence) => {
              if (scanSequence.id === action.payload.id) {
                return {
                  ...scanSequence,
                  ...action.payload,
                };
              }

              return scanSequence;
            }
          );

          state.selectedScanSequence = action.payload;
        }
      )
      .addCase(
        addScanningPlanToScanSequence.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteScanningPlanFromScanSequence.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        deleteScanningPlanFromScanSequence.fulfilled,
        (state, action: PayloadAction<ScanSequence>) => {
          state.isLoading = false;

          state.scanSequenceList = state.scanSequenceList.map(
            (scanSequence) => {
              if (scanSequence.id === action.payload.id) {
                return {
                  ...scanSequence,
                  ...action.payload,
                };
              }

              return scanSequence;
            }
          );

          state.selectedScanSequence = action.payload;
        }
      )
      .addCase(
        deleteScanningPlanFromScanSequence.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteScanSequence.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteScanSequence.fulfilled, (state) => {
        state.scanSequenceList = [];
        state.isLoading = false;
      })
      .addCase(deleteScanSequence.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setScanSequenceListPaging,
  restoreSearchModel,
  setScanSequenceListStoredQuery,
  clearScanSequenceList,
  clearSelectedScanSequence,
} = adminScanSequenceSlice.actions;
export default adminScanSequenceSlice.reducer;
