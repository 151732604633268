import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConfigurationState } from '../states/configuration.state';
import { getProviderDefaultStripeLink } from '../thunks/configuration.thunk';
import { AxiosError } from 'axios';

const initialState: ConfigurationState = {
  isLoading: false,
  providerDefaultStripeLink: undefined,
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getProviderDefaultStripeLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getProviderDefaultStripeLink.fulfilled,
        (
          state,
          action: PayloadAction<{
            provider_default_stripe_link: string;
          }>
        ) => {
          state.isLoading = false;
          state.providerDefaultStripeLink =
            action.payload.provider_default_stripe_link;
        }
      )
      .addCase(
        getProviderDefaultStripeLink.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
        }
      );
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = configurationSlice.actions;
export default configurationSlice.reducer;
