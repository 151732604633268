import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import { UserInfoDataUtils } from '../../../common/utils/services/user-info-data-utils';
import breakpoints from '../../../design-system/breakpoints';
import Variables from '../../../design-system/variables';
import OvTable from '../molecules/OvTable';

const OvPatientInfo: FC<{ selectedPatient?: UserInfo }> = ({
  selectedPatient,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <LeftSideContainer>
        <GeneralInfoContainer>
          <StyledSectionHeader>
            {t('patientDetails.generalInfo')}
          </StyledSectionHeader>
          <TableWrapper>
            <OvTable
              data={UserInfoDataUtils.mapGeneralPatientInfoToTableDataFormat(
                selectedPatient
              )}
            />
          </TableWrapper>
        </GeneralInfoContainer>
      </LeftSideContainer>
      <HealthInfoContainer>
        <StyledSectionHeader>
          {t('patientDetails.healthInfo')}
        </StyledSectionHeader>
        <TableWrapper>
          <OvTable
            data={UserInfoDataUtils.mapUserHealthInfoToTableDataFormat(
              selectedPatient
            )}
          />
        </TableWrapper>
      </HealthInfoContainer>
    </Container>
  );
};

export default OvPatientInfo;

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const LeftSideContainer = styled.div`
  flex-grow: 1;
  padding-right: 1rem;
`;

const GeneralInfoContainer = styled.div`
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  padding: 1rem;
  margin-bottom: 0.75rem;
`;

const HealthInfoContainer = styled.div`
  flex-grow: 2;
  padding: 0 1rem;
  box-shadow: ${Variables.boxShadow.alternateBox};
  border-radius: ${Variables.borderRadius.LARGE};
  padding: 1rem;
`;

const StyledSectionHeader = styled.h3`
  margin: 0 0 0 0.5rem;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 0.75rem;
  }
`;
