import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createArticle,
  deleteArticle,
  getArticleById,
  getArticles,
  updateArticle,
} from '../thunks/articles.thunk';
import { AxiosError } from 'axios';
import isNumber from 'lodash/isNumber';
import { ListPaging, OrderBy, Paginated } from '../../common/types';
import { ArticlesState } from '../states/articles.state';
import { Article } from '../../common/model/dto/article';
import { ArticleFields } from '../../firebase/document-field.enums';
import { FilterType } from '../../common/model/ui/filter.type';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import { ErrorUtils } from '../../common/utils/services/error-utils';

const initialState: ArticlesState = {
  loading: false,
  error: '',
  articleList: [],
  articleListPaging: {
    total: 0,
    offset: 0,
  },
  articleListSorting: null,
  articleListFilters: [
    {
      fieldName: ArticleFields.title,
      placeholder: 'articles.fields.title',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ArticleFields.topic,
      placeholder: 'articles.fields.topic',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ArticleFields.section,
      placeholder: 'articles.fields.section',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: ArticleFields.color,
      placeholder: 'articles.fields.color',
      type: FilterType.text,
      value: '',
    },
  ],
  selectedArticle: null,
};

const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setArticleListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.articleListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.articleListPaging.offset = action.payload.offset;
      }
    },
    setArticleListSorting: (state, action: PayloadAction<OrderBy>) => {
      state.articleListSorting = action.payload;
    },
    changeArticleListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.articleListFilters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      state.articleListFilters[index].value = FilterUtils.getParsedFilterValue(
        action.payload.value
      );
      state.articleListPaging.offset = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearSelectedArticle: (state) => {
      state.selectedArticle = null;
    },
    clearArticleList: (state) => {
      state.articleList = initialState.articleList;
      state.articleListSorting = initialState.articleListSorting;
      state.articleListPaging = initialState.articleListPaging;
      state.articleListFilters = initialState.articleListFilters;
    },
    clearFilters: (state) => {
      state.articleListPaging = initialState.articleListPaging;
      state.articleListFilters = initialState.articleListFilters;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder
      .addCase(getArticles.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        getArticles.fulfilled,
        (state, action: PayloadAction<Paginated<Article>>) => {
          state.loading = false;
          state.error = '';
          state.articleList = action.payload.docs;

          if (isNumber(action.payload.offset)) {
            state.articleListPaging.offset = action.payload.offset;
          }

          if (isNumber(action.payload.total)) {
            state.articleListPaging.total = action.payload.total;
          }
        }
      )
      .addCase(
        getArticles.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = ErrorUtils.parseAxiosError(action);
        }
      );

    // @ts-ignore
    builder
      .addCase(getArticleById.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        getArticleById.fulfilled,
        (state, action: PayloadAction<Article>) => {
          state.loading = false;
          state.error = '';
          state.selectedArticle = action.payload;
        }
      )
      .addCase(
        getArticleById.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = ErrorUtils.parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createArticle.fulfilled,
        (state, action: PayloadAction<Article>) => {
          state.loading = false;
          state.error = '';

          if (state.articleList?.length) {
            state.articleList = [action.payload, ...state.articleList];
          } else {
            state.articleList = [action.payload];
          }
        }
      )
      .addCase(
        createArticle.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = ErrorUtils.parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateArticle.fulfilled,
        (state, action: PayloadAction<Article>) => {
          state.loading = false;
          state.error = '';
          state.selectedArticle = action.payload;
        }
      )
      .addCase(
        updateArticle.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = ErrorUtils.parseAxiosError(action);
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteArticle.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.articleList = state.articleList.filter(
            ({ id }) => id !== action.payload
          );
        }
      )
      .addCase(
        deleteArticle.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.loading = false;
          state.error = ErrorUtils.parseAxiosError(action);
        }
      );
  },
});

export const {
  setArticleListPaging,
  setArticleListSorting,
  clearSelectedArticle,
  clearError,
  clearArticleList,
  changeArticleListFilter,
  clearFilters,
} = articlesSlice.actions;

export default articlesSlice.reducer;
