import axios from 'axios';
import { getIdToken } from '../firebase/auth';
import { API_PATH_PREFIX } from '../api/backend';

axios.interceptors.request.use(
  async (config) => {
    if (!config.baseURL) {
      config.baseURL = `${process.env.REACT_APP_API_URL}${API_PATH_PREFIX}`;
    }

    const token: string = await getIdToken();

    if (token) {
      config.headers.set({
        ...config.headers,
        Authorization: `Bearer ${token}`,
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
