import React, { FC } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import StringUtils from '../../../common/utils/services/string-utils';

const OvPrettyUserName: FC<{
  user?: UserInfo;
  options?: { readSafe: boolean };
}> = ({ user, options }) => {
  const prettyUserName = StringUtils.getPrettyUserName(user, options);

  return (
    <>
      {prettyUserName}
      <br />
      {!options?.readSafe && (
        <div style={{ whiteSpace: 'nowrap' }}>
          {prettyUserName ? ` (${user?.email})` : user?.email}
        </div>
      )}
    </>
  );
};

export default OvPrettyUserName;
