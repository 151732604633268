import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportService } from '../../services/report.service';
import { CreateReportRequest } from '../../common/model/dto/report/create-report-request';

const findReportsByUserDocId = createAsyncThunk(
  'report/findByDocId',
  async (userDocId: string, { rejectWithValue }) => {
    try {
      let paginatedReports = await ReportService.findReportsByUserDocId(
        userDocId
      );
      return paginatedReports.docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createReport = createAsyncThunk(
  'report/createReport',
  async (createReportRequest: CreateReportRequest, { rejectWithValue }) => {
    try {
      const report = await ReportService.createReport(createReportRequest);

      return await ReportService.generatePdf(report.id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateReport = createAsyncThunk(
  'report/updateReport',
  async (
    updateObj: {
      id: string;
      updatedProperties: any;
    },
    { rejectWithValue }
  ) => {
    try {
      await ReportService.updateReport(
        updateObj.id,
        updateObj.updatedProperties
      );

      return updateObj;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const deleteReportById = createAsyncThunk(
  'report/deleteById',
  async (reportId: string, { rejectWithValue }) => {
    try {
      await ReportService.deleteReportById(reportId);
      return reportId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { findReportsByUserDocId, deleteReportById, createReport, updateReport };
