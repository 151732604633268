import styled from 'styled-components';
import { Select, SelectProps } from '@mui/material';
import Colours from '../../../design-system/colours';
import breakpoints from '../../../design-system/breakpoints';
import React, { FC } from 'react';

const OvSelect: FC<SelectProps> = ({ children, ...props }) => (
  <BorderedSelect {...props}>{children}</BorderedSelect>
);

export default OvSelect;

const BorderedSelect = styled(Select)`
  && {
    max-width: 260px;
    border: 1px solid ${Colours.OV_BASE};
    font-size: 0.875rem;

    @media (min-width: ${breakpoints.md}) {
      max-width: none;
    }

    &.Mui-focused {
      border-color: transparent;
    }

    .MuiInputBase-input {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
`;
