import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Slider,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ScanSequence } from '../../../common/model/dto/scan-sequences/scan-sequence';
import breakpoints from '../../../design-system/breakpoints';
import OvSelect from '../atoms/OvSelect';
import { ScanType } from '../../../common/model/dto/scan-sequences/scan-type.enum';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AddScanningPlanDto } from '../../../common/model/dto/scan-sequences/add-scanning-plan';
import { useAppDispatch } from '../../../redux/hooks';
import { addScanningPlanToScanSequence } from '../../../redux/thunks/admin/admin-scan-sequences.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';

const MAX_LENGTH_OF_SCANNING_PLAN = 30;

const OvAddNewPlanDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  isLoading: boolean;
  scanSequence: ScanSequence;
}> = ({ isOpen, onCancel, isLoading, scanSequence }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [scanningPlanLength, setScanningPlanLength] = useState(0);
  const [scanTypeValues, setScanTypeValues] = useState<ScanType[][]>([]);

  const disabledValues: number[] = scanSequence.scanning_plans.map(
    (plan) => plan.scanning_plan_entries.length
  );
  const currentLengths = scanSequence.scanning_plans.map(
    (plan) => plan.scanning_plan_entries.length
  );

  const maxPossibleLength = Math.max(
    ...Array.from(
      { length: MAX_LENGTH_OF_SCANNING_PLAN + 1 },
      (_, i) => i++
    ).filter((number) => !currentLengths.includes(number))
  );

  const handleChange = (event: any, newValue: any) => {
    if (!disabledValues.includes(newValue)) {
      setScanningPlanLength(newValue);
    }
  };

  useEffect(() => {
    setScanningPlanLength(maxPossibleLength);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setScanTypeValues(Array.from({ length: scanningPlanLength }, () => []));
  }, [scanningPlanLength]);

  useEffect(() => {
    if (isOpen) {
      setScanningPlanLength(maxPossibleLength);
      setScanTypeValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const marks = Array.from({ length: maxPossibleLength }, (_, i) => i + 2).map(
    (value) => {
      return disabledValues.includes(value) ? { value: null } : { value };
    }
  );

  const handleSelect = (event: any, index: number) => {
    const selectedValuesForDay = event.target.value;

    setScanTypeValues((prevValues) => {
      const updatedValues = [...prevValues];

      if (!updatedValues[index]) {
        updatedValues[index] = [];
      }

      updatedValues[index] =
        typeof selectedValuesForDay === 'string'
          ? [selectedValuesForDay]
          : selectedValuesForDay;

      return updatedValues;
    });
  };

  const handleSubmit = (e: any) => {
    if (scanSequence.id) {
      e.preventDefault();

      const addScanningPlanDto: AddScanningPlanDto = {
        generic_scanning_plan: {
          scanning_plan_entries: scanTypeValues.map((scanTypes, index) => ({
            number_of_day: index,
            scan_types: scanTypes,
          })),
        },
      };

      dispatch(
        addScanningPlanToScanSequence({
          id: scanSequence.id,
          addScanningPlanDto,
        })
      );

      onCancel();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <StyledDialogTitle>
          {t('scanSequenceDetails.addNewScanningPlan')}
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledSliderHeader>
            {t('scanSequenceDetails.scanningPlanLengthDay', {
              scanningPlanLength,
            })}
          </StyledSliderHeader>
          <StyledSlider
            value={scanningPlanLength}
            onChange={handleChange}
            min={2}
            max={maxPossibleLength}
            marks={marks as unknown as any}
          />
          <StyledSelectContainer>
            {Array.from({ length: scanningPlanLength }).map((_, index) => (
              <>
                <StyledSelectTitle>
                  {t('scanSequenceDetails.nthDay', {
                    numberOfDay: index,
                  })}
                  :
                </StyledSelectTitle>
                <StyledSelect
                  required
                  labelId={`day-${index}`}
                  multiple
                  value={scanTypeValues[index] ?? []}
                  onChange={(event) => handleSelect(event, index)}
                  renderValue={(selected: any) => {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                          height: '100%',
                          alignItems: 'center',
                        }}
                      >
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={value}
                            style={{
                              backgroundColor:
                                value === ScanType.LH_PG
                                  ? Colours.LIGHT_TEAL
                                  : Colours.LIGHT_BLUE_GRAY,
                            }}
                          />
                        ))}
                      </Box>
                    );
                  }}
                >
                  {Object.values(ScanType).map((scanType) => (
                    <StyledMenuItem
                      key={scanType}
                      value={scanType}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '5px',
                      }}
                    >
                      {scanType}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </>
            ))}
          </StyledSelectContainer>
          <StyledDialogActions>
            <StyledLightOvButton onClick={onCancel}>
              {t('common.actions.cancel')}
            </StyledLightOvButton>
            <StyledOvButton
              disabled={scanTypeValues.some(
                (scanTypeValue) => scanTypeValue.length === 0
              )}
              type="submit"
            >
              {t('common.actions.create')}
            </StyledOvButton>
          </StyledDialogActions>
        </StyledDialogContent>
      </form>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvAddNewPlanDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledSlider = styled(Slider)`
  && {
    color: ${Colours.OV_BASE};
    margin: auto;
  }

  & .MuiSlider-mark {
    background-color: ${Colours.OV_BASE_HOVER};
    width: 1rem;
    height: 1rem;
    border-radius: ${Variables.borderRadius.CIRCLE};
    opacity: 1;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  & .MuiSlider-thumb {
    &:hover,
    &.Mui-focusVisible {
      box-shadow: 0 0 0 0.5rem ${Colours.OV_BASE};
    }
  }

  & .MuiSlider-thumb.Mui-active {
    box-shadow: 0 0 0 0.5rem ${Colours.OV_BASE};
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    margin-bottom: 1rem;
    max-width: none;
    height: 3rem;

    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }

      .MuiSelect-select {
        height: 100%;
      }
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledSelectTitle = styled.p`
  font-weight: normal;
  font-size: 0.75rem;
  padding: 0;
  margin: 0;
  color: ${Colours.OV_BASE};
`;

const StyledSelectContainer = styled.div`
  max-height: 38rem;
  overflow-y: auto;
  width: 100%;
`;

const StyledSliderHeader = styled.h4`
  margin: 0;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
