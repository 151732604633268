import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Head } from '../../common/utils/Head';
import breakpoints from '../../design-system/breakpoints';
import Colours from '../../design-system/colours';
import OvLogo from '../UI/atoms/OvLogo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  acceptPatientInvitation,
  acceptPatientInvitationFromProvider,
  acceptProviderInvitation,
} from '../../redux/thunks/clinic-location.thunk';
import Variables from '../../design-system/variables';
import OvButton from '../UI/atoms/OvButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { History } from 'history';

export enum AcceptInvitationRequestType {
  CLINIC_LOCATION_PROVIDER = 'clinic-location-provider',
  CLINIC_LOCATION_PATIENT = 'clinic-location-patient',
  PROVIDER_PATIENT = 'provider-patient',
}

const AcceptInvitationPage: FC<{
  acceptInvitationRequestType: AcceptInvitationRequestType;
}> = ({ acceptInvitationRequestType }) => {
  const { t } = useTranslation();
  const { token }: any = useParams();
  const history: History = useHistory();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state) => state.clinicLocation.isAcceptRequestLoading
  );
  const error = useAppSelector(
    (state) => state.clinicLocation.acceptRequestError
  );

  useEffect(() => {
    acceptInvitationBasedOnRequestType();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptInvitationRequestType, dispatch, token]);

  const acceptInvitationBasedOnRequestType = () => {
    if (
      acceptInvitationRequestType ===
      AcceptInvitationRequestType.CLINIC_LOCATION_PROVIDER
    ) {
      dispatch(acceptProviderInvitation(token));
    } else if (
      acceptInvitationRequestType ===
      AcceptInvitationRequestType.CLINIC_LOCATION_PATIENT
    ) {
      dispatch(acceptPatientInvitation(token));
    } else if (
      acceptInvitationRequestType ===
      AcceptInvitationRequestType.PROVIDER_PATIENT
    ) {
      dispatch(acceptPatientInvitationFromProvider(token));
    }
  };

  return (
    <>
      <Head title={t('login.pageTitle')} description={t('login.pageDesc')} />
      {!isLoading ? (
        <Container>
          <InfoBox>
            <OvLogo />
            {!error ? (
              <>
                <StyledCheckCircleIcon />
                <InfoHeading>{t('approveInvitationPage.success')}</InfoHeading>
                {acceptInvitationRequestType ===
                  AcceptInvitationRequestType.CLINIC_LOCATION_PROVIDER && (
                  <StyledOvButton onClick={() => history.push('/')}>
                    {t('approveInvitationPage.proceed')}
                    <ArrowForwardIcon style={{ marginLeft: '0.5rem' }} />
                  </StyledOvButton>
                )}
              </>
            ) : (
              <>
                <StyledCancelIcon />
                <InfoHeading>
                  {t('approveInvitationPage.alreadyApprovedTitle')}
                </InfoHeading>
                {acceptInvitationRequestType ===
                AcceptInvitationRequestType.CLINIC_LOCATION_PROVIDER ? (
                  <InfoDescription>
                    {t('approveInvitationPage.alreadyApprovedLoginSuggestion')}
                    <StyledLink href="https://360.oova.life">
                      360.oova.life
                    </StyledLink>
                    .
                    <br />
                    {t('approveInvitationPage.ifSomethingWrongMessage')}
                  </InfoDescription>
                ) : (
                  <InfoDescription>
                    {t('approveInvitationPage.ifSomethingWrongPatientMessage')}
                  </InfoDescription>
                )}
              </>
            )}
          </InfoBox>
        </Container>
      ) : (
        <OvLoadingIndicator position={'fixed'} />
      )}
    </>
  );
};

export default AcceptInvitationPage;

const Container = styled.main`
  background: ${Colours.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${Variables.boxShadow.alternateBox};
  padding: 3.875rem 2rem 1.5rem 2rem;

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 30rem;
    border-radius: 0.5rem;
  }
`;

const InfoBox = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    margin-top: 2rem;
    color: ${Colours.OV_GREEN};
    font-size: 5rem;
  }
`;

const StyledCancelIcon = styled(CancelIcon)`
  && {
    margin-top: 2rem;
    color: ${Colours.OV_RED};
    font-size: 5rem;
  }
`;

const InfoHeading = styled.h1`
  color: ${Colours.OV_BASE};
`;

const InfoDescription = styled.h3`
  color: ${Colours.OV_BASE};
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
    padding: 0.5rem 1rem;
    background-color: ${Colours.OV_BASE};
    margin: 1rem 0;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      margin: 0 0.75rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledLink = styled.a`
  color: ${Colours.OV_DARK_BLUE};
`;
