import axios from 'axios';
import { FullscriptRequestAccessToken } from '../common/model/dto/fullscript/fullscript-request-access-token';
import { FullScriptOauthAccessToken } from '../common/model/dto/fullscript/fullscript-oauth-access-token';
import { FullScriptSessionGrantToken } from '../common/model/dto/fullscript/fullscript-session-grant-token';
import { TreatmentPlanListResponse } from '../common/model/dto/fullscript/treatment-plan-list-response';
import { TreatmentPlan } from '../common/model/dto/fullscript/treatment-plan';

export class FullScriptService {
  static async requestAccessToken(
    requestAccessTokenObject: FullscriptRequestAccessToken
  ) {
    return await axios.post<FullScriptOauthAccessToken>(
      `fullscript/request-access-token`,
      requestAccessTokenObject,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  }

  static async requestSessionGrant() {
    return await axios.get<FullScriptSessionGrantToken>(
      `fullscript/session-grant`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  }

  static async revokeAccess() {
    return await axios.get(`fullscript/revoke-access`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  static async getTreatmentPlans(fullscriptPatientId?: string) {
    const { data } = await axios.get<TreatmentPlanListResponse>(
      `fullscript/patient/${fullscriptPatientId}/treatment-plans`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    return data;
  }

  static async removeTreatmentPlan(id?: string) {
    await axios.patch<TreatmentPlan>(
      `fullscript/treatment_plans/${id}/cancel`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  }
}
