import { FC } from 'react';
import styled from 'styled-components';
import { Address } from '../../../common/model/dto/address';

const OvOrderAddress: FC<{ address: Address }> = ({ address }) => {
  return (
    <StyledWrapper>
      <StyledText>
        {address.first_name} {address.last_name}
      </StyledText>
      <StyledText>
        {address.address1 ?? '-'} {address.address2 ?? ''}
      </StyledText>
      <StyledText>
        {address.city}, {address.region_code}, {address.postal_code}
      </StyledText>
      <StyledText>
        {address.country} ({address.country_code})
      </StyledText>
      <StyledText>{address.phone}</StyledText>
    </StyledWrapper>
  );
};

export default OvOrderAddress;

const StyledWrapper = styled.div`
  text-align: left;
  padding: 0.75rem;
`;

const StyledText = styled.p`
  margin: 0;
`;
