import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

const OvNoContent: FC<{ style?: any; children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

export default OvNoContent;

const StyledParagraph = styled.p`
  color: ${Colours.OV_BASE};
  margin: 0.5rem 1.5px;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: ${Variables.fontSizes.LARGE};
  display: flex;
  align-items: center;
`;
