import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import styled from 'styled-components';
import Snackbar from '@mui/material/Snackbar';
import UpdateIcon from '@mui/icons-material/Update';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getLatestConfiguration,
  updateConfiguration,
} from '../../redux/thunks/admin/admin-configuration.thunk';

import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvCompactButton from '../UI/atoms/OvCompactButton';

import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';

const ConfigurationUserInfoRequiredFields: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.adminConfiguration.loading);
  const errorMessage = useAppSelector(
    (state) => state.adminConfiguration.errorMessage
  );
  const latestConfiguration = useAppSelector(
    (state) => state.adminConfiguration.latestConfiguration
  );

  const [updatedJson, setUpdatedJson] = useState<any>({});
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    dispatch(getLatestConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (latestConfiguration?.user_info_required_fields) {
      setUpdatedJson(latestConfiguration.user_info_required_fields);
    }
  }, [latestConfiguration]);

  useEffect(() => {
    if (errorMessage) {
      setOpenErrorSnackbar(true);
    }
  }, [errorMessage]);

  const handleJsonChange = (value: any) => {
    setUpdatedJson(value.jsObject);
  };

  const handleUpdateConfiguration = () => {
    if (latestConfiguration) {
      dispatch(
        updateConfiguration({
          ...latestConfiguration,
          user_info_required_fields: updatedJson,
        })
      );
    }
  };

  const handleCloseSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  return (
    <Container>
      <JsonContainer>
        {latestConfiguration && (
          <JSONInput
            id="user_info_required_fields_editor"
            placeholder={latestConfiguration.user_info_required_fields}
            locale={locale}
            theme="light_mitsuketa_tribute"
            height="550px"
            onChange={handleJsonChange}
          />
        )}
      </JsonContainer>

      <OvCompactButton
        onClick={handleUpdateConfiguration}
        icon={<UpdateIcon />}
      >
        {t('configurationSettings.update')}
      </OvCompactButton>

      {isLoading && <OvLoadingIndicator position="fixed" />}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <ErrorMessageContainer>
          <OvErrorMessage message={errorMessage} />
        </ErrorMessageContainer>
      </Snackbar>
    </Container>
  );
};

export default ConfigurationUserInfoRequiredFields;

// Styled Components
const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const JsonContainer = styled.div`
  padding-bottom: 1.5rem;
`;

const ErrorMessageContainer = styled.div`
  padding: 1rem;
  border-radius: ${Variables.borderRadius.LARGE};
  background-color: ${Colours.STRAWBERRY['50']};
`;
