import React, { FC } from 'react';
import styled from 'styled-components';
import { Clinic } from '../../common/model/dto/clinic';
import { ClinicInfoDataUtils } from '../../common/utils/services/clinic-info-data-utils';
import breakpoints from '../../design-system/breakpoints';
import { useAppDispatch } from '../../redux/hooks';
import { updateClinic } from '../../redux/thunks/admin/admin-clinic.thunk';
import OvTable from '../UI/molecules/OvTable';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useTranslation } from 'react-i18next';
import { CLINIC_PROFILE_IMAGES_PATH } from '../../api/firebase-storage';
import OvEditableAvatar from '../UI/molecules/OvEditableAvatar';
import FilterableClinicLocationList from './FilterableClinicLocationList';
import Colours from '../../design-system/colours';

const ClinicInfo: FC<{ selectedClinic?: Clinic }> = ({ selectedClinic }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClinicUpdate = (updatedProperties: any) => {
    if (selectedClinic?.id) {
      const updateObj = {
        clinicId: selectedClinic.id,
        updatedProperties: {
          ...updatedProperties,
        },
      };

      dispatch(updateClinic(updateObj));
    }
  };

  return (
    <Container>
      <HeaderSection>
        <OvEditableAvatar
          entity={selectedClinic}
          handleUpdate={handleClinicUpdate}
          imageBasePath={CLINIC_PROFILE_IMAGES_PATH}
          dialogTitle={t('clinicDialogs.clinicImageUploadTitle')}
          defaultImageComponent={<ProfileImage />}
        />
        <TableWrapper>
          <OvTable
            data={ClinicInfoDataUtils.mapClinicInfoToTableDataFormat(
              selectedClinic
            )}
            onSaveRowValue={handleClinicUpdate}
          />
        </TableWrapper>
      </HeaderSection>

      <ClinicLocationSection>
        <SectionHeader>{t('clinicDetails.tabs.clinicSites')}</SectionHeader>
        <FilterableClinicLocationList selectedClinic={selectedClinic} />
      </ClinicLocationSection>
    </Container>
  );
};

export default ClinicInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const HeaderSection = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const ProfileImage = styled(LocalHospitalIcon)`
  && {
    width: 12rem !important;
    height: 12rem !important;
    border: 0.375rem solid;
    border-radius: 50%;
    margin-bottom: 0.25rem;
    padding: 0.75rem;
  }
`;

const ClinicLocationSection = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const SectionHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${Colours.OV_BASE};
  margin-bottom: 1rem;
`;
